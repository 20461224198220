import React, { useState, useEffect } from 'react'
import { Table, Form, Row, Col, InputGroup, Container } from 'react-bootstrap'
import Select from 'react-select'
import CurrencyInput from 'react-currency-input'
import { registerLocale } from 'react-datepicker'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'react-datepicker/dist/react-datepicker.css'
import ptBr from 'date-fns/locale/pt-BR'
import moment from 'moment'
import { toast } from 'react-toastify'
import { formatMoney, handleDecimalConvert } from '../../../utils/string.utils'
import { parcelasSelect } from '../../../constants/agendamento.constants'

registerLocale('pt-BR', ptBr)

export default function Parcelamentos(props) {
  const { procedimentosSelecionados, avaliacaoProcedimentoProteticoId, updatePagamento, index, isProtetico} = props
  const especialidade = props.avaliacao.especialidade;


  const [totalPagamento, setTotal] = useState(0.0)
  const [valorEntrada, setValorEntrada] = useState('0.0')
  const [valorParcelamento] = useState(totalPagamento / parcelasSelect[0].value)
  const [parcelamentoSelecionado, setParcelamentoSelecionado] = useState(parcelasSelect[0])
  const [parcelas, setParcelas] = useState([])
  const [dataVencimento, setDataVencimento] = useState(new Date())

  useEffect(() => {
    let soma = 0
    procedimentosSelecionados.forEach((p) => {
      soma += p.valorCobrado || (p.procedimentoProteticoId ? p.valor : p.procedimento.valor)
    })
    setTotal(soma)
  }, [procedimentosSelecionados])

  useEffect(() => {
    let valorParcelas = 0
    let diferencaPrimeiraParcela = 0
    const valorEntradaNumber = handleDecimalConvert(valorEntrada)
    if (valorEntradaNumber > totalPagamento) {
      setValorEntrada(formatMoney(totalPagamento).substring(3))
      setParcelamento(parcelasSelect[0])
    } else {
      const { valorFinalParcelas, diferenca } = getValorParcelas(valorEntradaNumber, valorParcelas)
      valorParcelas = valorFinalParcelas
      diferencaPrimeiraParcela = diferenca
      if (valorEntradaNumber > 0 && parcelamentoSelecionado === parcelasSelect[0]) {
        setParcelamento(parcelasSelect[1])
      }
    }

    let parcelas = getParcelas(valorParcelas, diferencaPrimeiraParcela, valorEntradaNumber)
    setParcelas(parcelas)
    const pagamento = {
      valorTotal: Number(totalPagamento.toFixed(2)),
      dataVencimento: dataVencimento ? dataVencimento.toISOString() : null,
      numeroParcelas: parcelamentoSelecionado.value,
      parcelas,
      avaliacaoProcedimentoProteticoId,
    }
    updatePagamento(pagamento, index)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valorEntrada, totalPagamento, parcelamentoSelecionado, dataVencimento])

  function getParcelas(valorParcelas, diferencaPrimeiraParcela, entrada) {
    let parcelas = []
    for (let index = 1; index < parcelamentoSelecionado.value; index++) {
      const valor = index === 1 ? +(valorParcelas + diferencaPrimeiraParcela).toFixed(2) : +valorParcelas.toFixed(2)
      parcelas.push({
        valor: +valor.toFixed(2),
        numero: index + 1,
        dataVencimento: moment(dataVencimento)
          .add(index, 'month')
          .toISOString(),
      })
    }
    if (entrada === 0) {
      parcelas.unshift({
        valor: +valorParcelas.toFixed(2),
        numero: 1,
        dataVencimento: moment(dataVencimento).toISOString(),
      })
    } else {
      parcelas.unshift({
        valor: entrada,
        numero: 1,
        dataVencimento: moment(dataVencimento).toISOString(),
      })
    }
    return parcelas
  }

  function getValorParcelas(valorEntradaNumber, valorParcelas) {
    let diferenca = 0
    let totalParcelas = parcelamentoSelecionado.value
    if (valorEntradaNumber > 0 && totalParcelas > 1) {
      totalParcelas = totalParcelas - 1
    }
    valorParcelas = (totalPagamento - valorEntradaNumber) / totalParcelas
    diferenca = totalPagamento - valorEntradaNumber - valorParcelas.toFixed(2) * totalParcelas
    return { valorFinalParcelas: valorParcelas, diferenca }
  }
  const handleDataVencimento = (data) => {
    if (moment() > data) {
      data = moment()
    }
    setVencimento(data)
  }

  const setEntrada = (valor) => {
    const minValue = (totalPagamento * 5) / 100;
    
    if((typeof isProtetico == "undefined" || !isProtetico) && typeof especialidade.nome !== "undefined" && especialidade.nome.includes("Implantodontia") && valor && parseFloat(valor.replace(".", "").replace(",", ".")) < minValue)
      toast(`Valor da Primeira parcela inferior ao permitido ${formatMoney(minValue)}`, { type: toast.TYPE.WARNING })

    setValorEntrada(valor)
  }

  const setParcelamento = (parcelas) => setParcelamentoSelecionado(parcelas)

  const setVencimento = (vencimento) => setDataVencimento(vencimento)

  return (
    
    <Container className='my-4 px-0'>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Procedimento</th>
            <th className='text-right' width='250px'>
              Valor
            </th>
          </tr>
        </thead>
        <tbody>
          {procedimentosSelecionados.map((p) => (
            <tr key={p.id}>
              <td>{p.procedimento ? p.procedimento.nome : p.procedimentoProtetico.nome}</td>
              <td className='text-right'>
                {formatMoney(p.procedimentoProteticoId ? p.valor : p.valorCobrado || p.procedimento.valor)}
              </td>
            </tr>
          ))}
          <tr className='bg-secondary-03'>
            <td className='font-weight-bold'>Total</td>
            <td className='text-right font-weight-bold'>{formatMoney(totalPagamento)}</td>
          </tr>
        </tbody>
      </Table>
      <h4 className='my-4 text-secondary font-weight-bold'>Condições de pagamento</h4>
      <Row>
        <Form.Group as={Col} className={(typeof especialidade.nome !== "undefined" && especialidade.nome.includes("Implantodontia") && (typeof isProtetico == "undefined" || !isProtetico)) ? 'col-md-10' : 'col-md-5'}>
          <Form.Label>Primeira parcela</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>R$</InputGroup.Text>
            </InputGroup.Prepend>
            <CurrencyInput
              className='form-control'
              decimalSeparator=','
              thousandSeparator='.'
              value={valorEntrada}
              onChange={setEntrada}
            />
          </InputGroup>

          {((typeof isProtetico == "undefined" || !isProtetico) && typeof especialidade.nome !== "undefined" && especialidade.nome.includes("Implantodontia")) &&
          <small class="text-danger">Mínimo de entrada: {formatMoney(((totalPagamento * 5) / 100))}</small>
          }

        </Form.Group>
        <Form.Group as={Col} className='col-md-2'>
          <Form.Label>Parcelas</Form.Label>
          <Select
            noOptionsMessage={() => 'Indisponível'}
            value={parcelamentoSelecionado}
            options={parcelasSelect}
            onChange={setParcelamento}
          />
        </Form.Group>

        {((typeof especialidade.nome !== "undefined" && especialidade.nome.includes("Implantodontia") && (typeof isProtetico != "undefined" || isProtetico)) || typeof especialidade.nome !== "undefined" && !especialidade.nome.includes("Implantodontia") ) &&
        <Form.Group as={Col} className='col-md-5 required'>
          <Form.Label>Data da Primeira parcela</Form.Label>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              id='dataPrimeiraParcela'
              name='dataPrimeiraParcela'
              className='form-control'
              invalidDateMessage='Formato de data inválido'
              onChange={handleDataVencimento}
              value={dataVencimento}
              variant='inline'
              format='DD/MM/YYYY'
              maxDate={moment().add(30, 'days')}
              required
              autoOk
              animateYearScrolling
              disablePast
            />
          </MuiPickersUtilsProvider>
        </Form.Group>
        }
      </Row>
      {valorParcelamento <= totalPagamento && (
        <div>
          <h4 className='my-4 text-secondary font-weight-bold'>Resumo do Parcelamento</h4>
          <Table bordered hover>
            <thead>
              <tr>
                <th className='text-center' width='100px'>
                  Parcela
                </th>
                <th className='text-center'>Vencimento</th>
                <th className='text-center' width='250px'>
                  Valor
                </th>
              </tr>
            </thead>
            <tbody>
              {parcelas.map(({ numero, dataVencimento, valor }, index) => (
                <tr key={index}>
                  <td className='text-center'>{numero}</td>
                  <td className='text-center'>
                    {dataVencimento ? moment(dataVencimento).format(`DD/MM/YYYY`) : 'Data Inválida'}
                  </td>
                  <td className='text-center'>{formatMoney(valor)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  )
}
//

/* eslint-disable */
import { numeroPorExtenso } from './prototype.utils'

String.prototype.numeroPorExtenso = numeroPorExtenso

export function checkPasswordFormat(password) {
  return !(password.length < 8 || password.search(/\d/) === -1 || password.search(/[a-zA-Z]/) === -1)
}

export function handleDecimalConvert(value) {
  value = String(value).replace(/\./g, '')
  return Number(value.replace(/\,/g, '.'))
}

export function formatTitulo(str) {
  let splitStr = str.toLowerCase().split(' ')
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

export function camelCaseToNormal(str) {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase()
  })
}

export function formatMoney(value, currency = true) {
  const money = value ? value : 0
  if (currency)
    return money.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })

  return money.toLocaleString('pt-BR', {
    currency: 'BRL',
  })
}

export function formatFromMoney(value) {
  if (typeof value === 'string') {
    value = value.replace('R$', '')
    value = value.replace('.', '')
    value = value.replace(',', '.')
  }
  return Number(value)
}

export function enderecoToString(endereco) {
  if (endereco) {
    return `${endereco.logradouro} ${endereco.numero}, ${endereco.complemento ? endereco.complemento + ',' : ''} ${endereco.bairro
      }, Cidade de ${endereco.cidade}-${endereco.estado}`
  } else {
    return '-'
  }
}

export function valorPorExtenso(value) {
  const fixed = value.toFixed(2)
  const [reais, centavos] = fixed.split('.')
  return `${formatMoney(value)} (${('' + reais).numeroPorExtenso()} reais e ${(
    '' + centavos
  ).numeroPorExtenso()} centavos)`
}

import React, { Fragment } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import Select from 'react-select'

export default function Filtros(props) {
  const { pacienteFilterOptions, selectedPacienteFilter } = props

  const clear = async () => {
    props.setSelectedPacienteFilter(null)
  }

  const setPaciente = async (paciente) => {
    props.setSelectedPacienteFilter(paciente)
  }

  return (
    <Fragment>
      <Form.Label>Filtrar por:</Form.Label>
      <Row className='mb-5 d-flex align-baseline'>
        <Col md={4}>
          <Select
            placeholder='Paciente'
            value={selectedPacienteFilter}
            onChange={setPaciente}
            options={pacienteFilterOptions}
            noOptionsMessage={() => 'Nenhum paciente agendado'}
          />
        </Col>
        <Col md={7} />
        <Col md={1}>
          <Button onClick={clear} variant='danger'>
            Limpar
          </Button>
        </Col>
      </Row>
    </Fragment>
  )
}

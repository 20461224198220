import React, { Fragment, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'
import SystemConstants from '../../constants/system.constants'

export default function Chat({ authenticated, isProtetico }) {
  const [isOpen, setIsOpen] = useState(false)
  const chatButtonIsVisible = localStorage.getItem(SystemConstants.getChatStatus()) === 'success'

  useEffect(() => {
    authenticated && !isProtetico && configChatToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const configChatToken = () => {
    const externalCommand = 'login-with-token'
    // const userId = localStorage.getItem(SystemConstants.getChatUserId())
    const token = localStorage.getItem(SystemConstants.getChatTokenKey())
    document.getElementById('rocket-chat-window').contentWindow.postMessage({ externalCommand, token }, '*')
  }

  const toggleIframe = () => {
    setIsOpen(!isOpen)
    configChatToken()
  }

  return (
    <Fragment>
      {chatButtonIsVisible && (
        <div
          onClick={toggleIframe}
          style={{ color: 'white', cursor: 'pointer', fontSize: '1.7em' }}
          className='p-0 mr-3'
        >
          {<FontAwesomeIcon icon={faCommentDots} />}
        </div>
      )}
      <iframe
        id='rocket-chat-window'
        src={SystemConstants.getChatUrl()}
        frameBorder='0'
        title='chat-integration'
        className='position-fixed'
        style={{ display: isOpen ? 'block' : 'none' }}
      />
    </Fragment>
  )
}

import { Anamnese } from "../types";

const initialState = {
  anamnese: [],
  respostasPaciente: [],
  selectedAnamnese: {}
};

export const anamneseReducer = (state = initialState, action) => {
  switch (action.type) {
    case Anamnese.LISTAR_ANAMNESE:
      return {
        ...state,
        anamnese: action.anamnese,
        selectedAnamnese: {}
      };
    case Anamnese.EDITAR_ANAMNESE:
      return {
        ...state,
        selectedAnamnese: action.selectedAnamnese
      };
    case Anamnese.RESPOSTAS_PACIENTE:
      return {
        ...state,
        respostasPaciente: action.respostasPaciente
      };
    case Anamnese.RESET_ANAMNESE:
      return {
        ...state,
        selectedAnamnese: {}
      };
    default:
      return state;
  }
};

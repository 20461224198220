import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Spinner } from 'react-bootstrap'
import Swal from 'sweetalert2'
import AtendimentoService from '../../services/atendimento.service'
import { status } from '../../constants/agendamento.constants'
import { toast } from 'react-toastify'
import { opcoesResposta } from '../../constants/anamnese.constants'

const FINALIZADO = 'Finalizado'
const passos = {
  ANAMNESE: 1,
  ODONTOGRAMA: 2,
  PROCEDIMENTOS: 3,
}

export default function Navigation(props) {
  const { wizard, passo, ficha, history, agendamento } = props

  const [isLoading, setLoading] = useState(false)
  const [podeAvancar, setPodeAvancar] = useState(false)

  useEffect(() => {
    if (passo === passos.ANAMNESE) {
      let naoRespondida = true,
        respostaSimTextoVazio = true
      ficha.anamneseRespostas.every((resposta) => {
        if (resposta === undefined) {
          naoRespondida = true
          return false
        }
        if (
          ((resposta.tipoResposta !== null && resposta.tipoResposta === String(opcoesResposta[0].value)) ||
            resposta.tipoResposta === null) && // Resposta 'Sim'
          resposta.texto !== null &&
          resposta.texto.trim().length === 0
        ) {
          respostaSimTextoVazio = true
          return false
        }
        naoRespondida = false
        respostaSimTextoVazio = false
        return true
      })
      setPodeAvancar(!naoRespondida && !respostaSimTextoVazio)
    } else {
      setPodeAvancar(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ficha, passo])

  const scrollToTheTop = () => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }

  const proximoPasso = async () => {
    if (passo === passos.PROCEDIMENTOS) {
      const { value } = await Swal.fire({
        title:
          'Ao finalizar o atendimento todos os procedimentos serão salvos e não será possível mais alterar. Deseja confirmar?',
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#dbdbdb',
        cancelButtonText: 'Não confirmar',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Confirmar',
      })
      if (value) {
        finalizar()
      }
    } else {
      wizard.nextStep()
      scrollToTheTop()
    }
  }

  const passoAnterior = () => {
    wizard.previousStep()
    scrollToTheTop()
  }

  const finalizar = async () => {
    setLoading(true)
    if (ficha.procedimentos.length > 0) {
      try {
        const fichaSalva = { ...ficha }
        const { dentesPaciente } = fichaSalva
        const dentes = []
        Object.keys(dentesPaciente).forEach((quadrante) => {
          dentesPaciente[quadrante].forEach((dente) => {
            dentes.push(dente)
          })
        })
        fichaSalva.dentesPaciente = dentes
        await AtendimentoService.salvarAvaliacao(fichaSalva)
        await AtendimentoService.salvarStatus(agendamento.id, status.find((s) => s.label === FINALIZADO).value)
        toast(`Avaliação finalizada com sucesso.`, { type: toast.TYPE.SUCCESS })
        history.push('/atendimento')
      } catch (e) {
        console.error(e)
      }
    } else {
      toast('Nenhum procedimento adicionado.', { type: toast.TYPE.ERROR })
    }
    setLoading(false)
  }

  return (
    wizard && (
      <Row id='wizard-navigation' className='border-top position-fixed rounded-0 py-3 bg-white'>
        <Col>
          {passo > passos.ANAMNESE && (
            <Button onClick={passoAnterior} className='border' variant='light'>
              Anterior
            </Button>
          )}
        </Col>
        <Col className='text-right'>
          <Button disabled={!podeAvancar} onClick={proximoPasso} variant='primary'>
            {!isLoading ? (
              passo < passos.PROCEDIMENTOS ? (
                'Próximo'
              ) : (
                'Finalizar Atendimento'
              )
            ) : (
              <Spinner animation='border' size='sm' variant='light' />
            )}
          </Button>
        </Col>
      </Row>
    )
  )
}

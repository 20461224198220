import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'

export default function Impressao({ clearPagamento, onBack, firstStep }) {
  const handleRetorno = () => {
    clearPagamento()
    onBack()
    firstStep()
  }

  return (
    <Fragment>
      <div className='mb-5 text-center'>
        <FontAwesomeIcon style={{ fontSize: '11em' }} className='text-success mt-5 mb-3' icon={faCheckCircle} />
        <h2 style={fontStyle} className='text-success'>
          Pagamento cancelado com sucesso!
        </h2>
      </div>
      <div className='d-block mx-auto text-center'>
        <Button variant='outline-primary' className='' onClick={handleRetorno}>
          Voltar
        </Button>
      </div>
    </Fragment>
  )
}

const fontStyle = {
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
}

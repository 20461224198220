import React, { useState, useEffect, Fragment } from 'react'
import { Table, Form, Row, Col, InputGroup, Container, Button } from 'react-bootstrap'
import Select from 'react-select'
import CurrencyInput from 'react-currency-input'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { registerLocale } from 'react-datepicker'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import ptBr from 'date-fns/locale/pt-BR'

import { formatMoney, handleDecimalConvert } from '../../../../utils/string.utils'
import { parcelasSelect } from '../../../../constants/agendamento.constants'

import 'react-datepicker/dist/react-datepicker.css'
// import PageLoading from '../../../Common/PageLoading'

registerLocale('pt-BR', ptBr)

export default function Financeiro({
  procedimentosSelecionados,
  salvarPagamento,
  previousStep,
  planoTratamento,
  setIsLoading,
}) {
  const [totalPagamento, setTotal] = useState(0.0)
  const [valorEntrada, setValorEntrada] = useState('0.0')
  const [valorParcelamento] = useState(totalPagamento / parcelasSelect[0].value)
  const [parcelamentoSelecionado, setParcelamentoSelecionado] = useState(parcelasSelect[0])
  const [parcelas, setParcelas] = useState([])
  const [dataVencimento, setDataVencimento] = useState(new Date())

  useEffect(() => {
    planoTratamento && calcularTotalPagamento()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procedimentosSelecionados, planoTratamento])

  useEffect(() => {
    calcularParcelas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valorEntrada, totalPagamento, parcelamentoSelecionado, dataVencimento])

  const calcularTotalPagamento = () => {
    let soma = 0
    procedimentosSelecionados.forEach((procedimento) => {
      soma = soma + procedimento.valor
    })
    const faltaPagarValor = soma > planoTratamento.valorPago
    setTotal(faltaPagarValor ? soma - planoTratamento.valorPago : 0)
  }

  const calcularParcelas = () => {
    let valorParcelas = 0
    let diferencaPrimeiraParcela = 0
    const valorEntradaNumber = handleDecimalConvert(valorEntrada)
    if (valorEntradaNumber > totalPagamento) {
      setValorEntrada(formatMoney(totalPagamento).substring(3))
      setParcelamento(parcelasSelect[0])
    } else {
      const { valorFinalParcelas, diferenca } = getValorParcelas(valorEntradaNumber, valorParcelas)
      valorParcelas = valorFinalParcelas
      diferencaPrimeiraParcela = diferenca
      if (valorEntradaNumber > 0 && parcelamentoSelecionado === parcelasSelect[0]) {
        setParcelamento(parcelasSelect[1])
      }
    }

    let parcelas = getParcelas(valorParcelas, diferencaPrimeiraParcela, valorEntradaNumber)
    setParcelas(parcelas)
  }

  function getParcelas(valorParcelas, diferencaPrimeiraParcela, entrada) {
    let parcelas = []
    for (let index = 1; index < parcelamentoSelecionado.value; index++) {
      const valor = index === 1 ? +(valorParcelas + diferencaPrimeiraParcela).toFixed(2) : +valorParcelas.toFixed(2)
      parcelas.push({
        valor: +valor.toFixed(2),
        numero: index + 1,
        dataVencimento: moment(dataVencimento)
          .add(index, 'month')
          .toISOString(),
      })
    }
    if (entrada === 0) {
      parcelas.unshift({
        valor: +valorParcelas.toFixed(2),
        numero: 1,
        dataVencimento: moment(dataVencimento).toISOString(),
      })
    } else {
      parcelas.unshift({
        valor: entrada,
        numero: 1,
        dataVencimento: moment(dataVencimento).toISOString(),
      })
    }
    return parcelas
  }

  function getValorParcelas(valorEntradaNumber, valorParcelas) {
    let diferenca = 0
    let totalParcelas = parcelamentoSelecionado.value
    if (valorEntradaNumber > 0 && totalParcelas > 1) {
      totalParcelas = totalParcelas - 1
    }
    valorParcelas = (totalPagamento - valorEntradaNumber) / totalParcelas
    diferenca = totalPagamento - valorEntradaNumber - valorParcelas.toFixed(2) * totalParcelas
    return { valorFinalParcelas: valorParcelas, diferenca }
  }

  const setEntrada = (valor) => setValorEntrada(valor)

  const setParcelamento = (parcelas) => setParcelamentoSelecionado(parcelas)

  const setVencimento = (vencimento) => setDataVencimento(vencimento)

  const handleSalvar = async () => {
    try {
      setIsLoading(true)
      await salvarPagamento({
        valorTotal: totalPagamento,
        dataVencimento: dataVencimento.toISOString(),
        numeroParcelas: parcelamentoSelecionado.value,
        parcelas,
      })
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }
  const handleDataVencimento = (data) => {
    if (moment() > data) {
      data = moment()
    }
    setVencimento(data)
  }

  const renderProcedimentos = () => (
    <Container className='mb-5 pb-5' style={{ maxWidth: 750 }}>
      <h2 className='mb-4 text-secondary font-weight-bold'>Novo parcelamento dos procedimentos pendentes</h2>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Procedimento</th>
            <th className='text-right' width='250px'>
              Valor
            </th>
          </tr>
        </thead>
        <tbody>
          {procedimentosSelecionados.map((p) => (
            <tr key={p.id}>
              <td>{p.procedimento ? p.procedimento.nome : ''}</td>
              <td className='text-right'>{formatMoney(p.valor)}</td>
            </tr>
          ))}
          {planoTratamento.valorPago > 0 && (
            <tr className='bg-success-03'>
              <td className='font-weight-bold'>Valor pago</td>
              <td className='text-right font-weight-bold'>{formatMoney(planoTratamento.valorPago)}</td>
            </tr>
          )}
          <tr className='bg-secondary-03'>
            <td className='font-weight-bold'>Total</td>
            <td className='text-right font-weight-bold'>{formatMoney(totalPagamento)}</td>
          </tr>
        </tbody>
      </Table>

      {totalPagamento > 0 ? (
        renderParcelamento()
      ) : (
        <div className='alert alert-success'>
          O plano de tratamento do paciente estará quitado após este(s) cancelamento(s)
        </div>
      )}

      <div className='d-flex flex-fill align-items-center justify-content-between'>
        <div style={backContainerStyle} onClick={previousStep}>
          <Button variant='outline-primary' style={backStyle}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <span className='text-primary'>Voltar</span>
        </div>
        <Button variant='primary' onClick={handleSalvar}>
          Salvar
        </Button>
      </div>
    </Container>
  )

  const renderParcelamento = () => (
    <Fragment>
      <h4 className='my-4 text-secondary font-weight-bold'>Condições de pagamento</h4>
      <Row>
        <Form.Group as={Col} className='col-md-5'>
          <Form.Label>Primeira parcela</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>R$</InputGroup.Text>
            </InputGroup.Prepend>
            <CurrencyInput
              className='form-control'
              decimalSeparator=','
              thousandSeparator='.'
              value={valorEntrada}
              onChange={setEntrada}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} className='col-md-2'>
          <Form.Label>Parcelas</Form.Label>
          <Select value={parcelamentoSelecionado} options={parcelasSelect} onChange={setParcelamento} />
        </Form.Group>
        <Form.Group as={Col} className='col-md-5 required'>
          <Form.Label>Data da Primeira parcela</Form.Label>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              id='dataPrimeiraParcela'
              name='dataPrimeiraParcela'
              className='form-control'
              invalidDateMessage='Formato de data inválido'
              onChange={handleDataVencimento}
              value={dataVencimento}
              variant='inline'
              format='DD/MM/YYYY'
              required
              autoOk
              animateYearScrolling
              disablePast
            />
          </MuiPickersUtilsProvider>
        </Form.Group>
      </Row>
      {valorParcelamento <= totalPagamento && (
        <div>
          <h4 className='my-4 text-secondary font-weight-bold'>Resumo do Parcelamento</h4>
          <Table bordered hover>
            <thead>
              <tr>
                <th className='text-center' width='100px'>
                  Parcela
                </th>
                <th className='text-center'>Vencimento</th>
                <th className='text-center' width='250px'>
                  Valor
                </th>
              </tr>
            </thead>
            <tbody>
              {parcelas.map(({ numero, dataVencimento, valor }, index) => (
                <tr key={index}>
                  <td className='text-center'>{numero}</td>
                  <td className='text-center'>
                    {dataVencimento ? moment(dataVencimento).format(`DD/MM/YYYY`) : 'Data Inválida'}
                  </td>
                  <td className='text-center'>{formatMoney(valor)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Fragment>
  )

  return (
    <div className='mb-5 pb-5 px-5'>
      <Row>
        <Col>{planoTratamento && renderProcedimentos()}</Col>
      </Row>
    </div>
  )
}

const backContainerStyle = {
  cursor: 'pointer',
}

const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
  marginLeft: '10px',
}

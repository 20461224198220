import AxiosClient from './axios-client'

class AnamneseService {
  getRespostasById(id) {
    return AxiosClient.get(`Anamnese/getById/${id}`)
  }

  getRespostasByAgendamentoId(agendamentoId) {
    return AxiosClient.get(`Anamnese/getByAgendamento/${agendamentoId}`)
  }
}

export default new AnamneseService()

import DEV from './../enviroment/dev.env'
import QA from './../enviroment/qa.env'
import TEST from './../enviroment/test.env'
import PROD from './../enviroment/prod.env'

const SystemConstants = {
  BASE_CONTENT_TYPE: 'application/json',
  BASE_ACCEPT: 'application/json',
}

const config =
  process.env.REACT_APP_ENV === 'dev'
    ? DEV
    : process.env.REACT_APP_ENV === 'test'
    ? TEST
    : process.env.REACT_APP_ENV === 'qa'
    ? QA
    : PROD

class SystemConfig {
  static getTokenKey = () => config.TOKEN_KEY

  static getChatTokenKey = () => config.CHAT_TOKEN

  static getChatUserId = () => config.CHAT_USER_ID

  static getChatStatus = () => config.CHAT_STATUS

  static getUser = () => config.USER

  static getAtualizarAgenda = () => config.ATUALIZAR_AGENDA

  static getApiUrl = () => config.REACT_APP_API_URL

  static getContentType = () => SystemConstants.BASE_CONTENT_TYPE

  static getAccept = () => SystemConstants.BASE_ACCEPT

  static getWebSocketURL = () => config.WEBSOCKET_SERVER

  static getFranquia = () => config.FRANQUIA

  static getChatUrl = () => config.CHAT_URL
}

export default SystemConfig

import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Table, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import formatCnpj from '@brazilian-utils/format-cnpj'

import CollapsePanel from '../../Common/CollapsePanel'
import RadiologiaForm from './RadiologiaForm'
import PageLoading from '../../Common/PageLoading'
import OrtodontiaService from '../../../services/ortodontia.service'
import { enderecoToString } from '../../../utils/string.utils'
import MenuContext from '../../../contexts/MenuContexts'
import permissoesConstants from '../../../constants/permissoes.constants'

export default function ClinicaRadiologia() {
  const INITIAL_OPEN_STATE = { step0: false }
  const acoes = useContext(MenuContext)

  const [isLoading, setIsLoading] = useState(true)
  const [stepIsOpen, setStepIsOpen] = useState({ ...INITIAL_OPEN_STATE })
  const [clinicas, setClinicas] = useState([])
  const [clinicaEdit, setClinicaEdit] = useState(null)
  const [acoesPermitidas, setAcoesPermitidas] = useState({})

  useEffect(() => {
    getClinicasCadastradas()
  }, [])

  useEffect(() => {
    if (!acoes) {
      return
    }
    let permissoes = {}
    acoes.map((a) => {
      permissoes[`${a.nome}`] = a
      return true
    })
    setAcoesPermitidas(permissoes)
  }, [acoes])

  const getClinicasCadastradas = async () => {
    try {
      const { data } = await OrtodontiaService.getClinicasCadastradas()
      setClinicas(data)
    } catch (error) {}
    setIsLoading(false)
  }

  const toggleIsOpen = (step) => {
    let newSteps = { ...INITIAL_OPEN_STATE }
    setStepIsOpen({
      ...newSteps,
      [step]: !stepIsOpen[step],
    })
  }

  const handleEdit = (clinica) => {
    setClinicaEdit(clinica)
    setStepIsOpen({ step0: true })
  }

  const handleAddClinica = async (novaClinica, successCallback) => {
    setIsLoading(true)
    try {
      await OrtodontiaService.cadastrarClinica(novaClinica)
      toast(`Clínica cadastrada com sucesso`, { type: toast.TYPE.SUCCESS })
      await getClinicasCadastradas()
      setClinicaEdit(null)
      setStepIsOpen({ step0: false })
      successCallback()
    } catch (error) {}
    setIsLoading(false)
  }

  const handleToggleAtivo = async (clinica) => {
    setIsLoading(true)
    try {
      await OrtodontiaService.toggleAtivarClinica(clinica)
      await getClinicasCadastradas()
    } catch (e) {}
    setIsLoading(false)
  }

  const handleCancelEdit = () => {
    setClinicaEdit(null)
    setStepIsOpen({ step0: false })
  }

  const renderLoading = () => <PageLoading />

  return (
    <div>
      <h1 className='h3 m-0 mb-4 text-secondary font-weight-bold'>Clínicas Radiológicas</h1>
      {acoesPermitidas[permissoesConstants.ADICIONAR] && (
        <CollapsePanel stepCode='step0' text='Cadastrar clínica' isOpen={stepIsOpen.step0} toggleIsOpen={toggleIsOpen}>
          <RadiologiaForm
            isOpen={stepIsOpen.step0}
            handleAddClinica={handleAddClinica}
            handleCancelEdit={handleCancelEdit}
            clinicaEdit={clinicaEdit}
          />
        </CollapsePanel>
      )}

      {clinicas.length > 0 ? (
        <Row>
          <Col>
            <Table bordered hover className='bg-white'>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Endereço</th>
                  <th className='text-center' width='150px'>CNPJ</th>
                  <th className='text-center' width='120px'>Telefone</th>
                  <th width='110px' className='text-center'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {clinicas.map((c, index) => (
                  <tr style={{ fontSize: 'smaller' }} key={index}>
                    <td className='pl-2'>{c.nome}</td>
                    <td className='pl-2'>{enderecoToString(c.endereco)}</td>
                    <td className='text-center'>{formatCnpj(c.cnpj)}</td>
                    <td className='text-center'>{c.telefone}</td>
                    <td className='text-center justify-content-around'>
                      {acoesPermitidas[permissoesConstants.EDITAR] && (
                        <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Editar clínica</Tooltip>}>
                          <Button onClick={() => handleEdit(c)} variant='primary' size='sm' style={{marginRight:'10px'}}>
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                        </OverlayTrigger>
                      )}
                      {acoesPermitidas[permissoesConstants.ATIVAR_INATIVAR] && (
                        <OverlayTrigger
                          trigger='hover'
                          placement='top'
                          overlay={<Tooltip>{c.ativo ? 'Desativar clínica' : 'Ativar clínica'}</Tooltip>}
                        >
                          <Button
                            onClick={() => handleToggleAtivo(c)}
                            variant={c.ativo ? 'success' : 'danger'}
                            size='sm'
                          >
                            <FontAwesomeIcon icon={faPowerOff} />
                          </Button>
                        </OverlayTrigger>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      ) : (
        <div className='my-5'>Nenhuma clínica cadastrada</div>
      )}
      {isLoading && renderLoading()}
    </div>
  )
}

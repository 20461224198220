import React, { Fragment, useContext, useState, useEffect } from 'react'
import { Table, Button } from 'react-bootstrap'

import { formatMoney } from '../../../../utils/string.utils'
import SystemConstants from '../../../../constants/system.constants'

import MenuContext from '../../../../contexts/MenuContexts'
import { statusProcedimento, status, cargoConstant } from '../../../../constants/common.constants'
import permissoesConstants from '../../../../constants/permissoes.constants'

const Procedimentos = (props) => {
  let user = JSON.parse(localStorage.getItem(SystemConstants.getUser()))
  const isAdmin = user.tipoCargo  === cargoConstant.ADMINISTRADOR.value

  const { procedimentos, escolhidos, handleSaveStatus } = props
  const acoes = useContext(MenuContext)

  const [acoesPermitidas, setAcoesPermitidas] = useState({})

  useEffect(() => {
    if (!acoes) {
      return
    }
    let permissoes = {}
    acoes.map((a) => {
      permissoes[`${a.nome}`] = a
      return true
    })
    setAcoesPermitidas(permissoes)
  }, [acoes])


  return (
    <div>
      <Fragment>
        {procedimentos && procedimentos.length > 0 ? (
          <Table bordered hover size='sm' className='mt-3'>
            <thead>
              <tr>
                <th>Nome do Procedimento</th>
                <th className='text-center' width='60px'>
                  Dente
                </th>
                <th>Observação</th>
                <th className='text-center' width='120px'>
                  Valor
                </th>
                {escolhidos && (
                  <th className='text-center' width='100px'>
                    Status
                  </th>
                )}
                {escolhidos && acoesPermitidas[`${permissoesConstants.REABRIR_PROCEDIMENTO}`] && (
                  <th width='100px' className='text-center'>
                    Ações
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {procedimentos.map((procedimento, index) => (
                <Fragment key={'fragment' + index}>
                  <tr style={{ fontSize: 'smaller' }} key={'procedimento' + index}>
                    <td>{procedimento.procedimento.nome}</td>
                    <td className='text-center'>{procedimento.dente ? procedimento.dente.codigo : 'N/D'}</td>
                    <td>{procedimento.descricao}</td>                    
                    <td className='text-center'>
                      {formatMoney(
                        escolhidos && !procedimento.avaliacaoProcedimentoProtetico
                          ? procedimento.valor
                          : procedimento.valorCobrado
                      )}
                    </td>
                    {escolhidos && (
                      <td className='text-center' width='150px'>
                        {status.find((s) => s.value === procedimento.statusProcedimento).label}
                      </td>
                    )}
                    {escolhidos && acoesPermitidas[`${permissoesConstants.REABRIR_PROCEDIMENTO}`] && (
                      <td className='text-center'>
                        {isAdmin && procedimento.statusProcedimento === statusProcedimento.FINALIZADO.value && (
                          // <Button size='sm' onClick={() => handleSaveStatus(index)}>
                          <Button size='sm' onClick={() => alert('Botão temporáriamente desativado.')}>
                            Reabrir
                          </Button>
                        )}
                      </td>
                    )}
                  </tr>
                  {procedimento.planoTratamentoProcedimentoProtetico && (
                    <tr style={{ fontSize: 'smaller' }} key={'protetico1' + index}>
                      <td className='pl-3'>
                        <i className='fa fa-level-up-alt fa-rotate-90 mr-3 text-secondary' />
                        {`${procedimento.planoTratamentoProcedimentoProtetico.procedimentoProtetico.nome} `}
                      </td>
                      <td className='text-center'>{procedimento.dente ? procedimento.dente.codigo : 'N/D'}</td>
                      
                      <td className='align-items-center'></td>
                      <td className='text-center'>
                        {' '}
                        {formatMoney(procedimento.planoTratamentoProcedimentoProtetico.valor)}
                      </td>
                      <td />
                      {escolhidos && acoesPermitidas[`${permissoesConstants.REABRIR_PROCEDIMENTO}`] && <td />}
                    </tr>
                  )}
                  {procedimento.avaliacaoProcedimentoProtetico && (
                    <tr style={{ fontSize: 'smaller' }} key={'protetico2' + index}>
                      <td className='pl-3'>
                        <i className='fa fa-level-up-alt fa-rotate-90 mr-3 text-secondary' />
                        {`${procedimento.avaliacaoProcedimentoProtetico.procedimentoProtetico.nome} `}
                      </td>
                      <td className='text-center'>{procedimento.dente ? procedimento.dente.codigo : 'N/D'}</td>
                      
                      <td className='align-items-center'></td>
                      <td className='text-center'> {formatMoney(procedimento.avaliacaoProcedimentoProtetico.valor)}</td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </Table>
        ) : (
          <div>Nenhum procedimento selecionado.</div>
        )}
      </Fragment>
    </div>
  )
}

export default Procedimentos

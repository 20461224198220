import React, { Fragment, useState, useEffect } from 'react'
import { Table, Form, Row, Col, InputGroup, Container, Button } from 'react-bootstrap'
import Select from 'react-select'
import CurrencyInput from 'react-currency-input'
import { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ptBr from 'date-fns/locale/pt-BR'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'

import PlanoTratamentoService from '../../../../services/planoTratamento.service'
import { formatMoney, handleDecimalConvert } from '../../../../utils/string.utils'
import { parcelasSelect } from '../../../../constants/agendamento.constants'

registerLocale('pt-BR', ptBr)

const Financeiro = (props) => {
  const {
    selectedPlano,
    nextStep,
    setPagamento,
    onBack,
    totalPagamento,
    setTotal,
    isLoading,
    setIsLoading,
    parcelasEmAberto,
  } = props

  const [valorEntrada, setValorEntrada] = useState(selectedPlano.minEntrada)
  const [valorParcelamento] = useState(totalPagamento / parcelasSelect[0].value)
  const [parcelamentoSelecionado, setParcelamentoSelecionado] = useState(parcelasSelect[0])
  const [parcelas, setParcelas] = useState([])
  const [dataVencimento] = useState(moment())
  const [disabled, setDisabled] = useState(false)
  const [minEntrada] = useState(selectedPlano.minEntrada)


  useEffect(() => {
    calcularTotalParcelasEmAberto()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelasEmAberto])

  useEffect(() => {
    let valorParcelas = 0
    let diferencaPrimeiraParcela = 0
    const valorEntradaNumber = handleDecimalConvert(valorEntrada)
    if (valorEntradaNumber >= totalPagamento) {
      setValorEntrada(formatMoney(totalPagamento).substring(3))
      setParcelamento(parcelasSelect[0])
    } else {
      const { valorFinalParcelas, diferenca } = getValorParcelas(valorEntradaNumber, valorParcelas)
      valorParcelas = valorFinalParcelas
      diferencaPrimeiraParcela = diferenca
      if (valorEntradaNumber > 0 && parcelamentoSelecionado === parcelasSelect[0]) {
        setParcelamento(parcelasSelect[1])
      }
    }

    let parcelas = getParcelas(valorParcelas, diferencaPrimeiraParcela, valorEntradaNumber)
    setParcelas(parcelas)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valorEntrada, totalPagamento, parcelamentoSelecionado, dataVencimento])

  function getParcelas(valorParcelas, diferencaPrimeiraParcela, entrada) {
    let parcelas = []
    for (let index = 1; index < parcelamentoSelecionado.value; index++) {
      const valor = index === 1 ? +(valorParcelas + diferencaPrimeiraParcela).toFixed(2) : +valorParcelas.toFixed(2)
      parcelas.push({
        valor: +valor.toFixed(2),
        numero: index + 1,
        dataVencimento: moment(dataVencimento)
          .add(index, 'month')
          .toISOString(),
      })
    }
    if (entrada === 0) {
      parcelas.unshift({
        valor: +valorParcelas.toFixed(2),
        numero: 1,
        dataVencimento: moment(dataVencimento).toISOString(),
      })
    } else {
      parcelas.unshift({
        valor: entrada,
        numero: 1,
        dataVencimento: moment(dataVencimento).toISOString(),
      })
    }
    return parcelas
  }

  function getValorParcelas(valorEntradaNumber, valorParcelas) {
    let diferenca = 0
    let totalParcelas = parcelamentoSelecionado.value
    if (valorEntradaNumber > 0 && totalParcelas > 1) {
      totalParcelas = totalParcelas - 1
    }
    valorParcelas = (totalPagamento - valorEntradaNumber) / totalParcelas
    diferenca = totalPagamento - valorEntradaNumber - valorParcelas.toFixed(2) * totalParcelas
    return { valorFinalParcelas: valorParcelas, diferenca }
  }

  const calcularTotalParcelasEmAberto = () => {
    let soma = 0
    parcelasEmAberto.forEach((p) => {
      if (p.valorCalculado > p.valor) {
        soma += p.valorCalculado
      } else {
        soma += p.valor
      }
    })
    setTotal(soma)
  }

  const salvarRenegociacao = async () => {
    setIsLoading(true)
    if (disabled) {
      return
    }

    setDisabled(true)
    try {
      const pagamentoRenegociado = {
        valorTotal: Number(totalPagamento.toFixed(2)),
        valorEntrada: handleDecimalConvert(valorEntrada) || 0,
        planoTratamentoId: selectedPlano.id,
        dataVencimento: dataVencimento.toISOString(),
        numeroParcelas: parcelamentoSelecionado.value,
        parcelas,
      }
      setPagamento(pagamentoRenegociado)
      delete pagamentoRenegociado.valorEntrada
      await PlanoTratamentoService.salvarRenegociacao(parcelasEmAberto, pagamentoRenegociado)
      nextStep()
    } catch (e) { }
    setIsLoading(false)
    setDisabled(false)
  }

  const setEntrada = (valor) => {
    // Buscar minimo de entrada e validar, não deixar setar o menor valor.
    if (handleDecimalConvert(valor) < minEntrada)
      return toast(`Valor da entrada está abaixo do estipulado pela unidade, o valor minimo de entrada é: R$ ${selectedPlano.minEntrada}`, { type: toast.TYPE.WARNING });

    setValorEntrada(valor)
  }

  const setParcelamento = (parcelas) => setParcelamentoSelecionado(parcelas)

  return (
    <div className='d-flex'>
      <div className='position-absolute' onClick={onBack}>
        <Button variant='outline-primary' style={backStyle}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <span className='text-primary'>Voltar</span>
      </div>
      <Container className='pb-5' style={{ maxWidth: 750 }}>
        {selectedPlano.comParceiroFinanceiro &&
          <div className='alert alert-danger'>
            <b>Atenção</b> Este tratamento está com Parceiro, informar cliente para entrar em contato direto com nosso Parceiro Financeiro, não receber diretamente do cliente.
          </div>
        }
        {parcelasEmAberto.length > 0 ? (
          <Fragment>
            <h4 className='mb-4 text-secondary font-weight-bold'>Parcelas em Aberto</h4>
            <Table bordered hover className='mb-5'>
              <thead>
                <tr>
                  <th className='text-center' width='100px'>
                    Parcela
                  </th>
                  <th className='text-center'>Vencimento</th>
                  <th className='text-center'>Valor</th>
                </tr>
              </thead>
              <tbody>
                {parcelasEmAberto.map((p, index) => (
                  <tr key={index}>
                    <td className='text-center'>{p.numero}</td>
                    <td className='text-center'>{moment(p.dataVencimento).format(`DD/MM/YYYY`)}</td>
                    <td className={`text-center font-weight-bold ${p.valorCalculado > p.valor && 'text-danger'}`}>
                      {p.valorCalculado > p.valor ? formatMoney(p.valorCalculado) : formatMoney(p.valor)}
                    </td>
                  </tr>
                ))}
                <tr className='bg-secondary-03'>
                  <td className='text-center font-weight-bold'>Total:</td>
                  <td className='text-center font-weight-bold'></td>
                  <td className='text-center font-weight-bold'>{formatMoney(totalPagamento)}</td>
                </tr>
              </tbody>
            </Table>
          </Fragment>
        ) : (
          !isLoading && <h4 className='mb-4 text-secondary font-weight-bold'>Nenhuma parcela em aberto</h4>
        )}
        {parcelasEmAberto.length > 0 && (
          <Fragment>
            <h4 className='my-4 text-secondary font-weight-bold'>Condições de pagamento</h4>
            <Row>
              <Form.Group as={Col} clssName='col-md-5'>
                <Form.Label>Primeira parcela</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>R$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <CurrencyInput
                    className='form-control'
                    decimalSeparator=','
                    thousandSeparator='.'
                    minValue={1}
                    value={valorEntrada}
                    onChange={setEntrada}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} className='col-md-2'>
                <Form.Label>Parcelas</Form.Label>
                <Select value={parcelamentoSelecionado} options={parcelasSelect} onChange={setParcelamento} />
              </Form.Group>
              <Form.Group as={Col} className='col-md-5 required'>
                <Form.Label>Data da Primeira parcela</Form.Label>
                <input className='form-control' disabled={true} type='text' value={dataVencimento.format("DD/MM/YYYY")} />
              </Form.Group>
            </Row>
            {valorParcelamento <= totalPagamento && (
              <div>
                <h4 className='my-4 text-secondary font-weight-bold'>Resumo do Parcelamento</h4>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th className='text-center' width='100px'>
                        Parcela
                      </th>
                      <th className='text-center'>Vencimento</th>
                      <th className='text-center' width='250px'>
                        Valor
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {parcelas.map(({ numero, dataVencimento, valor }, index) => (
                      <tr key={index}>
                        <td className='text-center'>{numero}</td>
                        <td className='text-center'>
                          {dataVencimento ? moment(dataVencimento).format(`DD/MM/YYYY`) : 'Data Inválida'}
                        </td>
                        <td className='text-center'>{formatMoney(valor)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>


                {handleDecimalConvert(valorEntrada) >= minEntrada ?
                  <Button
                    className='mt-3 ml-auto d-block'
                    onClick={salvarRenegociacao}
                    variant='primary'
                    disabled={disabled}
                  >
                    {disabled ? 'Salvando..' : 'Salvar Renegociação'}
                  </Button>
                  :
                  <Button className='mt-3 ml-auto d-block' disabled={true} variant='primary'>
                    Salvar Renegociação
                  </Button>
                }


              </div>
            )}
          </Fragment>
        )}
      </Container>
    </div>
  )
}

export default Financeiro

const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

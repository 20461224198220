import React, { Fragment, useState, useEffect } from 'react'
import StepWizard from 'react-step-wizard'

import Parcelamento from './Parcelamento'
import Impressao from './Impressao'
import OrtodontiaService from '../../../../services/ortodontia.service'
import { handleDecimalConvert } from '../../../../utils/string.utils'

const INITIAL_PAGAMENTO = {
  valorTotal: 0.0,
  dataVencimento: new Date(),
  numeroParcelas: 1,
  planoTratamentoOrtodontiaId: '',
  parcelas: [
    {
      valor: '0.0',
      numero: 1,
      dataVencimento: new Date(),
      status: 0,
    },
  ],
}

export default function CancelarPlano({
  firstStep,
  setIsLoading,
  selectedPlano,
  refreshPlanosTratamentoOrtodontiaList,
}) {
  const [wizardRef, setWizardRef] = useState(null)
  const [pagamento, setPagamento] = useState({ ...INITIAL_PAGAMENTO })

  useEffect(() => {
    selectedPlano &&
      setPagamento({
        ...INITIAL_PAGAMENTO,
        planoTratamentoOrtodontiaId: selectedPlano.id,
      })
  }, [selectedPlano])

  const handleSave = async () => {
    setIsLoading(true)
    try {
      const pagmentoEfetuado = {
        ...pagamento,
        valorTotal: handleDecimalConvert(pagamento.valorTotal),
        creditoDevolvido: handleDecimalConvert(pagamento.creditoDevolvido),
      }
      setPagamento(pagmentoEfetuado)
      await OrtodontiaService.cancelarPlanoTratamento(pagmentoEfetuado)
      wizardRef.nextStep()
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleGoBack = () => {
    firstStep()
    wizardRef.firstStep()
    refreshPlanosTratamentoOrtodontiaList()
  }

  return (
    <Fragment>
      <StepWizard isLazyMount instance={setWizardRef}>
        <Parcelamento
          pagamento={pagamento}
          setPagamento={setPagamento}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
        />
        <Impressao selectedPlano={selectedPlano} pagamento={pagamento} handleGoBack={handleGoBack} />
      </StepWizard>
    </Fragment>
  )
}

import AxiosClient from './axios-client'
import { saveAs } from 'file-saver'

class AnexosService {
    getCategorias(planoId) {
        return AxiosClient.get(`anexo/categorias`)
    }

    getDocumentos(pacienteId) {
        return AxiosClient.get(`anexo/${pacienteId}`)
    }

    upload(payload) {
        return AxiosClient.post(`anexo/upload`, payload)
    }

    async download(anexoId, nomeAnexo) {
        try {
            const { data } = await AxiosClient.get(`anexo/${anexoId}/download`, { responseType: 'blob' })
            const file = new Blob([data])
            saveAs(file, nomeAnexo)
        } catch (error) {
            console.error(error)
        }
    }
}

export default new AnexosService()

import AxiosClient from './axios-client'
import SystemConfig from '../constants/system.constants'

class UsuarioService {
  /**
   * Usado para alterar a senha do usuário logado
   * @param {*} senhaAtual
   * @param {*} novaSenha
   */
  changePassword(senhaAtual, novaSenha) {
    const user = JSON.parse(localStorage.getItem(SystemConfig.getUser()))
    const request = {
      usuarioId: user.usuarioId,
      newPassword: novaSenha,
      oldPassword: senhaAtual,
      senhaProtetico: !!user.proteticoDTO, //ALTERAR PARA TIPO USUARIO
    }
    return AxiosClient.post('Usuario/trocarSenha', request)
  }

  /**
   * Efetua a recuperacao de senha.
   * Envia e-mail para o e-mail cadastrado.
   * Necessario CPF
   */
  async recover(data) {
    return await AxiosClient.anonymousPost(`Usuario/recuperarSenha`, data)
  }
}

export default new UsuarioService()

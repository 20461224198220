import React from 'react'
import { Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faDownload, faTimes } from '@fortawesome/free-solid-svg-icons'

import OrtodontiaService from '../../../services/ortodontia.service'
import FileUpload from '../../Common/FileUpload'
import { toast } from 'react-toastify'

export default function ArquivosRadiologia({
  previousStep,
  setSelectedAgendamento,
  selectedAgendamento,
  arquivos,
  setIsLoading,
  setShouldReload,
}) {
  const handleGoBack = () => {
    previousStep()
    setSelectedAgendamento()
  }

  const handleUpload = async (fileUploaded) => {
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append('Arquivo', fileUploaded)
      formData.append('AgendamentoRadiologiaId', selectedAgendamento.id)
      await OrtodontiaService.uploadRadiologia(formData)
      toast(`Arquivo enviado com sucesso!`, { type: toast.TYPE.SUCCESS })
      setShouldReload(true)
      previousStep()
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const download = async (nome) => {
    setIsLoading(true)
    try {
      await OrtodontiaService.downloadRadiologia(nome, selectedAgendamento.id)
      setShouldReload(true)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const remover = async (id) => {
    setIsLoading(true)
    try {
      await OrtodontiaService.deleteArquivoRadiologia(id)
      toast(`Arquivo removido com sucesso!`, { type: toast.TYPE.SUCCESS })
      setShouldReload(true)
      previousStep()
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const renderList = () => {
    return (
      <Table size='sm' bordered hover className='bg-white'>
        <thead>
          <tr>
            <th>Arquivo</th>
            <th className='text-center' width='150px'>
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          {arquivos.map((a, i) => {
            return (
              <tr key={i}>
                <td>{a.nome}</td>
                <td className='d-flex align-items-center justify-content-around'>
                  <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Baixar arquivo</Tooltip>}>
                    <Button onClick={() => download(a.nome)} size='sm'>
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Remover arquivo</Tooltip>}>
                    <Button variant='outline-danger' onClick={() => remover(a.id)} size='sm'>
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
  return (
    <div>
      <div className='my-4 d-flex align-items-center justify-content-between'>
        <div style={backContainerStyle} onClick={handleGoBack}>
          <Button variant='outline-primary' style={backStyle}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <span className='text-primary'>Voltar</span>
        </div>
        <FileUpload label='Enviar arquivo' onChange={handleUpload} />
      </div>
      {arquivos.length > 0 ? (
        renderList()
      ) : (
        <div className='alert alert-primary w-50'>Nenhum arquivo nos registros</div>
      )}
    </div>
  )
}

const backContainerStyle = {
  cursor: 'pointer',
  width: 'fit-content',
}

const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

import React from 'react'
import Collapse from '@material-ui/core/Collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

export default function CollapsePanel({ stepCode, text, isOpen, toggleIsOpen, children }) {
  return (
    <div className='shadow-sm' style={stepContainerStyle}>
      <div style={stepHeaderStyle} onClick={() => toggleIsOpen(stepCode)}>
        <h4 className='h5 mb-0 text-secondary font-weight-bold'>{text}</h4>
        <FontAwesomeIcon className='ml-auto text-secondary' icon={isOpen ? faChevronUp : faChevronDown} />
      </div>
      <Collapse in={isOpen}>{children}</Collapse>
    </div>
  )
}

//////////////////////////////////////////////////////////////////
////////////////////////////// STYLES ////////////////////////////
//////////////////////////////////////////////////////////////////
const marginSize = 1

const stepContainerStyle = {
  margin: `${marginSize}em 0`,
  padding: '1em 2em',
  background: 'white',
  borderRadius: '4px',
  border: '1px solid #DDDDDD',
}
const stepHeaderStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  cursor: 'pointer',
  padding: '10px 0',
  fontSize: '1.25em',
}

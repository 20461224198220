import React from 'react'
import { Col } from 'react-bootstrap'
import CountUp from 'react-countup'

export default function CaixaMaiorAtual({ faturamento }) {
  const { maiorMes, mesAtual } = faturamento
  return (
    <Col style={colStyle} className='mx-3 py-3'>
      <h3 className='m-0 mb-2 text-secondary font-weight-bold text-center'>Faturamento Mensal</h3>
      <CountUp end={maiorMes} duration={2} start={0} separator='.' decimals={2} delay={0} decimal=',' startOnMount>
        {({ countUpRef }) => {
          return (
            <div style={containerStyle}>
              <div className='text-secondary' style={labelStyle}>
                Maior Mês
              </div>
              <span className='text-secondary' style={valueStyle} ref={countUpRef} />
            </div>
          )
        }}
      </CountUp>
      <CountUp end={mesAtual} duration={2} start={0} separator='.' decimals={2} delay={0} decimal=',' startOnMount>
        {({ countUpRef }) => {
          return (
            <div style={containerStyle}>
              <div className={mesAtual < maiorMes ? 'text-danger' : ''} style={{ ...labelStyle, marginTop: 0 }}>
                Mês Atual:
              </div>
              <span
                className={mesAtual > maiorMes ? 'text-success' : 'text-danger'}
                style={valueStyle}
                ref={countUpRef}
              />
            </div>
          )
        }}
      </CountUp>
    </Col>
  )
}

const colStyle = {
  marginTop: '2em',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
}

const containerStyle = {
  width: 'max-content',
}

const labelStyle = {
  marginTop: '15px',
  marginBottom: '-15px',
}

const valueStyle = {
  fontSize: '3.5em',
  letterSpacing: '2px',
}

import React, { Component, Fragment } from 'react'
import { Row, Col, Button, Form, InputGroup } from 'react-bootstrap'
import CurrencyInput from 'react-currency-input'

// Material-UI components
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import MaterialSelect from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'

import { cargoConstant } from '../../constants/common.constants'
import PageLoading from '../Common/PageLoading'
import colaboradoresService from '../../services/colaboradores.service'
import procedimentosService from '../../services/procedimentos.service'
import { toast } from 'react-toastify'

export default class ProcedimentosForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      procedimento: {
        nome: '',
        descricao: '',
        valor: 0,
        ativo: true,
        procedimentoPadrao: false,
        procedimentosEspecialidades: [],
        isProtetico: false,
      },
      especialidades: [],
      proteticos: [],
      selectedProtetico: '',
    }
  }

  async componentDidMount() {
    try {
      const { procedimento } = this.props
      const proteticos = await this.getProteticos()
      const cargoDentista = await this.getCargoDentista()
      let especialidades = []
      if (cargoDentista) {
        especialidades = await this.requestEspecialidades(cargoDentista.id)
      }
      if (procedimento) {
        const { id, isProtetico } = procedimento
        let request
        if (isProtetico) {
          this.setState({
            procedimento: {
              ...procedimento,
            },
            isLoading: false,
          })
        } else {
          request = await procedimentosService.getProcedimentoById(id)
          this.setState({
            procedimento: {
              ...request.data,
              isProtetico: !!isProtetico,
              procedimentosEspecialidades: request.data.procedimentosEspecialidades.map((e) => {
                return e.especialidadeId
              }),
            },
            especialidades,
            proteticos,
            selectedProtetico: request.data.procedimentoProteticoId,
            isLoading: false,
          })
        }
      } else {
        this.setState({ ...this.state, especialidades, proteticos, isLoading: false })
      }
    } catch (e) {
      console.error(e)
    }
    this.setState({ ...this.state, isLoading: false })
  }

  getProteticos = async () => {
    try {
      let { data } = await procedimentosService.getProcedimentosProteticos(true)
      let proteticos = data.map((protetico) => ({
        label: protetico.nome,
        value: protetico.id,
      }))
      return proteticos
    } catch (e) {
      console.error(e)
    }
    return null
  }

  getCargoDentista = async () => {
    try {
      const { data } = await colaboradoresService.getAllCargos()
      const cargo = data.find((cargo) => cargo.tipo === cargoConstant.DENTISTA.value)
      return cargo
    } catch (e) {
      console.error(e)
    }
    return null
  }

  requestEspecialidades = async (cargoId) => {
    try {
      const { data } = await colaboradoresService.getEspecialidadesByCargo(cargoId)
      const especialidades = data.map((especialidade) => ({
        label: especialidade.nome,
        value: especialidade.id,
      }))
      return especialidades
    } catch (e) {}
    return []
  }

  salvarProcedimento = (e) => {
    e.preventDefault()
    this.setState({ ...this.state, isLoading: true }, async () => {
      const { procedimento, selectedProtetico } = this.state
      try {
        const { isProtetico, procedimentosEspecialidades } = procedimento
        if (isProtetico) {
          const procedimentoProtetico = {
            ...procedimento,
          }
          await procedimentosService.salvarProcedimentoProtetico(procedimentoProtetico)
        } else {
          procedimento.procedimentoProteticoId = selectedProtetico
          if (procedimentosEspecialidades.length < 1) {
            toast(`Ao menos uma especialidade deve ser selecionada`, { type: toast.TYPE.ERROR })
            return
          } else {
            const procedimentoSave = {
              ...procedimento,
              procedimentosEspecialidades: procedimentosEspecialidades.map((e) => ({
                especialidadeId: e,
              })),
            }
            delete procedimentoSave.procedimentoProtetico
            await procedimentosService.salvarProcedimento(procedimentoSave)
          }
        }
        this.props.resetarProcedimento()
        toast(`Procedimento ${isProtetico ? 'protético' : ''} salvo com sucesso`, { type: toast.TYPE.SUCCESS })
        this.props.history.push('/procedimentos')
      } catch (e) {
        console.error(e)
      } finally {
        this.setState({ ...this.state, isLoading: false })
      }
    })
  }

  onChange = (e) => {
    const { name, value } = e.target
    this.setState({ ...this.state, procedimento: { ...this.state.procedimento, [name]: value } })
  }

  onChangeValor = (e) => {
    this.setState({ ...this.state, procedimento: { ...this.state.procedimento, valor: e } })
  }

  onClose = () => {
    this.props.resetarProcedimento()
    this.props.history.push('/procedimentos')
  }

  onChangeEspecialidade = (e) => {
    this.setState({
      ...this.state,
      procedimento: {
        ...this.state.procedimento,
        procedimentosEspecialidades: e.target.value,
      },
    })
  }

  onChangeProteticos = (e) => {
    this.setState({
      ...this.state,
      selectedProtetico: e.target.value,
    })
  }

  setProcedimentoPadrao = ({ target }) => {
    this.setState({
      ...this.state,
      procedimento: { ...this.state.procedimento, procedimentoPadrao: target.checked },
      selectedProtetico: '',
    })
  }

  setIsProtetico = ({ target }) => {
    this.setState({
      ...this.state,
      procedimento: { ...this.state.procedimento, isProtetico: target.checked },
    })
  }

  renderLoading = () => <PageLoading />

  renderForm = () => {
    const { procedimento, especialidades, proteticos, selectedProtetico } = this.state
    const { nome, valor, procedimentosEspecialidades, isProtetico } = procedimento
    return (
      <Form className='required' onSubmit={this.salvarProcedimento}>
        <Row>
          <Form.Group as={Col} className='required mt-3 col-md-8'>
            <Form.Label>Nome do Procedimento: </Form.Label>
            <Form.Control
              required
              name='nome'
              type='text'
              placeholder='Ex: Extração'
              onChange={this.onChange}
              value={nome}
            />
          </Form.Group>
          {!isProtetico && (
            <Fragment>
              <Form.Group as={Col} className='required mt-3'>
                <Form.Label> Valor: </Form.Label>
                <InputGroup className='mb-3'>
                  <InputGroup.Prepend>
                    <InputGroup.Text>R$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <CurrencyInput
                    className='form-control'
                    name='renda'
                    required
                    decimalSeparator=','
                    thousandSeparator='.'
                    value={valor}
                    maxLength={10}
                    onChange={this.onChangeValor}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} className='required mt-3 col-md-4'>
                <Form.Label htmlFor='nome'>Especialidades</Form.Label>
                <FormControl style={formControlStyle}>
                  {especialidades.length > 0 && (
                    <MaterialSelect
                      multiple
                      className='bg-white'
                      value={procedimentosEspecialidades}
                      onChange={this.onChangeEspecialidade}
                      input={<Input />}
                      renderValue={(selected) =>
                        selected
                          .map((s) => {
                            const found = especialidades.find((e) => e.value === s)
                            return found ? found.label : ''
                          })
                          .join(', ')
                      }
                      MenuProps={MenuProps}
                    >
                      {especialidades.map((espec) => (
                        <MenuItem key={espec.value} value={espec.value}>
                          <Checkbox
                            className='text-primary'
                            checked={procedimentosEspecialidades.includes(espec.value)}
                          />
                          <ListItemText primary={espec.label} />
                        </MenuItem>
                      ))}
                    </MaterialSelect>
                  )}
                </FormControl>
              </Form.Group>
              {proteticos && proteticos.length > 0 && (
                <Form.Group as={Col} className='mt-3 col-md-4'>
                  {!procedimento.procedimentoPadrao && (
                    <Fragment>
                      <Form.Label htmlFor='nome'>Protético</Form.Label>
                      <FormControl style={formControlStyle}>
                        {proteticos.length > 0 && (
                          <MaterialSelect
                            className='bg-white'
                            value={selectedProtetico}
                            onChange={this.onChangeProteticos}
                            input={<Input />}
                            renderValue={(selected) => {
                              const found = proteticos.find((e) => e.value === selected)
                              return found ? found.label : ''
                            }}
                            MenuProps={MenuProps}
                          >
                            {proteticos.map((prot) => (
                              <MenuItem key={prot.value} value={prot.value}>
                                <Checkbox
                                  className='text-primary'
                                  checked={selectedProtetico && selectedProtetico === prot.value}
                                />
                                <ListItemText primary={prot.label} />
                              </MenuItem>
                            ))}
                          </MaterialSelect>
                        )}
                      </FormControl>
                    </Fragment>
                  )}
                </Form.Group>
              )}
              <Form.Group as={Col} className='mt-4 col-md-4 d-flex align-items-center'>
                <label className='checkbox mt-4'>
                  <input
                    type='checkbox'
                    checked={this.state.procedimento.procedimentoPadrao}
                    onChange={this.setProcedimentoPadrao}
                  />
                  <span className='d-flex align-items-center'>Procedimento padrão na avaliação</span>
                </label>
              </Form.Group>
            </Fragment>
          )}
        </Row>
        <Row>
          <Col className='mt-3 d-flex align-items-center justify-content-end'>
            <Button variant='outline-primary' onClick={this.onClose} className='mr-2'>
              Cancelar
            </Button>
            <Button type='submit' variant='primary'>
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }

  render() {
    return (
      <Fragment>
        <div className='d-flex justify-content-between align-items-center'>
          <h1 className='h3 m-0 text-secondary font-weight-bold'>
            {`${this.props.procedimento ? 'Editar' : 'Cadastrar'} procedimento`}
          </h1>
          {!this.props.procedimento && (
            <label className='checkbox mt-4'>
              <input type='checkbox' checked={this.state.procedimento.isProtetico} onChange={this.setIsProtetico} />
              <span className='d-flex align-items-center'>É um procedimento protético</span>
            </label>
          )}
        </div>
        {this.state.isLoading ? this.renderLoading() : this.renderForm()}
      </Fragment>
    )
  }
}
const formControlStyle = {
  width: '100%',
  borderRadius: '5px',
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

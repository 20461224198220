import { Account } from '../types'
import * as AgendamentosDentista from '../reducers/agendamentosDentista.reducer'
import SystemConstants from '../../constants/system.constants'

const initialState = {
  user: {
    accessToken: localStorage.getItem(SystemConstants.getTokenKey()),
    authenticated: !!localStorage.getItem(SystemConstants.getTokenKey()),
    colaboradorId:
      localStorage.getItem(SystemConstants.getUser()) &&
      JSON.parse(localStorage.getItem(SystemConstants.getUser())).colaboradorId,
    created: '',
    expiration: '',
    mesesAConfigurar: localStorage.getItem(SystemConstants.getAtualizarAgenda()) || [],
    message: '',
    proteticoDTO:
      localStorage.getItem(SystemConstants.getUser()) &&
      JSON.parse(localStorage.getItem(SystemConstants.getUser())).proteticoDTO,
    procedimentosProteticos:
      localStorage.getItem(SystemConstants.getUser()) &&
      JSON.parse(localStorage.getItem(SystemConstants.getUser())).procedimentosProteticos,
    name:
      localStorage.getItem(SystemConstants.getUser()) &&
      JSON.parse(localStorage.getItem(SystemConstants.getUser())).name,    
    tipoUsuario: localStorage.getItem(SystemConstants.getUser())
    ? JSON.parse(localStorage.getItem(SystemConstants.getUser())).tipoUsuario
    : '',

  },
  loading: false,
}

export const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case Account.SET_USER:
      return {
        ...state,
        user: action.user,
      }
    case AgendamentosDentista.PROXIMO_BLOQUEIO:
      let update = { ...state }
      localStorage.removeItem(SystemConstants.getAtualizarAgenda())
      update.user.mesesAConfigurar = []
      return {
        ...update,
      }
    case Account.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case Account.LOGOUT:
      return {
        user: { ...initialState, authenticated: false },
      }
    default:
      return state
  }
}

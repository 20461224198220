import React from 'react'
import { Table, Col } from 'react-bootstrap'
import { formatMoney } from '../../../../utils/string.utils'

export default function TicketMedioColaborador({ ticketMedioColaborador }) {
  const contemValores = ticketMedioColaborador.length > 0
  return (
    <Col className='mx-3 py-3 shadow border'>
      <div className='d-flex flex-column align-items-center'>
        <h3 className='m-0 mb-4 text-secondary font-weight-bold'>Ticket médio por profissional</h3>
      </div>
      {contemValores ? (
        <Table size='sm' bordered hover>
          <thead>
            <tr className='text-center'>
              <th>Profissional</th>
              <th>Índice de Aceitação</th>
              <th>Ticket Médio</th>
              <th>Valor Procedimentos</th>
              <th>Percentual Concluídos e Cancelados</th>
              <th>Média de Produtividade Diária</th>
              <th>Estimativa de Conclusão da Carteira</th>
              <th>Pacientes Ativos</th>
              <th>Procedimentos a Realizar</th>
              <th>Média Parcelamento</th>
            </tr>
          </thead>
          <tbody>
            {ticketMedioColaborador.map((avaliacao, index) => {
              const {
                nome,
                aceitacao,
                ticket,
                valorProcedimentos,
                concluidos,
                cancelados,
                produtividade,
                estimativaConclusao,
                pacientesAtivos,
                procedimentosPendentes,
                mediaParcelamento,
              } = avaliacao
              return (
                <tr style={{ fontSize: 'small' }} className='text-center' key={index}>
                  <td>{nome}</td>
                  <td>{aceitacao}%</td>
                  <td>{formatMoney(ticket)}</td>
                  <td>{formatMoney(valorProcedimentos)}</td>
                  <td>
                    <p className='m-0'>{concluidos}%</p>
                    <p className='m-0'>{cancelados}%</p>
                  </td>
                  <td>{produtividade}</td>
                  <td>{estimativaConclusao}</td>
                  <td>{pacientesAtivos}</td>
                  <td>{procedimentosPendentes}</td>
                  <td>{mediaParcelamento}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <p className='alert alert-warning'>Sua franquia ainda não possui registros para este relatório</p>
      )}
    </Col>
  )
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Tratamento from '../../components/Tratamento'

const mapStateToProps = (store) => ({
  agendamento: store.avaliacaoState.agendamento,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Tratamento)

import React, { useEffect, useState, Fragment } from 'react'
import { Table } from 'react-bootstrap'

import PageLoading from '../Common/PageLoading'
import ColaboradoresServices from '../../services/colaboradores.service.js'

export default function ListaDentista(props) {
  const { nextStep, setDentista } = props

  const [isDentistasLoading, setIsLoading] = useState(false)
  const [dentistas, setDentistas] = useState([])

  useEffect(() => {
    fetchDentistas()
  }, [])

  const fetchDentistas = async () => {
    setIsLoading(true)
    try {
      const data = await ColaboradoresServices.getDentistas()
      setDentistas(data)
    } catch (error) {}
    setIsLoading(false)
  }

  const handleSetDentista = (id) => {
    setDentista(id)
    nextStep()
  }

  const renderLista = () => (
    <Fragment>
      <h3 className='text-secondary font-weight-bold'>Colaboradores</h3>
      {dentistas.length > 0 ? (
        <Table bordered hover className='bg-white'>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Ativo</th>
              <th>Configurou Agenda</th>
            </tr>
          </thead>
          <tbody>
            {dentistas.map((colaborador, index) => (
              <tr onClick={() => handleSetDentista(colaborador.id)} key={index}>
                <td>{colaborador.nome}</td>
                <td>{colaborador.ativo ? 'Sim' : 'Não'}</td>
                <td>{!colaborador.acessoBloqueado ? 'Sim' : 'Não'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div>Nenhum colaborador Cadastrado.</div>
      )}
    </Fragment>
  )

  const renderLoading = () => <PageLoading />

  return isDentistasLoading ? renderLoading() : renderLista()
}

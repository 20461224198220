import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Form, Button, Card } from 'react-bootstrap'
import 'react-dual-listbox/lib/react-dual-listbox.css'
import 'moment/locale/pt-br'
import Select from 'react-select'
import { respostasAnamnese } from '../../constants/anamnese.constants'
import { toast } from 'react-toastify'

class AnamneseForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pergunta: '',
      obrigatorio: false,
      campoTexto: false,
      ativo: true,
      selectedTipoResposta: null,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.anamnese.id !== state.id) {
      let { anamnese } = props
      anamnese.selectedTipoResposta = respostasAnamnese.filter((r) => r.value === anamnese.tipoResposta)
      return { ...anamnese }
    }
    return null
  }

  onChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  onSubmit = (e) => {
    e.preventDefault()
    let data = { ...this.state }
    data.tipoResposta = data.selectedTipoResposta ? data.selectedTipoResposta.value : null
    if (!data.campoTexto && data.tipoResposta === null) {
      toast('Você deve escolher um tipo de resposta ou marcar como resposta textual', { type: toast.TYPE.ERROR })
    } else {
      try {
        this.props.salvarAnamnese(data)
        this.props.history.push('/anamnese')
      } catch (error) {}
    }
  }

  setTipoResposta = (selectedTipoResposta) => {
    this.setState({ selectedTipoResposta })
  }

  setObrigatorio = (e) => {
    this.setState({
      obrigatorio: e.target.checked,
    })
  }

  setCampoTexto = (e) => {
    this.setState({
      campoTexto: e.target.checked,
    })
  }

  render() {
    const { selectedTipoResposta } = this.state
    return (
      <Form className='required' onSubmit={this.onSubmit}>
        <Form.Label>
          <h1 className='h3 m-0 text-secondary font-weight-bold'>Anamnese</h1>
        </Form.Label>
        <Card className='p-4 mb-3'>
          <Row>
            <Col className='mt-3'>
              <Form.Group className='required'>
                <Form.Label> Pergunta </Form.Label>
                <Form.Control
                  id='pergunta'
                  name='pergunta'
                  type='text'
                  required
                  placeholder='Ex: Alérgico a algum medicamento ?'
                  value={this.state.pergunta}
                  onChange={this.onChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Form.Group as={Col} className='mt-3 required'>
              <Form.Label>Tipo da resposta</Form.Label>
              <Select
                placeholder='Escolher o tipo de resposta'
                onChange={this.setTipoResposta}
                value={selectedTipoResposta}
                options={respostasAnamnese}
              />
            </Form.Group>
            <Col className='d-flex justify-content-center pt-2'>
              <label className='checkbox mt-4'>
                <input type='checkbox' checked={this.state.obrigatorio} onChange={this.setObrigatorio} />
                <span className='d-flex align-items-center'>Obrigatório</span>
              </label>
            </Col>
            <Col className='d-flex justify-content-center pt-2'>
              <label className='checkbox mt-4'>
                <input type='checkbox' checked={this.state.campoTexto} onChange={this.setCampoTexto} />
                <span className='d-flex align-items-center'>Campo de texto</span>
              </label>
            </Col>
          </Row>
          <Row>
            <Col className='mt-3 d-flex align-items-center justify-content-end'>
              <Link to='/anamnese' className='btn btn-secondary mr-4'>
                Cancelar
              </Link>
              <Button type='submit' variant='primary'>
                Salvar
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    )
  }
}

export default AnamneseForm

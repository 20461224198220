import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";

import SystemConstants from "../../constants/system.constants";
import JwtUtils from "../../utils/jwt.utils";
import errorsService from "../errors.service";

axios.defaults.baseURL = SystemConstants.getApiUrl();
axios.defaults.headers.post["Content-Type"] = SystemConstants.getContentType();
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Accept"] = SystemConstants.getAccept();

class AxiosClient {
  /**
   * Verifica se o token esta expirado
   */
  isTokenExpired() {
    return JwtUtils.isTokenExpired(
      localStorage.getItem(SystemConstants.getTokenKey())
    );
  }

  /**
   * Usado para deslogar da aplicacao
   */
  logout() {
    localStorage.removeItem(SystemConstants.getTokenKey());
    localStorage.removeItem(SystemConstants.getTokenKey());
    localStorage.removeItem(SystemConstants.getUser());
    localStorage.removeItem(SystemConstants.getFranquia());
    localStorage.removeItem(SystemConstants.getChatStatus());
    localStorage.removeItem(SystemConstants.getChatUserId());
    localStorage.removeItem(SystemConstants.getChatTokenKey());
    localStorage.removeItem(SystemConstants.getAtualizarAgenda());
    window.location.href = '/login?status=sessionexpired'
  }

  /**
   * Verifica erros do backend, se houver problema de autenticacao
   * Apresenta modal com informativo e 'desloga' o usuario
   * @param {*} error
   * @param {*} reject
   */
  onBackendError(error, reject) {
    if (error && error.response && error.response.status === 401) {
      //Forbidden
      this.logout();
    } else {
      errorsService.showErrorToast(error);
    }
    reject(error);
  }

  /**
   * Retorna um objeto com a configuracao do request
   * @param {*} conf
   */
  _getConfig(conf) {
    let config = conf ? conf : {};
    config.headers = config.headers ? config.headers : {};
    return config;
  }

  /**
   * Verifica se token expirou
   * Se expirou realiza o refresh do token
   * @param {*} conf
   */
  _validateToken(conf) {
    return new Promise((resolve, reject) => {
      let config = this._getConfig(conf);
      config.headers.Authorization =
        "Bearer " + localStorage.getItem(SystemConstants.getTokenKey());
      if (this.isTokenExpired()) {
        axios.put("Account/refreshToken/" + localStorage.getItem(SystemConstants.getFranquia()), undefined, config)
          .then(resp => {
            if (resp.data.token) {
              localStorage.setItem(SystemConstants.getTokenKey(), resp.data.token.accessToken);
              localStorage.setItem(SystemConstants.getUser(), JSON.stringify(resp.data.token))
              config.headers.Authorization = "Bearer " + resp.data.token.accessToken;
              resolve(config);
            } else {
              this.logout();
              reject(null);
            }
          }, error => {
            reject(error)
            this.logout()
          })
      } else {
        resolve(config);
      }
    });
  }

  /**
   * Execute a rest get
   * @param {string} endpoint
   */
  get(endpoint, conf) {
    return new Promise((resolve, reject) => {
      this._validateToken(conf).then(
        config => {
          axios
            .get(endpoint, config)
            .then(resolve, error => this.onBackendError(error, reject));
        },
        error => this.onBackendError(error, reject)
      );
    });
  }

  /**
   * Execute a post rest
   * @param {string} endpoint
   * @param {object} payload
   */
  post(endpoint, payload, config = {}) {
    return new Promise((resolve, reject) => {
      this._validateToken(config).then(
        config => {
          axios
            .post(endpoint, payload, config)
            .then(resolve, error => this.onBackendError(error, reject));
        },
        error => this.onBackendError(error, reject)
      );
    });
  }

  /**
   * Execute a put rest
   * @param {string} endpoint
   * @param {object} payload
   */
  put(endpoint, payload, config = {}) {
    return new Promise((resolve, reject) => {
      this._validateToken(config).then(
        config => {
          axios
            .put(endpoint, payload, config)
            .then(resolve, error => this.onBackendError(error, reject));
        },
        error => this.onBackendError(error, reject)
      );
    });
  }

  /**
   * Execute a delete
   * @param {string} endpoint
   * @param {object} params
   */
  delete(endpoint, params) {
    return new Promise((resolve, reject) => {
      this._validateToken(params).then(
        config => {
          axios
            .delete(endpoint, config)
            .then(resolve, error => this.onBackendError(error, reject));
        },
        error => this.onBackendError(error, reject)
      );
    });
  }

  /**
   * Execute a path request
   * @param {string} endpoint
   * @param {object} body
   */
  patch(endpoint, body) {
    return new Promise((resolve, reject) => {
      this._validateToken().then(
        config => {
          axios
            .patch(endpoint, body, config)
            .then(resolve, error => this.onBackendError(error, reject));
        },
        error => this.onBackendError(error, reject)
      );
    });
  }
}

export default new AxiosClient();

import React, { Fragment, useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { status, statusProteses, statusPlanoTratamento } from '../../../constants/common.constants'
import { formatMoney } from '../../../utils/string.utils'
import Moment from 'moment'


export default function VisualizarProcedimentos({ selectedPlano, firstStep }) {
  const [nomePaciente, setNomePaciente] = useState('')
  const [statusPlano, setStatusPlano] = useState('')

  useEffect(() => {
    if (selectedPlano) {
      const currentStatus = statusPlanoTratamento.find((s) => selectedPlano.statusTratamento === s.value)

      setNomePaciente(selectedPlano.dependente ? selectedPlano.dependente.nome : selectedPlano.paciente.nome)
      setStatusPlano(currentStatus ? currentStatus.label : 'Pendente')
    }
  }, [selectedPlano])

  return selectedPlano ? (
    <div className='m-3'>
      <h3 className='m-0 text-secondary font-weight-bold'>Plano de Tratamento {selectedPlano.numeroPlano}</h3>
      <div className='mt-4 mb-2 text-secondary'>
        <strong>Paciente:</strong> <span className='text-dark'>{nomePaciente}</span>
      </div>
      <div className='mt-2 mb-4 text-secondary'>
        <strong>Status do Tratamento: </strong>
        <span className='text-dark'>{statusPlano}</span>
      </div>
      {selectedPlano.procedimentos && selectedPlano.procedimentos.length > 0 && (
        <Table bordered hover>
          <thead>
            <tr className='text-center'>
              <th>Procedimento</th>
              <th>Observação</th>
              <th width='80px'>Dente</th>
              <th>Responsável</th>
              <th width='180px'>Status</th>
              <th width='180px'>Data de finalização</th>
              <th width='150px'>Valor</th>
            </tr>
          </thead>
          <tbody>
            {selectedPlano.procedimentos.map((p) => {
              const {
                procedimento,
                planoTratamentoProcedimentoProtetico: procProtetico,
                responsavelProcedimento,
                dente,
                finalizados,
              } = p
              const statusAtualProcedimento = status.find((s) => s.value === p.statusProcedimento)
              let statusAtualProtetico
              if (procProtetico) {
                statusAtualProtetico = statusProteses.find((s) => s.value === procProtetico.statusProcedimento)
              }
              return (
                <Fragment>
                  <tr className='text-center'>
                    <td>{procedimento ? procedimento.nome : 'Procedimento'}</td>
                    <td>{p.descricao || procedimento.descricao || '-'}</td>
                    <td>{dente ? dente.codigo : 'N/D'}</td>
                    <td>{responsavelProcedimento ? responsavelProcedimento.nome : '-'}</td>
                    <td>{statusAtualProcedimento ? statusAtualProcedimento.label : 'Pendente'}</td>
                    {finalizados && finalizados.length > 0 ? (
                      <td>
                        <p className='m-0'>{Moment(finalizados[finalizados.length - 1].dataCriacao).format('DD/MM/YYYY')}</p>
                        <p className='m-0'>{Moment(finalizados[finalizados.length - 1].dataCriacao).format('HH:mm')}</p>
                      </td>
                    ) : (
                      <td>-</td>
                    )}
                    <td>{formatMoney(p.valor)}</td>
                  </tr>
                  {procProtetico && (
                    <tr>
                      <td>
                        <i className='fa fa-level-up-alt fa-rotate-90 mr-3 text-secondary' />
                        {procProtetico.procedimentoProtetico
                          ? procProtetico.procedimentoProtetico.nome
                          : 'Procedimento Protético'}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className='text-center'>{statusAtualProtetico ? statusAtualProtetico.label : 'Pendente'}</td>
                      <td></td>
                      <td className='text-center'>{formatMoney(procProtetico.valor)}</td>
                    </tr>
                  )}
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      )}
      <div style={backContainerStyle}>
        <div onClick={firstStep}>
          <Button variant='outline-primary' style={backStyle}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <span className='text-primary'>Voltar</span>
        </div>
      </div>
    </div>
  ) : (
    <Fragment></Fragment>
  )
}

//////////////////////////////////////////////////////////////////
///////////////////////////// STYLES /////////////////////////////
//////////////////////////////////////////////////////////////////
const backContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '1.5em 0',
}
const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

import React, { useState, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { initServer } from '../websocketServer'
import Routes from '../router/Routes'

import { TIPO_USUARIO } from '../constants/usuario.constants'

const NavigatorWrapper = (props) => {
  const dispatch = useDispatch()

  const tipoUsuario = props.tipoUsuario

  let { menus, authenticated, colaboradorId } = props

  const [menusList, setMenusList] = useState([])

  const newMenuItem = (id, caminho, nome) => {
    var retorno = {
      id,
      dataCriacao: new Date(),
      caminho,
      icone: 'fa-circle',
      nome,
      mostrar: 1,
      permissao: null,
      sequencial: 99,
      menuPaild: null,
      regra: 0,
      tipoMenu: 0,
      Agrupamento:0
    }

    return retorno;
  }

  useEffect(() => {
    let menusSidebar = menus.filter((menu) => menu.mostrar)

    if(tipoUsuario === TIPO_USUARIO.Protetico){
      setMenusList([
        //newMenuItem('5F01C3F2-24B6-4F3B-48E7-08D80EEC8E6C', '/protetico/editar',  'Edição'),
        newMenuItem('5F01C3F2-24B6-4F3B-48E7-08D80EEC8E6C', '/protetico/tabelapreco',  'Tabela Preços'),
        newMenuItem('5F01C3F2-24B6-4F3B-48E7-08D80EEC8E6B', '/financeiro/repasseprotetico',  'Repasse Protético')
      ])
    }
    else {
      setMenusList(menusSidebar)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menus])

  useEffect(() => {
    if (authenticated) {
      initServer(colaboradorId, dispatch)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, colaboradorId])

  return (
    <BrowserRouter basename='/'>
      <Routes {...props} menus={menusList} />
    </BrowserRouter>
  )
}

export default NavigatorWrapper

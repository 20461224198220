import React, { Fragment, useState } from 'react'
import StepWizard from 'react-step-wizard'

import BloqueioDentista from './BloqueioDentista'
import ListaDentista from './ListaDentista'
import PageLoading from '../Common/PageLoading'

import { cargoConstant } from '../../constants/common.constants'
import SystemConstants from '../../constants/system.constants'

export default function BloqueioDentistaWizard(props) {
  const [selectedDentista, setDentista] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  let user = JSON.parse(localStorage.getItem(SystemConstants.getUser()))
  const isAdmin = user.tipoCargo  === cargoConstant.ADMINISTRADOR.value
  
  return (
    <Fragment>
      <Fragment>
        {isAdmin ? (
          <StepWizard isLazyMount>
            <ListaDentista setDentista={setDentista} {...props} />
            <BloqueioDentista
              isAdmin={isAdmin}
              selectedDentista={selectedDentista}
              setIsLoading={setIsLoading}
              {...props}
            />
          </StepWizard>
        ) : (
          <BloqueioDentista {...props} setIsLoading={setIsLoading} />
        )}
        {props.isLoading && <PageLoading />}
      </Fragment>
      {isLoading && <PageLoading />}
    </Fragment>
  )
}

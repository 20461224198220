export const LISTAR_PROCEDIMENTOS = 'LISTAR_PROCEDIMENTOS'
export const REQUEST_PROCEDIMENTOS = 'REQUEST_PROCEDIMENTOS'
export const LISTAR_DENTES = 'LISTAR_DENTES'
export const REQUEST_DENTES = 'REQUEST_DENTES'
export const SALVAR_PROCEDIMENTO = 'SALVAR_PROCEDIMENTO'
export const EDITAR_PROCEDIMENTO = 'EDITAR_PROCEDIMENTO'
export const GET_PROCEDIMENTO_ID = 'GET_PROCEDIMENTO_ID'
export const SET_PROCEDIMENTO_ID = 'SET_PROCEDIMENTO_ID'
export const INATIVAR_PROCEDIMENTO = 'INATIVAR_PROCEDIMENTO'
export const RESETAR_PROCEDIMENTO = 'RESETAR_PROCEDIMENTO'

import { Colaboradores } from '../types'

const initialState = {
  cargos: [],
  colaboradores: [],
  dentistas: [],
  especialidades: [],
  selectedColaborador: null,
  isLoading: false,
}

export const colaboradoresReducer = (state = initialState, action) => {
  switch (action.type) {
    case Colaboradores.LISTAR_COLABORADORES:
      return {
        ...state,
        colaboradores: action.colaboradores,
      }
    case Colaboradores.LISTAR_DENTISTAS:
      return {
        ...state,
        dentistas: action.dentistas,
      }
    case Colaboradores.SET_COLABORADOR_ID:
      return {
        ...state,
        selectedColaborador: action.colaboradorId,
      }
    case Colaboradores.RESET_COLABORADOR_ID:
      return {
        ...state,
        selectedColaborador: null,
      }
    case Colaboradores.LISTAR_CARGOS:
      return {
        ...state,
        cargos: action.cargos,
      }
    case Colaboradores.LISTAR_ESPECIALIDADES:
      return {
        ...state,
        especialidades: action.especialidades,
      }
    case Colaboradores.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    default:
      return state
  }
}

import React, { useState, useEffect, Fragment, useContext } from 'react'
import formatCPF from '@brazilian-utils/format-cpf'
import { format, startOfMonth } from 'date-fns'
import Select from 'react-select'
import { formatMoney } from '../../utils/string.utils'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'

import RelatorioService from '../../services/relatorio.service'
import PageLoading from '../Common/PageLoading'
import { Badge, Table, Button, Spinner, Row, Col, OverlayTrigger, Tooltip, Card, Form } from 'react-bootstrap'
import Paginator from '../Common/Paginator'
import { statusPlanoTratamento } from '../../constants/common.constants'
import CSVDownload from './CSVDownload'
import colaboradoresService from '../../services/colaboradores.service'
import Swal from 'sweetalert2'
import MenuContext from '../../contexts/MenuContexts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

export default function PacientesNegativados({ selectedRelatorio }) {
  const itensPorPagina = 20
  const acoes = useContext(MenuContext)
  const [isLoading, setLoading] = useState(false)
  const [isLoadingCSV, setLoadingCSV] = useState(false)
  const [itensLista, setItensLista] = useState([])
  const [cabecalhoRelatorio, setCabecalhoRelatorio] = useState([])
  const [feedbackBusca, setFeedbackBusca] = useState('')
  const [pagina, setPagina] = useState(0)
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [itensCSV, setItensCSV] = useState([])
  const [downloadCSV, setDownloadCSV] = useState(false)
  const [selectedEspecialidade, setSelectedEspecialidade] = useState(null)
  const [selectedNegativado, setSelectedNegativado] = useState(null)
  const [especialidadeOptions, setEspecialidadeOptions] = useState([])
  const [negativadoOptions, setNegativadoOptions] = useState([])
  const [filterLoading, setFilterLoading] = useState(false)
  const [resumo, setResumo] = useState([])
  const [acoesPermitidas, setAcoesPermitidas] = useState({})
  const [isOpen, setIsOpen] = useState('')
  const [isOpenHistoricoTelefone, setIsOpenHistoricoTelefone] = useState('')
  const [dateFilterAte, setDateFilterAte] = useState(new Date())
  const [dateFilterDe, setDateFilterDe] = useState(startOfMonth(new Date()))

  useEffect(() => {
    didMount()
    setCabecalhoRelatorio([
      'Nome',
      'CPF',
      'Telefone Principal',
      'Whats App',
      'Nº Plano',
      'Status',
      'Parcela p/ desnegativar',
      'Vencimento',
      'Valor Parcela',
      'Data da Operação',
      'Responsavel Operação',
      'Negativado'
    ])
  }, [])

  useEffect(() => {
    if (!acoes) {
      return
    }
    let permissoes = {}
    acoes.map((a) => {
      permissoes[`${a.nome}`] = a
      return true
    })
    setAcoesPermitidas(permissoes)
  }, [acoes])

  useEffect(() => {
    handleSearch()
    setIsOpen('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina])

  useEffect(() => {
    if (downloadCSV) {
      setTimeout(() => {
        setDownloadCSV(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itensCSV, downloadCSV])

  const didMount = async () => {
    setFilterLoading(true)

    const { data } = await colaboradoresService.getAllEspecialidades()
    const especialidades = [{ label: 'Todos', value: '00000000-0000-0000-0000-0000000000' }]

    data.map(({ nome, id }) => especialidades.push({
      label: nome,
      value: id,
    }))

    setEspecialidadeOptions(especialidades)
    setSelectedEspecialidade([{ label: 'Todos', value: '00000000-0000-0000-0000-0000000000' }])

    setNegativadoOptions(
      [
        { value: '-1', label: 'Todos' },
        { value: '1', label: 'Sim' },
        { value: '0', label: 'Não' }
      ]
    )
    setSelectedNegativado({ label: 'Não', value: '0' })

    handleSearch()
    setFilterLoading(false)
  }

  const getValuesLista = async () => {
    const totalDaLista = itensPorPagina * totalPaginas
    if (totalDaLista === 0) {
      return
    }
    const filtros = []
    if (selectedEspecialidade) {
      filtros.push({ campo: 'especialidadeId', valor: selectedEspecialidade.value })
    }
    filtros.push({ campo: 'marcado', valor: selectedNegativado ? (selectedNegativado.value == '-1' ? '' : selectedNegativado.value) : '0' })

    filtros.push({ campo: 'tipoNegativacao', valor: "0" })

    setLoadingCSV(true)
    const { lista, resumo } = await RelatorioService.getRegistrosNegativados(filtros, 1, totalDaLista)

    const dados = lista.dados
    setResumo(resumo)

    const valores = []

    dados.forEach((item) => {
      if (valores.filter(valorJaAdicionado => valorJaAdicionado[1] === formatCPF(item.cpf)).length > 0)
        return;

      const itemRelatorio = relatorioItem(item)

      if (item.telefones && item.telefones.split(',').filter(t => t.telefonePrincipal).length > 0) {
        const telefone = item.telefones.split(',').find(t => t.telefonePrincipal);
        itemRelatorio.telefonePrincipal = telefone;
      }

      valores.push(Object.values(itemRelatorio))

    })

    setItensCSV(valores)
    setDownloadCSV(true)
    setLoadingCSV(false)
    return {
      data: valores,
      headers: cabecalhoRelatorio,
      filename: `${selectedRelatorio.label} - ${format(new Date(), 'dd-MM-yyyy')}.csv`,
    }
  }

  const relatorioItem = (item) => {
    const statusPlano = statusPlanoTratamento.find((s) => s.value === item.status)
    const itemRelatorio = new Object()
    itemRelatorio.nome = item.nome
    itemRelatorio.cpf = formatCPF(item.cpf)
    itemRelatorio.telefonePrincipal = item.telefonePrincipal
    itemRelatorio.telefonesWhatsApp = item.telefones && item.telefones.split(',') ? item.telefones.split(',').filter(t => !t.telefonePrincipal) : null;
    itemRelatorio.numeroPlano = item.numeroPlano
    statusPlano ? (itemRelatorio.statusTratamento = statusPlano.label) : (itemRelatorio.statusTratamento = '-')
    itemRelatorio.parcela = item.parcela
    itemRelatorio.dataVencimento = format(
      new Date(item.dataVencimento),
      'dd/MM/yyyy'
    )
    itemRelatorio.valorParcela = formatMoney(item.valorParcela)
    itemRelatorio.dataOperacao = (item.dataOperacao ? format(
      new Date(item.dataOperacao),
      'dd/MM/yyyy'
    ) : '-')
    itemRelatorio.colaborador = item.colaborador || '-'
    itemRelatorio.negativado = item.negativado ? 'Sim' : 'Não'

    return itemRelatorio
  }

  const handleSearch = async () => {
    setLoading(true)
    try {
      const filtros = []
      if (selectedEspecialidade) {
        filtros.push({ campo: 'especialidadeId', valor: selectedEspecialidade.value })
      }


      if (selectedNegativado.value === '1') {
        dateFilterDe && filtros.push({ campo: 'dataInicial', valor: dateFilterDe })
        dateFilterAte && filtros.push({ campo: 'dataFinal', valor: dateFilterAte })
      }

      filtros.push({ campo: 'tipoNegativacao', valor: "0" })
      filtros.push({ campo: 'marcado', valor: selectedNegativado ? (selectedNegativado.value == '-1' ? '' : selectedNegativado.value) : '0' })

      setFeedbackBusca('')
      const { lista, resumo } = await RelatorioService.getRegistrosNegativados(
        filtros,
        pagina + 1,
        itensPorPagina
      )

      const dados = lista.dados

      setItensLista(dados)
      setTotalPaginas(lista.totalPaginas)

      setResumo(resumo)

      if (dados.length === 0) {
        setFeedbackBusca('Nenhum registro encontrado')
      }
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  const handleClear = () => {
    setPagina(0)
    setDateFilterDe(null)
    setDateFilterAte(null)
    setSelectedEspecialidade([{ label: 'Todos', value: '00000000-0000-0000-0000-0000000000' }])
    setSelectedNegativado({ label: 'Não', value: '0' })
  }

  const handleChangeEspecialidade = (option) => {
    setSelectedEspecialidade(option)
  }

  const handleChangeNegativado = (option) => {
    setSelectedNegativado(option)
  }

  const handlePagina = async (data) => setPagina(data.selected)

  const onChangeBoolean = async (index, e) => {

    if (!e.target.checked && !acoesPermitidas.DesmarcarNegativacao) {
      Swal.fire({
        title: 'Operação não autorizada',
        text: 'Não é possível realizar essa operação. Por favor, entre em contato com administrador do sistema.',
        type: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      }).then(async (result) => {
        return;
      })

      return;
    }

    const itens = itensLista
    const checked = !e.target.checked

    let novoItem = { ...itens[index] }
    delete novoItem.id
    delete novoItem.dataCriacao

    let mensagem = !checked ? "Ao marcar essa opção você está confirmando que já foi realizada a operação junto ao SPC. Deseja confirmar?" : "Ao desmarcar essa opção você está confirmando que a operação não foi realizada junto ao SPC. Deseja confirmar?"

    Swal.fire({
      title: 'Confirmar Operação',
      text: mensagem,
      type: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#dbdbdb',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirmar',
    }).then(async (result) => {
      if (result.value === true) {
        novoItem.negativado = !checked

        await RelatorioService.atualizarHistoricoNegativacao(novoItem)

        Swal.fire(
          'Confirmado',
          'Operação confirmada com sucesso.',
          'success'
        ).then(async () => {
          await handleSearch()
        })
      }
    })
  }


  const handleOpen = (item) => {
    if (isOpenHistoricoTelefone === item)
      setIsOpenHistoricoTelefone('')
    else
      setIsOpenHistoricoTelefone(item)

    isOpen === item ? setIsOpen('') : setIsOpen(item)
  }

  const handleDateFilterDe = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterDe(filter)
    } else {
      setDateFilterDe(null)
    }
    setPagina(0)
  }

  const handleDateFilterAte = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterAte(filter)
    } else {
      setDateFilterAte(null)
    }
    setPagina(0)
  }

  const renderItens = () =>
    itensLista.length > 0 ? (
      <Fragment>
        <div className='mt-4 d-flex justify-content-end align-items-center'>
          <CSVDownload asyncExportMethod={getValuesLista}>
            <Button variant='outline-secondary' className='mb-3'>
              {isLoadingCSV ? <Spinner animation='border' size='sm' variant='light' /> : 'Gerar CSV'}
            </Button>
          </CSVDownload>
        </div>

        <Card className='mb-4 py-2'>
          <Row>
            {resumo?.map(({ descricao, valor }, index) => (
              <Col key={index} className='d-flex align-items-center justify-content-center flex-column'>
                <small className='text-center text-muted m-0'>{!!descricao ? descricao.toUpperCase() : '-'}</small>
                <h4 className='text-center m-0 font-weight-bold'>{valor || '-'}</h4>
              </Col>
            ))}
          </Row>
        </Card>

        <Table hover size='sm' className='border rounded-0 shadow-sm' style={{ backgroundColor: 'white' }}>
          <thead>
            <tr>
              <th width="150px">Nome</th>
              <th width="100px" class="text-center">CPF</th>
              <th width="150px" class="text-left">Telefone Principal</th>
              <th width="70px" class="text-center">Nº Plano</th>
              <th width="90px" class="text-center">Status</th>
              <th width="60px" class="text-center">Parcelas em atraso</th>
              <th width="90px" class="text-center">Vencimento</th>
              <th width="100px" class="text-center">Valor Parcela</th>
              <th width="90px" class="text-center">Data Operação</th>
              <th width="120px" class="text-center">Responsável Operação</th>
              <th width="80px" class="text-center">Negativado</th></tr>
          </thead>
          <tbody>
            {itensLista.map((item, i) => {
              const {
                idHistoricoNegativacao,
                nome,
                cpf,
                telefonePrincipal,
                numeroPlano,
                status,
                parcela,
                dataVencimento,
                valorParcela,
                dataOperacao,
                colaborador,
                negativado,
                telefones
              } = item
              const statusPlano = statusPlanoTratamento.find((s) => s.value === status)

              const quantidadeTelefones = telefones ? telefones.split(',').length : 0

              const expand = i === isOpen

              return (
                <Fragment key={i}>
                  <tr key={i} style={{ fontSize: 'smaller' }}>
                    <td>{nome.toUpperCase()}</td>
                    <td className='text-center'>{formatCPF(cpf)}</td>
                    <td className='text-left'>{telefonePrincipal}
                      {telefones && (
                        <Badge pill variant="primary" style={{ padding: '5px 10px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => handleOpen(i)}> + {quantidadeTelefones}  <FontAwesomeIcon style={{ marginLeft: '8px' }} icon={isOpenHistoricoTelefone === i ? faChevronUp : faChevronDown} /></Badge>
                      )}</td>
                    <td className='text-center'>{numeroPlano}</td>
                    <td className='text-center'>{statusPlano ? statusPlano.label : '-'}</td>
                    <td className='text-center'>{parcela}</td>
                    <td className='text-center'>{format(new Date(dataVencimento), 'dd/MM/yyyy') || '-'}</td>
                    <td className='text-center'>{valorParcela ? formatMoney(valorParcela) : '-'}</td>
                    <td className='text-center'>{dataOperacao ? format(new Date(dataOperacao), 'dd/MM/yyyy') : '-'}</td>
                    <td className='text-center'>{colaborador || '-'}</td>
                    <td className='text-center'>
                      <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>{negativado ? `Desmarcar como negativado no SPC` : `Marcar como negativado no SPC`}</Tooltip>}>
                        <label className='checkbox labeless m-0'>
                          <input
                            type='checkbox'
                            checked={negativado}
                            onChange={(e) => onChangeBoolean(i, e)}
                          />
                          <span />
                        </label>
                      </OverlayTrigger>
                    </td>
                  </tr>

                  {expand && (

                    telefones.split(',').map((x) => {
                      return (
                        <tr className={'text-center' + expand ? null : 'd-none'} style={{ fontSize: 'smaller' }}>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none', paddingLeft: '30px', fontSize: '11px' }} className='text-left'>{x}</td>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none' }}></td>
                        </tr>
                      )
                    })

                  )}
                </Fragment>
              )
            })}
          </tbody>
        </Table>
        {totalPaginas > 1 && <Paginator handlePagina={handlePagina} pageCount={totalPaginas} paginaAtual={pagina} />}
      </Fragment>
    ) : (
      <div className='mt-4'>{feedbackBusca}</div>
    )

  const renderLoading = () => <PageLoading />

  return (
    <Fragment>
      <Row className='mb-2'>
        <Col md={selectedNegativado?.value == '1' ? '3' : '6'}>
          <label>Negativado:</label>
          <Select
            placeholder='Negativado'
            className='w-100 mr-1'
            onChange={handleChangeNegativado}
            value={selectedNegativado}
            options={negativadoOptions}
          />
        </Col>
        <Col md={selectedNegativado?.value == '1' ? '3' : '6'}>
          <label>Especialidade:</label>
          <Select
            placeholder='Especialidade'
            className='w-100 mr-1'
            onChange={handleChangeEspecialidade}
            value={selectedEspecialidade}
            options={especialidadeOptions}
          />
        </Col>

        {selectedNegativado?.value == '1' &&
          <>
            <Col md='3'>
              <Form.Group>
                <Form.Label>Data Inicial</Form.Label>
                <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                  <KeyboardDatePicker
                    invalidDateMessage='Formato de data inválido'
                    value={dateFilterDe}
                    onChange={handleDateFilterDe}
                    className='form-control'
                    variant='inline'
                    format='DD/MM/YYYY'
                    minDate={moment(dateFilterAte).add(-90, 'days')}
                    maxDate={dateFilterAte || moment()}
                    animateYearScrolling
                    autoOk
                    placeholder='De'
                  />
                </MuiPickersUtilsProvider>
              </Form.Group>
            </Col>

            <Col md='3'>
              <Form.Group>
                <Form.Label>Data Final</Form.Label>
                <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                  <KeyboardDatePicker
                    invalidDateMessage='Formato de data inválido'
                    value={dateFilterAte}
                    onChange={handleDateFilterAte}
                    minDate={dateFilterDe || moment()}
                    maxDate={moment()}
                    className='form-control'
                    variant='inline'
                    format='DD/MM/YYYY'
                    animateYearScrolling
                    autoOk
                    placeholder='Até'
                  />
                </MuiPickersUtilsProvider>
              </Form.Group>
            </Col>
          </>
        }
      </Row>
      <Row className='mb-2'>
        <Col className='text-right'>
          {filterLoading && <Spinner animation='border' variant='primary' size='sm' style={spinnerStyle} />}
          <Button disabled={filterLoading} onClick={handleClear} variant='outline-danger' className='mr-2'>
            Limpar
          </Button>
          {filterLoading && <Spinner animation='border' variant='primary' size='sm' />}
          <Button disabled={filterLoading} onClick={handleSearch} variant='primary'>
            Pesquisar
          </Button>
        </Col>
      </Row>
      {isLoading ? renderLoading() : renderItens()}
    </Fragment>
  )
}

const spinnerStyle = {
  position: 'absolute',
  top: '10px',
  right: '50px',
}
import React, { useState, useEffect } from 'react'
import StepWizard from 'react-step-wizard'

import FinanceiroService from '../../../services/financeiro.service'
import FranquiaService from '../../../services/franquias.service'
import AvaliacaoService from '../../../services/atendimento.service'
import PlanoTratamentoService from '../../../services/planoTratamento.service'
import PageLoading from '../../Common/PageLoading'
import PlanosPaciente from './PlanosPaciente'
import AlterarProtetico from './AlterarProtetico'
import AlterarVencimento from './AlterarVencimento'
import RealizarPagamento from './RealizarPagamento'
import Renegociacao from './Renegociacao'
import RenegociacaoProtese from './RenegociacaoProtese'
import CancelarProcedimento from './CancelarProcedimento'
import CancelarPagamento from './CancelarPagamento'
import VisualizarAvaliacao from './VisualizarAvaliacao'
import VisualizarProcedimentos from './VisualizarProcedimentos'
import VisualizarParcelas from './VisualizarParcelas'
import { statusProcedimento } from '../../../constants/common.constants'
import Swal from 'sweetalert2'

export default function PacientePlanosTratamento({
  nomeColaborador,
  planosTratamentoList,
  paciente,
  getPlanosTratamentoList,
  getPagamentosRealizados,
}) {
  const passos = {
    PLANOS: 1,
    VENCIMENTO: 2,
    PAGAR: 3,
    RENEGOCIAR: 4,
    CANCELAR_PROC: 5,
    CANCELAR_PAG: 6,
    VISUALIZAR_AVAL: 7,
    ALTERAR_PROT: 8,
    VISUALIZAR_PROCED: 9,
    VISUALIZAR_PARC: 10,
    RENEGOCIAR_PROT: 11,
  }

  const [isLoading, setIsLoading] = useState(false)
  const [franquia, setFranquia] = useState({})
  const [stepWizardRef, setWizardRef] = useState()
  const [selectedPlano, setPlano] = useState(null)
  const [parcelas, setParcelas] = useState([])
  const [parcelasProteticas, setParcelasProteticas] = useState([])
  const [selectedAvaliacao, setSelectedAvaliacao] = useState(null)
  const [parcelasPlano, setparcelasPlano] = useState(null)
  const [passoAtual, setPassoAtual] = useState(null)

  useEffect(() => {
    getFranquia()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlano])

  const getFranquia = async () => {
    try {
      const { data } = await FranquiaService.getFranquia(selectedPlano.franquiaId)
      setFranquia(data)
    } catch (error) {}
  }

  const handleStepChange = async (p) => {
    if (p.activeStep === passos.PLANOS) {
      setIsLoading(true)
      await getPlanosTratamentoList()
      await getPagamentosRealizados()
      setPlano(null)
      setIsLoading(false)
    }
  }

  const onChangeStep = async (plano, passo) => {
    setPassoAtual(passo)
    setIsLoading(true)
    setPlano(plano)
    let canGoToNextStep = true
    let mensagem = ''
    let response
    try {
      switch (passo) {
        case passos.RENEGOCIAR:
        case passos.RENEGOCIAR_PROT:
        case passos.VENCIMENTO:
          response = await PlanoTratamentoService.getParcelasEmAberto(
            plano.id,
            passo === passos.RENEGOCIAR || passo === passos.RENEGOCIAR_PROT,
            passo === passos.VENCIMENTO || passo === passos.RENEGOCIAR_PROT
          )
          if (response.data.parcelas) {
            setParcelas(response.data.parcelas)
            setParcelasProteticas(response.data.parcelasProteticas)
          } else {
            setParcelas(response.data)
          }
          break
        case passos.PAGAR:
          response = await FinanceiroService.getParcelasEmAberto(plano.id)
          setParcelas(response.data.pagamentoParcelas)
          break
        case passos.VISUALIZAR_AVAL:
          response = await getAvaliacaoPlano(plano)
          setSelectedAvaliacao(response)
          break
        case passos.VISUALIZAR_PROCED:
          setPlano(plano)
          break
        case passos.VISUALIZAR_PARC:
          setPlano(plano)
          response = await PlanoTratamentoService.getParcelasPlano(plano.id)
          setparcelasPlano(response.data)
          break
        case passos.CANCELAR_PROC:
          canGoToNextStep =
            plano.statusTratamento === statusProcedimento.CANCELADO.value ||
            plano.statusTratamento === statusProcedimento.CANCELADO_AUTOMATICAMENTE.value
              ? false
              : true
          mensagem = 'Todos os procedimentos já foram cancelados.'
        default:
          break
      }
      if (canGoToNextStep) {
        stepWizardRef.goToStep(passo)
      } else {
        setIsLoading(false)
        const { value } = await Swal.fire({
          title: mensagem,
          type: 'warning',
          confirmButtonColor: '#3085d6',
        })
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const getAvaliacaoPlano = async (planoTratamento) => {
    const { data } = await AvaliacaoService.getAvaliacaoById(planoTratamento.avaliacaoId)
    return data
  }

  const getParcelasPagamento = async () => {
    setIsLoading(true)
    try {
      const { data } = await FinanceiroService.getParcelasEmAberto(selectedPlano.id)
      setParcelas(data.pagamentoParcelas)
      setIsLoading(false)
      return data
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
    return []
  }

  const renderLoading = () => <PageLoading />

  return (
    <div className='my-4'>
      {isLoading && renderLoading()}
      <StepWizard isLazyMount onStepChange={handleStepChange} instance={setWizardRef}>
        <PlanosPaciente passos={passos} onChangeStep={onChangeStep} planosTratamentoList={planosTratamentoList} />
        <AlterarVencimento
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          planoTratamento={selectedPlano}
          parcelasOriginais={parcelas}
          parcelasProteticasOriginais={parcelasProteticas}
        />
        <RealizarPagamento
          franquia={franquia}
          nomeColaborador={nomeColaborador}
          parcelas={parcelas}
          paciente={paciente}
          getParcelas={getParcelasPagamento}
          setIsLoading={setIsLoading}
          planoTratamento={selectedPlano}
        />
        <Renegociacao
          selectedPaciente={paciente}
          selectedPlano={selectedPlano}
          setIsLoading={setIsLoading}
          franquia={franquia}
          parcelas={parcelas}
          passos={passos}
        />
        
        <CancelarProcedimento
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          planoTratamento={selectedPlano}
          franquia={franquia}

        />
        <CancelarPagamento
          selectedPlano={selectedPlano}
          nomeColaborador={nomeColaborador}
          paciente={paciente}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          passos={passos}
        />
        <VisualizarAvaliacao avaliacao={selectedAvaliacao} planoTratamento={selectedPlano} />
        <AlterarProtetico
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          planoTratamento={selectedPlano}
          franquia={franquia}
        />
        <VisualizarProcedimentos selectedPlano={selectedPlano} />
        <VisualizarParcelas parcelasPlano={parcelasPlano} selectedPlano={selectedPlano} />
        {parcelasProteticas.length > 0 &&
        <RenegociacaoProtese
          selectedPaciente={paciente}
          selectedPlano={selectedPlano}
          setIsLoading={setIsLoading}
          franquia={franquia}
          passos={passos}
          parcelasProteticas={parcelasProteticas}
          onChangeStep={onChangeStep}
          passoAtual={passoAtual}
        />
        }
      </StepWizard>
    </div>
  )
}


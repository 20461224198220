import AxiosClient from './axios-client'

class PerfilService {
	getAcoesByMenuId(menuId) {
		return AxiosClient.get(`Perfil/getAcoes/${menuId}`)
	}

	getFuncionalidades() {
		return AxiosClient.get('Perfil/getFuncionalidades')
	}

	getPerfis() {
		return AxiosClient.get('Perfil/getAll')
	}

	getPerfilById(perfilId) {
		return AxiosClient.get(`Perfil/getById/${perfilId}`)
	}

	salvarPerfil(perfil) {
		return AxiosClient.post('Perfil/salvar', perfil)
	}
}

export default new PerfilService()

import React, { useEffect, useState, Fragment, useContext } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { Table, Row, Col, OverlayTrigger, Tooltip, Card, Spinner, Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'

import { format, compareAsc } from 'date-fns'
import FinanceiroService from '../../../services/financeiro.service'
import { formatMoney } from '../../../utils/string.utils'
import Select from 'react-select'
import Paginator from '../../../components/Common/Paginator'
import MenuContext from '../../../contexts/MenuContexts'
import proteticoService from '../../../services/protetico.service'
import PageLoading from '../../../components/Common/PageLoading'
import SystemConstants from '../../../constants/system.constants'
import { TIPO_USUARIO } from '../../../constants/usuario.constants'

const parcelasEmAbertoOptions = [
  { value: 2, label: 'Todas' },
  { value: 0, label: 'Em aberto' },
  { value: 1, label: 'Pagas' },
]

export default function RepasseProtetico(props) {
  const porPagina = 18
  const [itensLista, setItensLista] = useState([])
  const [dateFilterDe, setDateFilterDe] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  const [dateFilterAte, setDateFilterAte] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [pagina, setPagina] = useState(0)
  const [selectedParcelasEmAberto, setSelectedParcelasEmAberto] = useState(parcelasEmAbertoOptions[0])
  const [filterProtetico, setfilterProtetico] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [acoesPermitidas, setAcoesPermitidas] = useState({})
  const [proteticosOptions, setProteticosOptions] = useState([])
  const [resumo, setResumo] = useState([])
  const acoes = useContext(MenuContext)
  const [isProtetico, setIsProtetico] = useState()

  useEffect(() => {
    didMount()
  }, [])

  useEffect(() => {
    // getFirstDayCurrentMonth()
    // getLastDayCurrentMonth()
    getResumo()
  }, [pagina])

  useEffect(() => {
    if (!acoes) {
      return
    }
    let permissoes = {}
    acoes.map((a) => {
      permissoes[`${a.nome}`] = a
      return true
    })
    setAcoesPermitidas(permissoes)
  }, [acoes])

  const didMount = async () => {
    setIsLoading(true)
    getFirstDayCurrentMonth()
    getLastDayCurrentMonth()
    
    let data = await getProteticos()

    setProteticosOptions([
      { label: 'Todos', value: null },
      ...data.map((protetico) => ({
        label: protetico.nomeFantasia,
        value: protetico.id,
      })),
    ])
    setfilterProtetico({label: 'Todos', value: null})

    setFiltrosSeUsuarioForProtetico()
    getResumo()
    setIsLoading(false)
  }

  const getFirstDayCurrentMonth = () =>{
    const today = new Date()
    setDateFilterDe(new Date(today.getFullYear(), today.getMonth(), 1))
  }

  const getLastDayCurrentMonth = () =>{
    const today = new Date()
    setDateFilterAte(new Date(today.getFullYear(), today.getMonth() + 1, 0))
  }

  const getProteticos = async () => {
    try {
      let { data } = await proteticoService.getAllProteticos()
      
      return data

    } catch (error) {
      console.error(error)
    }
  }

  const handlePagina = (data) => setPagina(data.selected)

  const getResumo = async () => {
    !isLoading && setIsLoading(true)

    if(!dateFilterDe)
      getFirstDayCurrentMonth()
    if(!dateFilterAte)
      getLastDayCurrentMonth()

    let filtros = [
      {
        campo: 'dataInicial',
        valor: dateFilterDe,
      },
      {
        campo: 'dataFinal',
        valor: dateFilterAte,
      },
      {
        campo: 'parcelasEmAberto',
        valor: selectedParcelasEmAberto.value,
      },
    ]

    if(filterProtetico){
      filtros.push({
        campo: 'proteticoId',
        valor: filterProtetico.value,
      })
    }

    const { dados, totalPaginas, resumo } = await FinanceiroService.getRegistrosDebitosProtetico(
      filtros,
      pagina + 1,
      porPagina
    )

    setItensLista(dados)
    setResumo(resumo)
    setTotalPaginas(totalPaginas)
    setIsLoading(false)
  }

  const handleDateFilterDe = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterDe(filter)
      setPagina(0)
    } else {
      setDateFilterDe(null)
      setPagina(0)
    }
  }

  const handleDateFilterAte = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterAte(filter)
      setPagina(0)
    } else {
      setDateFilterAte(null)
      setPagina(0)
    }
  }

  const handleFilterProtetico = (colaboradorId) => {
    if (colaboradorId) {
      setfilterProtetico(colaboradorId)
      setPagina(0)
    } else {
      setfilterProtetico(null)
      setPagina(0)
    }
  }

  const handleParcelasEmAbertoChange = (option) => {
    setSelectedParcelasEmAberto(option)
    setPagina(0)
  }

  const handleStatusChange = async (item) => {
    try {
      const status = !item.repassePagamentoProtetico
      const frase = `Deseja mesmo mudar o status deste pagamento para ${status === true ? 'Pago' : 'Pendente'}?`
      const { value } = await Swal.fire({
        title: frase,
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#dbdbdb',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Confirmar',
      })
      setIsLoading(true)
      if (value) {
        await FinanceiroService.confirmarPagamentoProtetico(item.id, status)
        await getResumo()
      }
      toast(`Status alterado com sucesso!`, { type: toast.TYPE.SUCCESS })
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const handleClear = async (e) => {
    setSelectedParcelasEmAberto(parcelasEmAbertoOptions[0])
    setfilterProtetico(proteticosOptions[0])
    getFirstDayCurrentMonth()
    getLastDayCurrentMonth()
    
    setFiltrosSeUsuarioForProtetico()
  }

  const handleSearch = async (e) => {
    setFiltrosSeUsuarioForProtetico()

    getResumo()
  }

  const setFiltrosSeUsuarioForProtetico = () => {
    const user = JSON.parse(localStorage.getItem(SystemConstants.getUser()))

    if(user.tipoUsuario === TIPO_USUARIO.Protetico){
      setIsProtetico(true)
      if (proteticosOptions && proteticosOptions.length > 0) {
        const protetico = proteticosOptions.find(x => x.label === user.nomeFantasia)
        setfilterProtetico(protetico)
      }
      if (parcelasEmAbertoOptions && parcelasEmAbertoOptions.length > 0) {
        setSelectedParcelasEmAberto({value: 0, label: 'Em aberto'})
      }
    }
  }

  const renderLista = () => {
    return (
      <Fragment>
        <h1 className='h3 m-0 text-secondary font-weight-bold'>Repasse Protético</h1>
        <Row className='my-4'>
          <Col md='2'>
            <label>Data de Finalização de:</label>
            <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
              <KeyboardDatePicker
                invalidDateMessage='Formato de data inválido'
                value={dateFilterDe}
                onChange={handleDateFilterDe}
                className='form-control'
                variant='inline'
                format='DD/MM/YYYY'
                animateYearScrolling
                autoOk
                placeholder='De'
                maxDate={dateFilterAte || new Date('1900-01-01')}
                maxDateMessage={'A data deve ser menor que a "Data de Finalização até"' }
              />
            </MuiPickersUtilsProvider>
          </Col>
          <Col md='2'>
            <label>Data de Finalização até:</label>
            <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
              <KeyboardDatePicker
                invalidDateMessage='Formato de data inválido'
                value={dateFilterAte}
                onChange={handleDateFilterAte}
                minDate={dateFilterDe || new Date('1900-01-01')}
                className='form-control'
                variant='inline'
                format='DD/MM/YYYY'
                animateYearScrolling
                autoOk
                placeholder='Até'
                minDateMessage={'A data deve ser maior que a "Data de Finalização de"'}
              />
            </MuiPickersUtilsProvider>
          </Col>
          {!isProtetico && (
          <Col md='2'>
            <label>Status da Parcela:</label>
            <Select
              placeholder='Mostrar parcelas em aberto?'
              value={selectedParcelasEmAberto}
              options={parcelasEmAbertoOptions}
              onChange={handleParcelasEmAbertoChange}
              noOptionsMessage={() => 'Nenhuma opção'}
            />
          </Col>
          )}
          {!isProtetico && (
          <Col md='2'>
            <label>Protético:</label>
            <Select
              placeholder='Filtrar por Protetico'
              value={filterProtetico}
              options={proteticosOptions}
              onChange={handleFilterProtetico}
              noOptionsMessage={() => 'Nenhuma opção disponível'}
            />
          </Col>
          )}
          <Col md='2'>
          {isLoading && <Spinner animation='border' variant='primary' size='sm' style={spinnerStyle} />}
          <Button disabled={isLoading} onClick={handleClear} variant='outline-danger' className='mr-2' style={buttonClearAlign}>
            Limpar
          </Button>
          <Button variant='primary' style={buttonSearchAlign} onClick={handleSearch}>
              {isLoading ? <Spinner animation='border' size='sm' variant='light' /> : 'Pesquisar'}
          </Button>
        </Col>
        </Row>
        {itensLista && itensLista.length > 0 ? (
          <div>
            <Card className='mb-4 py-2'>
              <Row>
                {resumo &&
                  resumo.map(({ descricao, valor }, index) => (
                    <Col key={index} className='d-flex align-items-center justify-content-center flex-column'>
                      <small className='text-center text-muted m-0'>
                        {!!descricao ? descricao.toUpperCase() : '-'}
                      </small>
                      <h4 className='text-center m-0 font-weight-bold'>
                        {!!valor ? formatMoney(valor) : formatMoney(0)}
                      </h4>
                    </Col>
                  ))}
              </Row>
            </Card>
            <Table size='sm' bordered striped className='border rounded-0 shadow-sm'  style={{backgroundColor:'white'}}>
              <thead>
                <tr className='text-center'>
                  <th width='120px'>Plano</th>
                  <th>Paciente</th>
                  <th>Procedimento</th>
                  <th>Protético</th>
                  <th width='120px'>Valor</th>
                  {!isProtetico && (
                    <th width='120px'>Data Finalização</th>
                  )}
                  {!isProtetico && (
                  <th width='120px' className='text-center'>
                    Conta Paga
                  </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {itensLista.map((item, i) => {
                  const {
                    nomePaciente,
                    numeroPlano,
                    nomeProcedimento,
                    nomeProtetico,
                    valorOriginal,
                    dataFinalizacao,
                    repassePagamentoProtetico,
                  } = item
                  return (
                    <tr className='text-center' style={{ fontSize: 'smaller', height:'45px' }} key={i}>
                      <td>{numeroPlano}</td>
                      <td>{nomePaciente}</td>
                      <td>{nomeProcedimento.toUpperCase()}</td>
                      <td>{nomeProtetico ? nomeProtetico.toUpperCase() : '-'}</td>
                      <td>{formatMoney(valorOriginal)}</td>
                      {!isProtetico && (
                      <td>{!!dataFinalizacao ? format(new Date(dataFinalizacao), 'dd/MM/yyyy') : '-'}</td>
                      )}
                      {!isProtetico && (
                      <td className='text-center'>
                        <OverlayTrigger
                          trigger='hover'
                          placement='top'
                          overlay={<Tooltip>{`Marcar como pago`}</Tooltip>}
                        >
                          <label className='checkbox labeless m-0'>
                            <input
                              type='checkbox'
                              checked={repassePagamentoProtetico}
                              onChange={() => handleStatusChange(item)}
                            />
                            <span/>
                          </label>
                        </OverlayTrigger>
                      </td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div>Nenhum repasse até o momento</div>
        )}
        {totalPaginas > 1 && <Paginator handlePagina={handlePagina} pageCount={totalPaginas} paginaAtual={pagina} />}
      </Fragment>
    )
  }

  if (isLoading) {
    return <PageLoading />
  }

  return renderLista()
}

const buttonClearAlign = {
  position: 'absolute',
  bottom: '0',
  height: '38px'
}
const buttonSearchAlign = {
  position: 'absolute',
  bottom: '0',
  left: '100px',
  height: '38px'
}

const spinnerStyle = {
  position: 'absolute',
  top: '10px',
  right: '50px',
}

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, Modal, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import MaskedInput from 'react-text-mask'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import colaboradoresService from '../../services/colaboradores.service'
import isValidCep from '@brazilian-utils/is-valid-cep'
import SharedService from './../../services/shared.service'
import { onlyNumbers } from '@brazilian-utils/helper-only-numbers'
import { estadoCivilOptions } from '../../constants/common.constants'

const FranquiaResponsavel = (props) => {
  const [invalidCPF, setInvalidCPF] = useState(false)
  const [invalidTelefone, setInvalidTelefone] = useState(false)
  const [disableAll, setDisableAll] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const [invalidCEP, setInvalidCEP] = useState(false);
  const [responsavel, setResponsavel] = useState({});
  const [franquiaId, setFranquiaId] = useState();
  const [loadingCEP, setLoadingCEP] = useState(false)

  useEffect(() => {
    if(props.responsavel)
      setResponsavel(parseData(props.responsavel))

    if(props.franquiaId)
      setFranquiaId(props.franquiaId)

  },[props.responsavel]);

  const mphone = (v) => {
    v = v.replace(/\D/g, '')
    v = v.replace(/^(\d{2})(\d)/g, '($1)$2')
    v = v.replace(/(\d)(\d{4})$/, '$1-$2')
    return v
  }

  const handleTelefone = (e) => {

    e.target.value = mphone(e.target.value)

    const numero = e.target.value;

    var resp = responsavel;
    resp.telefone = numero;
    setResponsavel(resp)

     const invalid = !(numero.length === 0) && !(numero.length === 14) && !(numero.length === 13)
     setInvalidTelefone(invalid)

    parseState(resp);
  }

  const onBlurCEP = (e) => {
    e.preventDefault()
    if (isValidCep(e.target.value)) {
      setLoadingCEP(true);
      SharedService.consultarCEP(onlyNumbers(e.target.value))
        .then(({ data }) => {
          updateAddress(data)
        })
        .catch((error) => {
          setLoadingCEP(false);
          //setInvalidCEP(true);
        })
    }
  }

  const updateAddress = (data) => {
    const resp = responsavel;

    if(!data || !data.logradouro){
      setLoadingCEP(false);
      setInvalidCEP(true);

      resp.logradouro = '';
      resp.bairro = '';
      resp.cidade = '';
      resp.estado = '';

      parseState();
      return
    }
    
    resp.logradouro = data.logradouro;
    resp.bairro = data.bairro;
    resp.cidade = data.cidade;
    resp.estado = data.estado;
    
    setResponsavel(resp);

    setLoadingCEP(false);
    setInvalidCEP(false);
    parseState();
  }

  const parseData = (data) => {
    var resp = {
      id: data.id,
      nome: data.nome,
      dataNascimento: data.dataNascimento,
      email: data.emails && data.emails[0] ? data.emails[0].endereco : '',
      rg: data.rg,
      cpf: data.cpf,
      telefone: data.telefones && data.telefones[0] ? data.telefones[0].numero : '',
      enderecoId: data.endereco ? data.endereco.id : null,

      estadoCivil: data.estadoCivil ? data.estadoCivil : 0,
      estadoCivilSelected: estadoCivilOptions.find((estado) => estado.value === (data.estadoCivil ?? 0)),
      nacionalidade: data.nacionalidade,
      orgaoEmissor: data.orgaoEmissor,
      profissao: data.profissao,

      cep: data.endereco ? data.endereco.cep : '',
      bairro: data.endereco ? data.endereco.bairro : '',
      complemento: data.endereco ? data.endereco.complemento : '',
      estado: data.endereco ? data.endereco.estado : '',
      logradouro: data.endereco ? data.endereco.logradouro : '',
      cidade: data.endereco ? data.endereco.cidade : '',
      numero: data.endereco ? data.endereco.numero : ''
    };

    return resp;
  }

  const handleChangeCpf = async ({ target }) => {
    const { value } = target
    let data = null
    setIsLoading(true);

    if (onlyNumbers(value).length == 11) {
      if(franquiaId)
        data = await colaboradoresService.getByCpfFranquiaId(onlyNumbers(value), franquiaId);
      
      if(!data)
        data = await colaboradoresService.getByCpf(onlyNumbers(value));

      if (data) {
        setResponsavel(parseData(data))
        const { index, responsavel } = props        
        props.onchange(index, data)
      }
      else {
        const resp = responsavel
        resp.cpf = value;
        setResponsavel(resp);
        setDisableAll(false);
      }
    }
    else {      
      clearValues()
    }

    setIsLoading(false);
  }

  const clearValues = () => {
    const clear = {
      nome: '', email: '', telefone: '', rg: '', cep: '',
      bairro: '', numero: '', cidade: '', dataNascimento: null,
      logradouro: '', estado: '', bairro: '', nacionalidade: '',
      orgaoEmissor: '', profissao: '', estadoCivil: 0,
      estadoCivilSelected: estadoCivilOptions.find((estado) => estado.value === 0)
    }
    setResponsavel(clear);
  }

  const onChangeDate = (dataNascimento) => {

    var resp = responsavel;
    resp.dataNascimento = dataNascimento;
    setResponsavel(resp)

    parseState(resp);
  }

  const onChange = e => {
    const resp = responsavel
    resp[e.target.name] = e.target.value
    setResponsavel(resp)

    parseState(responsavel);
  }

  const onChangeEstadoCivil = (estadoCivil) => {
    const resp = responsavel;
    resp['estadoCivilSelected'] = estadoCivil;
    setResponsavel(resp)

    parseState(responsavel);
  }

  const parseState = (data) => {

    var retorno = new Object();
    retorno.id = data.id;
    retorno.nome = data.nome;
    retorno.cpf = data.cpf;
    retorno.rg = data.rg;
    retorno.dataNascimento = data.dataNascimento;

    retorno.estadoCivil = data.estadoCivilSelected ? data.estadoCivilSelected.value : 0;
    retorno.nacionalidade = data.nacionalidade;
    retorno.orgaoEmissor = data.orgaoEmissor;
    retorno.profissao = data.profissao;

    retorno.telefones = data.telefone ? [{ numero: data.telefone }] : null;
    retorno.emails = data.email ? [{ endereco: data.email }] : null;

    if (data.enderecoId)
      retorno.enderecoId = data.enderecoId;
    else { }

    retorno.endereco = {
      cep: data.cep,
      bairro: data.bairro,
      logradouro: data.logradouro,
      numero: data.numero,
      complemento: data.complemento,
      cidade: data.cidade,
      estado: data.estado
      
    }
    const { index, responsavel } = props

    props.onchange(index, retorno)

  }

  return (
    <Card>
      <Card.Header>
        <h1 className='h3 m-0 text-secondary font-weight-bold'>Franqueado</h1>
      </Card.Header>

      <Card.Body>
        <Form.Group className='required'>
          <Form.Label>CPF</Form.Label>
          <MaskedInput
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            className={invalidCPF ? 'form-control is-invalid' : 'form-control'}
            id='cpf'
            name='cpf'
            value={responsavel.cpf}
            onChange={handleChangeCpf}
            required
          />
        </Form.Group>

        <Form.Group className='required'>
          <Form.Label >Nome</Form.Label>
          <Form.Control
            type='text'
            id='nome'
            name='nome'
            value={responsavel.nome}
            maxLength={100}
            disabled={disableAll}
            onChange={onChange}
            required
          />
        </Form.Group>

        <Form.Group className='required'>
          <Form.Label>E-mail</Form.Label>
          <Form.Control
            type='email'
            id='email'
            name='email'
            value={responsavel.email}
            maxLength={100}
            disabled={disableAll}
            onChange={onChange}
            required
          />
        </Form.Group>

        <Form.Group className='required'>
          <Form.Label>Telefone</Form.Label>
          <Form.Control
            className={invalidTelefone ? 'is-invalid' : ''}
            name='telefone'
            id='telefone'
            type='text'
            value={responsavel.telefone}
            onChange={handleTelefone}
            disabled={disableAll}
            required
            maxLength={14}
          />

        </Form.Group>

        <Form.Group className='required'>
          <Form.Label >RG</Form.Label>
          <Form.Control
            name='rg'
            id='rg'
            value={responsavel.rg}
            maxLength={10}
            disabled={disableAll}
            onChange={onChange}
            required
          />
        </Form.Group>

        <Form.Group className='required'>
          <Form.Label >Orgão Emissor</Form.Label>
          <Form.Control
            name='orgaoEmissor'
            id='orgaoEmissor'
            value={responsavel.orgaoEmissor}
            maxLength={20}
            disabled={disableAll}
            onChange={onChange}
            required
          />
        </Form.Group>

        <Form.Group className='required'>
          <Form.Label>Data Nascimento</Form.Label>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              invalidDateMessage='Formato de data inválido'
              id='dataNascimento'
              name='dataNascimento'
              className='form-control'
              value={responsavel.dataNascimento}
              variant='inline'
              format='DD/MM/YYYY'
              required
              animateYearScrolling
              disableFuture
              disabled={disableAll}
              onChange={onChangeDate}
            />
          </MuiPickersUtilsProvider>
        </Form.Group>

        <Form.Group className='required'>
          <Form.Label>Estado Cívil</Form.Label>
          <Select
            name='estadoCivil'
            value={responsavel.estadoCivilSelected}
            onChange={onChangeEstadoCivil}
            options={estadoCivilOptions}
            readOnly={disableAll}
            isDisabled={disableAll}
            placeholder=''
            noOptionsMessage={() => 'Nenhum Estado Cívil Encontrado'}
          />
        </Form.Group>

        <Form.Group className='required'>
          <Form.Label >Nacionalidade</Form.Label>
          <Form.Control
            name='nacionalidade'
            id='nacionalidade'
            value={responsavel.nacionalidade}
            maxLength={100}
            disabled={disableAll}
            onChange={onChange}
            required
          />
        </Form.Group>

        <Form.Group className='required'>
          <Form.Label >Profissão</Form.Label>
          <Form.Control
            name='profissao'
            id='profissao'
            value={responsavel.profissao}
            maxLength={100}
            disabled={disableAll}
            onChange={onChange}
            required
          />
        </Form.Group>

        <Form.Group className='required'>
          <Form.Label>CEP</Form.Label>
          {loadingCEP && <Spinner animation='grow' variant='primary' size='sm' />}
          <MaskedInput
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            className={invalidCEP ? 'form-control is-invalid' : 'form-control'}
            id='cep'
            name='cep'
            value={responsavel.cep}
            onChange={onChange}
            onBlur={onBlurCEP}
            required
            disabled={loadingCEP || disableAll}
          />
          {invalidCEP && <div className='invalid-feedback'>CEP inválido</div>}
        </Form.Group>

        <Form.Group className='required'>
          <Form.Label htmlFor='numero'>Número</Form.Label>
          <Form.Control
            type='text'
            id='numero'
            name='numero'
            value={responsavel.numero}
            onChange={onChange}
            required
            maxLength={10}
            disabled={disableAll}
          />
        </Form.Group>

        <Form.Group className='required'>
          <Form.Label htmlFor='logradouro'>Logradouro</Form.Label>
          <Form.Control
            type='text'
            id='logradouro'
            name='logradouro'
            value={responsavel.logradouro}
            onChange={onChange}
            required
            maxLength={100}
            disabled={disableAll}
          />
        </Form.Group>

        <Form.Group className='required'>
          <Form.Label htmlFor='estado'>Estado</Form.Label>
          <Form.Control
            type='text'
            id='estado'
            name='estado'
            value={responsavel.estado}
            onChange={onChange}
            maxLength={100}
            required
            disabled={disableAll}
          />
        </Form.Group>

        <Form.Group className='required'>
          <Form.Label htmlFor='cidade'>Cidade</Form.Label>
          <Form.Control
            type='text'
            id='cidade'
            name='cidade'
            value={responsavel.cidade}
            onChange={onChange}
            maxLength={100}
            required
            disabled={disableAll}
          />
        </Form.Group>

        <Form.Group className='required'>
          <Form.Label htmlFor='bairro'>Bairro</Form.Label>
          <Form.Control
            type='text'
            id='bairro'
            name='bairro'
            value={responsavel.bairro}
            onChange={onChange}
            maxLength={100}
            required            
            disabled={disableAll}
          />
        </Form.Group>

      </Card.Body>
    </Card>
  )
}

export default FranquiaResponsavel
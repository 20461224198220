import { Perfis } from '../types'

const initialState = {
  perfis: [],
  selectedPerfil: '',
  acoes: [],
  funcionalidades: [],
  menus: [],
  menu: '',
}

export const perfisReducer = (state = initialState, action) => {
  switch (action.type) {
    case Perfis.LISTAR_PERFIS:
      return {
        ...state,
        perfis: action.perfis,
      }
    case Perfis.LISTAR_MENU:
      return {
        ...state,
        menus: action.menu,
      }
    case Perfis.LISTAR_FUNCIONALIDADES:
      return {
        ...state,
        funcionalidades: action.funcionalidades,
      }
    case Perfis.LISTAR_ACOES:
      return {
        ...state,
        acoes: action.acoes,
      }
    case Perfis.REQUEST_ACOES:
      return {
        ...state,
        menu: state.menus.find((m) => m.id === action.menuId),
      }
    case Perfis.EDITAR_PERFIL:
      return {
        ...state,
        selectedPerfil: action.perfilId,
      }
    default:
      return state
  }
}

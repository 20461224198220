import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ptBr from 'date-fns/locale/pt-BR'
import moment from 'moment'
registerLocale('pt-BR', ptBr)

const filterDate = (date, diasFranquia) => {
  if (!diasFranquia) {
    return true
  }

  const diaSemana = diasFranquia.find((d) => d.diaSemana === date.getDay())

  return diaSemana !== undefined
}

export default function TimeSelector({ date, handleDate, dateStep, minDate, maxDate, excludeDates, diasFranquia }) {
  return (
    <DatePicker
      selected={date}
      className='form-control w-100'
      onChange={handleDate}
      showTimeSelect
      minDate={minDate || new Date()}
      maxDate={
        maxDate ||
        moment(new Date())
          .add(dateStep, 'days')
          .toDate()
      }
      timeFormat='HH:mm'
      timeIntervals={15}
      timeCaption='Hora'
      dateFormat='dd/MM/yyyy, HH:mm'
      locale='pt-BR'
      placeholderText='DD/MM/YYYY, hh:mm'
      excludeDates={excludeDates}
      filterDate={(date) => filterDate(date, diasFranquia)}
    />
  )
}

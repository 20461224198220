import React, { Fragment, useState, useEffect } from 'react'
import { Button, OverlayTrigger, Table, Tooltip, Col, Row } from 'react-bootstrap'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import Select from 'react-select'
import Swal from 'sweetalert2'

import OrtodontiaService from '../../../services/ortodontia.service'
import ModalReagendamentoRadiologia from './ModalReagendamentoRadiologia'
import Paginator from '../../Common/Paginator'

const radiologiaOptions = [
  { value: 0, label: 'Todos os exames' },
  { value: 1, label: 'Exames pendentes' },
  { value: 2, label: 'Exames prontos' },
]

export default function AgendamentosRadiologia({
  selectedAgendamento,
  setSelectedAgendamento,
  nextStep,
  isLoading,
  setIsLoading,
  shouldReload,
  setShouldReload,
}) {
  const registrosPorPagina = 20
  const [numeroPagina, setNumeroPagina] = useState(0)
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [dateFilterDe, setDateFilterDe] = useState(null)
  const [dateFilterAte, setDateFilterAte] = useState(null)
  const [selectedRadiologiaOption, setSelectedRadiologiaOption] = useState(radiologiaOptions[0])
  const [agendamentos, setAgendamentos] = useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false)

  useEffect(() => {
    handleSearch()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numeroPagina])

  useEffect(() => {
    shouldReload && handleSearch()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReload])

  const toggleModalReagendamento = async (avaliacao) => {
    if (avaliacao) {
      setSelectedAgendamento(avaliacao)
    } else {
      await handleSearch()
    }
    setModalIsOpen(!modalIsOpen)
  }

  const handleTogglePronto = async (agend) => {
    const { value } = await Swal.fire({
      title: `Deseja confirmar que o exame está pronto?`,
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirmar',
      cancelButtonColor: '#dbdbdb',
      cancelButtonText: 'Cancelar',
    })

    if (value) {
      setIsLoading(true)
      try {
        await OrtodontiaService.toggleStatusAgendamentoRadiologico(agend)
        await handleSearch()
      } catch (e) {}
      setIsLoading(false)
    }
  }

  const handleSearch = async () => {
    setIsLoading(true)
    try {
      const filtros = [
        {
          campo: 'dataInicial',
          valor: dateFilterDe,
        },
        {
          campo: 'dataFinal',
          valor: dateFilterAte,
        },
        {
          campo: 'radiografiaPronta',
          valor: selectedRadiologiaOption.value,
        },
      ]
      const { data } = await OrtodontiaService.getAllAgendamentoRadiologia(
        filtros,
        numeroPagina + 1,
        registrosPorPagina
      )
      setAgendamentos(data.dados)
      setTotalPaginas(data.totalPaginas)
      setShouldReload(false)
    } catch (e) {}
    setIsLoading(false)
  }

  const handlePagina = (data) => setNumeroPagina(data.selected)

  const handleEditarArquivos = (agendamento) => {
    setSelectedAgendamento(agendamento)
    nextStep()
  }

  const handleChangeOption = (option) => setSelectedRadiologiaOption(option)

  const handleDateFilterDe = (date) => (date ? setDateFilterDe(date.toDate().toISOString()) : setDateFilterDe(null))

  const handleDateFilterAte = (date) => (date ? setDateFilterAte(date.toDate().toISOString()) : setDateFilterAte(null))

  const renderFiltros = () => {
    return (
      <Row className='my-4'>
        <Col md='3'>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              invalidDateMessage='Formato de data inválido'
              value={dateFilterDe}
              onChange={handleDateFilterDe}
              className='form-control'
              variant='inline'
              format='DD/MM/YYYY'
              maxDate={dateFilterAte || new Date('2100-01-01')}
              animateYearScrolling
              autoOk
              placeholder='De'
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col md='3'>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              invalidDateMessage='Formato de data inválido'
              value={dateFilterAte}
              onChange={handleDateFilterAte}
              minDate={dateFilterDe || new Date('1900-01-01')}
              className='form-control'
              variant='inline'
              format='DD/MM/YYYY'
              animateYearScrolling
              autoOk
              placeholder='Até'
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col md='3'>
          <Select
            options={radiologiaOptions}
            value={selectedRadiologiaOption}
            onChange={handleChangeOption}
            placeholder='Selecione um filtro...'
          />
        </Col>
        <Col md='1'>
          <Button onClick={handleSearch} variant='outline-primary'>
            Pesquisar
          </Button>
        </Col>
      </Row>
    )
  }

  const renderList = () => {
    return agendamentos.length > 0 ? (
      <Fragment>
        <Table size='sm' bordered hover className='bg-white'>
          <thead>
            <tr>
              <th>Clínica</th>
              <th>Paciente</th>
              <th width='150px' className='text-center'>
                Data
              </th>
              <th width='120px' className='text-center'>
                Exame Pronto
              </th>
              <th width='120px' className='text-center'>
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            {agendamentos.map((agend, index) => (
              <tr style={{ fontSize: 'small' }} key={index}>
                <td>{agend.nomeClinica}</td>
                <td>{agend.nomeDependente ? agend.nomeDependente : agend.nomePaciente}</td>
                <td className='text-center'>{moment(agend.data).format('DD/MM/YYYY - HH:mm')}</td>
                <td className='text-center'>
                  <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>{`Marcar como pronto`}</Tooltip>}>
                    <label className='checkbox labeless m-0'>
                      <input
                        type='checkbox'
                        checked={agend.radiografiaPronta}
                        onChange={agend.radiografiaPronta ? null : () => handleTogglePronto(agend)}
                      />
                      <span />
                    </label>
                  </OverlayTrigger>
                </td>
                <td className='text-center'>
                  {!agend.radiografiaPronta && (
                    <OverlayTrigger
                      trigger='hover'
                      placement='top'
                      overlay={
                        <Tooltip>{`Reagendar exame de ${
                          agend.nomeDependente ? agend.nomeDependente : agend.nomePaciente
                        }`}</Tooltip>
                      }
                    >
                      <Button className='mr-2' onClick={() => toggleModalReagendamento(agend)} size='sm'>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </Button>
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Arquivos de Radiologia</Tooltip>}>
                    <Button onClick={() => handleEditarArquivos(agend)} size='sm'>
                      <FontAwesomeIcon icon={faFilePdf} />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {totalPaginas > 1 && (
          <Paginator handlePagina={handlePagina} pageCount={totalPaginas} paginaAtual={numeroPagina} />
        )}
        {modalIsOpen && (
          <ModalReagendamentoRadiologia
            agendamento={selectedAgendamento}
            isOpen={modalIsOpen}
            handleClose={toggleModalReagendamento}
          />
        )}
      </Fragment>
    ) : (
      <div>Nenhum agendamento encontrado.</div>
    )
  }
  return (
    <Fragment>
      {!isLoading && renderFiltros()}
      {renderList()}
    </Fragment>
  )
}

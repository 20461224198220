export const TIPOS_TEMPLATE = {
  DeclaracaoDeRealizacaoDeProcedimento: 0,
  ComprovanteDeRealizacaoDeAtendimento: 1,
  TermoDeConclusaoDeTratamento: 2,
  Contrato: 3,
  TermoDeRenegociacao: 4,
  TermoDeCancelamentoDeProcedimentoComPendencia: 5,
  TermoDeCancelamentoDeProcedimentoComQuitacao: 6,
  AnexoContrato: 7,
  TermoDeConsentimentoDeTratamento: 8,
  GuiaDeOrientacaoDeProtese: 9,
  RespostasAnamnese: 10,
  TermoDeCancelamentoDeOrtodontiaComQuitacao: 11,
  TermoDeConclusaoDeOrtodontia: 12,
  TermoDeRenegociacaoProtetico: 13,
}

export const MENUS = { CONTRATANTE: 'CONTRATANTE', PACIENTE: 'PACIENTE', CONTRATADA: 'CONTRATADA', OUTROS: 'OUTROS' }

export const TAGS = {
  [MENUS.CONTRATANTE]: {
    NOME_CONTRATANTE: '[NOME_CONTRATANTE]',
    RG_CONTRATANTE: '[RG_CONTRATANTE]',
    CPF_CONTRATANTE: '[CPF_CONTRATANTE]',
    ENDERECO_CONTRATANTE: '[ENDERECO_CONTRATANTE]',
  },
  [MENUS.PACIENTE]: {
    NOME_PACIENTE: '[NOME_PACIENTE]',
    RG_PACIENTE: '[RG_PACIENTE]',
    CPF_PACIENTE: '[CPF_PACIENTE]',
    ENDERECO_PACIENTE: '[ENDERECO_PACIENTE]',
  },
  [MENUS.CONTRATADA]: {
    RAZAO_SOCIAL_CONTRATADA: '[RAZAO_SOCIAL_CONTRATADA]',
    NOME_FANTASIA_CONTRATADA: '[NOME_FANTASIA_CONTRATADA]',
    CNPJ_CONTRATADA: '[CNPJ_CONTRATADA]',
    ENDERECO_CONTRATADA: '[ENDERECO_CONTRATADA]',
    CIDADE_CONTRATADA: '[CIDADE_CONTRATADA]',
    ESTADO_CONTRATADA: '[ESTADO_CONTRATADA]',

    NOME_REPRESENTANTE_LEGAL: '[NOME_REPRESENTANTE_LEGAL]',
    CPF_REPRESENTANTE_LEGAL: '[CPF_REPRESENTANTE_LEGAL]',
    RG_REPRESENTANTE_LEGAL: '[RG_REPRESENTANTE_LEGAL]',
    ORGAO_EMISSOR_REPRESENTANTE_LEGAL: '[ORGAO_EMISSOR_REPRESENTANTE_LEGAL]',
    NACIONALIDADE_REPRESENTANTE_LEGAL: '[NACIONALIDADE_REPRESENTANTE_LEGAL]',
    ESTADO_CIVIL_REPRESENTANTE_LEGAL: '[ESTADO_CIVIL_REPRESENTANTE_LEGAL]',
    PROFISSAO_REPRESENTANTE_LEGAL: '[PROFISSAO_REPRESENTANTE_LEGAL]',
  },
  [MENUS.OUTROS]: {
    DATA_ATUAL_EXTENSO: '[DATA_ATUAL_EXTENSO]',
    DATA_INICIO_PLANO_TRATAMENTO: '[DATA_INICIO_PLANO_TRATAMENTO]',
    LINHA_EM_BRANCO: '[LINHA_EM_BRANCO]',
    LISTAGEM_PROCEDIMENTOS_PACIENTE: '[LISTAGEM_PROCEDIMENTOS_PACIENTE]',
    LISTAGEM_PROCEDIMENTOS_INDICADOS: '[LISTAGEM_PROCEDIMENTOS_INDICADOS]',
    LISTAGEM_PROCEDIMENTOS_FINALIZADOS: '[LISTAGEM_PROCEDIMENTOS_FINALIZADOS]',
    NUMERO_PARCELAS: '[NUMERO_PARCELAS]',
    NUMERO_PLANO_TRATAMENTO: '[NUMERO_PLANO_TRATAMENTO]',
    TABELA_PARCELAS: '[TABELA_PARCELAS]',
    TABELA_PARCELAS_PROTESE: '[TABELA_PARCELAS_PROTESE]',
    TABELA_CONTRATO_ORIGINAL: '[TABELA_CONTRATO_ORIGINAL]',
    TABELA_RENEGOCIACAO: '[TABELA_RENEGOCIACAO]',
    TABELA_PROCEDIMENTOS: '[TABELA_PROCEDIMENTOS]',
    VALOR_PARCELAS: '[VALOR_PARCELAS]',
    VALOR_PAGO_TRATAMENTO: '[VALOR_PAGO_TRATAMENTO]',
    VALOR_RESTANTE_TRATAMENTO: '[VALOR_RESTANTE_TRATAMENTO]',
    VENCIMENTO_PARCELAS_POR_EXTENSO: '[VENCIMENTO_PARCELAS_POR_EXTENSO]',
    RESPOSTAS_ANAMNESE: '[RESPOSTAS_ANAMNESE]',
    DATA_AVALIACAO: '[DATA_AVALIACAO]',
    VALOR_CREDITO: '[VALOR_CREDITO]',
    HISTORICO_ATENDIMENTO_ORTODONTIA: '[HISTORICO_ATENDIMENTO_ORTODONTIA]',
    TABELA_PARCELAS_PAGAS: '[TABELA_PARCELAS_PAGAS]',
    TABELA_PROTESES_PAGAS: '[TABELA_PROTESES_PAGAS]',
    TABELA_PROTETICO_ORIGINAL: '[TABELA_PROTETICO_ORIGINAL]',
    TABELA_PROTETICO_RENEGOCIACAO: '[TABELA_PROTETICO_RENEGOCIACAO]',
    NOME_PROTETICO: '[NOME_PROTETICO]'
  },
}

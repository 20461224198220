export const INICIAR_TRATAMENTO = 'INICIAR_TRATAMENTO'
export const FINALIZAR_TRATAMENTO = 'FINALIZAR_TRATAMENTO'
export const INICIAR_AVALIACAO = 'INICIAR_AVALIACAO'
export const FINALIZAR_AVALIACAO = 'FINALIZAR_AVALIACAO'
export const CONCLUIR_ATENDIMENTO = 'CONCLUIR_ATENDIMENTO'

export const iniciarTratamento = (agendamento) => ({
  type: INICIAR_TRATAMENTO,
  agendamento,
})

export const finalizarTratamento = () => ({
  type: FINALIZAR_AVALIACAO,
})

export const iniciarAvaliacao = (pacienteId, agendamento, anamneseFichaId) => ({
  type: INICIAR_AVALIACAO,
  pacienteId,
  agendamento,
  anamneseFichaId,
})

export const finalizarAvaliacao = (agendamentoId, status) => ({
  type: FINALIZAR_AVALIACAO,
  agendamentoId,
  status,
})

export const concluirAtendimento = (agendamento) => ({
  type: CONCLUIR_ATENDIMENTO,
  agendamento,
})

const initialState = {
  pacienteId: null,
  pacienteNome: null,
  pacienteCpf: null,
  agendamento: {},
  anamneseFichaId: null,
}

export const avaliacaoReducer = (state = initialState, action) => {
  switch (action.type) {
    case INICIAR_TRATAMENTO:
    case CONCLUIR_ATENDIMENTO:
      return { ...state, agendamento: action.agendamento ? action.agendamento : {} }
    case INICIAR_AVALIACAO:
      return {
        ...state,
        pacienteId: action.pacienteId ? action.pacienteId : null,
        agendamento: action.agendamento ? action.agendamento : {},
        anamneseFichaId: action.anamneseFichaId ? action.anamneseFichaId : null,
      }
    case FINALIZAR_TRATAMENTO:
    case FINALIZAR_AVALIACAO:
      return { ...initialState }
    default:
      return { ...state }
  }
}

import React from 'react'
import { Spinner } from 'react-bootstrap'

const containerStyle = {
  position: 'fixed',
  minHeight: '500px',
  width: '100vw',
  height: '100vh',
  zIndex: '99999',
  top: '0',
  left: '0',
  backgroundColor: 'rgba(255,255,255, 0.8)',
}

const spinnerStyle = {
  width: '4rem',
  height: '4rem',
  borderWidth: '0.5rem',
}

const spinnerContainerStyle = {
  marginTop: 'calc(45vh - 4rem)',
}

const PageLoading = ({ text }) => (
  <div style={containerStyle}>
    <div className='d-flex flex-column w-25 text-center mx-auto' style={spinnerContainerStyle}>
      <Spinner
        className='spinner-border text-primary mx-auto'
        animation='border'
        variant='primary'
        style={spinnerStyle}
      />
      <h5 className='text-primary mt-2'> {text || 'Carregando...'}</h5>
    </div>
  </div>
)

export default PageLoading

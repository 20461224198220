import React, { Fragment } from 'react'
import DenteButton from '../../../Common/DenteButton'

const Odontograma = (props) => {
  const {
    dentesSuperioresDireito,
    dentesSuperioresEsquerdo,
    dentesInferioresDireito,
    dentesInferioresEsquerdo,
    dentesDeciduosSuperioresDireito,
    dentesDeciduosSuperioresEsquerdo,
    dentesDeciduosInferioresDireito,
    dentesDeciduosInferioresEsquerdo,
  } = props.dentesPaciente

  return (
    <Fragment>
      <div className='d-flex'>
        <div className='d-flex flex-fill'></div>
        <div className='d-flex justify-content-between mb-3 flex-fill'>
          {dentesSuperioresDireito.map((dente) => (
            <DenteButton disabled key={dente.id} dente={{ ...dente.dente, ausente: dente.ausente }} />
          ))}
          {dentesSuperioresEsquerdo.map((dente) => (
            <DenteButton disabled key={dente.id} dente={{ ...dente.dente, ausente: dente.ausente }} />
          ))}
        </div>
        <div className='d-flex flex-fill'></div>
      </div>

      <div className='d-flex'>
        <div className='d-flex flex-fill'></div>
        <div className='d-flex flex-fill'></div>
        <div className='d-flex justify-content-between mb-3 flex-fill'>
          {dentesDeciduosSuperioresDireito.map((dente) => (
            <DenteButton disabled key={dente.id} dente={{ ...dente.dente, ausente: dente.ausente }} />
          ))}
          {dentesDeciduosSuperioresEsquerdo.map((dente) => (
            <DenteButton disabled key={dente.id} dente={{ ...dente.dente, ausente: dente.ausente }} />
          ))}
        </div>
        <div className='d-flex flex-fill'></div>
        <div className='d-flex flex-fill'></div>
      </div>

      <div className='d-flex'>
        <div className='d-flex flex-fill'></div>
        <div className='d-flex flex-fill'></div>
        <div className='d-flex justify-content-between mb-3 flex-fill'>
          {dentesDeciduosInferioresDireito.map((dente) => (
            <DenteButton disabled key={dente.id} dente={{ ...dente.dente, ausente: dente.ausente }} />
          ))}
          {dentesDeciduosInferioresEsquerdo.map((dente) => (
            <DenteButton disabled key={dente.id} dente={{ ...dente.dente, ausente: dente.ausente }} />
          ))}
        </div>
        <div className='d-flex flex-fill'></div>
        <div className='d-flex flex-fill'></div>
      </div>

      <div className='d-flex'>
        <div className='d-flex flex-fill'></div>
        <div className='d-flex justify-content-between flex-fill'>
          {dentesInferioresDireito.map((dente) => (
            <DenteButton disabled key={dente.id} dente={{ ...dente.dente, ausente: dente.ausente }} />
          ))}
          {dentesInferioresEsquerdo.map((dente) => (
            <DenteButton disabled key={dente.id} dente={{ ...dente.dente, ausente: dente.ausente }} />
          ))}
        </div>
        <div className='d-flex flex-fill'></div>
      </div>
    </Fragment>
  )
}

export default Odontograma

import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Table } from 'react-bootstrap'
import Select from 'react-select'
import CurrencyInput from 'react-currency-input'
import { toast } from 'react-toastify'

import { statusProcedimento } from '../../constants/common.constants'
import { formatMoney, handleDecimalConvert } from '../../utils/string.utils'
import { QUADRANTES_DENTES } from '../../constants/anamnese.constants'
import DenteButton from '../Common/DenteButton'
import ProteticoService from '../../services/protetico.service'

const tiposSelect = {
  PROTETICO: 'protetico',
  PROCEDIMENTO: 'procedimento',
}

const Procedimento = (props) => {
  const { dentes, procedimentos, procedimentosPaciente, setProcedimentos, setIsLoading } = props

  const [dentesSupDir, setDentesSupDir] = useState([])
  const [dentesSupEsq, setDentesSupEsq] = useState([])
  const [dentesInfDir, setDentesInfDir] = useState([])
  const [dentesInfEsq, setDentesInfEsq] = useState([])

  const [dentesDeciduosSupDir, setDentesDeciduosSupDir] = useState([])
  const [dentesDeciduosSupEsq, setDentesDeciduosSupEsq] = useState([])
  const [dentesDeciduosInfDir, setDentesDeciduosInfDir] = useState([])
  const [dentesDeciduosInfEsq, setDentesDeciduosInfEsq] = useState([])

  const [dentesSelecionados, setDentesSelecionados] = useState([])
  const [procedimentoSelecionado, setProcedimentoSelecionado] = useState(null)
  const [proteticoSelecionado, setProteticoSelecionado] = useState(null)
  const [proteticos, setProteticos] = useState([])
  const [observacaoProcedimentoSelecionado, setObservacaoProcedimentoSelecionado] = useState('')
  const [valorDentista, setValorDentista] = useState('')
  const [valorProtese, setValorProtese] = useState('')

  useEffect(() => {
    if (procedimentos.length > 0) {
      let procedimentosPadrao = procedimentos.filter((p) => p.procedimentoPadrao)
      procedimentosPadrao = procedimentosPadrao.map((p) => {
        const newProc = {
          ...p,
          procedimentoId: p.id,
          valor: p.valor + p.valor * p.percentualAcrescimo,
          valorCobrado: p.valorComAcrescimo,
          denteId: null,
        }
        delete newProc.id
        return newProc
      })
      setProcedimentos([...procedimentosPadrao])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procedimentos])

  useEffect(() => {
    if (Object.keys(dentes).length > 0) {
      setDentesSupDir(dentes.dentesSuperioresDireito)
      setDentesSupEsq(dentes.dentesSuperioresEsquerdo)
      setDentesInfDir(dentes.dentesInferioresDireito)
      setDentesInfEsq(dentes.dentesInferioresEsquerdo)
      setDentesDeciduosSupDir(dentes.dentesDeciduosSuperioresDireito)
      setDentesDeciduosSupEsq(dentes.dentesDeciduosSuperioresEsquerdo)
      setDentesDeciduosInfDir(dentes.dentesDeciduosInferioresDireito)
      setDentesDeciduosInfEsq(dentes.dentesDeciduosInferioresEsquerdo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(dentes)])

  const getProcedimentosOptions = () => {
    let procedimentosOptions = procedimentos.filter((p) => !p.protetico)
    if (procedimentosOptions.length > 0) {
      return procedimentosOptions.map((procedimento) => ({
        value: procedimento.id,
        label: `${procedimento.nome} (${formatMoney(procedimento.valor)})`,
      }))
    } else {
      return []
    }
  }

  const toggleDentesSelecionados = (dente) => {
    if (dente.ausente) {
      return
    }
    const newDentesSelecionados = [...dentesSelecionados]
    const index = newDentesSelecionados.indexOf(dente.denteId)
    if (index >= 0) {
      newDentesSelecionados.splice(index, 1)
    } else {
      newDentesSelecionados.push(dente.denteId)
    }
    setDentesSelecionados(newDentesSelecionados)
  }

  const handleSelectChange = async (name, option) => {
    if (name === tiposSelect.PROCEDIMENTO) {
      setIsLoading(true)
      let procedimento = procedimentos.find((p) => p.id === option.value)
      if (procedimento.procedimentoProteticoId) {
        try {
          let { data } = await ProteticoService.getProteticoByProcedimentoId(procedimento.procedimentoProteticoId)
          
          let itensProtetico = data.map((prot) => {
            const itemProtese = prot.proteticoFranquias[0].tabelaPrecos.find(
              (p) => procedimento.procedimentoProteticoId === p.procedimentoProteticoId
            )
            return {
              label: `${prot.razaoSocial} - ${formatMoney(itemProtese.valorFinal)}`,
              value: prot.id,
              valorFinal: itemProtese.valorFinal,
              valorOriginal: itemProtese.valor,
            }
          })
          setProteticos(itensProtetico)
          setProteticoSelecionado(null)
        } catch (error) {}
      }
      setProcedimentoSelecionado({ ...option, procedimentoProteticoId: procedimento.procedimentoProteticoId })
      handleChangeValorDentista(procedimento.valorComAcrescimo)
      setIsLoading(false)
    } else if (name === tiposSelect.PROTETICO) {
      setProteticoSelecionado(option)
      setValorProtese(option.valorFinal)
    }
  }

  const handleChangeValorDentista = (valor) => {
    setValorDentista(valor)
  }

  const handleChangeObservacao = (event) => {
    setObservacaoProcedimentoSelecionado(event.target.value)
  }

  // const handleChangeObservacaoNoPlano = ({ target }, indexProced) => {
  //   const procedimentos = [...procedimentosPaciente]
  //   procedimentos[indexProced] = { ...procedimentos[indexProced], descricao: target.value }
  //   setProcedimentos(procedimentos)
  // }

  const handleAddProcedimento = async () => {
    if (procedimentoSelecionado) {
      const novoProcedimento = getProcedimentoById(procedimentoSelecionado.value)
      let jaFoiAdicionado = procedimentosPaciente.find((p) => {
        return (
          p.procedimentoId === novoProcedimento.id &&
          (dentesSelecionados.includes(p.denteId) || (p.denteId === null && dentesSelecionados.length === 0))
        )
      })
      if (!jaFoiAdicionado) {
        adicionarProcedimento(novoProcedimento)
        resetState()
        toast('Procedimento adicionado à lista.', { type: toast.TYPE.SUCCESS })
      } else {
        toast('Já existe um procedimento igual a este incluso.', { type: toast.TYPE.ERROR })
      }
    }
  }

  const adicionarProcedimento = (novoProcedimento) => {
    const valorDentistaNumber = typeof valorDentista === 'number' ? valorDentista : handleDecimalConvert(valorDentista)
    novoProcedimento = {
      ...novoProcedimento,
      statusProcedimento: statusProcedimento.PENDENTE.value,
      procedimentoId: novoProcedimento.id,
      valorCobrado:
        valorDentistaNumber === novoProcedimento.valorComAcrescimo
          ? valorDentistaNumber
          : valorDentistaNumber + valorDentistaNumber * 0.08,
      valorSugerido: novoProcedimento.valorComAcrescimo,
    }
    if (observacaoProcedimentoSelecionado) {
      novoProcedimento.descricao = observacaoProcedimentoSelecionado
    }
    delete novoProcedimento.id
    const novosProcedimentos = [...procedimentosPaciente]
    if (novoProcedimento.procedimentoProteticoId && proteticoSelecionado) {
      novoProcedimento.avaliacaoProcedimentoProtetico = {
        valor: valorProtese,
        valorOriginal: proteticoSelecionado.valorOriginal,
        procedimentoProteticoId: novoProcedimento.procedimentoProteticoId,
        proteticoId: proteticoSelecionado.value,
      }
    }
    const assertDentesSelecionados = dentesSelecionados.filter((denteId) => {
      let estaAusente = false
      Object.keys(dentes).forEach((quadrante) => {
        const denteBusca = dentes[quadrante].find((dente) => dente.denteId === denteId)
        if (denteBusca) {
          estaAusente = denteBusca.ausente
        }
      })
      return !estaAusente
    })
    if (assertDentesSelecionados.length > 0) {
      assertDentesSelecionados.forEach((denteId) => {
        if (novoProcedimento.avaliacaoProcedimentoProtetico) {
          novoProcedimento.avaliacaoProcedimentoProtetico.denteId = denteId
        }
        novosProcedimentos.unshift({
          ...novoProcedimento,
          denteId,
        })
      })
    } else {
      if (novoProcedimento.avaliacaoProcedimentoProtetico) {
        novoProcedimento.avaliacaoProcedimentoProtetico.denteId = null
      }
      novosProcedimentos.unshift({
        ...novoProcedimento,
        denteId: null,
      })
    }
    setProcedimentos(novosProcedimentos)
  }

  const resetState = () => {
    setDentesSelecionados([])
    setProcedimentoSelecionado(null)
    setProteticoSelecionado(null)
    setObservacaoProcedimentoSelecionado('')
    setValorDentista('')
    setValorProtese('')
  }

  const handleRemoveProcedimento = (index) => {
    const procedimentos = [...procedimentosPaciente]
    procedimentos.splice(index, 1)
    setProcedimentos(procedimentos)
  }

  const getDenteById = (id) => {
    let dente
    Object.keys(dentes).forEach((quadrante) => {
      if (dentes[quadrante].find((dente) => dente.denteId === id)) {
        dente = dentes[quadrante].find((dente) => dente.denteId === id)
      }
    })
    return dente
  }

  const getProcedimentoById = (id) =>
    procedimentos.find((procedimento) => procedimento.id === id || procedimento.procedimentoId === id)

  const renderListaDeProcedimentos = () => {
    if (procedimentosPaciente.length > 0) {
      return (
        <div className='mb-5'>
          <h5>Lista de Procedimentos: </h5>
          <Table hover>
            <thead>
              <tr>
                <th>Procedimento</th>
                <th width='100px' className='text-center'>
                  Dente
                </th>
                <th width='180px'>Valor Proc. (R$)</th>
                <th>Observação</th>
                <th width='50px' />
              </tr>
            </thead>
            <tbody>
              {procedimentosPaciente.map((procedimento, indexProced) => {
                const {
                  denteId,
                  avaliacaoProcedimentoProtetico,
                  procedimentoProtetico,
                  valorCobrado,
                  valorComAcrescimo,
                  nome,
                } = procedimento
                const dente = getDenteById(denteId)
                // const formControlInputClass = 'border-top-0 border-right-0 border-left-0 rounded-0 bg-transparent pl-0'
                return (
                  <Fragment key={indexProced}>
                    <tr key={indexProced}>
                      <td>{nome}</td>
                      <td className='text-center'>{dente ? dente.codigo : 'N/D'}</td>
                      <td className='align-items-center'>{formatMoney(valorCobrado || valorComAcrescimo)}</td>
                      <td style={{ fontSize: 'small' }}>
                        {procedimento.descricao || ''}
                        {/* <Form.Control
                          value={procedimento.descricao || ''}
                          placeholder='Nenhuma observação'
                          className={formControlInputClass}
                          onChange={(event) => handleChangeObservacaoNoPlano(event, indexProced)}
                        /> */}
                      </td>
                      <td>
                        <Button variant='danger' onClick={() => handleRemoveProcedimento(indexProced)}>
                          <i className='fa fa-trash' />
                        </Button>
                      </td>
                    </tr>
                    {avaliacaoProcedimentoProtetico && (
                      <tr key={indexProced}>
                        <td className='pl-3'>
                          <i className='fa fa-level-up-alt fa-rotate-90 mr-3 text-secondary' />
                          {`${procedimentoProtetico.nome} `}
                        </td>
                        <td className='text-center'>{dente ? dente.codigo : 'N/D'}</td>
                        <td> {formatMoney(avaliacaoProcedimentoProtetico.valor)}</td>
                        <td className='align-items-center' />
                        <td />
                      </tr>
                    )}
                  </Fragment>
                )
              })}
            </tbody>
          </Table>
        </div>
      )
    }
  }

  const getNomeProcedimentoProtetico = () => {
    const found = procedimentos.find((p) => p.id === procedimentoSelecionado.value)
    const protetico = !found ? null : found.procedimentoProtetico
    return protetico && protetico.nome ? protetico.nome : '-'
  }

  return (
    <form className='py-3'>
      <div className='form-group row'>
        <Col md='8' className='flex-column mx-auto' style={{ width: 'min-content' }}>
          <h5>Odontograma do paciente: </h5>
          <div className='d-flex justify-content-between mb-4'>
            {dentesSupDir.map((dente) => (
              <DenteButton
                key={dente.codigo}
                dente={dente}
                onClick={() => toggleDentesSelecionados(dente, QUADRANTES_DENTES.SUPERIOR_DIREITO)}
                isSelected={dentesSelecionados.includes(dente.denteId)}
              />
            ))}
            {dentesSupEsq.map((dente) => (
              <DenteButton
                key={dente.codigo}
                dente={dente}
                onClick={() => toggleDentesSelecionados(dente, QUADRANTES_DENTES.SUPERIOR_ESQUERDO)}
                isSelected={dentesSelecionados.includes(dente.denteId)}
              />
            ))}
          </div>
          <div className='d-flex'>
            <div className='d-flex flex-fill' />
            <div className='d-flex justify-content-between flex-fill mx-auto mb-2'>
              {dentesDeciduosSupDir.map((dente) => (
                <DenteButton
                  margin
                  key={dente.denteId}
                  dente={dente}
                  onClick={() => toggleDentesSelecionados(dente, QUADRANTES_DENTES.DECIDUO_SUPERIOR_DIREITO)}
                  isSelected={dentesSelecionados.includes(dente.denteId)}
                />
              ))}
              {dentesDeciduosSupEsq.map((dente) => (
                <DenteButton
                  margin
                  key={dente.denteId}
                  dente={dente}
                  onClick={() => toggleDentesSelecionados(dente, QUADRANTES_DENTES.DECIDUO_SUPERIOR_ESQUERDO)}
                  isSelected={dentesSelecionados.includes(dente.denteId)}
                />
              ))}
            </div>
            <div className='d-flex flex-fill' />
          </div>
          <div className='d-flex'>
            <div className='d-flex flex-fill' />
            <div className='d-flex justify-content-between flex-fill mx-auto mb-4'>
              {dentesDeciduosInfDir.map((dente) => (
                <DenteButton
                  margin
                  key={dente.denteId}
                  dente={dente}
                  onClick={() => toggleDentesSelecionados(dente, QUADRANTES_DENTES.DECIDUO_INFERIOR_DIREITO)}
                  isSelected={dentesSelecionados.includes(dente.denteId)}
                />
              ))}
              {dentesDeciduosInfEsq.map((dente) => (
                <DenteButton
                  margin
                  key={dente.denteId}
                  dente={dente}
                  onClick={() => toggleDentesSelecionados(dente, QUADRANTES_DENTES.DECIDUO_INFERIOR_ESQUERDO)}
                  isSelected={dentesSelecionados.includes(dente.denteId)}
                />
              ))}
            </div>
            <div className='d-flex flex-fill' />
          </div>
          <div className='d-flex justify-content-between'>
            {dentesInfDir.map((dente) => (
              <DenteButton
                key={dente.codigo}
                dente={dente}
                onClick={() => toggleDentesSelecionados(dente, QUADRANTES_DENTES.INFERIOR_DIREITO)}
                isSelected={dentesSelecionados.includes(dente.denteId)}
              />
            ))}
            {dentesInfEsq.map((dente) => (
              <DenteButton
                key={dente.codigo}
                dente={dente}
                onClick={() => toggleDentesSelecionados(dente, QUADRANTES_DENTES.INFERIOR_ESQUERDO)}
                isSelected={dentesSelecionados.includes(dente.denteId)}
              />
            ))}
          </div>
          <>
            <Button disabled variant='light p-0 mt-4'>
              <i className='fa fa-square text-danger' /> Dentes ausentes
            </Button>
          </>
        </Col>
        <Col md='4'>
          <h5>Procedimento: (Valor Sugerido) </h5>
          <Form.Group className='required'>
            <Select
              placeholder='Selecione o Procedimento'
              options={getProcedimentosOptions()}
              value={procedimentoSelecionado}
              onChange={(option) => handleSelectChange(tiposSelect.PROCEDIMENTO, option)}
            />
            <InputGroup className='mt-3'>
              <InputGroup.Prepend>
                <InputGroup.Text>R$</InputGroup.Text>
              </InputGroup.Prepend>
              <CurrencyInput
                className='form-control'
                decimalSeparator=','
                thousandSeparator='.'
                placeholder='Preço final (R$)'
                value={valorDentista}
                onChange={(e) => handleChangeValorDentista(e)}
              />
            </InputGroup>
          </Form.Group>
          {procedimentoSelecionado && procedimentoSelecionado.procedimentoProteticoId && (
            <Form.Group>
              <Form.Label>
                {getNomeProcedimentoProtetico()}
                {valorProtese && <Form.Label> - {formatMoney(valorProtese)}</Form.Label>}
              </Form.Label>
              {proteticos.length > 0 ? (
                <Select
                  placeholder='Selecione o Fornecedor'
                  options={proteticos}
                  value={proteticoSelecionado}
                  onChange={(option) => handleSelectChange(tiposSelect.PROTETICO, option)}
                />
              ) : (
                <div className='alert alert-warning'>Não há fornecedores cadastrados para este serviço</div>
              )}
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Observação: </Form.Label>
            <Form.Control
              as='textarea'
              rows='2'
              maxLength={250}
              name='observacaoProcedimentoSelecionado'
              value={observacaoProcedimentoSelecionado}
              onChange={handleChangeObservacao}
            />
          </Form.Group>
          <Button
            disabled={
              !procedimentoSelecionado || (procedimentoSelecionado.procedimentoProteticoId && !proteticoSelecionado)
            }
            variant='outline-primary'
            className='float-right'
            onClick={handleAddProcedimento}
          >
            Adicionar
          </Button>
        </Col>
      </div>

      {procedimentosPaciente.length > 0 && renderListaDeProcedimentos()}
    </form>
  )
}

export default Procedimento

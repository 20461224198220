import React, { useEffect, useState } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Container, Form, Button, Spinner } from 'react-bootstrap'
import MaskedInput from 'react-text-mask'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { onlyNumbers } from '@brazilian-utils/helper-only-numbers'

import AccountService from './../services/account.service'
import { acessos, tiposAcesso } from '../constants/common.constants'
import { cpfMask, cnpjMask } from '../utils/mask.utils'
import accountService from './../services/account.service'
import usuarioService from './../services/usuario.service'


const Recover  = (props) => {

  const [loading, setLoading] = useState(false);
  const [recovered, setRecovered] = useState(false);
  const [login, setLogin] = useState('');

  const onChange = ({ target }) => {
    setLogin(target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    recoveryPassword();


  }

  const recoveryPassword = async () => {
    setLoading(true);
    
    const request = {
      cpfCnpj: onlyNumbers(login)
    }

    try {

      await usuarioService.recover(request); 

      Swal.fire({
        title: 'E-mail enviado!',
        text: 'Enviamos um e-mail para você com o passo a passo para recuperar sua senha.',
        type: 'success',
        heightAuto: false,
      })
      setRecovered(true);

      }
      catch(ex){
      }
    setLoading(false);
  }

  return (
    <>
      {!recovered && (
        <Container className='bg-primary pt-5' fluid style={{ minHeight: '100vh' }}>
          <Form onSubmit={onSubmit} className='form-login pt-5'>
            <img className='mb-4' src='img/logo-login.png' alt='CredOdonto' />  

            <MaskedInput
              style={{ ...inputStyle }}
              mask={(a, b)=>{
                return onlyNumbers(a).length > 11 ? cnpjMask : cpfMask;
              }}
              className='form-control'
              id='cpfcnpj'
              name='cpfcnpj'
              placeholder={'CPF/CNPJ'}
              value={login}
              onChange={onChange}              
              required
              autoFocus
            />

            <Button type='submit' className='btn-login py-3 mt-4 mb-2' variant='light' disabled={loading} block>
              {loading ? <Spinner as='span' animation='border' size='sm' /> : <span>Recuperar Senha</span>}
            </Button>
            <Link to='/login' className='btn-forget' disabled={loading}>
              Já sabe sua senha?
        </Link>
          </Form>
        </Container>

      )}
      {recovered && (
        <Redirect to='/login' />
      )}
    </>
  )
}

export default Recover

const inputStyle = {
  height: '40px',
}

import React, { Fragment, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Button, Spinner } from 'react-bootstrap'
import { printParcelasReceipt } from '../../../Templates/PrintableReceipt'
import ortodontiaService from '../../../../services/ortodontia.service'
import { TIPOS_TEMPLATE } from '../../../../constants/template.constants'

export default function Impressao({ selectedPlano, pagamento, handleGoBack }) {
  const [isLoading, setIsLoading] = useState(false)

  const handlePrintParcelamento = () => {
    printParcelasReceipt({
      numeroPlano: selectedPlano.numeroPlano,
      nomeContratante: selectedPlano.paciente.nome,
      nomePaciente: selectedPlano.dependente ? selectedPlano.dependente.nome : selectedPlano.paciente.nome,
      parcelas: pagamento.parcelas,
      valorTotal: pagamento.valorTotal,
    })
  }

  const handlePrintCancelamento = async () => {
    setIsLoading(true)
    if (pagamento.valorTotal > 0) {
      await ortodontiaService.downloadTemplateAsPDF(
        selectedPlano.id,
        TIPOS_TEMPLATE.TermoDeCancelamentoDeProcedimentoComPendencia
      )
    } else {
      await ortodontiaService.downloadTemplateAsPDF(
        selectedPlano.id,
        TIPOS_TEMPLATE.TermoDeCancelamentoDeOrtodontiaComQuitacao
      )
    }
    setIsLoading(false)
  }

  return (
    <Fragment>
      <div className='mb-5 text-center'>
        <FontAwesomeIcon style={{ fontSize: '11em' }} className='text-success mt-5 mb-3' icon={faCheckCircle} />
        <h2 style={fontStyle} className='text-success'>
          Cancelamento realizado com sucesso!
        </h2>
      </div>
      <div className='d-block mx-auto text-center'>
        <Button variant='primary' className=' mr-3' onClick={handlePrintCancelamento}>
          {!isLoading ? 'Imprimir Termo de Cancelamento' : <Spinner animation='border' size='sm' variant='light' />}
        </Button>
        <Button variant='primary' className=' mr-3' onClick={handlePrintParcelamento}>
          Imprimir Parcelamento
        </Button>
        <Button variant='outline-primary' onClick={handleGoBack}>
          Voltar
        </Button>
      </div>
      <div className='alert alert-warning mt-5 mx-auto w-50'>
        <strong>Pop-ups</strong> bloquados no seu navegador, <strong>AdBlock</strong> habilitado para esta página, ou
        algumas <strong>extensões</strong> e <strong>add-ons</strong> instaladas podem ocasionar em problemas ao tentar
        imprimir os documentos desejados
      </div>
    </Fragment>
  )
}

const fontStyle = {
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
}

export default {
  ADICIONAR: 'Adicionar',
  EDITAR: 'Editar',
  DETALHAR: 'Detalhar',
  DESBLOQUEAR: 'Desbloquear',
  ATIVAR_INATIVAR: 'Ativar/Inativar',
  REABRIR_PROCEDIMENTO: 'ReabrirProcedimento',
  PERMITIR_ALTERAR_PRECO: 'PermitirAlterarPreco',
  EXCLUIR: 'Excluir',
  MARCARCOMOPAGO: 'MarcarComoPago',
  DESMARCARCOMOPAGO: 'DesmarcarComoPago',
}

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'

import { printParcelasReceipt } from '../../../Templates/PrintableReceipt'

export default function Impressao({ selectedPlano, pagamento, handleGoBackListaPlanos }) {
  const handlePrint = () => {
    printParcelasReceipt({
      numeroPlano: selectedPlano.numeroPlano,
      nomeContratante: selectedPlano.paciente.nome,
      nomePaciente: selectedPlano.dependente ? selectedPlano.dependente.nome : selectedPlano.paciente.nome,
      parcelas: pagamento.parcelas,
      valorTotal: Number(pagamento.valorTotal),
      type: 'suprimento'
    })
  }

  return (
    <div>
      <div className='mb-5 text-center'>
        <FontAwesomeIcon style={{ fontSize: '11em' }} className='text-success mt-5 mb-3' icon={faCheckCircle} />
        <h2 style={fontStyle} className='text-success'>
          Forma de pagamento registrada com sucesso!
        </h2>
      </div>
      <div className='d-block mx-auto text-center'>
        <Button variant='primary' onClick={handlePrint}>
          Imprimir Parcelamento
        </Button>
        <Button variant='outline-primary' className='ml-2' onClick={handleGoBackListaPlanos}>
          Voltar
        </Button>
      </div>
      <div className='alert alert-warning mt-5 mx-auto w-50'>
        <strong>Pop-ups</strong> bloquados no seu navegador, <strong>AdBlock</strong> habilitado para esta página, ou
        algumas <strong>extensões</strong> e <strong>add-ons</strong> instaladas podem ocasionar em problemas ao tentar
        imprimir os documentos desejados
      </div>
    </div>
  )
}

const fontStyle = {
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
}

import React, { useState, useEffect, useRef, Fragment } from 'react'
import { CSVLink } from 'react-csv'

const CsvDownload = ({ asyncExportMethod, children }) => {
  const [csvData, setCsvData] = useState(false)
  const csvInstance = useRef()
  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click()
        setCsvData(false)
      })
    }
  }, [csvData])

  return (
    <Fragment>
      <div
        onClick={async () => {
          const newCsvData = await asyncExportMethod()
          setCsvData(newCsvData)
        }}
      >
        {children}
      </div>
      {csvData ? (
        <CSVLink
          data={csvData.data}
          headers={csvData.headers}
          filename={csvData.filename}
          ref={csvInstance}
          title={csvData.filename}
          separator=';'
          target='_blank'
        />
      ) : (
        undefined
      )}
    </Fragment>
  )
}

export default CsvDownload

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AcompanhamentoRadiologia from '../../components/Ortodontia/AcompanhamentoRadiologia'

const mapStateToProps = (store) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AcompanhamentoRadiologia)

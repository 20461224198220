import React, { Fragment, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import Parcelas from './ParcelasList'
import CollapsePanel from '../../../../Common/CollapsePanel'
import { formatMoney } from '../../../../../utils/string.utils'
import Select from 'react-select'
import { formasDePagamento } from '../../../../../constants/common.constants'
import { toast } from 'react-toastify'

const Pagamento = ({
  parcelas,
  selectedParcelas,
  selecionarTodasParcelas,
  toggleParcela,
  setFormaPagamento,
  getSomaParcelasSelecioandas,
  nextStep,
  handleVoltar,
}) => {
  const [parcelasAgrupadas, setParcelasAgrupadas] = useState({})
  const [selectedFormaPagamento, setSelectedFormaPagamento] = useState(null)
  const INITIAL_OPEN_STATE = { step0: true }

  const [stepIsOpen, setStepIsOpen] = useState({ ...INITIAL_OPEN_STATE })

  useEffect(() => {
    let agrupamento = {}
    parcelas.forEach((parcela) => {
      if (!agrupamento[`${parcela.planoTratamentoPagamentoId}`]) {
        agrupamento[`${parcela.planoTratamentoPagamentoId}`] = []
      }
      agrupamento[parcela.planoTratamentoPagamentoId].push(parcela)
    })
    setParcelasAgrupadas(agrupamento)
  }, [parcelas])

  const toggleIsOpen = (step) => {
    let newSteps = { ...INITIAL_OPEN_STATE }
    Object.keys(parcelasAgrupadas).forEach((_, i) => {
      newSteps[`step${i}`] = false
    })
    setStepIsOpen({
      ...newSteps,
      [step]: !stepIsOpen[step],
    })
  }

  const handleChangeFormaPagamento = (formaPgto) => {
    setFormaPagamento(formaPgto)
    setSelectedFormaPagamento(formaPgto)
  }

  const handleNextStep = () => {
    if (!selectedFormaPagamento) {
      toast('Escolha uma forma de pagamento', { type: toast.TYPE.WARNING })
      return false
    }
    nextStep()
  }

  return (
    <Fragment>
      <h3 className='my-4 text-secondary font-weight-bold'>Efetuar pagamento</h3>
      {Object.keys(parcelasAgrupadas).map((pagamentoId, i) => (
        <CollapsePanel
          key={i}
          stepCode={`step${i}`}
          text={
            parcelasAgrupadas[pagamentoId][0].procedimentoProteticoNome
              ? parcelasAgrupadas[pagamentoId][0].procedimentoProteticoNome
              : `Procedimentos - ${parcelasAgrupadas[pagamentoId][0].numeroPlano}`
          }
          isOpen={stepIsOpen[`step${i}`]}
          toggleIsOpen={toggleIsOpen}
        >
          <Parcelas
            isProtetico={!!parcelasAgrupadas[pagamentoId][0].procedimentoProteticoNome}
            parcelas={parcelasAgrupadas[pagamentoId]}
            selecionarTodasParcelas={selecionarTodasParcelas}
            selectedParcelas={selectedParcelas}
            toggleParcela={toggleParcela}
          />
        </CollapsePanel>
      ))}
      {parcelas.length > 0 && (
        <div className='d-flex justify-content-between align-items-center pb-5 pt-3'>
          <h1 className='h4 m-0 text-secondary font-weight-bold flex-fill'>Total: {getSomaParcelasSelecioandas()} </h1>
          <div className='flex-fill'>
            <Select
              value={selectedFormaPagamento}
              onChange={handleChangeFormaPagamento}
              options={Object.values(formasDePagamento)}
              placeholder='Forma de pagamento'
              noOptionsMessage={() => 'Nenhuma forma de pagamento disponível'}
            />
          </div>
          <div className='flex-fill text-right'>
            <Button variant='primary' disabled={selectedParcelas.length < 1} onClick={handleNextStep}>
              Efetuar Pagamento
            </Button>
          </div>
        </div>
      )}
      <div style={backContainerStyle} onClick={handleVoltar}>
        <Button variant='outline-primary' style={backStyle}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <span className='text-primary'>Voltar</span>
      </div>
    </Fragment>
  )
}

export default Pagamento

const backContainerStyle = {
  cursor: 'pointer',
  width: 'fit-content',
}

const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

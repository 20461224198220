import React, { Fragment, useState, useEffect } from 'react'
import { Button, Col, Form, InputGroup, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import CurrencyInput from 'react-currency-input'
import { toast } from 'react-toastify'

import OrtodontiaService from '../../services/ortodontia.service'
import PageLoading from '../Common/PageLoading'
import CollapsePanel from '../Common/CollapsePanel'
import { formatMoney, handleDecimalConvert } from '../../utils/string.utils'

const CadastrarPlano = (props) => {
  const [isEdit, setIsEdit] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [planosCadastrados, setPlanosCadastrados] = useState([])
  const [nome, setNome] = useState('')
  const [prazo, setPrazo] = useState('')
  const [valorTotal, setValorTotal] = useState('')

  useEffect(() => {
    getPlanosCadastrados()
  }, [])

  const getPlanosCadastrados = async () => {
    try {
      const { data } = await OrtodontiaService.getPlanosCadastrados()
      setPlanosCadastrados(data)
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const resetFields = () => {
    setNome('')
    setPrazo('')
    setValorTotal('')
    setIsOpen(false)
    setIsEdit(null)
  }

  const handleSaveAdd = async () => {
    setIsLoading(true)
    const prazoNumber = Number(prazo)
    const valorTotalNumber = typeof valorTotal === 'string' ? +handleDecimalConvert(valorTotal) : valorTotal
    try {
      if (!nome) {
        toast(`O nome deve ser preenchido`, { type: toast.TYPE.ERROR })
      } else if (!prazoNumber) {
        toast(`O prazo deve ser maior do que zero`, { type: toast.TYPE.ERROR })
      } else if (!valorTotalNumber) {
        toast(`O valor do plano deve ser maior do que zero`, { type: toast.TYPE.ERROR })
      } else {
        await OrtodontiaService.cadastrarPlano({
          nome,
          prazo: prazoNumber,
          ativo: true,
          valorTotal: valorTotalNumber,
        })
        toast('Plano de tratamento cadastrado com sucesso!', { type: toast.TYPE.SUCCESS })
        resetFields()
        await getPlanosCadastrados()
      }
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleSaveEdit = async () => {
    setIsLoading(true)
    const prazoNumber = Number(prazo)
    const valorTotalNumber = typeof valorTotal === 'string' ? +handleDecimalConvert(valorTotal) : valorTotal
    try {
      if (!nome) {
        toast(`O nome deve ser preenchido`, { type: toast.TYPE.ERROR })
      } else if (!prazoNumber) {
        toast(`O prazo deve ser maior do que zero`, { type: toast.TYPE.ERROR })
      } else if (!valorTotalNumber) {
        toast(`O valor do plano deve ser maior do que zero`, { type: toast.TYPE.ERROR })
      } else {
        await OrtodontiaService.cadastrarPlano({
          ...planosCadastrados[isEdit],
          nome,
          prazo: prazoNumber,
          valorTotal: valorTotalNumber,
        })
        toast('Plano de tratamento editado com sucesso!', { type: toast.TYPE.SUCCESS })
        resetFields()
        await getPlanosCadastrados()
      }
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleEdit = (index) => {
    setIsEdit(index)
    setIsOpen(true)
    setNome(planosCadastrados[index].nome)
    setPrazo(planosCadastrados[index].prazo)
    setValorTotal(planosCadastrados[index].valorTotal)
  }

  const handleToggleAtivo = async (plano) => {
    setIsLoading(true)
    try {
      await OrtodontiaService.toggleAtivarPlano(plano)
      await getPlanosCadastrados()
    } catch (e) {}
    setIsLoading(false)
  }

  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
  }

  const handleChangeNome = (event) => {
    setNome(event.target.value)
  }

  const handleChangePrazo = (event) => {
    setPrazo(event.target.value.replace(/[^0-9]+/g, ''))
  }

  const handleChangeValor = (value) => {
    setValorTotal(value)
  }

  const renderCadastrar = () => (
    <div className='my-4'>
      <CollapsePanel stepCode='cadastrar' text='Formulário de Cadastro' isOpen={isOpen} toggleIsOpen={toggleIsOpen}>
        <Form>
          <Row>
            <Col>
              <Form.Group className='required'>
                <Form.Label>Nome do Plano:</Form.Label>
                <Form.Control type='text' name='nome' value={nome} onChange={handleChangeNome} required={isOpen} />
              </Form.Group>
            </Col>
            <Col md='3'>
              <Form.Group className='required'>
                <Form.Label>Número de Meses:</Form.Label>
                <Form.Control type='number' name='prazo' value={prazo} onChange={handleChangePrazo} required={isOpen} />
              </Form.Group>
            </Col>
            <Col md='3'>
              <Form.Group className='required'>
                <Form.Label>Valor Total:</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>R$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <CurrencyInput
                    className='form-control'
                    name='valorTotal'
                    decimalSeparator=','
                    thousandSeparator='.'
                    value={valorTotal}
                    onChange={handleChangeValor}
                    required={isOpen}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <div className='d-flex justify-content-end'>
            {(isEdit || isEdit === 0) && (
              <Button variant='outline-primary' className='mr-2' onClick={resetFields}>
                Cancelar
              </Button>
            )}
            <Button variant='primary' onClick={isEdit || isEdit === 0 ? handleSaveEdit : handleSaveAdd}>
              {isEdit || isEdit === 0 ? 'Editar' : 'Cadastrar'}
            </Button>
          </div>
        </Form>
      </CollapsePanel>
    </div>
  )

  const renderPlanosCadastrados = () => (
    <Fragment>
      {planosCadastrados.length > 0 ? (
        <Table size='sm' bordered hover className='bg-white'>
          <thead>
            <tr>
              <th>Plano</th>
              <th width='100px' className='text-center'>
                Prazo
              </th>
              <th width='150px' className='text-center'>
                Valor Total
              </th>
              <th width='150px' className='text-center'>
                Valor Parcela
              </th>
              <th width='110px' className='text-center'>
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            {planosCadastrados.map((plano, index) => (
              <tr style={{ fontSize: 'small' }} key={index}>
                <td>{plano.nome}</td>
                <td className='text-center'>{plano.prazo}</td>
                <td className='text-center'>{formatMoney(plano.valorTotal)}</td>
                <td className='text-center'>{formatMoney(plano.valorTotal / plano.prazo)}</td>
                <td className='text-center'>
                  <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Editar plano</Tooltip>}>
                    <Button onClick={() => handleEdit(index)} variant='primary' size='sm' className='mr-2'>
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    trigger='hover'
                    placement='top'
                    overlay={<Tooltip>{plano.ativo ? 'Desativar plano' : 'Ativar plano'}</Tooltip>}
                  >
                    <Button
                      onClick={() => handleToggleAtivo(plano)}
                      variant={plano.ativo ? 'success' : 'danger'}
                      size='sm'
                    >
                      <FontAwesomeIcon icon={faPowerOff} />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div>Nenhum plano de tratamento encontrado.</div>
      )}
    </Fragment>
  )

  return (
    <div>
      <h1 className='h3 m-0 text-secondary font-weight-bold'>Cadastrar Plano de Tratamento Ortodôntico</h1>
      {renderCadastrar()}
      {isLoading ? <PageLoading /> : renderPlanosCadastrados()}
    </div>
  )
}

export default CadastrarPlano

import React, { Fragment } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import MaskedInput from 'react-text-mask'
import isValidCep from '@brazilian-utils/is-valid-cep'
import { onlyNumbers } from '@brazilian-utils/helper-only-numbers'

import SharedService from './../../services/shared.service'

const NUMBER_REGEX = /^\d*$/

class Enderecos extends React.Component {
  state = {
    loadingCEP: false,
    invalidCEP: false,
    //nomeArquivoComprovanteResidencia: this.props.endereco.nomeArquivoComprovanteResidencia || '',
  }

  file = React.createRef()

  onBlurCEP = (e) => {
    e.preventDefault()
    if (isValidCep(e.target.value)) {
      this.setState({
        loadingCEP: true,
      })
      SharedService.consultarCEP(onlyNumbers(e.target.value))
        .then(({ data }) => {
          this.updateAddress(data)
        })
        .catch((error) => {
          this.setState({
            loadingCEP: false,
            invalidCEP: true,
          })
        })
    }
  }

  updateAddress = (data) => {
    const { endereco, index } = this.props

    if(data.logradouro == null){
      endereco.logradouro = ''
      endereco.bairro = ''
      endereco.cidade = ''
      endereco.estado = ''

      this.setState({
        loadingCEP: false,
        invalidCEP: true,
      })

      this.props.onChange(index, endereco)
      
      return
    }
    endereco.logradouro = data.logradouro
    endereco.bairro = data.bairro
    endereco.cidade = data.cidade
    endereco.estado = data.estado
    this.setState({
      loadingCEP: false,
      invalidCEP: false,
    })
    this.props.onChange(index, endereco)
  }

  // handleFile = (comprovanteResidencia) => {
  //   const { endereco, index } = this.props
  //   if (comprovanteResidencia) {
  //     endereco.comprovanteResidencia = comprovanteResidencia
  //     endereco.comprovanteResidenciaEntregue = true
  //   } else {
  //     endereco.comprovanteResidencia = null
  //     endereco.comprovanteResidenciaEntregue = null
  //   }
  //   this.props.onChange(index, endereco)
  // }

  onChange = (e) => {
    const { index, endereco } = this.props
    endereco[e.target.name] = e.target.value
    this.props.onChange(index, endereco)
  }

  onChangeNumero = ({ target }) => {
    const { index, endereco } = this.props
    if (NUMBER_REGEX.test(target.value)) {
      endereco[target.name] = target.value
    }
    this.props.onChange(index, endereco)
  }

  // onChangeCheckbox = (e) => {
  //   const { index, endereco } = this.props
  //   endereco.comprovanteResidenciaEntregue = e.target.checked
  //   this.props.onChange(index, endereco)
  // }
  //
  // handleRemoveFile = () => {
  //   this.setState({ ...this.state, nomeArquivoComprovanteResidencia: '' })
  // }
  //
  // handleDownloadFile = async () => {
  //   SharedService.downloadArquivo(this.state.nomeArquivoComprovanteResidencia)
  // }

  render() {
    const { endereco, disableAll } = this.props
    return (
      <Fragment>
        <Row className='mt-3'>
          <Col md='2'>
            <Form.Group className='required'>
              <Form.Label htmlFor='cep' className='mr-2'>
                CEP
              </Form.Label>
              {this.state.loadingCEP && <Spinner animation='grow' variant='primary' size='sm' />}
              <MaskedInput
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                className={this.state.invalidCEP ? 'form-control is-invalid' : 'form-control'}
                id='cep'
                name='cep'
                value={endereco.cep}
                onChange={this.onChange}
                onBlur={this.onBlurCEP}
                required
                disabled={this.state.loadingCEP || disableAll}
              />
              {this.state.invalidCEP && <div className='invalid-feedback'>CEP inválido</div>}
            </Form.Group>
          </Col>
          <Col md='2'>
            <Form.Group className='required'>
              <Form.Label htmlFor='numero'>Número</Form.Label>
              <Form.Control
                type='text'
                id='numero'
                name='numero'
                value={endereco.numero}
                onChange={this.onChangeNumero}
                required
                maxLength={10}
                disabled={disableAll}
              />
            </Form.Group>
          </Col>
          <Col md='2'>
            <Form.Group>
              <Form.Label htmlFor='complemento'>Complemento</Form.Label>
              <Form.Control
                type='text'
                id='complemento'
                name='complemento'
                value={endereco.complemento || ''}
                onChange={this.onChange}
                maxLength={100}
                disabled={disableAll}
              />
            </Form.Group>
          </Col>
          <Col md='6'>
            <Form.Group className='required'>
              <Form.Label htmlFor='logradouro'>Logradouro</Form.Label>
              <Form.Control
                type='text'
                id='logradouro'
                name='logradouro'
                value={endereco.logradouro}
                onChange={this.onChange}
                required
                maxLength={100}
                disabled={disableAll}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className='align-items-center'>
          <Col md='2'>
            <Form.Group className='required'>
              <Form.Label htmlFor='estado'>Estado</Form.Label>
              <Form.Control
                type='text'
                id='estado'
                name='estado'
                value={endereco.estado}
                onChange={this.onChange}
                maxLength={100}
                required
                readOnly
              />
            </Form.Group>
          </Col>
          <Col md='2'>
            <Form.Group className='required'>
              <Form.Label htmlFor='cidade'>Cidade</Form.Label>
              <Form.Control
                type='text'
                id='cidade'
                name='cidade'
                value={endereco.cidade}
                onChange={this.onChange}
                maxLength={100}
                required
                readOnly
              />
            </Form.Group>
          </Col>
          <Col md='2'>
            <Form.Group className='required'>
              <Form.Label htmlFor='bairro'>Bairro</Form.Label>
              <Form.Control
                type='text'
                id='bairro'
                name='bairro'
                value={endereco.bairro}
                onChange={this.onChange}
                maxLength={100}
                required
                readOnly
              />
            </Form.Group>
          </Col>
          <Col md='6' className='mt-2 pr-0'>
            {/* {this.props.needHomeProof && (
              <div className='d-flex align-items-center mt-1'>
                <div className='mr-3'>
                  <label className='checkbox mb-0'>
                    <input
                      type='checkbox'
                      checked={endereco.comprovanteResidenciaEntregue}
                      onChange={this.onChangeCheckbox}
                      disabled={disableAll}
                    />
                    <span className='d-flex align-items-center'>Comprovante de residência entregue</span>
                  </label>
                </div>
                <div className='d-flex align-items-baseline ml-2'>
                  {nomeArquivoComprovanteResidencia ? (
                    <div className='d-flex'>
                      <Button variant='primary' disabled={disableAll} onClick={this.handleDownloadFile}>
                        Baixar
                      </Button>
                      <Button
                        disabled={disableAll}
                        className='ml-2'
                        variant='outline-danger'
                        onClick={this.handleRemoveFile}
                      >
                        Remover
                      </Button>
                    </div>
                  ) : (
                    <FileUpload disableAll={disableAll} label='Carregar comprovante' onChange={this.handleFile} />
                  )}
                </div>
              </div>
            )} */}
          </Col>
        </Row>
      </Fragment>
    )
  }
}

export default Enderecos

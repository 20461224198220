import React, { useState, useEffect } from 'react'

import { QUADRANTES_DENTES } from '../../constants/anamnese.constants'
import DenteButton from '../Common/DenteButton'

const Odontograma = (props) => {
  const { dentes, toggleDentesSelecionados } = props

  const [dentesSupDir, setDentesSupDir] = useState([])
  const [dentesSupEsq, setDentesSupEsq] = useState([])
  const [dentesInfDir, setDentesInfDir] = useState([])
  const [dentesInfEsq, setDentesInfEsq] = useState([])
  const [dentesDeciduosSupDir, setDentesDeciduosSupDir] = useState([])
  const [dentesDeciduosSupEsq, setDentesDeciduosSupEsq] = useState([])
  const [dentesDeciduosInfDir, setDentesDeciduosInfDir] = useState([])
  const [dentesDeciduosInfEsq, setDentesDeciduosInfEsq] = useState([])

  useEffect(() => {
    if (Object.keys(dentes).length > 0) {
      setDentesSupDir(dentes[QUADRANTES_DENTES.SUPERIOR_DIREITO])
      setDentesSupEsq(dentes[QUADRANTES_DENTES.SUPERIOR_ESQUERDO])
      setDentesInfDir(dentes[QUADRANTES_DENTES.INFERIOR_DIREITO])
      setDentesInfEsq(dentes[QUADRANTES_DENTES.INFERIOR_ESQUERDO])

      setDentesDeciduosSupDir(dentes[QUADRANTES_DENTES.DECIDUO_SUPERIOR_DIREITO])
      setDentesDeciduosSupEsq(dentes[QUADRANTES_DENTES.DECIDUO_SUPERIOR_ESQUERDO])
      setDentesDeciduosInfDir(dentes[QUADRANTES_DENTES.DECIDUO_INFERIOR_DIREITO])
      setDentesDeciduosInfEsq(dentes[QUADRANTES_DENTES.DECIDUO_INFERIOR_ESQUERDO])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dentes])

  const toggleDentesQuadrante = (dentesQuadrante, quadrante, somenteDesmarcados = false) => {
    if (somenteDesmarcados) {
      dentesQuadrante.forEach((dente, i) => {
        !dente.ausente && toggleDentesSelecionados(quadrante, i)
      })
    } else {
      dentesQuadrante.forEach((dente, i) => {
        toggleDentesSelecionados(quadrante, i)
      })
    }
  }

  const todosDeciduosSelecionados = () => {
    const deciduosSupDir = !dentesDeciduosSupDir.find((d) => !d.ausente)
    const deciduosSupEsq = !dentesDeciduosSupEsq.find((d) => !d.ausente)
    const deciduosInfDir = !dentesDeciduosInfDir.find((d) => !d.ausente)
    const deciduosInfEsq = !dentesDeciduosInfEsq.find((d) => !d.ausente)
    return deciduosSupDir && deciduosSupEsq && deciduosInfDir && deciduosInfEsq
  }

  const toggleDeciduos = () => {
    if (todosDeciduosSelecionados()) {
      toggleDentesQuadrante(dentesDeciduosSupEsq, QUADRANTES_DENTES.DECIDUO_SUPERIOR_ESQUERDO)
      toggleDentesQuadrante(dentesDeciduosSupDir, QUADRANTES_DENTES.DECIDUO_SUPERIOR_DIREITO)
      toggleDentesQuadrante(dentesDeciduosInfDir, QUADRANTES_DENTES.DECIDUO_INFERIOR_DIREITO)
      toggleDentesQuadrante(dentesDeciduosInfEsq, QUADRANTES_DENTES.DECIDUO_INFERIOR_ESQUERDO)
    } else {
      toggleDentesQuadrante(dentesDeciduosSupEsq, QUADRANTES_DENTES.DECIDUO_SUPERIOR_ESQUERDO, true)
      toggleDentesQuadrante(dentesDeciduosSupDir, QUADRANTES_DENTES.DECIDUO_SUPERIOR_DIREITO, true)
      toggleDentesQuadrante(dentesDeciduosInfDir, QUADRANTES_DENTES.DECIDUO_INFERIOR_DIREITO, true)
      toggleDentesQuadrante(dentesDeciduosInfEsq, QUADRANTES_DENTES.DECIDUO_INFERIOR_ESQUERDO, true)
    }
  }

  const todosPermanentesSelecionados = () => {
    const permanentesSupDir = !dentesSupDir.find((d) => !d.ausente)
    const permanentesSupEsq = !dentesSupEsq.find((d) => !d.ausente)
    const permanentesInfDir = !dentesInfDir.find((d) => !d.ausente)
    const permanentesInfEsq = !dentesInfEsq.find((d) => !d.ausente)
    return permanentesSupDir && permanentesSupEsq && permanentesInfDir && permanentesInfEsq
  }

  const togglePermanentes = () => {
    if (todosPermanentesSelecionados()) {
      toggleDentesQuadrante(dentesSupDir, QUADRANTES_DENTES.SUPERIOR_DIREITO)
      toggleDentesQuadrante(dentesSupEsq, QUADRANTES_DENTES.SUPERIOR_ESQUERDO)
      toggleDentesQuadrante(dentesInfDir, QUADRANTES_DENTES.INFERIOR_DIREITO)
      toggleDentesQuadrante(dentesInfEsq, QUADRANTES_DENTES.INFERIOR_ESQUERDO)
    } else {
      toggleDentesQuadrante(dentesSupDir, QUADRANTES_DENTES.SUPERIOR_DIREITO, true)
      toggleDentesQuadrante(dentesSupEsq, QUADRANTES_DENTES.SUPERIOR_ESQUERDO, true)
      toggleDentesQuadrante(dentesInfDir, QUADRANTES_DENTES.INFERIOR_DIREITO, true)
      toggleDentesQuadrante(dentesInfEsq, QUADRANTES_DENTES.INFERIOR_ESQUERDO, true)
    }
  }

  return (
    <form className='pb-5 mb-5'>
      <div className='mx-auto col-md-8'>
        <div className='d-flex justify-content-between'>
          <h4 className='mb-5'>Selecione os dentes ausentes:</h4>
          <div className='d-flex'>
            <div className='mr-2'>
              <label className='checkbox'>
                <input type='checkbox' checked={todosDeciduosSelecionados()} onChange={toggleDeciduos} />
                <span className='d-flex align-items-center'>Nenhum dente decíduo</span>
              </label>
            </div>
            <div>
              <label className='checkbox'>
                <input type='checkbox' checked={todosPermanentesSelecionados()} onChange={togglePermanentes} />
                <span className='d-flex align-items-center'>Nenhum dente permanente</span>
              </label>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between my-4'>
          {dentesSupDir.map((dente, index) => (
            <DenteButton
              key={dente.denteId}
              dente={dente}
              onClick={() => toggleDentesSelecionados(QUADRANTES_DENTES.SUPERIOR_DIREITO, index)}
              isSelected={dente.ausente}
            />
          ))}
          {dentesSupEsq.map((dente, index) => (
            <DenteButton
              key={dente.denteId}
              dente={dente}
              onClick={() => toggleDentesSelecionados(QUADRANTES_DENTES.SUPERIOR_ESQUERDO, index)}
              isSelected={dente.ausente}
            />
          ))}
        </div>
        <div className='d-flex'>
          <div className='d-flex flex-fill'></div>
          <div className='d-flex justify-content-between flex-fill mb-2'>
            {dentesDeciduosSupDir.map((dente, index) => (
              <DenteButton
                key={dente.denteId}
                dente={dente}
                onClick={() => toggleDentesSelecionados(QUADRANTES_DENTES.DECIDUO_SUPERIOR_DIREITO, index)}
                isSelected={dente.ausente}
              />
            ))}
            {dentesDeciduosSupEsq.map((dente, index) => (
              <DenteButton
                key={dente.denteId}
                dente={dente}
                onClick={() => toggleDentesSelecionados(QUADRANTES_DENTES.DECIDUO_SUPERIOR_ESQUERDO, index)}
                isSelected={dente.ausente}
              />
            ))}
          </div>
          <div className='d-flex flex-fill'></div>
        </div>
        <div className='d-flex'>
          <div className='d-flex flex-fill'></div>
          <div className='d-flex justify-content-between flex-fill mb-4'>
            {dentesDeciduosInfDir.map((dente, index) => (
              <DenteButton
                key={dente.denteId}
                dente={dente}
                onClick={() => toggleDentesSelecionados(QUADRANTES_DENTES.DECIDUO_INFERIOR_DIREITO, index)}
                isSelected={dente.ausente}
              />
            ))}
            {dentesDeciduosInfEsq.map((dente, index) => (
              <DenteButton
                key={dente.denteId}
                dente={dente}
                onClick={() => toggleDentesSelecionados(QUADRANTES_DENTES.DECIDUO_INFERIOR_ESQUERDO, index)}
                isSelected={dente.ausente}
              />
            ))}
          </div>
          <div className='d-flex flex-fill'></div>
        </div>
        <div className='d-flex justify-content-between'>
          {dentesInfDir.map((dente, index) => (
            <DenteButton
              key={dente.denteId}
              dente={dente}
              onClick={() => toggleDentesSelecionados(QUADRANTES_DENTES.INFERIOR_DIREITO, index)}
              isSelected={dente.ausente}
            />
          ))}
          {dentesInfEsq.map((dente, index) => (
            <DenteButton
              key={dente.denteId}
              dente={dente}
              onClick={() => toggleDentesSelecionados(QUADRANTES_DENTES.INFERIOR_ESQUERDO, index)}
              isSelected={dente.ausente}
            />
          ))}
        </div>
      </div>
    </form>
  )
}

export default Odontograma

import { Templates } from '../types'

const initialState = {
	selectedTemplate: null,
}

export const templatesReducer = (state = initialState, action) => {
	switch (action.type) {
		case Templates.SELECT_TEMPLATE:
			return { ...state, selectedTemplate: action.payload }
		case Templates.RESET_TEMPLATE:
			return { ...state, selectedTemplate: null }
		default:
			return state
	}
}

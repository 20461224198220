import { connect } from 'react-redux'
import { requestVideos, editarVideo, toggleVideo, resetVideo } from '../../services/actions/videos.actions'
import { bindActionCreators } from 'redux'
import VideosList from '../../components/Videos/VideosList'

const mapStateToProps = (store) => ({
  videos: [...store.videosState.videos],
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestVideos, editarVideo, toggleVideo, resetVideo }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(VideosList)

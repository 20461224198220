import { connect } from 'react-redux'
import { finishReload } from '../../services/reducers/agendamentosDentista.reducer'
import { iniciarAvaliacao, iniciarTratamento } from '../../services/reducers/avaliacao.reducer'
import { bindActionCreators } from 'redux'

import AgendaDentista from '../../pages/Dashboard/Geral/AgendaDentista'

const mapStateToProps = (store) => ({  
  shouldReloadAgendamentos: store.agendamentosDentistaState.refreshAgendamentos,
})

const mapDispatchToProps = (dispatch) => {   
  return bindActionCreators({ iniciarAvaliacao, iniciarTratamento, finishReload },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AgendaDentista)

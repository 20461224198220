import React, { useState } from 'react'
import SidebarSubItem from './SidebarSubItem'
import { NavLink } from 'react-router-dom'

const isAtivo = (item, rotaAtual, possuiFilhos) => {
  if (!possuiFilhos) {
    return item.caminho === rotaAtual
  }

  const subMenusRotaAtual = item.subMenus.filter((subMenu) => subMenu.caminho === rotaAtual)

  return subMenusRotaAtual.length > 0
}

const montarItem = (item, requestAcoes, ativo) => {
  return (
    <li
      onClick={() => requestAcoes(item.id)}
      key={item.id}
      className={ativo ? 'sidebarItem activeItem' : 'sidebarItem'}
    >
      <NavLink
        to={item.caminho.toLowerCase()}
        activeClassName='active'
        className='d-flex align-items-center justify-content-between'
      >
        <span>{item.nome}</span>
      </NavLink>
    </li>
  )
}

const montarItemComFilhos = (item, requestAcoes, aberto, setAberto, ativo) => {
  return (
    <ul className={ativo ? 'list-unstyled activeItemComFilhos' : 'list-unstyled'}>
      <li onClick={() => setAberto(!aberto)} key={item.id} className='sidebarItemComFilhos'>
        <span>{item.nome}</span>
      </li>
      {aberto && item.subMenus.map((filho) => <SidebarSubItem item={filho} requestAcoes={requestAcoes} />)}
    </ul>
  )
}

const SidebarItem = (props) => {
  const { item, requestAcoes, rotaAtual } = props
  const possuiFilhos = item.subMenus !== undefined && item.subMenus.length > 0

  const ativo = isAtivo(item, rotaAtual, possuiFilhos)
  const [aberto, setAberto] = useState(ativo)

  if (possuiFilhos) {
    return montarItemComFilhos(item, requestAcoes, aberto, setAberto, ativo)
  }

  return montarItem(item, requestAcoes, ativo)
}

export default SidebarItem

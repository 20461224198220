import { Colaboradores } from '../types'

export const requestColaboradores = () => ({
  type: Colaboradores.REQUEST_COLABORADORES,
})

export const requestDentistas = (atualizouAgenda) => ({
  type: Colaboradores.REQUEST_DENTISTAS,
  atualizouAgenda,
})

export const requestDentistasByEspecialidade = (especialidadeId) => ({
  type: Colaboradores.REQUEST_DENTISTAS_BY_ESPECIALIDADE,
  especialidadeId,
})

export const requestCargos = () => ({ type: Colaboradores.REQUEST_CARGOS })

export const requestEspecialidades = (cargoId) => ({
  type: Colaboradores.REQUEST_ESPECIALIDADES,
  cargoId,
})

export const editarColaborador = (colaboradorId) => ({
  type: Colaboradores.SET_COLABORADOR_ID,
  colaboradorId,
})

export const resetColaborador = () => ({
  type: Colaboradores.RESET_COLABORADOR_ID,
  colaborador: {},
})

export const salvarColaborador = (colaborador) => ({
  type: Colaboradores.SALVAR_COLABORADOR,
  colaborador,
})

export const toggleColaborador = (colaboradorId, bloquear) => ({
  type: Colaboradores.TOGGLE_ACESSO_COLABORADOR,
  colaboradorId,
  bloquear,
})

export const inativarColaborador = (colaboradorId, ativar) => ({
  type: Colaboradores.INATIVAR_COLABORADOR,
  colaboradorId,
  ativar,
})

export const gerarNovaSenha = (colaboradorId) => ({
  type: Colaboradores.SOLICITAR_SENHA,
  colaboradorId,
})

export const alterarSenha = (colaboradorId) => ({
  type: Colaboradores.ALTERAR_SENHA,
  colaboradorId,
})

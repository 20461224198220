import React, { Fragment } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { formatMoney } from '../../../../utils/string.utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faTrash } from '@fortawesome/free-solid-svg-icons'

export default function ListaAparelhos({
  aparelhosSemPagamento,
  handleSelectAparelho,
  handleRecusar,
  handleGoBackListaPlanos,
}) {
  return (
    <Fragment>
      {aparelhosSemPagamento && aparelhosSemPagamento.length > 0 ? (
        <Table size='sm' bordered hover>
          <thead>
            <tr>
              <th>Aparelho</th>
              <th width='150px' className='text-center'>
                Valor
              </th>
              <th width='110px' className='text-center'>
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            {aparelhosSemPagamento.map((apar, index) => (
              <tr key={index}>
                <td>{apar.aparelhoComplementar ? apar.aparelhoComplementar.nome : '-'}</td>
                <td className='text-center'>{formatMoney(apar.valor)}</td>
                <td className='text-center d-flex justify-content-around'>
                  <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Definir pagamento</Tooltip>}>
                    <Button onClick={() => handleSelectAparelho(apar)} variant='primary' size='sm'>
                      <FontAwesomeIcon icon={faDollarSign} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Recusar aparelho</Tooltip>}>
                    <Button onClick={() => handleRecusar(apar)} variant='danger' size='sm'>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div>Nenhum aparelho complementar sem pagamento definido.</div>
      )}
      <Button variant='outline-primary' onClick={handleGoBackListaPlanos}>
        Voltar
      </Button>
    </Fragment>
  )
}

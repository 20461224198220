import React, { useEffect, useState, useContext, Fragment } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { format } from 'date-fns'
import MomentUtils from '@date-io/moment'
import { Row, Col, Button, Card, Form } from 'react-bootstrap'
import Select from 'react-select'
import financeiroService from '../../../services/financeiro.service'
import colaboradoresService from '../../../services/colaboradores.service'
import { formatMoney } from '../../../utils/string.utils'
import Paginator from '../../../components/Common/Paginator'
import ListaContas from '../../../components/Financeiro/Debitos/ListaContas'
import ModalConta from '../../../components/Financeiro/Debitos/ModalConta'
import ModalSubgrupos from '../../../components/Financeiro/Debitos/ModalSubgrupos'
import PageLoading from '../../../components/Common/PageLoading'
import { faCog, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SystemConstants from '../../../constants/system.constants'
import FranquiaService from '../../../services/franquias.service'
import MenuContext from '../../../contexts/MenuContexts'

export default function ContasPagar(props) {
  const porPagina = 18

  const [itensLista, setItensLista] = useState(null)
  const [itensListaPrint, setItensListaPrint] = useState(null)
  const [dateFilterDe, setDateFilterDe] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  const [dateFilterAte, setDateFilterAte] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [pagina, setPagina] = useState(0)
  const [showModalConta, setShowModalConta] = useState(false)
  const [showModalSubgrupos, setShowModalSubgrupos] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [colaboradoresOptions, setColaboradoresOptions] = useState([])
  const [resumo, setResumo] = useState([])
  const [resumoPrint, setResumoPrint] = useState([])
  const [selectedGrupo, setSelectedGrupo] = useState()
  const [grupoOptions, setGrupoOptions] = useState([])
  const [selectedSubGrupo, setSelectedSubGrupo] = useState()
  const [subGrupoOptions, setSubGrupoOptions] = useState([])
  const [selectedStatus, setSelectedStatus] = useState()
  const [statusOptions, setStatusOptions] = useState([])
  const [grupo, setGrupo] = useState([])
  const [subGrupo, setSubGrupo] = useState([])
  const [franquia, setFranquia] = useState([])
  const [conta, setConta] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [clearPageFilter, setClearPageFilter] = useState(false)
  const [gruposSubgrupos, setGruposSubgrupos] = useState([])
  const [usuarioLogado, setUsuarioLogado] = useState('')

  useEffect(() => {
    didMount()
    getPrimeiroDiaMesAtual()
    getUltimoDiaMesAtual()

  }, [pagina])

  const getPrimeiroDiaMesAtual = () => {
    let hoje = new Date()

    return new Date(hoje.getFullYear(), hoje.getMonth(), 1)
  }

  const getUltimoDiaMesAtual = () => {
    let hoje = new Date()

    return new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0)
  }

  const getFranquia = async () => {
    const franquiaId = localStorage.getItem(SystemConstants.getFranquia())

    setUsuarioLogado(JSON.parse(localStorage.getItem(SystemConstants.getUser())).name)
    try {
      const { data } = await FranquiaService.getFranquia(franquiaId)
      setFranquia(data)
    } catch (error) {}
  }

  const didMount = async () => {
    setIsLoading(true)

    
  
  if (!dateFilterDe) {
    setDateFilterDe(getPrimeiroDiaMesAtual())
  }
  if (!dateFilterAte) {
    setDateFilterAte(new Date())
  }

    await Promise.all([getColaboradores()])
    await Promise.all([getGrupos()])
    await Promise.all([getSubgrupos()])
    await Promise.all([getFranquia()])

    let status = [
      {label: 'Todos', value: null},
      {label: 'Pendente', value: 0},
      {label: 'Pago', value: 1},
    ]
    setStatusOptions(status)
    setSelectedStatus(status[0])
    getResumo()

    setIsLoading(false)
  }

  const getGrupos = async () => {
    try {
      let { data } = await financeiroService.getGruposContasPagar()

      let grupo = [{label: 'Todos', value: null}]
      data.map((g) => grupo.push({
        label: g.nome,
        value: g.id,
      }))
      setGrupo(data)
      setGrupoOptions(grupo)
      if(!selectedGrupo)
        setSelectedGrupo(grupo[0])

      let items = data.map((item) => {
        let retorno = item
        retorno.blockEdit = item.nome.toLowerCase() === 'salários' || item.nome.toLowerCase() === 'repasse profissionais'
        return retorno
      })
      
      setGruposSubgrupos(items)

    } catch (error) {
      console.error(error)
    }
  }

  const getSubgrupos = async () => {
    try {
      let { data } = await financeiroService.getSubGruposContasPagar()

      let subGrupo = [{label: 'Todos', value: null, ativo: true}]
      data.map((g) => subGrupo.push({
        label: g.nome,
        value: g.id,
        ativo: g.ativo
      }))
      setSubGrupo(data)
      setSubGrupoOptions(subGrupo)

      if(!selectedSubGrupo)
        setSelectedSubGrupo(subGrupo[0])

    } catch (error) {
      console.error(error)
    }
  }

  const getColaboradores = async () => {
    try {
      let { data } = await colaboradoresService.getAllColaboradores()
      setColaboradoresOptions(
        data.map((colaborador) => ({
          label: colaborador.nome,
          value: colaborador.id,
        }))
      )
    } catch (error) {
      console.error(error)
    }
  }

  const handlePagina = (data) => {
    setPagina(data.selected)
  }

  const editarConta = async (conta) => {
    setConta(conta)
    setIsEdit(true)
    setShowModalConta(true)
  }

  const getResumo = async () => {
    !isLoading && setIsLoading(true)

    if(!dateFilterDe)
      setDateFilterDe(getPrimeiroDiaMesAtual())

    if(!dateFilterAte)
      setDateFilterAte(new Date())

    let filtros = [
      {
        campo: 'dataInicial',
        valor: dateFilterDe,
      },
      {
        campo: 'dataFinal',
        valor: dateFilterAte,
      },
    ]

    if(selectedGrupo)
      filtros.push({campo: 'grupo', valor: selectedGrupo.value})

    if(selectedSubGrupo)
      filtros.push({campo: 'subgrupo', valor: selectedSubGrupo.value})

    if(selectedStatus)
      filtros.push({campo: 'status', valor: selectedStatus.value})

    await getDespesasGerais(filtros)

    await getDespesasGeraisPrint(filtros)

    setIsLoading(false)
  }

  const toggleModalConta = async () => {
    setIsEdit(false)

    setShowModalConta(!showModalConta)
  }

  const handleCloseModalSubgrupos = () => {
    setIsEdit(false)
    if(showModalSubgrupos){
      handleLimpar()
    }

    setShowModalSubgrupos(false)
  }

  const toggleModalSubgrupos = () => {
    setIsEdit(true)

    setShowModalSubgrupos(true)
  }

  const getDespesasGerais = async (filtros) => {
    try {

      var page = pagina + 1;

      if(clearPageFilter){
        page = 1;
        setClearPageFilter(false);
      }

      const { data } = await financeiroService.getContasAPagar(filtros, page, porPagina)
      setItensLista(data.lista.dados)
      setTotalPaginas(data.lista.totalPaginas)
      setResumo(data.resumo)
    } catch (error) {}
  }

  const getDespesasGeraisPrint = async (filtros) => {
    try {
      const { data } = await financeiroService.getContasAPagar(filtros, 0, 0)
      setItensListaPrint(data.lista.dados)
      setResumoPrint(data.resumo)
    } catch (error) {}
  }

  const handleDateFilterDe = (date) => {
    if (date)
      setDateFilterDe(date.toDate())
    else
      setDateFilterDe(null)

    setPagina(0)
    setClearPageFilter(true)
  }

  const handleDateFilterAte = (date) => {
    if (date) 
      setDateFilterAte(date.toDate())
    else 
      setDateFilterAte(null)

    setPagina(0)
    setClearPageFilter(true)
  }

  const handleGrupo = (e) => {

    setSelectedGrupo(e)
    let subGrupoDado = [{label: 'Todos', value: null, ativo: true}]
    if(e.value){
      grupo.find(x => x.id == e.value).subGruposContasPagar.filter(y => y.ativo).map((g) => subGrupoDado.push({
        label: g.nome,
        value: g.id,
        ativo: g.ativo
      }))

      grupo.find(x => x.id == e.value).subGruposContasPagar.filter(y => !y.ativo).map((g) => subGrupoDado.push({
        label: g.nome,
        value: g.id,
        ativo: g.ativo
      }))
    }
    else
    {
      subGrupo.map(s => subGrupoDado.push({
        label: s.nome,
        value: s.id,
        ativo: s.ativo,
      }))
    }
    setSubGrupoOptions(subGrupoDado)  

    setSelectedSubGrupo(subGrupoDado[0])
    
    setClearPageFilter(true)
  }

  const handleSubGrupo = (e) => {
    
    setSelectedSubGrupo(e)
    
    if(!e.value)
      return
    
    let idGrupoSelecionado = subGrupo.find(x => x.id == e.value).grupoContasPagarId
    setSelectedGrupo(grupoOptions.find(x => x.value == idGrupoSelecionado))

    setClearPageFilter(true)
  } 

  const handlePesquisar = (e) => {
    getResumo()
  }

  const handleStatus = e => {
    setSelectedStatus(e);
    setClearPageFilter(true)
  }

  const handleImprimir = (e) => {
    let suaString = `<!DOCTYPE html><html>
    <head>
    <style type="text/css" media="print">
      @page { size: landscape; filename: Contas a Pagar}
      
    </style>
    </head>
    <body>${renderPrint()}</body></html>`
    
    var wnd = window.open("Contas a Pagar");
    wnd.document.write(suaString);
    wnd.print()
    wnd.document.title = "Contas a Pagar";
  }

  const handleLimpar = async (e) => {
    setDateFilterDe(getPrimeiroDiaMesAtual())
    setDateFilterAte(getUltimoDiaMesAtual())

    setSelectedGrupo(grupoOptions[0])
    setSelectedSubGrupo(subGrupoOptions[0])
    setSelectedStatus(statusOptions[0])

    handleGrupo(grupoOptions[0])
  }
  
  const renderLista = () => {
    
  return (
    <Fragment>
      <h1 className='h3 m-0 text-secondary font-weight-bold'>Contas a Pagar</h1>
      <Row className='my-4'>
        <Col md='2'>
          <Form.Label>Vencimento de:</Form.Label>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              invalidDateMessage='Formato de data inválido'
              value={dateFilterDe}
              onChange={handleDateFilterDe}
              className='form-control'
              variant='inline'
              format='DD/MM/YYYY'
              maxDate={dateFilterAte || new Date('2100-01-01')}
              animateYearScrolling
              autoOk
              placeholder='De'
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col md='2'>
          <Form.Label>Vencimento até:</Form.Label>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              invalidDateMessage='Formato de data inválido'
              value={dateFilterAte}
              onChange={handleDateFilterAte}
              minDate={dateFilterDe || new Date('1900-01-01')}
              className='form-control'
              variant='inline'
              format='DD/MM/YYYY'
              animateYearScrolling
              autoOk
              placeholder='Até'
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col>
          <Button className='ml-auto' variant='primary' onClick={toggleModalConta} style={{float: 'right'}}>
            Adicionar Conta a Pagar
          </Button>
          <Button className='ml-auto' variant='outline-primary' onClick={toggleModalSubgrupos} style={{marginRight: '10px', float: 'right'}}>
            <FontAwesomeIcon icon={faCog} className='mr-1' />
            Gerenciar Subgrupos
          </Button>
        </Col>
      </Row>
      <Row className='my-4'>
        <Col md='3'>
          <Form.Group className=''>
            <Form.Label>Grupo:</Form.Label>
            <Select
              placeholder='grupo...'
              value={selectedGrupo}
              options={grupoOptions}
              onChange={handleGrupo}
              noOptionsMessage={() => 'Nenhuma opção disponível'}
            />
          </Form.Group>
        </Col>
        { selectedGrupo.value && subGrupoOptions.length > 1 &&
        <Col md='2'>
          <Form.Group className=''>
            <Form.Label>Subgrupo:</Form.Label>
            <Select
              placeholder='subgrupo...'
              value={selectedSubGrupo}
              options={subGrupoOptions}
              onChange={handleSubGrupo}
              noOptionsMessage={() => 'Nenhuma opção disponível'}
              styles={customStyles}
            />
          </Form.Group>
        </Col>
        } 
        <Col md='2'>
          <Form.Group className=''>
            <Form.Label>Status:</Form.Label>
            <Select
              placeholder='status...'
              value={selectedStatus}
              options={statusOptions}
              onChange={handleStatus}
              noOptionsMessage={() => 'Nenhuma opção disponível'}
            />
          </Form.Group>
        </Col>
        <Col>
          <Button className='ml-auto' variant='outline-danger' onClick={handleLimpar} style={buttonFilters}>
            Limpar
          </Button>
          <Button className='ml-auto' variant='primary' onClick={handlePesquisar} style={buttonSearch}>
            Pesquisar
          </Button>
          <Button className='ml-auto' variant='outline-secondary' onClick={handleImprimir} style={buttonPrint}>
          <FontAwesomeIcon icon={faPrint} className='mr-1' />
            Imprimir
          </Button>
        </Col>
      </Row>

      {itensLista && itensLista.length > 0 ? (
        <Fragment>
          <Card className='mb-4 py-2'>
            <Row>
              {resumo &&
                resumo.map(({ descricao, valor }, index) => (
                  <Col key={index} className='d-flex align-items-center justify-content-center flex-column'>
                    <small className='text-center text-muted m-0'>
                      {!!descricao ? descricao.toUpperCase() : '-'}
                    </small>
                    <h4 className='text-center m-0 font-weight-bold'>
                      {!!valor ? formatMoney(valor) : formatMoney(0)}
                    </h4>
                  </Col>
                ))}
            </Row>
          </Card>
          <ListaContas setIsLoading={setIsLoading} update={getResumo} itensLista={itensLista} editarConta={editarConta}/>
        </Fragment>
    ) : (
      !isLoading && <div>Nenhum registro encontrado</div>
    )}

    {totalPaginas > 1 && <Paginator handlePagina={handlePagina} pageCount={totalPaginas} paginaAtual={pagina} />}
    {showModalConta && (
      <ModalConta
        colaboradoresOptions={colaboradoresOptions}
        isOpen={showModalConta}
        handleClose={toggleModalConta}
        handleUpdate={getResumo}
        conta={conta}
        isEdit={isEdit}
        getColaboradores={getColaboradores}
        handleUpdateFilterGrupo={getGrupos}
      />
    )}
    {showModalSubgrupos && (
      <ModalSubgrupos
        colaboradoresOptions={colaboradoresOptions}
        isOpen={showModalSubgrupos}
        handleClose={handleCloseModalSubgrupos}
        dadosGrupoSubGrupos={gruposSubgrupos}
        handleUpdateFilterGrupo={getGrupos}
      />
    )}
  </Fragment>
  )}  

  if (isLoading) {
    return <PageLoading />
  }

  const renderPrint = () => {
    //FILTROS
    let htmlFiltros = `<h3 style="margin:0">Filtros</h3>
      Pagamentos:<b> de ${format(dateFilterDe, 'dd/MM/yyyy')} até ${format(dateFilterAte, 'dd/MM/yyyy')}</b><br>`

    if(selectedGrupo.value)
      htmlFiltros += `Grupo: <b>${selectedGrupo.label}</b><br>`

    if(selectedSubGrupo.value)
      htmlFiltros += `Subgrupo: <b>${selectedSubGrupo.label}</b><br>`

    if(selectedStatus.value)
      htmlFiltros += `Status: <b>${selectedStatus.label}</b><br>`

    //RESUMO
    let htmlResumo = ''
    {resumoPrint.map(({ descricao, valor }, index) => (
      htmlResumo += `
        <div style="float:left; width:33.33%; text-align: center">
            <small style="color: #6c757d !important; font-size: 80%; font-weight: 400;">${!!descricao ? descricao.toUpperCase() : '-'}</small>
            <h4 style="font-weight: 700 !important; font-size: 1.3125rem;margin: 0">${!!valor ? formatMoney(valor) : formatMoney(0)}</h4>
        </div>`
    ))}

    //DADOS DA TABELA
    let htmlTabela = ''
    {itensListaPrint.map((item, i) => (
        htmlTabela += `
        <tr style="font-size: smaller; height: 45px; color: #212529; border: 1px solid #dee2e6;">
          <td style="vertical-align: middle; border-bottom: 1px solid #dee2e6; font-weight: 700 !important; text-align: center !important;">${item.descricao || item.descricao.toUpperCase()}</td>
          <td style="vertical-align: middle; border-bottom: 1px solid #dee2e6; text-align: center !important;">${item.grupoContasPagarId ? item.grupoContasPagar.nome.toUpperCase() : '-'}</td>
          <td style="vertical-align: middle; border-bottom: 1px solid #dee2e6; text-align: center !important;">${(item.subGrupoContasPagar && item.subGrupoContasPagar.nome) || (item.colaborador && item.colaborador.nome) || '-'}</td>
          <td style="vertical-align: middle; border-bottom: 1px solid #dee2e6; text-align: center !important;">${item.dataLancamento ? format(new Date(item.dataLancamento), 'dd/MM/yyyy') : '-'}</td>
          <td style="vertical-align: middle; border-bottom: 1px solid #dee2e6; text-align: center !important;">${item.dataVencimento ? format(new Date(item.dataVencimento), 'dd/MM/yyyy') : '-'}</td>
          <td style="vertical-align: middle; border-bottom: 1px solid #dee2e6; text-align: center !important;">${item.valor ? formatMoney(item.valor) : '-'}</td>
          <td style="vertical-align: middle; border-bottom: 1px solid #dee2e6; text-align: center !important;border-right: 1px solid #dee2e6;">${item.status == 1 ? 'Pago' : 'Em aberto'}</td>
        </tr>`
    ))}

    //FOOTER DA TABELA
    let htmlFooter = `Clínica - ${format(new Date(), 'dd/MM/yyyy HH:mm:ss')}`

    let table = `
  
      <div id="contas-pagar" style="width:100%; font-family: 'Roboto', sans-serif; font-size: 0.875rem; font-weight: 400; line-height: 1.5; color: #212529; text-align: left;">
        <div id="cabecalho">
          <h1 style="color: #6c757d">Contas a Pagar</h1>
          
          <span style="color: #6c757d !important;padding-top:10px;">${htmlFiltros}</h1>

          <div style="margin-top:30px; width:auto; border: solid 1px black; height: 45px; padding: 0.5rem; word-wrap: break-word; background-color: #fff; background-clip: border-box; border: 1px solid rgba(0, 0, 0, 0.125); border-radius: 3px; margin-bottom:20px">
            ${htmlResumo}
          </div>
        </div>
        <table style="width: 100%; table-layout: fixed; background-color: #fff !important; margin-bottom: 1rem; border-collapse: collapse;border: border: none;">
            <thead>
              <tr style="color: #737373; border: 2px solid #dee2e6;">
                <th width="30%" style="vertical-align: bottom; padding: 0.3rem;text-align: center; border-bottom: 2px solid #dee2e6;">Descrição</th>
                <th width="17%" style="vertical-align: bottom; padding: 0.3rem;text-align: center; border-bottom: 2px solid #dee2e6;">Grupo</th>
                <th width="17%" style="vertical-align: bottom; padding: 0.3rem;text-align: center; border-bottom: 2px solid #dee2e6;">SubGrupo/Colaborador</th>
                <th width="12%" style="vertical-align: bottom; padding: 0.3rem;text-align: center; border-bottom: 2px solid #dee2e6;">Lançado em</th>
                <th width="8%" style="vertical-align: bottom; padding: 0.3rem;text-align: center; border-bottom: 2px solid #dee2e6;">Vencimento</th>
                <th width="8%" style="vertical-align: bottom; padding: 0.3rem;text-align: center; border-bottom: 2px solid #dee2e6;">Valor</th>
                <th width="8%" style="vertical-align: bottom; padding: 0.3rem;text-align: center; border-bottom: 2px solid #dee2e6; border-right: 1px solid #dee2e6;">Status</th>
              </tr>
            </thead>
            <tbody>
              ${htmlTabela}
            </tbody>
            <tfoot>
              <tr>
                <td colspan="8" style="padding-top:20px; text-align:left">
                  <span style="width: 33%; text-align:left; display: inline-block;">${franquia.nomeFantasia}</span>
                  <span style="width: 33%; text-align:center; display: inline-block;">${format(new Date(), 'dd/MM/yyyy HH:mm:ss')}</span>
                  <span style="width: 33%; text-align:right; display: inline-block;">${usuarioLogado}</span>
                </td>
              </tr>
            </tfoot>
        </table>
      </div>`
    
    return table
  }

  return renderLista()
}

const buttonPrint = {
  position: 'absolute',
  right: '20px',
  height: '38px',
  bottom: '16px'
}

const buttonSearch = {
  left: '80px',
  position: 'absolute',
  height: '38px',
  bottom: '16px'
}

const buttonFilters = {
  left: '0',
  position: 'absolute',
  height: '38px',
  bottom: '16px'
}

const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const backgroundColor = isSelected ? '#2684FF' : isFocused ? '#DEEBFF' : data.ativo ? 'transparent' : '#D3D3D3'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
}
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ProcedimentoManutencao from '../../components/Ortodontia/ProcedimentoManutencao'

const mapStateToProps = (store) => ({
  agendamento: store.avaliacaoState.agendamento,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProcedimentoManutencao)

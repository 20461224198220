import { perfisReducer } from './perfis.reducer'
import { permissoesReducer } from './permissoes.reducer'
import { pacientesReducer } from './pacientes.reducer'
import { colaboradoresReducer } from './colaboradores.reducer'
import { videosReducer } from './videos.reducer'
import { procedimentosReducer } from './procedimento.reducer'
import { agendamentosDentistaReducer } from './agendamentosDentista.reducer'
import { agendamentosReducer } from './agendamentos.reducer'
import { accountReducer } from './account.reducer'
import { avaliacaoReducer } from './avaliacao.reducer'
import { combineReducers } from 'redux'
import { anamneseReducer } from './anamnese.reducer'
import { notificacoesReducer } from './notificacoes.reducer'
import { modalsReducer } from './modals.reducer'
import { templatesReducer } from './templates.reducer'

export const Reducers = combineReducers({
  perfisState: perfisReducer,
  permissoesState: permissoesReducer,
  pacientesState: pacientesReducer,
  colaboradoresState: colaboradoresReducer,
  videosState: videosReducer,
  procedimentosState: procedimentosReducer,
  agendamentosDentistaState: agendamentosDentistaReducer,
  agendamentosState: agendamentosReducer,
  accountState: accountReducer,
  avaliacaoState: avaliacaoReducer,
  anamneseState: anamneseReducer,
  notificacoesState: notificacoesReducer,
  modalsState: modalsReducer,
  templatesState: templatesReducer,
})

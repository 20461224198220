import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Col, Button, Form, Spinner } from 'react-bootstrap'
import DualListBox from 'react-dual-listbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleDoubleLeft, faAngleRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import 'react-dual-listbox/lib/react-dual-listbox.css'
import { toast } from 'react-toastify'

import PerfilService from '../../services/perfil.service'
import PageLoading from '../Common/PageLoading'

export default class PerfilForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isSaveLoading: false,
      funcionalidades: [],
      perfil: {
        nome: '',
        descricao: '',
        formValido: false,
        selectedFuncionalidades: [],
        funcionalidades: [],
      },
    }
    this.list = React.createRef()
  }

  componentDidMount() {
    this.setState({ ...this.state, isLoading: true }, async () => {
      const { selectedPerfil } = this.props
      let mappedFuncionalidades = []
      if (selectedPerfil) {
        mappedFuncionalidades = await this.getPerfilById(selectedPerfil)
      }
      await this.getFuncionalidades(mappedFuncionalidades)
      this.setState({ ...this.state, isLoading: false })
    })
  }

  getFuncionalidades = async (funcionalidadesPerfil) => {
    try {
      const { data } = await PerfilService.getFuncionalidades()
      const funcionalidades = data.filter(x => !x.isPai).map((p) => {
        let funcionalidade = {
          value: p.permissaoId,
          label: p.nomeMenu || p.nomeAcao,
          options: p.acoes
            ? p.acoes.map((a) => ({
                label: a.nomeAcao,
                value: a.permissaoId,
              }))
            : undefined,
        }
        p.acoes &&
          funcionalidade.options.push({
            label: 'Visualizar',
            value: p.permissaoId,
          })
        return funcionalidade
      })
      this.setState({ ...this.state, funcionalidades })
    } catch (e) {}
  }

  getPerfilById = async (perfilId) => {
    try {
      const { data } = await PerfilService.getPerfilById(perfilId)
      data.selectedFuncionalidades = this.mapFuncionalidades(data.funcionalidades)
      this.setState({ ...this.state, perfil: data })
      return data.selectedFuncionalidades
    } catch (error) {
      console.error(error)
      return []
    }
  }

  mapFuncionalidades = (funcionalidades) => {
    const resultMap = []
    funcionalidades.forEach((f) => {
      if (f.acoes.length > 0) {
        f.acoes.forEach((a) => {
          resultMap.push(a.permissaoId)
        })
      }
      resultMap.push(f.permissaoId)
    })
    return resultMap
  }

  onChangeFuncionalidades = (selectedFuncionalidades) => {
    this.setState({ ...this.state, perfil: { ...this.state.perfil, selectedFuncionalidades } })
  }

  onChangeText = (e) => {
    this.setState({ ...this.state, perfil: { ...this.state.perfil, [e.target.name]: e.target.value } })
  }

  fetchFuncionalidadesSelecionadas = () => {
    let resultFetch = []
    const { funcionalidades, perfil } = this.state
    const { selectedFuncionalidades } = perfil
    resultFetch = funcionalidades.map((f) => {
      f = {
        nomeMenu: f.label,
        permissaoId: f.value,
        acoes: f.options
          .filter((a) => {
            return selectedFuncionalidades.indexOf(a.value) > -1
          })
          .map((a) => ({
            permissaoId: a.value,
            nomeAcao: a.label,
          })),
      }
      if (f.acoes.length > 0) {
        return f
      }
      return false
    })
    return resultFetch.filter((result) => {
      return result
    })
  }

  salvarPerfil = (perfil) => {
    this.setState({ ...this.state, isSaveLoading: true }, async () => {
      try {
        await PerfilService.salvarPerfil(perfil)
        toast('Perfil salvo com sucesso', { type: toast.TYPE.SUCCESS })
        this.props.history.push('/perfis')
      } catch (e) {
      } finally {
        this.setState({ ...this.state, isSaveLoading: false })
      }
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const request = { ...this.state.perfil }
    request.funcionalidades = this.fetchFuncionalidadesSelecionadas()
    if (request.funcionalidades.length === 0) {
      toast('Adicione permissões a este perfil', { type: toast.TYPE.ERROR })
      return
    }
    this.salvarPerfil(request)
  }

  renderLoading = () => <PageLoading />

  renderForm = () => {
    const { funcionalidades, perfil, isSaveLoading } = this.state
    const { selectedFuncionalidades, nome, descricao } = perfil
    return (
      <Form className='mt-2 required' onSubmit={this.onSubmit}>
        <Form.Row>
          <Form.Group as={Col} className='mt-2 required'>
            <Form.Label>Nome</Form.Label>
            <Form.Control
              required
              onChange={this.onChangeText}
              name='nome'
              value={nome}
              placeholder='Digite o nome do novo perfil'
            />
            <Form.Control.Feedback>Dê um nome ao novo perfil</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} className='mt-2 required'>
            <Form.Label>Descrição</Form.Label>
            <Form.Control
              required
              onChange={this.onChangeText}
              name='descricao'
              value={descricao}
              as='textarea'
              rows='4'
              placeholder='Este perfil é responsável por fazer as funcionalidades seleiconadas'
            />
            <Form.Control.Feedback>Dê uma descrição ao novo perfil</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} className='mt-2 required'>
            <Form.Label>Permissões</Form.Label>
            <DualListBox
              icons={{
                moveLeft: <FontAwesomeIcon icon={faAngleLeft} />,
                moveAllLeft: <FontAwesomeIcon icon={faAngleDoubleLeft} />,
                moveRight: <FontAwesomeIcon icon={faAngleRight} />,
                moveAllRight: <FontAwesomeIcon icon={faAngleDoubleRight} />,
              }}
              canFilter
              filterPlaceholder='Pesquisar...'
              options={funcionalidades}
              selectedRef={(ref) => {
                this.list = ref
              }}
              selected={selectedFuncionalidades}
              onChange={this.onChangeFuncionalidades}
            />
            <Form.Control.Feedback>Defina as permissões deste novo perfil</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} className='mt-2 d-flex justify-content-end'>
            <Link to='/perfis' className='btn btn-secondary mr-3'>
              Cancelar
            </Link>
            <Button variant='primary' type='submit'>
              {!isSaveLoading ? 'Salvar' : <Spinner animation='border' variant='light' size='sm' />}
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
    )
  }

  render() {
    return (
      <Fragment>
        <h1 className='h3 m-0 text-secondary font-weight-bold'>
          {`${this.props.selectedPerfil ? 'Editar' : 'Cadastrar'} perfil`}
        </h1>
        {this.state.isLoading ? this.renderLoading() : this.renderForm()}
      </Fragment>
    )
  }
}

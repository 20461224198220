import React, { Fragment, useState, useEffect } from 'react'
import MaskedInput from 'react-text-mask'
import { Row, Form, Col, Button } from 'react-bootstrap'
import formatCnpj from '@brazilian-utils/format-cnpj'
import { isValidCnpj } from '@brazilian-utils/is-valid-cnpj'

import { Enderecos, Telefones } from '../../Common'
import { cnpjMask } from '../../../utils/mask.utils'
import { toast } from 'react-toastify'

export default function RadiologiaForm({ handleAddClinica, clinicaEdit, handleCancelEdit, isOpen }) {
  const [nome, setNome] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [telefone, setTelefone] = useState({ numero: '' })
  const [endereco, setEndereco] = useState({})

  useEffect(() => {
    if (clinicaEdit) {
      setNome(clinicaEdit.nome)
      setCnpj(formatCnpj(clinicaEdit.cnpj))
      setTelefone({ numero: clinicaEdit.telefone })
      setEndereco(clinicaEdit.endereco)
    } else {
      clearFields()
    }
  }, [clinicaEdit])

  const handleTelefone = (index, novoTelefone) => {
    setTelefone({ ...novoTelefone })
  }
  const handleEndereco = (index, novoEndereco) => {
    setEndereco({ ...novoEndereco })
  }

  const handleChangeNome = ({ target }) => {
    setNome(target.value)
  }

  const handleChangeCnpj = ({ target }) => {
    setCnpj(target.value)
  }

  const clearFields = () => {
    setNome('')
    setCnpj('')
    setTelefone({ numero: '' })
    setEndereco({})
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    if (!isValidCnpj(cnpj)) {
      toast('CNPJ inválido!', { type: toast.TYPE.ERROR })
    } else if (telefone.numero.length < 13) {
      toast('Telefone inválido!', { type: toast.TYPE.ERROR })
    } else {
      await handleAddClinica(
        {
          ...clinicaEdit,
          ativo: clinicaEdit ? clinicaEdit.ativo : true,
          nome,
          cnpj,
          telefone: telefone.numero,
          endereco,
        },
        clearFields
      )
    }
  }

  return (
    <Fragment>
      <Form onSubmit={onSubmit}>
        <Row style={{ marginBottom: '-5px' }}>
          <Form.Group className='required' as={Col}>
            <Form.Label htmlFor='nomePai'>Nome</Form.Label>
            <Form.Control required type='text' value={nome} onChange={handleChangeNome} maxLength={100} />
          </Form.Group>
          <Form.Group className='required' as={Col}>
            <Form.Label htmlFor='nomePai'>CNPJ</Form.Label>
            <MaskedInput
              className={cnpj && !isValidCnpj(cnpj) ? 'is-invalid form-control' : 'form-control'}
              type='text'
              value={cnpj}
              onChange={handleChangeCnpj}
              mask={cnpjMask}
              required
            />
          </Form.Group>
          <Col>
            <Telefones index={0} telefone={telefone} required onChange={handleTelefone} />
          </Col>
        </Row>
        <Row>
          <Col>{isOpen && <Enderecos required endereco={endereco} onChange={handleEndereco} />}</Col>
        </Row>
        <div className='d-flex align-items-center justify-content-end'>
          {clinicaEdit && (
            <Button className='float-right mr-2' variant='outline-primary' onClick={handleCancelEdit}>
              Cancelar
            </Button>
          )}
          <Button className='float-right' variant='primary' type='submit'>
            {clinicaEdit ? 'Editar' : 'Cadastrar'}
          </Button>
        </div>
      </Form>
    </Fragment>
  )
}

import React from 'react'
import CountUp from 'react-countup'

export default function AnimatedCounter(props) {
  const { label, value, duration, labelStyle, valueStyle, containerStyle } = props
  return (
    <CountUp
      className='text-center'
      end={value}
      duration={duration}
      start={0}
      separator='.'
      decimals={0}
      delay={0}
      decimal=','
      startOnMount
    >
      {({ countUpRef }) => {
        return (
          <div style={containerStyle}>
            <span className='text-secondary' style={labelStyle}>
              {label}
            </span>
            <span>
              <span className='' style={valueStyle} ref={countUpRef} />
              {props.children}
            </span>
          </div>
        )
      }}
    </CountUp>
  )
}

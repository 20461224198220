import { put, takeLeading } from "redux-saga/effects";

import axios from "./axios-client";
import { Account, Notificacoes } from "../types";
import SystemConstants from "../../constants/system.constants";

function* login(action) {
  try {
    const { data } = yield axios.post("Account/login", action.request);
    localStorage.setItem(SystemConstants.getTokenKey(), data.accessToken);
    yield put({
      type: Account.SET_USER,
      user: data
    });
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications });
  }
}

function* accountListener() {
  yield takeLeading(Account.LOGIN, login);
}

export default accountListener;

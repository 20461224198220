import { connect } from 'react-redux'
import { requestAcoes, requestMenu } from '../../services/actions/perfis.actions'
import { bindActionCreators } from 'redux'

import NavigatorWrapper from '../../App/NavigatorWrapper'

const mapStateToProps = (store) => {
  let mesesAConfigurar = store.accountState.user.mesesAConfigurar
  if (typeof mesesAConfigurar === 'string') {
    mesesAConfigurar = mesesAConfigurar.split(`,`)
  }
  return {
    mesesAConfigurar,
    acoes: store.perfisState.acoes,
    menus: store.perfisState.menus,
    authenticated: !!store.accountState.user.accessToken,
    nome: store.accountState.user.name,
    isProtetico: !!store.accountState.user.proteticoDTO,
    colaboradorId: store.accountState.user.colaboradorId,
    loading: store.accountState.loading,
    notifyType: store.notificacoesState.notifyType,
    notify: store.notificacoesState.notify,
    notifyFeedback: store.notificacoesState.notifyFeedback,
    tipoUsuario: store.accountState.user.tipoUsuario,
    franquias: store.accountState.user.franquias
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ requestMenu, requestAcoes }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NavigatorWrapper)

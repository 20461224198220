import AxiosClient from './axios-client'
import { toast } from 'react-toastify'
import { setMinutes, setHours } from 'date-fns'

import { TIPO_CONSULTA, status } from '../constants/agendamento.constants'

class AgendamentoService {
  salvarAgendamento(agendamento) {
    return AxiosClient.post('Agendamento/salvar', agendamento)
  }

  verificarHorarioDentista(agendamento) {
    if (agendamento.colaboradorId === null) {
      toast('Escolha um dentista para o atendimento ', { type: toast.TYPE.ERROR })
      throw new Error('Colaborador não selecionado')
    }
    if (agendamento.especialidadeId === null) {
      toast('Escolha uma especialidade ', { type: toast.TYPE.ERROR })
      throw new Error('Especialidade não selecionada')
    }
    return AxiosClient.post('Agendamento/verificarHorarioDisponivelDentista', agendamento)
  }

  verificarParcelasEmAtraso(agendamento) {
    if (agendamento.colaboradorId === null) {
      toast('Escolha um dentista para o atendimento ', { type: toast.TYPE.ERROR })
      throw new Error('Colaborador não selecionado')
    }
    if (agendamento.especialidadeId === null) {
      toast('Escolha uma especialidade ', { type: toast.TYPE.ERROR })
      throw new Error('Especialidade não selecionada')
    }
    return AxiosClient.post('Agendamento/VerificarPacienteComParcelasEmAtraso', agendamento)
  }

  async salvarStatusAgendamento(agendamentoId, status) {
    return await AxiosClient.put(`Agendamento/AlterarStatus/${agendamentoId}/${status}`)
  }

  getDoDia() {
    try {
      return AxiosClient.get('Agendamento/GetAgendamentos')
    } catch (error) {
      console.log(error)
    }
  }

  getAgendamentosEmAberto() {
    try {
      return AxiosClient.get('Agendamento/GetAgendamentos')
    } catch (error) {
      console.log(error)
    }
  }

  getById(agendamentoId) {
    return AxiosClient.get(`Agendamento/getById/${agendamentoId}`)
  }

  getByColaborador(colaboradorId) {
    try {
      return AxiosClient.get(`Agendamento/GetByColaboradorId/${colaboradorId}`)
    } catch (error) {
      console.log(error)
    }
  }

  async getAgendamentoByPaciente(pacienteId, nome, cpf) {
    try {
      const params = `pacienteId=${pacienteId ? pacienteId : ''}&nome=${nome ? nome : ''}&cpf=${cpf ? cpf : ''}`
      const { data } = await AxiosClient.get(`Agendamento/GetByPaciente?${params}`)
      return this.parseAgendamentos(data)
    } catch (error) {}
  }

  async getAgendamentoByColaboradorId(colaboradorId) {
    try {
      const { data } = await AxiosClient.get(`Agendamento/GetByColaboradorId/${colaboradorId}`)
      return this.parseAgendamentos(data)
    } catch (error) {}
  }

  getProximoHorarioDisponivel(colaboradorId) {
    try {
      return AxiosClient.get(`Agendamento/GetProximoHorarioDisponivelDentista/${colaboradorId}`)
    } catch (error) {}
  }

  async getAgendamentoByEspecialidadeId(especialidadeId) {
    try {
      const { data } = await AxiosClient.get(`Agendamento/GetByEspecialidadeId/${especialidadeId}`)
      return this.parseAgendamentos(data)
    } catch (error) {}
  }

  async getHotariosDisponiveis(agendamento, colaboradorId, especialidadeId) {
    try {
      const { data } = await AxiosClient.get(`Agendamento/GetHorariosDisponiveis/${agendamento}`, {
        params: {
          colaboradorId,
          especialidadeId,
        },
      })
      return this.parseAgendamentos(data)
    } catch (error) {}
  }

  parseAgendamentos(data) {
    return data.map((d) => {
      let { horaInicio, horaFim } = d
      horaInicio = horaInicio.split(':')
      horaFim = horaFim.split(':')
      return {
        ...d,
        title:
          (d.tipoConsulta === TIPO_CONSULTA.AVALIACAO.value
            ? TIPO_CONSULTA.AVALIACAO.shortLabel
            : d.tipoConsulta === TIPO_CONSULTA.PROCEDIMENTO.value
            ? TIPO_CONSULTA.PROCEDIMENTO.shortLabel
            : TIPO_CONSULTA.MANUTENCAO.shortLabel) + (d.paciente && d.paciente.negativado == true ? ' - Negativado' : ''),
        allDay: false,
        backgroundColor: this.getColor(d),
        borderColor: this.getColor(d),
        start: setHours(setMinutes(new Date(d.data), horaInicio[1]), horaInicio[0]).toISOString(),
        end: setHours(setMinutes(new Date(d.data), horaFim[1]), horaFim[0]).toISOString(),
      }
    })
  }

  getColor(item) {
    var statusAgendamento = status.find((x) => x.value === item.statusAgendamento)

    return statusAgendamento && statusAgendamento.color ? statusAgendamento.color : 'blue'
  }

  async getAgendamentos(body) {
    try {
      const { data } = await AxiosClient.post('Agendamento/GetAgendamentos', body)
      return this.parseAgendamentos(data)
    } catch (error) {
      console.log(error)
    }
  }
}

export default new AgendamentoService()

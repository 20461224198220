import React, { Fragment, useState } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faPrint } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { printPaymentReceipt } from '../Templates/PrintableReceipt'
import { formatMoney } from '../../utils/string.utils'
import { formasDePagamento, statusParcelas } from '../../constants/common.constants'
import FinanceiroService from '../../services/financeiro.service'
import OrtodontiaService from '../../services/ortodontia.service'

export default function PagamentosRealizados({
  setIsLoading,
  pagamentosRealizados,
  paciente,
  getPagamentosRealizados,
}) {
  const [isOpen, setIsOpen] = useState('')

  const handlePrint = async (pagamento) => {
    setIsLoading(true)
    printPaymentReceipt({
      ...pagamento,
      nomeContratante: paciente.nome,
      nomeColaborador: typeof pagamento.nomeColaborador !== "undefined" && pagamento.nomeColaborador !== null ? pagamento.nomeColaborador : "",
      parcelasPayload: pagamento.parcelas,
      formaPagamento: !!pagamento.formaPagamento ? pagamento.formaPagamento : formasDePagamento.DINHEIRO.label,
    })
    if (pagamento.pagamentoOrtodontia) {
      await OrtodontiaService.marcarImpresso(pagamento.id)
      await getPagamentosRealizados()
    } else {
      await FinanceiroService.marcarImpresso(pagamento.id)
      await getPagamentosRealizados()
    }
    setIsLoading(false)
  }

  const handleOpen = (codigoValidacao) => {
    isOpen === codigoValidacao ? setIsOpen('') : setIsOpen(codigoValidacao)
  }

  const getStatusParcelaLabel = (parcela) => {
    const statusObj = Object.values(statusParcelas).find((status) => status.value === parcela.status)
    return statusObj ? statusObj.label : '--'
  }

  return (
    <Fragment>
      {pagamentosRealizados && pagamentosRealizados.length > 0 ? (
        <Table className='mt-3' hover bordered>
          <thead>
            <tr className='text-center'>
              <th width='150px'>Data</th>
              <th width='170px'>Valor</th>
              <th width='150px'>Validação</th>
              <th width='150px'>Plano</th>
              <th>Colaborador</th>
              <th width='100px'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {pagamentosRealizados.map((pagamento, index) => {
              const { parcelas, valorPago, nomeColaborador, codigoValidacao, numeroPlano, dataPagamento } = pagamento
              const expand = codigoValidacao === isOpen
              return (
                <Fragment key={index}>
                  <tr key={index + 'tr1'} className='text-center'>
                    <td>{moment(dataPagamento).format('DD/MM/YYYY - HH:mm')}</td>
                    <td>{valorPago ? formatMoney(valorPago) : 'Pagamento cancelado'}</td>
                    <td>{codigoValidacao}</td>
                    <td>{numeroPlano}</td>
                    <td>{nomeColaborador}</td>
                    <td>
                      {!!valorPago && (
                        <OverlayTrigger
                          trigger='hover'
                          placement='top'
                          overlay={<Tooltip>Imprimir Comprovante</Tooltip>}
                        >
                          <Button onClick={() => handlePrint(pagamento)} variant='primary' size='sm'>
                            <FontAwesomeIcon icon={faPrint} />
                          </Button>
                        </OverlayTrigger>
                      )}
                    </td>
                  </tr>
                  <tr key={index + 'tr2'} style={{ cursor: 'pointer' }} onClick={() => handleOpen(codigoValidacao)}>
                    <td colSpan='6'>
                      <FontAwesomeIcon
                        className='ml-auto d-block text-secondary'
                        style={{ marginRight: '32px' }}
                        icon={expand ? faChevronUp : faChevronDown}
                      />
                      <div className={expand ? 'd-block' : 'd-none'}>
                        <Table>
                          <thead>
                            <tr className='text-center' style={{ backgroundColor: 'white' }}>
                              <th>Vencimento</th>
                              <th>Desconto</th>
                              <th>Juros</th>
                              <th>Multa</th>
                              <th>Status</th>
                              <th>Valor Pago</th>
                            </tr>
                          </thead>
                          <tbody>
                            {parcelas.map((parcela, index) => (
                              <tr key={index} className='text-center' style={{ backgroundColor: 'white' }}>
                                <td>{moment(parcela.dataVencimento).format('DD/MM/YYYY')}</td>
                                <td>{parcela.desconto > 0 ? formatMoney(parcela.desconto) : '-'}</td>
                                <td>{parcela.juros > 0 ? formatMoney(parcela.juros) : '-'}</td>
                                <td>{parcela.multa > 0 ? formatMoney(parcela.multa) : '-'}</td>
                                <td>{getStatusParcelaLabel(parcela)}</td>
                                <td>{parcela.valorPago ? formatMoney(parcela.valorPago) : 'Pagamento cancelado'}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <p>Nenhum pagamento realizado.</p>
      )}
    </Fragment>
  )
}

import React, { useEffect, useState } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import Select from 'react-select'
import { Button, Table, Row, Col, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import ColaboradoresServices from '../../../services/colaboradores.service'
import OrtodontiaService from '../../../services/ortodontia.service'
import { cargoConstant } from '../../../constants/common.constants'

export default function HistoricoAtendimentos({
  firstStep,
  setIsLoading,
  selectedPlano,
  refreshPlanosTratamentoOrtodontiaList,
}) {
  const [dateFilterDe, setDateFilterDe] = useState(null)
  const [dateFilterAte, setDateFilterAte] = useState(null)
  const [colaboradorSelecionado, setColaboradorSelecionado] = useState(null)
  const [colaboradores, setColaboradores] = useState([])
  const [historicoAtendimentos, setHistoricoAtendimentos] = useState(null)

  useEffect(() => {
    getColaboradores()
  }, [])

  useEffect(() => {
    getHistoricoAtendimentos()
  }, [dateFilterDe, dateFilterAte, colaboradorSelecionado, selectedPlano])

  const getHistoricoAtendimentos = async () => {
    setIsLoading(true)
    try {
      const { data } = await OrtodontiaService.getHistoricoAtendimentos(
        selectedPlano.id,
        dateFilterDe,
        dateFilterAte,
        colaboradorSelecionado?.value
      )
      setHistoricoAtendimentos(data)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const getColaboradores = async () => {
    try {
      const { data } = await ColaboradoresServices.getAllColaboradores()
      setColaboradores(
        data
          .filter(({ cargo }) => cargo.tipo === cargoConstant.DENTISTA.value)
          .map(({ nome, id }) => ({ label: nome, value: id }))
      )
    } catch (error) {
      console.error(error)
    }
  }

  const handleGoBackListaPlanos = () => {
    firstStep()
    refreshPlanosTratamentoOrtodontiaList()
  }

  const handleDateFilterDe = (date) => {
    date ? setDateFilterDe(date.toDate()) : setDateFilterDe(null)
  }

  const handleDateFilterAte = (date) => {
    date ? setDateFilterAte(date.toDate()) : setDateFilterAte(null)
  }

  const handleChangeColaborador = (option) => {
    setColaboradorSelecionado(option)
  }

  return (
    <div>
      <div style={backContainerStyle} onClick={handleGoBackListaPlanos}>
        <Button variant='outline-primary' style={backStyle}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <span className='text-primary'>Voltar</span>
      </div>
      <Row className='my-4'>
        <Col md='3'>
          <Form.Group>
            <Form.Label>Data Inicial</Form.Label>
            <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
              <KeyboardDatePicker
                invalidDateMessage='Formato de data inválido'
                value={dateFilterDe}
                onChange={handleDateFilterDe}
                className='form-control'
                variant='inline'
                format='DD/MM/YYYY'
                maxDate={dateFilterAte || new Date('2100-01-01')}
                animateYearScrolling
                autoOk
                placeholder='De'
              />
            </MuiPickersUtilsProvider>
          </Form.Group>
        </Col>
        <Col md='3'>
          <Form.Group>
            <Form.Label>Data Final</Form.Label>
            <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
              <KeyboardDatePicker
                invalidDateMessage='Formato de data inválido'
                value={dateFilterAte}
                onChange={handleDateFilterAte}
                minDate={dateFilterDe || new Date('1900-01-01')}
                className='form-control'
                variant='inline'
                format='DD/MM/YYYY'
                animateYearScrolling
                autoOk
                placeholder='Até'
              />
            </MuiPickersUtilsProvider>
          </Form.Group>
        </Col>
        <Col md='3'>
          <Form.Group>
            <Form.Label>Colaborador</Form.Label>
            <Select
              placeholder='Selecione...'
              noOptionsMessage={() => 'Nenhuma opção disponível'}
              value={colaboradorSelecionado}
              options={colaboradores}
              onChange={handleChangeColaborador}
            />
          </Form.Group>
        </Col>
      </Row>
      <h5 className='my-3 text-secondary font-weight-bold'>Histórico</h5>
      <Table className='bg-white' bordered hover>
        <thead>
          <tr>
            <th>Descrição</th>
            <th className='text-center' width='200px'>
              Dentista
            </th>
            <th className='text-center' width='200px'>
              Manutenção
            </th>
            <th className='text-center' width='150px'>
              Data / Hora
            </th>
          </tr>
        </thead>
        <tbody>
          {historicoAtendimentos?.length > 0 ? (
            historicoAtendimentos.map(({ manutencao, observacao, agendamento, dataCriacao }, index) => (
              <tr key={index}>
                <td>{observacao}</td>
                <td className='text-center'>{agendamento?.colaborador?.nome}</td>
                <td className='text-center'>{manutencao ? 'Sim' : 'Não'}</td>
                <td className='text-center'>
                  <p className='m-0'>{moment(dataCriacao).format('DD/MM/YYYY')}</p>
                  <p className='m-0'>{moment(dataCriacao).format('HH:mm')}</p>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colspan='4'>Nenhum histórico disponível</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

const backContainerStyle = {
  cursor: 'pointer',
  marginBottom: '2em',
}
const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

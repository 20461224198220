import React, { Fragment } from 'react'
import { Table } from 'react-bootstrap'
import { formatMoney } from '../../../../utils/string.utils'
import moment from 'moment'

export default function ParcelasList({ parcelas, selectedParcelas, toggleParcela, selecionarTodasParcelas, grupo }) {
  return (
    <Fragment>
      {parcelas.length > 0 ? (
        <Table bordered hover>
          <thead>
            <tr className='text-center'>
              <th>Procedimento</th>
              <th width='120px'>Data de Vencimento</th>
              <th width='120px'>Data de Pagamento</th>
              <th width='120px'>Valor da parcela</th>
              <th width='120px'>Valor pago</th>
              <th width='80px'>
                <label className='checkbox labeless m-0'>
                  <input
                    type='checkbox'
                    checked={selectedParcelas[grupo] && selectedParcelas[grupo].length === parcelas.length}
                    onChange={() => selecionarTodasParcelas(grupo)}
                  />
                  <span />
                </label>
              </th>
            </tr>
          </thead>
          <tbody>
            {parcelas.map((parcela, index) => {
              const {
                dataPagamento,
                dataVencimento,
                valorOriginal,
                valorPago,
                pagamentoParcelaId,
                procedimentoProteticoNome,
              } = parcela
              const isLate = valorPago > valorOriginal
              return (
                <tr className='text-center' key={index}>
                  <td>{procedimentoProteticoNome ? procedimentoProteticoNome : 'Procedimento'}</td>
                  <td>{moment(dataVencimento).format('DD/MM/YYYY')}</td>
                  <td>{moment(dataPagamento).format('DD/MM/YYYY')}</td>
                  <td>{formatMoney(valorOriginal)}</td>
                  <td className={`font-weight-bold ${isLate ? 'text-danger' : 'text-success'}`}>
                    {formatMoney(valorPago)}
                  </td>
                  <td>
                    <label className='checkbox labeless m-0'>
                      <input
                        type='checkbox'
                        checked={selectedParcelas[grupo] && selectedParcelas[grupo].includes(pagamentoParcelaId)}
                        onChange={() => toggleParcela(pagamentoParcelaId, grupo, index)}
                      />
                      <span />
                    </label>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <div>Nenhuma parcela paga até o momento.</div>
      )}
    </Fragment>
  )
}

import { Procedimentos } from '../types'

export const resetarProcedimento = () => ({ type: Procedimentos.RESETAR_PROCEDIMENTO })

export const resetProcedimento = () => ({
  type: Procedimentos.RESETAR_PROCEDIMENTO,
  selectedProcedimento: {},
})

export const requestProcedimentos = () => ({
  type: Procedimentos.REQUEST_PROCEDIMENTOS,
})

export const editarProcedimento = (procedimento) => ({
  type: Procedimentos.SET_PROCEDIMENTO_ID,
  procedimento,
})

export const salvarProcedimento = (procedimento) => ({
  type: Procedimentos.SALVAR_PROCEDIMENTO,
  procedimento,
})

export const toggleProcedimento = (procedimento, ativar) => ({
  type: Procedimentos.INATIVAR_PROCEDIMENTO,
  procedimento,
  ativar,
})

import { Templates } from '../types'

export const selectTemplate = (template) => ({
	type: Templates.SELECT_TEMPLATE,
	payload: template,
})

export const resetTemplate = () => ({
	type: Templates.RESET_TEMPLATE,
})

import { Modals } from '../types'

const initialState = {
	pacientesModalIsOpen: false,
	pacienteId: null,
	pacienteNome: null,
	pacienteCpf: null,
	pacienteTelefones: null,
	agendamentoId: null
}

export const modalsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Modals.OPEN_PACIENTES_MODAL:
			return {
				...state,
				pacientesModalIsOpen: true,
				pacienteId: action.pacienteId ? action.pacienteId : null,
				pacienteNome: action.pacienteNome ? action.pacienteNome : null,
				pacienteCpf: action.pacienteCpf ? action.pacienteCpf : null,
				pacienteTelefones: action.pacienteTelefones ? action.pacienteTelefones : null,
				agendamentoId: action.agendamentoId ? action.agendamentoId : null
			}
		case Modals.CLOSE_PACIENTES_MODAL:
			return { ...initialState }
		default:
			return state
	}
}

const DiasSemana = {
  DOMINGO: { value: 0, label: 'Domingo' },
  SEGUNDA: { value: 1, label: 'Segunda-feira' },
  TERCA: { value: 2, label: 'Terça-feira' },
  QUARTA: { value: 3, label: 'Quarta-feira' },
  QUINTA: { value: 4, label: 'Quinta-feira' },
  SEXTA: { value: 5, label: 'Sexta-feira' },
  SABADO: { value: 6, label: 'Sábado' },
  // TODOS: { value: 7, label: 'Todos os dias da semana'},
  // SOMENTE_UTEIS: { value: 8, label: 'Somente dias úteis'}
}

export default DiasSemana

import React from 'react'
import { Row, Col, Card, Form, InputGroup, Button, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'

import usuarioService from '../../services/usuario.service'
import SystemConstants from '../../constants/system.constants'

const INITIAL_STATE = {
  togglePassword: false,
  toggleNewPassword: false,
  toggleConfirmPassword: false,
  senhaAtual: '',
  senhaNova: '',
  confirmarSenha: '',
  loading: false,
  isInvalid: false,
}

class MudarSenha extends React.Component {
  state = { ...INITIAL_STATE }

  /**
   * Retorna ao estado inicial
   */
  reset() {
    this.setState({ ...INITIAL_STATE })
  }

  /**
   * Usado para alterar visibilidade de senha
   */
  togglePassword = () => {
    this.setState({ togglePassword: !this.state.togglePassword })
  }

  /**
   * Usado para alterar visibilidade de senha
   */
  toggleNewPassword = () => {
    this.setState({ toggleNewPassword: !this.state.toggleNewPassword })
  }

  /**
   * Usado para alterar visibilidade de senha
   */
  toggleConfirmPassword = () => {
    this.setState({ toggleConfirmPassword: !this.state.toggleConfirmPassword })
  }

  /**
   * Usado para prevenir paste e drop
   */
  blockPasteDrop = (e) => {
    e.preventDefault()
    return false
  }

  /**
   * Modifica estado no evento de change de inputs
   */
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  verifyPassword = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value,
      isInvalid: !(value.length >= 8 && /^[0-9a-zA-Z]+$/.test(value)),
    })
  }

  /**
   * Apresenta mensagens de sucesso
   */
  onSuccess = async () => {
    await Swal.fire('Sucesso!', 'Senha alterada com sucesso!', 'success')
    this.reset()
    const { proteticoDTO } = JSON.parse(localStorage.getItem(SystemConstants.getUser()))
    if (proteticoDTO) {
      this.props.history.push('/protetico/editar')
    }
  }

  /**
   * Trata erros
   */
  onError = async () => {
    await Swal.fire('Error!', 'Houve um erro ao alterar a senha!', 'error')
    this.setState({ loading: false })
  }

  /**
   * Realiza a operacao de troca de senha se os requisitos forem preenchidos
   */
  onSubmit = (e) => {
    e.preventDefault()
    const { senhaAtual, senhaNova, confirmarSenha, isInvalid } = this.state
    if (senhaNova === confirmarSenha && !isInvalid) {
      this.setState({ loading: true }, async () => {
        try {
          await usuarioService.changePassword(senhaAtual, senhaNova)
          this.onSuccess()
        } catch (e) {
          this.onError()
        }
      })
    }
  }

  render() {
    const {
      senhaNova,
      senhaAtual,
      confirmarSenha,
      togglePassword,
      toggleNewPassword,
      toggleConfirmPassword,
      isInvalid,
    } = this.state
    return (
      <>
        <Row className='mb-3'>
          <Col>
            <h1 className='h3 m-0 text-secondary font-weight-bold'>Mudar Senha</h1>
          </Col>
        </Row>
        <Card className='p-4'>
          <Form onSubmit={this.onSubmit}>
            <Row>
              <Col md='6'>
                <Form.Group className='required mb-1'>
                  <Form.Label>Senha Atual</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={togglePassword ? 'text' : 'password'}
                      name='senhaAtual'
                      value={senhaAtual}
                      onChange={this.onChange}
                      //onPaste={this.blockPasteDrop}
                      //onDrop={this.blockPasteDrop}
                      required
                    />
                    <InputGroup.Append onClick={this.togglePassword} className='cursor-pointer'>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={togglePassword ? faEyeSlash : faEye} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <Form.Group className='required mb-1 pt-3'>
                  <Form.Label>Nova Senha</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={toggleNewPassword ? 'text' : 'password'}
                      name='senhaNova'
                      value={senhaNova}
                      onChange={this.verifyPassword}
                      onPaste={this.blockPasteDrop}
                      onDrop={this.blockPasteDrop}
                      isInvalid={isInvalid}
                      required
                    />
                    <InputGroup.Append onClick={this.toggleNewPassword} className='cursor-pointer'>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={toggleNewPassword ? faEyeSlash : faEye} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
                <Form.Text className='text-muted'>
                  Senha deve ter no mínimo 8 caracteres e conter letras e números.
                </Form.Text>
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <Form.Group className='required mb-1 pt-3'>
                  <Form.Label>Confirmar Nova Senha</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={toggleConfirmPassword ? 'text' : 'password'}
                      name='confirmarSenha'
                      value={confirmarSenha}
                      onChange={this.onChange}
                      onPaste={this.blockPasteDrop}
                      onDrop={this.blockPasteDrop}
                      isInvalid={senhaNova !== confirmarSenha}
                      required
                    />
                    <InputGroup.Append onClick={this.toggleConfirmPassword} className='cursor-pointer'>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={toggleConfirmPassword ? faEyeSlash : faEye} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type='invalid'>
                      A confirmação deve ser igual a nova senha digitada.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Text className='text-muted'>Digite novamente a senha para confirmar.</Form.Text>
              </Col>
            </Row>
            <Button type='submit' variant='primary' className='float-right' disabled={this.state.loading}>
              {this.state.loading ? <Spinner as='span' animation='border' size='sm' /> : <span>Salvar</span>}
            </Button>
          </Form>
        </Card>
      </>
    )
  }
}

export default MudarSenha

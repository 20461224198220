import { Pacientes } from '../types'

const initialState = {
  pacientes: [],
  selectedPaciente: {},
  planosDeTratamento: [],
  pacienteId: null,
  pacienteNome: null,
  cpfPaciente: null,
  pacienteTelefones: null,
  agendamentoId: null,
}

export const pacientesReducer = (state = initialState, action) => {
  switch (action.type) {
    case Pacientes.SET_PLANOS_TRATAMENTO:
      return {
        ...state,
        planosDeTratamento: action.planosDeTratamento,
      }
    case Pacientes.LISTAR_PACIENTES:
      return {
        ...state,
        pacientes: action.pacientes,
      }
    case Pacientes.SET_PACIENTE:
      return {
        ...state,
        selectedPaciente: action.paciente,
      }
    case Pacientes.EDITAR_PACIENTE:
      return {
        ...state,
        pacienteId: action.pacienteId ? action.pacienteId : null,
        pacienteNome: action.pacienteNome ? action.pacienteNome : null,
        cpfPaciente: action.cpfPaciente ? action.cpfPaciente : null,
        pacienteTelefones: action.pacienteTelefones ? action.pacienteTelefones : null,
        agendamentoId: action.agendamentoId ? action.agendamentoId : null,
      }
    case Pacientes.RESET_PACIENTE:
      return {
        ...state,
        selectedPaciente: {},
        pacienteId: null,
        pacienteNome: null,
        pacienteCpf: null,
        pacienteTelefones: null,
        agendamentoId: null,
      }
    case Pacientes.RESET_PLANOS_TRATAMENTO:
      return {
        ...state,
        planosDeTratamento: [],
      }
    default:
      return state
  }
}

import React, { Fragment, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'

import { formatMoney } from '../../../../utils/string.utils'
import CollapsePanel from '../../../Common/CollapsePanel'
import ParcelasList from './ParcelasList'
import { formasDePagamento } from '../../../../constants/common.constants'
import { toast } from 'react-toastify'

export default function RealizarPagamento({
  parcelamentoPagamento,
  selectedParcelas,
  setSelectedParcelas,
  setFormaPagamento,
  sumSelectedParcelasCalculado,
  handleGoBack,
  nextStep,
}) {
  const INITIAL_OPEN_STATE = { step0: true }
  const [stepIsOpen, setStepIsOpen] = useState({ ...INITIAL_OPEN_STATE })
  const [selectedFormaPagamento, setSelectedFormaPagamento] = useState(null)

  const panels = parcelamentoPagamento
    ? [
        ...Object.values(parcelamentoPagamento.plano),
        ...Object.values(parcelamentoPagamento.aparelho),
        ...Object.values(parcelamentoPagamento.complementar),
        ...Object.values(parcelamentoPagamento.suprimentos),
      ]
    : []

  const toggleIsOpen = (step) => {
    let newSteps = { ...INITIAL_OPEN_STATE }
    Object.keys(panels).forEach((_, i) => {
      newSteps[`step${i}`] = false
    })
    setStepIsOpen({
      ...newSteps,
      [step]: !stepIsOpen[step],
    })
  }

  const handleChangeFormaPagamento = (formaPgto) => {
    setFormaPagamento(formaPgto)
    setSelectedFormaPagamento(formaPgto)
  }

  const handleNextStep = () => {
    if (!selectedFormaPagamento) {
      toast('Escolha uma forma de pagamento', { type: toast.TYPE.WARNING })
      return false
    }
    nextStep()
  }

  const renderPanels = () => (
    <Fragment>
      {panels.map((parcelas, index) => {
        const { nomeAparelho, nomeAparelhoComplementar, descricaoSuprimento } = parcelas[0]
        const text = nomeAparelho || nomeAparelhoComplementar || descricaoSuprimento || 'Plano Ortodôntico'
        return (
          <CollapsePanel
            key={index}
            stepCode={`step${index}`}
            isOpen={stepIsOpen[`step${index}`]}
            toggleIsOpen={toggleIsOpen}
            text={text}
          >
            <ParcelasList
              text={text}
              parcelas={parcelas}
              selectedParcelas={selectedParcelas}
              setSelectedParcelas={setSelectedParcelas}
              panelIndex={index}
            />
          </CollapsePanel>
        )
      })}
    </Fragment>
  )

  return (
    <Fragment>
      <h3 className='my-4 text-secondary font-weight-bold'>Efetuar pagamento</h3>
      {panels.length > 0 ? renderPanels() : <div>Nenhuma parcela em aberto.</div>}
      {selectedParcelas.length > 0 && (
        <div className='d-flex justify-content-between pb-5 pt-3'>
          <h1 className='h4 m-0 text-secondary font-weight-bold flex-fill'>
            Total: {formatMoney(sumSelectedParcelasCalculado)}
          </h1>
          <div className='flex-fill flex-column aling-items-center'>
            <Select
              value={selectedFormaPagamento}
              onChange={handleChangeFormaPagamento}
              options={Object.values(formasDePagamento)}
              placeholder='Forma de pagamento'
              noOptionsMessage={() => 'Nenhuma forma de pagamento disponível'}
            />
          </div>
          <div className='flex-fill text-right'>
            <Button variant='primary' disabled={selectedParcelas.length < 1} onClick={handleNextStep}>
              Efetuar Pagamento
            </Button>
          </div>
        </div>
      )}
      <div style={backContainerStyle} onClick={handleGoBack}>
        <Button variant='outline-primary' style={backStyle}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <span className='text-primary'>Voltar</span>
      </div>
    </Fragment>
  )
}

const backContainerStyle = {
  cursor: 'pointer',
  width: 'fit-content',
}

const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

import React, { Fragment, useState, useEffect } from 'react'
import { Table, Form, Row, Col, InputGroup, Container, Button, Modal, Card, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import CurrencyInput from 'react-currency-input'
import { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import ptBr from 'date-fns/locale/pt-BR'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import PlanoTratamentoService from '../../../../services/planoTratamento.service'
import { formatMoney, handleDecimalConvert } from '../../../../utils/string.utils'
import { parcelasSelect } from '../../../../constants/agendamento.constants'
import CollapsePanel from '../../../Common/CollapsePanel'
import { TIPOS_TEMPLATE } from '../../../../constants/template.constants'
import { printParcelasReceipt } from '../../../Templates/PrintableReceipt'

registerLocale('pt-BR', ptBr)

const Financeiro = (props) => {
  const {
    selectedPlano,
    onBack,
    totalPagamento,
    setTotal,
    isLoading,
    setIsLoading,
    parcelasProteticas,
  } = props

  const [valorParcelamento] = useState(totalPagamento / parcelasSelect[0].value)
  const [parcelasEmAberto, setParcelasEmAberto] = useState(parcelasProteticas)
  const [reparcelamentos, setReparcelamentos] = useState([])
  const [parcelasAgrupadas, setParcelasAgrupadas] = useState([])
  const [parcelamentoRenegociado, setParcelamentoRenegociado] = useState([])
  const [parcelamentoRenegociadoId, setParcelamentoRenegociadoId] = useState(null)
  const [showModalImpressao, setShowModalImpressao] = useState(false)
  const INITIAL_OPEN_STATE = { step0: true }
  const [stepIsOpen, setStepIsOpen] = useState({ ...INITIAL_OPEN_STATE })

  useEffect(() => {
    calcularTotalParcelasEmAberto()
  }, [parcelasEmAberto])

  useEffect(() => {
    let agrupamentos = []
    let grupo = null
    let p = {}
    let planoTratamentoPagamentoProteticoIdAtual = null

    parcelasEmAberto.map((parcela) => {
      if(!planoTratamentoPagamentoProteticoIdAtual || parcela.planoTratamentoPagamentoProteticoId != planoTratamentoPagamentoProteticoIdAtual)
      {
        if(grupo)
          agrupamentos.push(grupo)

        planoTratamentoPagamentoProteticoIdAtual = parcela.planoTratamentoPagamentoProteticoId
        grupo = {id: parcela.planoTratamentoPagamentoProteticoId, nome: parcela.nomeProcedimentoProtetico, valorTotal: 0, renegociacao: parcela.renegociacao, dataRenegociacao: parcela.dataCriacao, parcelas: []}
      }

      let p = {dataVencimento: parcela.dataVencimento, id: parcela.id, numero: parcela.numero, observacao: parcela.observacao, status: parcela.status, valor: parcela.valor}
      grupo.parcelas.push(p)
      grupo.valorTotal += p.valor
    })

    agrupamentos.push(grupo)
    
    setParcelasAgrupadas(agrupamentos)
    criarReparcelamentos(agrupamentos)
  }, [parcelasEmAberto])

  const criarReparcelamentos = (parcelas) => {
    let r = []
    
    parcelas.map(plano => {
      r.push({id: plano.id, nome: plano.nome, valorEntrada: 0, diferencaPrimeiraParcela: plano.valorTotal, valorTotal: plano.valorTotal, quantidadeParcelasSelected: {label: "1x", value: 1}, quantidadeParcelas: 1, dataPrimeiraParcela: moment(), parcelas: [{numero: 1, dataVencimento: moment(), valor: plano.valorTotal}]})
    })

    setReparcelamentos(r)
  }

  const toggleIsOpen = (step) => {
    let newSteps = { ...INITIAL_OPEN_STATE }
    Object.keys(parcelasAgrupadas).forEach((_, i) => {
      newSteps[`step${i}`] = false
    })
    setStepIsOpen({
      ...newSteps,
      [step]: !stepIsOpen[step],
    })
  }

  const getCollapseName = (parcelamento) => {
    if (parcelamento.renegociacao) {
      return `Procedimento: ${parcelamento.nome} - RENEGOCIADO em ${moment(parcelamento.dataRenegociacao).format(`DD/MM/YYYY HH:mm`)}`
    }

    return `Procedimento: ${parcelamento.nome}`
  }

  const calcularTotalParcelasEmAberto = () => {
    let soma = 0
    parcelasEmAberto.forEach((p) => {
      if (p.valorCalculado > p.valor) {
        soma += p.valorCalculado
      } else {
        soma += p.valor
      }
    })
    setTotal(soma)
  }

  const salvarRenegociacao = async (e, parcelas, reparcelamento) => {
    setIsLoading(true)
    try {
      delete reparcelamento.quantidadeParcelasSelected

      const renegociacao = {
        parcelamento: parcelas,
        reparcelamento: reparcelamento
      }
      
      const result = await PlanoTratamentoService.salvarRenegociacaoProtese(renegociacao)

      setParcelamentoRenegociadoId(result.data.result.id)

      setIsLoading(false)
      setParcelamentoRenegociado(reparcelamento)
      setShowModalImpressao(true)

    } catch (e) {}
  }

  const refreshPlanos = async () => {
    const response = await PlanoTratamentoService.getParcelasEmAberto(selectedPlano.id, true, true)

    setParcelasEmAberto(response.data.parcelasProteticas)
  }

  const setEntrada = (e, reparcelamento) => {
    if(!reparcelamento)
      return
      
    reparcelamento.valorEntrada = handleDecimalConvert(e)
    
    //Se o valor digitado for maior que o valor total assume o valor total
    if(reparcelamento.valorEntrada >= reparcelamento.valorTotal){
      reparcelamento.valorEntrada = reparcelamento.valorTotal
      setParcelamento(parcelasSelect[0], reparcelamento)
    }
    
    reparcelamento.diferencaPrimeiraParcela = reparcelamento.valorTotal - reparcelamento.valorEntrada

    //Se o valor de entrada for menor que o valor total e a quantidade de parcelas for 1, muda para 2
    if(reparcelamento.quantidadeParcelas == 1 && reparcelamento.valorEntrada < reparcelamento.valorTotal)
      setParcelamento(parcelasSelect[1], reparcelamento)

    calcularParcelas(reparcelamento)
  }

  const setParcelamento = (e, reparcelamento) => {
    if(!reparcelamento)
      return
    
    if(reparcelamento.valorTotal == reparcelamento.valorEntrada)
      reparcelamento.quantidadeParcelasSelected = parcelasSelect[0]
    else
      reparcelamento.quantidadeParcelasSelected = e

    reparcelamento.quantidadeParcelas = reparcelamento.quantidadeParcelasSelected.value

    calcularParcelas(reparcelamento)
  }

  const setVencimento = (e, reparcelamento) => {
    if(!reparcelamento)
      return
    
    if (moment() > e) {
      e = moment()
    }

    reparcelamento.dataPrimeiraParcela = e

    calcularParcelas(reparcelamento)
  }

  const calcularParcelas = (reparcelamento) => {
    if(!reparcelamento)
      return

    let dataPrimeiraParcela = reparcelamento.dataPrimeiraParcela
    
    let entrada = reparcelamento.valorEntrada
    let temEntrada = entrada > 0
    let quantidadeParcelas = temEntrada ? reparcelamento.quantidadeParcelas - 1 : reparcelamento.quantidadeParcelas
    let valorParcelas = (reparcelamento.diferencaPrimeiraParcela / quantidadeParcelas)

    let parcelas = []
    let vencimento = dataPrimeiraParcela
    
    for(let i = 1; i <= reparcelamento.quantidadeParcelas; i++){
      vencimento = new Date(dataPrimeiraParcela.year(), dataPrimeiraParcela.month() + i - 1, dataPrimeiraParcela.date()) 
      parcelas.push({numero: i, dataVencimento: vencimento, valor: (temEntrada && i == 1 ? entrada : valorParcelas)})
    }

    let atualizar = [ ...reparcelamentos ]

    atualizar[reparcelamentos.findIndex(x => x.id === reparcelamento.id)].parcelas = parcelas

    setReparcelamentos(atualizar)
  }

  const handleImprimirTermoRenegociacao = async () => {
    setIsLoading(true)
    await PlanoTratamentoService.downloadTemplateAsPDFByParteNome(selectedPlano.id, TIPOS_TEMPLATE.TermoDeRenegociacaoProtetico, parcelamentoRenegociadoId)
    setIsLoading(false)
  }

  const handleImprimirCarne = async () => {
    const { valorTotal, parcelas } = parcelamentoRenegociado
    const { dependente, paciente, numeroPlano } = selectedPlano
    await printParcelasReceipt({
      numeroPlano,
      nomeContratante: paciente.nome,
      nomePaciente: dependente ? dependente.nome : paciente.nome,
      parcelas,
      valorTotal,
      type: 'protese',
      nomeProtese: parcelamentoRenegociado.nome
    })
  }

  const handleModalImpressao = async () => {
    const showModal = !showModalImpressao

    setShowModalImpressao(showModal)

    if(!showModal)
      refreshPlanos()
  }

  const handleFinish = () => {
    onBack()
  }

  return (
    <Fragment>
      <div onClick={onBack}>
        <Button variant='outline-primary' style={backStyle}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <span className='text-primary'>Voltar</span>
      </div>

      {Object.keys(parcelasAgrupadas).map((pagamentoId, i) => (
        <CollapsePanel
          key={i}
          stepCode={`step${i}`}
          text={getCollapseName(parcelasAgrupadas[i])}
          isOpen={stepIsOpen[`step${i}`]}
          toggleIsOpen={toggleIsOpen}
        >
          <Container className='pb-5' style={{ maxWidth: 750, paddingTop: '40px' }}>
            {parcelasAgrupadas[i].parcelas.length > 0 ? (
              <Fragment>
                <h4 className='mb-4 text-secondary font-weight-bold'>Parcelas da Prótese em Aberto</h4>
                <Table bordered hover className='mb-5'>
                  <thead>
                    <tr>
                      <th className='text-center' width='100px'>
                        Parcela
                      </th>
                      <th className='text-center'>Vencimento</th>
                      <th className='text-center'>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {parcelasAgrupadas[i].parcelas.map((p, index) => (
                      <tr key={index}>
                        <td className='text-center'>{p.numero}</td>
                        <td className='text-center'>{moment(p.dataVencimento).format(`DD/MM/YYYY`)}</td>
                        <td className={`text-center font-weight-bold`}>
                          {formatMoney(p.valor)}
                        </td>
                      </tr>
                    ))}
                    <tr className='bg-secondary-03'>
                      <td className='text-center font-weight-bold'>Total:</td>
                      <td className='text-center font-weight-bold'></td>
                      <td className='text-center font-weight-bold'>{formatMoney(parcelasAgrupadas[i].valorTotal)}</td>
                    </tr>
                  </tbody>
                </Table>
              </Fragment>
            ) : (
              !isLoading && <h4 className='mb-4 text-secondary font-weight-bold'>Nenhuma parcela em aberto</h4>
            )}
            {reparcelamentos.length > 0 && (
              <Fragment>
                <h4 className='my-4 text-secondary font-weight-bold'>Condições de pagamento</h4>
                <Row>
                  <Form.Group as={Col} className='col-md-5'>
                    <Form.Label>Primeira parcela</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>R$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <CurrencyInput
                        className='form-control'
                        decimalSeparator=','
                        thousandSeparator='.'
                        value={reparcelamentos[i].valorEntrada}
                        onChange={(e) => setEntrada(e, reparcelamentos[i])}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} className='col-md-2'>
                    <Form.Label>Parcelas</Form.Label>
                    <Select value={reparcelamentos[i].quantidadeParcelasSelected} options={parcelasSelect} onChange={(e) => setParcelamento(e, reparcelamentos[i])} />
                  </Form.Group>
                  <Form.Group as={Col} className='col-md-5 required'>
                    <Form.Label>Data da Primeira parcela</Form.Label>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                      <KeyboardDatePicker
                        id='dataPrimeiraParcela'
                        name='dataPrimeiraParcela'
                        className='form-control'
                        minDateMessage='A data deve ser a partir de hoje'
                        invalidDateMessage='Formato de data inválido'
                        onChange={(e) => setVencimento(e, reparcelamentos[i])} 
                        value={reparcelamentos[i].dataPrimeiraParcela}
                        variant='inline'
                        format='DD/MM/YYYY'
                        required
                        autoOk
                        animateYearScrolling
                        disablePast
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Group>
                </Row>
                {valorParcelamento <= totalPagamento && (
                  <div>
                    <h4 className='my-4 text-secondary font-weight-bold'>Resumo do Parcelamento</h4>
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th className='text-center' width='100px'>
                            Parcela
                          </th>
                          <th className='text-center'>Vencimento</th>
                          <th className='text-center' width='250px'>
                            Valor
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {reparcelamentos[i].parcelas.map(({ numero, dataVencimento, valor }, index) => (
                          <tr key={index}>
                            <td className='text-center'>{numero}</td>
                            <td className='text-center'>
                              {dataVencimento ? moment(dataVencimento).format(`DD/MM/YYYY`) : 'Data Inválida'}
                            </td>
                            <td className='text-center'>{formatMoney(valor)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Button className='mt-3 ml-auto d-block' onClick={(e) => salvarRenegociacao(e, parcelasAgrupadas[i], reparcelamentos[i])} variant='primary'>
                      Salvar Renegociação
                    </Button>
                  </div>
                )}
              </Fragment>
            )}
          </Container>
        </CollapsePanel>
      ))}

    <Modal className='modal-impressao' show={showModalImpressao} onHide={handleModalImpressao}>
    <Card>
        <Modal.Header className='p-4' closeButton>
          <Modal.Title>Procedimento - { parcelamentoRenegociado.nome }</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>
          <Row>
            <Container className='text-center pb-5'>
              <div className='mb-5'>
                <FontAwesomeIcon style={{ fontSize: '11em' }} className='text-success mt-5 mb-3' icon={faCheckCircle} />
                <h2 style={fontStyle} className='text-success'>
                  Renegociação realizada com sucesso!
                </h2>
              </div>
              <div>
                <Button className='mr-3' variant='outline-secondary' onClick={handleImprimirTermoRenegociacao}>
                  {!isLoading ? 'Imprimir Termo de Renegociação' : <Spinner animation='border' size='sm' variant='light' />}
                </Button>
                <Button className='mr-3' variant='outline-secondary' onClick={handleImprimirCarne}>
                  Imprimir Parcelamento
                </Button>
                <Button className='mr-3' variant='outline-primary' onClick={handleModalImpressao}>
                  Reparcelar Outros
                </Button>
                <Button className='mr-3' variant='primary' onClick={handleFinish}>
                  Finalizar
                </Button>
              </div>
            </Container>
          </Row>
        </Modal.Body>
      </Card> 

    </Modal>
    </Fragment>
  )
}

export default Financeiro

const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

const fontStyle = {
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
}
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import PageLoading from '../../Common/PageLoading'
import anexosService from '../../../services/anexos.service'
import { convertToBase64 } from '../../../utils/anexo.utils'

export default function AnexarGeral({ colaboradorId, categorias, fecharModais}) {
    const [isLoading, setIsLoading] = useState(false)
    const [documentos, setDocumentos] = useState([])

    useEffect(() => {
    }, [])

    const adicionarNoDocumentos = async (event) => {
        const files = Array.from(event.target.files);
        let tempDocumentos = [];

        await Promise.all(files.map(async file => {
            if (documentos.find(documento => documento.nome === file.name))
                return toast(`O documento ${file.name}, já está anexado, remover e reanexar se houver necessidade!`, { type: toast.TYPE.WARNING });

            tempDocumentos.push({
                colaboradorId: colaboradorId,
                nome: file.name,
                binary: await convertToBase64(file),
                categoriaId: null,
                planoTratamentoId: null,
                planoTratamentoOrtodontiaId: null,
            })
        }))

        tempDocumentos.push(...documentos);
        
        if (tempDocumentos.length > 0)
            setDocumentos(tempDocumentos)

    }

    const deletaDocumento = (documento) => {
        let tempDocumentos = documentos.filter(doc => doc != documento);
        setDocumentos(tempDocumentos);
    }

    const upload = async () => {
        let hasError = false;
        setIsLoading(true);

        documentos.map(documento => {
            if (documento.categoriaId != null && documento.categoriaId != "")
                return;

            hasError = true;
            toast(`Necessário selecionar uma categoria para o arquivo ${documento.nome}`, { type: toast.TYPE.ERROR })
        })

        if (hasError)
            return setIsLoading(false);


        await anexosService.upload(documentos)
        toast("Anexos salvo com sucesso!", { type: toast.TYPE.SUCCESS })

        setDocumentos([]);
        setIsLoading(false)
        fecharModais();

    }

    return (
        <div>
            {isLoading && <PageLoading />}
            <div>
                <div>
                    <label className='anexe'>
                        <h3>Clique para Anexar</h3>
                        <small>Anexe diversos documentos de uma só vez</small>
                        <input onChange={(event) => adicionarNoDocumentos(event)} type='file' hidden multiple={true}></input>
                    </label>
                </div>
                {documentos.length > 0 &&
                    <div>
                        <div className='table-responsive'>
                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th className='col-5'>Nome do Documento</th>
                                        <th className='col-6'>Categoria</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documentos.map((documento, index) => {
                                        return <tr key={index}>
                                            <td>{documento.nome}</td>
                                            <td>
                                                <select className='form-control' onChange={(event) => {
                                                    documento.categoriaId = event.target.value
                                                }}>
                                                    <option value={""}>Selecione uma categoria</option>
                                                    {categorias.map((categoria, index) => {
                                                        return <option value={categoria.id} key={index}>{categoria.nome}</option>
                                                    })}
                                                </select>
                                            </td>
                                            <td className='text-center'>
                                                <button onClick={() => deletaDocumento(documento)} className='btn btn-outline-danger'>X</button>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='text-right'>
                            <button className='btn btn-outline-primary' onClick={() => upload()}>Salvar</button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

import React, { useEffect, useState, Fragment } from 'react'
import { Button, Col, Row, Form, Table } from 'react-bootstrap'
import CurrencyInput from 'react-currency-input'
import ProteticoService from '../../services/protetico.service.js'
import Swal from 'sweetalert2'

export default function TabelaPreco(props) {

    const [proteticoFranquia, setProteticoFranquia] = useState({})

    useEffect(() => {
        didMount()
    }, [])

    const didMount = async () => {
        const { data } = await ProteticoService.getProteticoFranquia();
        setProteticoFranquia(data)
    }

    const handleSave = async () => {
        if(proteticoFranquia.permitirAlterarPreco){
            const { value } = await Swal.fire({
                title: 'Finalizar Alteração',
                text:'Ao finalizar a alteração na tabela de preços, será necessário permissão do gerente da clínica para uma nova alteração. Deseja confirmar?',
                type: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#dbdbdb',
                cancelButtonText: 'Não confirmar',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Confirmar',
            })
            if (value) {
                await ProteticoService.updateProteticoFranquia(proteticoFranquia)            
                await Swal.fire('Sucesso!', 'Tabela de Preço alterada com sucesso!', 'success')
                didMount()
            }
        }
        else
        {
            await ProteticoService.updateProteticoFranquia(proteticoFranquia)            
            await Swal.fire('Sucesso!', 'Tabela de Preço alterada com sucesso!', 'success')
            didMount()
        }
    }

    const handleChangePreco = (event, index) => {
        const protetico = proteticoFranquia;
        const novoPreco = event.target.value.replace(/\D/g, '')
        protetico.tabelaPrecos[index].valor = Number(novoPreco) / 100
        protetico.tabelaPrecos[index].ativo = true
        setProteticoFranquia(protetico)
    }

    const toggleProtese = (event, index) => {
        const protetico = proteticoFranquia;
        protetico.tabelaPrecos[index].ativo = event.target.checked;
        setProteticoFranquia(protetico);
    }

    return (
        <Fragment>
            <h1 className='h3 m-0 text-secondary font-weight-bold'>Preços de Prótese</h1>
            <Form className='py-5'>
                {proteticoFranquia.tabelaPrecos && proteticoFranquia.tabelaPrecos.length > 0 ? (
                    <Table bordered hover className='bg-white'>
                        <thead>
                            <tr>
                                <th>Prótese</th>
                                <th width='20%' className='text-center'>Preço</th>
                                <th width='10%' className='text-center'>Disponível</th>
                            </tr>
                        </thead>
                        <tbody>
                            {proteticoFranquia.tabelaPrecos.map((protese, index) => (
                                <tr key={index}>
                                    <td>
                                        {protese.nome
                                            ? protese.nome
                                            : protese.procedimentoProtetico
                                                ? protese.procedimentoProtetico.nome
                                                : '-'}
                                    </td>
                                    <td className='text-center'>
                                        <CurrencyInput
                                            className='form-control text-right'
                                            decimalSeparator=','
                                            thousandSeparator='.'
                                            prefix='R$'
                                            placeholder='R$ 00,00'
                                            value={protese.ativo || protese.isNew ? protese.valor : 0}
                                            disabled={!proteticoFranquia.permitirAlterarPreco}
                                            onChangeEvent={(event) => handleChangePreco(event, index)}
                                        />
                                        <span />
                                    </td>
                                    <td className='text-center'>
                                        <label className='checkbox labeless m-0'>
                                            <input
                                                type='checkbox'
                                                defaultChecked={protese.ativo}
                                                disabled={!proteticoFranquia.permitirAlterarPreco}
                                                onChange={(event) => toggleProtese(event, index)}
                                            />
                                            <span />
                                        </label>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <div>Nenhuma prótese encontrada.</div>
                )}
                {proteticoFranquia.permitirAlterarPreco &&
                    <div className='d-flex align-items-center justify-content-end'>
                        <Button variant='primary'
                            onClick={handleSave}
                        >
                            Salvar Alterações
                        </Button>
                    </div>
                }
            </Form>
        </Fragment >
    )
}
import React from 'react'
import { Row, Col, Button, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

import MenuContext from '../../contexts/MenuContexts'
import PermissoesConstants from '../../constants/permissoes.constants'

class AnamneseList extends React.Component {
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    const { anamnese, toggleAnamnese, editarAnamnese } = this.props
    let acoesPermitidas = {}

    return (
      <MenuContext.Consumer>
        {(acoes) => {
          acoes.map((a) => {
            acoesPermitidas[`${a.nome}`] = a
            return true
          })
          return (
            <div>
              <Row className='mb-3'>
                <Col>
                  <h1 className='h3 m-0 text-secondary font-weight-bold'>Anamnese</h1>
                </Col>
                <Col>
                  {acoesPermitidas[PermissoesConstants.ADICIONAR] && (
                    <Link to='/anamnese/cadastrar'>
                      <Button className='float-right'>Cadastrar Pergunta</Button>
                    </Link>
                  )}
                </Col>
              </Row>
              {anamnese && anamnese.length > 0 ? (
                <div>
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>Pergunta</th>
                        <th width='120px'>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {anamnese.map((pergunta, index) => (
                        <tr key={index}>
                          <td>{pergunta.pergunta}</td>
                          <td className='text-center'>
                            {acoesPermitidas[PermissoesConstants.EDITAR] && (
                              <Link
                                to={`/anamnese/editar`}
                                onClick={() => editarAnamnese(pergunta.id)}
                                className='mr-2 btn btn-primary btn-sm'
                              >
                                <FontAwesomeIcon icon={faPen} />
                              </Link>
                            )}
                            {acoesPermitidas[PermissoesConstants.ATIVAR_INATIVAR] && (
                              <Button
                                title={pergunta.ativo ? 'Desativar pergunta' : 'Ativar pergunta'}
                                className='btn'
                                onClick={() => toggleAnamnese(pergunta.id, !pergunta.ativo)}
                                variant={pergunta.ativo ? 'success' : 'danger'}
                                size='sm'
                              >
                                <FontAwesomeIcon icon={faPowerOff} />
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div>Nenhuma pergunta encontrada.</div>
              )}
            </div>
          )
        }}
      </MenuContext.Consumer>
    )
  }
}

export default AnamneseList

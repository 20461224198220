import AxiosClient from './axios-client'
import { saveAs } from 'file-saver'
import { filtroNaoSimAmbos } from '../constants/common.constants'

class FeriadoService {
  getFeriadosCadastrados() {
    return AxiosClient.get('Feriados/getAll')
  }


  getFeriadosCadastradosDoAno(year) {
    return AxiosClient.get(`Feriados/GetAllOfTheYear/${year}`)
  }

  getAnosCadastrados() {
    return AxiosClient.get(`Feriados/GetRegisteredYears`)
  }

  getFeriadoById(id) {
    return AxiosClient.get(`Feriados/getById/${id}`)
  }

  cadastrarFeriado(feriado) {
    return AxiosClient.post('Feriados/add', feriado)
  }

  async alterarFeriado(feriado) {
    return await AxiosClient.put('Feriados/update', feriado)
  }

  async inativarFeriado(feriado) {
    return await AxiosClient.put(`Feriados/inativar/${feriado.id}`)
  }

  async ativarFeriado(feriado) {
    return await AxiosClient.put(`Feriados/ativar/${feriado.id}`)
  }
}

export default new FeriadoService()


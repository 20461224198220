import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { editarPerfil } from '../../services/actions/perfis.actions'

import Perfis from '../../components/Perfis'

const mapStateToProps = (store) => ({
	perfis: store.perfisState.perfis,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ editarPerfil }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Perfis)

import React from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interaction from '@fullcalendar/interaction'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrap from '@fullcalendar/bootstrap'

export default function Calendario({
  events,
  hourStep,
  dateStep,
  firstDay,
  dateClick,
  eventClick,
  viewType,
  header,
  customButtons,
  eventClassName,
  setRef,
  nowIndicator,
  titleFormat,
  eventRender,
  diasFranquia,
  validRange,
  dayRender,
}) {
  let duration = { days: 7 }
  let minutes = 30
  let hours = 0
  if (hourStep && hourStep >= 60) {
    hours = Math.floor(hourStep / 60)
    minutes = hours * 60
    minutes = hourStep - minutes
  } else if (hourStep && hourStep < 60) {
    minutes = Number(hourStep)
  }
  if (dateStep) {
    duration.days = parseInt(dateStep)
  }

  return (
    <FullCalendar
      ref={setRef}
      nowIndicator={nowIndicator}
      dateClick={dateClick}
      eventClick={eventClick}
      events={events}
      defaultView={viewType || 'timelineCustomDays'}
      plugins={[resourceTimeGridPlugin, timeGridPlugin, interaction, bootstrap, dayGridPlugin]}
      themeSystem='bootstrap'
      views={{
        timelineCustomDays: {
          type: 'timeGrid',
          duration,
          visibleRange: validRange,
        },
      }}
      defaultDate={Date.now()}
      eventRender={eventRender}
      firstDay={firstDay ? firstDay : 0}
      titleFormat={titleFormat ? titleFormat : { month: 'long' }}
      customButtons={customButtons}
      allDaySlot={false}
      slotDuration={'00:15:00'}
      header={header}
      slotLabelInterval={{ hours, minutes }}
      weekends={
        diasFranquia &&
        diasFranquia.length > 0 &&
        (!!diasFranquia.find((d) => d.diaSemana === 0) || !!diasFranquia.find((d) => d.diaSemana === 6))
      }
      minTime={'07:00:00'}
      maxTime={'23:15:00'}
      schedulerLicenseKey='GPL-My-Project-Is-Open-Source'
      locale='pt-BR'
      slotLabelFormat={{
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: 'short',
      }}
      height='auto'
      showNonCurrentDates={false}
      eventClassName={eventClassName}
      validRange={validRange}
      dayRender={dayRender}
    />
  )
}

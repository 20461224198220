import AxiosClient from './axios-client'

class PlanoTratamentoService {
  async downloadTemplateAsPDF(planoTratamentoId, tipoTemplate) {
    try {
      const endpoint = `PlanoTratamento/downloadFile/${planoTratamentoId}/${tipoTemplate}`
      const { data } = await AxiosClient.get(endpoint, { responseType: 'arraybuffer' })
      const file = new Blob([data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    } catch (e) { }
  }

  async downloadTemplateAsPDFByParteNome(planoTratamentoId, tipoTemplate, parteNome) {
    try {
      const endpoint = `PlanoTratamento/downloadFile/${planoTratamentoId}/${tipoTemplate}/${parteNome}`
      const { data } = await AxiosClient.get(endpoint, { responseType: 'arraybuffer' })
      const file = new Blob([data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    } catch (e) { }
  }

  async getPagamentosRenegociadosProtetico(planoId) {
    try {
      return await AxiosClient.get(`PlanoTratamento/getPagamentosRenegociadosProtetico/${planoId}`)
    } catch (error) { }
  }

  getById(id) {
    try {
      return AxiosClient.get(`PlanoTratamento/getById/${id}`)
    } catch (error) { }
  }

  getPlanosEmAberto(pacienteId) {
    try {
      return AxiosClient.get(`PlanoTratamento/getPlanosEmAberto/${pacienteId}`)
    } catch (error) { }
  }

  getParcelasEmAberto(planoId, calcularValor = false, trazerProteticos = false) {
    try {
      return AxiosClient.get(
        `PlanoTratamento/getParcelasEmAbertoById/${planoId}?calcularValor=${calcularValor}&trazerProteticos=${trazerProteticos}`
      )
    } catch (error) { }
  }

  getPlanosComProcedimentosEmAberto(pacienteId) {
    try {
      return AxiosClient.get(`PlanoTratamento/getPlanosComProcedimentosEmAberto/${pacienteId}`)
    } catch (error) { }
  }

  async getAllProcedimentos() {
    let procedimentos = []
    try {
      const { data } = await AxiosClient.get(`Procedimento/getAll`)
      procedimentos = data
    } catch (error) {
      console.error(error)
    }
    return procedimentos
  }

  async salvarProcedimentos(planoTratamento) {
    return await AxiosClient.put(`PlanoTratamento/salvarProcedimentos`, planoTratamento)
  }

  async salvarRenegociacao(parcelasCanceladas, pagamentoRenegociado) {
    return await AxiosClient.put(`PlanoTratamento/salvarRenegociacao`, {
      parcelasCanceladas,
      pagamentoRenegociado,
    })
  }

  async salvarRenegociacaoProtese(renegociacao) {
    return await AxiosClient.put(`PlanoTratamento/salvarRenegociacaoProtese`, renegociacao)
  }

  async alterarValidadePlano(parcelasAgrupadas) {
    return await AxiosClient.put(`PlanoTratamento/alterarVencimentoParcelas`, { ...parcelasAgrupadas })
  }

  async cancelarProcedimentos(planoTratamentoId, planoTratamentoProcedimento, pagamentoRenegociado) {
    if (pagamentoRenegociado) {
      pagamentoRenegociado.valorTotal = parseFloat(pagamentoRenegociado.valorTotal.toFixed(2))
    }
    return await AxiosClient.put(`PlanoTratamento/cancelarProcedimentos`, {
      planoTratamentoId,
      planoTratamentoProcedimento,
      pagamentoRenegociado,
    })
  }

  async alterarProtetico(planoTratamentoProcedimentoProteticoDTO) {
    return await AxiosClient.put(`PlanoTratamento/alterarProtetico`, planoTratamentoProcedimentoProteticoDTO)
  }

  getParcelasPlano(planoId) {
    return AxiosClient.get(`PlanoTratamento/getParcelasPlano/${planoId}`)
  }
}

export default new PlanoTratamentoService()

import React, { useState, useEffect, Fragment } from 'react'
import { Button, Table, Form, Col, Row } from 'react-bootstrap'
import { formatMoney } from '../../../utils/string.utils'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import Select from 'react-select'
import moment from 'moment'
import 'moment/locale/pt-br'

import CollapsePanel from '../../Common/CollapsePanel'
import Parcelamento from './Parcelamento'
import { isSameDate } from '../../../utils/time.utils'
import { formatFromMoney } from '../../../utils/string.utils'

export default function SelecaoPlanoPagamento({
  setPlanoPagamento,
  planosOrtodonticos,
  setPagamentoAparelho,
  setIsLoading,
  planoPagamento,
  handleSalvar,
  pagamentoAparelho,
  setDataVencimento,
  dataVencimento,
  aparelhoOptions,
  setSelectedAparelho,
  selectedAparelho,
}) {
  const INITIAL_OPEN_STATE = { step0: false, step1: false }

  const [stepIsOpen, setStepIsOpen] = useState({ ...INITIAL_OPEN_STATE })
  const [showPlanoBalao, setShowPlanoBalao] = useState(false)

  useEffect(() => {
    const novoPlano = { ...planoPagamento }
    delete novoPlano.id
    if (showPlanoBalao) {
      novoPlano.nome = 'Plano diferenciado com Balão'
      novoPlano.numeroParcelas = 1
      novoPlano.prazo = undefined
    } else {
      novoPlano.nome = ''
      novoPlano.valorTotal = '0.0'
      novoPlano.numeroParcelas = undefined
    }
    setPlanoPagamento(novoPlano)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPlanoBalao])

  const toggleIsOpen = (step) => {
    let newSteps = { ...INITIAL_OPEN_STATE }
    setStepIsOpen({
      ...newSteps,
      [step]: !stepIsOpen[step],
    })
  }
  const handleDataVencimento = (data) => {
    if (moment() > data) {
      data = moment()
    }
    setDataVencimento(data)
  }

  const togglePlanoBalao = () => {
    setShowPlanoBalao(!showPlanoBalao)
  }

  const dataVencimentoIsBefore = () =>
    moment(dataVencimento).isBefore(moment(new Date())) &&
    !isSameDate(dataVencimento._isAMomentObject ? dataVencimento.toDate() : dataVencimento, new Date())

  const saveButtonIsDisabled = () =>
    !(formatFromMoney(String(planoPagamento.valorTotal)) > 0) || !dataVencimento || dataVencimentoIsBefore()

  return (
    <div className='mb-3'>
      <Row>
        <Col>
          <h3 className='m-0 mb-4 text-secondary font-weight-bold'>Escolha o Plano de Pagamento</h3>
        </Col>
        <Col md='3'>
          {selectedAparelho && (
            <Fragment>
              <h5 className='m-0 ml-2 text-secondary font-weight-bold'>Aparelho escolhido:</h5>
              <Select
                placeholder='Selecionar aparelho...'
                noOptionsMessage={() => 'Nenhuma opção disponível'}
                options={aparelhoOptions}
                value={selectedAparelho}
                onChange={setSelectedAparelho}
              />
            </Fragment>
          )}
        </Col>
      </Row>
      <CollapsePanel
        stepCode='step0'
        text='Plano de Pagamento Ortodôntico'
        isOpen={stepIsOpen.step0}
        toggleIsOpen={toggleIsOpen}
      >
        {planosOrtodonticos.length > 0 && !showPlanoBalao ? (
          <Table className='my-4' bordered>
            <thead>
              <tr>
                <th>Plano</th>
                <th className='text-center' width='100px'>
                  N. Mêses
                </th>
                <th className='text-center' width='180px'>
                  Valor Total
                </th>
                <th className='text-center' width='180px'>
                  Valor Parcela
                </th>
                <th width='80px' className='text-center'></th>
              </tr>
            </thead>
            <tbody>
              {planosOrtodonticos.map((plano) => {
                const { nome, valorTotal, prazo, id } = plano
                return (
                  <tr
                    className={planoPagamento.id === id ? 'alert-warning' : 'cursor-pointer'}
                    key={id}
                    onClick={() => setPlanoPagamento(plano)}
                  >
                    <td>{nome}</td>
                    <td className='text-center'>{prazo}</td>
                    <td className='text-center'>{formatMoney(valorTotal)}</td>
                    <td className='text-center'>{formatMoney(valorTotal / prazo)}</td>
                    <td className='text-center'>
                      <Form.Check type='radio' name='planoTratamento' checked={planoPagamento.id === id} />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        ) : (
          !showPlanoBalao && <div>Nenhum plano cadastrado no momento</div>
        )}
        {showPlanoBalao && (
          <Parcelamento
            pagamento={planoPagamento}
            isPlanoBalao={showPlanoBalao}
            setPagamento={setPlanoPagamento}
            setIsLoading={setIsLoading}
            dataVencimentoPrimeiraParcela={dataVencimento}
            setDataVencimentoPrimeiraParcela={setDataVencimento}
          />
        )}
        <div className='align-items-center d-flex justify-content-end my-3'>
          {!showPlanoBalao && (
            <Form.Group className='required align-items-center d-flex mb-0'>
              <Form.Label className='mb-0 w-100'>Data de Vencimento:</Form.Label>
              <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                <KeyboardDatePicker
                  invalidDateMessage='A data deve ser a partir de hoje'
                  minDateMessage='A data deve ser a partir de hoje'
                  id='dataNascimento'
                  name='dataNascimento'
                  className='form-control mr-3 ml-n4'
                  onChange={handleDataVencimento}
                  value={dataVencimento}
                  variant='inline'
                  format='DD/MM/YYYY'
                  required
                  animateYearScrolling
                  disablePast
                />
              </MuiPickersUtilsProvider>
            </Form.Group>
          )}
          <Button onClick={togglePlanoBalao} variant='primary'>
            {!showPlanoBalao ? 'Plano Diferenciado com Balão' : 'Plano pré-definido'}
          </Button>
        </div>
      </CollapsePanel>
      {pagamentoAparelho && (
        <CollapsePanel
          stepCode='step1'
          text='Parcelas do Aparelho'
          isOpen={stepIsOpen.step1}
          toggleIsOpen={toggleIsOpen}
        >
          <Parcelamento pagamento={pagamentoAparelho} setPagamento={setPagamentoAparelho} setIsLoading={setIsLoading} />
        </CollapsePanel>
      )}
      <div className='d-flex justify-content-end'>
        <Button disabled={saveButtonIsDisabled()} className='px-4' onClick={handleSalvar}>
          Salvar
        </Button>
      </div>
    </div>
  )
}

import React, { PureComponent } from 'react'
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts'
import { Col } from 'react-bootstrap'
import { statusConstants } from '../../../../constants/agendamento.constants'

const RADIAN = Math.PI / 180

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)
  return (
    <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const getCellName = (entry) => {
  return entry.name === 'AguardandoAtendimento'
    ? 'Aguardando Atendimento'
    : entry.name === 'EmAtendimento'
    ? 'Em Atendimento'
    : entry.name
}

const getColorByTipoAgendamento = (entry) => {
  const name = getCellName(entry)
  switch (name) {
    case statusConstants.FINALIZADO.label:
      return statusConstants.FINALIZADO.color
    case statusConstants.EM_ATENDIMENTO.label:
      return statusConstants.EM_ATENDIMENTO.color
    case statusConstants.AGENDADO.label:
      return statusConstants.AGENDADO.color
    case statusConstants.CONFIRMADO.label:
      return statusConstants.CONFIRMADO.color
    case statusConstants.CANCELADO.label:
      return statusConstants.CANCELADO.color
    case statusConstants.FALTOU.label:
      return statusConstants.FALTOU.color
    case statusConstants.AGUARDANDO.label:
      return statusConstants.AGUARDANDO.color
    default:
      return '#ff00ff'
  }
}

export default class AgendamentoAtendimento extends PureComponent {
  render() {
    const { agendamento } = this.props
    const contemValores = !!agendamento.some((a) => a.value > 0)
    return (
      <Col className='py-3 shadow border'>
        <h3 className='m-0 mb-4 text-secondary font-weight-bold text-center'>Agendamentos Atendidos</h3>
        {contemValores ? (
          <PieChart style={{ margin: '0 auto' }} width={400} height={380}>
            <Pie
              data={agendamento}
              cx={190}
              cy={150}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={130}
              fill='#8884d8'
              dataKey='value'
            >
              {agendamento.map((entry, index) => (
                <Cell name={getCellName(entry)} key={`cell-${index}`} fill={getColorByTipoAgendamento(entry)} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        ) : (
          <div style={{ height: '379.5px' }}>
            <p className='alert alert-warning'>Sua franquia ainda não possui agendamentos atendidos nos registros</p>
          </div>
        )}
      </Col>
    )
  }
}

import React, { useState } from 'react'
import StepWizard from 'react-step-wizard'

import OrtodontiaService from '../../../services/ortodontia.service'
import PageLoading from '../../Common/PageLoading'
import PlanosPaciente from './PlanosPaciente'
import AparelhosComplementares from './AparelhosComplementares'
import SuprimentosManutencao from './SuprimentosManutencao'
import RealizarPagamento from './RealizarPagamento'
import CancelarPlano from './CancelarPlano'
import CancelarPagamento from './CancelarPagamento'
import AlterarVencimento from './AlterarVencimento'
import Renegociacao from './Renegociacao'
import VisualizarParcelasOrtodontia from './VisualizarParcelasOrtodontia'
import HistoricoAtendimentos from './HistoricoAtendimentos'
import { statusPlano } from '../../../constants/common.constants'
import Swal from 'sweetalert2'

const passos = {
  PLANOS: 1,
  APARELHOS: 2,
  SUPRIMENTOS: 3,
  PAGAMENTO: 4,
  CANCELAMENTO: 5,
  CANCELAMENTO_PAGAMENTO: 6,
  ALTERAR_VENCIMENTO: 7,
  RENEGOCIACAO: 8,
  VISUALIZAR_PARC: 9,
  VISUALIZAR_HISTORICO: 10,
}

export default function PacientePlanosOrtodontia({
  planosTratamentoList,
  refreshPlanosTratamentoOrtodontiaList,
  getPagamentosRealizados,
  nomeColaborador,
}) {
  const [wizardRef, setWizardRef] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedPlano, setSelectedPlano] = useState(null)
  const [parcelamentoPagamento, setParcelamentoPagamento] = useState(null)
  const [parcelasPagas, setParcelasPagas] = useState(null)
  const [parcelasPlano, setparcelasPlano] = useState(null)

  const handleStepChange = (p) => {
    if (p.activeStep === passos.PLANOS) {
      setSelectedPlano(null)
      setParcelamentoPagamento(null)
      setParcelasPagas(null)
    }
  }

  const onChangeStep = async (plano, passo) => {
    let canGoToNextStep = true
    let mensagem = ''
    setIsLoading(true)
    setSelectedPlano(plano)
    switch (passo) {
      case passos.PAGAMENTO:
      case passos.RENEGOCIACAO:
        await getAllParcelasEmAberto(plano.id)
        break
      case passos.CANCELAMENTO_PAGAMENTO:
        await getParcelasPagas(plano.id)
        break
      case passos.ALTERAR_VENCIMENTO:
        await getParcelasEmAbertoNaoVencidas(plano.id)
        break
      case passos.VISUALIZAR_PARC:
        let response = await OrtodontiaService.getParcelasPlanoOrtodontia(plano.id)
        setparcelasPlano(response.data)
        break
      case passos.CANCELAMENTO:
        canGoToNextStep =
          plano.statusTratamento === statusPlano.CANCELADO.value ||
          plano.statusTratamento === statusPlano.CANCELADO_AUTOMATICAMENTE.value
            ? false
            : true
        mensagem = 'Este plano já esta cancelado.'
        console.log(plano)
        break
      default:
        break
    }
    if (canGoToNextStep) {
      wizardRef.goToStep(passo)
    } else {
      setIsLoading(false)
      const { value } = await Swal.fire({
        title: mensagem,
        type: 'warning',
        confirmButtonColor: '#3085d6',
      })
    }
    setIsLoading(false)
  }

  const getParcelasPagas = async (planoTratamentoId) => {
    try {
      const { data } = await OrtodontiaService.getPagamentosEfetuados(planoTratamentoId)
      setParcelasPagas(data)
    } catch (e) {
      console.error(e)
    }
  }

  const getParcelasEmAbertoNaoVencidas = async (planoTratamentoId) => {
    try {
      const trazerVencidas = false
      const { data } = await OrtodontiaService.getParcelasByPlanoTratamentoId(planoTratamentoId, trazerVencidas)
      const { pagamentoParcelas } = data
      agruparParcelas(pagamentoParcelas)
    } catch (e) {
      console.error(e)
    }
  }

  const getAllParcelasEmAberto = async (planoTratamentoId) => {
    try {
      const trazerVencidas = true
      const { data } = await OrtodontiaService.getParcelasByPlanoTratamentoId(planoTratamentoId, trazerVencidas)
      const { pagamentoParcelas } = data
      agruparParcelas(pagamentoParcelas)
    } catch (e) {
      console.error(e)
    }
  }

  const agruparParcelas = (pagamentoParcelas) => {
    const parcelamento = {
      plano: {},
      aparelho: {},
      complementar: {},
      suprimentos: {},
    }
    pagamentoParcelas.forEach((parcela) => {
      const {
        planoTratamentoOrtodontiaPagamentoId: planoId,
        planoTratamentoPagamentoAparelhoId: aparelhoId,
        planoTratamentoPagamentoAparelhoComplementarId: complementarId,
        planoTratamentoPagamentoSuprimentoId: suprimentoId,
      } = parcela
      if (planoId) {
        parcelamento.plano[planoId] = parcelamento.plano[planoId] || []
        parcelamento.plano[planoId].push(parcela)
      } else if (aparelhoId) {
        parcelamento.aparelho[aparelhoId] = parcelamento.aparelho[aparelhoId] || []
        parcelamento.aparelho[aparelhoId].push(parcela)
      } else if (complementarId) {
        parcelamento.complementar[complementarId] = parcelamento.complementar[complementarId] || []
        parcelamento.complementar[complementarId].push(parcela)
      } else if (suprimentoId) {
        parcelamento.suprimentos[suprimentoId] = parcelamento.suprimentos[suprimentoId] || []
        parcelamento.suprimentos[suprimentoId].push(parcela)
      }
    })
    setParcelamentoPagamento(parcelamento)
  }

  return (
    <div className='my-4'>
      <StepWizard isLazyMount onStepChange={handleStepChange} instance={setWizardRef}>
        <PlanosPaciente passos={passos} onChangeStep={onChangeStep} planosTratamentoList={planosTratamentoList} />
        <AparelhosComplementares
          setIsLoading={setIsLoading}
          selectedPlano={selectedPlano}
          refreshPlanosTratamentoOrtodontiaList={refreshPlanosTratamentoOrtodontiaList}
        />
        <SuprimentosManutencao
          setIsLoading={setIsLoading}
          selectedPlano={selectedPlano}
          refreshPlanosTratamentoOrtodontiaList={refreshPlanosTratamentoOrtodontiaList}
        />
        <RealizarPagamento
          setIsLoading={setIsLoading}
          selectedPlano={selectedPlano}
          parcelamentoPagamento={parcelamentoPagamento}
          nomeColaborador={nomeColaborador}
          refreshPlanosTratamentoOrtodontiaList={refreshPlanosTratamentoOrtodontiaList}
          getPagamentosRealizados={getPagamentosRealizados}
        />
        <CancelarPlano
          setIsLoading={setIsLoading}
          selectedPlano={selectedPlano}
          refreshPlanosTratamentoOrtodontiaList={refreshPlanosTratamentoOrtodontiaList}
        />
        <CancelarPagamento
          parcelasPagas={parcelasPagas}
          setIsLoading={setIsLoading}
          refreshPlanosTratamentoOrtodontiaList={refreshPlanosTratamentoOrtodontiaList}
          getPagamentosRealizados={getPagamentosRealizados}
        />
        <AlterarVencimento
          setIsLoading={setIsLoading}
          parcelamentoPagamento={parcelamentoPagamento}
          refreshPlanosTratamentoOrtodontiaList={refreshPlanosTratamentoOrtodontiaList}
        />
        <Renegociacao
          setIsLoading={setIsLoading}
          selectedPlano={selectedPlano}
          parcelamentoPagamento={parcelamentoPagamento}
          refreshPlanosTratamentoOrtodontiaList={refreshPlanosTratamentoOrtodontiaList}
        />
        <VisualizarParcelasOrtodontia parcelasPlano={parcelasPlano} selectedPlano={selectedPlano} />
        <HistoricoAtendimentos
          selectedPlano={selectedPlano}
          setIsLoading={setIsLoading}
          refreshPlanosTratamentoOrtodontiaList={refreshPlanosTratamentoOrtodontiaList}
        />
      </StepWizard>
      {isLoading && <PageLoading />}
    </div>
  )
}

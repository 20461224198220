import AxiosClient from './axios-client'

class DashboardService {
  getDados(filtroDeBuscaPadrao) {
    return AxiosClient.post(`Dashboard/getDashboard`, filtroDeBuscaPadrao)
  }

  async getDashboardProfissional(filtros, numeroPagina = 1, registrosPorPagina = 20) {
    try {
      const { data } = await AxiosClient.post(`Dashboard/getDashboardProfissional`, {
        filtros,
        numeroPagina,
        registrosPorPagina,
      })
      return data
    } catch (error) {
      console.log(error)
    }
    return []
  }

  async getDashboard(filtros) {
    try {
      const { data } = await AxiosClient.post(`Dashboard/getNewDashboard`, {
        filtros
      })

      return data
    } catch (error) {
      console.log(error)
    }

    return {
      metric: {
        faturamento: {
          maiorFaturamento: 0,
          faturamentoAtual: 0
        },
      },
      agendamentoClinicoGeralPorPaciente: {
        agendamentos: [],
        presenca: [],
        semReagendar: [],
        faltosos: [],
        totalDeAgendamentos: 0,
        porcentagemPresenca: 0,
        porcentagemSemReagendar: 0,
        porcentagemFaltosos: 0
      }
    }
  }
}

export default new DashboardService()

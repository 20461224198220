export const periodoIndisponivel = {
  MANHA: '0',
  TARDE: '1',
}

export const bloqueioDia = {
  data: '',
  periodoIndisponivel: periodoIndisponivel.MANHA,
  justificativa: '',
  bloqueado: false,
}

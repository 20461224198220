import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Container, Button, Spinner } from 'react-bootstrap'

import PlanoTratamentoService from '../../services/planoTratamento.service'
import { printParcelasReceipt } from '../Templates/PrintableReceipt'
import { TIPOS_TEMPLATE } from '../../constants/template.constants'

export default function Impressao({ avaliacao, pagamentos, numeroPlano, planoId, procedimentosSelecionados }) {
  const [isPrintLoading, setIsPrintLoading] = useState(false)
  const [proteticoSelecionado, setProteticoSelecionado] = useState(false)

  useEffect(() => {
    setProteticoSelecionado(procedimentosSelecionados.some((proc) => !!proc.avaliacaoProcedimentoProtetico))
  }, [procedimentosSelecionados])

  const parcelas = pagamentos.map((pag) => pag.parcelas)
  let parcelasProtese = [...parcelas]
  parcelasProtese.shift()
  const contratante = avaliacao.paciente
    ? {
        ...avaliacao.paciente,
        endereco: avaliacao.paciente.enderecos[0],
      }
    : {}
  const paciente = avaliacao.dependenteId
    ? {
        ...avaliacao.dependente,
        endereco: contratante.endereco,
      }
    : { ...contratante }

  const handleImprimirTermoConsentimento = async () => {
    setIsPrintLoading(true)
    await PlanoTratamentoService.downloadTemplateAsPDF(planoId, TIPOS_TEMPLATE.TermoDeConsentimentoDeTratamento)
    setIsPrintLoading(false)
  }

  const handleImprimirAnexo = async () => {
    setIsPrintLoading(true)
    await PlanoTratamentoService.downloadTemplateAsPDF(planoId, TIPOS_TEMPLATE.AnexoContrato)
    setIsPrintLoading(false)
  }

  const handleImprimirContrato = async () => {
    setIsPrintLoading(true)
    await PlanoTratamentoService.downloadTemplateAsPDF(planoId, TIPOS_TEMPLATE.Contrato)
    setIsPrintLoading(false)
  }

  const handleImprimirRespostasAnamnese = async () => {
    setIsPrintLoading(true)
    await PlanoTratamentoService.downloadTemplateAsPDF(planoId, TIPOS_TEMPLATE.RespostasAnamnese)
    setIsPrintLoading(false)
  }

  const handleImprimirGuia = async () => {
    setIsPrintLoading(true)
    await PlanoTratamentoService.downloadTemplateAsPDF(planoId, TIPOS_TEMPLATE.GuiaDeOrientacaoDeProtese)
    setIsPrintLoading(false)
  }

  const handleImprimirCarne = () => {
    const nomeProtese = []
    procedimentosSelecionados.map(procedimento => {
      if (procedimento.avaliacaoProcedimentoProtetico)
      {
          nomeProtese.push({'id': procedimento.avaliacaoProcedimentoProtetico.id, 'nome': procedimento.avaliacaoProcedimentoProtetico.procedimentoProtetico.nome})
      }
    });

    pagamentos.forEach((pag) => {
      printParcelasReceipt({
        numeroPlano,
        nomeContratante: contratante.nome,
        nomePaciente: paciente.nome,
        nomeProtese: pag.avaliacaoProcedimentoProteticoId ? nomeProtese.find((x) => x.id == pag.avaliacaoProcedimentoProteticoId).nome : '',
        parcelas: pag.parcelas,
        valorTotal: pag.valorTotal,
        type: pag.avaliacaoProcedimentoProteticoId ? 'protese' : 'plano',
      })
    })
  }

  return (
    <Container className='text-center pb-5'>
      <div className='mb-5'>
        <FontAwesomeIcon
          style={{
            fontSize: '11em',
          }}
          className='text-success mt-5 mb-3'
          icon={faCheckCircle}
        />
        <h2 style={fontStyle} className='text-success'>
          Avaliação realizada com sucesso!
        </h2>
      </div>
      <div className='align-items-center d-flex justify-content-center'>
        <Button disabled={isPrintLoading} className='mr-3' variant='primary' onClick={handleImprimirContrato}>
          Imprimir Contrato
        </Button>
        <Button disabled={isPrintLoading} className='mr-3' variant='primary' onClick={handleImprimirAnexo}>
          Imprimir Anexo
        </Button>
        <Button disabled={isPrintLoading} className='mr-3' variant='primary' onClick={handleImprimirTermoConsentimento}>
          Imprimir Termo de Consentimento
        </Button>
        <Button disabled={isPrintLoading} className='mr-3' variant='primary' onClick={handleImprimirRespostasAnamnese}>
          Imprimir Anamnese
        </Button>
        {proteticoSelecionado && (
          <Button disabled={isPrintLoading} className='mr-3' variant='primary' onClick={handleImprimirGuia}>
            Imprimir Guia de Orientação de Prótese
          </Button>
        )}
        <Button
          disabled={isPrintLoading}
          variant='primary'
          className={isPrintLoading ? 'mr-3' : ''}
          onClick={handleImprimirCarne}
        >
          Imprimir Parcelamento
        </Button>
        {isPrintLoading && <Spinner animation='border' size='sm' variant='primary' />}
      </div>
      <div className='alert alert-warning mt-5 mx-auto w-50'>
        <strong>Pop-ups</strong> bloquados no seu navegador, <strong>AdBlock</strong> habilitado para esta página, ou
        algumas <strong>extensões</strong> e <strong>add-ons</strong> instaladas podem ocasionar em problemas ao tentar
        imprimir os documentos desejados
      </div>
    </Container>
  )
}

const fontStyle = {
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
}

import _11 from '../Assets/11.png'
import _12 from '../Assets/12.png'
import _13 from '../Assets/13.png'
import _14 from '../Assets/14.png'
import _15 from '../Assets/15.png'
import _16 from '../Assets/16.png'
import _17 from '../Assets/17.png'
import _18 from '../Assets/18.png'
import _21 from '../Assets/21.png'
import _22 from '../Assets/22.png'
import _23 from '../Assets/23.png'
import _24 from '../Assets/24.png'
import _25 from '../Assets/25.png'
import _26 from '../Assets/26.png'
import _27 from '../Assets/27.png'
import _28 from '../Assets/28.png'
import _31 from '../Assets/31.png'
import _32 from '../Assets/32.png'
import _33 from '../Assets/33.png'
import _34 from '../Assets/34.png'
import _35 from '../Assets/35.png'
import _36 from '../Assets/36.png'
import _37 from '../Assets/37.png'
import _38 from '../Assets/38.png'
import _41 from '../Assets/41.png'
import _42 from '../Assets/42.png'
import _43 from '../Assets/43.png'
import _44 from '../Assets/44.png'
import _45 from '../Assets/45.png'
import _46 from '../Assets/46.png'
import _47 from '../Assets/47.png'
import _48 from '../Assets/48.png'
import _51 from '../Assets/51.png'
import _52 from '../Assets/52.png'
import _53 from '../Assets/53.png'
import _54 from '../Assets/54.png'
import _55 from '../Assets/55.png'
import _61 from '../Assets/61.png'
import _62 from '../Assets/62.png'
import _63 from '../Assets/63.png'
import _64 from '../Assets/64.png'
import _65 from '../Assets/65.png'
import _71 from '../Assets/71.png'
import _72 from '../Assets/72.png'
import _73 from '../Assets/73.png'
import _74 from '../Assets/74.png'
import _75 from '../Assets/75.png'
import _81 from '../Assets/81.png'
import _82 from '../Assets/82.png'
import _83 from '../Assets/83.png'
import _84 from '../Assets/84.png'
import _85 from '../Assets/85.png'

export const statusConstants = {
  AGENDADO: { label: 'Agendado', value: 0, color: '#0881c7' },
  CONFIRMADO: { label: 'Confirmado', value: 1, color: '#fd7e14' },
  CANCELADO: { label: 'Cancelado', value: 2, color: 'brown' },
  FINALIZADO: { label: 'Finalizado', value: 3, color: '#28a745' },
  FALTOU: { label: 'Faltou', value: 4, color: '#FF2A0A' },
  AGUARDANDO: { label: 'Aguardando Atendimento', value: 5, color: '#6c757d' },
  EM_ATENDIMENTO: { label: 'Em Atendimento', value: 6, color: '#6f42c1' },
}

export const status = Object.values(statusConstants)

export const statusAvaliacaoOrtodontica = {
  EXAME_SOLICITADO: { value: 0, label: 'Exame Solicitado' },
  EXAME_MARCADO: { value: 1, label: 'Exame Marcado' },
  TRATAMENTO_DEFINIDO: { value: 2, label: 'Tratamento Definido' },
  FINALIZADA: { value: 3, label: 'Finalizada' },
  REJEITADA_DENTISTA: { value: 4, label: 'Rejeitada pelo Dentista' },
  REJEITADA_PACIENTE: { value: 5, label: 'Rejeitada pelo Paciente' },
  ABANDONO: { value: 6, label: 'Abandono' },
  CANCELADA: { value: 7, label: 'Cancelada' },
}

export const formasPagamentoRadiologia = {
  NA_RADIOLOGIA: {
    label: 'Cliente irá pagar na Radiologia',
    value: 0,
  },
  PELA_CLINICA: {
    label: 'Será por conta da Clínica',
    value: 1,
  },
  PARCELAMENTO: {
    label: 'Cliente irá pagar junto com as parcelas do tratamento',
    value: 2,
  },
}

export const statusAvaliacao = {
  PENDENTE: 0,
  EFETIVADA: 1,
  NAO_EFETIVADA: 2,
}

export const TIPO_CONSULTA = {
  AVALIACAO: { label: 'Avaliação', shortLabel: 'A', value: 0 },
  PROCEDIMENTO: { label: 'Procedimento', shortLabel: 'P', value: 1 },
  MANUTENCAO: { label: 'Manutenção', shortLabel: 'M', value: 2 },
}

export const tipoConsulta = Object.values(TIPO_CONSULTA)

function getParcelas() {
  let PARCELAS = Array(60)
  for (let index = 0; index < PARCELAS.length; index++) {
    PARCELAS[index] = { label: `${index + 1}x`, value: index + 1 }
  }
  return PARCELAS
}

export const parcelasSelect = getParcelas()

export const imagensDentes = {
  11: _11,
  12: _12,
  13: _13,
  14: _14,
  15: _15,
  16: _16,
  17: _17,
  18: _18,
  19: _18,
  21: _21,
  22: _22,
  23: _23,
  24: _24,
  25: _25,
  26: _26,
  27: _27,
  28: _28,
  29: _28,
  31: _31,
  32: _32,
  33: _33,
  34: _34,
  35: _35,
  36: _36,
  37: _37,
  38: _38,
  39: _38,
  41: _41,
  42: _42,
  43: _43,
  44: _44,
  45: _45,
  46: _46,
  47: _47,
  48: _48,
  49: _48,
  51: _51,
  52: _52,
  53: _53,
  54: _54,
  55: _55,
  61: _61,
  62: _62,
  63: _63,
  64: _64,
  65: _65,
  71: _71,
  72: _72,
  73: _73,
  74: _74,
  75: _75,
  81: _81,
  82: _82,
  83: _83,
  84: _84,
  85: _85,
}

import React, { PureComponent } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import { Col } from 'react-bootstrap'
import CustomTooltip from './CustomTooltip'
import AnimatedCounter from './AnimatedCounter'
import CustomAxisTick from './CustomAxisTick'
import CustomLegenda from './CustomLegenda'

export default class Example extends PureComponent {
  getTotais = () => {
    const { avaliacoes } = this.props
    let total = 0
    let somaEfetivados = 0
    let somaNaoEfetivados = 0
    avaliacoes.forEach((aval) => {
      total += aval.efetivado + aval.naoEfetivado
      somaEfetivados += aval.efetivado
      somaNaoEfetivados += aval.naoEfetivado
    })
    return { total, somaEfetivados, somaNaoEfetivados }
  }

  render() {
    const { avaliacoes } = this.props
    const { total, somaEfetivados, somaNaoEfetivados } = this.getTotais()
    return (
      <Col className='py-3 shadow border'>
        <h3 className='m-0 mb-4 text-secondary font-weight-bold text-center'>Avaliações</h3>
        <div style={wrapperDivStyle}>
          <AnimatedCounter
            labelStyle={labelStyle}
            valueStyle={valueStyle}
            containerStyle={containerStyle}
            label='Total:'
            value={total}
            duration={2}
          />
          <AnimatedCounter
            labelStyle={labelStyle}
            valueStyle={valueStyle}
            containerStyle={containerStyle}
            label='Efetivadas:'
            value={somaEfetivados}
            duration={2}
          >
            <span className='text-secondary' style={valueStyle}>
              ({somaEfetivados ? ((somaEfetivados / total) * 100).toFixed(2) : 0}%)
            </span>
          </AnimatedCounter>
          <AnimatedCounter
            labelStyle={labelStyle}
            valueStyle={valueStyle}
            containerStyle={containerStyle}
            label='Não Efetivadas:'
            value={somaNaoEfetivados}
            duration={2}
          >
            <span className='text-secondary' style={valueStyle}>
              ({somaNaoEfetivados ? ((somaNaoEfetivados / total) * 100).toFixed(2) : 0}%)
            </span>
          </AnimatedCounter>
        </div>
        <BarChart width={570} height={300} data={avaliacoes} className='mx-auto mt-1'>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' height={100} tick={<CustomAxisTick />} />
          <YAxis />
          <Tooltip content={<CustomTooltip {...Tooltip} />} />
          <Legend content={<CustomLegenda {...Legend} />} />
          <Bar label='Efetivado' dataKey='efetivado' stackId='a' fill='#0881c7' />
          <Bar label='Não Efetivado' dataKey='naoEfetivado' stackId='a' fill='#0881c76b' />
        </BarChart>
      </Col>
    )
  }
}

const wrapperDivStyle = {
  width: 'max-content',
  margin: '0 auto',
}

const containerStyle = {
  alignItems: 'baseline',
  justifyContent: 'space-between',
  display: 'flex',
}

const labelStyle = {
  marginRight: '30px',
}

const valueStyle = {
  letterSpacing: '1px',
  fontSize: '1.2em',
  fontWeight: 'bold',
}

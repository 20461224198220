import React from 'react'
import Header from './Header'
import { Sidebar } from './Sidebar'
import Container from 'react-bootstrap/Container'
import TEST from '../../enviroment/test.env'


class Wrap extends React.Component {
  state = {
    toggleSidebar: false,
  }

  toggleSidebar = (e) => {
    e.preventDefault()
    this.setState({
      toggleSidebar: !this.state.toggleSidebar,
    })
  }

  render() {
    const menus = this.props.menus.filter(x => x.tipoMenu == 0)
    const menuConfiguracao = this.props.menus.filter(x => x.tipoMenu == 1)
    const isTest = (window.location.origin.indexOf(TEST.REACT_APP_BASE_URL) != -1)  

    return (
      <div className='h-100'>
        <Header nome={this.props.nome} tipoUsuario={this.props.tipoUsuario} menus={menuConfiguracao} toggleSidebar={this.toggleSidebar} isTest={isTest} />
        <Sidebar
          mesesAConfigurar={this.props.mesesAConfigurar}
          menus={menus}
          requestAcoes={this.props.requestAcoes}
          toggleSidebar={this.state.toggleSidebar}
        />
        <Container className={this.state.toggleSidebar ? 'wrap' : 'wrap has-sidebar'} fluid>
          {this.props.children}
        </Container>
      </div>
    )
  }
}

export default Wrap

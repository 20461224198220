import AxiosClient from './axios-client'

class AtendimentoService {
  salvarAvaliacao(fichaAtendimento) {
    return AxiosClient.post('Avaliacao/salvar', fichaAtendimento)
  }

  async naoRealizarProcedimentos(avaliacaoId) {
    return await AxiosClient.put(`Avaliacao/naoRealizarProcedimentos/${avaliacaoId}`)
  }

  salvarAtendimento(planoTratamento) {
    return AxiosClient.post('Avaliacao/gerarPlanoDeTratamento', planoTratamento)
  }

  async salvarStatus(agendamentoId, status) {
    return await AxiosClient.put(`Agendamento/AlterarStatus/${agendamentoId}/${status}`)
  }

  getAvaliacaoByAgendamento(agendamentoId) {
    try {
      return AxiosClient.get(`Avaliacao/getByAgendamentoId/${agendamentoId}`)
    } catch (error) {}
  }

  getAvaliacaoByPacienteId(pacienteId) {
    try {
      return AxiosClient.get(`Avaliacao/getByPacienteId/${pacienteId}`)
    } catch (error) {}
  }

  getAvaliacaoById(avaliacaoId) {
    return AxiosClient.get(`Avaliacao/getById/${avaliacaoId}`)
  }

  getPlanoById(id) {
    return AxiosClient.get(`PlanoTratamento/getById/${id}`)
  }

  getPlanosByPacienteId(pacienteId) {
    return AxiosClient.get(`PlanoTratamento/getByPacienteId/${pacienteId}`)
  }

  async salvarPlanoDeTratamento(planoDeTratamento) {
    return await AxiosClient.put(`PlanoTratamento/salvarProcedimentos`, planoDeTratamento)
  }

  async cancelarAvaliacao(agendamentoId) {
    return await AxiosClient.put(`Avaliacao/cancelarAvaliacao/${agendamentoId}`)
  }

  getPlanosByDependenteId(dependenteId) {
    return AxiosClient.get(`Atendimento/getByDependenteId/${dependenteId}`)
  }
}

export default new AtendimentoService()

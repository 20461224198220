import { Videos } from "../types";

const initialState = {
  videos: [],
  selectedVideo: {}
};

export const videosReducer = (state = initialState, action) => {
  switch (action.type) {
    case Videos.LISTAR_VIDEOS:
      return {
        ...state,
        videos: action.videos
      };
    case Videos.EDITAR_VIDEO:
      return {
        ...state,
        selectedVideo: action.selectedVideo
      };
    default:
      return state;
  }
};

import React, { useState, useEffect, Fragment } from 'react'
import { Col, Row, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPrint } from '@fortawesome/free-solid-svg-icons'

import { statusProtese } from '../../constants/common.constants'
import PageLoading from '../Common/PageLoading'
import ProteticoService from '../../services/protetico.service'

import { printAutorizacaoReceipt } from '../Templates/PrintableReceipt'
import Swal from 'sweetalert2'

const Acompanhamento = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [proteticosOptions, setProteticosOptions] = useState([])
  const [selectedProtetico, setSelectedProtetico] = useState(null)
  const [acompanhamentos, setAcompanhamentos] = useState(null)

  useEffect(() => {
    didMount()
  }, [])

  const didMount = async () => {
    try {
      const { data } = await ProteticoService.getAllProteticos()
      setProteticosOptions(
        data.map((protetico) => ({
          value: protetico.id,
          label: protetico.razaoSocial,
        }))
      )
    } catch (e) {}
    setIsLoading(false)
  }

  const handleSearch = async () => {
    if (!selectedProtetico) {
      toast('Você deve selecionar um protético!', { type: toast.TYPE.ERROR })
    } else {
      setIsLoading(true)
      try {
        const { data } = await ProteticoService.getAcompanhamentoProtese(selectedProtetico.value)
        setAcompanhamentos(data)
      } catch (e) {}
      setIsLoading(false)
    }
  }

  const handlePrintConfeccao = async (index) => {
    setIsLoading(true)
    try {
      const { planoTratamentoId: paramA, planoTratamentoProcedimentoProteticoId: paramB } = acompanhamentos[index]
      const paramC = statusProtese.ENVIADA_CONFECCAO.value
      const { data } = await ProteticoService.alterarSituacaoProcedimentoProtetico(paramA, paramB, paramC)
      const newAcompanhamentos = updateAcompanhamentos(index)
      await handlePrintAutorizacao(
        newAcompanhamentos[index],
        data.numeroPlanoTratamento,
        data.nomeItemProtetico,
        data.valorProteseOriginal
      )
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleSetStatusProtese = async (index, status) => {
    setIsLoading(true)
    try {
      await ProteticoService.alterarSituacaoProcedimentoProtetico(
        acompanhamentos[index].planoTratamentoId,
        acompanhamentos[index].planoTratamentoProcedimentoProteticoId,
        status
      )
      await handleSearch()
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  const updateAcompanhamentos = (index) => {
    const newAcompanhamentos = [...acompanhamentos]
    newAcompanhamentos[index] = {
      ...newAcompanhamentos[index],
      statusProcedimento: statusProtese.ENVIADA_CONFECCAO.value,
    }
    setAcompanhamentos(newAcompanhamentos)
    return newAcompanhamentos
  }

  const handlePrintAutorizacao = async (acompanhamento, numeroPlano, nomeProtetico, valorProtese) => {
    const { nomeColaborador, nomeTitular, nomeDependente, razaoSocialProtetico } = acompanhamento
    await printAutorizacaoReceipt({
      nomeTitular,
      nomeDependente,
      razaoSocialProtetico,
      nomeColaborador,
      numeroPlano,
      nomeProtetico,
      valorProtese,
    })
  }

  const handleConfirmarPronta = async (index) => {
    const { value } = await Swal.fire({
      title: 'Atenção',
      text:
        'Deseja confirmar que a confecção da prótese está pronta? Se confirmar, deverá ser feito um agendamento para o paciente!',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Salvar',
      cancelButtonColor: '#dbdbdb',
      cancelButtonText: 'Cancelar',
    })
    if (value) {
      await handleSetStatusProtese(index, statusProtese.PRONTA.value)
    }
  }

  const getStatusLabel = (status) => {
    const statusObject = Object.values(statusProtese).find((s) => s.value === status)
    return statusObject ? statusObject.label : '-'
  }

  const handleChangeProtetico = (option) => setSelectedProtetico(option)

  const renderTable = () => (
    <Fragment>
      {acompanhamentos && acompanhamentos.length > 0 && (
        <Table className='mt-4 bg-white' bordered hover>
          <thead>
            <tr>
              <th>Procedimento</th>
              <th className='text-center'>Protético</th>
              <th className='text-center' width='150px'>
                Titular
              </th>
              <th className='text-center' width='150px'>
                Dependente
              </th>              
              <th className='text-center' width='150px'>
                Dentista
              </th>
              <th className='text-center' width='70px'>
                Dente
              </th>
              <th className='text-center' width='150px'>
                Status
              </th>
              <th className='text-center' width='80px'>
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            {acompanhamentos.map((acompanhamento, index) => (
              <tr style={{ fontSize: 'smaller' }} key={index}>
                <td>{acompanhamento.nomeProcedimento || '--'}</td>
                <td className='text-center'>{acompanhamento.razaoSocialProtetico || '--'}</td>
                <td className='text-center'>{acompanhamento.nomeTitular || '--'}</td>
                <td className='text-center'>{acompanhamento.nomeDependente || '--'}</td>
                <td className='text-center'>{acompanhamento.nomeColaborador || '--'}</td>
                <td className='text-center'>{acompanhamento.codigoDente || '--'}</td>
                <td className='text-center'>{getStatusLabel(acompanhamento.statusProcedimento)}</td>
                <td className='text-center'>
                  {acompanhamento.statusProcedimento === statusProtese.PAGAMENTO_EFETUADO.value && (
                    <OverlayTrigger
                      trigger='hover'
                      placement='top'
                      overlay={<Tooltip>Imprimir autorização de confecção de prótese</Tooltip>}
                    >
                      <Button onClick={() => handlePrintConfeccao(index)} variant='primary' size='sm'>
                        <FontAwesomeIcon icon={faPrint} />
                      </Button>
                    </OverlayTrigger>
                  )}
                  {acompanhamento.statusProcedimento === statusProtese.ENVIADA_CONFECCAO.value && (
                    <OverlayTrigger
                      trigger='hover'
                      placement='top'
                      overlay={<Tooltip>Confirmar que a prótese está pronta</Tooltip>}
                    >
                      <Button onClick={() => handleConfirmarPronta(index)} variant='success' size='sm'>
                        <FontAwesomeIcon icon={faCheck} />
                      </Button>
                    </OverlayTrigger>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {acompanhamentos && acompanhamentos.length === 0 && <div className='mt-5'>Nenhum acompanhamento encontrado.</div>}
    </Fragment>
  )

  return (
    <Fragment>
      <h1 className='h3 m-0 mb-4 text-secondary font-weight-bold'>Acompanhamento de Confecção</h1>
      <Row>
        <Col md='5'>
          <Select
            placeholder='Filtrar por protético'
            value={selectedProtetico}
            options={proteticosOptions}
            onChange={handleChangeProtetico}
          />
        </Col>
        <Col className='pl-2'>
          <Button onClick={handleSearch} className='float-left' variant='outline-primary'>
            Pesquisar
          </Button>
        </Col>
      </Row>
      {isLoading ? <PageLoading /> : renderTable()}
    </Fragment>
  )
}

export default Acompanhamento

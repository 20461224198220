import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { selectTemplate } from '../../services/actions/templates.actions'

import TemplatesList from '../../components/Templates/TemplatesList'

const mapStateToProps = (store) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({ selectTemplate }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TemplatesList)

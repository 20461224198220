import React from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import MaskedInput from 'react-text-mask'
import MomentUtils from '@date-io/moment'
import { isValidCpf } from '@brazilian-utils/is-valid-cpf'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import 'moment/locale/pt-br'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/free-solid-svg-icons'

class Dependentes extends React.Component {
  state = { invalidCPF: false }

  onBlurCPF = (e) => {
    this.onChange(e)
    this.setState({ invalidCPF: !isValidCpf(e.target.value) && e.target.value.length > 0 })
  }

  onChangeDate = (date) => {
    const { index, dependente } = this.props
    dependente.dataNascimento = date
    this.props.onChange(index, dependente)
  }

  onChange = (e) => {
    const { index, dependente } = this.props
    dependente[e.target.name] = e.target.value
    this.props.onChange(index, dependente)
  }

  render() {
    const { dependente, removeDependente, index, disableAll } = this.props
    return (
      <Row className='align-items-center'>
        <Col md='3'>
          <Form.Group className='required'>
            <Form.Label htmlFor='nome'>Nome</Form.Label>
            <Form.Control
              type='text'
              id='nome'
              name='nome'
              value={dependente.nome}
              onChange={this.onChange}
              maxLength={100}
              disabled={disableAll}
            />
          </Form.Group>
        </Col>
        <Col md='2'>
          <Form.Group className='required'>
            <Form.Label htmlFor='dataNascimento'>Data de nascimento</Form.Label>
            <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
              <KeyboardDatePicker
                invalidDateMessage='Formato de data inválido'
                id='dataNascimento'
                name='dataNascimento'
                className={'form-control ' + (disableAll ? 'disabled-datepicker' : '')}
                value={dependente.dataNascimento}
                onChange={(date) => this.onChangeDate(date)}
                variant='inline'
                format='DD/MM/YYYY'
                animateYearScrolling
                disableFuture
                autoOk
                disabled={disableAll}
              />
            </MuiPickersUtilsProvider>
          </Form.Group>
        </Col>
        <Col md='2'>
          <Form.Group>
            <Form.Label htmlFor='cpf'>CPF</Form.Label>
            <MaskedInput
              mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
              className={this.state.invalidCPF ? 'form-control is-invalid' : 'form-control'}
              id='cpf'
              name='cpf'
              value={dependente.cpf}
              onChange={this.onChange}
              onBlur={this.onBlurCPF}
              disabled={disableAll}
            />
            {this.state.invalidCPF && <div className='invalid-feedback'>Digite um CPF válido.</div>}
          </Form.Group>
        </Col>
        <Col md='2'>
          <Form.Group>
            <Form.Label htmlFor='rg'>RG</Form.Label>
            <Form.Control
              type='text'
              id='rg'
              name='rg'
              value={dependente.rg}
              onChange={this.onChange}
              maxLength={100}
              disabled={disableAll}
            />
          </Form.Group>
        </Col>
        <Col md='1' className='mt-2'>
          {!dependente.id && (
            <Button disabled={disableAll} onClick={() => removeDependente(index)} className='mr-3' variant='danger'>
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          )}
        </Col>
      </Row>
    )
  }
}

export default Dependentes

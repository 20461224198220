import { toast } from 'react-toastify'

class ErrorService {
  /**
   * Show toasters with messages
   * @param {*} error
   */
  showErrorToast(error) {
    const messages = this.getErrorMessages(error)
    messages.forEach((msg) => {
      toast(msg, { type: toast.TYPE.ERROR })
    })
  }

  /**
   * Return an array with all messages of error
   */
  getErrorMessages(error) {
    const hasErrorsList = !!(error && error.response && error.response.data && error.response.data.errors)
    const isArray = hasErrorsList ? error.response.data.errors instanceof Array : false

    if(hasErrorsList && typeof error.response.data.errors[0].message === "undefined")
      return [error.response.data.errors]

    const isDownloadError = !!(error && error.response && error.response.data && error.response.data.byteLength)

    if (isArray) {
      return error.response.data.errors.map((err) => err.message)
    } else if (isDownloadError) {
      try {
        let parsedResponse = this.uintToString(new Uint8Array(error.response.data))
        parsedResponse = JSON.parse(parsedResponse)
        return parsedResponse.errors.map((err) => err.message)
      } catch (error) {
        return ['Houve um erro na requisição']
      }
    } else {
      return ['Houve um erro na requisição']
    }
  }

  uintToString(uintArray) {
    var responseString = String.fromCharCode.apply(null, uintArray)
    responseString = decodeURIComponent(escape(responseString))
    return responseString
  }
}

export default new ErrorService()

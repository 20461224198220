import React, { useState, useEffect } from 'react'
import { Card, Modal, Button, Form, Spinner, Row, Col } from 'react-bootstrap'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'
import moment from 'moment'
import Select from 'react-select'
import { toast } from 'react-toastify'

import ortodontiaService from '../../../services/ortodontia.service'
import PageLoading from '../../Common/PageLoading'
import Swal from 'sweetalert2'

const ModalDetalhesAgendamento = (props) => {
  const { isOpen, handleClose, agendamento } = props

  const [isInnerLoading, setIsInnerLoading] = useState(true)
  const [dataRetorno, setDataRetorno] = useState(new Date())
  const [avaliacao, setAvaliacao] = useState(null)
  const [selectedClicnica, setSelectedClicnica] = useState(null)
  const [clinicaOptions, setClinicaOptions] = useState([])

  useEffect(() => {
    agendamento && didMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendamento])

  const didMount = async () => {
    try {
      await Promise.all([getClinicas(), getAvaliacao()])
    } catch (e) {
      console.error(e)
      handleClose()
    }
    setIsInnerLoading(false)
  }

  const getClinicas = async () => {
    const { data } = await ortodontiaService.getClinicasCadastradas()
    let clinicas = (data || []).filter((clinica) => clinica.ativo)
    clinicas = clinicas.map((clinica) => ({
      value: clinica.id,
      label: clinica.nome,
    }))
    setClinicaOptions(clinicas)
  }

  const getAvaliacao = async () => {
    const { data } = await ortodontiaService.getAvaliacaoById(agendamento.avaliacaoOrtodontiaId)
    setAvaliacao(data)
  }

  const handleSave = async () => {
    setIsInnerLoading(true)
    if (!dataRetorno) {
      toast('Selecione a data do agendamento.', { type: toast.TYPE.ERROR })
    } else if (!selectedClicnica) {
      toast('Selecione a clínica do agendamento.', { type: toast.TYPE.ERROR })
    } else {
      try {
        const confirmValue = await getConfirmValue()
        confirmValue && (await saveAgendamento())
        toast('Reagendamento marcado com sucesso', { type: toast.TYPE.SUCCESS })
        handleClose()
      } catch (e) {
        console.error(e)
      }
    }
    setIsInnerLoading(false)
  }

  const saveAgendamento = async () => {
    await ortodontiaService.salvarReagendamentoRadiologia({
      data: moment(dataRetorno).format('YYYY-MM-DDTHH:mm:ss'),
      avaliacaoOrtodontiaId: avaliacao.id,
      clinicaRadiologiaId: selectedClicnica.value,
    })
  }

  const getConfirmValue = async () => {
    const { data } = await ortodontiaService.validarReagendar(avaliacao.id)
    if (data) {
      const { value } = await Swal.fire({
        title: 'Atenção!',
        text: data,
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#999999',
        confirmButtonColor: '#3085d6',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      })
      return value
    }
    return true
  }

  return (
    <Modal className='modal-agenda' show={isOpen} onHide={handleClose}>
      <Card>
        <Modal.Header className='p-4' closeButton>
          <Modal.Title>Reagendar Retorno de Radiologia</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>
          <Form.Group>
            <Form.Label>
              Paciente:
              <h4>{agendamento.nomeDependente ? agendamento.nomeDependente : agendamento.nomePaciente}</h4>
            </Form.Label>
          </Form.Group>
          <Row>
            <Col>
              <Form.Group className='required'>
                <Form.Label htmlFor='dataNascimento'>Data de retorno à clínica</Form.Label>
                <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                  <KeyboardDateTimePicker
                    className='form-control'
                    value={dataRetorno}
                    onChange={setDataRetorno}
                    variant='inline'
                    format='DD/MM/YYYY HH:mm'
                    required
                    animateYearScrolling
                    disablePast
                  />
                </MuiPickersUtilsProvider>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='required'>
                <Form.Label>Clínica selecionada</Form.Label>
                <Select
                  placeholder='Selecione...'
                  noOptionsMessage={() => 'Nenhuma clínica ativa.'}
                  value={selectedClicnica}
                  options={clinicaOptions}
                  onChange={(option) => setSelectedClicnica(option)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='d-flex flex-fill justify-content-end'>
          <Button onClick={handleSave} variant='primary'>
            {isInnerLoading ? <Spinner animation='border' size='sm' variant='primary' /> : 'Salvar Reagendamento'}
          </Button>
        </Modal.Footer>
      </Card>
      {isInnerLoading && <PageLoading />}
    </Modal>
  )
}

export default ModalDetalhesAgendamento

import React, { useState, useEffect, Fragment } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import formatCPF from '@brazilian-utils/format-cpf'
import { format } from 'date-fns'
import { Col, Row, Table, Button, Spinner } from 'react-bootstrap'

import RelatorioService from '../../services/relatorio.service'
import PageLoading from '../Common/PageLoading'
import Paginator from '../Common/Paginator'
import CSVDownload from './CSVDownload'
import { formatMoney } from '../../utils/string.utils'

export default function VencimentoParcela({ selectedRelatorio }) {
  const itensPorPagina = 20

  const [isLoading, setLoading] = useState(false)
  const [itensLista, setItensLista] = useState([])
  const [cabecalhoRelatorio, setCabecalhoRelatorio] = useState([])
  const [dateFilterDe, setDateFilterDe] = useState(new Date().toISOString())
  const [dateFilterAte, setDateFilterAte] = useState(new Date().toISOString())
  const [feedbackBusca, setFeedbackBusca] = useState('')
  const [pagina, setPagina] = useState(0)
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [itensCSV, setItensCSV] = useState([])
  const [isLoadingCSV, setLoadingCSV] = useState(false)
  const [downloadCSV, setDownloadCSV] = useState(false)
  const [mostrarProtese, setMostrarProtese] = useState(false)

  useEffect(() => {
    setCabecalhoRelatorio([
      'Nome',
      'CPF',
      'Telefone Principal',
      'Whats App',
      'E-mail',
      'Data de Vencimento',
      'Nº Plano',
      'Parcela',
    ])
  }, [])

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilterDe, dateFilterAte, mostrarProtese, pagina])

  useEffect(() => {
    if (downloadCSV) {
      setTimeout(() => {
        setDownloadCSV(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itensCSV, downloadCSV])

  const getValuesLista = async () => {
    const totalDaLista = itensPorPagina * totalPaginas
    if (totalDaLista === 0) {
      return
    }
    setLoadingCSV(true)
    let filtros = [
      {
        campo: 'dataInicial',
        valor: dateFilterDe,
      },
      {
        campo: 'dataFinal',
        valor: dateFilterAte,
      },
      {
        campo: 'mostrarProtese',
        valor: mostrarProtese,
      },
    ]
    const { dados } = await RelatorioService.getRegistrosVencimentoParcela(filtros, 1, totalDaLista)

    const valores = []
    dados.forEach((item) => {
      const itemRelatorio = new Object()
      itemRelatorio.nome = item.nome
      itemRelatorio.cpf = formatCPF(item.cpf)
      itemRelatorio.telefonePrincipal = item.telefonePrincipal
      itemRelatorio.telefonesWhatsApp = item.telefonesWhatsApp
      itemRelatorio.emails = item.emails
      itemRelatorio.dataVencimento = format(new Date(item.dataVencimento), 'dd/MM/yyyy')
      itemRelatorio.numeroPlano = item.numeroPlano
      itemRelatorio.valor = item.valor
      itemRelatorio.protese = item.protese
      valores.push(Object.values(itemRelatorio))
    })
    setItensCSV(valores)
    setDownloadCSV(true)
    setLoadingCSV(false)
    return {
      data: valores,
      headers: cabecalhoRelatorio,
      filename: `${selectedRelatorio.label} - ${format(new Date(), 'dd-MM-yyyy')}.csv`,
    }
  }

  const onChangeMostrarProtese = (e) => {
    setMostrarProtese(e.target.checked)
  }

  const handleSearch = async () => {
    setLoading(true)
    try {
      setFeedbackBusca('')
      let filtros = [
        {
          campo: 'dataInicial',
          valor: dateFilterDe,
        },
        {
          campo: 'dataFinal',
          valor: dateFilterAte,
        },
        {
          campo: 'mostrarProtese',
          valor: mostrarProtese,
        },
      ]
      const { dados, totalPaginas } = await RelatorioService.getRegistrosVencimentoParcela(
        filtros,
        pagina + 1,
        itensPorPagina
      )
      setItensLista(dados)
      setTotalPaginas(totalPaginas)
      if (dados.length === 0) {
        setFeedbackBusca('Nenhum registro encontrado')
      }
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  const handleDateFilterDe = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterDe(filter)
    } else {
      setDateFilterDe(null)
    }
    setPagina(0)
  }

  const handleDateFilterAte = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterAte(filter)
    } else {
      setDateFilterAte(null)
    }
    setPagina(0)
  }

  const handleClear = () => {
    setDateFilterDe(new Date().toISOString())
    setDateFilterAte(new Date().toISOString())
  }

  const handlePagina = (data) => setPagina(data.selected)

  const renderItens = () =>
    itensLista.length > 0 ? (
      <Fragment>
        <div className='mt-4 d-flex justify-content-end align-items-center'>
          <CSVDownload asyncExportMethod={getValuesLista}>
            <Button variant='outline-secondary' className='mb-3'>
              {isLoadingCSV ? <Spinner animation='border' size='sm' variant='light' /> : 'Gerar CSV'}
            </Button>
          </CSVDownload>
        </div>
        <Table hover size='sm' className='border rounded-0 shadow-sm' style={{backgroundColor:'white'}}>
          <thead>
            <tr className='text-center'>
              <th>Nome</th>
              <th>CPF</th>              
              <th>Data de Vencimento</th>
              <th>Telefone Principal</th>
              <th>WhatsApp</th>
              <th>E-mail</th>
              <th>Número Plano</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {itensLista.map((item, i) => {
              const { nome, cpf, telefonePrincipal, telefonesWhatsApp, emails, dataVencimento, numeroPlano, protese, valor } = item
              return (
                <tr key={i} style={{ fontSize: 'smaller' }} className={!!protese ? 'text-center bg-warning-03' : 'text-center'}>
                  <td>{nome.toUpperCase()}</td>
                  <td className='text-center'>{formatCPF(cpf)}</td>
                  <td className='text-center'>{format(new Date(dataVencimento), 'dd/MM/yyyy')}</td>
                  <td className='text-center'>{telefonePrincipal}</td>
                  <td className='text-center'>{telefonesWhatsApp ? telefonesWhatsApp : '-'}</td>
                  <td className='text-center'>{emails ? emails : '-'}</td>
                  <td className='text-center'>{numeroPlano}</td>
                  <td className='text-center'>{formatMoney(valor)}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {totalPaginas > 1 && <Paginator handlePagina={handlePagina} pageCount={totalPaginas} paginaAtual={pagina} />}
      </Fragment>
    ) : (
      <div className='mt-4'>{feedbackBusca}</div>
    )

  const renderLoading = () => <PageLoading />

  return (
    <Fragment>
      <Row>
        <Col md='3' className="d-flex align-items-center">
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              invalidDateMessage='Formato de data inválido'
              value={dateFilterDe}
              onChange={handleDateFilterDe}
              className='form-control'
              variant='inline'
              format='DD/MM/YYYY'
              maxDate={dateFilterAte || new Date('2100-01-01')}
              animateYearScrolling
              autoOk
              placeholder='De'
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col md='3' className="d-flex align-items-center">
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              invalidDateMessage='Formato de data inválido'
              value={dateFilterAte}
              onChange={handleDateFilterAte}
              minDate={dateFilterDe || new Date('1900-01-01')}
              className='form-control mx-1'
              variant='inline'
              format='DD/MM/YYYY'
              animateYearScrolling
              autoOk
              placeholder='Até'
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col className="d-flex align-items-center">
            <label className='checkbox m-0'>
              <input type='checkbox' checked={mostrarProtese} onChange={onChangeMostrarProtese} />
              <span className='d-flex align-items-center'>Mostrar parcelas de prótese</span>
            </label>
        </Col>
      </Row>
      {isLoading ? renderLoading() : renderItens()}
    </Fragment>
  )
}

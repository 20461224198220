import AxiosClient from './axios-client'

class FinanceiroService {
  getParcelasEmAberto(planoId) {
    return AxiosClient.get(`Pagamento/getParcelasByPlanoTratamentoId/${planoId}`)
  }

  getParcelasPagas(planoTratamentoId) {
    return AxiosClient.get(`Pagamento/getByPlanoTratamentoId/${planoTratamentoId}`)
  }

  salvarPagamentoParcelas(parcelas, senha, formaPagamento) {
    return AxiosClient.post(`Pagamento/salvar`, { parcelas, senha, formaPagamento })
  }

  async salvarCancelamentoPagamento(parcelas) {
    return await AxiosClient.put(`Pagamento/cancelarPagamentos`, parcelas)
  }

  async getRegistrosCreditos(filtros, numeroPagina = 1, registrosPorPagina = 20, menuId) {
    try {
      const { data } = await AxiosClient.post(`Pagamento/getContasAReceber`, {
        filtros,
        numeroPagina,
        registrosPorPagina,
        menuId,
      })
      return data
    } catch (error) {
      console.log(error)
      return []
    }
  }

  async getFechamentoCaixa(filtros, numeroPagina = 1, registrosPorPagina = 20, menuId) {
    try {
      const { data } = await AxiosClient.post(`Pagamento/getFechamentoCaixa`, {
        filtros,
        numeroPagina,
        registrosPorPagina
      })
      return data
    } catch (error) {
      console.log(error)
      return []
    }
  }

  async getRegistrosDebitosProtetico(filtros, numeroPagina = 1, registrosPorPagina = 20) {
    try {
      const { data } = await AxiosClient.post(`Pagamento/getContasAPagarProtetico`, {
        filtros,
        numeroPagina,
        registrosPorPagina,
      })
      return data
    } catch (error) {
      console.log(error)
      return []
    }
  }
  async confirmarPagamentoProtetico(id, status) {
    return await AxiosClient.put(`Pagamento/confirmarPagamentoProtetico/${id}/${status}`)
  }

  // getRegistrosDebitosDentistas(filtros, numeroPagina = 1, registrosPorPagina = 20) {
  //   return AxiosClient.post(`Pagamento/getContasAPagarDentistas`, {
  //     filtros,
  //     numeroPagina,
  //     registrosPorPagina,
  //   })
  // }

  getContasAPagar(filtros, numeroPagina = 1, registrosPorPagina = 20) {
    return AxiosClient.post(`ContasPagar/getAll`, {
      filtros,
      numeroPagina,
      registrosPorPagina,
    })
  }

  salvarConta(conta, repeticao) {
    return AxiosClient.post(`ContasPagar/salvar/${repeticao}`, conta)
  }

  async editarConta(conta, repeticao) {
    return await AxiosClient.put(`ContasPagar/editar/${repeticao}`, conta)
  }

  async excluirConta(conta) {
    return await AxiosClient.put(`ContasPagar/excluirConta`, conta)
  }

  async alterarStatusConta(id, status) {
    return await AxiosClient.put(`ContasPagar/alterarStatus/${id}/${status}`)
  }

  getGruposContasPagar() {
    try{
      return AxiosClient.get('ContasPagar/getAllGrupoContasPagar')
      
    } catch (error) {
      console.log(error)
      return []
    }
  }

  getSubGruposContasPagar() {
    try{
      return AxiosClient.get('ContasPagar/getAllSubGrupoContasPagar')
      
    } catch (error) {
      console.log(error)
      return []
    }
  }
  
  salvarSubGrupoContasPagar(grupoId, subgrupo) {
    return AxiosClient.post(`ContasPagar/salvarSubGrupoContasPagar/${grupoId}/${subgrupo}`)
  }

  async alterarSubGrupoContasPagar(id, nome) {
    return await AxiosClient.put(`ContasPagar/alterarSubGrupoContasPagar/${id}/${nome}`)
  }

  async alterarStatusSubGrupoContasPagar(id, nome) {
    return await AxiosClient.put(`ContasPagar/alterarStatusSubGrupoContasPagar/${id}`)
  }

  async excluirSubGrupoContasPagar(id) {
    return await AxiosClient.put(`ContasPagar/excluirSubGrupoContasPagar/${id}`)
  }

  async marcarImpresso(pagamentoId) {
    return await AxiosClient.put(`Pagamento/marcarImpresso/${pagamentoId}`)
  }
}

export default new FinanceiroService()

const TEST = {
  REACT_APP_API_URL: 'https://credodonto-api.supero.com.br/api',
  TOKEN_KEY: 'OdontoTokenAccess',
  ATUALIZAR_AGENDA: 'atualizar_agenda',
  USER: 'USER',
  FRANQUIA: 'Fraquia',
  WEBSOCKET_SERVER: 'https://credodonto-api.supero.com.br/notificationHub',
  CHAT_URL: 'http://40.87.94.229/channel/general',
  CHAT_TOKEN: 'Meteor.loginToken',
  CHAT_USER_ID: 'Meteor.userId',
  CHAT_STATUS: 'Chat.Status',
  REACT_APP_BASE_URL: 'https://sisqa.credodonto.com.br'
}

export default TEST

import React, { Fragment, useEffect, useState } from 'react'
import StepWizard from 'react-step-wizard'

import PlanosDeTratamento from './PlanosDeTratamento'
import EditarPlanoDeTratamento from './EditarPlanoDeTratamento'
import AtendimentoService from '../../services/atendimento.service'
import { enviarMensagem, alertaTermoConclusao } from '../../websocketServer'
import { cargoConstant } from '../../constants/common.constants'
import { statusConstants } from '../../constants/agendamento.constants'
import { tipoMensagem } from '../../constants/websocket.constants'
import { toast } from 'react-toastify'

const Tratamento = ({ agendamento, history }) => {
  const [wizardRef, setWizardRef] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [planosTratamento, setPlanosTratamento] = useState([])
  const [selectedPlano, setSelectedPlano] = useState(null)
  const [showReangendar, setShowReagendar] = useState(false)

  useEffect(() => {
    !agendamento.id ? history.push('/atendimento') : tratamentoDidMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tratamentoDidMount = async () => {
    const { dependenteId, pacienteId } = agendamento
    dependenteId ? await getPlanoDependente(dependenteId) : await getPlanoPaciente(pacienteId)

    setIsLoading(false)
  }

  const getPlanoPaciente = async (pacienteId) => {
    try {
      const { data } = await AtendimentoService.getPlanosByPacienteId(pacienteId)
      setPlanosTratamento(data)
    } catch (e) {}
  }

  const getPlanoDependente = async (dependenteId) => {
    try {
      const { data } = await AtendimentoService.getPlanosByDependenteId(dependenteId)
      console.log(data)
      setPlanosTratamento(data)
    } catch (e) {}
  }

  const getPlanoDeTratamentoById = async (id) => {
    try {
      const { data } = await AtendimentoService.getPlanoById(id)
      setSelectedPlano(data)
    } catch (e) {}
  }

  const scrollToTheTop = () => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }

  const handleEditClick = async (planoId) => {
    setIsLoading(true)
    scrollToTheTop()
    const planoTratamento = planosTratamento.find(plano => plano.id === planoId)

    setSelectedPlano(planoTratamento)
    // await getPlanoDeTratamentoById(planoId)
    wizardRef.nextStep()
    setIsLoading(false)
  }

  const handleSaveClick = async (planoDeTratamento) => {
    setIsLoading(true)
    try {
      await AtendimentoService.salvarPlanoDeTratamento(planoDeTratamento)
      toast('Alterações salvas com sucesso', { type: toast.TYPE.SUCCESS })
      handleFinalizarAtendimento()
    } catch (e) {}
    setIsLoading(false)
  }

  const handleBackClick = () => {
    scrollToTheTop()
    setSelectedPlano(null)
    wizardRef.previousStep()
  }

  const handleMensagemWebsocket = async () => {
    const { dependenteId, dependente, paciente, horaInicio } = agendamento
    const nomePaciente = dependenteId ? dependente.nome : paciente.nome
    try {
      await enviarMensagem(
        tipoMensagem.MENSAGEM_ALERTA,
        cargoConstant.ATENDENTE.value,
        `O paciente ${nomePaciente}, agendado às ${horaInicio} precisa agendar um retorno para a próxima data possível`,
        '/agendamento'
      )
    } catch (error) {
      console.error(error)
    }
  }

  const handleFinalizarAtendimento = async () => {
    setIsLoading(true)
    try {
      await AtendimentoService.salvarStatus(agendamento.id, statusConstants.FINALIZADO.value);

      history.push('/atendimento');
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  return (
    <Fragment>
      <StepWizard isLazyMount instance={setWizardRef}>
        <PlanosDeTratamento
          isLoading={isLoading}
          planosTratamento={planosTratamento}
          handleEditClick={handleEditClick}
        />
        <EditarPlanoDeTratamento
          showReangendar={showReangendar}
          setShowReagendar={setShowReagendar}
          agendamento={agendamento}
          isLoading={isLoading}
          selectedPlano={selectedPlano}
          handleBackClick={handleBackClick}
          handleSaveClick={handleSaveClick}
          handleMensagemWebsocket={handleMensagemWebsocket}
        />
      </StepWizard>
    </Fragment>
  )
}

export default Tratamento

import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import MaskedInput from 'react-text-mask'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { isValidCpf } from '@brazilian-utils/is-valid-cpf'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import formatCpf from '@brazilian-utils/format-cpf'
import Swal from 'sweetalert2'

import TimeSelector from './TimeSelector'
import Telefones from '../Common/Telefones'
import SharedService from '../../services/shared.service'
import { RESET_PACIENTE } from '../../services/types/pacientes.types'
import { status } from '../../constants/agendamento.constants'
import AgendamentoService from '../../services/agendamentos.service'
import { toast } from 'react-toastify'

const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
const TipoConsulta = {
  AVALIACAO: { value: 0, label: 'Avaliação' },
  PROCEDIMENTO: { value: 1, label: 'Procedimento' },
  MANUTENCAO: { value: 2, label: 'Manutenção' },
}

const convertToOption = (modelo) => {
  if (!modelo) {
    return null
  }

  return { label: modelo.nome, value: modelo.id }
}

const ModalData = (props) => {
  const dispatch = useDispatch()
  const {
    isOpen,
    handleClose,
    colaboradores,
    schedule,
    selectedPaciente,
    getPacienteByCpf,
    dateStep,
    especialidade,
    dentista,
    startDate,
    endDate,
    excludeDates,
    diasFranquia,
  } = props

  const [selectedTipoConsulta, setSelectedTipoConsulta] = useState(TipoConsulta.AVALIACAO.value)
  const [dataHoraInicio, setDataHoraInicio] = useState(undefined)
  const [especialidades, setEspecialidades] = useState([])
  const [selectedEspecialidade, setSelectedEspecialidade] = useState(convertToOption(especialidade))
  const [visibleColaboradores, setVisibleColaboradores] = useState(colaboradores)
  const [selectedColab, setSelectedColab] = useState(convertToOption(dentista))
  const [nomePaciente, setNomePaciente] = useState('')
  const [pacienteCadastrado, setPacienteCadastrado] = useState(false)
  const [statusAgendamento, setStatusAgendamento] = useState(status[0])
  const [selectedDependente, setSelectedDependente] = useState(null)
  const [pacienteId, setPacienteId] = useState('')
  const [telefones, setTelefones] = useState([{ numero: '' }])
  const [cpfPaciente, setCpfPaciente] = useState('')
  const [validCpf, setValidCpf] = useState(true)
  const [observacao, setObservacao] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [agendamentoId, setId] = useState(undefined)
  const [isOrtodontia, setIsOrtodontia] = useState(false)

  useEffect(() => {
    getAllEspecialidades()
  }, [])

  useEffect(() => {
    setIsLoading(true)
    if (selectedEspecialidade) {
      setIsOrtodontia(selectedEspecialidade.label.toLowerCase() === 'ortodontia')

      setVisibleColaboradores(
        colaboradores.filter(
          (colaborador) =>
            !!colaborador.especialidades.find((espec) => espec.especialidadeId === selectedEspecialidade.value)
        )
      )
    } else {
      setVisibleColaboradores(colaboradores)
    }
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEspecialidade])

  useEffect(() => {
    setIsLoading(true)
    schedule.date && setDataHoraInicio(schedule.date)
    const { id } = schedule
    if (id) {
      setNomePaciente(schedule.nomePaciente)
      setCpfPaciente(formatCpf(schedule.cpfPaciente))
      getPacienteByCpf(formatCpf(schedule.cpfPaciente))
      setDataHoraInicio(moment(schedule.data).toDate())
      setSelectedColab(colaboradores.find((c) => c.value === schedule.colaboradorId))
      setSelectedEspecialidade({ label: schedule.especialidade.nome, value: schedule.especialidade.id })
      setStatusAgendamento(status.find((s) => s.value === schedule.statusAgendamento))
      setTelefones(schedule.telefones)
      setId(id)
      if (schedule.dependenteId) {
        setSelectedDependente({ label: schedule.dependente.nome, value: schedule.dependente.id })
      }
      setSelectedTipoConsulta(schedule.tipoConsulta)
    }
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule])

  useEffect(() => {
    setIsLoading(true)
    const { id } = selectedPaciente
    if (id) {
      setNomePaciente(selectedPaciente.nome)
      setTelefones(selectedPaciente.telefones)
      setPacienteCadastrado(true)
      setPacienteId(id)
      if (selectedPaciente.dependentes.length > 0 && schedule.dependenteId) {
        const dependente = selectedPaciente.dependentes.filter((d) => d.id === schedule.dependenteId)[0]
        setSelectedDependente({ label: dependente.nome, value: dependente.id })
      }
    }
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaciente])

  const getAllEspecialidades = async () => {
    try {
      const { data } = await SharedService.getAllEspecialidades()
      const newEspecialidades = data.map((espec) => ({
        value: espec.id,
        label: espec.nome,
      }))
      setEspecialidades(newEspecialidades)
    } catch (e) {}
  }

  const clear = () => {
    setNomePaciente('')
    setDataHoraInicio(null)
    setSelectedColab(null)
    setPacienteCadastrado(false)
    setStatusAgendamento(status[0])
    setPacienteId('')
    setTelefones([{ numero: '' }])
    setCpfPaciente('')
    setIsLoading(false)
    handleClose()
  }

  const setNome = ({ target }) => setNomePaciente(target.value)

  const setdataHoraInicio = (dataHoraInicio) => setDataHoraInicio(dataHoraInicio)

  const setTelefone = (telefone, index) => {
    let telefonesUpdate = [...telefones]
    telefonesUpdate[index] = telefone
    setTelefones(telefonesUpdate)
  }

  const removeTelefone = (index) => {
    let telefonesUpdate = [...telefones]
    telefonesUpdate.splice(index, 1)
    setTelefones(telefonesUpdate)
  }

  const addTelefone = () => {
    let telefonesUpdate = [...telefones]
    telefonesUpdate.push({ numero: '' })
    setTelefones(telefonesUpdate)
  }

  const renderTelefone = (index, telefone) => (
    <Row key={index}>
      <Col>
        <Telefones
          disabled={pacienteCadastrado}
          index={index}
          telefone={telefone}
          required={true}
          onChange={setTelefone}
        />
      </Col>
      {!pacienteCadastrado && (
        <>
          <Col md='2' className='pt-3'>
            {index >= 1 && (
              <Button
                onClick={() => removeTelefone(index)}
                disabled={pacienteCadastrado}
                className='fix-btn-margin mr-2'
                variant='primary'
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
            )}
            <Button onClick={addTelefone} disabled={pacienteCadastrado} className='fix-btn-margin' variant='primary'>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Col>
        </>
      )}
    </Row>
  )

  const getAgendamentoBody = () => ({
    nomePaciente,
    pacienteId,
    telefones,
    cpfPaciente,
    observacao,
    colaboradorId: selectedColab ? selectedColab.value : null,
    tipoConsulta: selectedTipoConsulta,
    statusAgendamento: statusAgendamento.value,
    especialidadeId: selectedEspecialidade ? selectedEspecialidade.value : null,
    id: agendamentoId,
    dependenteId: selectedDependente && selectedDependente.value ? selectedDependente.value : undefined,
    horaInicio: moment(dataHoraInicio).format('HH:mm'),
    data: moment(dataHoraInicio)
      .subtract(3, 'hours')
      .toDate(),
  })

  const getHorarioDentistaConfirmValue = async (mensagem) => {
    if (mensagem) {
      const { value } = await Swal.fire({
        title: 'Confirmar Horário!',
        text: mensagem,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceitar',
        confirmButtonColor: '#3085d6',
        cancelButtonClass: 'text-primary',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#fff',
      })
      return value
    }
    return true
  }

  const getParcelasEmAtrasoConfirmValue = async (mensagem) => {
    if (mensagem) {
      const { value } = await Swal.fire({
        title: 'Parcelas em Atraso!',
        text: mensagem,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceitar',
        confirmButtonColor: '#3085d6',
        cancelButtonClass: 'text-primary',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#fff',
      })
      return value
    }
    return true
  }

  const verificarHorarioDentista = async (agendamento) => {
    const { data } = await AgendamentoService.verificarHorarioDentista(agendamento)
    return await getHorarioDentistaConfirmValue(data.mensagem)
  }

  const verificarParcelasEmAtraso = async (agendamento) => {
    let devedorConfirmValue = true;

    const { data } = await AgendamentoService.verificarParcelasEmAtraso(agendamento);

    if (selectedTipoConsulta === TipoConsulta.PROCEDIMENTO.value || selectedTipoConsulta === TipoConsulta.MANUTENCAO.value) {
      devedorConfirmValue = await getParcelasEmAtrasoConfirmValue(data.mensagem)
    }
    else {
      if (data.pacienteNegativado) {
        devedorConfirmValue = false;

        Swal.fire({
          title: 'Parcelas em Atraso!',
          text: data.mensagem,
          type: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          confirmButtonColor: '#3085d6'          
        })
      }
    }
    return devedorConfirmValue
  }

  const validTelefones = (agendamento) => !agendamento.telefones.some((tel) => tel.numero.length < 13)

  const onSubmit = async (e) => {

    e.preventDefault()
    const agendamento = getAgendamentoBody()

    if (!selectedColab) {
      toast('Escolha um dentista para o atendimento', { type: toast.TYPE.ERROR })
    } else if (!nomePaciente) {
      toast('Escolha um paciente para o atendimento', { type: toast.TYPE.ERROR })
    } else if (!validTelefones(agendamento)) {
      toast('Telefone inválido', { type: toast.TYPE.ERROR })
    } else if (!validCpf) {
      toast('CPF inválido', { type: toast.TYPE.ERROR })
    } else {
      try {
        setIsLoading(true)
        const horarioDentistaConfirmValue = await verificarHorarioDentista(agendamento)
        const devedorConfirmValue = await verificarParcelasEmAtraso(agendamento)
        if (horarioDentistaConfirmValue && devedorConfirmValue) {
          await AgendamentoService.salvarAgendamento(agendamento)
          toast('Agendamento registrado', { type: toast.TYPE.SUCCESS })
          clear()
        }
      } catch (e) {
        console.error(e)
      }
      setIsLoading(false)
    }
  }

  const onChangeCpf = ({ target }) => {
    const { value } = target
    setIsLoading(true)
    setPacienteId('')
    setPacienteCadastrado(false)
    setCpfPaciente(formatCpf(value))
    let telefonesAjustados = telefones.map((t) => {
      delete t.id
      delete t.dataCriacao
      delete t.principal
      return { ...t }
    })
    if (!pacienteCadastrado && telefones.find((t) => t.principal)) {
      const index = telefonesAjustados.findIndex((t) => t.principal)
      telefonesAjustados[index].principal = false
    }
    setTelefones(telefonesAjustados)

    if (value.length === 14 && isValidCpf(value)) {
      dispatch({ type: RESET_PACIENTE })
      getPacienteByCpf(value)
    } else {
      setPacienteCadastrado(false)
      setTelefones([{ numero: '' }])
      setNomePaciente('')
    }

    setIsLoading(false)
  }

  const onChangeEspecialidade = (value) => {
    setSelectedEspecialidade(value)
    setSelectedColab(null)
  }

  const onBlurCPF = () => {
    setValidCpf(isValidCpf(cpfPaciente) || cpfPaciente.length < 1)
  }

  const handleChangeObservacao = (event) => {
    setObservacao(event.target.value)
  }

  const handleTipoConsultaChange = (event) => {
    setSelectedTipoConsulta(+event.target.value)
  }

  const getDependentesOptions = () => {
    let dependentes =
      selectedPaciente.dependentes && selectedPaciente.dependentes.length > 0
        ? selectedPaciente.dependentes.map((d) => ({
            label: d.nome,
            value: d.id,
          }))
        : []
    dependentes.length > 0 &&
      dependentes.unshift({
        label: 'Selecionar dependente',
        value: null,
      })
    return dependentes
  }

  return (
    <Modal className='modal-agenda' show={isOpen} onHide={clear}>
      <Card>
        <Form onSubmit={onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Agendamento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor='tipoConsulta'>Tipo de Consulta</Form.Label>
                  <Row>
                    <Col>
                      <label className='checkbox m-0'>
                        <input
                          type='checkbox'
                          onChange={handleTipoConsultaChange}
                          checked={selectedTipoConsulta === TipoConsulta.AVALIACAO.value}
                          value={TipoConsulta.AVALIACAO.value}
                        />
                        <span className='d-flex align-items-center'>{TipoConsulta.AVALIACAO.label}</span>
                      </label>
                    </Col>
                    <Col>
                      <label className='checkbox m-0'>
                        <input
                          type='checkbox'
                          onChange={handleTipoConsultaChange}
                          checked={selectedTipoConsulta === TipoConsulta.PROCEDIMENTO.value}
                          value={TipoConsulta.PROCEDIMENTO.value}
                        />
                        <span className='d-flex align-items-center'>{TipoConsulta.PROCEDIMENTO.label}</span>
                      </label>
                    </Col>
                  </Row>
                  {isOrtodontia && (
                    <Row>
                      <Col>
                        <label className='checkbox m-0'>
                          <input
                            type='checkbox'
                            onChange={handleTipoConsultaChange}
                            checked={selectedTipoConsulta === TipoConsulta.MANUTENCAO.value}
                            value={TipoConsulta.MANUTENCAO.value}
                          />
                          <span className='d-flex align-items-center'>{TipoConsulta.MANUTENCAO.label}</span>
                        </label>
                      </Col>
                    </Row>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='required'>
                  <Form.Label htmlFor='tipoConsulta'>Horário</Form.Label>
                  <br />
                  <TimeSelector
                    dateStep={dateStep}
                    date={dataHoraInicio}
                    handleDate={setdataHoraInicio}
                    minDate={startDate}
                    maxDate={endDate}
                    excludeDates={excludeDates}
                    diasFranquia={diasFranquia}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='required'>
                  <Form.Label>Especialidade</Form.Label>
                  <Select
                    placeholder='Selecione...'
                    onChange={onChangeEspecialidade}
                    value={selectedEspecialidade}
                    options={especialidades}
                    isDisabled={especialidade !== undefined}
                    noOptionsMessage={() => 'Nenhuma especialidade cadastrada'}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='required'>
                  <Form.Label htmlFor='colab'>Dentista</Form.Label>
                  <Select
                    placeholder='Selecione...'
                    onChange={(value) => setSelectedColab(value)}
                    value={selectedColab}
                    options={visibleColaboradores}
                    isDisabled={!selectedEspecialidade || dentista !== undefined}
                    noOptionsMessage={() => 'Nenhum dentista para esta especialidade'}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='required'>
                  <Form.Label htmlFor='nomePaciente'>CPF</Form.Label>
                  <MaskedInput
                    mask={cpfMask}
                    className={!validCpf ? 'form-control is-invalid' : 'form-control'}
                    id='cpf'
                    name='cpf'
                    value={cpfPaciente}
                    onChange={onChangeCpf}
                    onBlur={onBlurCPF}
                    required
                    placeholder='CPF do paciente'
                  />
                  {!validCpf && <div className='invalid-feedback'>Digite um CPF válido.</div>}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='required'>
                  <Form.Label htmlFor='nomePaciente'>Nome</Form.Label>
                  <Form.Control
                    disabled={pacienteCadastrado}
                    value={nomePaciente}
                    id='nomePaciente'
                    name='nomePaciente'
                    onChange={setNome}
                    placeholder='Nome do paciente'
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='d-flex align-items-start'>
              <Col>{telefones.map((telefone, index) => renderTelefone(index, telefone))}</Col>
              <Col>
                {getDependentesOptions().length > 0 && pacienteCadastrado && (
                  <Form.Group className=''>
                    <Form.Label htmlFor='colab'>Dependentes</Form.Label>
                    <Select
                      placeholder='Selecione...'
                      onChange={(value) => setSelectedDependente(value)}
                      value={selectedDependente}
                      options={getDependentesOptions()}
                      noOptionsMessage={() => 'Nenhum dependente cadastrado'}
                    />
                  </Form.Group>
                )}
                <Form.Group>
                  <Form.Label>Observação</Form.Label>
                  <Form.Control
                    value={observacao}
                    name='observacao'
                    onChange={handleChangeObservacao}
                    placeholder='Observação'
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-end'>
            <Button onClick={clear} variant='outline-secondary'>
              Cancelar
            </Button>
            <Button type='submit' variant='primary'>
              {isLoading ? <Spinner animation='border' size='sm' variant='light' /> : 'Salvar'}
            </Button>
          </Modal.Footer>
        </Form>
      </Card>
    </Modal>
  )
}

export default ModalData

import { HubConnectionState } from '@aspnet/signalr'
import Swal from 'sweetalert2'

import SystemConstants from '../constants/system.constants'
import {
  MENSAGEM_ALERTA,
  RELOAD_AGENDA,
  RELOAD_AGENDA_DENTISTA,
  ENVIAR_MENSAGEM_GRUPO,
  ENVIAR_ALERTA_TERMO_CONCLUSAO
} from '../constants/websocket.constants'
import * as Agendamentos from './reducers/agendamentos.reducer'
import * as AgendamentosDentista from './reducers/agendamentosDentista.reducer'

import { TIPOS_TEMPLATE } from '../constants/template.constants'
import PlanoTratamentoService from '../services/planoTratamento.service'
import OrtodontiaService from '../services/ortodontia.service'



export default class WebSocketService {
  connection = {}
  colaboradorId = ''
  franquiaId = ''
  dispatch = {}

  constructor(connection) {
    this.connection = connection
    this.fraquiaId = localStorage.getItem(SystemConstants.getFranquia())
  }

  async startServer() {
    try {
      await this.connection.start()
      console.log('NotificationHub conectado com sucesso!')
    } catch (err) {}
  }

  async setEventListeners(colaboradorId, dispatch) {
    try {
      this.colaboradorId = colaboradorId
      this.dispatch = dispatch
      this.alertaMensagens()
      this.reloadAgenda()
      this.alertaTermoConclusao()
      await this.connection.invoke('AddToGroup', colaboradorId)
    } catch (error) {}
  }

  alertaMensagens() {
    this.connection.on(MENSAGEM_ALERTA, async (msg, caminho) => {
      this.dispatch({
        type: Agendamentos.REQUEST_AGENDAMENTOS,
      })
      if (!msg || msg.length === 0) {
        return
      }
      try {
        await Swal.fire({
          title: 'Aviso!',
          type: 'warning',
          text: msg,
          showCancelButton: false,
          confirmButtonText: 'Ok!',
          confirmButtonColor: '#3085d6',
        })
      } catch (error) {}
    })
  }

  reloadAgenda() {
    this.connection.on(RELOAD_AGENDA, async () => {
      try {
        this.dispatch({ type: Agendamentos.REQUEST_AGENDAMENTOS })
      } catch (error) {}
    })
    this.connection.on(RELOAD_AGENDA_DENTISTA, async () => {
      try {
        this.dispatch({ type: AgendamentosDentista.INIT_RELOAD_DENDISTA })
      } catch (error) {}
    })
  }

  keepAlive() {
    this.connection.onclose = this.startServer
    try {
      if (this.connection.state === HubConnectionState.Disconnected) {
        setTimeout(() => this.startServer(), 5000)
      }
    } catch (error) {}
  }

  async stopServer() {
    try {
      await this.connection.invoke('RemoveFromGroup', this.colaboradorId)
      await this.connection.stop()
      console.log('NotificationHub desconectado')
    } catch (error) {}
  }

  async enviarMensagem(tipoMensagem, cargo, mensagem, caminho) {
    try {
      await this.connection.invoke(ENVIAR_MENSAGEM_GRUPO, this.fraquiaId, tipoMensagem, cargo, mensagem, caminho)
    } catch (error) {}
  }

  async alertaTermoConclusao(){
    
    this.connection.on(ENVIAR_ALERTA_TERMO_CONCLUSAO, async (a, b, c, d) => {
      
      try {

        Swal.fire({
          type: 'info',
          title: '<span style="color:#ff0000"> <h5> Termo de Conclusão </h5><span>',
          html:  `<p> Plano de Tratamento <b>${d.data.planoTratamento}</b> </p> <p> Dentista ${d.data.nomeColaborador} </p> <p> <b> ${d.data.nomePaciente} </b> </p>`,
          showCloseButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Imprimir'
        }).then((result) => {
          if (result && result.value) {

            if(d.data.ortodontia)
              OrtodontiaService.downloadTemplateAsPDF(d.data.planoTratamentoId, TIPOS_TEMPLATE.TermoDeConclusaoDeOrtodontia)
            else
              PlanoTratamentoService.downloadTemplateAsPDF(d.data.planoTratamentoId, TIPOS_TEMPLATE.TermoDeConclusaoDeTratamento)

            return false;
          }
        })

      }
      catch(error){
      }
    });
  }

  getEmitter() {
    return this.emitter
  }
}

import createSagaMiddleware from 'redux-saga'
import { createStore, applyMiddleware, compose } from 'redux'

import { Reducers } from '../reducers'
import sagaListener from '../sagas'

const initialState = {}

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const OdontoStore = createStore(Reducers, initialState, composeEnhancers(applyMiddleware(sagaMiddleware)))
sagaMiddleware.run(sagaListener)

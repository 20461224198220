import React, { useEffect, useState } from 'react'
import StepWizard from 'react-step-wizard'

import ListaSuprimentos from './ListaSuprimentos'
import Parcelamento from './Parcelamento'
import Impressao from './Impressao'
import OrtodontiaService from '../../../../services/ortodontia.service'

export default function SuprimentosManutencao({
  firstStep,
  selectedPlano,
  setIsLoading,
  refreshPlanosTratamentoOrtodontiaList,
}) {
  const [wizardRef, setWizardRef] = useState(null)
  const [suprimentosSemPagamento, setSuprimentosSemPagamento] = useState([])
  const [pagamento, setPagamento] = useState(null)

  useEffect(() => {
    if (selectedPlano && selectedPlano.suprimentos) {
      setSuprimentosSemPagamento(
        selectedPlano.suprimentos.filter((sup) => !sup.pagamentos || sup.pagamentos.length === 0)
      )
    }
  }, [selectedPlano])

  const handleSelectSuprimento = (suprimento) => {
    setPagamento({
      valorTotal: suprimento.valor,
      dataVencimento: new Date(),
      numeroParcelas: 1,
      planoTratamentoOrtodontiaSuprimentoId: suprimento.id,
      parcelas: [
        {
          valor: suprimento.valor,
          numero: 1,
          dataVencimento: new Date(),
          status: 0,
        },
      ],
    })
    wizardRef.nextStep()
  }

  const handleSalvar = async () => {
    setIsLoading(true)
    try {
      await OrtodontiaService.salvarPagamentoSuprimento({
        ...pagamento,
        valorTotal: Number(pagamento.valorTotal),
        parcelas: pagamento.parcelas.map((parcela) => ({
          ...parcela,
          status: 0,
        })),
      })
      wizardRef && wizardRef.nextStep()
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleGoBackListaPlanos = () => {
    wizardRef.firstStep()
    firstStep()
    refreshPlanosTratamentoOrtodontiaList()
  }

  return (
    <div>
      <StepWizard isLazyMount instance={setWizardRef}>
        <ListaSuprimentos
          suprimentosSemPagamento={suprimentosSemPagamento}
          handleSelectSuprimento={handleSelectSuprimento}
          handleGoBackListaPlanos={handleGoBackListaPlanos}
        />
        <Parcelamento pagamento={pagamento} setPagamento={setPagamento} handleSalvar={handleSalvar} />
        <Impressao
          pagamento={pagamento}
          selectedPlano={selectedPlano}
          handleGoBackListaPlanos={handleGoBackListaPlanos}
        />
      </StepWizard>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { Card, Modal, Button, Form, Spinner, InputGroup, Row, Col, FormControl } from 'react-bootstrap'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { toast } from 'react-toastify'
import Select from 'react-select'
import CurrencyInput from 'react-currency-input'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'
import { faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import financeiroService from '../../../services/financeiro.service'
import colaboradoresService from '../../../services/colaboradores.service'
import { handleDecimalConvert } from '../../../utils/string.utils'
import Swal from 'sweetalert2'

export default function ModalConta({ isOpen, handleClose, conta, isEdit, handleUpdate, handleUpdateFilterGrupo }) {
  const [isInnerLoading, setIsInnerLoading] = useState(false)
  const [dataLancamento, setDataLancamento] = useState(new Date())
  const [dataVencimento, setDatavencimento] = useState(new Date())
  const [descricao, setDescricao] = useState('')
  const [valorTotal, setValorTotal] = useState(0.0)
  const [showParcelamento, setShowParcelamento] = useState(false)
  const [numeroRepeticoes, setNumeroRepeticoes] = useState(0)
  const [selectedColaborador, setSelectedColaborador] = useState(null)
  const [colaboradoresOptions, setColaboradoresOptions] = useState([])
  const [selectedGrupo, setSelectedGrupo] = useState()
  const [gruposOptions, setGruposOptions] = useState([])
  const [selectedSubgrupo, setSelectedSubgrupo] = useState()
  const [subgrupoOptions, setSubgrupoOptions] = useState([])
  const [isVisibleSubgrupo, setIsVisibleSubgrupo] = useState(false)
  const [grupoContasPagar, setGrupoContasPagar] = useState([])
  const [isInsertedSubgrupo, setIsInsertedSubgrupo] = useState(false)
  const [novoSubGrupo, setNovoSubGrupo] = useState('')
  const [isVisibleColaborador, setIsVisibleColaborador] = useState(false)

  useEffect(() => {
    didMount()
    
  }, [])

  const didMount = async () => {
    setIsInnerLoading(true)

    await getGruposContasPagar()

    await getColaboradores()

    if(isEdit)
      preencherCampos()

    setIsInnerLoading(false)
  }

  const preencherCampos = () => {
    setDataLancamento(conta.dataLancamento)
    setDatavencimento(conta.dataVencimento)
    setDescricao(conta.descricao)
    setValorTotal(conta.valor)
    if(conta.colaboradorId){
      setSelectedColaborador(colaboradoresOptions.find(x => x.value == conta.colaboradorId))
    }
    if(conta.grupoContasPagarId){
      setSelectedGrupo({value: conta.grupoContasPagar.Id, label: conta.grupoContasPagar.nome})
      setIsVisibleSubgrupo(true)
    }
    if(conta.subGrupoContasPagarId)
      setSelectedSubgrupo({value: conta.subGrupoContasPagar.id, label: conta.subGrupoContasPagar.nome})
  }

  const getColaboradores = async () => {
    try {
      let { data } = await colaboradoresService.getAllColaboradores()
      putColaboradoresOptions(data.filter(x => x.ativo).sort((a, b) => a.nome.localeCompare(b.nome)))
      
    } catch (error) {
      console.error(error)
    }
  }

  const putColaboradoresOptions = (data) => {

    setColaboradoresOptions(
      data.map((colaborador) => ({
        label: colaborador.nome,
        value: colaborador.id,
      })))

  }

  const getGruposContasPagar = async () => {
    let { data } = await financeiroService.getGruposContasPagar()

    let optionsGrupo = data.map((grupo) => ({
      label: grupo.nome,
      value: grupo.id,
    }))

    setGruposOptions(optionsGrupo)
    
    setGrupoContasPagar(...[data])

    if(selectedGrupo)
      getSubgruposContasPagar(selectedGrupo.value, data)
  }

  const getSubgruposContasPagar = (grupoId, grupos) => {
    let grupo
    if(!grupos)
      grupo = grupoContasPagar.find(x => x.id == grupoId)
    else
      grupo = grupos.find(x => x.id == grupoId)

    let optionsSubgrupo = grupo.subGruposContasPagar.filter(g => g.ativo)
                                                    .map((subgrupo) => ({
      label: subgrupo.nome,
      value: subgrupo.id
    }))
    setSubgrupoOptions(optionsSubgrupo)

    if(novoSubGrupo)
      setSelectedSubgrupo(optionsSubgrupo.find(x => x.label == novoSubGrupo))
  
    if(optionsSubgrupo)
      setIsVisibleSubgrupo(true)
  }

  const salvarConta = async () => {
    setIsInnerLoading(true)
    const todosOsCampos = valorTotal && dataLancamento && dataVencimento && descricao
    try {
      if (todosOsCampos && (!showParcelamento || (showParcelamento && numeroRepeticoes && numeroRepeticoes > 0))) {
        await financeiroService.salvarConta(
          {
            valor: valorTotal,
            dataLancamento,
            dataVencimento,
            descricao,
            grupoContasPagarId: selectedGrupo ? selectedGrupo.value : null,
            subGrupoContasPagarId: selectedSubgrupo ? selectedSubgrupo.value : null,
            colaboradorId: selectedColaborador ? selectedColaborador.value : null,
          },
          numeroRepeticoes
        )
        toast('Nova conta adicionada com sucesso!', { type: toast.TYPE.SUCCESS })
        handleUpdate()
        handleClose()
      } else if (todosOsCampos && showParcelamento && (!numeroRepeticoes || numeroRepeticoes <= 0)) {
        toast('O número de repetições deve ser maior do que zero!', { type: toast.TYPE.WARNING })
      } else {
        toast('Preencha todos os campos!', { type: toast.TYPE.WARNING })
      }
    } catch (error) {
      console.error(`SALVAR CONTA::`, error)
    }
    setIsInnerLoading(false)
  }

  const editarConta = async () => {
    setIsInnerLoading(true)
    const todosOsCampos = valorTotal && dataLancamento && dataVencimento && descricao
    try {
      if (todosOsCampos && (!showParcelamento || (showParcelamento && numeroRepeticoes && numeroRepeticoes > 0))) {
        await financeiroService.editarConta(
          {
            id: conta.id,
            valor: valorTotal,
            dataLancamento,
            dataVencimento,
            descricao,
            grupoContasPagarId: selectedGrupo ? selectedGrupo.value : null,
            subGrupoContasPagarId: selectedSubgrupo ? selectedSubgrupo.value : null,
            colaboradorId: selectedColaborador ? selectedColaborador.value : null,
            status: conta.status
          },
          numeroRepeticoes
        )
        toast('Edição realizada com sucesso!', { type: toast.TYPE.SUCCESS })
        handleClose()
      } else if (todosOsCampos && showParcelamento && (!numeroRepeticoes || numeroRepeticoes <= 0)) {
        toast('O número de repetições deve ser maior do que zero!', { type: toast.TYPE.WARNING })
      } else {
        toast('Preencha todos os campos!', { type: toast.TYPE.WARNING })
      }
    } catch (error) {
      console.error(`SALVAR CONTA::`, error)
    }
    setIsInnerLoading(false)
  }

  const handleDescricao = ({ target }) => {
    setDescricao(target.value)
  }

  const changeNovoSubgrupo = ({ target }) => {
    setNovoSubGrupo(target.value)
  }

  const handleNumeroRepeticoes = ({ target }) => {
    setNumeroRepeticoes(target.value)
  }

  const toggleParcelas = ({ target }) => {
    setShowParcelamento(target.checked)
  }

  const handleGrupo = async (e) => {    

    setSelectedGrupo(e)

    if (!selectedGrupo || (selectedGrupo && selectedGrupo.value != e.value)) {

      var label = e.label.toLowerCase()
      if (label === 'salários' || label === 'repasse profissionais') {
        if (e.label.toLowerCase() === 'salários') {          
          await getAllExceptDentista()          
        }
        else if (e.label.toLowerCase() === 'repasse profissionais') {          
          await getDentistas()                    
        }
        
        setSelectedSubgrupo(null)
        setIsVisibleSubgrupo(false)
        setSelectedColaborador(null)
        setIsVisibleColaborador(true)
      }
      else {
        getColaboradores()
        setIsVisibleColaborador(false)
        setSelectedColaborador(null)
        getSubgruposContasPagar(e.value)
        setIsVisibleSubgrupo(true)
      }
    }
  }

  const getDentistas = async () => {
    try {
      const dentistas = await colaboradoresService.getDentistas()
      putColaboradoresOptions(dentistas.filter(x => x.ativo).sort((a, b) => a.nome.localeCompare(b.nome)))
      
    } catch (error) {
      console.error(error)
    }
  }

  const getAllExceptDentista = async () => {
    try {
      let { data } = await colaboradoresService.getAllExceptDentista()
      putColaboradoresOptions(data.sort((a, b) => a.nome.localeCompare(b.nome)))
      
    } catch (error) {
      console.error(error)
    }
  }

  const handleNovoSubgrupo = async (e) => {
    setIsInsertedSubgrupo(true)
  }

  const handleCancelarInsercao = async (e) => {
    setIsInsertedSubgrupo(false)
  }

  const handleSaveAdd = async (event) => {

    try {
        await Swal.fire({
          title: 'Inserir Subgrupo',
          text: `Deseja inserir o subgrupo "${novoSubGrupo}" no grupo "${selectedGrupo.label}"`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar'
        }).then(async (result) => {
          if (!result.value) {
            return
          }
          else
          {
            if (!novoSubGrupo) {
              toast(`O campo 'Nome do Subgrupo' é obrigatório`, { type: toast.TYPE.ERROR })
              return
            }
      
            await financeiroService.salvarSubGrupoContasPagar(selectedGrupo.value, novoSubGrupo)
            toast('Subgrupo cadastrado com sucesso!', { type: toast.TYPE.SUCCESS })
            
            getGruposContasPagar()
            
            setSelectedSubgrupo(subgrupoOptions.find(x => x.nome == novoSubGrupo))
            setIsInsertedSubgrupo(false)
            setNovoSubGrupo('')
            handleUpdateFilterGrupo()
          }
        }
      )
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal className='modal-agenda' show={isOpen} onHide={handleClose}>
      <Card>
        <Modal.Header className='p-4' closeButton>
          <Modal.Title>Adicionar Conta a Pagar</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>
          <Row>
            <Form.Group as={Col} className='required'>
              <Form.Label htmlFor='dataNascimento'>Data de Lançamento:</Form.Label>
              <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                <KeyboardDatePicker
                  invalidDateMessage='Formato de data inválido'
                  id='dataNascimento'
                  name='dataNascimento'
                  className='form-control'
                  value={dataLancamento}
                  onChange={setDataLancamento}
                  variant='inline'
                  format='DD/MM/YYYY'
                  required
                  animateYearScrolling
                />
              </MuiPickersUtilsProvider>
            </Form.Group>
            <Form.Group as={Col} className='required'>
              <Form.Label htmlFor='dataNascimento'>Data de Vencimento:</Form.Label>
              <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                <KeyboardDatePicker
                  invalidDateMessage='Formato de data inválido'
                  id='dataNascimento'
                  name='dataNascimento'
                  className='form-control'
                  value={dataVencimento}
                  onChange={setDatavencimento}
                  variant='inline'
                  format='DD/MM/YYYY'
                  required
                  animateYearScrolling
                  disablePast
                />
              </MuiPickersUtilsProvider>
            </Form.Group>
            <Form.Group as={Col} className='required'>
              <Form.Label>Valor:</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>R$</InputGroup.Text>
                </InputGroup.Prepend>
                <CurrencyInput
                  required
                  className='form-control'
                  decimalSeparator=','
                  thousandSeparator='.'
                  value={valorTotal}
                  onChange={(valor) => setValorTotal(handleDecimalConvert(valor))}
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
          <Form.Group as={Col} md='6' className=''>
              <Form.Label>Grupo:</Form.Label>
              <Select
                placeholder='Grupo...'
                value={selectedGrupo}
                options={gruposOptions}
                onChange={handleGrupo}
                noOptionsMessage={() => 'Nenhuma opção disponível'}
              />
            </Form.Group>
            {isVisibleSubgrupo && !isInsertedSubgrupo && 
              <Form.Group as={Col} md='6' className=''>
                <Form.Label className="label-chindren-link">Subgrupo:<Button variant='link' onClick={handleNovoSubgrupo}><FontAwesomeIcon icon={faPlus} /> novo</Button></Form.Label>
                <Select
                  placeholder='Subgrupo..'
                  value={selectedSubgrupo}
                  options={subgrupoOptions}
                  onChange={setSelectedSubgrupo}
                  noOptionsMessage={() => 'Nenhuma opção disponível'}
                />
              </Form.Group>
            } 
            {isVisibleSubgrupo && isInsertedSubgrupo &&
              <Form.Group as={Col} md='6' className=''>
                <Form.Label className="label-chindren-link">Novo Subgrupo: <Button variant="link" onClick={handleCancelarInsercao}><FontAwesomeIcon icon={faTimes} /> cancelar</Button></Form.Label>
                <InputGroup>
                  <FormControl
                    placeholder="subgrupo..."
                    aria-label="subgrupo..."
                    aria-describedby="basic-addon2"
                    value={novoSubGrupo}
                    onChange={changeNovoSubgrupo}
                  />
                  <InputGroup.Append>
                    <Button variant="outline-primary" onClick={handleSaveAdd}><FontAwesomeIcon icon={faSave} /></Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            }

            {isVisibleColaborador && (
              <Form.Group as={Col} className=''>
                <Form.Label>Colaborador a pagar:</Form.Label>
                <Select
                  placeholder='Colaborador...'
                  value={selectedColaborador}
                  options={colaboradoresOptions}
                  onChange={setSelectedColaborador}
                  noOptionsMessage={() => 'Nenhuma opção disponível'}
                />
              </Form.Group>
            )}

          </Row>
          <Form.Group className='required'>
            <Form.Label>Descrição</Form.Label>
            <Form.Control required value={descricao} onChange={handleDescricao} as='textarea' rows={4}></Form.Control>
          </Form.Group>
          <div className='align-items-center d-flex'>
            <div className='d-flex align-items-center'>
              <label className='checkbox my-3'>
                <input type='checkbox' checked={showParcelamento} onChange={toggleParcelas} />
                <span className='d-flex align-items-center'>Repetir esta conta{showParcelamento && ' por:'}</span>
              </label>
            </div>
            {showParcelamento && (
              <div className='d-flex align-items-center'>
                <Form.Control
                  type='number'
                  min={1}
                  className='form-control mx-3 w-25'
                  value={numeroRepeticoes}
                  onChange={handleNumeroRepeticoes}
                />
                <Form.Label>{numeroRepeticoes > 1 ? 'meses' : 'mês'}</Form.Label>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex flex-fill justify-content-end'>
          <Button onClick={isEdit ? editarConta : salvarConta} variant='primary'>
            {isInnerLoading ? <Spinner animation='border' size='sm' variant='light' /> : (isEdit ? 'Editar Conta' : 'Salvar Nova Conta' )}
          </Button>
        </Modal.Footer>
      </Card>
    </Modal>
  )
}

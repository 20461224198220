import React, { Fragment } from 'react'
import moment from 'moment'
import { Table } from 'react-bootstrap'

import { formatMoney } from '../../../../utils/string.utils'

export default function ParcelasList({ selectedParcelas, parcelas, text, panelIndex, setSelectedParcelas }) {
  const toggleParcela = (parcela, index) => {
    let newSelectedParcelas = [...selectedParcelas]
    const id =
      parcela.planoTratamentoOrtodontiaPagamentoParcelaId ||
      parcela.planoTratamentoPagamentoAparelhoParcelaId ||
      parcela.planoTratamentoPagamentoAparelhoComplementarParcelaId ||
      parcela.planoTratamentoPagamentoSuprimentoParcelaId

    if (
      selectedParcelas.some(
        (parc) =>
          id === parc.planoTratamentoOrtodontiaPagamentoParcelaId ||
          id === parc.planoTratamentoPagamentoAparelhoParcelaId ||
          id === parc.planoTratamentoPagamentoAparelhoComplementarParcelaId ||
          id === parc.planoTratamentoPagamentoSuprimentoParcelaId
      )
    ) {
      const parcelasToRemove = [id]
      parcelas.forEach((parc, i) => {
        if (i > index) {
          const parcId =
            parc.planoTratamentoOrtodontiaPagamentoParcelaId ||
            parc.planoTratamentoPagamentoAparelhoParcelaId ||
            parc.planoTratamentoPagamentoAparelhoComplementarParcelaId ||
            parc.planoTratamentoPagamentoSuprimentoParcelaId
          parcelasToRemove.push(parcId)
        }
      })

      const novasParcelas = newSelectedParcelas.filter((parc) => {
        const parcId =
          parc.planoTratamentoOrtodontiaPagamentoParcelaId ||
          parc.planoTratamentoPagamentoAparelhoParcelaId ||
          parc.planoTratamentoPagamentoAparelhoComplementarParcelaId ||
          parc.planoTratamentoPagamentoSuprimentoParcelaId
        return !parcelasToRemove.includes(parcId)
      })

      setSelectedParcelas(novasParcelas)
    } else {
      newSelectedParcelas.push(parcela)
      parcelas.forEach((parc, i) => {
        if (i < index) {
          if (!newSelectedParcelas.includes(parc)) {
            newSelectedParcelas.push(parc)
          }
        }
      })
      setSelectedParcelas(newSelectedParcelas)
    }
  }

  return (
    <Fragment>
      {parcelas.length > 0 ? (
        <Table bordered hover>
          <thead>
            <tr>
              <th className='text-center'>Vencimento</th>
              <th className='text-center'>Valor</th>
              {text === 'Plano Ortodôntico' && (
                <Fragment>
                  <th className='text-center'>Desconto</th>
                  <th className='text-center'>Juros</th>
                  <th className='text-center'>Multa</th>
                  <th className='text-center'>Valor Calculado</th>
                </Fragment>
              )}
              <th width='100px' className='text-center'>
                Selecionar
              </th>
            </tr>
          </thead>
          <tbody>
            {parcelas.map((parcela, index) => {
              const {
                valor,
                valorCalculado,
                juros,
                multa,
                desconto,
                dataVencimento,
                planoTratamentoOrtodontiaPagamentoParcelaId: planoId,
                planoTratamentoPagamentoAparelhoParcelaId: aparelhoId,
                planoTratamentoPagamentoAparelhoComplementarParcelaId: complementarId,
                planoTratamentoPagamentoSuprimentoParcelaId: suprimentoId,
              } = parcela
              const isLate = valorCalculado > valor
              const id = planoId || aparelhoId || complementarId || suprimentoId
              const isChecked = selectedParcelas.some(
                (parc) =>
                  parc.planoTratamentoOrtodontiaPagamentoParcelaId === id ||
                  parc.planoTratamentoPagamentoAparelhoParcelaId === id ||
                  parc.planoTratamentoPagamentoAparelhoComplementarParcelaId === id ||
                  parc.planoTratamentoPagamentoSuprimentoParcelaId === id
              )

              return (
                <tr key={index}>
                  <td className='text-center'>{moment(dataVencimento).format('DD/MM/YYYY')}</td>
                  <td className='text-center'>{formatMoney(valor)}</td>
                  {text === 'Plano Ortodôntico' && (
                    <Fragment>
                      <td className='text-center'>{desconto > 0 ? formatMoney(desconto) : '-'}</td>
                      <td className='text-center'>{juros > 0 ? formatMoney(juros) : '-'}</td>
                      <td className='text-center'>{multa > 0 ? formatMoney(multa) : '-'}</td>
                      <td className={`text-center font-weight-bold ${isLate ? 'text-danger' : 'text-success'}`}>
                        {formatMoney(valorCalculado)}
                      </td>
                    </Fragment>
                  )}
                  <td className='text-center'>
                    <label className='checkbox labeless m-0'>
                      <input type='checkbox' checked={isChecked} onChange={() => toggleParcela(parcela, index)} />
                      <span />
                    </label>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <div>Nenhuma parcela em aberto.</div>
      )}
    </Fragment>
  )
}

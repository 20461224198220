import AxiosClient from './axios-client'

class TemplatesService {
  getAllTemplates() {
    return AxiosClient.get('Template/getAll')
  }

  getTemplateByTipo(tipo) {
    return AxiosClient.get(`Template/getByTipo/${tipo}`)
  }

  getTemplateById(templateId) {
    return AxiosClient.get(`Template/getById/${templateId}`)
  }

  salvarTemplate(template) {
    return AxiosClient.post('Template/salvar', template)
  }
}

export default new TemplatesService()

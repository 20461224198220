import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Container, Button, Spinner } from 'react-bootstrap'

import { printParcelasReceipt } from '../../../Templates/PrintableReceipt'
import { TIPOS_TEMPLATE } from '../../../../constants/template.constants'
import ortodontiaService from '../../../../services/ortodontia.service'

const Impressao = ({ selectedPlano, pagamento, handleGoBack, type }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleImprimirTermoRenegociacao = async () => {
    setIsLoading(true)
    await ortodontiaService.downloadTemplateAsPDF(selectedPlano.id, TIPOS_TEMPLATE.TermoDeRenegociacao)
    setIsLoading(false)
  }

  const handleImprimirCarne = async () => {
    const { valorTotal, parcelas } = pagamento
    const { dependente, paciente, numeroPlano } = selectedPlano
    await printParcelasReceipt({
      numeroPlano,
      nomeContratante: paciente.nome,
      nomePaciente: dependente ? dependente.nome : paciente.nome,
      parcelas,
      valorTotal,
      type,
    })
  }

  return (
    <Container className='text-center pb-5'>
      <div className='mb-5'>
        <FontAwesomeIcon style={{ fontSize: '11em' }} className='text-success mt-5 mb-3' icon={faCheckCircle} />
        <h2 style={fontStyle} className='text-success'>
          Renegociação realizada com sucesso!
        </h2>
      </div>
      <div>
        <Button className='mr-3' variant='primary' onClick={handleImprimirTermoRenegociacao}>
          {!isLoading ? 'Imprimir Termo de Renegociação' : <Spinner animation='border' size='sm' variant='light' />}
        </Button>
        <Button className='mr-3' variant='primary' onClick={handleImprimirCarne}>
          Imprimir Parcelamento
        </Button>
        <Button variant='outline-primary' onClick={handleGoBack}>
          Voltar
        </Button>
      </div>
    </Container>
  )
}

export default Impressao

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const fontStyle = {
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { editarColaborador, toggleColaborador, resetColaborador } from '../../services/actions/colaboradores.actions'

import ColaboradoresList from '../../components/Colaboradores/ColaboradoresList'

const mapStateToProps = (store) => ({})

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ editarColaborador, toggleColaborador, resetColaborador }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ColaboradoresList)

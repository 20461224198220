import React from 'react'
import { Table } from 'react-bootstrap'

import ortodontiaService from '../../../services/ortodontia.service'
import { status } from '../../../constants/common.constants'

export default function SelecaoPlanos(props) {
  const { setIsLoading, nextStep, planosTratamento, isLoading, setSelectedPlano } = props

  const handleSelectPlano = async (planoId) => {
    setIsLoading(true)
    try {
      const { data } = await ortodontiaService.getPlanosTratamentoById(planoId)
      setSelectedPlano(data)
      nextStep()
    } catch (error) {
      console.error(`handle Select Plano::`, error)
    }
    setIsLoading(false)
  }

  return (
    <div>
      <h3 className='m-0 mb-4 text-secondary font-weight-bold'>Planos de Tratamento Ortodônticos</h3>
      {planosTratamento.length > 0 ? (
        <Table bordered hover>
          <thead>
            <tr>
              <th>Plano de tratamento</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {planosTratamento.map((plano) => {
              const statusPlano = status.find((s) => s.value === plano.statusTratamento)
              return (
                <tr key={plano.id} className='cursor-pointer' onClick={() => handleSelectPlano(plano.id)}>
                  <td>{plano.numeroPlano}</td>
                  <td>{statusPlano.label}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        !isLoading && <div>Nenhum plano encontrado para este paciente</div>
      )}
    </div>
  )
}

export const LISTAR_AGENDAMENTOS = 'LISTAR_AGENDAMENTOS'
export const REQUEST_AGENDAMENTOS = 'REQUEST_AGENDAMENTOS'
export const RESET_REQUEST_AGENDAMENTOS = 'RESET_REQUEST_AGENDAMENTOS'
export const RESET_AGENDAMENTOS = 'RESET_AGENDAMENTOS'

export const EDITAR_AGENDAMENTO = 'EDITAR_AGENDAMENTO'
export const SALVAR_AGENDAMENTO = 'SALVAR_AGENDAMENTO'
export const SALVAR_STATUS_AGENDAMENTO = 'SALVAR_STATUS_AGENDAMENTO'

export const GET_AGENDAMENTO_ID = 'GET_AGENDAMENTO_ID'
export const GET_AGENDAMENTO_PACIENTE = 'GET_AGENDAMENTO_PACIENTE'
export const GET_AGENDAMENTO_COLABORADOR = 'GET_AGENDAMENTO_COLABORADOR'
export const GET_AGENDAMENTO_ESPECIALIDADE = 'GET_AGENDAMENTO_ESPECIALIDADE'
export const GET_AGENDAMENTO_DATA = 'GET_AGENDAMENTO_DATA'
export const GET_AGENDAMENTO_HOJE = 'GET_AGENDAMENTO_HOJE'
export const SET_NOT_LOADING = 'SET_NOT_LOADING'

export const resetRequestAgendamentos = () => ({
  type: RESET_REQUEST_AGENDAMENTOS,
})

export const resetAgendamento = () => ({
  type: RESET_AGENDAMENTOS,
})

export const salvarAgendamento = (agendamento) => ({
  type: SALVAR_AGENDAMENTO,
  agendamento,
})

export const salvarStatusAgendamento = (agendamentoId, status) => ({
  type: SALVAR_STATUS_AGENDAMENTO,
  agendamentoId,
  status,
})

export const getById = (agendamentoId) => ({
  type: GET_AGENDAMENTO_ID,
  agendamentoId,
})

export const getByPaciente = (pacienteId, nome, cpf) => ({
  type: GET_AGENDAMENTO_PACIENTE,
  pacienteId,
  nome,
  cpf,
})

export const getByColaborador = (colaboradorId) => ({
  type: GET_AGENDAMENTO_COLABORADOR,
  colaboradorId,
})

export const getByEspecialidade = (especialidadeId) => ({
  type: GET_AGENDAMENTO_ESPECIALIDADE,
  especialidadeId,
})

export const getDoDia = () => ({
  type: GET_AGENDAMENTO_HOJE,
})

const initialState = {
  agendamentos: [],
  selectedAgendamento: {},
  isLoading: false,
  requestAgendamentos: false,
}

export const agendamentosReducer = (state = initialState, action) => {
  switch (action.type) {
    case LISTAR_AGENDAMENTOS:
      return {
        ...state,
        agendamentos: action.agendamentos,
        isLoading: false
      }
    case EDITAR_AGENDAMENTO:
      return {
        ...state,
        selectedAgendamento: action.selectedAgendamento,
      }
    case RESET_AGENDAMENTOS:
      return {
        ...state,
        selectedAgendamento: {},
      }
    case REQUEST_AGENDAMENTOS:
      return { ...state, isLoading: true, requestAgendamentos: true }
    case RESET_REQUEST_AGENDAMENTOS:
      return { ...state, isLoading: false, requestAgendamentos: false }
    case GET_AGENDAMENTO_ID:
    case GET_AGENDAMENTO_PACIENTE:
    case GET_AGENDAMENTO_COLABORADOR:
    case GET_AGENDAMENTO_ESPECIALIDADE:
    case GET_AGENDAMENTO_DATA:
    case GET_AGENDAMENTO_HOJE:
      return { ...state, isLoading: true }
    case SET_NOT_LOADING:
      return { ...state, isLoading: false }
    default:
      return { ...state }
  }
}

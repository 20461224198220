import React, { Fragment } from 'react'
import { Row, Col, Form, Button, InputGroup, Modal, Card } from 'react-bootstrap'
import MaskedInput from 'react-text-mask'
import CurrencyInput from 'react-currency-input'
import MomentUtils from '@date-io/moment'
import formatCpf from '@brazilian-utils/format-cpf'
import { isValidCpf } from '@brazilian-utils/is-valid-cpf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faTrashAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import 'moment/locale/pt-br'

import { handleDecimalConvert } from '../../utils/string.utils'
import { tiposTelefone } from '../../constants/telefones.constants'
import { Emails, Telefones, Enderecos, Dependentes } from '../Common'
import PermissoesConstants from '../../constants/permissoes.constants'
import MenuContext from '../../contexts/MenuContexts'
import { toast } from 'react-toastify'

const EMPTY_TELEFONE = { numero: '', nomeContato: '' }
const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]

class PacienteForm extends React.Component {
  state = this.initialState

  get initialState() {
    let telefonesPacienteAgendamento = this.props.paciente.telefones
    if (telefonesPacienteAgendamento) {
      let telefones = []
      telefonesPacienteAgendamento.forEach((telefone) => {
        telefones.push({ principal: false, numero: telefone.numero })
      })
      while (telefones.length < 4) {
        telefones.push({ principal: false, numero: '' })
      }
      telefones.push({ principal: false, tipoTelefone: tiposTelefone.TRABALHO, numero: '' })
      telefonesPacienteAgendamento = telefones
    }
    return {
      observacao: this.props.paciente.observacao || '',
      cpf: this.props.paciente.cpf || '',
      rg: '',
      nome: this.props.paciente.nome || '',
      nomeSocial: this.props.paciente.nomeSocial || '',
      dataNascimento: null,
      nomePai: '',
      nomeMae: '',
      telefones: telefonesPacienteAgendamento || [
        { principal: true, numero: '' },
        { principal: false, numero: '' },
        { principal: false, numero: '' },
        { principal: false, numero: '' },
        { principal: false, tipoTelefone: tiposTelefone.TRABALHO, numero: '' },
      ],
      enderecos: [{}],
      emails: [],
      dependentes: [],
      historicoTelefones: [],
      localTrabalho: '',
      renda: '0',
      outrasRendas: '0',
      nomeConjuge: '',
      invalidCPF: false,
      agendamentoId: this.props.paciente.agendamentoId,
      modalHistoricoTelefone: false
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { paciente } = props
    if (paciente && paciente.id && !state.id) {
      const toUpdate = { ...paciente }
      toUpdate.cpf = formatCpf(toUpdate.cpf)
      toUpdate.telefones = [...paciente.telefones]
      toUpdate.historicoTelefones = [ ...paciente.historicoTelefones ]
      if(toUpdate.telefones.length == 0)
        toUpdate.telefones = [{ principal: true, numero: '' },
        { principal: false, numero: '' },
        { principal: false, numero: '' },
        { principal: false, numero: '' },
        { principal: false, tipoTelefone: tiposTelefone.TRABALHO, numero: '' }]
      return toUpdate
    }
    return null
  }

  onChange = (e) => {
    const { name, value } = e.target
    let novosTelefones = [...this.state.telefones]
    if (name === `nome`) {
      const telefonePrincipal = novosTelefones.find((t) => t.principal)
      telefonePrincipal.nomeContato = value
    }
    this.setState({ [name]: value, telefones: novosTelefones })
  }

  onChangeRenda = (e) => {
    this.setState({
      renda: e,
    })
  }

  onChangeOutrasRendas = (e) => {
    this.setState({
      outrasRendas: e,
    })
  }

  onBlurCPF = (e) => {
    this.onChange(e)
    this.setState({ invalidCPF: !isValidCpf(e.target.value) && e.target.value.length > 0 })
  }

  onChangeDate = (dataNascimento) => this.setState({ dataNascimento })

  validTelefones = () => !this.state.telefones.some((tel) => tel.numero.length < 13)

  onSubmit = async (e) => {
    e.preventDefault()

    if (this.state.invalidCPF) {
      toast('CPF inválido!', { type: toast.TYPE.ERROR })
    } else if (!this.validTelefones()) {
      toast('Telefone inválido!', { type: toast.TYPE.ERROR })
    } else {
      let data = {
        ...this.state,
        renda: handleDecimalConvert(this.state.renda),
        outrasRendas: handleDecimalConvert(this.state.outrasRendas),
        ativo: true,
        id: this.props.paciente.id ? this.props.paciente.id : undefined,
        dependentes: this.state.dependentes.filter((d) => Object.keys(d).length > 0),
        dataNascimento: moment.isMoment(this.state.dataNascimento)
          ? this.state.dataNascimento.toDate()
          : this.state.dataNascimento,
      }
      data.emails.forEach((email, index) => {
        if (Object.keys(email).length === 0 && email.constructor === Object) {
          data.emails.splice(index, 1)
        }
      })
      await this.props.salvar(data)
    }
  }

  addEmail = () => {
    const emails = [...this.state.emails]
    emails.push({})
    this.setState({ emails })
  }

  setEmail = (index, email) => {
    const emails = [...this.state.emails]
    emails[index] = email
    this.setState({ emails })
  }

  removeEmail = (index) => {
    const emails = [...this.state.emails]
    emails.splice(index, 1)
    this.setState({ emails })
  }

  renderEmail(index, email, disableAll = false) {
    return (
      <Row key={index}>
        <Emails disableAll={disableAll} index={index} email={email} onChange={this.setEmail} />
        <Col className='pt-3'>
          <Button onClick={() => this.removeEmail(index)} className='fix-btn-margin mx-2' variant='danger'>
            <FontAwesomeIcon icon={faMinus} />
          </Button>
        </Col>
      </Row>
    )
  }

  addTelefone = () => {
    let telefones = this.state.telefones ? [...this.state.telefones] : []
    const telefoneTrabalho = telefones.find((tel) => tel.tipoTelefone === tiposTelefone.TRABALHO)
    telefones = telefones.filter((tel) => tel.tipoTelefone !== tiposTelefone.TRABALHO)
    telefones.push({})
    telefoneTrabalho && telefones.push(telefoneTrabalho)
    this.setState({ telefones })
  }

  removeTelefone = (index) => {
    const telefones = [...this.state.telefones]
    telefones.splice(index, 1)
    this.setState({ telefones })
  }

  setTelefone = (index, telefone) => {
    let telefones = [...this.state.telefones]
    if (telefone.principal) {
      telefones = this.setTelefonePrincipal(index)
    }
    telefones[index] = telefone

    this.setState({ telefones })
  }

  setTelefonePrincipal = (index) =>
    [...this.state.telefones].map((t, i) => {
      t.principal = index === i
      if (t.principal) {
        t.nomeContato = this.state.nome
      } else {
        t.nomeContato = t.nomeContato === this.state.nome ? '' : t.nomeContato
      }
      return t
    })

  renderTelefone(index, telefone, disableAll = false) {
    let telefonesPaciente = this.state.telefones.filter((t) => t.tipoTelefone !== tiposTelefone.TRABALHO)
    let historicoTelefones = this.state.historicoTelefones
    
    if(telefone.tipoTelefone == tiposTelefone.TRABALHO)
      return;

    return (
      <Row key={index}>
        <Col md='10'>
          <Telefones
            index={index}
            telefone={telefone}
            tipoTelefone={tiposTelefone.PESSOAL}
            setTelefonePrincipal={this.setTelefonePrincipal}
            onChange={this.setTelefone}
            checkWhatsapp
            choosePrincipal
            showContactName
            disabled={disableAll}
            required
            historicoTelefones={historicoTelefones}
            handleOpenModalHistoricoTelefone={this.handleOpenModalHistoricoTelefone}
          />
        </Col>
        <Col md='2' className='pt-3'>
          {this.props.id && index > 2 && telefone.numero && !disableAll && (
            <Button onClick={() => this.removeTelefone(index)} className='btn-delete-field' variant='danger'>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )}
          {index === telefonesPaciente.length - 1 && !disableAll && (
            <>
              {index > 2 && !telefone.ddd && !telefone.numero && !disableAll && (
                <Button onClick={() => this.removeTelefone(index)} className='fix-btn-margin mr-2' variant='primary'>
                  <FontAwesomeIcon icon={faMinus} />
                </Button>
              )}
              <Button onClick={this.addTelefone} className='fix-btn-margin' variant='primary'>
                <FontAwesomeIcon icon={faPlus} />
              </Button>

              
            </>
          )}
        </Col>
      </Row>
    )
  }

  handleCloseModalHistoricoTelefone = () => {
    this.setState({ modalHistoricoTelefone: false })
  }

  handleOpenModalHistoricoTelefone = () => {
    this.setState({ modalHistoricoTelefone: true })
  }

  onFileChange = (index, file) => {}

  addEndereco = () => {
    let enderecos = this.state.enderecos || []
    enderecos.push({})
    this.setState({
      enderecos,
    })
  }

  setEndereco = (index, endereco) => {
    const enderecos = [...this.state.enderecos]
    enderecos[index] = endereco
    this.setState({ enderecos })
  }

  removeEndereco = (index) => {
    let enderecos = [...this.state.enderecos]
    enderecos.splice(index, 1)
    this.setState({
      enderecos,
    })
  }

  renderEndereco(index, endereco, disableAll = false) {
    return (
      <div key={index}>
        <Enderecos
          needHomeProof
          endereco={endereco}
          index={index}
          onFileChange={this.onFileChange}
          onChange={this.setEndereco}
          disableAll={disableAll}
        />
        <Row>
          <Col>
            {index > 1 && this.props.id && endereco.cep && !disableAll && (
              <Button onClick={() => this.removeEndereco(index)} className='mr-3' variant='danger'>
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            )}
          </Col>
        </Row>
      </div>
    )
  }

  addDependentes = () => {
    this.setState({
      ...this.state,
      dependentes: [
        ...this.state.dependentes,
        {
          nome: '',
          dataNascimento: new Date().toISOString(),
          cpf: '',
          rg: '',
        },
      ],
    })
  }

  setDependente = (index, dependente) => {
    const dependentes = [...this.state.dependentes]
    dependentes[index] = dependente
    this.setState({ dependentes })
  }

  removeDependente = (index) => {
    let dependentes = [...this.state.dependentes]
    dependentes.splice(index, 1)
    this.setState({ dependentes })
  }

  renderDependente(index, dependente, disableAll) {
    return (
      <div key={index} className='pt-2'>
        <Dependentes
          key={index}
          index={index}
          disableAll={disableAll}
          dependente={dependente}
          removeDependente={this.removeDependente}
          onChange={this.setDependente}
        />
      </div>
    )
  }

  render() {
    let telefoneTrabalho = this.state.telefones.find((t) => t.tipoTelefone === tiposTelefone.TRABALHO)
    if (!telefoneTrabalho) {
      telefoneTrabalho = EMPTY_TELEFONE
    }
    let acoesPermitidas = {}
    return (
      <MenuContext.Consumer>
        {(acoes) => {
          acoes.map((a) => {
            acoesPermitidas[`${a.nome}`] = a
            return true
          })
          const allDisabled = !acoesPermitidas[PermissoesConstants.EDITAR]
          return (
            <Form className='py-3' onSubmit={this.onSubmit}>
              <Row>
                <Col md='3'>
                  <Form.Group className='required'>
                    <Form.Label htmlFor='nome'>Nome</Form.Label>
                    <Form.Control
                      type='text'
                      name='nome'
                      value={this.state.nome}
                      onChange={this.onChange}
                      required
                      maxLength={100}
                      disabled={allDisabled}
                    />
                  </Form.Group>
                </Col>
                <Col md='3'>
                  <Form.Group className=''>
                    <Form.Label htmlFor='nome'>Nome Social</Form.Label>
                    <Form.Control
                      type='text'
                      name='nomeSocial'
                      value={this.state.nomeSocial}
                      onChange={this.onChange}
                      maxLength={100}
                      disabled={allDisabled}
                    />
                  </Form.Group>
                </Col>
                <Col md='2'>
                  <Form.Group className='required'>
                    <Form.Label htmlFor='cpf'>CPF</Form.Label>
                    <MaskedInput
                      mask={cpfMask}
                      className={this.state.invalidCPF ? 'form-control is-invalid' : 'form-control'}
                      name='cpf'
                      value={this.state.cpf}
                      onChange={this.onChange}
                      onBlur={this.onBlurCPF}
                      required
                      readOnly={this.props.id}
                      disabled={allDisabled}
                    />
                    {this.state.invalidCPF && <div className='invalid-feedback'>Digite um CPF válido.</div>}
                  </Form.Group>
                </Col>
                <Col md='2'>
                  <Form.Group className='required'>
                    <Form.Label htmlFor='rg'>RG</Form.Label>
                    <Form.Control
                      type='text'
                      name='rg'
                      value={this.state.rg}
                      onChange={this.onChange}
                      required
                      maxLength={10}
                      disabled={allDisabled}
                    />
                  </Form.Group>
                </Col>
                <Col className='pl-0' md='2'>
                  <Form.Group className='required'>
                    <Form.Label htmlFor='dataNascimento'>Data de nascimento</Form.Label>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                      <KeyboardDatePicker
                        invalidDateMessage='Formato de data inválido'
                        name='dataNascimento'
                        className={'form-control ' + (allDisabled ? 'disabled-datepicker' : '')}
                        value={this.state.dataNascimento}
                        onChange={(date) => this.onChangeDate(date)}
                        variant='inline'
                        format='DD/MM/YYYY'
                        required
                        animateYearScrolling
                        disableFuture
                        autoOk
                        disabled={allDisabled}
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Group>
                </Col>
              </Row>

              <Row className='mt-2'>
                <Col>
                  <h2 className='h5 border-bottom pb-2'>Endereço:</h2>
                </Col>
              </Row>
              {(this.state.enderecos || []).map((endereco, index) => this.renderEndereco(index, endereco, allDisabled))}

              <Row className='mt-2'>
                <Col>
                  <h2 className='h5 border-bottom pb-2'>Telefones:</h2>
                </Col>
              </Row>
              {(
                this.state.telefones|| [EMPTY_TELEFONE]
              ).map((telefone, index) => this.renderTelefone(index, telefone, allDisabled))}

              <Row className='mt-2 mb-3'>
                <Col>
                  <h2 className='h5 border-bottom pb-2'>Outras informações:</h2>
                </Col>
              </Row>
              <Row>
                <Col md='3'>
                  <Form.Group className='required'>
                    <Form.Label htmlFor='localTrabalho'>Local de trabalho</Form.Label>
                    <Form.Control
                      type='text'
                      name='localTrabalho'
                      value={this.state.localTrabalho}
                      onChange={this.onChange}
                      required
                      maxLength={100}
                      disabled={allDisabled}
                    />
                  </Form.Group>
                </Col>
                <Col md='3'>
                  <Telefones
                    tipoTelefone={1}
                    telefone={telefoneTrabalho || {}}
                    required
                    index={this.state.telefones.indexOf(telefoneTrabalho)}
                    onChange={this.setTelefone}
                    disabled={allDisabled}
                  />
                </Col>
                <Col md='3'>
                  <Form.Group>
                    <Form.Label htmlFor='renda'>Renda</Form.Label>
                    <InputGroup className='mb-3'>
                      <InputGroup.Prepend>
                        <InputGroup.Text>R$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <CurrencyInput
                        className='form-control'
                        name='renda'
                        decimalSeparator=','
                        thousandSeparator='.'
                        value={this.state.renda}
                        onChange={this.onChangeRenda}
                        disabled={allDisabled}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md='3'>
                  <Form.Group>
                    <Form.Label htmlFor='outrasRendas'>Outras rendas</Form.Label>
                    <InputGroup className='mb-3'>
                      <InputGroup.Prepend>
                        <InputGroup.Text>R$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <CurrencyInput
                        className='form-control'
                        name='outrasRendas'
                        decimalSeparator=','
                        thousandSeparator='.'
                        value={this.state.outrasRendas}
                        onChange={this.onChangeOutrasRendas}
                        disabled={allDisabled}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md='3'>
                  <Form.Group className='required'>
                    <Form.Label htmlFor='nomeMae'>Nome da mãe</Form.Label>
                    <Form.Control
                      type='text'
                      name='nomeMae'
                      value={this.state.nomeMae}
                      onChange={this.onChange}
                      maxLength={100}
                      disabled={allDisabled}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md='3'>
                  <Form.Group className='required'>
                    <Form.Label htmlFor='nomePai'>Nome do pai</Form.Label>
                    <Form.Control
                      type='text'
                      name='nomePai'
                      value={this.state.nomePai}
                      onChange={this.onChange}
                      maxLength={100}
                      disabled={allDisabled}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md='3'>
                  <Form.Group>
                    <Form.Label htmlFor='nomeConjuge'>Nome do cônjuge</Form.Label>
                    <Form.Control
                      type='text'
                      name='nomeConjuge'
                      value={this.state.nomeConjuge}
                      onChange={this.onChange}
                      maxLength={100}
                      disabled={allDisabled}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md='12'>
                  <Form.Group>
                    <Form.Label>Observação</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows='2'
                      name='observacao'
                      value={this.state.observacao}
                      onChange={this.onChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {this.state.emails.length > 0 && (
                <Fragment>
                  <Row className='mt-3'>
                    <Col>
                      <h2 className='h5 border-bottom pb-2'>E-mails:</h2>
                    </Col>
                  </Row>
                  {this.state.emails.map((email, index) => this.renderEmail(index, email, allDisabled))}
                </Fragment>
              )}

              {this.state.dependentes.length > 0 && (
                <Fragment>
                  <Row className='mt-3 mb-3'>
                    <Col>
                      <h2 className='h5 border-bottom pb-3'>Dependentes:</h2>
                    </Col>
                  </Row>
                  {this.state.dependentes.map((dependente, index) =>
                    this.renderDependente(index, dependente, allDisabled)
                  )}
                </Fragment>
              )}

              <div className='mt-5'>
                {!allDisabled && (
                  <Fragment>
                    <Button variant='outline-primary' className='mr-3' onClick={this.addEmail}>
                      Adicionar Email
                    </Button>
                    <Button variant='outline-primary' onClick={this.addDependentes}>
                      Adicionar Dependente
                    </Button>
                    <Button type='submit' variant='primary' className='float-right'>
                      {this.props.paciente && this.props.paciente.id ? 'Atualizar' : 'Cadastrar'}
                    </Button>
                  </Fragment>
                )}
                <Button
                  variant={allDisabled ? 'primary' : 'outline-primary'}
                  onClick={this.props.handleCancel}
                  className='float-right mr-3'
                >
                  {allDisabled ? 'Voltar' : 'Cancelar'}
                </Button>
              </div>


              <> </>
              {<Modal className='modal-agenda' size='sm' show={this.state.modalHistoricoTelefone} onHide={this.handleCloseModalHistoricoTelefone}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Paciente
                        <h1>{this.state.nome}</h1>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {this.state.historicoTelefones.map((t) => {
                        return (                          
                          <>
                            <Form.Group>
                              <Form.Label>Telefone Principal</Form.Label>
                              <Form.Control placeholder={t.numero} disabled />
                            </Form.Group>
                          </>
                        )
                      })}
                      </Modal.Body>
                </Modal>
              }

            </Form>
          )
        }}
      </MenuContext.Consumer>
    )
  }
}

export default PacienteForm

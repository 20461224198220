import React, { Fragment, useState, useEffect } from 'react'
import { Table, Form, Row, Col, InputGroup, Container, Button } from 'react-bootstrap'
import Select from 'react-select'
import CurrencyInput from 'react-currency-input'
import { registerLocale } from 'react-datepicker'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'react-datepicker/dist/react-datepicker.css'
import ptBr from 'date-fns/locale/pt-BR'
import moment from 'moment'

import { formatMoney, handleDecimalConvert, formatFromMoney } from '../../../utils/string.utils'
import { parcelasSelect } from '../../../constants/agendamento.constants'

registerLocale('pt-BR', ptBr)

export default function Parcelamento(props) {
  const {
    setPagamento,
    previousStep,
    handlePlanoPagamento,
    pagamento,
    isPlanoBalao,
    dataVencimentoPrimeiraParcela,
    setDataVencimentoPrimeiraParcela,
  } = props

  const [totalPagamento, setTotalPagamento] = useState(pagamento ? pagamento.valorTotal : 0)
  const [valorEntrada, setValorEntrada] = useState('0.0')
  const valorParcelamento = handleDecimalConvert(totalPagamento) / parcelasSelect[0].value
  const [parcelamentoSelecionado, setParcelamentoSelecionado] = useState(parcelasSelect[0])
  const [parcelas, setParcelas] = useState([])
  const [dataVencimento, setDataVencimento] = useState(
    dataVencimentoPrimeiraParcela
      ? dataVencimentoPrimeiraParcela._isAMomentObject
        ? dataVencimentoPrimeiraParcela.toDate()
        : dataVencimentoPrimeiraParcela
      : new Date()
  )

  useEffect(() => {
    let valorParcelas = 0
    let diferencaPrimeiraParcela = 0
    const valorEntradaNumber = handleDecimalConvert(valorEntrada)
    if (valorEntradaNumber >= formatFromMoney(totalPagamento)) {
      setValorEntrada(totalPagamento.substring(3))
      setParcelamento(parcelasSelect[0])
    } else {
      const { valorFinalParcelas, diferenca } = getValorParcelas(valorEntradaNumber, valorParcelas)
      valorParcelas = valorFinalParcelas
      diferencaPrimeiraParcela = diferenca
      if (valorEntradaNumber > 0 && parcelamentoSelecionado === parcelasSelect[0]) {
        setParcelamento(parcelasSelect[1])
      }
    }

    let parcelas = getParcelas(valorParcelas, diferencaPrimeiraParcela, valorEntradaNumber)
    setPagamento &&
      setPagamento({
        ...pagamento,
        parcelas,
        valorTotal: typeof totalPagamento === 'string' ? handleDecimalConvert(totalPagamento) : totalPagamento,
        dataVencimento: dataVencimento,
        numeroParcelas: parcelas.length,
      })
    setParcelas(parcelas)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valorEntrada, totalPagamento, parcelamentoSelecionado, dataVencimento])

  function getParcelas(valorParcelas, diferencaPrimeiraParcela, entrada) {
    let parcelas = []
    for (let index = 1; index < parcelamentoSelecionado.value; index++) {
      const valor = index === 1 ? +(valorParcelas + diferencaPrimeiraParcela).toFixed(2) : +valorParcelas.toFixed(2)
      parcelas.push({
        valor: +valor.toFixed(2),
        numero: index + 1,
        dataVencimento: moment(dataVencimento)
          .add(index, 'month')
          .toISOString(),
      })
    }
    if (entrada === 0) {
      parcelas.unshift({
        valor: +valorParcelas.toFixed(2),
        numero: 1,
        dataVencimento: moment(dataVencimento).toISOString(),
      })
    } else {
      parcelas.unshift({
        valor: entrada,
        numero: 1,
        dataVencimento: moment(dataVencimento).toISOString(),
      })
    }
    return parcelas
  }

  const handleChangeCurrency = (currency) => setTotalPagamento(currency)

  function getValorParcelas(valorEntradaNumber, valorParcelas) {
    let diferenca = 0
    let totalParcelas = parcelamentoSelecionado.value
    if (valorEntradaNumber > 0 && totalParcelas > 1) {
      totalParcelas = totalParcelas - 1
    }
    const totalNumber =
      typeof totalPagamento === 'string' ? handleDecimalConvert(totalPagamento) : Number(totalPagamento)
    valorParcelas = (totalNumber - valorEntradaNumber) / totalParcelas
    diferenca = totalNumber - valorEntradaNumber - Number(valorParcelas.toFixed(2)) * totalParcelas
    return { valorFinalParcelas: valorParcelas, diferenca }
  }
  const handleDataVencimento = (data) => {
    if (moment() > data) {
      data = moment()
    }
    setVencimento(data)
  }

  const setParcelamento = (parcelas) => setParcelamentoSelecionado(parcelas)

  const setVencimento = (vencimento) => {
    setDataVencimento(vencimento)

    if (setDataVencimentoPrimeiraParcela) {
      setDataVencimentoPrimeiraParcela(vencimento)
    }
  }

  return (
    <div className='d-flex'>
      <Container className='pb-5' style={{ maxWidth: 750 }}>
        <Fragment>
          <h4 className='my-4 text-secondary font-weight-bold'>Condições de pagamento</h4>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Valor Total</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>R$</InputGroup.Text>
                </InputGroup.Prepend>
                <CurrencyInput
                  className='form-control'
                  decimalSeparator=','
                  thousandSeparator='.'
                  value={totalPagamento}
                  onChange={handleChangeCurrency}
                  disabled={!isPlanoBalao}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} className='col-md-2'>
              <Form.Label>Parcelas</Form.Label>
              <Select value={parcelamentoSelecionado} options={parcelasSelect} onChange={setParcelamento} />
            </Form.Group>
            <Form.Group as={Col} className='required'>
              <Form.Label>Data da Primeira parcela</Form.Label>
              <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                <KeyboardDatePicker
                  invalidDateMessage='A data deve ser a partir de hoje'
                  minDateMessage='A data deve ser a partir de hoje'
                  id='dataPrimeiraParcela'
                  name='dataPrimeiraParcela'
                  className='form-control'
                  onChange={handleDataVencimento}
                  value={dataVencimento}
                  variant='inline'
                  format='DD/MM/YYYY'
                  required
                  animateYearScrolling
                  disablePast
                />
              </MuiPickersUtilsProvider>
            </Form.Group>
          </Row>
          {valorParcelamento <= handleDecimalConvert(totalPagamento) && (
            <div>
              <h4 className='my-4 text-secondary font-weight-bold'>Resumo do Parcelamento</h4>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th className='text-center' width='100px'>
                      Parcela
                    </th>
                    <th className='text-center'>Vencimento</th>
                    <th className='text-center' width='250px'>
                      Valor
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {parcelas.map(({ numero, dataVencimento, valor }, index) => (
                    <tr key={index}>
                      <td className='text-center'>{numero}</td>
                      <td className='text-center'>
                        {dataVencimento ? moment(dataVencimento).format(`DD/MM/YYYY`) : 'Data Inválida'}
                      </td>
                      <td className='text-center'>{formatMoney(valor)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className='d-flex justify-content-end'>
                {previousStep && (
                  <Button className='mt-3 d-block' onClick={previousStep} variant='outline-primary'>
                    Cancelar
                  </Button>
                )}
                {handlePlanoPagamento && (
                  <Button className='mt-3 ml-2 d-block' onClick={handlePlanoPagamento} variant='primary'>
                    Salvar Plano
                  </Button>
                )}
              </div>
            </div>
          )}
        </Fragment>
      </Container>
    </div>
  )
}

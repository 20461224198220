import React from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

class Emails extends React.Component {
  onChange = (e) => {
    let { value } = e.target
    const { index, email } = this.props
    let objChange = {
      endereco: value,
    }
    if (email.id) {
      objChange.id = email.id
    }
    this.props.onChange(index, objChange)
  }

  render() {
    const { email, index, disableAll, required } = this.props
    return (
      <Col md='4'>
        <Form.Group className={required ? 'required' : ''}>
          <Form.Label htmlFor='enderecoEmail'>E-mail</Form.Label>
          <Form.Control
            required={required}
            type='email'
            id='enderecoEmail'
            name={`enderecoEmail${index}`}
            value={email.endereco}
            onChange={this.onChange}
            maxLength={100}
            disabled={disableAll}
          />
        </Form.Group>
      </Col>
    )
  }
}

export default Emails

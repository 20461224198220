import StepWizard from 'react-step-wizard'
import React, { Fragment, useState, useRef, useEffect } from 'react'

import SelecaoProcedimentos from './SelecaoProcedimentos'
import Conclusao from './Conclusao'
import AlterarProteticoProcedimento from './AlterarProteticoProcedimento'

import PlanoTratamentoService from '../../../../services/planoTratamento.service'
import { toast } from 'react-toastify'

export default function AlterarProtetico({ planoTratamento, firstStep, isLoading, setIsLoading }) {
  const [procedimentos, setProcedimentos] = useState([])
  const [selectedProcedimento, setSelectedProcedimento] = useState(null)
  const [selectedProtetico, setSelectedProtetico] = useState(null)
  const [wizardRef, setWizard] = useState(useRef())

  useEffect(() => {
    if (planoTratamento && planoTratamento.id) {
      setProcedimentos(planoTratamento.procedimentos)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planoTratamento])

  useEffect(() => {
    if (selectedProtetico) {
      salvarPagamento()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProtetico])

  const salvarPagamento = async () => {
    setIsLoading(true)
    try {
      const { itemProtese, protetico } = selectedProtetico
      await PlanoTratamentoService.alterarProtetico({
        ...selectedProcedimento.planoTratamentoProcedimentoProtetico,
        valorOriginal: itemProtese.valor,
        valor: itemProtese.valorFinal,
        proteticoId: protetico.id,
        pago: false,
        valorPago: 0,
      })
      toast('Alterações realizadas com sucesso!', { type: toast.TYPE.SUCCESS })
      wizardRef.nextStep()
    } catch (error) {}
    setIsLoading(false)
  }

  const setProteticoDefinido = (novoProtetico) => {
    const procedimentoNovoProtetico = { ...selectedProcedimento }
    procedimentoNovoProtetico.proteticoId = novoProtetico.protetico.id
    procedimentoNovoProtetico.planoTratamentoProcedimentoProtetico = {
      ...procedimentoNovoProtetico.planoTratamentoProcedimentoProtetico,
      valor: novoProtetico.itemProtese.valorFinal,
      valorOriginal: novoProtetico.itemProtese.valor,
    }
    const indexProcedimento = procedimentos.indexOf(selectedProcedimento)
    const novosProcedimentos = [...procedimentos]
    novosProcedimentos.splice(indexProcedimento, 1, procedimentoNovoProtetico)
    setProcedimentos([...novosProcedimentos])
    setSelectedProcedimento(procedimentoNovoProtetico)
    setSelectedProtetico(novoProtetico)
  }

  const handleBack = () => {
    firstStep()
    setSelectedProcedimento(null)
    setProcedimentos([])
    setSelectedProtetico(null)
    wizardRef.firstStep()
  }

  return (
    <Fragment>
      <StepWizard isLazyMount instance={setWizard}>
        <SelecaoProcedimentos
          isLoading={isLoading}
          onBack={firstStep}
          procedimentos={procedimentos.filter((p) => !!p.planoTratamentoProcedimentoProtetico)}
          setProcedimento={setSelectedProcedimento}
          planoTratamento={planoTratamento}
        />
        <AlterarProteticoProcedimento
          selectedProcedimento={selectedProcedimento}
          setIsLoading={setIsLoading}
          setProteticoDefinido={setProteticoDefinido}
          handleSave={salvarPagamento}
        />
        <Conclusao onBack={handleBack} />
      </StepWizard>
    </Fragment>
  )
}

const MENSAGEM_ALERTA = 'mensagemAlerta'
const RELOAD_AGENDA = 'reloadAgenda'
const RELOAD_AGENDA_DENTISTA = 'reloadAgendaDentista'
const ENVIAR_MENSAGEM_GRUPO = 'EnviarNotificacaoGrupo'
const ENVIAR_ALERTA_TERMO_CONCLUSAO = 'EnviaAlertaTermoConclusao';

export { MENSAGEM_ALERTA, RELOAD_AGENDA, RELOAD_AGENDA_DENTISTA, ENVIAR_MENSAGEM_GRUPO, ENVIAR_ALERTA_TERMO_CONCLUSAO }

export const tipoMensagem = {
  MENSAGEM_ALERTA: 0,
  ENVIAR_ALERTA_TERMO_CONCLUSAO: 3
}

export const eventos = {
  RELOAD_AGENDA,
}

import {connect} from 'react-redux';
import {salvarAnamnese} from '../../services/actions/anamnese.actions';
import {requestPerfis} from '../../services/actions/perfis.actions';
import {bindActionCreators} from 'redux';

import AnamneseForm from '../../components/Anamnese/AnamneseForm';

const mapStateToProps = store => ({
  anamnese: store.anamneseState.selectedAnamnese,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({salvarAnamnese, requestPerfis}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AnamneseForm)
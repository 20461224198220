import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'

import PlanoTratamentoService from '../../../../services/planoTratamento.service'
import { TIPOS_TEMPLATE } from '../../../../constants/template.constants'

export default function Impressao({ paymentRecord, handlePrint, onBack, firstStep, setIsLoading, planoTratamento }) {
  const handlePrintGuia = async () => {
    setIsLoading(true)
    await PlanoTratamentoService.downloadTemplateAsPDF(planoTratamento.id, TIPOS_TEMPLATE.GuiaDeOrientacaoDeProtese)
    setIsLoading(false)
  }

  const handleBack = () => {
    onBack()
    firstStep()
  }

  return (
    <Fragment>
      <div className='mb-5 text-center'>
        <FontAwesomeIcon style={{ fontSize: '11em' }} className='text-success mt-5 mb-3' icon={faCheckCircle} />
        <h2 style={fontStyle} className='text-success'>
          Pagamento realizado com sucesso!
        </h2>
      </div>
      <div className='d-block mx-auto text-center'>
        {paymentRecord && paymentRecord.mensagemPagamentoProteticoConcluido && (
          <Button variant='primary' className=' mr-3' onClick={handlePrintGuia}>
            Imprimir Guia de orientação de prótese
          </Button>
        )}
        <Button variant='primary' className=' mr-3' onClick={handlePrint}>
          Imprimir Comprovante
        </Button>
        <Button variant='outline-primary' onClick={handleBack}>
          Voltar
        </Button>
      </div>
      {paymentRecord && paymentRecord.mensagemPagamentoProteticoConcluido && (
        <div className='alert alert-success mt-5 mx-auto w-50'>{paymentRecord.mensagemPagamentoProteticoConcluido}</div>
      )}
      <div className='alert alert-warning mt-5 mx-auto w-50'>
        <strong>Pop-ups</strong> bloquados no seu navegador, <strong>AdBlock</strong> habilitado para esta página, ou
        algumas <strong>extensões</strong> e <strong>add-ons</strong> instaladas podem ocasionar em problemas ao tentar
        imprimir os documentos desejados
      </div>
    </Fragment>
  )
}

const fontStyle = {
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
}

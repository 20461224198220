import React, { useState, useEffect, Fragment } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import formatCPF from '@brazilian-utils/format-cpf'
import { format } from 'date-fns'
import Select from 'react-select'

import RelatorioService from '../../services/relatorio.service'
import ColaboradoresService from '../../services/colaboradores.service'
import PageLoading from '../Common/PageLoading'
import { Badge, Col, Row, Table, Button, Spinner } from 'react-bootstrap'
import Paginator from '../Common/Paginator'
import planoTratamentoService from '../../services/planoTratamento.service'
import { formatMoney } from '../../utils/string.utils'
import CSVDownload from './CSVDownload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

export default function VencimentoParcela({ selectedRelatorio }) {
  const itensPorPagina = 20

  const [isLoading, setLoading] = useState(false)
  const [isFilterLoading, setFilterLoading] = useState(false)
  const [itensLista, setItensLista] = useState([])
  const [cabecalhoRelatorio, setCabecalhoRelatorio] = useState([])
  const [feedbackBusca, setFeedbackBusca] = useState('')
  const [pagina, setPagina] = useState(0)
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [itensCSV, setItensCSV] = useState([])
  const [dateFilterDe, setDateFilterDe] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  const [dateFilterAte, setDateFilterAte] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))
  const [selectedColaborador, setSelectedColaborador] = useState({ value: null, label: 'Todos', especialidades: null, ativo: true })
  const [colaboradoresOptions, setColaboradoresOptions] = useState([])
  const [isLoadingCSV, setLoadingCSV] = useState(false)
  const [downloadCSV, setDownloadCSV] = useState(false)
  const [selectedStatus, setStatus] = useState(null)
  const [isOpenHistoricoTelefone, setIsOpenHistoricoTelefone] = useState('')


  useEffect(() => {
    didMount()
    setCabecalhoRelatorio([
      'Data',
      'Profissional',
      'Paciente',
      'CPF',
      'Nº Plano',
      'Procedimento',
      'Dente',
      'Valor'
    ])
  }, [])

  useEffect(() => {
    handleSearch()
    setIsOpen('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagina,
  ])

  useEffect(() => {
    if (downloadCSV) {
      setTimeout(() => {
        setDownloadCSV(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itensCSV, downloadCSV])

  const didMount = async () => {
    setFilterLoading(true)
    const dentistas = await ColaboradoresService.getDentistas(false)
    setColaboradoresOptions(
      [{ value: null, label: 'Todos', especialidades: null, ativo: true }, 
      ...dentistas.map((d) => ({
        label: d.nome,
        value: d.id,
        especialidades: d.especialidadeColaborador,
        ativo: d.ativo,
      }))]
    )
    
    setFilterLoading(false)
  }

  const getValuesLista = async () => {
    const totalDaLista = itensPorPagina * totalPaginas
    if (totalDaLista === 0) {
      return
    }
    setLoadingCSV(true)
    let filtros = [
      getFilter('dataInicial', dateFilterDe),
      getFilter('dataFinal', dateFilterAte),
      getFilter('ResponsavelProcedimentoId', selectedColaborador ? selectedColaborador.value : null)
    ]
    const { dados } = await RelatorioService.getRegistrosResponsaveisProcedimentos(filtros, 1, totalDaLista)

    const valores = []
    dados.forEach((item) => {

      //for (var i = 0; i <= item.quantidade - 1; i++) {

      var row = {
        datas: item.datas ? item.datas.split(',')[0] : '-',
        nomeResponsavel: item.nomeResponsavel ? item.nomeResponsavel.split(',')[0] : '-',
        nome: item.nome,
        cpf: item.cpf ? formatCPF(item.cpf) : '-',
        numeroPlano: item.numeroPlano || '-',
        nomeProcedimento: item.nomeProcedimento,
        nomeDente: item.nomeDente,
        valor: formatMoney(item.valor)
      }
        
      valores.push(Object.values(row))
      //}

    })
    setItensCSV(valores)
    setDownloadCSV(true)
    setLoadingCSV(false)
    return {
      data: valores,
      headers: cabecalhoRelatorio,
      filename: `${selectedRelatorio.label} - ${format(new Date(), 'dd-MM-yyyy')}.csv`,
    }
  }

  const getFilter = (campo, valor) => ({ campo, valor })

  const handleClear = () => {
    setStatus(null)
    setDateFilterAte(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))
    setDateFilterDe(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    setSelectedColaborador({ value: null, label: 'Todos', especialidades: null, ativo: true })
    setPagina(0)
  }

  const handleSearch = async () => {
    setLoading(true)
    try {
      setFeedbackBusca('')
      let filtros = [
        getFilter('dataInicial', dateFilterDe),
        getFilter('dataFinal', dateFilterAte),
        getFilter('ResponsavelProcedimentoId', selectedColaborador ? selectedColaborador.value : null)
      ]
      const { dados, totalPaginas } = await RelatorioService.getRegistrosResponsaveisProcedimentos(
        filtros,
        pagina + 1,
        itensPorPagina
      )
      setItensLista(dados)
      setTotalPaginas(totalPaginas)
      if (dados.length === 0) {
        setFeedbackBusca('Nenhum registro encontrado')
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const handleDateFilterDe = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterDe(filter)
    } else {
      setDateFilterDe(null)
    }
    setPagina(0)
  }

  const handleDateFilterAte = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterAte(filter)
    } else {
      setDateFilterAte(null)
    }
    setPagina(0)
  }

  const handlePagina = (data) => setPagina(data.selected)

  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const backgroundColor = isSelected ? '#2684FF' : isFocused ? '#DEEBFF' : data.ativo ? 'transparent' : '#D3D3D3'
      return {
        ...styles,
        backgroundColor: backgroundColor,
      }
    },
  }

  const [isOpen, setIsOpen] = useState('')

  const handleOpen = (item) => {
    if(isOpenHistoricoTelefone === item)
      setIsOpenHistoricoTelefone('')
    else
      setIsOpenHistoricoTelefone(item)

    isOpen === item ? setIsOpen('') : setIsOpen(item)
  }

  const SubTable = (data) => {

    var subs = [];

    var subData = data.datas ? data.datas.split(',') : '-';
    var subNomes = data.nomes?  data.nomes.split(',') : '-';

    for (var i = 1; i <= data.count - 1; i++) {
      subs.push(
        <tr className={data.expand ? null : 'd-none'}>
          <td style={{ border: 'none', paddingLeft: '30px', fontSize: '11px' }} width= '120px' className='text-center'>{subData[i]}</td>
          <td style={{ border: 'none', paddingLeft: '30px', fontSize: '11px' }} >{subNomes[i]}</td>
          <td style={{ border: 'none' }} width='70px'>{''}</td>
          <td style={{ border: 'none' }}>{''}</td>
          <td style={{ border: 'none' }} width='130px'>{''}</td>
          <td style={{ border: 'none' }}>{''}</td>
          <td style={{ border: 'none' }} width='120px'>{''}</td>
          <td style={{ border: 'none' }} width='100px'>{''}</td>
          <td style={{ border: 'none' }} width='50px'>{''}</td>
        </tr>          
      )
    }

    return subs;

  }

  const handleChangeColaborador = (option) => {
      setSelectedColaborador(option)
      setPagina(0)
  }

  const renderItens = () =>
    itensLista.length > 0 ? (
      <Fragment>
        <div className='mt-4 d-flex justify-content-end align-items-center'>
          <CSVDownload asyncExportMethod={getValuesLista}>
            <Button variant='outline-secondary' className='mb-3'>
              {isLoadingCSV ? <Spinner animation='border' size='sm' variant='light' /> : 'Gerar CSV'}
            </Button>
          </CSVDownload>
        </div>
        <Table hover size='sm' className='border rounded-0 shadow-sm' style={{backgroundColor: 'white'}}>
          <thead>
            <tr>
              <th width='120px' className='text-center'>
                Data
              </th>
              <th>Profissional</th>
              <th width='70px'></th>
              <th>Paciente</th>
              <th width='130px' className='text-center'>
                CPF
              </th>
              <th  width='120px' className='text-center'>Nº Plano</th>
              <th>Procedimento</th>
              <th>Dente</th>
              <th width='120px' className='text-center'>
                Valor
              </th>
            </tr>
          </thead>
          <tbody>
            {itensLista.map((item, i) => {
              const {
                nomeResponsavel,
                nome,
                cpf,
                numeroPlano,
                nomeProcedimento,
                dataFinalizacao,
                nomeDente,
                valor,
                datas,
                quantidade
              } = item
              
              const expand = i === isOpen

              return (
                <Fragment key={i}>
                  <tr key={i + 'tr1'} style={{ fontSize: 'smaller', height: '45px' }}>
                    <td className='text-center'> {datas ? datas.split(',')[0] : '-'} </td>
                    <td>{nomeResponsavel ? nomeResponsavel.split(',')[0] : 'NÃO DEFINIDO'}</td>
                    
                    <td> {quantidade > 1 ? <Badge pill variant="primary" style={{padding: '5px 10px', marginLeft: '10px', cursor: 'pointer'}} onClick={() => handleOpen(i)}> + {quantidade - 1}  <FontAwesomeIcon style={{marginLeft:'8px'}} icon={isOpenHistoricoTelefone === i ? faChevronUp : faChevronDown} /></Badge>  : ''} </td>
                    <td>{nome ? nome.toUpperCase() : '-'}</td>
                    <td className='text-center'>{cpf ? formatCPF(cpf) : '-'}</td>
                    <td className='text-center'>{numeroPlano ? numeroPlano : '-'}</td>
                    <td>{nomeProcedimento ? nomeProcedimento : '-'}</td>
                    <td>{nomeDente ? nomeDente : '-'}</td>
                    <td className='text-center'>{formatMoney(valor)}</td>
                  </tr>

                  {expand && (

                    <SubTable count={quantidade} nomes={nomeResponsavel} datas={datas} expand={expand}></SubTable>
                  )}

                </Fragment>
              )
            })}
          </tbody>
        </Table>
        {totalPaginas > 1 && <Paginator handlePagina={handlePagina} pageCount={totalPaginas} paginaAtual={pagina} />}
      </Fragment>
    ) : (
      <div className='mt-4'>{feedbackBusca}</div>
    )

  const renderLoading = () => <PageLoading />

  return (
    <Fragment>
      <Row>
        <Col md='2'>
          <label>Data de Finalização de:</label>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              invalidDateMessage='Formato de data inválido'
              value={dateFilterDe}
              onChange={handleDateFilterDe}
              className='form-control'
              variant='inline'
              format='DD/MM/YYYY'
              maxDate={dateFilterAte || new Date('2100-01-01')}
              animateYearScrolling
              autoOk
              placeholder='De'
            />
          </MuiPickersUtilsProvider>
        </Col>


        <Col md='2'>
          <label>Data de Finalização até:</label>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              invalidDateMessage='Formato de data inválido'
              value={dateFilterAte}
              onChange={handleDateFilterAte}
              minDate={dateFilterDe || new Date('1900-01-01')}
              className='form-control'
              variant='inline'
              format='DD/MM/YYYY'
              animateYearScrolling
              autoOk
              placeholder='Até'
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col md='3'>
          <label>Profissional:</label>
          <Select
              placeholder='Profissional'
              className='w-100 mr-1'
              onChange={handleChangeColaborador}
              value={selectedColaborador}
              styles={customStyles}
              options={colaboradoresOptions}
          />
        </Col>
        <Col md='3' style={{paddingTop: '29px'}}>
          <Button disabled={isFilterLoading} onClick={handleClear} variant='outline-danger' className='mr-2' style={{height: '38px'}}>
              Limpar
          </Button>
          <Button disabled={isFilterLoading} onClick={handleSearch} variant='primary' className='' style={{height: '38px'}}>
            Pesquisar
          </Button>
        </Col>
      </Row>
      {isLoading ? renderLoading() : renderItens()}
    </Fragment>
  )
}

const spinnerStyle = {
  position: 'absolute',
  top: '10px',
  right: '50px',
}


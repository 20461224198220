import React, { Fragment, useState, useEffect } from 'react'
import { Button, Col, Form, InputGroup, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import CurrencyInput from 'react-currency-input'
import { toast } from 'react-toastify'

import PageLoading from '../Common/PageLoading'
import CollapsePanel from '../Common/CollapsePanel'
import OrtodontiaService from '../../services/ortodontia.service'
import { filtroNaoSimAmbos } from '../../constants/common.constants'
import { formatMoney, handleDecimalConvert } from '../../utils/string.utils'
import SystemConstants from '../../constants/system.constants'
import { TIPO_USUARIO } from '../../constants/usuario.constants'


const CadastrarAparelho = () => {
  const [isEdit, setIsEdit] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [aparelhosCadastrados, setAparelhosCadastrados] = useState([])
  const [nome, setNome] = useState('')
  const [complementar, setComplementar] = useState(false)
  const [valorCusto, setValorCusto] = useState('')
  const [valorVenda, setValorVenda] = useState('')
  const [isFranqueador, setIsFranqueador] = useState(false);

  useEffect(() => {
    getAparelhosCadastrados()
    verficaTipoUsuario()
  }, [])

  const verficaTipoUsuario = () => {
    let user = JSON.parse(localStorage.getItem(SystemConstants.getUser()))  
    var ehFranqueador =  user.tipoUsuario === TIPO_USUARIO.Franqueador
    setIsFranqueador(ehFranqueador)
  }

  const getAparelhosCadastrados = async () => {
    try {
      const { data } = await OrtodontiaService.getAparelhosCadastrados(filtroNaoSimAmbos.AMBOS.value)
      setAparelhosCadastrados(data)
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleSaveAdd = async () => {
    setIsLoading(true)
    try {
      if (!nome) {
        toast(`O campo 'Nome do Aparelho' é obrigatório`, { type: toast.TYPE.ERROR })
        setIsLoading(false)
        return
      }
      await OrtodontiaService.cadastrarAparelho({
        nome,
        complementar,
        ativo: true,
        valorCusto: typeof valorCusto === 'string' ? +handleDecimalConvert(valorCusto) : valorCusto,
        valorVenda: typeof valorVenda === 'string' ? +handleDecimalConvert(valorVenda) : valorVenda,
      })
      toast('Aparelho cadastrado com sucesso!', { type: toast.TYPE.SUCCESS })
      resetFields()
      await getAparelhosCadastrados()
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }

  const handleSaveEdit = async () => {
    setIsLoading(true)
    try {
      if (!nome) {
        toast(`O campo 'Nome do Aparelho' é obrigatório`, { type: toast.TYPE.ERROR })
        setIsLoading(false)
        return
      }
      await OrtodontiaService.cadastrarAparelho({
        ...aparelhosCadastrados[isEdit],
        nome,
        complementar,
        valorCusto: typeof valorCusto === 'string' ? +handleDecimalConvert(valorCusto) : valorCusto,
        valorVenda: typeof valorVenda === 'string' ? +handleDecimalConvert(valorVenda) : valorVenda,
      })
      toast('Aparelho editado com sucesso!', { type: toast.TYPE.SUCCESS })
      resetFields()
      await getAparelhosCadastrados()
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }

  const handleEdit = (index) => {
    setIsEdit(index)
    setIsOpen(true)
    setNome(aparelhosCadastrados[index].nome)
    setComplementar(aparelhosCadastrados[index].complementar)
    setValorCusto(aparelhosCadastrados[index].valorCusto)
    setValorVenda(aparelhosCadastrados[index].valorVenda)
  }

  const resetFields = () => {
    setNome('')
    setValorCusto('')
    setValorVenda('')
    setIsOpen(false)
    setIsEdit(null)
  }

  const handleCancelEdit = () => {
    setIsEdit(null)
    setIsOpen(false)
    setNome('')
    setComplementar(false)
    setValorCusto('')
    setValorVenda('')
  }

  const handleToggleAtivo = async (aparelho) => {
    setIsLoading(true)
    try {
      await OrtodontiaService.toggleAtivarAparelho(aparelho)
      await getAparelhosCadastrados()
    } catch (e) {}
    setIsLoading(false)
  }

  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
  }

  const handleComplementarChange = (event) => {
    setComplementar(event.target.checked)
  }

  const handleChangeNome = (event) => {
    setNome(event.target.value)
  }

  const handleChangeValorCusto = (value) => {
    setValorCusto(value)
  }

  const handleChangeValorVenda = (value) => {
    setValorVenda(value)
  }

  const renderCadastrar = () => (
    <div className='my-4'>
      <CollapsePanel stepCode='cadastrar' text='Formulário de Cadastro' isOpen={isOpen} toggleIsOpen={toggleIsOpen}>
        <Form>
          <Row className='my-3 align-items-center'>
            <Col md='4'>
              <Form.Group className='required'>
                <Form.Label>Nome do Aparelho:</Form.Label>
                <Form.Control type='text' name='nome' value={nome} onChange={handleChangeNome} required />
              </Form.Group>
            </Col>

            {!isFranqueador && (
              <>
                <Col md='3'>
                  <Form.Group className='required'>
                    <Form.Label>Valor Custo:</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>R$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <CurrencyInput
                        className='form-control'
                        name='valorCusto'
                        decimalSeparator=','
                        thousandSeparator='.'
                        value={valorCusto}
                        onChange={handleChangeValorCusto}
                        required
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md='3'>
                  <Form.Group className='required'>
                    <Form.Label>Valor Venda:</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>R$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <CurrencyInput
                        className='form-control'
                        name='valorVenda'
                        decimalSeparator=','
                        thousandSeparator='.'
                        value={valorVenda}
                        onChange={handleChangeValorVenda}
                        required
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </>
            )}
            <Col md='2'>
              <label className='checkbox mt-4'>
                <input type='checkbox' checked={complementar} onChange={handleComplementarChange} />
                <span className='d-flex align-items-center'>Complementar</span>
              </label>
            </Col>
          </Row>
          <div className='d-flex justify-content-end'>
            <div className='alert alert-secondary mb-0 mr-auto py-2'>
              <strong>Aviso: </strong>aparelhos cadastrados com valor de venda igual a zero não serão cobrados do
              paciente.
            </div>
            {(isEdit || isEdit === 0) && (
              <Button variant='outline-primary' className='mr-2' onClick={handleCancelEdit}>
                Cancelar
              </Button>
            )}
            <Button variant='primary' onClick={isEdit || isEdit === 0 ? handleSaveEdit : handleSaveAdd}>
              {isEdit || isEdit === 0 ? 'Editar' : 'Cadastrar'}
            </Button>
          </div>
        </Form>
      </CollapsePanel>
    </div>
  )

  const renderAparelhosCadastrados = () => (
    <Fragment>
      {aparelhosCadastrados.length > 0 ? (
        <Table size='sm' bordered hover className='bg-white'>
          <thead>
            <tr>
              <th>Aparelho</th>
              <th width='150px' className='text-center'>
                Complementar
              </th>
              {!isFranqueador && (<>
                <th width='150px' className='text-center'>
                  Valor Custo
              </th>
                <th width='150px' className='text-center'>
                  Valor Venda
              </th>
              </>)}
              <th width='110px' className='text-center'>
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            {aparelhosCadastrados.map((aparelho, index) => (
              <tr style={{ fontSize: 'small' }} key={index}>
                <td>{aparelho.nome}</td>
                <td className='text-center'>{aparelho.complementar ? 'Sim' : 'Não'}</td>
                {!isFranqueador && (<>
                  <td className='text-center'>{formatMoney(aparelho.valorCusto)}</td>
                  <td className='text-center'>{formatMoney(aparelho.valorVenda)}</td>
                </>)}
                <td className='text-center'>
                  <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Editar aparelho</Tooltip>}>
                    <Button onClick={() => handleEdit(index)} variant='primary' className='mr-2 btn btn-sm'>
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    trigger='hover'
                    placement='top'
                    overlay={<Tooltip>{aparelho.ativo ? 'Desativar aparelho' : 'Ativar aparelho'}</Tooltip>}
                  >
                    <Button
                      onClick={() => handleToggleAtivo(aparelho)}
                      variant={aparelho.ativo ? 'success' : 'danger'}
                      className='mr-2 btn btn-sm'
                    >
                      <FontAwesomeIcon icon={faPowerOff} />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div>Nenhum aparelho encontrado.</div>
      )}
    </Fragment>
  )

  return (
    <div>
      <h1 className='h3 m-0 text-secondary font-weight-bold'>Cadastrar Aparelho Complementar</h1>
      {renderCadastrar()}
      {isLoading ? <PageLoading /> : renderAparelhosCadastrados()}
    </div>
  )
}

export default CadastrarAparelho

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetarProcedimento } from '../../services/actions/procedimento.actions'

import ProcedimentosForm from '../../components/Procedimentos/ProcedimentosForm'

const mapStateToProps = (store) => ({
	procedimento: store.procedimentosState.selectedProcedimento,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ resetarProcedimento }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProcedimentosForm)

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Button, Table, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import formatCnpj from '@brazilian-utils/format-cnpj'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPowerOff } from '@fortawesome/free-solid-svg-icons'

import MenuContext from '../../contexts/MenuContexts'
import PageLoading from '../Common/PageLoading'
import FranquiasService from './../../services/franquias.service'
import PermissoesConstants from '../../constants/permissoes.constants'

class FranquiasList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      franquias: [],
      toggleModal: false,
      toggleFranquia: [],
      showAtivos: false,
      showInativos: false,
    }

    this.closeModal = this.closeModal.bind(this)
    this.filterFranquias = this.filterFranquias.bind(this)
    this.filterAtivos = this.filterAtivos.bind(this)
    this.filterInativos = this.filterInativos.bind(this)
  }

  componentDidMount() {
    this.listarFranquias()
  }

  listarFranquias() {
    this.setState({ ...this.state, isLoading: true }, async () => {
      const { data } = await FranquiasService.listarFranquias()
      this.setState({ franquias: data, showAtivos: false, showInativos: false, isLoading: false })
    })
  }

  editFranquia(id) {
    this.props.history.push(`/franquias/editar/${id}`)
  }

  openModal(franquia) {
    this.setState({ toggleModal: true, toggleFranquia: franquia })
  }

  closeModal() {
    this.setState({ toggleModal: false })
  }

  ativar(franquia, ativar) {
    FranquiasService.ativar(franquia.id, ativar).then(({ data }) => {
      this.listarFranquias()
    })
  }

  filterFranquias(e) {
    let franquias = []
    if (e.target.value !== '') {
      franquias = this.state.franquias
      franquias = franquias.filter(function(franquia) {
        return franquia.nomeFantasia.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      })
    } else {
      this.listarFranquias()
    }
    this.setState({ franquias: franquias })
  }

  filterAtivos() {
    let franquias = []
    FranquiasService.listarFranquias().then(({ data }) => {
      //Modificar para filtro no backend
      franquias = data.filter((franquia) => franquia.status === 1)
      this.setState({ franquias: franquias, showAtivos: true, showInativos: false })
    })
  }

  filterInativos() {
    let franquias = []
    FranquiasService.listarFranquias().then(({ data }) => {
      //Modificar para filtro no backend
      franquias = data.filter((franquia) => franquia.status === 0)
      this.setState({ franquias: franquias, showInativos: true, showAtivos: false })
    })
  }

  renderLoading = () => <PageLoading />

  renderLista = (acoesPermitidas) => {
    const { franquias, toggleModal, toggleFranquia } = this.state
    return (
      <Fragment>
        {franquias.length !== [] ? (
          <div>
            <Table bordered hover className='bg-white'>
              <thead>
                <tr>
                  <th>Razão social</th>
                  <th>Nome fantasia</th>
                  <th className='text-center' width='170px'>CNPJ</th>
                  <th>Responsável</th>
                  {Object.keys(acoesPermitidas).length > 0 && (
                    <th className='text-center' width='120px'>
                      Ações
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {franquias.map((franquia, index) => (
                  <tr key={index}>
                    <td>{franquia.razaoSocial}</td>
                    <td>{franquia.nomeFantasia}</td>
                    <td className='text-center'>{formatCnpj(franquia.cnpj)}</td>
                    <td>{franquia.responsavel ? franquia.responsavel.nome : '-'}</td>
                    <td className='text-center d-flex justify-content-around'>
                      <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Editar Franquia</Tooltip>}>
                        <Button onClick={() => this.editFranquia(franquia.id)} variant='primary' size='sm'>
                          <FontAwesomeIcon icon={faPen} />
                        </Button>
                      </OverlayTrigger>
                
                    
                      <OverlayTrigger
                        trigger='hover'
                        placement='top'
                        overlay={
                          <Tooltip>{franquia.status === 0 ? 'Desativar franquia' : 'Ativar franquia'}</Tooltip>
                        }
                      >
                        <Button
                          onClick={() => this.ativar(franquia, franquia.status !== 0)}
                          variant={franquia.status === 0 ? 'success' : 'danger'}
                          size='sm'
                        >
                          <FontAwesomeIcon icon={faPowerOff} />
                        </Button>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Modal show={toggleModal} onHide={this.closeModal}>
              <Modal.Body>Deseja {toggleFranquia.status ? 'desativar' : 'ativar'} essa franquia?</Modal.Body>
              <Modal.Footer>
                <Button onClick={this.closeModal} variant='light'>
                  Cancelar
                </Button>
                <Button
                  onClick={() => this.toggleFranquia(toggleFranquia)}
                  variant={toggleFranquia.status ? 'danger' : 'success'}
                >
                  {toggleFranquia.status ? 'Desativar' : 'Ativar'}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        ) : (
          <div>Nenhuma franquia encontrada.</div>
        )}
      </Fragment>
    )
  }

  render() {
    let acoesPermitidas = {}
    return (
      <MenuContext.Consumer>
        {(acoes) => {
          if (acoes) {
            acoes.forEach((a) => {
              acoesPermitidas[`${a.nome}`] = a
            })
          }
          return (
            <div>
              <Row className='mb-4'>
                <Col>
                  <h1 className='h3 m-0 text-secondary font-weight-bold'>Franquias</h1>
                </Col>
                <Col>
                  <Form.Control type='text' onChange={this.filterFranquias} placeholder='Filtrar por nome' />
                </Col>
                <Col>
                  {acoesPermitidas[PermissoesConstants.ADICIONAR] && (
                    <Link to='/franquias/cadastrar'>
                      <Button className='float-right'>Cadastrar franquia</Button>
                    </Link>
                  )}
                </Col>
              </Row>
              {this.state.isLoading ? this.renderLoading() : this.renderLista(acoesPermitidas)}
            </div>
          )
        }}
      </MenuContext.Consumer>
    )
  }
}

export default FranquiasList

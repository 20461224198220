import React, { useState, useEffect, Fragment } from 'react'
import StepWizard from 'react-step-wizard'

import Anamnese from './Anamnese'
import PageLoading from '../Common/PageLoading'
import Procedimentos from './Procedimentos'
import Odontograma from './Odontograma'
import Navigation from './Navigation'

import PacienteService from '../../services/paciente.service'
import procedimentosService from '../../services/procedimentos.service'
import AnamneseService from '../../services/anamnese.service'

export default function Avaliacao(props) {
  const { pacienteId, pacienteNome, pacienteCpf, history, agendamento, perguntasAnamnese, anamneseFichaId } = props

  const INITIAL_PACIENTE = { nome: pacienteNome, cpf: pacienteCpf, id: pacienteId }
  const INITIAL_FICHA = {
    descricao: '',
    statusAvaliacao: 0,
    agendamentoId: agendamento.id,
    ...agendamento,
    pacienteId,
    anamneseFichaId,
    anamneseRespostas: [],
    anamnesePerguntas: [],
    dataRetornoPaciente: new Date().toISOString(),
  }

  delete INITIAL_FICHA.id
  delete INITIAL_FICHA.dataCriacao
  delete INITIAL_FICHA.dependente

  const [ficha, setFicha] = useState(INITIAL_FICHA)
  const [isLoading, setIsLoading] = useState(true)
  const [wizardRef, setWizardRef] = useState(null)
  const [dentes, setDentes] = useState({})
  const [dentesPaciente, setDentesPaciente] = useState({})
  const [procedimentos, setProcedimentos] = useState([])
  const [procedimentosPaciente, setProcedimentosPaciente] = useState([])
  const [passo, setPasso] = useState(1)
  const [paciente, setPaciente] = useState({ ...INITIAL_PACIENTE })

  useEffect(() => {
    if (!pacienteId) {
      history.push('/')
    }
    wizardDidMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let novaFicha = { ...ficha }
    novaFicha.pacienteId = paciente.id
    if (paciente.id !== pacienteId) {
      novaFicha.dependenteId = pacienteId
    }
    setFicha(novaFicha)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paciente])

  useEffect(() => {
    let novaFicha = { ...ficha }
    novaFicha.pacienteId = paciente.id
    if (paciente.id !== pacienteId) {
      novaFicha.dependenteId = pacienteId
    }
    setFicha(novaFicha)
    setDentesFichaPaciente()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dentes])

  useEffect(() => {
    if (perguntasAnamnese && perguntasAnamnese.length > 0) {
      const perguntasNovaFicha = perguntasAnamnese.find((p) => {
        return p.anamneseFichaId === anamneseFichaId
      })
      perguntasNovaFicha && atualizarPerguntasFicha(perguntasNovaFicha.anamnesePerguntas)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perguntasAnamnese])

  const setStepWizard = (wizard) => setWizardRef(wizard)

  const wizardDidMount = async () => {
    await getProcedimentos()
    await getDentes()
    pacienteId && (await getPaciente())
    anamneseFichaId && (await getAnamnese())
    setIsLoading(false)
  }

  const getAnamnese = async () => {
    try {
      const { data } = await AnamneseService.getRespostasByAgendamentoId(agendamento.id)
      onChangeFicha(data.anamnesePerguntas, data.id, data.nome)
    } catch (error) {
      console.error(error)
    }
  }

  const atualizarPerguntasFicha = (perguntas, anamneseFichaId, nome) => {
    let novaFicha = { ...ficha }
    if (nome && !novaFicha.nome) {
      novaFicha.nome = nome
    }
    novaFicha.anamneseFichaId = anamneseFichaId
    novaFicha.anamneseRespostas = new Array(perguntas.length)
    novaFicha.anamnesePerguntas = perguntas
    setFicha(novaFicha)
  }

  const onChangeRespostaRadio = (index, { target }) => {
    let novasRespostas = [...ficha.anamneseRespostas]
    if (novasRespostas[index]) {
      novasRespostas[index].tipoResposta = target.value
    } else {
      novasRespostas[index] = {
        tipoResposta: target.value,
        texto: null,
        anamneseId: target.name,
      }
    }
    setFicha({
      ...ficha,
      anamneseRespostas: novasRespostas,
    })
  }

  const onChangeRespostaTexto = (index, { target }) => {
    let novasRespostas = [...ficha.anamneseRespostas]
    if (novasRespostas[index]) {
      if (!target.value && novasRespostas[index].tipoResposta === null) {
        novasRespostas[index] = undefined
      } else {
        novasRespostas[index].texto = target.value
      }
    } else {
      novasRespostas[index] = {
        tipoResposta: null,
        texto: target.value,
        anamneseId: target.name,
      }
    }
    setFicha({
      ...ficha,
      anamneseRespostas: novasRespostas,
    })
  }

  const getPaciente = async () => {
    try {
      const { data } = await PacienteService.getPacienteById(pacienteId)
      setPaciente(data)
    } catch (e) {}
  }

  const getDentes = async () => {
    try {
      const { data } = await PacienteService.getDentesOrdenados()
      setDentes(data)
    } catch (e) {}
  }

  const getProcedimentos = async () => {
    try {
      if (!agendamento.especialidadeId) {
        return
      }
      const { data } = await procedimentosService.getProcedimentosByEspecialidade(agendamento.especialidadeId)
      setProcedimentos(
        data.filter((p) => {
          return p.ativo
        })
      )
    } catch (e) {}
  }

  const onChangeFicha = (perguntas, fichaId, nome) => atualizarPerguntasFicha(perguntas, fichaId, nome)

  const setDentesFichaPaciente = () => {
    if (Object.keys(dentes).length > 0) {
      let dentesPaciente = { ...dentes }
      Object.keys(dentes).forEach((quadrante) => {
        dentes[quadrante].forEach((dente, index) => {
          dente = {
            denteId: dente.id,
            ausente: false,
            codigo: dente.codigo,
          }
          dentesPaciente[quadrante].splice(index, 1, dente)
        })
      })
      setDentesPaciente(dentesPaciente)
    }
  }

  const setDenteFaltantePaciente = (quadrante, index) => {
    let novosDentesPaciente = { ...dentesPaciente }
    novosDentesPaciente[quadrante][index].ausente = !novosDentesPaciente[quadrante][index].ausente
    setDentesPaciente(novosDentesPaciente)
  }

  const setProcedimentoPaciente = (procedimentos) => {
    setProcedimentosPaciente([...procedimentos])
  }

  const onStepChange = (passo) => setPasso(passo.activeStep)

  const renderLoading = () => <PageLoading />

  return (
    <Fragment>
      {paciente.id && procedimentos.length > 0 && (
        <Fragment>
          <StepWizard isLazyMount onStepChange={onStepChange} instance={setStepWizard} key={pacienteId}>
            <Anamnese
              {...props}
              respostas={ficha.anamneseRespostas}
              onChangeRadio={onChangeRespostaRadio}
              onChangeTexto={onChangeRespostaTexto}
              onChangeFicha={onChangeFicha}
              selectedFicha={ficha}
              paciente={paciente}
            />
            <Odontograma dentes={{ ...dentesPaciente }} toggleDentesSelecionados={setDenteFaltantePaciente} />
            <Procedimentos
              dentes={dentesPaciente}
              procedimentos={procedimentos}
              procedimentosPaciente={procedimentosPaciente}
              setProcedimentos={setProcedimentoPaciente}
              setIsLoading={setIsLoading}
            />
          </StepWizard>
          <Navigation
            {...props}
            passo={passo}
            ficha={{ ...ficha, dentesPaciente, procedimentos: procedimentosPaciente }}
            wizard={wizardRef}
          />
        </Fragment>
      )}
      {isLoading && renderLoading()}
    </Fragment>
  )
}

import React, { Fragment, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'

import CollapsePanel from '../../../Common/CollapsePanel'
import Anamnese from './Anamnese'
import Procedimentos from './Procedimentos'
import Odontograma from './Odontograma'
import AtendimentoService from '../../../../services/atendimento.service'
import { statusProcedimento } from '../../../../constants/common.constants'

function VisualizarAvaliacao(props) {
  const INITIAL_OPEN_STATE = { step1: false, step2: false, step3: false, step4: false }
  const { avaliacao, firstStep, planoTratamento } = props
  const [stepIsOpen, setStepIsOpen] = useState({ ...INITIAL_OPEN_STATE })

  const toggleIsOpen = (step) => {
    setStepIsOpen({
      ...stepIsOpen,
      [step]: !stepIsOpen[step],
    })
  }

  const handleSaveStatus = async (indexProcedimento) => {
    try {
      if (!planoTratamento) {
        return
      }
      let procedimentoSelecionado = planoTratamento.procedimentos[indexProcedimento]
      procedimentoSelecionado.statusProcedimento = statusProcedimento.REABERTO.value

      const planoTratamentoAtualizado = { ...planoTratamento }
      planoTratamentoAtualizado.procedimentos[indexProcedimento] = procedimentoSelecionado

      const { value } = await Swal.fire({
        title: `Deseja mesmo alterar o status do procedimento ${procedimentoSelecionado.procedimento.nome} para REABERTO?`,
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#dbdbdb',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Reabrir',
      })
      if (value) {
        await AtendimentoService.salvarPlanoDeTratamento(planoTratamentoAtualizado)
        toast('Status de procedimento alterado com sucesso', { type: toast.TYPE.SUCCESS })
      }
    } catch (error) {}
  }

  const renderAvaliacao = (avaliacao) => (
    <div>
      <div style={backContainerStyle}>
        <div onClick={firstStep}>
          <Button variant='outline-primary' style={backStyle}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <span className='text-primary'>Voltar</span>
        </div>
        <div className='ml-auto mw-50'>
          <p className='m-0'>
            <strong>Responsável: </strong>
            {avaliacao.responsavelAvaliacao && avaliacao.responsavelAvaliacao.nome}
          </p>
          <p className='m-0'>
            <strong>Descrição: </strong>
            {avaliacao.descricao}
          </p>
        </div>
      </div>
      <CollapsePanel stepCode='step1' text='Anamnese' isOpen={stepIsOpen.step1} toggleIsOpen={toggleIsOpen}>
        <Anamnese anamneseRespostas={avaliacao.anamneseRespostas} />
      </CollapsePanel>
      <CollapsePanel stepCode='step2' text='Odontograma' isOpen={stepIsOpen.step2} toggleIsOpen={toggleIsOpen}>
        <Odontograma dentesPaciente={avaliacao.dentesPaciente} />
      </CollapsePanel>
      <CollapsePanel
        stepCode='step3'
        text='Procedimentos Indicados pelo Dentista'
        isOpen={stepIsOpen.step3}
        toggleIsOpen={toggleIsOpen}
      >
        <Procedimentos procedimentos={avaliacao.procedimentos} />
      </CollapsePanel>
      <CollapsePanel
        stepCode='step4'
        text='Procedimentos Escolhidos pelo Paciente'
        isOpen={stepIsOpen.step4}
        toggleIsOpen={toggleIsOpen}
      >
        <Procedimentos
          escolhidos
          handleSaveStatus={handleSaveStatus}
          procedimentos={planoTratamento ? planoTratamento.procedimentos : []}
        />
      </CollapsePanel>
    </div>
  )

  return <Fragment>{avaliacao && renderAvaliacao(avaliacao)}</Fragment>
}

export default VisualizarAvaliacao

//////////////////////////////////////////////////////////////////
///////////////////////////// STYLES /////////////////////////////
//////////////////////////////////////////////////////////////////
const backContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '1.5em 0',
}
const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

import React from 'react'
import { Provider } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { OdontoStore } from '../services/store'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/app.scss'
import '../styles/fontAwesome'

import AppContainer from '../containers/AppContainer'

class App extends React.Component {
  render() {
    return (
      <Provider store={OdontoStore}>
        <AppContainer />
        <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={3000} />
        <div id='print'></div>
      </Provider>
    )
  }
}

export default App

import React, { useEffect, useState } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import Select from 'react-select'
import { Table, Row, Col, Form, Button } from 'react-bootstrap'
import { status, tipoConsulta } from '../../constants/agendamento.constants'
import ColaboradoresService from '../../services/colaboradores.service'
import PacienteService from '../../services/paciente.service'
import SharedService from '../../services/shared.service'

export default function HistoricoAgendamentos({ setIsLoading, pacienteId }) {
  const [dateFilterDe, setDateFilterDe] = useState(null)
  const [dateFilterAte, setDateFilterAte] = useState(null)
  const [colaboradores, setColaboradores] = useState([])
  const [especialidades, setEspecialidades] = useState([])
  const [colaboradorSelecionado, setColaboradorSelecionado] = useState(null)
  const [statusSelecionado, setStatusSelecionado] = useState(null)
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState(null)
  const [planoTratamento, setPlanoTratamento] = useState(null)
  const [planoTratamentoOrtodontia, setPlanoTratamentoOrtodontia] = useState(null)
  const [historicoAgendamentos, setHistoricoAgendamentos] = useState(null)

  useEffect(() => {
    getColaboradores()
    getAllEspecialidades()
  }, [])

  useEffect(() => {
    getHistoricoAgendamentos()
  }, [dateFilterDe, dateFilterAte, colaboradorSelecionado, statusSelecionado, especialidadeSelecionada, planoTratamento, planoTratamentoOrtodontia])

  const getHistoricoAgendamentos = async () => {
    setIsLoading(true)
    try {
      const { data } = await PacienteService.getHistoricoAgendamentos(
        pacienteId,
        dateFilterDe,
        dateFilterAte,
        colaboradorSelecionado,
        statusSelecionado,
        especialidadeSelecionada,
        planoTratamento,
        planoTratamentoOrtodontia
      )
      setHistoricoAgendamentos(data)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const getColaboradores = async () => {
    try {
      const data = await ColaboradoresService.getDentistas()
      setColaboradores([{ value: null, label: 'Todos' }, ...data.map(({ id, nome }) => ({ value: id, label: nome }))])
    } catch (error) {
      console.error(error)
    }
  }

  const getAllEspecialidades = async () => {
    try {
      const { data } = await SharedService.getAllEspecialidades()
      setEspecialidades([{ value: null, label: 'Todos' }, ...data.map(({ id, nome }) => ({ value: id, label: nome }))])
    } catch (error) {
      console.error(error)
    }
  }

  const getStatusLabel = (statusAgendamento) => {
    const found = status.find(({ value }) => value === statusAgendamento)
    return found ? found.label : '--'
  }

  const handleDateFilterDe = (date) => {
    date ? setDateFilterDe(date.toDate()) : setDateFilterDe(null)
  }

  const handleDateFilterAte = (date) => {
    date ? setDateFilterAte(date.toDate()) : setDateFilterAte(null)
  }

  const handleChangeColaborador = (option) => {
    setColaboradorSelecionado(option)
  }

  const handleChangeStatus = (option) => {
    setStatusSelecionado(option)
  }

  const handleChangeEspecialidade = (option) => {
    setEspecialidadeSelecionada(option)
  }

  const handleClear = () => {
    setDateFilterDe(null)
    setDateFilterAte(null)
    setColaboradorSelecionado(null)
    setEspecialidadeSelecionada(null)
    setStatusSelecionado(null)
  }

  const getTipoAgendamentoLabel = (tipoAgendamento) => {
    const found = tipoConsulta.find(({ value }) => value === tipoAgendamento)
    return found ? found.label : '--'
  }

  return (
    <div>
      <Row className='mt-4'>
        <Col md='3'>
          <Form.Group>
            <Form.Label>Data Inicial</Form.Label>
            <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
              <KeyboardDatePicker
                invalidDateMessage='Formato de data inválido'
                value={dateFilterDe}
                onChange={handleDateFilterDe}
                className='form-control'
                variant='inline'
                format='DD/MM/YYYY'
                maxDate={dateFilterAte || new Date('2100-01-01')}
                animateYearScrolling
                autoOk
                placeholder='De'
              />
            </MuiPickersUtilsProvider>
          </Form.Group>
        </Col>
        <Col md='3'>
          <Form.Group>
            <Form.Label>Data Final</Form.Label>
            <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
              <KeyboardDatePicker
                invalidDateMessage='Formato de data inválido'
                value={dateFilterAte}
                onChange={handleDateFilterAte}
                minDate={dateFilterDe || new Date('1900-01-01')}
                className='form-control'
                variant='inline'
                format='DD/MM/YYYY'
                animateYearScrolling
                autoOk
                placeholder='Até'
              />
            </MuiPickersUtilsProvider>
          </Form.Group>
        </Col>
      </Row>

      <Row className='mb-4 align-items-center'>
        <Col md='3'>
          <Form.Group>
            <Form.Label>Dentista</Form.Label>
            <Select
              placeholder='Selecione...'
              noOptionsMessage={() => 'Nenhuma opção disponível'}
              value={colaboradorSelecionado}
              options={colaboradores}
              onChange={handleChangeColaborador}
            />
          </Form.Group>
        </Col>
        <Col md='3'>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Select
              placeholder='Selecione...'
              noOptionsMessage={() => 'Nenhuma opção disponível'}
              value={statusSelecionado}
              options={[{ label: 'Todos', value: null }, ...status]}
              onChange={handleChangeStatus}
            />
          </Form.Group>
        </Col>
        <Col md='3'>
          <Form.Group>
            <Form.Label>Especialidade</Form.Label>
            <Select
              placeholder='Selecione...'
              noOptionsMessage={() => 'Nenhuma opção disponível'}
              value={especialidadeSelecionada}
              options={especialidades}
              onChange={handleChangeEspecialidade}
            />
          </Form.Group>
        </Col>
        <Col md='3'>
          <Button variant='outline-danger' className='mt-3' onClick={handleClear}>
            Limpar
          </Button>
        </Col>
      </Row>
      <h5 className='my-3 text-secondary font-weight-bold'>Histórico</h5>
      <Table className='bg-white' bordered hover>
        <thead>
          <tr className='text-center'>
            <th width='120px'>Data Agendamento</th>
            <th width='120px'>Tipo Agendamento</th>
            <th>Paciente</th>
            <th>Dentista</th>
            <th width='150px'>Status</th>
            <th width='120px'>Especialidade</th>
            <th>Atendente</th>
            <th>Observação</th>
          </tr>
        </thead>
        <tbody>
          {historicoAgendamentos?.length > 0 ? (
            historicoAgendamentos.map(({ tipoConsulta, data, colaborador, statusAgendamento, especialidade, nomePaciente, dependente, observacao, planoTratamento, planoTratamentoOrtodontia, atendente }, index) => (
              <tr className='text-center' key={index}>
                <td>{moment(data).format('DD/MM/YYYY')} {moment(data).format('HH:mm')}</td>
                <td>{getTipoAgendamentoLabel(tipoConsulta)}</td>
                <td>{(dependente?.nome || nomePaciente || '--').toUpperCase()}</td>
                <td>{colaborador?.nome || '--'}</td>
                <td>{getStatusLabel(statusAgendamento)}</td>
                <td>{especialidade?.nome || '--'} <br /> {planoTratamento?.numeroPlano || planoTratamentoOrtodontia?.numeroPlano || ''}</td>
                <td>{atendente?.nome || '--'}</td>
                <td>{observacao || '--'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colspan='5'>Nenhum histórico disponível</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

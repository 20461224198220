import React, { useState, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
import * as faceapi from 'face-api.js';
import './WebcamFaceDetection.css';
import faceComparisonService from '../../services/face-comparison.service';
import { convertBase64ToBlob } from '../../utils/anexo.utils';

const WebcamFaceDetection = ({ pacienteId, dependenteId, closeWebcamFaceDetection, cancelWebcamFaceDetection }) => {
  const [instructionText, setInstructionText] = useState("Inicializando...");
  const [approveForCapture, setApproveForCapture] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [messageError, setMessageError] = useState("Ocorreu uma falha, por favor tente novamente!");

  const webcamRef = useRef(null);

  useEffect(() => {
    const loadModels = async () => {
      if (modelsLoaded)
        return;

      await Promise.all([
        faceapi.nets.ssdMobilenetv1.loadFromUri('/models'),
        faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
        faceapi.nets.faceRecognitionNet.loadFromUri('/models')
      ]);

      setModelsLoaded(true);
    };
    loadModels();
  }, []);

  useEffect(() => {
    if (!modelsLoaded)
      return;

    const intervalId = setInterval(captureWithFaceDetection, 1500);
    return () => clearInterval(intervalId);
  }, [modelsLoaded, instructionText, approveForCapture, processing, error]);

  const captureWithFaceDetection = async () => {
    if (processing || error) return;
    if (!modelsLoaded) return;

    const imageSrc = webcamRef.current.getScreenshot();
    const img = await faceapi.fetchImage(imageSrc).catch(error => {
      setMessageError("Ocorreu uma falha ao inicializar a câmera, tente novamente!");
      setError(true);
      setProcessing(false);
    });

    const detections = await faceapi.detectAllFaces(img).withFaceLandmarks().withFaceDescriptors();
    const faceWithMinConfidence = detections.find(face => face.detection.score >= 0.5);

    if (detections.length < 1 && !faceWithMinConfidence)
      return setInstructionText("Por favor, centralize o olhar na câmera...");

    setApproveForCapture(true);
    setInstructionText("Fique nessa posição por até 3 segundos...");

    setTimeout(function () {
      if (!processing && approveForCapture) {
        setProcessing(true)
        handleSubmit(imageSrc);
      }
    }, 1000);
  };

  const handleSubmit = async (img) => {
    try {
      let formData = new FormData();
      formData.append("capturado", convertBase64ToBlob(img))

      if (pacienteId && !dependenteId) {
        faceComparisonService.matchPatient(pacienteId, formData).then(response => {
          closeWebcamFaceDetection();
          setProcessing(false);
        }).catch(error => {
          setMessageError("Paciente não encontrado para comparar reconhecimento Facial, por favor recarregue página e tente novamente.");

          if (error.response.status === 403)
            setMessageError("Paciente não é o mesmo do documento de identificação RG/CPF ou afins.");

          setError(true);
          setProcessing(false);
        })

        return;
      }

      if (dependenteId) {
        faceComparisonService.matchPatientByDependentId(dependenteId, formData).then(response => {
          closeWebcamFaceDetection();
          setProcessing(false);
        }).catch(error => {
          setMessageError("Paciente não encontrado para comparar reconhecimento Facial, por favor recarregue página e tente novamente.");

          if (error.response.status === 403)
            setMessageError("Paciente não é o mesmo do documento de identificação RG/CPF ou afins.");

          setError(true);
          setProcessing(false);
        })

        return;
      }

      setMessageError("Paciente não é o mesmo do documento de identificação RG/CPF ou afins.");
      setError(true);
      setProcessing(false);
      return;

    } catch (error) {
      console.error(error)
      setMessageError("Paciente não é o mesmo do documento de identificação RG/CPF ou afins.");
      setError(true);
      setProcessing(false);
      return;
    }
  }

  return (
    <>
      <div className='capture-container'>
        {!processing ?
          <>
            {!error ?
              <>
                <h3>Reconhecimento Facial</h3>
                <div className='capture-webcam' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Webcam
                    audio={false}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{ width: 285, height: 355 }}
                    ref={webcamRef}
                  />
                </div>
                <p>{instructionText}</p>
              </>
              :
              <>
                <h3>Reconhecimento Facial</h3>
                <h5>Não foi possível, tente novamente!</h5>
                <p>{messageError}</p>
                <a onClick={() => {
                  setError(false);
                  setMessageError("");
                  setProcessing(false);
                }}>Tente novamente</a>

                <a onClick={() => {
                  setError(false);
                  setMessageError("");
                  setProcessing(false);
                  cancelWebcamFaceDetection();
                }}>Fechar</a>
              </>
            }

          </>
          :
          <>
            <div className='loading-content'>
              <img src='/img/face-loading.gif' />
              <p>Estamos fazendo processamento, por favor aguarde...</p>
            </div>
          </>
        }
      </div>
    </>
  );
};

export default WebcamFaceDetection;

import React, { Fragment, useState, useEffect } from 'react'
import { Button, Form, Table } from 'react-bootstrap'
import moment from 'moment'
import Swal from 'sweetalert2'

import OrtodontiaService from '../../../services/ortodontia.service'
import CollapsePanel from '../../Common/CollapsePanel'
import { formatMoney } from '../../../utils/string.utils'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

export default function CancelarPagamento({
  firstStep,
  parcelasPagas,
  setIsLoading,
  refreshPlanosTratamentoOrtodontiaList,
  getPagamentosRealizados,
}) {
  const [stepIsOpen, setStepIsOpen] = useState({})
  const [parcelasPagasOrtodontia, setParcelasPagasOrtodontia] = useState([])
  const [parcelasPagasAparelho, setParcelasPagasAparelho] = useState([])
  const [parcelasPagasSuprimento, setParcelasPagasSuprimento] = useState([])
  const [parcelasPagasAparelhoComplementar, setParcelasPagasAparelhoComplementar] = useState([])
  const [selectedParcelas, setSelectedParcelas] = useState({})
  const [motivo, setMotivo] = useState('')

  useEffect(() => {
    if (parcelasPagas) {
      setParcelasPagasOrtodontia(parcelasPagas.parcelasPagasOrtodontia)
      setParcelasPagasAparelho(parcelasPagas.parcelasPagasAparelho)
      setParcelasPagasSuprimento(parcelasPagas.parcelasPagasSubrimento)
      setParcelasPagasAparelhoComplementar(parcelasPagas.parcelasPagasAparelhoComplementar)
    } else {
      setParcelasPagasOrtodontia([])
      setParcelasPagasAparelho([])
      setParcelasPagasSuprimento([])
      setParcelasPagasAparelhoComplementar([])
    }
  }, [parcelasPagas])

  const handleGoBack = () => {
    setStepIsOpen({})
    setParcelasPagasOrtodontia([])
    setParcelasPagasAparelho([])
    setParcelasPagasSuprimento([])
    setParcelasPagasAparelhoComplementar([])
    setSelectedParcelas([])
    setMotivo('')
    firstStep()
    refreshPlanosTratamentoOrtodontiaList()
    getPagamentosRealizados()
  }

  const handleChangeMotivo = (event) => {
    setMotivo(event.target.value)
  }

  const toggleIsOpen = (step) => {
    setStepIsOpen({
      ...stepIsOpen,
      [step]: !stepIsOpen[step],
    })
  }

  const getSumSelectedParcelas = () => {
    let sum = 0
    Object.values(selectedParcelas).forEach((parcelasGrupo) => {
      parcelasGrupo.forEach((parcela) => {
        sum += parcela.valorPago
      })
    })
    return sum
  }

  const getCountSelectedParcelas = () => {
    let sum = 0
    Object.values(selectedParcelas).forEach((parcelasGrupo) => {
      parcelasGrupo.forEach(() => {
        sum += 1
      })
    })
    return sum
  }

  const getSelectedParcelas = () => {
    let list = []
    Object.values(selectedParcelas).forEach((parcelasGrupo) => {
      parcelasGrupo.forEach((parcela) => {
        list.push(parcela)
      })
    })
    return list
  }

  const handleEfetuarCancelamento = async () => {
    const qntdParcelas = getCountSelectedParcelas() === 1 ? '1 parcela' : `${getCountSelectedParcelas()} parcelas`
    const { value: senha } = await Swal.fire({
      title: `Deseja confirmar o cancelamento de ${qntdParcelas} pagamentos no valor total de ${formatMoney(
        getSumSelectedParcelas()
      )}?`,
      type: 'question',
      input: 'password',
      inputPlaceholder: 'Digite sua senha',
      inputAttributes: { autocapitalize: 'off', autocorrect: 'off' },
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#3085d6',
    })
    if (senha) {
      setIsLoading(true)
      try {
        await OrtodontiaService.cancelarPagamentosEfetuados(
          getSelectedParcelas().map((parc) => ({
            ...parc,
            motivoCancelamento: motivo,
          })),
          senha
        )
        toast('Parcelas canceladas com sucesso!', { type: toast.TYPE.SUCCESS })
        handleGoBack()
      } catch (e) {
        console.error(e)
      }
      setIsLoading(false)
    }
  }

  const handleSelectParcela = (parcela, grupo, indexGeral, parcelasPagas) => {
    const novasParcelas = { ...selectedParcelas }
    let parcelasGrupo = []
    if (!!novasParcelas[grupo]) {
      parcelasGrupo = [...novasParcelas[grupo]]
    } else {
      novasParcelas[grupo] = []
    }
    const indiceParcela = parcelasGrupo.indexOf(parcela)
    if (indiceParcela >= 0) {
      parcelasGrupo = parcelasGrupo.slice(indiceParcela + 1)
    } else {
      parcelasGrupo = parcelasPagas.slice(indexGeral)
    }
    novasParcelas[grupo] = parcelasGrupo
    setSelectedParcelas({ ...novasParcelas })
  }

  const renderParcelasPagas = (parcelasPagas, collapseText) => (
    <Fragment>
      <CollapsePanel
        stepCode={collapseText}
        text={collapseText}
        isOpen={!!stepIsOpen[collapseText]}
        toggleIsOpen={toggleIsOpen}
      >
        {parcelasPagas.length > 0 ? (
          <Table bordered hover size='sm'>
            <thead>
              <tr>
                <th>Colaborador</th>
                <th width='180px' className='text-center'>
                  Data Vencimento
                </th>
                <th width='180px' className='text-center'>
                  Data Pagamento
                </th>
                <th width='150px' className='text-center'>
                  Valor Pago
                </th>
                <th width='100px' className='text-center'>
                  Selecionar
                </th>
              </tr>
            </thead>
            <tbody>
              {parcelasPagas.map((parcela, index) => {
                const {
                  planoTratamentoOrtodontiaPagamentoParcelaId: planoId,
                  planoTratamentoPagamentoAparelhoParcelaId: aparelhoId,
                  planoTratamentoPagamentoAparelhoComplementarParcelaId: complementarId,
                  planoTratamentoPagamentoSuprimentoParcelaId: suprimentoId,
                } = parcela
                const id = planoId || aparelhoId || complementarId || suprimentoId
                const isChecked =
                  selectedParcelas[collapseText] &&
                  selectedParcelas[collapseText].some(
                    (parc) =>
                      parc.planoTratamentoOrtodontiaPagamentoParcelaId === id ||
                      parc.planoTratamentoPagamentoAparelhoParcelaId === id ||
                      parc.planoTratamentoPagamentoAparelhoComplementarParcelaId === id ||
                      parc.planoTratamentoPagamentoSuprimentoParcelaId === id
                  )
                return (
                  <tr key={index}>
                    <td>{parcela.colaboradorResponsavel}</td>
                    <td className='text-center'>{moment(parcela.dataVencimento).format('DD/MM/YYYY')}</td>
                    <td className='text-center'>{moment(parcela.dataPagamento).format('DD/MM/YYYY')}</td>
                    <td className='text-center'>{formatMoney(parcela.valorPago)}</td>
                    <td className='text-center'>
                      <label className='checkbox labeless m-0'>
                        <input
                          type='checkbox'
                          checked={isChecked}
                          onChange={() => handleSelectParcela(parcela, collapseText, index, parcelasPagas)}
                        />
                        <span />
                      </label>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        ) : (
          <p>Nenhuma parcela com pagamento efetuado.</p>
        )}
      </CollapsePanel>
    </Fragment>
  )

  return (
    <div>
      <div style={backContainerStyle} onClick={handleGoBack}>
        <Button variant='outline-primary' style={backStyle}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <span className='text-primary'>Voltar</span>
      </div>
      {renderParcelasPagas(parcelasPagasOrtodontia, 'Plano Ortodôntico')}
      {renderParcelasPagas(parcelasPagasAparelho, 'Aparelho')}
      {renderParcelasPagas(parcelasPagasSuprimento, 'Suprimentos de Manutenção')}
      {renderParcelasPagas(parcelasPagasAparelhoComplementar, 'Aparelhos Complementares')}
      <div className='d-flex justify-content-between align-items-center pb-5 pt-3'>
        <h1 className='h4 m-0 text-secondary font-weight-bold'>Total: {formatMoney(getSumSelectedParcelas())}</h1>
        <Form.Control
          placeholder='Motivo do cancelamento'
          className='w-50'
          type='text'
          value={motivo}
          onChange={handleChangeMotivo}
          required
        />
        <Button
          variant='primary'
          disabled={getSelectedParcelas().length === 0 || !motivo}
          onClick={handleEfetuarCancelamento}
        >
          Efetuar Cancelamento
        </Button>
      </div>
    </div>
  )
}

const backContainerStyle = {
  cursor: 'pointer',
  marginBottom: '2em',
}

const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

import { Notificacoes } from "../types";

const initialState = {
  notifyType: "bg-success",
  notify: false,
  notifyFeedback: []
};

export const notificacoesReducer = (state = initialState, action) => {
  switch (action.type) {
    case Notificacoes.ERRO:
      return {
        ...state
        // notifyType: "bg-danger",
        // notify: action.mensagens.length > 0,
        // notifyFeedback: action.mensagens
      };
    case Notificacoes.SUCESSO:
      return {
        ...state
        // notifyType: "bg-success",
        // notify: action.mensagens.length > 0,
        // notifyFeedback: action.mensagens
      };
    default:
      return state;
  }
};

import React, { Component } from 'react'
import { Card } from 'react-bootstrap'
import PropTypes from 'prop-types'

class CardColaborador extends Component {
  render() {
    const { onClick, colaborador } = this.props

    return (
      <div className='shadow rounded border p-4 cardColaborador' onClick={onClick}>
        <i className='fa fa-user-circle fa-3x mr-4' />
        <h4 className='h5 m-0 text-center font-weight-bold'>{colaborador.nome}</h4>
      </div>
    )
  }
}

CardColaborador.propTypes = {
  onClick: PropTypes.func.isRequired,
  colaborador: PropTypes.object.isRequired,
}

export default CardColaborador

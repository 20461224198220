import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Card, Button, Form, Modal, Spinner } from 'react-bootstrap'
import MaskedInput from 'react-text-mask'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { isValidCpf } from '@brazilian-utils/is-valid-cpf'
import { isValidCnpj } from '@brazilian-utils/is-valid-cnpj'

import { cnpjMask, cpfMask } from '../../utils/mask.utils'
import { Telefones, Enderecos, ConfiguracaoHorario, FileUpload } from './../Common'
import SharedService from './../../services/shared.service'
import FranquiasService from './../../services/franquias.service'
import FranquiaResponsavel from './FranquiaResponsavel';
import SystemConstants from '../../constants/system.constants'

const EMPTY_TELEFONE = { numero: '' }
const EMPTY_EMAIL = { endereco: '' }

class FranquiasForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      status: 0,
      cnpj: '',
      emails: [EMPTY_EMAIL],
      razaoSocial: '',
      nomeFantasia: '',
      croClinica: '',
      nomeResponsavelTecnico: '',
      croResponsavelTecnico: '',
      observacao: '',
      responsavel: {},
      telefones: [{}],
      endereco: {},
      invalidCNPJ: false,
      toggleModal: false,
      idItemDelete: '',
      indexItemDelete: '',
      typeItemDelete: '',
      messageItemDelete: '',
      nomeArquivoContrato: '',
      contrato: '',
      idFranquiaEdit: this.props.location.id ? this.props.location.id : '',
      invalidCPF: false,
    }
    this.formDOM = React.createRef()
    this.closeModal = this.closeModal.bind(this)
  }

  /**
   * Inicialida variaveis e franquia do componente
   */
  async componentDidMount() {
    if (this.state.idFranquiaEdit) {
      const { data } = await FranquiasService.getFranquia(this.state.idFranquiaEdit)
      this.setState({ ...data, emails: data.emails.length > 0 ? data.emails : [EMPTY_EMAIL], responsavel: data.responsavel ?? {} })
      this.configuracao.setConfiguration(data.horariosFuncionamento)
    }
  }

  /**
   * Modifica o estado do componente ao mudar um input
   */
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onChangeEmail = (e) => {
    const emails = [...this.state.emails]
    emails[0] = { ...emails[0], endereco: e.target.value }
    this.setState({ ...this.state, emails })
  }

  onBlurCPF = (e) => {
    this.onChange(e)
    this.setState({ invalidCPF: !isValidCpf(e.target.value) && e.target.value.length > 0 })
  }

  /**
   * Valida CNPJ no evento de blur
   */
  onBlurCNPJ = (e) => {
    this.onChange(e)
    this.setState({ invalidCNPJ: !isValidCnpj(e.target.value) })
  }

  /**
   * Valida e executa acoes (adicao ou update)
   */
  onSubmit = (e) => {
    e.preventDefault()
    const { invalidCNPJ, invalidCPF } = this.state
    if (!invalidCNPJ && !invalidCPF) {
      this.setState({ invalidCNPJ: false })
      const franquia = this.getFranquia()
      this.save(franquia)
    }
  }

  setEndereco = (index, endereco) => {
    this.setState({ endereco })
  }

  setResponsavel = (index, responsavel) => {
    this.setState({ responsavel });

    if (responsavel.id)
      this.setState({ responsavelId: responsavel.id })
    else
      this.setState({ responsavelId: null })
  }

  /**
   * Retorna objeto a ser enviado na requisicao
   */
  getFranquia() {
    let endereco = this.state.endereco
    if (!endereco.id) {
      delete endereco.id
    }

    let franquia = {
      ...this.state,
      endereco: endereco,
      horariosFuncionamento: this.configuracao.getConfiguracaoHorario(),
    }
    if (this.state.idFranquiaEdit) {
      franquia.id = this.state.idFranquiaEdit
    }

    return franquia
  }

  /**
   * Uasado para adicionar uma nova franquia
   * @param {*} franquia
   */
  save(franquia) {
    this.setState({ ...this.state, isLoading: true }, async () => {
      try {
        await FranquiasService.salvarFranquia(franquia, this.state.contrato)
        toast('Informações salvas com sucesso', { type: toast.TYPE.SUCCESS })
        this.props.history.push('/franquias')
      } catch (e) {
        console.error(e)
      } finally {
        this.setState({ ...this.state, isLoading: false })
      }
    })
  }

  /**
   * Adiciona telefone
   */
  addTelefone = () => {
    let telefones = this.state.telefones || []
    telefones.push({})
    this.setState({
      telefones,
    })
  }

  removeTelefone = (index) => {
    let telefones = this.state.telefones
    telefones.splice(index, 1)
    this.setState({
      telefones,
    })
  }

  setTelefone = (index, telefone) => {
    const telefones = [...this.state.telefones]
    telefones[index] = telefone
    this.setState({ telefones })
  }
  /**
   * Mostra/abre modal de confirmacao
   * @param {*} id
   * @param {*} index
   * @param {*} type
   * @param {*} message
   */
  openModal(id, index, type, message) {
    this.setState({
      toggleModal: true,
      idItemDelete: id,
      indexItemDelete: index,
      typeItemDelete: type,
      messageItemDelete: message,
    })
  }

  /**
   * Fecha modal de confirmacao
   */
  closeModal() {
    this.setState({ toggleModal: false })
  }

  deleteItem = (id, index, type) => {
    let data = JSON.stringify({
      item: type,
      id: id,
    })
    SharedService.deletarItem(data).then(
      function (response) {
        if (type === 'Telefone') {
          this.removeTelefone(index)
          if (this.state.telefones.length === 0) {
            this.addTelefone(index)
          }
        }

        toast(response.data, { type: toast.TYPE.SUCCESS })
        this.closeModal()
      }.bind(this)
    )
  }

  setContrato = (contrato) => {
    this.setState({ contrato })
  }

  insertEmptyPhone = () => {
    this.setState({ ...this.state, telefones: [EMPTY_TELEFONE] })
  }

  handleRemoveFile = () => {
    this.setState({ ...this.state, nomeArquivoContrato: '' })
  }

  handleDownloadFile = async () => {
    SharedService.downloadArquivo(this.state.nomeArquivoContrato)
  }

  /**
   * Renderiza componentes para adicao de telefones
   * @param {*} index
   * @param {*} telefone
   */
  renderTelefone(index, telefone) {
    return (
      <Row key={index} className='mb-3'>
        <Col>
          <Telefones index={index} telefone={telefone} required={false} onChange={this.setTelefone} checkWhatsapp={true} />
        </Col>
        <Col className='pt-3'>
          {this.state.idFranquiaEdit && telefone.ddd && telefone.numero && (
            <Button
              onClick={() => this.openModal(telefone.idFone, index, 'Telefone', 'este telefone')}
              className='btn-delete-field'
              variant='danger'
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )}
          {index === this.state.telefones.length - 1 && (
            <>
              {index > 0 && !telefone.ddd && !telefone.numero && (
                <Button onClick={() => this.removeTelefone(index)} className='fix-btn-margin mr-2' variant='primary'>
                  <FontAwesomeIcon icon={faMinus} />
                </Button>
              )}
              <Button onClick={this.addTelefone} className='fix-btn-margin' variant='primary'>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </>
          )}
        </Col>
      </Row>
    )
  }

  /**
   * Renderiza componente
   */
  render() {
    const { nomeArquivoContrato, emails } = this.state
    const nomeArquivo = nomeArquivoContrato
      ? nomeArquivoContrato.substring(1 + nomeArquivoContrato.indexOf('_'))
      : 'arquivo'
    return (
      <>
        <Row className='mb-3'>
          <Col>
            <h1 className='h3 m-0 text-secondary font-weight-bold'>Franquias</h1>
          </Col>
        </Row>
        <Card className='p-4'>


          <Form ref={this.formDOM} onSubmit={this.onSubmit}>
            <Row>
              <Col md='8'>

                <Row>
                  <Col>
                    <Form.Group className='required'>
                      <Form.Label htmlFor='cnpj'>CNPJ:</Form.Label>
                      <MaskedInput
                        mask={cnpjMask}
                        className={this.state.invalidCNPJ ? 'form-control is-invalid' : 'form-control'}
                        id='cnpj'
                        name='cnpj'
                        value={this.state.cnpj}
                        onChange={this.onChange}
                        onBlur={this.onBlurCNPJ}
                        required
                        
                      />
                      {this.state.invalidCNPJ && <div className='invalid-feedback'>Digite um CNPJ válido.</div>}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='required'>
                      <Form.Label htmlFor='razaoSocial'>E-mail da Clínica:</Form.Label>
                      <Form.Control
                        type='email'
                        id='email'
                        name='email'
                        value={emails[0].endereco}
                        onChange={this.onChangeEmail}
                        maxLength={100}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className='required'>
                      <Form.Label htmlFor='razaoSocial'>Razão Social:</Form.Label>
                      <Form.Control
                        type='text'
                        id='razaoSocial'
                        name='razaoSocial'
                        value={this.state.razaoSocial}
                        onChange={this.onChange}
                        required
                        maxLength={100}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor='nomeFantasia'>Nome Fantasia:</Form.Label>
                      <Form.Control
                        type='text'
                        id='nomeFantasia'
                        name='nomeFantasia'
                        value={this.state.nomeFantasia}
                        onChange={this.onChange}
                        maxLength={100}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor='croClinica'>CRO da Clínica:</Form.Label>
                      <Form.Control
                        type='text'
                        id='croClinica'
                        name='croClinica'
                        value={this.state.croClinica}
                        onChange={this.onChange}
                        maxLength={100}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  
                    <Col>
                      <Form.Group>
                        <Form.Label htmlFor='nomeResponsavelTecnico'>Nome do Responsável Técnico:</Form.Label>
                        <Form.Control
                          type='text'
                          id='nomeResponsavelTecnico'
                          name='nomeResponsavelTecnico'
                          value={this.state.nomeResponsavelTecnico}
                          onChange={this.onChange}
                          maxLength={100}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label htmlFor='CROResponsavelTecnico'>CRO do Responsável Técnico:</Form.Label>
                        <Form.Control
                          type='text'
                          id='croResponsavelTecnico'
                          name='croResponsavelTecnico'
                          value={this.state.croResponsavelTecnico}
                          onChange={this.onChange}
                          maxLength={100}
                        />
                      </Form.Group>
                    </Col>
                </Row>
                <Row>
                  <Col md='12'>
                    <Form.Group>
                      <Form.Label htmlFor='observacao'>Observação:</Form.Label>
                      <Form.Control
                        as='textarea'
                        id='observacao'
                        name='observacao'
                        value={this.state.observacao}
                        onChange={this.onChange}
                        rows='3'
                        maxLength='250'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {nomeArquivoContrato ? (
                      <div className='d-flex'>
                        <Button className='mr-2' variant='outline-danger' onClick={this.handleRemoveFile}>
                          {`Remover ${nomeArquivo}`}
                        </Button>
                        <Button variant='primary' onClick={this.handleDownloadFile}>
                          {`Baixar ${nomeArquivo}`}
                        </Button>
                      </div>
                    ) : (
                        <FileUpload label='Carregar contrato' onChange={this.setContrato} />
                      )}
                  </Col>
                </Row>

                <Row className='mb-2 mt-4'>
                  <Col>
                    <h2 className='h5 border-bottom pb-3'>Telefones</h2>
                  </Col>
                </Row>
                {this.state.telefones && this.state.telefones.length > 0
                  ? this.state.telefones.map((telefone, index) => this.renderTelefone(index, telefone))
                  : this.insertEmptyPhone()}
                <Row className='mb-2 mt-4'>
                  <Col>
                    <h2 className='h5 border-bottom pb-3'>Endereço</h2>
                  </Col>
                </Row>

                <Enderecos endereco={this.state.endereco || {}} onChange={this.setEndereco} />

                <Row className='mb-2 mt-4'>
                  <Col>
                    <h2 className='h5 border-bottom pb-3'>Horários de Atendimento</h2>
                  </Col>
                </Row>

                <ConfiguracaoHorario
                  ref={(el) => {
                    this.configuracao = el
                  }}
                />

                {this.state.idFranquiaEdit ? (
                  <Button type='submit' variant='primary' disabled={this.state.isLoading} className='float-right'>
                    {!this.state.isLoading ? 'Atualizar' : <Spinner animation='border' size='sm' variant='light' />}
                  </Button>
                ) : (
                    <Button type='submit' variant='primary' disabled={this.state.isLoading} className='float-right'>
                      {!this.state.isLoading ? 'Cadastrar' : <Spinner animation='border' size='sm' variant='light' />}
                    </Button>
                  )}
                <Link to='/franquias'>
                  <Button variant='light' className='float-right mr-3'>
                    Cancelar
              </Button>
                </Link>

              </Col>
              <Col md='4'>
                <FranquiaResponsavel responsavel={this.state.responsavel} onchange={this.setResponsavel} franquiaId={this.state.idFranquiaEdit}/>
              </Col>
            </Row>
          </Form>

        </Card>

        <Modal show={this.state.toggleModal} onHide={this.closeModal}>
          <Modal.Body>Deseja excluir {this.state.messageItemDelete}?</Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeModal} variant='light'>
              Cancelar
            </Button>
            <Button
              onClick={() =>
                this.deleteItem(this.state.idItemDelete, this.state.indexItemDelete, this.state.typeItemDelete)
              }
              variant='danger'
            >
              Excluir
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default FranquiasForm

import React, { useState, useEffect, Fragment } from 'react'
import { format } from 'date-fns'
import Select from 'react-select'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { formatMoney, handleDecimalConvert } from '../../utils/string.utils'
import RelatorioService from '../../services/relatorio.service'
import PageLoading from '../Common/PageLoading'
import { Col, Row, Table, Button, Spinner, Card, Form } from 'react-bootstrap'
import Paginator from '../Common/Paginator'
import { statusPlanoTratamento, statusPlano } from '../../constants/common.constants'
import CSVDownload from './CSVDownload'
import ColaboradoresService from '../../services/colaboradores.service'
import MomentUtils from '@date-io/moment'

export default function Cancelamentos({ selectedRelatorio }) {
  const itensPorPagina = 20

  const [isLoading, setLoading] = useState(false)
  const [isLoadingCSV, setLoadingCSV] = useState(false)
  const [itensLista, setItensLista] = useState([])
  const [cabecalhoRelatorio, setCabecalhoRelatorio] = useState([])
  const [feedbackBusca, setFeedbackBusca] = useState('')
  const [pagina, setPagina] = useState(0)
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [itensCSV, setItensCSV] = useState([])
  const [downloadCSV, setDownloadCSV] = useState(false)
  const [selectedEspecialidade, setSelectedEspecialidade] = useState(null)
  const [especialidadeOptions, setEspecialidadeOptions] = useState([])
  const [filterLoading, setFilterLoading] = useState(false)
  const [dateFilterDe, setDateFilterDe] = useState(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1))
  const [dateFilterAte, setDateFilterAte] = useState(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0))
  const [resumo, setResumo] = useState([])
  const [statusTratamentoOptions, setStatusTratamentoOptions] = useState([])
  const [selectedStatusTratamento, setSelectStatusTratamento] = useState()
  const [dentista, setDentista] = useState('')
  const [atendente, setAtendente] = useState('')
  const [colaboradoresOptions, setColaboradoresOptions] = useState([])
  const [selectedColaborador, setSelectedColaborador] = useState(null)
  const [atendentesOptions, setAtendentesOptions] = useState([])
  const [selectedAtendente, setSelectedAtendente] = useState(null)


  useEffect(() => {
    didMount()
    setCabecalhoRelatorio([
      'Nome',
      'Nº Plano',
      'Status do Tratamento',
      'Dentista',
      'Atendente',
      'Data Cancelamento',
      'Valor do Contrato',
      'Total Pago',
      'Total Realizado',
      'Total Cancelado'
    ])
  }, [])

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina])

  useEffect(() => {
    if (downloadCSV) {
      setTimeout(() => {
        setDownloadCSV(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itensCSV, downloadCSV])

  const didMount = async () => {
    setFilterLoading(true)

    const dataDentistas = await ColaboradoresService.getDentistas(false)
    const dentistas = [{ label: 'Todos', value: '00000000-0000-0000-0000-0000000000', especialidadeColaborador: '', ativo: true}]
    dataDentistas.map((d) => dentistas.push({
      label: d.nome,
      value: d.id,
      especialidadeColaborador: d.especialidadeColaborador,
      ativo: d.ativo
    }))

    setColaboradoresOptions(
      dentistas.map((d) => ({
        label: d.label,
        value: d.value,
        especialidades: d.especialidadeColaborador,
        ativo: d.ativo,
      }))
    )
    
    setSelectedColaborador({ label: 'Todos', value: '00000000-0000-0000-0000-0000000000', especialidadeColaborador: '', ativo: true})

    const dataAtendentes = await ColaboradoresService.getAllRecepcionistas()
    const atendentes = [{ label: 'Todos', value: '00000000-0000-0000-0000-0000000000', especialidadeColaborador: '', ativo: true}]
    dataAtendentes.data.map((d) => atendentes.push({
      label: d.nome,
      value: d.id,
      especialidadeColaborador: d.especialidadeColaborador,
      ativo: d.ativo
    }))

    setAtendentesOptions(
      atendentes.map((d) => ({
        label: d.label,
        value: d.value,
        especialidades: d.especialidadeColaborador,
        ativo: d.ativo,
      }))
    )

    setSelectedAtendente({ label: 'Todos', value: '00000000-0000-0000-0000-0000000000', especialidadeColaborador: '', ativo: true})

    const { data } = await ColaboradoresService.getAllEspecialidades()
    const especialidades = [{ label: 'Todos', value: '00000000-0000-0000-0000-0000000000'}]
    
    data.map(({ nome, id }) => especialidades.push({
      label: nome,
      value: id,
    }))

    setEspecialidadeOptions(especialidades)
    setSelectedEspecialidade({ label: 'Todos', value: '00000000-0000-0000-0000-0000000000'})

    setStatusTratamentoOptions(
      [
        { value: -1, label: 'Todos' },
        { value: 4, label: 'Cancelado pelo Paciente' },
        { value: 6, label: 'Abandono de Tratamento' }
      ])

    setSelectStatusTratamento({ value: -1, label: 'Todos' })

    handleSearch()
    setFilterLoading(false)
  }

  const getValuesLista = async () => {
    const totalDaLista = itensPorPagina * totalPaginas
    if (totalDaLista === 0) {
      return
    }
    const filtros = []
    if (selectedEspecialidade) {
      filtros.push({ campo: 'especialidadeId', valor: selectedEspecialidade.value })
    }
    if (dateFilterDe) {
      filtros.push({ campo: 'dataInicial', valor: dateFilterDe.toISOString() })
    }
    if (dateFilterAte) {
      filtros.push({ campo: 'dataFinal', valor: dateFilterAte.toISOString() })
    }
    if (selectedStatusTratamento) {
      filtros.push({ campo: 'statusTratamento', valor: selectedStatusTratamento.value })
    }
    if (selectedColaborador) {
      filtros.push({ campo: 'responsavelAvaliacao', valor: selectedColaborador.value })
    }
    if (selectedAtendente) {
      filtros.push({ campo: 'responsavelEfetivacaoPlanoTratamento', valor: selectedAtendente.value })
    }
    setLoadingCSV(true)
    const { lista, resumo } = await RelatorioService.getPlanosCancelados(filtros, 1, totalDaLista)

    const valores = []
    lista.dados.forEach((item) => {
      const statusPlano = statusPlanoTratamento.find((s) => s.value === item.statusTratamento)
      const itemRelatorio = new Object()
      
      itemRelatorio.nome = item.paciente
      itemRelatorio.numeroPlano = item.numeroPlano
      statusPlano ? (itemRelatorio.statusTratamento = statusPlano.label) : (itemRelatorio.statusTratamento = '-')
      itemRelatorio.responsavelAvaliacao = item.responsavelAvaliacao
      itemRelatorio.responsavelEfetivacaoPlanoTratamento = item.responsavelEfetivacaoPlanoTratamento
      itemRelatorio.dataCancelamento = item.dataCancelamento ? format(new Date(item.dataCancelamento), 'dd/MM/yyyy') : '-'
      itemRelatorio.valorContrato = formatMoney(item.valorContrato)
      itemRelatorio.valorPago = formatMoney(item.valorPago)
      itemRelatorio.valorRealizado = formatMoney(item.valorRealizado)
      itemRelatorio.valorCancelado = formatMoney(item.valorCancelado)
      valores.push(Object.values(itemRelatorio))
    })
    setItensCSV(valores)
    setDownloadCSV(true)
    setLoadingCSV(false)
    return {
      data: valores,
      headers: cabecalhoRelatorio,
      filename: `${selectedRelatorio.label} - ${format(new Date(), 'dd-MM-yyyy')}.csv`,
    }
  }

  const handleSearch = async () => {
    setLoading(true)
    try {
      const filtros = []
      if (selectedEspecialidade) {
        filtros.push({ campo: 'especialidadeId', valor: selectedEspecialidade.value })
      }
      if (dateFilterDe) {
        filtros.push({ campo: 'dataInicial', valor: dateFilterDe.toISOString() })
      }
      if (dateFilterAte) {
        filtros.push({ campo: 'dataFinal', valor: dateFilterAte.toISOString() })
      }
      if (selectedStatusTratamento) {
        filtros.push({ campo: 'statusTratamento', valor: selectedStatusTratamento.value })
      }
      if (selectedColaborador) {
        filtros.push({ campo: 'responsavelAvaliacao', valor: selectedColaborador.value })
      }
      if (selectedAtendente) {
        filtros.push({ campo: 'responsavelEfetivacaoPlanoTratamento', valor: selectedAtendente.value })
      }



      setFeedbackBusca('')
      const { lista, resumo } = await RelatorioService.getPlanosCancelados(
        filtros,
        pagina + 1,
        itensPorPagina
      )
      setItensLista(lista.dados)
      setResumo(resumo)
      setTotalPaginas(lista.totalPaginas)
      if (lista.dados.length === 0) {
        setFeedbackBusca('Nenhum registro encontrado')
      }

      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleClear = () => {
    setSelectedEspecialidade({ label: 'Todos', value: '00000000-0000-0000-0000-0000000000'})
    setDateFilterDe(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1))
    setDateFilterAte(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0))
    setSelectStatusTratamento({ value: -1, label: 'Todos' })
    setSelectedColaborador({ label: 'Todos', value: '00000000-0000-0000-0000-0000000000', especialidadeColaborador: '', ativo: true})
    setSelectedAtendente({ label: 'Todos', value: '00000000-0000-0000-0000-0000000000', especialidadeColaborador: '', ativo: true})
    setAtendente('')
    setPagina(0)
  }

  const handleDateFilterDe = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterDe(filter)
    } else {
      setDateFilterDe(null)
    }
    setPagina(0)
  }

  const handleDateFilterAte = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterAte(filter)
    } else {
      setDateFilterAte(null)
    }
    setPagina(0)
  }

  const handleChangeEspecialidade = (option) => {
    setSelectedEspecialidade(option)
    setPagina(0)
  }

  const handleChangeStatusTratamento = (option) => {
    setSelectStatusTratamento(option)
    setPagina(0)
  }
  
  const handleChangeColaborador = (option) => {
    setSelectedColaborador(option)
    setPagina(0)
  }

  const handleChangeAtendente = (option) => {
    setSelectedAtendente(option)
    setPagina(0)
  }

  const handlePagina = (data) => setPagina(data.selected)

  const renderItens = () =>
    itensLista.length > 0 ? (
      <Fragment>
        <div className='mt-4 d-flex justify-content-end align-items-center'>
          <CSVDownload asyncExportMethod={getValuesLista}>
            <Button variant='outline-secondary' className='mb-3'>
              {isLoadingCSV ? <Spinner animation='border' size='sm' variant='light' /> : 'Gerar CSV'}
            </Button>
          </CSVDownload>
        </div>
        <Card className='mb-4 py-2'>
            <Row>
              {resumo &&
                resumo.map(({ descricao, valor }, index) => (
                  <Col key={index} className='d-flex align-items-center justify-content-center flex-column'>
                    <small className='text-center text-muted m-0'>
                      {!!descricao ? descricao.toUpperCase() : '-'}
                    </small>
                    <h4 className='text-center m-0 font-weight-bold'>
                      {!!valor ? formatMoney(valor) : formatMoney(0)}
                    </h4>
                  </Col>
                ))}
            </Row>
          </Card>
        <Table hover size='sm' className='border rounded-0 shadow-sm' style={{backgroundColor:'white'}}>
          <thead>
            <tr>
              <th width='15%'>Nome</th>
              <th width='6%' className='text-center'>
                Nº Plano
              </th>
              <th width='10%' className='text-center'>
                Status do Tratamento
              </th>
              <th width='13%' className='text-center'>
                Dentista
              </th>
              <th width='13%' className='text-center'>
                Atendente
              </th>
              <th width='9%' className='text-center'>
                Data Cancelamento
              </th>
              <th width='6%' className='text-center'>
                Valor do Contrato
              </th>
              <th width='6%' className='text-center'>
                Total Pago
              </th>
              <th width='6%' className='text-center'>
                Total Realizado
              </th>
              <th width='6%' className='text-center'>
                Total Cancelado 
              </th>
            </tr>
          </thead>
          <tbody>
            {itensLista.map((item, i) => {
              const {
                paciente,
                nomeDependente,
                numeroPlano,
                statusTratamento,
                responsavelAvaliacao,
                responsavelEfetivacaoPlanoTratamento,
                valorContrato,
                valorPago,
                valorRealizado,
                valorCancelado,
                dataCancelamento
              } = item
              const statusPlano = statusPlanoTratamento.find((s) => s.value === statusTratamento)
              return (
                <tr key={i} style={{ fontSize: 'smaller' }}>
                  <td>{(paciente || nomeDependente).toUpperCase()}</td>
                  <td className='text-center'>{numeroPlano || '-'}</td>
                  <td className='text-center'>{statusPlano ? statusPlano.label : '-'}</td>
                  <td className='text-center'>{(responsavelAvaliacao ||  '-').toUpperCase()}</td>
                  <td className='text-center'>{(responsavelEfetivacaoPlanoTratamento || '-').toUpperCase()}</td>
                  <td className='text-center'>{(dataCancelamento ? format(new Date(dataCancelamento), 'dd/MM/yyyy') : '-')}</td>
                  <td className='text-center'>{formatMoney(valorContrato)}</td>
                  <td className='text-center'>{formatMoney(valorPago)}</td>
                  <td className='text-center'>{formatMoney(valorRealizado)}</td>
                  <td className='text-center'>{formatMoney(valorCancelado)}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {totalPaginas > 1 && <Paginator handlePagina={handlePagina} pageCount={totalPaginas} paginaAtual={pagina} />}
      </Fragment>
    ) : (
      <div className='mt-4'>{feedbackBusca}</div>
    )

  const renderLoading = () => <PageLoading />

  return (
    <Fragment>
      <Row style={rowMargin}>
        <Col md='3'>
          <label>De:</label>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              invalidDateMessage='Formato de data inválido'
              value={dateFilterDe}
              onChange={handleDateFilterDe}
              className='form-control'
              variant='inline'
              format='DD/MM/YYYY'
              maxDate={dateFilterAte || new Date('2100-01-01')}
              animateYearScrolling
              autoOk
              placeholder='De'
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col md='3'>
          <label>Até:</label>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              invalidDateMessage='Formato de data inválido'
              value={dateFilterAte}
              onChange={handleDateFilterAte}
              minDate={dateFilterDe || new Date('1900-01-01')}
              className='form-control mx-1'
              variant='inline'
              format='DD/MM/YYYY'
              animateYearScrolling
              autoOk
              placeholder='Até'
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col md='3'>
        <label>Especialidade:</label>
          <Select
            placeholder='Especialidade'
            className='w-100 mr-1'
            onChange={handleChangeEspecialidade}
            value={selectedEspecialidade}
            options={especialidadeOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col md='3'>
          <label>Dentista:</label>
          <Select
            placeholder='dentista'
            className='w-100 mr-1'
            onChange={handleChangeColaborador}
            value={selectedColaborador}
            styles={customStyles}
            options={colaboradoresOptions}
          />
        </Col>
        <Col md='3'>
        <label>Atendente:</label>
        <Select
            placeholder='atendente'
            className='w-100 mr-1'
            onChange={handleChangeAtendente}
            value={selectedAtendente}
            styles={customStyles}
            options={atendentesOptions}
          />
        </Col>
        <Col md='3'>
          <label>Status do Tratamento:</label>
          <Select
            placeholder='Status do Tratamento'
            className='w-100 mr-1'
            onChange={handleChangeStatusTratamento}
            value={selectedStatusTratamento}
            options={statusTratamentoOptions}
          />
        </Col>
        <Col>
          {filterLoading && <Spinner animation='border' variant='primary' size='sm' style={spinnerStyle} />}
          <Button disabled={filterLoading} onClick={handleClear} variant='outline-danger' className='mr-2' style={buttonClearAlign}>
            Limpar
          </Button>
          <Button variant='primary' style={buttonSearchAlign} onClick={handleSearch}>
              {isLoadingCSV ? <Spinner animation='border' size='sm' variant='light' /> : 'Pesquisar'}
          </Button>
        </Col>
      </Row>
      {isLoading ? renderLoading() : renderItens()}
    </Fragment>
  )
}

const spinnerStyle = {
  position: 'absolute',
  top: '10px',
  right: '50px',
}

const rowMargin = {
  marginBottom: '20px'
}

const buttonClearAlign = {
  position: 'absolute',
  bottom: '0',
  height: '38px'
}
const buttonSearchAlign = {
  position: 'absolute',
  bottom: '0',
  left: '100px',
  height: '38px'
}

const textFilter = {
  height: '38px'
}

const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const backgroundColor = isSelected ? '#2684FF' : isFocused ? '#DEEBFF' : data.ativo ? 'transparent' : '#D3D3D3'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
}
import { connect } from 'react-redux'
import { requestPerfis } from '../../services/actions/perfis.actions'
import { requestVideos } from '../../services/actions/videos.actions'
import { bindActionCreators } from 'redux'
import VideosForm from '../../components/Videos/VideosForm'

const mapStateToProps = (store) => ({
  video: store.videosState.selectedVideo,
  perfis:
    store.perfisState.perfis.length > 0
      ? store.perfisState.perfis.map((perfil) => ({
          label: perfil.nome,
          value: perfil.id,
        }))
      : [],
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ requestPerfis, requestVideos }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(VideosForm)

import React, { useState } from 'react'
import { Form, Col, Row, Button } from 'react-bootstrap'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import Select from 'react-select'

import { formasPagamentoRadiologia } from '../../../constants/agendamento.constants'

export default function AgendamentoRadiologia(props) {
  const { clinicas, salvarAgendamento, nomePaciente } = props
  const [dataConsulta, setDataConsulta] = useState(new Date())
  const [selectedClinica, setSelectedClinica] = useState(null)
  const [selectedPagamento, setSelectedPagamento] = useState(null)

  const handleSalvar = () => {
    salvarAgendamento(dataConsulta, selectedClinica, selectedPagamento)
  }

  return (
    <div className='px-4'>
      <h3 className='m-0 text-secondary font-weight-bold'>Agendamento Radiologia</h3>
      <div className='mt-5 mb-4'>
        Paciente: <h4>{nomePaciente}</h4>
      </div>
      <Row>
        <Col md='4'>
          <Form.Group className='required'>
            <Form.Label htmlFor='dataRetorno'>Data da consulta</Form.Label>
            <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
              <KeyboardDateTimePicker
                className='form-control'
                value={dataConsulta}
                onChange={setDataConsulta}
                variant='inline'
                format='DD/MM/YYYY HH:mm'
                required
                animateYearScrolling
                disablePast
              />
            </MuiPickersUtilsProvider>
          </Form.Group>
        </Col>
        <Col md='4'>
          <Form.Group className='required'>
            <Form.Label htmlFor='dataRetorno'>Clínica Radiológica</Form.Label>
            <Select
              options={clinicas.map((c) => ({
                label: c.nome,
                value: c.id,
              }))}
              placeholder=''
              value={selectedClinica}
              onChange={setSelectedClinica}
              noOptionsMessage={() => 'Nenhuma clínica cadastrada'}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className='d-flex align-items-center'>
        <Col md='4'>
          <Form.Group className='required'>
            <Form.Label htmlFor='dataRetorno'>Forma de Pagamento</Form.Label>
            <Select
              options={Object.values(formasPagamentoRadiologia)}
              placeholder=''
              value={selectedPagamento}
              onChange={setSelectedPagamento}
              noOptionsMessage={() => 'Nenhuma opção'}
            />
          </Form.Group>
        </Col>
        <Col className='align-items-center col-md-3 d-flex pt-3'>
          <Button variant='primary' onClick={handleSalvar}>
            Salvar Agendamento
          </Button>
        </Col>
      </Row>
    </div>
  )
}

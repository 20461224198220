import React, { useEffect, useState } from 'react'

export default function ListaDocumentos({ tratamento, categorias = [], download }) {
    useEffect(() => {
    }, [])

    return (
        <div>
            <div className='table-responsive'>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>Identificador</th>
                            <th>Nome do Documento</th>
                            <th>Nome do do Sistema</th>
                            <th>Categoria</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tratamento.documentos.map(documento => {
                            return <tr className='cursor-pointer' onClick={() => download(documento)}>
                                <td>{documento.id}</td>
                                <td>{documento.nome}</td>
                                <td>{documento.nomeGerado}</td>
                                <td>{categorias.find(categoria => categoria.id == documento.categoriaId).nome}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

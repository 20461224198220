import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Container, Button } from 'react-bootstrap'

/**
 * @return {null}
 */

export default function Conclusao({ onBack }) {
  return (
    <Container className='text-center pb-5'>
      <div className='mb-5'>
        <FontAwesomeIcon
          style={{
            fontSize: '11em',
          }}
          className='text-success mt-5 mb-3'
          icon={faCheckCircle}
        />
        <h2 style={fontStyle} className='text-success'>
          Protético alterado com sucesso!
        </h2>
      </div>
      <div>
        <Button variant='outline-primary' onClick={onBack}>
          Voltar
        </Button>
      </div>
    </Container>
  )
}

const fontStyle = {
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
}

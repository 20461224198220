import { connect } from 'react-redux'
import { requestMenu } from '../../services/actions/perfis.actions'
import { bindActionCreators } from 'redux'

import Recover from '../../pages/Recover'

const mapStateToProps = (store) => ({
  autenticado: store.accountState.user.authenticated,
  isProtetico: !!store.accountState.user.proteticoDTO,
  menus: store.perfisState.menus,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ requestMenu }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Recover)

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { editarPaciente } from '../../services/actions/pacientes.actions'
import { concluirAtendimento } from '../../services/reducers/avaliacao.reducer'

import PacienteDetails from '../../components/Pacientes/PacienteDetails'

const mapStateToProps = (store) => ({
  pacienteId: store.pacientesState.pacienteId,
  pacienteNome: store.pacientesState.pacienteNome,
  cpfPaciente: store.pacientesState.cpfPaciente,
  pacienteTelefones: store.pacientesState.pacienteTelefones,
  agendamentoId: store.pacientesState.agendamentoId,
  nomeColaborador: store.accountState.user.name,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ editarPaciente, concluirAtendimento }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PacienteDetails)

import React, { Component, Fragment } from 'react'
import { Button, Col, Row, Form, Spinner } from 'react-bootstrap'
import MaskedInput from 'react-text-mask'
import { isValidCnpj } from '@brazilian-utils/is-valid-cnpj'
import { toast } from 'react-toastify'

import ProteticoService from '../../services/protetico.service.js'
import { cnpjMask } from '../../constants/mask.constants'
import { onlyNumbers } from '@brazilian-utils/helper-only-numbers'

class CadastrarProtetico extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cnpj: '',
      razaoSocial: '',
      email: '',
      isLoading: false,
      invalidCNPJ: false,
      disableAll: false
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if (isValidCnpj(this.state.cnpj)) {
      this.setState({ ...this.state, isLoading: true }, async () => {
        const { cnpj, email, razaoSocial } = this.state
        try {
          await ProteticoService.cadastrarProtetico(cnpj, email, razaoSocial)
          toast('Protético cadastrado com sucesso!', { type: toast.TYPE.SUCCESS })
          this.handleCancel()
        } catch (e) {
          this.setState({ ...this.state, isLoading: false })
        }
      })
    }
  }

  handleCancel = () => {
    this.props.history.push('/protetico')
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({ ...this.state, [name]: value })
  }

  handleChangeCnpj = async (event) => {
    const { name, value } = event.target;
    this.setState({ ...this.state, [name]: value })

    var number = onlyNumbers(value)

    if (isValidCnpj(value) && number.length == 14) {
      const { data } = await ProteticoService.getByCnpj(number)

      if(data)
        this.setState({ disableAll: true, email: data.email, razaoSocial: data.razaoSocial });

      
      }
    else {
      this.setState({ disableAll: false });
    }
  }

  handleBlurCNPJ = (event) => {
    this.setState({ ...this.state, invalidCNPJ: !isValidCnpj(event.target.value) })
  }

  render() {
    const { isLoading, invalidCNPJ, cnpj, razaoSocial, email } = this.state
    return (
      <Fragment>
        <h1 className='h3 m-0 text-secondary font-weight-bold'>Cadastrar Protético</h1>
        <Form className='py-5' onSubmit={this.handleSubmit}>
          <Row>
            <Col md='3'>
              <Form.Group className='required'>
                <Form.Label htmlFor='cnpj'>CNPJ:</Form.Label>
                <MaskedInput
                  name='cnpj'
                  value={cnpj}
                  mask={cnpjMask}
                  className={invalidCNPJ ? 'form-control is-invalid' : 'form-control'}
                  onChange={this.handleChangeCnpj}
                  onBlur={this.handleBlurCNPJ}
                  required
                />
                {invalidCNPJ && <div className='invalid-feedback'>Digite um CNPJ válido.</div>}
              </Form.Group>
            </Col>
            <Col md='3'>
              <Form.Group className='required'>
                <Form.Label htmlFor='enderecoEmail'>E-mail:</Form.Label>
                <Form.Control
                  type='email'
                  name='email'
                  value={email}
                  onChange={this.handleChange}
                  onBlur={this.handleBlurEmail}
                  maxLength={100}
                  required
                  disabled={this.state.disableAll}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='required'>
                <Form.Label htmlFor='nome'>Razão Social:</Form.Label>
                <Form.Control
                  type='text'
                  name='razaoSocial'
                  value={razaoSocial}
                  onChange={this.handleChange}
                  maxLength={100}
                  required
                  disabled={this.state.disableAll}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className='d-flex align-items-center justify-content-end mt-3'>
            <Button variant='outline-primary' className='mr-3' onClick={this.handleCancel}>
              Cancelar
            </Button>
            <Button variant='primary' type='submit'>
              {isLoading ? <Spinner animation='border' size='sm' variant='light' /> : 'Cadastrar'}
            </Button>
          </div>
        </Form>
      </Fragment>
    )
  }
}

export default CadastrarProtetico

import React from 'react'
import { Form, Col, Row, Button } from 'react-bootstrap'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'

import DiasSemana from '../../constants/semana.constants'

const HORARIO_DEFAULT = {
  diaSemana: DiasSemana.SEGUNDA.value,
  horaInicioManha: '09:00',
  horaFimManha: '12:00',
  horaInicioTarde: '13:00',
  horaFimTarde: '18:00',
  ativo: true,
}

/**
 * Componente responsavel pela configuracao de horarios
 * de atendimento por dias da semana.
 */
class ConfiguracaoHorario extends React.Component {
  constructor(props) {
    super(props)
    this.state = { horarios: [HORARIO_DEFAULT] }
  }

  /**
   * Seta a lista de horarios
   */
  setConfiguration(data) {
    this.setState({ horarios: data })
  }

  /**
   * Retorna lista com as configuracaoes
   */
  getConfiguracaoHorario() {
    const horarios = [...this.state.horarios].filter((h) => this.isNotEmpty(h))
    // const hasAll = horarios.some((h) => +h.diaSemana === DiasSemana.TODOS.value)
    // if (hasAll) {
    //   return [
    //     {
    //       ...horarios[0],
    //       ...{ diaSemana: DiasSemana.DOMINGO.value },
    //     },
    //     {
    //       ...horarios[0],
    //       ...{ diaSemana: DiasSemana.SEGUNDA.value },
    //     },
    //     { ...horarios[0], ...{ diaSemana: DiasSemana.TERCA.value } },
    //     {
    //       ...horarios[0],
    //       ...{ diaSemana: DiasSemana.QUARTA.value },
    //     },
    //     {
    //       ...horarios[0],
    //       ...{ diaSemana: DiasSemana.QUINTA.value },
    //     },
    //     { ...horarios[0], ...{ diaSemana: DiasSemana.SEXTA.value } },
    //     { ...horarios[0], ...{ diaSemana: DiasSemana.SABADO.value } },
    //   ]
    // }
    // const hasSomenteUteis = horarios.some((h) => +h.diaSemana === DiasSemana.SOMENTE_UTEIS.value)

    // if (hasSomenteUteis) {
    //   const _result = []
    //   // const domingo = horarios.find((h) => +h.diaSemana === DiasSemana.DOMINGO.value && this.isNotEmpty(h))
    //   // const sabado = horarios.find((h) => +h.diaSemana === DiasSemana.SABADO.value && this.isNotEmpty(h))
    //   // domingo && _result.push(domingo)
    //   // sabado && _result.push(sabado)
    //   return _result.concat([
    //     {
    //       ...horarios[0],
    //       ...{ diaSemana: DiasSemana.SEGUNDA.value },
    //     },
    //     { ...horarios[0], ...{ diaSemana: DiasSemana.TERCA.value } },
    //     {
    //       ...horarios[0],
    //       ...{ diaSemana: DiasSemana.QUARTA.value },
    //     },
    //     {
    //       ...horarios[0],
    //       ...{ diaSemana: DiasSemana.QUINTA.value },
    //     },
    //     { ...horarios[0], ...{ diaSemana: DiasSemana.SEXTA.value } },
    //   ])
    // }
    return horarios
  }

  /**
   * Verify if is not empty
   * @param {*} h
   */
  isNotEmpty(h) {
    return (
      (!!h.horaInicioManha && !!h.horaFimManha && !!h.horaInicioTarde && !!h.horaFimTarde) ||
      (!h.horaInicioManha && !h.horaFimManha && !!h.horaInicioTarde && !!h.horaFimTarde) ||
      (!!h.horaInicioManha && !!h.horaFimManha && !h.horaInicioTarde && !h.horaFimTarde)
    )
  }

  /**
   * Modifica o estado do componente ao mudar um input
   */
  onChange = (e, index) => {
    const _horarios = this.state.horarios
    _horarios[index][e.target.name] = e.target.value
    this.setState({ horarios: _horarios })
  }

  handleSelectChange = (option, index) => {
    const _horarios = this.state.horarios
    _horarios[index]['diaSemana'] = option.value
    this.setState({ horarios: _horarios })
  }

  /**
   * Modifica status quando muda um checkbox
   */
  onChangeCheckbox = (e, index) => {
    const _horarios = this.state.horarios
    _horarios[index][e.target.name] = e.target.checked
    this.setState({ horarios: _horarios })
  }

  /**
   * Return list with available options
   */
  getSelectOptions() {
    const semanaOptions = [
      DiasSemana.DOMINGO,
      DiasSemana.SEGUNDA,
      DiasSemana.TERCA,
      DiasSemana.QUARTA,
      DiasSemana.QUINTA,
      DiasSemana.SEXTA,
      DiasSemana.SABADO,
    ]
    if (this.state.horarios.length === 0) {
      return semanaOptions
    }

    return semanaOptions.filter(
      (diaSemana) => !this.state.horarios.find((horario) => horario.diaSemana === diaSemana.value)
    )
  }

  /**
   * Retorna o proximo dia da semana
   */
  getNextDay() {
    const length = this.state.horarios.length
    const last = this.state.horarios[length - 1]
    const value = +last.diaSemana + 1
    return value < 7 ? value : 0
  }

  /**
   * Adiciona nova configuracao
   */
  addConfiguracao() {
    const semanaOptions = [
      DiasSemana.DOMINGO,
      DiasSemana.SEGUNDA,
      DiasSemana.TERCA,
      DiasSemana.QUARTA,
      DiasSemana.QUINTA,
      DiasSemana.SEXTA,
      DiasSemana.SABADO,
    ].filter((diaSemana) => !this.state.horarios.find((horario) => horario.diaSemana === diaSemana.value))
    const _horarios = this.state.horarios
    _horarios.push({
      diaSemana: semanaOptions[0].value,
      ativo: true,
      horaInicioManha: '08:00',
      horaFimManha: '12:00',
      horaInicioTarde: '13:00',
      horaFimTarde: '18:00',
      backgroundColor: 'alert-warning',
    })
    this.setState({ horarios: _horarios }, () => {
      setTimeout(() => {
        const bgDelete = this.state.horarios.find((h) => {
          return h.backgroundColor
        })
        if (bgDelete) {
          delete bgDelete.backgroundColor
          const index = this.state.horarios.indexOf(bgDelete),
            novosHorarios = [...this.state.horarios]
          novosHorarios[index] = bgDelete
          this.setState({ horarios: novosHorarios })
        }
      }, 2000)
    })
  }

  /**
   * Remove configuracao
   */
  removeConfiguracao(index) {
    if (this.state.horarios.length > 1) {
      const horarios = [...this.state.horarios]
      horarios.splice(index, 1)
      this.setState({ horarios })
    }
  }

  /**
   * Verifica se deve mostar botao para adicionar novos
   */
  canAddMore() {
    return this.state.horarios.length < Object.values(DiasSemana).length
  }

  /**
   * Rendereiza item de configuracao de horario
   */
  renderHorario(horario, index) {
    return (
      <Row key={index} className={`align-items-center pb-2 mb-3 border-bottom ${horario.backgroundColor || ''}`}>
        <Col>
          <Form.Group>
            <Form.Label htmlFor='diaSemana'>Dia da Semana</Form.Label>
            <Select
              onChange={(option) => this.handleSelectChange(option, index)}
              value={Object.values(DiasSemana).find((dia) => dia.value === horario.diaSemana)}
              options={this.getSelectOptions()}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label htmlFor='horaInicioManha'>Horário Início Manhã</Form.Label>
            <Form.Control
              style={inputStyle}
              type='time'
              name='horaInicioManha'
              value={horario.horaInicioManha}
              onChange={(e) => this.onChange(e, index)}
              required={!!horario.horaFimManha}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label htmlFor='horaFimManha'>Horário Fim Manhã</Form.Label>
            <Form.Control
              style={inputStyle}
              type='time'
              name='horaFimManha'
              value={horario.horaFimManha}
              onChange={(e) => this.onChange(e, index)}
              required={!!horario.horaInicioManha}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label htmlFor='horaInicioTarde'>Horário Início Tarde</Form.Label>
            <Form.Control
              style={inputStyle}
              type='time'
              name='horaInicioTarde'
              value={horario.horaInicioTarde}
              onChange={(e) => this.onChange(e, index)}
              required={!!horario.horaFimTarde}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label htmlFor='horaFimTarde'>Horário Fim Tarde</Form.Label>
            <Form.Control
              style={inputStyle}
              type='time'
              name='horaFimTarde'
              value={horario.horaFimTarde}
              onChange={(e) => this.onChange(e, index)}
              required={!!horario.horaInicioTarde}
            />
          </Form.Group>
        </Col>
        <Col style={{ marginTop: '0.9em' }}>
          {this.state.horarios.length > 1 && (
            <Button onClick={() => this.removeConfiguracao(index)} variant='danger'>
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          )}
        </Col>
      </Row>
    )
  }

  /**
   * Renderiza o componente
   */
  render() {
    return (
      <>
        {this.state.horarios
          .sort((horario1, horario2) => {
            if (horario1.diaSemana > horario2.diaSemana) {
              return 1
            } else {
              return -1
            }
          })
          .map((horario, index) => {
            return this.renderHorario(horario, index)
          })}
        {this.canAddMore() && (
          <Button onClick={() => this.addConfiguracao()} className='fix-btn-margin' variant='primary'>
            <FontAwesomeIcon icon={faPlus} /> Adicionar novo horário
          </Button>
        )}
      </>
    )
  }
}

export default ConfiguracaoHorario

const inputStyle = {
  height: '38px',
}

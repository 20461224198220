import React, { Fragment, useState, useEffect } from 'react'
import StepWizard from 'react-step-wizard'

import SelecionarAvaliacao from './SelecionarAvaliacao'
import Anamnese from '../../Avaliacao/Anamnese'
import DefinicaoTratamento from './DefinicaoTratamento'
import OrtodontiaService from '../../../services/ortodontia.service'
import { formatMoney } from '../../../utils/string.utils'

import PageLoading from '../../Common/PageLoading'
import ortodontiaService from '../../../services/ortodontia.service'
import pacienteService from '../../../services/paciente.service'
import AnamneseService from '../../../services/anamnese.service'
import Navigation from './Navigation'

export default function AvaliacaoOrtodontiaWizard(props) {
  const { pacienteId, agendamento, anamneseFichaId, history, perguntasAnamnese } = props
  const INITIAL_FICHA = {
    statusAvaliacao: 0,
    agendamentoId: agendamento.id,
    pacienteId,
    dependenteId: agendamento.dependenteId,
    anamneseFichaId: null,
    anamneseRespostas: [],
    anamnesePerguntas: [],
    aparelhoId: null,
  }
  const [fichaInicial, setFichaInicial] = useState(INITIAL_FICHA)
  const [isLoading, setIsLoading] = useState(true)
  const [wizardRef, setWizardRef] = useState(null)
  const [selectedAvaliacao, setSelectedAvaliacao] = useState(
    agendamento.avaliacaoOrtodontia ? agendamento.avaliacaoOrtodontia : fichaInicial
  )
  const [avaliacoesPaciente, setAvaliacoesPaciente] = useState([])
  const [paciente, setPaciente] = useState()
  const [selectedEncaminhamento, setSelectedEncaminhamento] = useState(null)
  const [selectedAparelho, setSelectedAparelho] = useState(null)
  const [aparelhoOptions, setAparelhoOptions] = useState([])
  const [isAparelhoLoading, setIsAparelhoLoading] = useState(true)
  const [passo, setPasso] = useState(1)

  useEffect(() => {
    wizardRef && wizardDidMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardRef])

  useEffect(() => {
    if (perguntasAnamnese && perguntasAnamnese.length > 0) {
      let anamneseFicha = perguntasAnamnese.find((perguntas) => perguntas.id === anamneseFichaId)
      atualizarPerguntasAnamnese(anamneseFicha)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perguntasAnamnese])

  const wizardDidMount = async () => {
    try {
      await Promise.all([
        !selectedAvaliacao.id && getAvaliacoesPaciente(),
        getPaciente(),
        getAparelhos(),
        getAnamnese(),
      ])
    } catch (error) {}
    setIsLoading(false)
  }

  const getAnamnese = async () => {
    try {
      const { data } = await AnamneseService.getRespostasByAgendamentoId(agendamento.id)
      onChangeFicha(data.anamnesePerguntas, data.id, data.nome)
    } catch (error) {
      console.error(error)
    }
  }

  const getAparelhos = async () => {
    try {
      const { data } = await OrtodontiaService.getAparelhosCadastrados()
      setAparelhoOptions(
        data.map((opt) => ({
          value: opt.id,
          label: `${opt.nome} - ${formatMoney(opt.valorVenda)}`,
        }))
      )
    } catch (e) {
      setAparelhoOptions([])
    }
    setIsAparelhoLoading(false)
  }

  const getAvaliacoesPaciente = async () => {
    try {
      const { data } = agendamento.dependenteId
        ? await ortodontiaService.getAvaliacoesDependente(agendamento.dependenteId)
        : await ortodontiaService.getAvaliacoesPaciente(pacienteId)
      setAvaliacoesPaciente(data)
      data.length === 0 && wizardRef.nextStep()
    } catch (error) {
      console.error(error)
    }
  }

  const getPaciente = async () => {
    try {
      const { data } = await pacienteService.getPacienteById(pacienteId)
      setPaciente(data)
    } catch (e) {}
  }

  const atualizarPerguntasAnamnese = (perguntas, fichaId, nome) => {
    let novaFicha = { ...selectedAvaliacao }
    if (nome && !novaFicha.nome) {
      novaFicha.nome = nome
    }
    novaFicha.anamneseRespostas = new Array(perguntas.length)
    novaFicha.anamneseFichaId = fichaId
    novaFicha.anamnesePerguntas = perguntas

    const avaliacao = { ...novaFicha, anamneseFicha: perguntas }

    setSelectedAvaliacao(avaliacao)
    setFichaInicial(avaliacao)
  }

  const onChangeRespostaRadio = (index, { target }) => {
    let novasRespostas = [...selectedAvaliacao.anamneseRespostas]
    if (novasRespostas[index]) {
      novasRespostas[index].tipoResposta = target.value
    } else {
      novasRespostas[index] = {
        tipoResposta: target.value,
        texto: null,
        anamneseId: target.name,
      }
    }
    setSelectedAvaliacao({
      ...selectedAvaliacao,
      anamneseRespostas: novasRespostas,
    })
  }

  const onChangeRespostaTexto = (index, { target }) => {
    let novasRespostas = [...selectedAvaliacao.anamneseRespostas]
    if (novasRespostas[index]) {
      if (!target.value && novasRespostas[index].tipoResposta === null) {
        novasRespostas[index] = undefined
      } else {
        novasRespostas[index].texto = target.value
      }
    } else {
      novasRespostas[index] = {
        tipoResposta: null,
        texto: target.value,
        anamneseId: target.name,
      }
    }
    setSelectedAvaliacao({
      ...selectedAvaliacao,
      anamneseRespostas: novasRespostas,
    })
  }

  const onChangeFicha = (perguntas, fichaId, nome) => atualizarPerguntasAnamnese(perguntas, fichaId, nome)

  const handleSelectAvaliacao = async (avaliacao) => {
    setIsLoading(true)
    try {
      const { data } = await ortodontiaService.getAvaliacaoById(avaliacao.id)
      setSelectedAvaliacao({
        ...selectedAvaliacao,
        ...data,
      })
      wizardRef.nextStep()
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const handleChangeAparelho = (aparelho) => {
    setSelectedAparelho(aparelho)
    if (aparelho) {
      setSelectedAvaliacao({ ...selectedAvaliacao, aparelhoId: aparelho.value })
    }
  }

  const renderLoading = () => <PageLoading />

  const onStepChange = (passo) => {
    if (passo.activeStep === 1) {
      setSelectedAvaliacao({
        ...fichaInicial,
        anamneseRespostas: new Array(perguntasAnamnese.length),
      })
      setSelectedEncaminhamento(null)
      setSelectedAparelho(null)
    }
    setPasso(passo.activeStep)
  }

  return (
    <Fragment>
      <StepWizard isLazyMount onStepChange={onStepChange} instance={setWizardRef}>
        <SelecionarAvaliacao
          agendamento={agendamento}
          handleSelectAvaliacao={handleSelectAvaliacao}
          history={history}
          avaliacoes={avaliacoesPaciente}
          isLoading={isLoading}
        />
        <Anamnese
          {...props}
          avaliacaoPreenchida={!!selectedAvaliacao.id}
          respostas={selectedAvaliacao.anamneseRespostas}
          onChangeRadio={onChangeRespostaRadio}
          onChangeTexto={onChangeRespostaTexto}
          paciente={paciente}
          selectedFicha={selectedAvaliacao}
        />
        <DefinicaoTratamento
          selectedEncaminhamento={selectedEncaminhamento}
          setSelectedEncaminhamento={setSelectedEncaminhamento}
          selectedAparelho={selectedAparelho}
          setSelectedAparelho={handleChangeAparelho}
          aparelhoOptions={aparelhoOptions}
          isAparelhoLoading={isAparelhoLoading}
          wizardRef={wizardRef}
          history={history}
          agendamento={
            selectedAvaliacao && selectedAvaliacao.agendamentosRadiologia
              ? selectedAvaliacao.agendamentosRadiologia[selectedAvaliacao.agendamentosRadiologia.length - 1]
              : null
          }
        />
      </StepWizard>
      <Navigation
        selectedEncaminhamento={selectedEncaminhamento}
        avaliacoes={avaliacoesPaciente}
        selectedAvaliacao={selectedAvaliacao}
        {...props}
        passo={passo}
        wizard={wizardRef}
      />
      {isLoading && renderLoading()}
    </Fragment>
  )
}

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { requestDentistasByEspecialidade } from '../../../services/actions/colaboradores.actions'
import PageLoading from '../../../components/Common/PageLoading'
import colaboradoresService from '../../../services/colaboradores.service'
import { Container, Row, Col } from 'react-bootstrap'
import CardColaborador from '../../../components/Colaboradores/CardColaborador'
import AgendamentoClinicoGeralContainer from '../../../containers/AgendamentoClinicoGeralContainer'

class AgendamentoClinicoGeralListagem extends Component {
  state = {
    isLoading: false,
  }

  componentDidMount() {
    this.buscarDentistas()
  }

  buscarDentistas = async () => {
    this.setState({ isLoading: true })
    try {
      const { data } = await colaboradoresService.getAllEspecialidades()
      const especialidadesFiltradas = data.filter(
        (especialidade) => especialidade.nome.toLowerCase() === 'clínico geral'
      )
      const especialidadeClinicoGeral = especialidadesFiltradas.length > 0 ? especialidadesFiltradas[0] : null

      if (!especialidadeClinicoGeral) {
        return
      }

      const { requestDentistasByEspecialidade } = this.props
      requestDentistasByEspecialidade(especialidadeClinicoGeral.id)
    } catch (error) {
      console.log(error)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  organizarGridDentistas = () => {
    let { dentistas } = this.props

    dentistas = dentistas.sort((a, b) => a.nome.localeCompare(b.nome))

    var perChunk = 3

    dentistas = dentistas.filter(x => x.especialidadeColaborador.some(y => y.especialidadeId == 'c1f7cf19-ae12-4f7c-7bf1-08d80eecea9d'))

    return dentistas.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
  }

  onClickDentista = (dentista) => {
    const { history } = this.props
    history.push(`/agendamento/clinicogeral/${dentista.id}`)
  }

  montarListaClinicosGerais = () => {
    const gridDentistas = this.organizarGridDentistas()

    const linhas = gridDentistas.map((linha, indice) => (
      <Row key={indice}>
        {linha.map((dentista) => (
          <Col key={dentista.id} sm={12} md={4} lg={4} xl={4}>
            <CardColaborador colaborador={dentista} onClick={() => this.onClickDentista(dentista)} />
          </Col>
        ))}
      </Row>
    ))

    return (
      <Container fluid className='listaCards'>
        {linhas}
      </Container>
    )
  }

  render() {
    const { isLoading } = this.state
    const { dentistas, isLoadingDentistas, location } = this.props

    if (location.id) {
      return <AgendamentoClinicoGeralContainer idDentista={location.id} />
    }

    if (isLoading || isLoadingDentistas) {
      return (
        <div className='pb-3'>
          <PageLoading />
        </div>
      )
    }

    const listaClinicosGerais = this.montarListaClinicosGerais()

    return (
      <div className='pb-3'>
        <Fragment>
          <h1 className='h3 mb-4 text-secondary font-weight-bold'>Agenda Clínico Geral</h1>
          {listaClinicosGerais}
        </Fragment>
      </div>
    )
  }
}

const mapStateToProps = (store) => ({
  isLoadingDentistas: store.colaboradoresState.isLoading,
  dentistas: store.colaboradoresState.dentistas,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestDentistasByEspecialidade,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AgendamentoClinicoGeralListagem))

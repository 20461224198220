import { startOfMonth } from 'date-fns'
import React, { useState, useEffect } from 'react'
import PageLoading from '../../../components/Common/PageLoading'
import Select from 'react-select'
import DateRangePicker from 'react-daterange-picker'
import moment from 'moment';
import colaboradoresService from '../../../services/colaboradores.service'
import { cargoConstant } from '../../../constants/common.constants'
import { setLoading } from '../../../services/actions/account.actions'
import SystemConstants from '../../../constants/system.constants'
import accountService from '../../../services/account.service'
import AgendamentoClinicoGeral from './Agendamentos/AgendamentoClinicoGeral'
import dashboardService from '../../../services/dashboard.service'
import { formatMoney } from '../../../utils/string.utils'

export default function MainDashboard(props) {
    const [isLoading, setIsLoading] = useState(true)
    const [showCalendar, setShowCalendar] = useState(false)
    const [hasPermission, setHasPermission] = useState(false)
    const [filtroDe, setFiltroDe] = useState(startOfMonth(new Date()))
    const [filtroAte, setFiltroAte] = useState(new Date())
    const [colaborador, setColaborador] = useState(null)
    const [colaboradores, setColaboradores] = useState([])
    const [dadosDashboard, setDadosDashboard] = useState({})

    const user = JSON.parse(localStorage.getItem(SystemConstants.getUser()))

    useEffect(() => {
        fetchAll();
    }, []);

    const fetchAll = async () => {
        const hasPermission = await accountService.hasPermission(user.franquiaId, "/Dashboard/Geral")
        setHasPermission(hasPermission.data)

        const { data } = await colaboradoresService.getAllColaboradores()
        const colaboradoresFiltrados = data.filter(({ cargo, ativo }) => cargo.tipo === cargoConstant.ATENDENTE.value && ativo).map(({ nome, id }) => ({ label: nome, value: id }));

        setColaboradores(colaboradoresFiltrados)

        const filtros = [
            {
                campo: "dataInicio",
                valor: moment(filtroDe).format(`YYYY-MM-DD`)
            },
            {
                campo: "dataFim",
                valor: moment(filtroAte).format(`YYYY-MM-DD`)
            }
        ]

        if (colaborador)
            filtros.push({
                campo: "atendenteId",
                valor: colaborador.value
            })


        const response = await dashboardService.getDashboard(filtros)
        setDadosDashboard(response);


        setIsLoading(false)
    }

    const onFilterDashboard = async () => {
        setShowCalendar(false);

        if (isLoading)
            return;

        setLoading(true);

        const filtros = [
            {
                campo: "dataInicio",
                valor: moment(filtroDe).format(`YYYY-MM-DD`)
            },
            {
                campo: "dataFim",
                valor: moment(filtroAte).format(`YYYY-MM-DD`)
            }
        ]

        if (colaborador)
            filtros.push({
                campo: "atendenteId",
                valor: colaborador.value
            })


        const response = await dashboardService.getDashboard(filtros)
        setDadosDashboard(response);
        setIsLoading(false);
    }

    useEffect(() => {
        onFilterDashboard();
    }, [filtroAte, colaborador])
    return (
        <>
            {isLoading ? (
                <PageLoading />
            )
                :
                <>
                    <div className='row'>
                        <div className='d-flex align-items-center justify-content-between col'>
                            <h4 className='text-secondary font-weight-normal mb-0'>
                                Dashboard
                            </h4>

                            <div className='d-flex col-8 col-xl-4'>
                                <div className='col-6'>
                                    <Select
                                        placeholder='Colaborador'
                                        options={colaboradores}
                                        onChange={setColaborador}
                                        value={colaborador}
                                        noOptionsMessage={() => 'Nenhum colaborador'}
                                    />
                                </div>
                                <div className='col-6'>
                                    <input className='form-control' onClick={() => setShowCalendar(!showCalendar)} value={`${moment(filtroDe).format(`DD/MM/YYYY`)} - ${moment(filtroAte).format(`DD/MM/YYYY`)}`} />
                                </div>

                                {showCalendar &&
                                    <DateRangePicker
                                        firstOfWeek={1}
                                        numberOfCalendars={2}
                                        selectionType='range'
                                        singleDateRange={true}
                                        value={moment.range(filtroDe, filtroAte)}
                                        onSelect={(value) => {
                                            setFiltroDe(value.start);
                                            setFiltroAte(value.end);
                                        }}
                                        maximumDate={moment()}
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    <div className='container mt-5'>
                        <div className='row justify-content-center'>
                            <div className={`${hasPermission ? 'col' : 'col-3'} card-dashboard d-flex align-items-center mr-5`}>
                                <div className='image'>
                                    <div className='content'>
                                        <img src='/img/person-notebook.png' />
                                    </div>
                                </div>
                                <div className='content ml-4'>
                                    <p className='title'>Bem vindo@,</p>
                                    <p className='common mb-0'>{`${user.name.split(' ')[0]} ${user.name.split(' ')[1]}`}</p>
                                </div>
                            </div>

                            {hasPermission &&
                                <>
                                    <div className='col card-dashboard d-flex align-items-center justify-content-between mr-5'>
                                        <div className='image'>
                                            <div className='content d-flex align-items-center justify-content-center'>
                                                <img src='/img/icon-money.png' />
                                            </div>
                                        </div>
                                        <div className='content ml-4 text-right'>
                                            <p className='title money'><sub>R$</sub> {formatMoney(dadosDashboard.metric.faturamento.maiorFaturamento, false)}</p>
                                            <p className='common mb-0'>Maior faturamento</p>
                                        </div>
                                    </div>

                                    <div className='col card-dashboard d-flex align-items-center justify-content-between'>
                                        <div className='image'>
                                            <div className='content d-flex align-items-center justify-content-center'>
                                                <img src='/img/loop-money.png' />
                                            </div>
                                        </div>
                                        <div className='content ml-4 text-right'>
                                            <p className='title money'><sub>R$</sub> {formatMoney(dadosDashboard.metric.faturamento.faturamentoAtual, false)}</p>
                                            <p className='common mb-0'>Faturamento atual</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <small className='d-block text-muted mt-3 text-center'>A dashboard é atualizada a cada 50 minutos, ultima atualização em {moment(dadosDashboard.metric.updateAt).format(`DD/MM/YYYY, HH:mm:ss`)}</small>
                    </div>

                    <div className='container mt-5 bg-shadow p-4'>
                        <h4 className='font-weight-normal mb-0'>Agendamentos Clinico Geral</h4>
                        <AgendamentoClinicoGeral
                            agendamentoClinicoGeralPorPaciente={dadosDashboard.agendamentoClinicoGeralPorPaciente}
                        />
                    </div>
                </>
            }
        </>
    )
}
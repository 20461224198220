export const LISTAR_COLABORADORES = 'LISTAR_COLABORADORES'

export const LISTAR_DENTISTAS = 'LISTAR_DENTISTAS'

export const REQUEST_COLABORADORES = 'REQUEST_COLABORADORES'

export const REQUEST_DENTISTAS = 'REQUEST_DENTISTAS'

export const REQUEST_DENTISTAS_BY_ESPECIALIDADE = 'REQUEST_DENTISTAS_BY_ESPECIALIDADE'

export const SALVAR_COLABORADOR = 'SALVAR_COLABORADOR'

export const EDITAR_COLABORADOR = 'EDITAR_COLABORADOR'

export const GET_COLABORADOR_ID = 'GET_COLABORADOR_ID'

export const SET_COLABORADOR_ID = 'SET_COLABORADOR_ID'

export const RESET_COLABORADOR_ID = 'RESET_COLABORADOR_ID'

export const TOGGLE_ACESSO_COLABORADOR = 'TOGGLE_ACESSO_COLABORADOR'

export const INATIVAR_COLABORADOR = 'INATIVAR_COLABORADOR'

export const SOLICITAR_SENHA = 'SOLICITAR_SENHA'

export const ALTERAR_SENHA = 'ALTERAR_SENHA'

export const REQUEST_CARGOS = 'REQUEST_CARGOS'

export const LISTAR_CARGOS = 'LISTAR_CARGOS'

export const REQUEST_ESPECIALIDADES = 'REQUEST_ESPECIALIDADES'

export const LISTAR_ESPECIALIDADES = 'LISTAR_ESPECIALIDADES'

export const SET_LOADING = 'SET_LOADING'

import React, { useState, useEffect, Fragment } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import formatCPF from '@brazilian-utils/format-cpf'
import { format } from 'date-fns'

import RelatorioService from '../../services/relatorio.service'
import PageLoading from '../Common/PageLoading'
import { Col, Row, Table, Button, Spinner } from 'react-bootstrap'
import Paginator from '../Common/Paginator'
import { TIPO_CONSULTA } from '../../constants/agendamento.constants'
import CSVDownload from './CSVDownload'

export default function PacientesFaltosos({ selectedRelatorio }) {
  const itensPorPagina = 20

  const [isLoading, setLoading] = useState(false)
  const [itensLista, setItensLista] = useState([])
  const [cabecalhoRelatorio, setCabecalhoRelatorio] = useState([])
  const [dateFilterDe, setDateFilterDe] = useState(new Date().toISOString())
  const [dateFilterAte, setDateFilterAte] = useState(new Date().toISOString())
  const [feedbackBusca, setFeedbackBusca] = useState(null)
  const [pagina, setPagina] = useState(0)
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [itensCSV, setItensCSV] = useState([])
  const [isLoadingCSV, setLoadingCSV] = useState(false)
  const [downloadCSV, setDownloadCSV] = useState(false)

  useEffect(() => {
    handleSearch()

    setCabecalhoRelatorio([
      'Nome',
      'CPF',
      'Telefone Principal',
      'Whats App',
      'E-mail',
      'Tipo da Consulta',
      'Última Consulta',
      'Horário',
    ])
  }, [])

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina])

  useEffect(() => {
    if (downloadCSV) {
      setTimeout(() => {
        setDownloadCSV(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itensCSV, downloadCSV])

  const getValuesLista = async () => {
    const totalDaLista = itensPorPagina * totalPaginas
    if (totalDaLista === 0) {
      return
    }
    setLoadingCSV(true)
    let filtros = [
      {
        campo: 'dataInicial',
        valor: dateFilterDe,
      },
      {
        campo: 'dataFinal',
        valor: dateFilterAte,
      },
    ]
    const { dados } = await RelatorioService.getRegistrosPacientesFaltosos(filtros, 1, totalDaLista)

    const valores = []
    dados.forEach((item) => {
      item.emails = item.emails
      item.telefonesWhatsApp = item.telefonesWhatsApp
      item.cpf = formatCPF(item.cpf)
      item.data = format(new Date(item.data), 'dd/MM/yyyy')
      item.tipoConsulta = item.tipoConsulta === 0 ? TIPO_CONSULTA.AVALIACAO.label : TIPO_CONSULTA.PROCEDIMENTO.label
      valores.push(Object.values(item))
    })
    setItensCSV(valores)
    setDownloadCSV(true)
    setLoadingCSV(false)
    return {
      data: valores,
      headers: cabecalhoRelatorio,
      filename: `${selectedRelatorio.label} - ${format(new Date(), 'dd-MM-yyyy')}.csv`,
    }
  }

  const handleSearch = async () => {
    setLoading(true)
    try {
      setFeedbackBusca('')
      let filtros = [
        {
          campo: 'dataInicial',
          valor: dateFilterDe,
        },
        {
          campo: 'dataFinal',
          valor: dateFilterAte,
        },
      ]
      const { dados, totalPaginas } = await RelatorioService.getRegistrosPacientesFaltosos(
        filtros,
        pagina + 1,
        itensPorPagina
      )
      setItensLista(dados)
      setTotalPaginas(totalPaginas)
      if (dados.length === 0) {
        setFeedbackBusca('Nenhum registro encontrado')
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const handleDateFilterDe = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterDe(filter)
    } else {
      setDateFilterDe(null)
    }
    setPagina(0)
  }

  const handleDateFilterAte = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterAte(filter)
    } else {
      setDateFilterAte(null)
    }
    setPagina(0)
  }

  const handlePagina = (data) => setPagina(data.selected)

  const renderItens = () =>
    itensLista.length > 0 ? (
      <Fragment>
        <div className='mt-4 d-flex justify-content-end align-items-center'>
          <CSVDownload asyncExportMethod={getValuesLista}>
            <Button variant='outline-secondary' className='mb-3'>
              {isLoadingCSV ? <Spinner animation='border' size='sm' variant='light' /> : 'Gerar CSV'}
            </Button>
          </CSVDownload>
        </div>
        <Table hover size='sm' className='border rounded-0 shadow-sm' style={{backgroundColor: 'white'}}>
          <thead>
            <tr>
              <th>Nome</th>
              <th width='120px' className='text-center'>
                CPF
              </th>
              <th width='130px' className='text-center'>
                Tipo de Consulta
              </th>
              <th width='100px' className='text-center'>
                Data
              </th>
              <th width='100px' className='text-center'>
                Hora
              </th>
              <th width='160px' className='text-center'>
                Telefone Principal
              </th>
              <th width='120px' className='text-center'>
                WhatsApp
              </th>
              <th width='180px' className='text-center'>
                E-mail
              </th>
            </tr>
          </thead>
          <tbody>
            {itensLista.map((item, i) => {
              const { nome, cpf, telefonePrincipal, telefonesWhatsApp, emails, data, horaInicio, tipoConsulta } = item
              return (
                <tr key={i} style={{ fontSize: 'smaller' }}>
                  <td>{nome.toUpperCase()}</td>
                  <td className='text-center'>{formatCPF(cpf)}</td>
                  <td className='text-center'>
                    {tipoConsulta === 0 ? TIPO_CONSULTA.AVALIACAO.label : TIPO_CONSULTA.PROCEDIMENTO.label}
                  </td>
                  <td className='text-center'>{format(new Date(data), 'dd/MM/yyyy')}</td>
                  <td className='text-center'>{horaInicio}</td>
                  <td className='text-center'>{telefonePrincipal}</td>
                  <td className='text-center'>{telefonesWhatsApp || '-'}</td>
                  <td className='text-center'>{emails || '-'}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {totalPaginas > 1 && <Paginator handlePagina={handlePagina} pageCount={totalPaginas} paginaAtual={pagina} />}
      </Fragment>
    ) : (
      <div className='mt-4'>{feedbackBusca}</div>
    )

  const renderLoading = () => <PageLoading />

  return (
    <Fragment>
      <Row>
        <Col xs='5' className='d-flex justify-content-between align-items-center'>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              invalidDateMessage='Formato de data inválido'
              value={dateFilterDe}
              onChange={handleDateFilterDe}
              className='form-control'
              variant='inline'
              format='DD/MM/YYYY'
              maxDate={dateFilterAte || new Date('2100-01-01')}
              animateYearScrolling
              autoOk
              placeholder='De'
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              invalidDateMessage='Formato de data inválido'
              value={dateFilterAte}
              onChange={handleDateFilterAte}
              minDate={dateFilterDe || new Date('1900-01-01')}
              className='form-control mx-1'
              variant='inline'
              format='DD/MM/YYYY'
              animateYearScrolling
              autoOk
              placeholder='Até'
            />
          </MuiPickersUtilsProvider>
          <Button onClick={handleSearch} variant='primary' style={{height: '37px'}}>
            Pesquisar
          </Button>
        </Col>
        <Col />
      </Row>
      {isLoading ? renderLoading() : renderItens()}
    </Fragment>
  )
}

import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Form, Accordion, Button, Card, Badge, Toast } from 'react-bootstrap'

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { format, startOfMonth } from 'date-fns'

import PageLoading from '../../../components/Common/PageLoading'
import SystemConstants from '../../../constants/system.constants'
import accountService from '../../../services/account.service'
import dashboardService from '../../../services/dashboard.service'
import { formatMoney } from '../../../utils/string.utils'
import { status } from '../../../constants/common.constants'

export default function DashboardProfissional(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [filtroDe, setFiltroDe] = useState(startOfMonth(new Date()))
  const [filtroAte, setFiltroAte] = useState(new Date())
  const [dadosDashboard, setDadosDashboard] = useState([])
  const [totalizadores, setTotalizadores] = useState({
    totalProducao: 0,
    totalProcedimentos: 0,
    honorarios: 0
  })
  const [openItems, setOpenItems] = useState([]);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem(SystemConstants.getUser()))

    accountService.hasPermission(user.franquiaId, "/Dashboard/Profissional").then(result => {
      if (!result.data)
        window.location.href = "/";
    });
  }, [])

  useEffect(() => {
    updateDashboard()
  }, [filtroDe, filtroAte])

  const updateDashboard = async () => {
    setIsLoading(true)
    try {
      const filtros = [
        {
          campo: 'dataInicial',
          valor: filtroDe,
        },
        {
          campo: 'dataFinal',
          valor: filtroAte,
        },
        {
          campo: 'ResponsavelProcedimentoId',
          valor: null
        }
      ]
      const response = await dashboardService.getDashboardProfissional(filtros, 1, 99999)
      setDadosDashboard(response.data)
      setTotalizadores({
        totalProducao: response.totalProducao,
        totalProcedimentos: response.totalProcedimentos,
        honorarios: response.honorarios,
      })
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false)
  }

  const toggleItem = (key) => {
    if (openItems.includes(key)) {
      setOpenItems(openItems.filter(item => item !== key));  // Remove o item da lista se já está aberto
    } else {
      setOpenItems([...openItems, key]);  // Adiciona o item à lista se não está aberto
    }
  };

  const print = (index) => {
    const printContent = document.getElementsByClassName(`doctor-${index}`)[0].innerHTML;
    document.body.innerHTML = `<div id="print">${printContent}</div>`;
    window.print();
    window.location.reload();
  }

  return (
    <Fragment>
      {
        <Fragment>
          {isLoading ? (
            <PageLoading />
          ) : (
            <Fragment>
              <Row>
                <Col className='d-flex align-items-center justify-content-between'>
                  <h2 className='text-secondary font-weight-bold mb-4'>Dashboard <small>Profissional</small></h2>
                  <div className='d-flex'>
                    <Form.Group className='required mr-2'>
                      <Form.Label>De</Form.Label>
                      <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                        <KeyboardDatePicker
                          invalidDateMessage='Formato de data inválido'
                          className='form-control'
                          variant='inline'
                          value={filtroDe}
                          onChange={setFiltroDe}
                          format='DD/MM/YYYY'
                          required
                          animateYearScrolling
                          disableFuture
                        />
                      </MuiPickersUtilsProvider>
                    </Form.Group>
                    <Form.Group className='required'>
                      <Form.Label>Até</Form.Label>
                      <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                        <KeyboardDatePicker
                          invalidDateMessage='Formato de data inválido'
                          className='form-control'
                          value={filtroAte}
                          onChange={setFiltroAte}
                          variant='inline'
                          format='DD/MM/YYYY'
                          required
                          animateYearScrolling
                          disableFuture
                        />
                      </MuiPickersUtilsProvider>
                    </Form.Group>
                  </div>
                </Col>
              </Row>

              <Row className='mb-4'>
                <Col cols={4}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Procedimentos</Card.Title>
                      <Card.Text>
                        {totalizadores.totalProcedimentos}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col cols={4}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Produção</Card.Title>
                      <Card.Text>
                        {formatMoney(totalizadores.totalProducao)}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col cols={4}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Honorários</Card.Title>
                      <Card.Text>
                        {formatMoney(totalizadores.honorarios)}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {dadosDashboard.length > 0 &&
                <Accordion defaultActiveKey="1">
                  {dadosDashboard.map((profissional, index) => {
                    return <Card key={index + 1} className={`doctor-${index + 1}`}>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="text" onClick={() => toggleItem(index + 1)} aria-controls={`collapse-${index + 1}`} aria-expanded={openItems.includes(index + 1)}>{profissional.responsavel}</Accordion.Toggle>
                        <Button className='btn-right' onClick={() => print(index + 1)}>Imprimir</Button>
                      </Card.Header>
                      <Accordion.Collapse in={openItems.includes(index + 1)} id={`collapse-${index + 1}`}>
                        <Card.Body>
                          <p>
                            <b>Dia(s) presente(s):</b> {profissional.diasPresentes} <br />
                            <b>Total de Procedimentos:</b> {profissional.totalProcedimentos}<br />
                            <b>Total Produção:</b> {formatMoney(profissional.totalProducao)}<br />
                            <b>Total Honorários:</b> {formatMoney(profissional.honorarios)}
                          </p>
                          <Accordion>
                            {profissional.datas.map((data, dataIndex) => {
                              return <Card className="card-datas" key={dataIndex + 1}>
                                <Card.Header>
                                  <Accordion.Toggle as={Button} variant="text" onClick={() => toggleItem(`data-collapse-${dataIndex + 1}`)} aria-controls={`data-collapse-${dataIndex + 1}`} aria-expanded={openItems.includes(`data-collapse-${dataIndex + 1}`)}><Badge variant="primary">{data.procedimentos.length}</Badge> {data.data}</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse in={openItems.includes(`data-collapse-${dataIndex + 1}`)} id={`data-collapse-${index}`}>
                                  <Card.Body>
                                    <p>
                                      <b>Total de Procedimentos:</b> {data.totalProcedimentos}<br />
                                      <b>Total Produção:</b> {formatMoney(data.totalProducao)}<br />
                                      <b>Honorários:</b> {formatMoney(data.honorarios)}
                                    </p>
                                    {data.procedimentos.map(procedimento => {
                                      return <>
                                        <table className='table'>
                                          <thead>
                                            <th>Nome</th>
                                            <th>CPF</th>
                                            <th>Número do Plano</th>
                                            <th>Procedimento</th>
                                            <th>Status</th>
                                            <th>Dente</th>
                                            <th>Valor</th>
                                            <th>Data Finalização</th>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td className='text-uppercase'>{procedimento.nome}</td>
                                              <td>{procedimento.cpf}</td>
                                              <td>{procedimento.numeroPlano}</td>
                                              <td>{procedimento.nomeProcedimento}</td>
                                              <td>{status.find((s) => s.value == procedimento.statusProcedimento).label}</td>
                                              <td>{procedimento.nomeDente}</td>
                                              <td>{formatMoney(procedimento.valor)}</td>
                                              <td>{format(new Date(procedimento.dataFinalizacao), 'dd/MM/yyyy H:mm')}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </>
                                    })}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            })}
                          </Accordion>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  })}
                </Accordion >
              }
            </Fragment >
          )}
        </Fragment>
      }
    </Fragment>
  )
}

import AxiosClient from './axios-client'

class PacienteService {
  getDentes() {
    return AxiosClient.get(`Common/getDentes`)
  }

  getDentesOrdenados() {
    return AxiosClient.get(`Common/getDentesOrdenados`)
  }

  getProcedimentos() {
    return AxiosClient.get('Procedimento/getAll')
  }

  getAllPacientes() {
    return AxiosClient.get('Paciente/getAll')
  }

  getPacientesByNome(filtros, numeroPagina = 1, registrosPorPagina = 20){
    try {
      return AxiosClient.post('Paciente/getPacienteByNome', {
        filtros,
        numeroPagina,
        registrosPorPagina,
      })
      
    } catch (error) {
      console.log(error)
    }
  }

  getAvaliacaoByPacienteId(pacienteId) {
    return AxiosClient.get(`Avaliacao/getResumoByPacienteId/${pacienteId}`)
  }


  getAvaliacaoDetalhadaByPacienteId(pacienteId) {
    return AxiosClient.get(`Avaliacao/getByPacienteId/${pacienteId}`)
  }

  getAvaliacaoById(avaliacaoId) {
    return AxiosClient.get(`Avaliacao/getById/${avaliacaoId}`)
  }

  getPlanosDeTratamento(pacienteId, trazerDependentes = false) {
    return AxiosClient.get(`PlanoTratamento/getByPacienteId/${pacienteId}?trazerPlanosDependente=${trazerDependentes}`)
  }

  getPlanosDeTratamentoDependente(dependenteId) {
    return AxiosClient.get(`PlanoTratamento/GetByDependenteId/${dependenteId}`)
  }

  salvarPlanoDeTratamento(planoDeTratamento) {
    return AxiosClient.post('PlanoTratamento/salvar', planoDeTratamento)
  }

  getPacienteById(pacienteId) {
    return AxiosClient.get(`Paciente/getById/${pacienteId}`)
  }

  async desativarPaciente(pacienteId, ativar) {
    return await AxiosClient.put(`Paciente/Ativar/${pacienteId}/${ativar}`)
  }

  async salvarPaciente(paciente, file) {
    const formData = new FormData()
    formData.append('Paciente', JSON.stringify(paciente))
    file && formData.append('ComprovanteResidencia', file)
    return await AxiosClient.put('Paciente/update', formData)
  }

  criarPaciente(paciente, file) {
    const formData = new FormData()
    formData.append('Paciente', JSON.stringify(paciente))
    paciente.agendamentoId && formData.append('AgendamentoId', paciente.agendamentoId)
    file && formData.append('ComprovanteResidencia', file)
    return AxiosClient.post('Paciente/add', formData)
  }

  getParcelasEmAberto(pacienteId) {
    return AxiosClient.get(`Pagamento/getParcelasByPacienteId/${pacienteId}`)
  }

  async salvarTelefonePrincipal(pacienteId, novoNumero) {
    return await AxiosClient.put(`Paciente/alterarTelefonePrincipal/${pacienteId}/${novoNumero}`)
  }

  getAllAvaliacoesPendentes(pacienteId) {
    return AxiosClient.get(`Avaliacao/getPendentes/${pacienteId}`)
  }

  getPagamentos(pacienteId) {
    return AxiosClient.get(`Pagamento/getPagamentos/${pacienteId}`)
  }

  getHistoricoAgendamentos(pacienteId, dataInicial, dataFinal, colaborador, status, especialidade, planoTratamento, planoTratamentoOrtodontia) {
    const filtros = []
    pacienteId && filtros.push({ campo: 'pacienteId', valor: pacienteId })
    dataInicial && filtros.push({ campo: 'dataInicial', valor: dataInicial })
    dataFinal && filtros.push({ campo: 'dataFinal', valor: dataFinal })
    colaborador && filtros.push({ campo: 'colaboradorId', valor: colaborador.value })
    status && filtros.push({ campo: 'status', valor: status.value })
    especialidade && filtros.push({ campo: 'especialidadeId', valor: especialidade.value })
    planoTratamento && filtros.push({ campo: 'planoTratamentoId', valor: planoTratamento.value })
    planoTratamentoOrtodontia && filtros.push({ campo: 'planoTratamentoOrtodontiaId', valor: planoTratamentoOrtodontia.value })
    return AxiosClient.post(`Agendamento/getHistoricoAgendamento`, {
      filtros,
    })
  }
}

export default new PacienteService()

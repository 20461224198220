import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Spinner } from 'react-bootstrap'

const passos = { SELECAO: 1, FINANCEIRO: 2, IMPRESSAO: 3 }

export default function Navigation(props) {
  const { wizard, passo, finalizar, procedimentosSelecionados, recusarProcedimentos, handleDecidirDepois } = props

  const [isLoading, setLoading] = useState(false)
  const [podeAvancar, setPodeAvancar] = useState(false)

  useEffect(() => {
    setPodeAvancar((passo === passos.SELECAO && procedimentosSelecionados.length > 0) || passo === passos.FINANCEIRO)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passo, procedimentosSelecionados])

  const proximoPasso = async () => {
    if (passo === passos.FINANCEIRO) {
      setLoading(true)
      await finalizar()
      setLoading(false)
    } else {
      wizard.nextStep()
    }
  }

  return (
    wizard && (
      <Row id='wizard-navigation' className='border-top position-fixed rounded-0 py-3 bg-white'>
        <Col>
          {passo !== passos.SELECAO && passo !== passos.IMPRESSAO && (
            <Button onClick={wizard.previousStep} className='border' variant='light'>
              Anterior
            </Button>
          )}
        </Col>
        {/* {!decidirDepois ? ( */}
        <Col className='text-right'>
          {passo === passos.SELECAO && (
            <Button variant='outline-primary' className='mr-2' onClick={handleDecidirDepois}>
              Decidir depois
            </Button>
          )}
          {!podeAvancar && passo === passos.SELECAO && (
            <Button variant='outline-danger' onClick={recusarProcedimentos}>
              Não realizar procedimentos
            </Button>
          )}
          {podeAvancar && (
            <Button onClick={proximoPasso} variant='primary'>
              {!isLoading ? (
                passo === passos.SELECAO ? (
                  'Pagamento'
                ) : (
                  'Salvar'
                )
              ) : (
                <Spinner animation='border' size='sm' variant='light' />
              )}
            </Button>
          )}
        </Col>
        {/* ) : (
          <Col className='text-right'>
            <Button className='mr-2' variant='outline-danger' onClick={handleDecidirDepois}>
              Cancelar
            </Button>
            <Button variant='primary' onClick={finalizar}>
              {!isLoading ? 'Confirmar adiamento' : <Spinner animation='border' size='sm' variant='light' />}
            </Button>
          </Col>
        )} */}
      </Row>
    )
  )
}

import React, { Fragment, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { format } from 'date-fns'

import { statusAvaliacaoOrtodontica } from '../../../constants/agendamento.constants'
import { toast } from 'react-toastify'

export default function SelecionarAvaliacao(props) {
  const { agendamento, handleSelectAvaliacao, history, avaliacoes, isLoading } = props

  useEffect(() => {
    if (!agendamento.id) {
      history.push('/atendimento')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getStatusLabel = (avaliacao) => {
    const object = Object.values(statusAvaliacaoOrtodontica).find(
      (status) => status.value === avaliacao.statusAvaliacao
    )
    return object ? object.label : '-'
  }

  const possuiRadiografia = (avaliacao) => {
    return !!avaliacao.agendamentosRadiologia.find((agendamento) => {
      return agendamento.radiografiaPronta
    })
  }

  const selecionarAvaliacao = (avaliacao) => {
    if (!possuiRadiografia(avaliacao)) {
      toast('Esta avaliação ainda não possui radiografia concluída', { type: toast.TYPE.WARNING })
    } else if (clickable(avaliacao)) {
      handleSelectAvaliacao(avaliacao)
    } else if (avaliacao.statusAvaliacao === statusAvaliacaoOrtodontica.CANCELADA.value) {
      toast('Esta avaliação foi cancelada.', { type: toast.TYPE.WARNING })
    }
  }

  const clickable = (avaliacao) =>
    avaliacao.statusAvaliacao === statusAvaliacaoOrtodontica.EXAME_MARCADO.value ||
    avaliacao.statusAvaliacao === statusAvaliacaoOrtodontica.EXAME_SOLICITADO.value

  const renderList = () => (
    <Fragment>
      {avaliacoes && avaliacoes.length > 0 ? (
        <Fragment>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Data</th>
                <th>Status</th>
                <th>Radiografia Pronta</th>
              </tr>
            </thead>
            <tbody>
              {avaliacoes.map((avaliacao, index) => (
                <tr
                  key={index}
                  style={clickable(avaliacao) ? { cursor: 'pointer' } : null}
                  onClick={() => selecionarAvaliacao(avaliacao)}
                >
                  <td>{format(new Date(avaliacao.dataCriacao), 'dd/MM/yyyy')}</td>
                  <td>{getStatusLabel(avaliacao)}</td>
                  <td>{possuiRadiografia(avaliacao) ? 'Sim' : 'Não'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Fragment>
      ) : (
        !isLoading && <div>Nenhuma avaliação encontrada.</div>
      )}
    </Fragment>
  )

  return (
    <div className='mb-5 pb-5'>
      <h2 className='text-secondary font-weight-bold mb-4'>Avaliações Ortodônticas</h2>
      {renderList()}
    </div>
  )
}

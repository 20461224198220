import React, { Fragment, useState } from 'react'
import StepWizard from 'react-step-wizard'

import CollapseList from './CollapseList'
import Impressao from './Impressao'

export default function Renegociacao({
  firstStep,
  setIsLoading,
  selectedPlano,
  parcelamentoPagamento,
  refreshPlanosTratamentoOrtodontiaList,
}) {
  const [pagamento, setPagamento] = useState(null)
  const [wizardRef, setWizardRef] = useState(null)
  const [type, setType] = useState('')

  const handleGoBack = () => {
    wizardRef.firstStep()
    firstStep()
    refreshPlanosTratamentoOrtodontiaList()
    setPagamento(null)
  }

  return (
    <Fragment>
      <StepWizard isLazyMount instance={setWizardRef}>
        <CollapseList
          handleGoBack={handleGoBack}
          setIsLoading={setIsLoading}
          parcelamentoPagamento={parcelamentoPagamento}
          setPagamento={setPagamento}
          setType={setType}
          selectedPlano={selectedPlano}
        />
        <Impressao handleGoBack={handleGoBack} pagamento={pagamento} selectedPlano={selectedPlano} type={type} />
      </StepWizard>
    </Fragment>
  )
}

import React from 'react'
import moment from 'moment'
import { formatMoney } from '../../../../utils/string.utils'
import { Table } from 'react-bootstrap'

export default function Parcelas({ parcelas }) {
  return (
    <Table bordered>
      <thead>
        <tr>
          <th className='text-center' width='100px'>
            Parcela
          </th>
          <th className='text-center' width='200px'>
            Valor
          </th>
          <th className='text-center' width='150px'>
            Vencimento
          </th>
        </tr>
      </thead>
      <tbody>
        {parcelas.map((parcela, i) => (
          <tr key={i}>
            <td className='text-center'>{parcela.numero}</td>
            <td className='text-center'>{formatMoney(parcela.valor)}</td>
            <td className='text-center'>{moment(parcela.dataVencimento).format('DD/MM/YYYY')} </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

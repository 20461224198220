import AxiosClient from './axios-client'

class SharedService {
  consultarCEP(cep) {
    return AxiosClient.get(`/Common/consultarCEP/${cep}`)
  }

  getCargos() {
    return AxiosClient.get(`Common/getCargos`)
  }

  getAllEspecialidades() {
    return AxiosClient.get(`Common/getEspecialidades`)
  }

  getEspecialidades(id) {
    return AxiosClient.get(`Common/getEspecialidades/${id}`)
  }

  downloadArquivo(nomeArquivo) {
    const apiURL = AxiosClient.getBaseURL()
    window.open(`${apiURL}/Common/downloadArquivo/${nomeArquivo}`)
    // return AxiosClient.get(`Common/downloadArquivo/${nomeArquivo}`, {
    // 	responseType: 'arraybuffer',
    // })
  }
}

export default new SharedService()

import React, { Fragment, useState, useEffect } from 'react'
import { Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

import MenuContext from '../../contexts/MenuContexts'
import PageLoading from '../Common/PageLoading'
import PermissoesConstants from '../../constants/permissoes.constants'
import TemplatesService from '../../services/templates.service'

const TemplatesList = (props) => {
  let acoesPermitidas = {}

  const [isLoading, setIsLoading] = useState(true)
  const [templates, setTemplates] = useState([])

  useEffect(() => {
    getTemplates()
  }, [])

  const getTemplates = async () => {
    setIsLoading(true)
    try {
      const { data } = await TemplatesService.getAllTemplates()
      setTemplates(data)
    } catch (e) {
      toast('Não foi possível mostrar os templates.', { type: toast.TYPE.ERROR })
    }
    setIsLoading(false)
  }

  const editarTemplate = (template) => {
    props.selectTemplate(template)
    props.history.push('/templates/editar')
  }

  const renderLoading = () => <PageLoading />

  const renderList = () => (
    <Fragment>
      {templates.length > 0 ? (
        <Table bordered hover className='bg-white'>
          <thead>
            <tr>
              <th>Nome</th>
              {acoesPermitidas[PermissoesConstants.EDITAR] && <th width='70px'>Ações</th>}
            </tr>
          </thead>
          <tbody>
            {templates.map((template, index) => (
              <tr key={index}>
                <td>{template.nome}</td>
                {acoesPermitidas[PermissoesConstants.EDITAR] && (
                  <td className='text-center'>
                    <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Editar Template</Tooltip>}>
                      <Button onClick={() => editarTemplate(template)} variant='primary' size='sm'>
                        <FontAwesomeIcon icon={faPen} />
                      </Button>
                    </OverlayTrigger>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        'Nenhum template encontrado.'
      )}
    </Fragment>
  )

  return (
    <MenuContext.Consumer>
      {(acoes) => {
        acoes.map((a) => {
          acoesPermitidas[`${a.nome}`] = a
          return true
        })
        return (
          <Fragment>
            <h1 className='h3 mb-4 text-secondary font-weight-bold'>Templates</h1>
            {isLoading ? renderLoading() : renderList()}
          </Fragment>
        )
      }}
    </MenuContext.Consumer>
  )
}

export default TemplatesList

import { HubConnectionBuilder } from '@aspnet/signalr'

import WebSocketService from './services/websocket.service'
import SystemConstants from './constants/system.constants'

const signalRConnection = new HubConnectionBuilder().withUrl(`${SystemConstants.getWebSocketURL()}`).build()

const service = new WebSocketService(signalRConnection)

const initServer = async (colaboradorId, dispatch) => {
  try {
    await service.startServer()
    await service.setEventListeners(colaboradorId, dispatch)
    service.keepAlive()
  } catch (error) {}
}

const stopServer = async () => {
  try {
    await service.stopServer()
  } catch (error) {}
}

const enviarMensagem = async (tipoMensagem, cargo, mensagem, caminho = null) => {
  try {
    await service.enviarMensagem(tipoMensagem, cargo, mensagem, caminho)
  } catch (error) {
    console.log(error);
  }
}

export { initServer, stopServer, enviarMensagem }

import React, { useState } from 'react'
import StepWizard from 'react-step-wizard'

import Financeiro from './Financeiro'
import Impressao from './Impressao'

const Renegociacao = ({ selectedPlano, firstStep, parcelas, isLoading, setIsLoading, currentStep, passos }) => {
  const [pagamento, setPagamento] = useState(null)
  const [totalPagamento, setTotal] = useState(0.0)

  const handleSetPagamento = async (pag) => {
    setPagamento(pag)
  }

  const handleBack = () => {
    firstStep()
    setPagamento(null)
  }

  return (
    <StepWizard isLazyMount>
      <Financeiro
        isRenegociacao={currentStep === passos.RENEGOCIAR}
        parcelasEmAberto={parcelas}
        selectedPlano={selectedPlano}
        setPagamento={handleSetPagamento}
        onBack={firstStep}
        totalPagamento={totalPagamento}
        setTotal={setTotal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <Impressao selectedPlano={selectedPlano} pagamento={pagamento} onBack={handleBack} />
    </StepWizard>
  )
}

export default Renegociacao

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AvaliacaoOrtodontiaDentista from '../../components/Ortodontia/AvaliacaoOrtodontiaDentista'

const mapStateToProps = (store) => ({
  agendamento: store.avaliacaoState.agendamento,
  anamneseFichaId: store.avaliacaoState.anamneseFichaId,
  pacienteId: store.avaliacaoState.pacienteId,
  pacienteNome: store.avaliacaoState.pacienteNome,
  pacienteCpf: store.avaliacaoState.pacienteCpf,
  perguntasAnamnese: [...store.anamneseState.anamnese].filter((p) => p.ativo),
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AvaliacaoOrtodontiaDentista)

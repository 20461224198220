import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Container, Form, Button, Spinner } from 'react-bootstrap'
import MaskedInput from 'react-text-mask'
import { onlyNumbers } from '@brazilian-utils/helper-only-numbers'

import AccountService from './../services/account.service'
import SystemConstants from '../constants/system.constants'
import { TIPO_USUARIO } from '../constants/usuario.constants'
import { cpfMask, cnpjMask } from '../utils/mask.utils'
import Swal from 'sweetalert2/dist/sweetalert2.js'

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cpfcnpj: '',
      senha: '',
      loading: true,
      franquiaId: '',
      authenticated: !!localStorage.getItem(SystemConstants.getTokenKey()),
      tipoUsuario: null,
    }
  }

  async componentDidMount() {
    const { autenticado, isProtetico, requestMenu } = this.props
    const search = this.props.location.search
    const parametros = new URLSearchParams(search)
    const sessaoExpirada = parametros.get('status')

    if (sessaoExpirada === 'sessionexpired') {
      Swal.fire('Sessão expirou!', 'Sua sessão expirou, é necessário efetuar o login novamente.', 'error').then(() => {
        localStorage.removeItem(SystemConstants.getTokenKey())
      })
    }

    if (autenticado && !isProtetico) {
      requestMenu()
    }
    this.setState({ loading: false })
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.setState({ loading: true }, async () => {
      const { cpfcnpj, senha, franquiaId } = this.state
      const request = {
        cpfcnpj: onlyNumbers(cpfcnpj),
        password: senha,
        franquiaId,
      }
      try {
        const { data } = await AccountService.login(request)
        if (data.authenticated) {
          localStorage.setItem(SystemConstants.getTokenKey(), data.accessToken)
          localStorage.setItem(SystemConstants.getUser(), JSON.stringify(data))
          localStorage.setItem(SystemConstants.getFranquia(), data.franquiaId)
          if (data.responseChat && data.responseChat.data) {
            localStorage.setItem(SystemConstants.getChatStatus(), data.responseChat.status)
            localStorage.setItem(SystemConstants.getChatUserId(), data.responseChat.data.userId)
            localStorage.setItem(SystemConstants.getChatTokenKey(), data.responseChat.data.authToken)
          }
          if (data.mesesAConfigurar) {
            localStorage.setItem(SystemConstants.getAtualizarAgenda(), data.mesesAConfigurar)
          }
          this.props.setUser(data)
          this.props.requestMenu()

          this.setState({
            loading: false,
            authenticated: data.authenticated,
            tipoUsuario: data.tipoUsuario,
          })
        }
      } catch (exception) {
        this.setState({ ...this.state, loading: false })
      }
    })
  }

  onChangeFranquia = (e) => this.setState({ franquiaId: e.target.value })

  render() {
    const { authenticated, loading, error, senha, cpfcnpj, errorFeedback, franquiaId, tipoUsuario } = this.state
    const { menus } = this.props
    if (!authenticated) {
      return (
        <Container className='bg-primary pt-5' fluid style={{ minHeight: '100vh' }}>
          <Form onSubmit={this.onSubmit} className='form-login pt-5'>
            <img className='mb-4' src='/img/logo-login.png' alt='CredOdonto' />

            <MaskedInput
              style={{ ...inputStyle }}
              mask={(a, b) => {
                return onlyNumbers(a).length > 11 ? cnpjMask : cpfMask
              }}
              className='form-control'
              id='cpfcnpj'
              name='cpfcnpj'
              placeholder={'CPF/CNPJ'}
              value={cpfcnpj}
              onChange={this.onChange}
              required
              autoFocus
            />
            <Form.Control
              style={{ ...inputStyle }}
              type='password'
              name='senha'
              value={senha}
              onChange={this.onChange}
              placeholder='Senha'
              required
            />
            <div className='small text-center text-white mt-2'>{error && errorFeedback}</div>
            <Button type='submit' className='btn-login py-3 mt-4 mb-2' variant='light' disabled={loading} block>
              {loading ? <Spinner as='span' animation='border' size='sm' /> : <span>Entrar</span>}
            </Button>
            <Link to='/recover' className='btn-forget' disabled={loading}>
              Esqueceu sua senha?
            </Link>
          </Form>
        </Container>
      )
    } else {
      if (this.props.tipoUsuario == TIPO_USUARIO.Protetico) {
        return <Redirect to='/protetico/editar' />
      } else if (this.props.tipoUsuario == TIPO_USUARIO.Franqueador) {
        return <Redirect to='/franquias' />
      } else {
        return menus.length > 0 ? <Redirect to={menus[0].caminho} /> : <div />
      }
    }
  }
}

export default Login

const inputStyle = {
  height: '40px',
}

import AxiosClient from './axios-client'

class ProteticoService {
  cadastrarProtetico(cnpj, email, razaoSocial) {
    return AxiosClient.post(`Protetico/add`, { cnpj, email, razaoSocial })
  }

  async updateProtetico(protetico) {
    return await AxiosClient.put(`Protetico/update`, protetico)
  }

  getAllProteticos() {
    return AxiosClient.get(`Protetico/getAll`)
  }

  getById(proteticoId) {
    return AxiosClient.get(`Protetico/getById/${proteticoId}`)
  }

  getByCnpj(cnpj) {
    return AxiosClient.get(`Protetico/getByCnpj/${cnpj}`)
  }

  getProteticoByProcedimentoId(procedimentoId) {
    return AxiosClient.get(`Protetico/getByProcedimento/${procedimentoId}`)
  }

  getProteticoFranquia() {
    return AxiosClient.get(`Protetico/getProteticoFranquia`)
  }

  getAcompanhamentoProtese(proteticoId) {
    return AxiosClient.post('PlanoTratamento/acompanhamentoProtese', {
      filtros: [{ campo: 'proteticoId', valor: proteticoId }],
    })
  }

  async validarConfeccaoProtese(planoTratamentoId, planoTratamentoProcedimentoProteticoId) {
    return await AxiosClient.put(
      `PlanoTratamento/validarConfeccaoProtese/${planoTratamentoId}/${planoTratamentoProcedimentoProteticoId}`
    )
  }

  async alterarSituacaoProcedimentoProtetico(
    planoTratamentoId,
    planoTratamentoProcedimentoProteticoId,
    statusProcedimentoProtese
  ) {
    return await AxiosClient.put(
      `PlanoTratamento/alterarSituacaoProcedimentoProtetico/${planoTratamentoId}/${planoTratamentoProcedimentoProteticoId}/${statusProcedimentoProtese}`
    )
  }

  async togglePermitirAlterarPreco(proteticoId, checked) {
    return await AxiosClient.put(`Protetico/alterarPermitirAlterarPreco/${proteticoId}/${checked}`)
  }

  async toggleAtivarInativar(proteticoId, newActiveState) {
    return await AxiosClient.put(`Protetico/ativar/${proteticoId}/${newActiveState}`)
  }

  async updateProteticoFranquia(protetico) {
    return await AxiosClient.put(`Protetico/alterarProteticoFranquia`, protetico)
  }
}

export default new ProteticoService()

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Avaliacao from '../../components/Avaliacao'

const mapStateToProps = (store) => ({
  acoes: store.perfisState.acoes,
  menus: store.perfisState.menus,
  pacienteId: store.avaliacaoState.pacienteId,
  pacienteNome: store.avaliacaoState.pacienteNome,
  pacienteCpf: store.avaliacaoState.pacienteCpf,
  agendamento: store.avaliacaoState.agendamento,
  anamneseFichaId: store.avaliacaoState.anamneseFichaId,
  perguntasAnamnese: [...store.anamneseState.anamnese].filter((p) => p.ativo),
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Avaliacao)

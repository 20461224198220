import { connect } from 'react-redux'
import { requestMenu } from '../../services/actions/perfis.actions'
import { login, setUser } from '../../services/actions/account.actions'
import { bindActionCreators } from 'redux'

import Login from '../../pages/Login'

const mapStateToProps = (store) => ({
  autenticado: store.accountState.user.authenticated,
  isProtetico: !!store.accountState.user.proteticoDTO,
  menus: store.perfisState.menus,
  tipoUsuario: store.accountState.user.tipoUsuario
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ requestMenu, login, setUser }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Login)

import AxiosClient from './axios-client'

class FaceComparisonService {
    matchPatient(patientId, payload) {
        return AxiosClient.post(`FaceComparison/${patientId}/face-comparison-patient`, payload)
    }

    matchPatientByDependentId(dependentId, payload) {
        return AxiosClient.post(`FaceComparison/${dependentId}/face-comparison-dependent`, payload)
    }
}

export default new FaceComparisonService()

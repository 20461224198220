import React, { Fragment } from 'react'
import { Table, FormControl, InputGroup, Button, Spinner, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'

import parametrosService from '../../services/parametros.service'
import MenuContext from '../../contexts/MenuContexts'
import AttributeTypes from '../../constants/attributetype.constants'
import PageLoading from '../Common/PageLoading'

export default class ParametrosList extends React.Component {
  /**
   * @constructor
   * @param {*} props
   */
  constructor(props) {
    super(props)

    this.state = {
      parametros: [],
      isLoading: true,
    }
  }

  /**
   * Busca lista de parametros ao inicializar componente
   */
  componentDidMount() {
    this.getParametrosList()
  }

  /**
   * Usando para alterar valor de um campo boleano
   */
  onChangeBoolean = (index, e) => {
    const parametros = this.state.parametros
    parametros[index].valor = e.target.checked
    parametros[index].changed = true
    this.setState({ parametros: parametros })
  }

  /**
   * Mostra mensagem de sucesso
   */
  onSuccess = () => {
    toast('O valor do parâmetro foi atualizado com sucesso!', { type: toast.TYPE.SUCCESS })
  }

  /**
   * Mostra mensagem de erro
   */
  onError = () => {
    toast('Houve um erro ao modificar o valor do parâmetro!', { type: toast.TYPE.ERROR })
  }

  /**
   * Usado para alterar o valor ao mudar o campo
   */
  onChange = (index, e) => {
    const parametros = this.state.parametros
    parametros[index].valor = e.target.value
    parametros[index].changed = true
    this.setState({ parametros: parametros })
  }

  /**
   * Usado para salvar atualizacoes nos parametros
   */
  onSave = (index) => {
    const parametros = this.state.parametros
    parametros[index].loading = true
    this.setState({ parametros: parametros }, () => {
      parametrosService.update(parametros[index]).then(
        () => {
          parametros[index].loading = false
          parametros[index].changed = false
          this.setState({ parametros: parametros })
          this.onSuccess()
        },
        () => {
          parametros[index].loading = false
          this.setState({ parametros: parametros })
          this.onError()
        }
      )
    })
  }

  /**
   * Busca a lista de parametros
   */
  getParametrosList() {
    parametrosService.getAll().then((resp) => {
      this.setState({ parametros: resp.data, isLoading: false })
    })
  }

  /**
   * Retorna campo tipo string/text
   */
  getFieldString = (parametro, index, acoesPermitidas) => {
    return (
      <InputGroup>
        <FormControl
          value={parametro.valor}
          type='text'
          onChange={(e) => this.onChange(index, e)}
          disabled={parametro.loading || Object.keys(acoesPermitidas).length === 0}
        />
        <InputGroup.Append>
          <Button
            variant='success'
            disabled={!parametro.changed || !parametro.valor || parametro.loading}
            onClick={() => this.onSave(index)}
          >
            {parametro.loading ? <Spinner as='span' animation='border' size='sm' /> : <span>Salvar</span>}
          </Button>
        </InputGroup.Append>
      </InputGroup>
    )
  }

  /**
   * Retorna campo tipo decimal
   */
  getFieldDecimal = (parametro, index, acoesPermitidas) => {
    return (
      <InputGroup>
        <FormControl
          value={parametro.valor}
          type='number'
          onChange={(e) => this.onChange(index, e)}
          disabled={parametro.loading || Object.keys(acoesPermitidas).length === 0}
        />
        <InputGroup.Append>
          <Button
            variant='success'
            disabled={!parametro.changed || !parametro.valor || parametro.loading}
            onClick={() => this.onSave(index)}
          >
            {parametro.loading ? <Spinner as='span' animation='border' size='sm' /> : <span>Salvar</span>}
          </Button>
        </InputGroup.Append>
      </InputGroup>
    )
  }

  /**
   * Retorna campo tipo integer
   */
  getFieldInteger = (parametro, index, acoesPermitidas) => {
    return (
      <InputGroup>
        <FormControl
          value={parametro.valor}
          type='number'
          onChange={(e) => this.onChange(index, e)}
          disabled={parametro.loading || Object.keys(acoesPermitidas).length === 0}
        />
        <InputGroup.Append>
          <Button
            variant='success'
            disabled={!parametro.changed || !parametro.valor || parametro.loading}
            onClick={() => this.onSave(index)}
          >
            {parametro.loading ? <Spinner as='span' animation='border' size='sm' /> : <span>Salvar</span>}
          </Button>
        </InputGroup.Append>
      </InputGroup>
    )
  }

  /**
   * Retorna campo tipo datetime
   */
  getFieldDatetime = (parametro, index, acoesPermitidas) => {
    return (
      <InputGroup>
        <FormControl
          value={parametro.valor}
          type='date'
          onChange={(e) => this.onChange(index, e)}
          disabled={parametro.loading || Object.keys(acoesPermitidas).length === 0}
        />
        <InputGroup.Append>
          <Button
            variant='success'
            disabled={!parametro.changed || !parametro.valor || parametro.loading}
            onClick={() => this.onSave(index)}
          >
            {parametro.loading ? <Spinner as='span' animation='border' size='sm' /> : <span>Salvar</span>}
          </Button>
        </InputGroup.Append>
      </InputGroup>
    )
  }

  /**
   * Retorna campo tipo time
   */
  getFieldTime = (parametro, index, acoesPermitidas) => {
    return (
      <InputGroup>
        <FormControl
          value={parametro.valor}
          type='time'
          onChange={(e) => this.onChange(index, e)}
          disabled={parametro.loading || Object.keys(acoesPermitidas).length === 0}
        />
        <InputGroup.Append>
          <Button
            variant='success'
            disabled={!parametro.changed || !parametro.valor || parametro.loading}
            onClick={() => this.onSave(index)}
          >
            {parametro.loading ? <Spinner as='span' animation='border' size='sm' /> : <span>Salvar</span>}
          </Button>
        </InputGroup.Append>
      </InputGroup>
    )
  }

  /**
   * Retorna campo tipo boolean
   */
  getFieldBoolean = (parametro, index, acoesPermitidas) => {
    return (
      <InputGroup>
        <FormControl
          value={parametro.valor}
          defaultChecked={parametro.valor === 'true'}
          type='checkbox'
          onChange={(e) => this.onChangeBoolean(index, e)}
          disabled={parametro.loading || Object.keys(acoesPermitidas).length === 0}
        />
        <InputGroup.Append>
          <Button
            variant='success'
            disabled={!parametro.changed || parametro.loading}
            onClick={() => this.onSave(index)}
          >
            {parametro.loading ? <Spinner as='span' animation='border' size='sm' /> : <span>Salvar</span>}
          </Button>
        </InputGroup.Append>
      </InputGroup>
    )
  }

  /**
   * Retorna campo conforme tipo do parametro
   */
  getParametroField = (parametro, index, acoesPermitidas) => {
    if (parametro.tipoValor === AttributeTypes.TIPO_STRING) {
      return this.getFieldString(parametro, index, acoesPermitidas)
    } else if (parametro.tipoValor === AttributeTypes.TIPO_DECIMAL) {
      return this.getFieldDecimal(parametro, index, acoesPermitidas)
    } else if (parametro.tipoValor === AttributeTypes.TIPO_INTEGER) {
      return this.getFieldInteger(parametro, index, acoesPermitidas)
    } else if (parametro.tipoValor === AttributeTypes.TIPO_DATETIME) {
      return this.getFieldDatetime(parametro, index, acoesPermitidas)
    } else if (parametro.tipoValor === AttributeTypes.TIPO_BOOLEAN) {
      return this.getFieldBoolean(parametro, index, acoesPermitidas)
    } else if (parametro.tipoValor === AttributeTypes.TIPO_TIME) {
      return this.getFieldTime(parametro, index, acoesPermitidas)
    }

    return parametro.valor
  }

  /**
   * Usado para renderizar a linha do parametro na tabela
   */
  getParametroRow = (parametro, index, acoesPermitidas) => {
    return (
      <tr key={index}>
        <td>{parametro.nome}</td>
        <td>{parametro.descricao}</td>
        <td>{this.getParametroField(parametro, index, acoesPermitidas)}</td>
      </tr>
    )
  }

  render() {
    let acoesPermitidas = {}
    const { isLoading } = this.state
    return (
      <MenuContext.Consumer>
        {(acoes) => {
          acoes.map((a) => {
            acoesPermitidas[`${a.nome}`] = a
            return true
          })
          return (
            <Fragment>
              <Row className='mb-4'>
                <Col>
                  <h1 className='h3 m-0 text-secondary font-weight-bold'>Parâmetros</h1>
                </Col>
              </Row>
              <Table bordered hover  className='bg-white'>
                <thead>
                  <tr>
                    <th>Parâmetro</th>
                    <th>Descrição</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.parametros.map((parametro, index) =>
                    this.getParametroRow(parametro, index, acoesPermitidas)
                  )}
                </tbody>
              </Table>
              {isLoading && <PageLoading />}
            </Fragment>
          )
        }}
      </MenuContext.Consumer>
    )
  }
}

export const respostasAnamnese = [
  { label: 'Sim/Não', value: 0 },
  { label: 'Sim/Não/Não Sei', value: 1 },
]

export const opcoesResposta = [
  { label: 'Sim', value: 0 },
  { label: 'Não', value: 1 },
  { label: 'Não Sei', value: 2 },
]

export const tiposResposta = {
  0: [opcoesResposta[0], opcoesResposta[1]],
  1: opcoesResposta,
}

export const QUADRANTES_DENTES = {
  // PERMANENTES
  SUPERIOR_DIREITO: 'dentesSuperioresDireito',
  SUPERIOR_ESQUERDO: 'dentesSuperioresEsquerdo',
  INFERIOR_DIREITO: 'dentesInferioresDireito',
  INFERIOR_ESQUERDO: 'dentesInferioresEsquerdo',
  // DE LEITE
  DECIDUO_SUPERIOR_DIREITO: 'dentesDeciduosSuperioresDireito',
  DECIDUO_SUPERIOR_ESQUERDO: 'dentesDeciduosSuperioresEsquerdo',
  DECIDUO_INFERIOR_DIREITO: 'dentesDeciduosInferioresDireito',
  DECIDUO_INFERIOR_ESQUERDO: 'dentesDeciduosInferioresEsquerdo',
}

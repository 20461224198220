import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'

export default function Impressao({ handlePrint, handleGoBack }) {
  return (
    <Fragment>
      <div className='mb-5 text-center'>
        <FontAwesomeIcon style={{ fontSize: '11em' }} className='text-success mt-5 mb-3' icon={faCheckCircle} />
        <h2 style={fontStyle} className='text-success'>
          Pagamento realizado com sucesso!
        </h2>
      </div>
      <div className='d-block mx-auto text-center'>
        <Button variant='primary' className=' mr-3' onClick={handlePrint}>
          Imprimir Comprovante
        </Button>
        <Button variant='outline-primary' onClick={handleGoBack}>
          Voltar
        </Button>
      </div>
      <div className='alert alert-warning mt-5 mx-auto w-50'>
        <strong>Pop-ups</strong> bloquados no seu navegador, <strong>AdBlock</strong> habilitado para esta página, ou
        algumas <strong>extensões</strong> e <strong>add-ons</strong> instaladas podem ocasionar em problemas ao tentar
        imprimir os documentos desejados
      </div>
    </Fragment>
  )
}

const fontStyle = {
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
}

import { put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "./axios-client";
import { Perfis, Notificacoes } from "../types";

function* fetchPerfis() {
  try {
    const { data } = yield axios.get("Perfil/getAll");
    yield put({ type: Perfis.LISTAR_PERFIS, perfis: data });
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications });
  }
}

function* getById(action) {
  try {
    if (action.perfilId) {
      let { data } = yield axios.get(`Perfil/getById/${action.perfilId}`);
      data.selectedFuncionalidades = mapFuncionalidades(data.funcionalidades);
      yield put({
        type: Perfis.EDITAR_PERFIL,
        selectedPerfil: data
      });
    } else {
      yield put({
        type: Perfis.EDITAR_PERFIL,
        selectedPerfil: {}
      });
    }
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications });
  }
}

function mapFuncionalidades(funcionalidades) {
  const resultMap = [];
  funcionalidades.map(f => {
    f.acoes.map(a => {
      resultMap.push(a.permissaoId);
      return true;
    });
    return true;
  });
  return resultMap;
}

function* fetchAcoes(action) {
  try {
    const { data } = yield axios.get(`Perfil/getAcoes/${action.menuId}`);
    yield put({ type: Perfis.LISTAR_ACOES, acoes: data });
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications });
  }
}

function* fetchMenu() {
  try {
    const { data } = yield axios.get("Perfil/getItensMenu");
    yield put({ type: Perfis.LISTAR_MENU, menu: data });
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications });
  }
}

function* fetchFuncionalidades() {
  try {
    const { data } = yield axios.get("Perfil/getFuncionalidades");
    yield put({ type: Perfis.LISTAR_FUNCIONALIDADES, funcionalidades: data });
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications });
  }
}

function* salvarPerfil(action) {
  try {
    yield axios.post("Perfil/salvar", action.perfil);
    yield put({ type: Perfis.REQUEST_PERFIS });
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications });
  }
}

function* perfisListener() {
  yield takeEvery(Perfis.REQUEST_PERFIS, fetchPerfis);
  yield takeEvery(Perfis.REQUEST_ACOES, fetchAcoes);
  yield takeEvery(Perfis.REQUEST_FUNCIONALIDADES, fetchFuncionalidades);
  yield takeEvery(Perfis.REQUEST_MENU, fetchMenu);
  yield takeLatest(Perfis.GET_PERFIL_ID, getById);
  yield takeEvery(Perfis.SALVAR_PERFIL, salvarPerfil);
}

export default perfisListener;

import React from 'react'
import ReactPaginate from 'react-paginate'

export default function Paginator({ pageCount, handlePagina, paginaAtual }) {
  return (
    <ReactPaginate
      previousLabel='<'
      nextLabel='>'
      breakLabel='...'
      forcePage={paginaAtual}
      pageCount={pageCount}
      activeLinkClassName='active'
      pageClassName='page-item px-0 mx-1 rounded'
      pageLinkClassName='px-3 py-2 page-link'
      previousClassName='page-item rounded'
      previousLinkClassName='page-link'
      nextClassName='page-item rounded'
      nextLinkClassName='page-link'
      breakLinkClassName='px-2'
      disabledClassName='disabled'
      marginPagesDisplayed={Math.ceil(pageCount / (pageCount * 0.8))}
      pageRangeDisplayed={Math.ceil(pageCount / (pageCount * 0.5))}
      onPageChange={handlePagina}
      containerClassName='pagination px-5 mx-auto justify-content-center pt-3'
      activeClassName='active'
    />
  )
}

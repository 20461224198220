import React, { Fragment } from 'react'
import moment from 'moment'
import { Table } from 'react-bootstrap'

import { formatMoney } from '../../../../../utils/string.utils'

export default function ParcelasList({ toggleParcela, selectedParcelas, isProtetico, parcelas }) {
  return (
    <Fragment>
      {parcelas.length > 0 ? (
        <Table bordered hover>
          <thead>
            <tr>
              <th className='text-center'>Vencimento</th>
              <th className='text-center'>Valor</th>
              {!isProtetico && (
                <Fragment>
                  <th className='text-center'>Desconto</th>
                  <th className='text-center'>Juros</th>
                  <th className='text-center'>Multa</th>
                  <th className='text-center'>Valor Calculado</th>
                </Fragment>
              )}
              <th width='100px' className='text-center'>
                Selecionar
              </th>
            </tr>
          </thead>
          <tbody>
            {parcelas.map((parcela, index) => {
              const {
                dataVencimento,
                valor,
                valorCalculado,
                planoTratamentoPagamentoProteticoParcelaId,
                planoTratamentoPagamentoParcelaId,
                juros,
                multa,
                desconto,
                planoTratamentoPagamentoId,
              } = parcela
              const isLate = valorCalculado > valor
              const id = planoTratamentoPagamentoProteticoParcelaId
                ? planoTratamentoPagamentoProteticoParcelaId
                : planoTratamentoPagamentoParcelaId
              return (
                <tr key={index}>
                  <td className='text-center'>{moment(dataVencimento).format('DD/MM/YYYY')}</td>
                  <td className='text-center'>{formatMoney(valor)}</td>
                  {!isProtetico && (
                    <Fragment>
                      <td className='text-center'>{desconto > 0 ? formatMoney(desconto) : '-'}</td>
                      <td className='text-center'>{juros > 0 ? formatMoney(juros) : '-'}</td>
                      <td className='text-center'>{multa > 0 ? formatMoney(multa) : '-'}</td>
                      <td className={`text-center font-weight-bold ${isLate ? 'text-danger' : 'text-success'}`}>
                        {formatMoney(valorCalculado)}
                      </td>
                    </Fragment>
                  )}
                  <td className='text-center'>
                    <label className='checkbox labeless m-0'>
                      <input
                        type='checkbox'
                        checked={selectedParcelas.includes(id)}
                        onChange={() => toggleParcela(id, index, planoTratamentoPagamentoId)}
                      />
                      <span />
                    </label>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <div>Nenhuma parcela em aberto.</div>
      )}
    </Fragment>
  )
}

import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom'

import SystemConfig from '../../constants/system.constants'

export default class PrivateRoute extends React.Component {
  state = {
    props: { ...this.props },
    authenticated: !!localStorage.getItem(SystemConfig.getTokenKey()),
    precisaConfigurarAgenda:
      localStorage.getItem(SystemConfig.getAtualizarAgenda()) &&
      localStorage.getItem(SystemConfig.getAtualizarAgenda()).length > 0,
  }

  render() {
    const { authenticated, precisaConfigurarAgenda } = this.state
    if (authenticated) {
      return (
        <Fragment>
          {this.props.children}
          {precisaConfigurarAgenda && <Redirect to='/agenda-bloqueio-dentista' />}
        </Fragment>
      )
    } else {
      return <Redirect to='/' />
    }
  }
}

import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Form, Card, Button, Spinner } from 'react-bootstrap'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleDoubleLeft, faAngleRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import DualListBox from 'react-dual-listbox'
import 'react-dual-listbox/lib/react-dual-listbox.css'
import 'moment/locale/pt-br'
import { isMoment } from 'moment'
import AxiosClient from '../../services/axios-client'

class VideosForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      video: {
        link: '',
        nome: '',
        descricao: '',
        dataLimiteExibicao: null,
        ativo: true,
        selectedPerfis: [],
      },
    }
    props.requestPerfis()
  }

  static getDerivedStateFromProps(props, state) {
    if (props.video.id !== state.video.id) {
      const { video } = props
      video.selectedPerfis = video.perfisVideos.length > 0 ? video.perfisVideos.map((p) => p.perfilId) : []
      return { isLoading: false, video: { ...video } }
    }
    return null
  }

  onChange = (e) => {
    this.setState({
      ...this.state,
      video: { ...this.state.video, [e.target.name]: e.target.value },
    })
  }

  onChangeDate = (state, date) => {
    this.setState({
      ...this.state,
      video: { ...this.state.video, [state]: date },
    })
  }

  onChangeVideo = (e) => {
    this.onChange(e)
    const link = e.target.value
    const idYoutube = link.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/)
    if (idYoutube != null) {
      this.setState({
        ...this.state,
        video: { ...this.state.video, idYoutube: idYoutube[1], link },
      })
    }
  }

  onChangePerfil = (selectedPerfis) => {
    this.setState({
      ...this.state,
      video: { ...this.state.video, selectedPerfis },
    })
  }

  salvarVideo = async (video) => {
    try {
      video.id ? await AxiosClient.put('Video/update', video) : await AxiosClient.post('Video/add', video)
      this.props.requestVideos()
      this.props.history.push('/videos')
    } catch (e) {}
  }

  onSubmit = async (e) => {
    e.preventDefault()
    const { video } = this.state
    await this.salvarVideo({
      ...video,
      perfisVideos: video.selectedPerfis.map((p) => ({ perfilId: p })),
      dataLimiteExibicao: isMoment(video.dataLimiteExibicao)
        ? video.dataLimiteExibicao.toDate()
        : video.dataLimiteExibicao,
      dataLimite: undefined,
      selectedPerfis: undefined,
      obrigatorio: undefined,
    })
  }

  render() {
    const { isLoading, video } = this.state
    const { nome, descricao, link, idYoutube, dataLimiteExibicao, selectedPerfis } = video
    return (
      <div>
        <Row className='mb-3'>
          <Col>
            <h1 className='h3 m-0 text-secondary font-weight-bold'>Vídeos</h1>
          </Col>
        </Row>
        <Card className='p-4'>
          <Form onSubmit={this.onSubmit}>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Form.Group className='required'>
                      <Form.Label htmlFor='link'>Link</Form.Label>
                      <Form.Control type='text' id='link' name='link' value={link} onChange={this.onChangeVideo} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='embed-responsive embed-responsive-16by9'>
                      <iframe
                        className='embed-responsive-item'
                        src={'https://www.youtube.com/embed/' + idYoutube}
                        frameBorder='0'
                        allowFullScreen
                        title={this.state.video.nome}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Group className='required'>
                      <Form.Label htmlFor='nome'>Nome</Form.Label>
                      <Form.Control type='text' id='nome' name='nome' value={nome} onChange={this.onChange} required />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor='descricao'>Descrição</Form.Label>
                      <Form.Control
                        type='text'
                        id='descricao'
                        name='descricao'
                        value={descricao}
                        onChange={this.onChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className='required'>
                      <Form.Label htmlFor='dataLimite'>Data Limite de Exibição</Form.Label>
                      <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                        <KeyboardDatePicker
                          invalidDateMessage='Formato de data inválido'
                          id='dataLimiteExibicao'
                          name='dataLimiteExibicao'
                          className='form-control'
                          value={dataLimiteExibicao}
                          onChange={(date) => this.onChangeDate('dataLimiteExibicao', date)}
                          variant='inline'
                          format='DD/MM/YYYY'
                          animateYearScrolling
                          disablePast
                          autoOk
                          required
                        />
                      </MuiPickersUtilsProvider>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className='required'>
                      <DualListBox
                        icons={{
                          moveLeft: <FontAwesomeIcon icon={faAngleLeft} />,
                          moveAllLeft: <FontAwesomeIcon icon={faAngleDoubleLeft} />,
                          moveRight: <FontAwesomeIcon icon={faAngleRight} />,
                          moveAllRight: <FontAwesomeIcon icon={faAngleDoubleRight} />,
                        }}
                        canFilter
                        filterPlaceholder='Pesquisar...'
                        options={this.props.perfis}
                        selectedRef={(ref) => {
                          this.list = ref
                        }}
                        selected={selectedPerfis}
                        onChange={this.onChangePerfil}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Button type='submit' variant='primary' className='float-right'>
              {isLoading ? (
                <Spinner animation='border' size='sm' variant='primary' />
              ) : video.id ? (
                'Atualizar'
              ) : (
                'Cadastrar'
              )}
            </Button>
            <Link to='/videos'>
              <Button variant='light' className='float-right mr-3'>
                Cancelar
              </Button>
            </Link>
          </Form>
        </Card>
      </div>
    )
  }
}

export default VideosForm

import { Procedimentos } from '../types'

const initialState = {
  dentes: [],
  procedimentos: [],
  selectedProcedimento: null,
}

export const procedimentosReducer = (state = initialState, action) => {
  switch (action.type) {
    case Procedimentos.LISTAR_DENTES:
      return { ...state, dentes: action.dentes }
    case Procedimentos.LISTAR_PROCEDIMENTOS:
      return { ...state, procedimentos: action.procedimentos }
    case Procedimentos.SET_PROCEDIMENTO_ID:
      return { ...state, selectedProcedimento: action.procedimento }
    case Procedimentos.RESETAR_PROCEDIMENTO:
      return { ...state, selectedProcedimento: null }
    default:
      return state
  }
}

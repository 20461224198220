import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import OrtodontiaService from '../../../services/ortodontia.service'
import AtendimentoService from '../../../services/atendimento.service'
import { statusConstants, statusAvaliacaoOrtodontica } from '../../../constants/agendamento.constants'
import { opcoesResposta } from '../../../constants/anamnese.constants'
import { encaminhamentoOptions } from '../../../constants/common.constants'

const passos = {
  AVALIACOES: 1,
  ANAMNESE: 2,
  TRATAMENTO: 3,
}

export default function Navigation(props) {
  const { wizard, passo, selectedAvaliacao, history, agendamento, avaliacoes, selectedEncaminhamento } = props

  const [isLoading, setLoading] = useState(false)
  const [podeAvancar, setPodeAvancar] = useState(false)

  useEffect(() => {
    if (passo === passos.ANAMNESE) {
      let naoRespondida = true,
        respostaSimTextoVazio = true
      selectedAvaliacao.anamneseRespostas.every((resposta) => {
        if (resposta === undefined) {
          naoRespondida = true
          return false
        }
        if (
          ((resposta.tipoResposta !== null && resposta.tipoResposta === String(opcoesResposta[0].value)) ||
            resposta.tipoResposta === null) && // Resposta 'Sim'
          resposta.texto !== null &&
          resposta.texto.trim().length === 0
        ) {
          respostaSimTextoVazio = true
          return false
        }
        naoRespondida = false
        respostaSimTextoVazio = false
        return true
      })
      setPodeAvancar(!naoRespondida && !respostaSimTextoVazio)
    } else if (passo === passos.TRATAMENTO) {
      if (selectedEncaminhamento && selectedEncaminhamento.value === encaminhamentoOptions.APROVADO.value) {
        setPodeAvancar(!!selectedAvaliacao.aparelhoId)
      } else {
        setPodeAvancar(!!selectedEncaminhamento)
      }
    } else {
      setPodeAvancar(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAvaliacao, passo, selectedEncaminhamento])

  const scrollToTheTop = () => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }

  const proximoPasso = async () => {
    if (passo === passos.ANAMNESE && !selectedAvaliacao.id) {
      salvarAnamnese()
    } else if (passo === passos.TRATAMENTO) {
      const { value } = await Swal.fire({
        title:
          'Ao finalizar o atendimento todos os procedimentos serão salvos e não será possível mais alterar. Deseja confirmar?',
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#dbdbdb',
        cancelButtonText: 'Não confirmar',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Confirmar',
      })
      if (value) {
        salvarAnamnese()
      }
    } else {
      wizard.nextStep()
      scrollToTheTop()
    }
  }

  const passoAnterior = () => {
    wizard.previousStep()
    scrollToTheTop()
  }

  const salvarAnamnese = async () => {
    setLoading(true)
    try {
      let fichaSalva = { ...selectedAvaliacao }
      fichaSalva.agendamentoId = agendamento.id
      if (fichaSalva.id && selectedEncaminhamento) {
        switch (selectedEncaminhamento.value) {
          case encaminhamentoOptions.APROVADO.value:
            fichaSalva.statusAvaliacao = statusAvaliacaoOrtodontica.TRATAMENTO_DEFINIDO.value
            break
          case encaminhamentoOptions.PACIENTE_REJEITOU.value:
            fichaSalva.statusAvaliacao = statusAvaliacaoOrtodontica.REJEITADA_PACIENTE.value
            break
          case encaminhamentoOptions.PROFISSIONAL_REJEITOU.value:
            fichaSalva.statusAvaliacao = statusAvaliacaoOrtodontica.REJEITADA_DENTISTA.value
            break
          default:
            fichaSalva.statusAvaliacao = statusAvaliacaoOrtodontica.EXAME_SOLICITADO.value
            break
        }
      } else {
        fichaSalva.statusAvaliacao = statusAvaliacaoOrtodontica.EXAME_SOLICITADO.value
      }
      if (passo === passos.TRATAMENTO) {
        delete fichaSalva.agendamentosRadiologia
      }
      await OrtodontiaService.salvarAvaliacao(fichaSalva)
      await AtendimentoService.salvarStatus(agendamento.id, statusConstants.FINALIZADO.value)
      toast(`Avaliação salva com sucesso`, { type: toast.TYPE.SUCCESS })
      history.push('/atendimento')
    } catch (error) {}
    setLoading(false)
  }

  return (
    wizard && (
      <Row id='wizard-navigation' className='border-top position-fixed rounded-0 py-3 bg-white'>
        <Col>
          {passo !== passos.AVALIACOES && (
            <Button disabled={avaliacoes.length === 0} onClick={passoAnterior} className='border' variant='light'>
              Anterior
            </Button>
          )}
        </Col>
        <Col className='text-right'>
          <Button disabled={!podeAvancar} onClick={proximoPasso} variant='primary'>
            {isLoading && <Spinner animation='border' size='sm' variant='light' />}
            {!isLoading && passo === passos.AVALIACOES && 'Criar Avaliação'}
            {!isLoading && passo === passos.ANAMNESE && (!selectedAvaliacao.id ? 'Finalizar Avaliação' : 'Próximo')}
            {!isLoading && passo === passos.TRATAMENTO && 'Finalizar Atendimento'}
          </Button>
        </Col>
      </Row>
    )
  )
}

import React, { useState, Fragment } from 'react'
import Moment from 'moment'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { Button, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faDollarSign } from '@fortawesome/free-solid-svg-icons'

import PlanoTratamentoService from '../../../services/planoTratamento.service'
import { TIPOS_TEMPLATE } from '../../../constants/template.constants'
import { statusPlano } from '../../../constants/common.constants'
import SystemConstants from '../../../constants/system.constants'
import { cargoConstant } from '../../../constants/common.constants'


import { printParcelasReceipt } from '../../Templates/PrintableReceipt'
import { format } from 'date-fns'

const PacientePlanosTratamento = (props) => {
  const { passos, onChangeStep, planosTratamentoList } = props
  const [menuPrintAnchorEl, setPrintMenuAnchorEl] = useState(null)
  const [selectedPrintMenu, setSelectedPrintMenu] = useState(null)
  const [menuFinancialAnchorEl, setFinancialMenuAnchorEl] = useState(null)
  const [selectedFinancialMenu, setSelectedFinancialMenu] = useState(null)

  const [menuProteticoMenuAnchorEl, setProteticoMenuAnchorEl] = useState(null)
  const [selectedProteticoMenu, setSelectedProteticoMenu] = useState(null)

  const [selectedPlanoTratamentoId, setSelectedPlanoTratamentoId] = useState(null)

  const [proteticosRenegociados, setProteticosRenegociados] = useState([])
  const [parcelasProteticoEmAberto, setParcelasProteticoEmAberto] = useState([])

  const user = JSON.parse(localStorage.getItem(SystemConstants.getUser()))
  const isAdmin = user.podeAlternarConta

  const handleOpenPrintMenu = async (event, index, planoTratamentoId) => {
    setPrintMenuAnchorEl(event.target)
    setSelectedPrintMenu(index)
    setSelectedPlanoTratamentoId(planoTratamentoId)

    await getPagamentosRenegociadosProtetico(planoTratamentoId)
  }

  const getPagamentosRenegociadosProtetico = async (planoTratamentoId) => {
    const { data } = await PlanoTratamentoService.getPagamentosRenegociadosProtetico(planoTratamentoId)
    setProteticosRenegociados(data)
  }

  const handlePrintClick = async (planoTratamentoId, tipoTemplate) => {
    handleClosePrintMenu()
    await PlanoTratamentoService.downloadTemplateAsPDF(planoTratamentoId, tipoTemplate)
  }

  const handleClosePrintMenu = () => {
    setPrintMenuAnchorEl(null)
    setSelectedPrintMenu(null)
  }

  const handleOpenFinancialMenu = (event, index, planoTratamentoId) => {
    setFinancialMenuAnchorEl(event.target)
    setSelectedFinancialMenu(index)

    getParcelasProteticoEmAberto(planoTratamentoId)
  }

  const getParcelasProteticoEmAberto = async (planoTratamentoId) => {
    const { data } = await PlanoTratamentoService.getParcelasEmAberto(planoTratamentoId, true, true)
    setParcelasProteticoEmAberto(data.parcelasProteticas)
  }

  const handleCloseFinancialMenu = () => {
    setFinancialMenuAnchorEl(null)
    setSelectedFinancialMenu(null)
  }

  const handleStepChange = (plano, step) => {
    onChangeStep(plano, step)
    handleCloseFinancialMenu()
  }

  /***** INIT PROTETICO *****/

  const handleOpenProteticoMenu = (event, index) => {
    setProteticoMenuAnchorEl(event.target)
    setSelectedProteticoMenu(index)
  }

  const handleCloseProteticoMenu = () => {
    setProteticoMenuAnchorEl(null)
    setSelectedProteticoMenu(null)
  }

  const handleProteticoClick = async (planoTratamentoId, parcelamentoRenegociadoId) => {
    handleCloseProteticoMenu()
    await PlanoTratamentoService.downloadTemplateAsPDFByParteNome(planoTratamentoId, TIPOS_TEMPLATE.TermoDeRenegociacaoProtetico, parcelamentoRenegociadoId)
  }

  /***** END PROTETICO *****/

  const getStatusLabel = (status) => {
    const statusPlanoTratamento = Object.values(statusPlano).find((s) => s.value === status)
    return statusPlanoTratamento && statusPlanoTratamento.label ? statusPlanoTratamento.label : ''
  }

  const handlePrintParcelamento = async (plano) => {
    const pagamentoPlano = plano.pagamentos[plano.pagamentos.length - 1]
    const procedimentosProteticos = plano.procedimentos.filter((proc) => !!proc.planoTratamentoProcedimentoProtetico)

    const pagProteticosAux = procedimentosProteticos
      .map(
        (proc) =>
          proc.planoTratamentoProcedimentoProtetico.planoTratamentoPagamentoProteticoAtual
      )

    const pagProteticos = pagProteticosAux.filter((proc) => !!proc)

    let dente = ''
    const nomeProtese = []
    plano.procedimentos.map(procedimento => {
      if (procedimento.planoTratamentoProcedimentoProteticoId) {
        if (procedimento.dente)
          dente = ' - Dente: ' + procedimento.dente.codigo

        nomeProtese.push({ 'id': procedimento.planoTratamentoProcedimentoProteticoId, 'nome': procedimento.planoTratamentoProcedimentoProtetico.procedimentoProtetico.nome + dente })

        dente = ''
      }
    });

    const receipt = {
      numeroPlano: plano.numeroPlano,
      nomeContratante: plano.paciente.nome,
      nomePaciente: plano.dependente ? plano.dependente.nome : plano.paciente.nome

    }

    printParcelasReceipt({
      ...receipt,
      parcelas: pagamentoPlano.parcelas,
      valorTotal: pagamentoPlano.valorTotal,
    })

    pagProteticos.forEach((pagamento) => {
      printParcelasReceipt({
        ...receipt,
        nomeProtese: pagamento.planoTratamentoProcedimentoProteticoId ? nomeProtese.find((x) => x.id == pagamento.planoTratamentoProcedimentoProteticoId).nome : '',
        parcelas: pagamento.parcelas,
        valorTotal: pagamento.valorTotal,
        type: 'protese'
      })
    })
  }

  const renderTable = () => (
    <Table bordered hover style={{ backgroundColor: 'white' }}>
      <thead>
        <tr className='text-center'>
          <th>Número do Plano</th>
          <th>Paciente</th>
          <th>Iniciado em</th>
          <th>Cancelado em</th>
          <th>Status</th>
          <th>Data de finalização</th>
          <th width='100px'>Ações</th>
        </tr>
      </thead>
      <tbody>
        {planosTratamentoList.map((plano, index) => (
          <tr className='text-center' key={index}>
            <td>{plano.numeroPlano}{plano.comParceiroFinanceiro && <><br /><span className='badge badge-danger'>Com Parceiro</span></>}</td>
            <td>{plano.dependenteId ? plano.dependente.nome : plano.paciente.nome}</td>
            <td>{format(new Date(plano.dataCriacao), 'dd/MM/yyyy')}</td>
            <td>{plano.dataCancelamento !== null ? format(new Date(plano.dataCancelamento), 'dd/MM/yyyy') : '-'}</td>
            <td>{getStatusLabel(plano.statusTratamento)}</td>
            {plano.dataFinalizacao ? (
              <td>
                <p className='m-0'>{format(new Date(plano.dataFinalizacao), 'dd/MM/yyyy')}</p>
                <p className='m-0'>{format(new Date(plano.dataFinalizacao), 'HH:mm')}</p>
              </td>
            ) : (
              <td>-</td>
            )}

            <td>
              <div className='justify-content-between'>
                <Button onClick={(event) => handleOpenFinancialMenu(event, index, plano.id)} variant='primary' size='sm' style={{ width: '32px', height: '32px', marginRight: '5px' }}>
                  <FontAwesomeIcon icon={faDollarSign} />
                </Button>
                <Menu
                  anchorEl={menuFinancialAnchorEl}
                  open={selectedFinancialMenu === index}
                  onClose={handleCloseFinancialMenu}
                  keepMounted
                >
                  {isAdmin &&
                    <MenuItem onClick={() => handleStepChange(plano, passos.VENCIMENTO)}>Alterar Vencimento</MenuItem>
                  }
                  
                  {(!plano.paciente.negativado || (plano.paciente.negativado && !plano.podeRenegociar)) &&
                    <MenuItem onClick={() => handleStepChange(plano, passos.PAGAR)}>Realizar Pagamento</MenuItem>
                  }

                  {plano.podeRenegociar &&
                    <MenuItem onClick={() => handleStepChange(plano, passos.RENEGOCIAR)}>Renegociar Plano</MenuItem>
                  }

                  {parcelasProteticoEmAberto.length > 0 &&
                    <MenuItem onClick={() => handleStepChange(plano, passos.RENEGOCIAR_PROT)}>Renegociar Protético</MenuItem>
                  }

                  {isAdmin &&
                    <MenuItem onClick={() => handleStepChange(plano, passos.CANCELAR_PROC)}>
                      Cancelar Procedimento
                    </MenuItem>
                  }

                  {isAdmin &&
                    <MenuItem onClick={() => handleStepChange(plano, passos.CANCELAR_PAG)}>Cancelar Pagamento</MenuItem>
                  }

                  <MenuItem onClick={() => handleStepChange(plano, passos.VISUALIZAR_AVAL)}>
                    Visualizar Avaliação
                  </MenuItem>
                  <MenuItem onClick={() => handleStepChange(plano, passos.VISUALIZAR_PROCED)}>
                    Visualizar Procedimentos
                  </MenuItem>
                  <MenuItem onClick={() => handleStepChange(plano, passos.VISUALIZAR_PARC)}>
                    Visualizar Parcelas
                  </MenuItem>
                  <MenuItem onClick={() => handleStepChange(plano, passos.ALTERAR_PROT)}>Alterar Protético</MenuItem>
                </Menu>
                <Button onClick={(event) => handleOpenPrintMenu(event, index, plano.id)} variant='primary' size='sm' style={{ width: '32px', height: '32px' }}>
                  <FontAwesomeIcon icon={faPrint} />
                </Button>
                <Menu
                  anchorEl={menuPrintAnchorEl}
                  open={selectedPrintMenu === index}
                  onClose={handleClosePrintMenu}
                  keepMounted
                >
                  <MenuItem onClick={() => handlePrintParcelamento(plano)}>Parcelamento</MenuItem>
                  <MenuItem onClick={() => handlePrintClick(plano.id, TIPOS_TEMPLATE.Contrato)}>
                    Contrato de Prestação de Serviços
                  </MenuItem>
                  <MenuItem onClick={() => handlePrintClick(plano.id, TIPOS_TEMPLATE.AnexoContrato)}>
                    Anexo do Contrato
                  </MenuItem>
                  <MenuItem onClick={() => handlePrintClick(plano.id, TIPOS_TEMPLATE.TermoDeConsentimentoDeTratamento)}>
                    Termo de Consentimento de Tratamento
                  </MenuItem>
                  {plano.statusTratamento === statusPlano.FINALIZADO.value && (
                    <MenuItem onClick={() => handlePrintClick(plano.id, TIPOS_TEMPLATE.TermoDeConclusaoDeTratamento)}>
                      Termo de Conclusão de Tratamento
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() =>
                      handlePrintClick(plano.id, TIPOS_TEMPLATE.TermoDeCancelamentoDeProcedimentoComPendencia)
                    }
                  >
                    Termo de Cancelamento com Confissão de Dívida
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handlePrintClick(plano.id, TIPOS_TEMPLATE.TermoDeCancelamentoDeProcedimentoComQuitacao)
                    }
                  >
                    Termo de Cancelamento com Quitação
                  </MenuItem>
                  <MenuItem onClick={() => handlePrintClick(plano.id, TIPOS_TEMPLATE.TermoDeRenegociacao)}>
                    Termo de Renegociação
                  </MenuItem>
                  <MenuItem onClick={() => handlePrintClick(plano.id, TIPOS_TEMPLATE.RespostasAnamnese)}>
                    Anamnese
                  </MenuItem>

                  {proteticosRenegociados.length > 0 &&
                    <MenuItem onClick={(event) => handleOpenProteticoMenu(event, index)} >
                      Termo de Renegociação de Protético
                    </MenuItem>
                  }
                  <Menu
                    anchorEl={menuProteticoMenuAnchorEl}
                    open={selectedProteticoMenu === index}
                    onClose={handleCloseProteticoMenu}
                    keepMounted
                  >
                    {proteticosRenegociados.map((proteticoRenegociado, index) => (
                      <MenuItem onClick={() => handleProteticoClick(plano.id, proteticoRenegociado.id)}>{proteticoRenegociado.planoTratamentoProcedimentoProtetico.procedimentoProtetico.nome + (proteticoRenegociado.planoTratamentoProcedimentoProtetico.dente ? ' - ' + proteticoRenegociado.planoTratamentoProcedimentoProtetico.dente.codigo : '')}</MenuItem>
                    ))}
                  </Menu>

                </Menu>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )

  return <Fragment>{planosTratamentoList.length > 0 ? renderTable() : <div>Nenhum plano encontrado para este paciente.</div>}</Fragment>
}

export default PacientePlanosTratamento

import { Anamnese } from '../types'

export const requestAnamnese = () => ({ type: Anamnese.REQUEST_ANAMNESE })

export const editarAnamnese = (anamneseId) => ({ type: Anamnese.GET_ANAMNESE_ID, anamneseId })

export const getByPaciente = (pacienteId) => ({ type: Anamnese.GET_ANAMNESE_ID, pacienteId })

export const salvarAnamnese = (anamnese) => ({ type: Anamnese.SALVAR_ANAMNESE, anamnese })

export const salvarRespostas = (respostas) => ({ type: Anamnese.SALVAR_ANAMNESE, respostas })

export const toggleAnamnese = (anamneseId, ativar) => ({ type: Anamnese.TOGGLE_ANAMNESE, anamneseId, ativar })

import {put, takeEvery, takeLatest, all} from 'redux-saga/effects';
import axios from './axios-client'
import {Videos, Notificacoes} from '../types'

function* fetchVideos() {
  try {
    const {data} = yield axios.get('Video/getAll');
    yield put({
      type: Videos.LISTAR_VIDEOS,
      videos: data
    })
  } catch (error) {
    yield put({type: Notificacoes.ERRO, notifications: error.notifications});  
  }
}

function* salvarVideo(action) {
  try {
    const {video} = action
    if (!video.id) {
      yield axios.post('Video/add', video);
    } else {
      yield axios.put('Video/update', video);
    }
    yield put({type: Videos.REQUEST_VIDEOS})
  } catch (error) {
    yield put({type: Notificacoes.ERRO, notifications: error.notifications});  
  }
}

function* getById(action) {
  try {
    const {data} = yield axios.get(`Video/getById/${action.videoId}`);
    yield put({
      type: Videos.EDITAR_VIDEO,
      selectedVideo: data
    })
  } catch (error) {
    yield put({type: Notificacoes.ERRO, notifications: error.notifications});  
  }
}

function* toggleVideo(action) {
  try {
    if (action.ativar) {
      yield axios.put(`Video/ativar/${action.videoId}`);
    } else {
      yield axios.put(`Video/desativar/${action.videoId}`);
    }
    yield put({type: Videos.REQUEST_VIDEOS})
  } catch (error) {
    yield put({type: Notificacoes.ERRO, notifications: error.notifications});  
  }
}

function* videosListener() {
  yield all([
    takeEvery(Videos.REQUEST_VIDEOS, fetchVideos),
    takeLatest(Videos.GET_VIDEO_ID, getById),
    takeEvery(Videos.SALVAR_VIDEO, salvarVideo),
    takeEvery(Videos.TOGGLE_VIDEO, toggleVideo),
  ])
}

export default videosListener
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPowerOff } from '@fortawesome/free-solid-svg-icons'

import MenuContext from '../../contexts/MenuContexts'
import PermissoesConstants from '../../constants/permissoes.constants'
import VideosService from '../../services/videos.service'
import PageLoading from '../Common/PageLoading'

class VideosList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      videos: [],
    }
  }

  componentDidMount() {
    this.setState({ ...this.state, isLoading: true }, async () => {
      try {
        const { data } = await VideosService.getAllVideos()
        this.setState({ ...this.state, isLoading: false, videos: data })
      } catch (e) {}
      this.setState({ ...this.state, isLoading: false })
    })
  }

  toggleVideo = (index, id, ativar) => {
    this.props.toggleVideo(id, !ativar)

    var videos = [...this.state.videos];
    videos[index].ativo = !ativar;
    this.setState({videos: videos});
  }

  editarVideo = (id) => this.props.editarVideo(id)

  renderLoading = () => <PageLoading />

  renderLista = (videos, acoesPermitidas) => (
    <Fragment>
      {videos && videos.length > 0 ? (
        <div>
          <Table bordered hover  className='bg-white'>
            <thead>
              <tr className='text-center'>
                <th>Nome</th>
                <th>Descrição</th>
                <th>Link</th>
                <th width='160px'>Data Limite Exibição</th>
                {(acoesPermitidas[PermissoesConstants.EDITAR] ||
                  acoesPermitidas[PermissoesConstants.ATIVAR_INATIVAR]) && <th width='110px'>Ações</th>}
              </tr>
            </thead>
            <tbody>
              {videos.map((video, index) => (
                <tr key={index} className='text-center'>
                  <td>{video.nome}</td>
                  <td>{video.descricao}</td>
                  <td>
                    <a href={video.link} target='_blank' rel='noopener noreferrer'>
                      {video.link}
                    </a>
                  </td>
                  <td>{Moment(video.dataLimiteExibicao).format('DD/MM/YYYY')}</td>
                  {Object.keys(acoesPermitidas).length > 0 && acoesPermitidas[PermissoesConstants.EDITAR] && (
                    <td className='text-center'>
                      {acoesPermitidas[PermissoesConstants.EDITAR] && (
                        <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Editar Video</Tooltip>}>
                          <Link
                            to={`/videos/editar/${video.id}`}
                            onClick={() => this.editarVideo(video.id)}
                            className='mr-2 btn btn-primary btn-sm'
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </Link>
                        </OverlayTrigger>
                      )}
                      {acoesPermitidas[PermissoesConstants.ATIVAR_INATIVAR] && (
                        <OverlayTrigger
                          trigger='hover'
                          placement='top'
                          overlay={<Tooltip>{video.ativo ? 'Desativar video' : 'Ativar video'}</Tooltip>}
                        >
                          <Button
                            className='btn'
                            onClick={() => this.toggleVideo(index, video.id, video.ativo)}
                            variant={video.ativo ? 'success' : 'danger'}
                            size='sm'
                          >
                            <FontAwesomeIcon icon={faPowerOff} />
                          </Button>
                        </OverlayTrigger>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div>Nenhum vídeo encontrado.</div>
      )}
    </Fragment>
  )

  render() {
    const { isLoading, videos } = this.state
    let acoesPermitidas = {}
    return (
      <MenuContext.Consumer>
        {(acoes) => {
          acoes.map((a) => {
            acoesPermitidas[`${a.nome}`] = a
            return true
          })
          return (
            <Fragment>
              <Row className='mb-3'>
                <Col>
                  <h1 className='h3 m-0 text-secondary font-weight-bold'>Vídeos</h1>
                </Col>
                <Col>
                  {acoesPermitidas[PermissoesConstants.ADICIONAR] && (
                    <Link onClick={this.props.resetVideo} to='/videos/cadastrar'>
                      <Button className='float-right'>Cadastrar vídeo</Button>
                    </Link>
                  )}
                </Col>
              </Row>
              {isLoading ? this.renderLoading() : this.renderLista(videos, acoesPermitidas)}
            </Fragment>
          )
        }}
      </MenuContext.Consumer>
    )
  }
}

export default VideosList

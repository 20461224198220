import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { startOfMonth } from 'date-fns'
import 'moment/locale/pt-br'
import DashboardService from '../../../services/dashboard.service'
import PageLoading from '../../../components/Common/PageLoading'
import SystemConstants from '../../../constants/system.constants'

import {
  AgendamentoAtendimento,
  AvaliacoesFeitasEfetivadas,
  CaixaMaiorAtual,
  AvaliacoesColaboradorEspecialidade,
  TicketMedioColaborador,
} from './Graficos'
import accountService from '../../../services/account.service'

export default function Dashboard(props) {

  const [isLoading, setIsLoading] = useState(false)
  const [filtroDe, setFiltroDe] = useState(startOfMonth(new Date()))
  const [filtroAte, setFiltroAte] = useState(new Date())
  const [dadosDashboard, setDadosDashboard] = useState(null)

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem(SystemConstants.getUser()))

    accountService.hasPermission(user.franquiaId, "/Dashboard/Geral").then(result => {
      if (!result.data)
        window.location.href = "/";
    });

    updateDashboard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtroDe, filtroAte])

  const updateDashboard = async () => {
    setIsLoading(true)
    try {
      const filtros = [
        {
          campo: 'dataInicial',
          valor: filtroDe,
        },
        {
          campo: 'dataFinal',
          valor: filtroAte,
        },
      ]
      const { data } = await DashboardService.getDados({ filtros })
      setDadosDashboard(data)
    } catch (error) { }
    setIsLoading(false)
  }

  return (
    <Fragment>
      {
        <Fragment>
          {isLoading ? (
            <PageLoading />
          ) : (
            <Fragment>
              <Row>
                <Col className='d-flex align-items-center justify-content-between'>
                  <h2 className='text-secondary font-weight-bold mb-4'>Dashboard</h2>
                  <div className='d-flex'>
                    <Form.Group className='required mr-2'>
                      <Form.Label>De</Form.Label>
                      <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                        <KeyboardDatePicker
                          invalidDateMessage='Formato de data inválido'
                          className='form-control'
                          variant='inline'
                          value={filtroDe}
                          onChange={setFiltroDe}
                          format='DD/MM/YYYY'
                          required
                          animateYearScrolling
                          disableFuture
                        />
                      </MuiPickersUtilsProvider>
                    </Form.Group>
                    <Form.Group className='required'>
                      <Form.Label>Até</Form.Label>
                      <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                        <KeyboardDatePicker
                          invalidDateMessage='Formato de data inválido'
                          className='form-control'
                          value={filtroAte}
                          onChange={setFiltroAte}
                          variant='inline'
                          format='DD/MM/YYYY'
                          required
                          animateYearScrolling
                          disableFuture
                        />
                      </MuiPickersUtilsProvider>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <Row className='pb-3 pt-2'>
                <CaixaMaiorAtual
                  faturamento={
                    dadosDashboard && dadosDashboard.faturamento
                      ? dadosDashboard.faturamento
                      : { maiorMes: 0, mesAtual: 0 }
                  }
                />
              </Row>
              <Row className='py-3'>
                <Col>
                  <AgendamentoAtendimento
                    agendamento={dadosDashboard && dadosDashboard.agendamento ? dadosDashboard.agendamento : []}
                  />
                </Col>
                <Col className='pl-0'>
                  <AvaliacoesFeitasEfetivadas
                    avaliacoes={dadosDashboard && dadosDashboard.avaliacoes ? dadosDashboard.avaliacoes : []}
                  />
                </Col>
              </Row>
              <Row className='py-3'>
                <AvaliacoesColaboradorEspecialidade
                  avaliacoesEfetivadasColaborador={
                    dadosDashboard && dadosDashboard.avaliacoesEfetivadasColaborador
                      ? dadosDashboard.avaliacoesEfetivadasColaborador
                      : []
                  }
                />
              </Row>
              <Row className='py-3'>
                <TicketMedioColaborador
                  ticketMedioColaborador={
                    dadosDashboard && dadosDashboard.ticketMedioColaborador ? dadosDashboard.ticketMedioColaborador : []
                  }
                />
              </Row>
            </Fragment>
          )}
        </Fragment>
      }
    </Fragment>
  )
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { resetColaborador } from '../../services/actions/colaboradores.actions'
import ColaboradoresForm from '../../components/Colaboradores/ColaboradoresForm'

const mapStateToProps = (store) => ({
  selectedColaborador: store.colaboradoresState.selectedColaborador,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ resetColaborador }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ColaboradoresForm)

import { put, takeLeading, takeLatest, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { setMinutes, setHours } from 'date-fns'

import axios from './axios-client'
import { Notificacoes } from '../types'
import * as Agendamentos from '../reducers/agendamentos.reducer'
import { TIPO_CONSULTA, status } from '../../constants/agendamento.constants'

function* getAgendamentoById(action) {
  try {
    const { data } = yield axios.get(`Agendamento/getById/${action.agendamentoId}`)
    yield all([
      put({
        type: Agendamentos.EDITAR_AGENDAMENTO,
        selectedAgendamento: data,
      }),
      put({
        type: Agendamentos.SET_NOT_LOADING,
      }),
    ])
  } catch (error) {}
}

function* inserirAgendamento(action) {
  try {
    yield axios.post('Agendamento/salvar', action.agendamento)
    toast('Agendamento salvo com sucesso', { type: toast.TYPE.SUCCESS })
    yield all([
      put({ type: Agendamentos.REQUEST_AGENDAMENTOS }),
      put({ type: Agendamentos.RESET_AGENDAMENTOS }),
      put({
        type: Agendamentos.SET_NOT_LOADING,
      }),
    ])
  } catch (error) {
    console.log(error)
  }
}

function* salvarStatus(action) {
  try {
    yield axios.put(`Agendamento/AlterarStatus/${action.agendamentoId}/${action.status}`)
    toast('Alteração de status salva com sucesso', { type: toast.TYPE.SUCCESS })
    yield all([
      put({ type: Agendamentos.REQUEST_AGENDAMENTOS }),
      put({
        type: Agendamentos.SET_NOT_LOADING,
      }),
    ])
  } catch (error) {}
}

function* getAgendamentoByPaciente(action) {
  try {
    const { pacienteId, nome, cpf } = action
    const params = `pacienteId=${pacienteId ? pacienteId : ''}&nome=${nome ? nome : ''}&cpf=${cpf ? cpf : ''}`
    const { data } = yield axios.get(`Agendamento/GetByPaciente?${params}`)
    yield all([
      put({
        type: Agendamentos.LISTAR_AGENDAMENTOS,
        agendamentos: parseAgendamentos(data),
      }),
      put({
        type: Agendamentos.SET_NOT_LOADING,
      }),
    ])
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* getAgendamentoByColaboradorId(action) {
  try {
    const { data } = yield axios.get(`Agendamento/GetByColaboradorId/${action.colaboradorId}`)
    yield all([
      put({
        type: Agendamentos.LISTAR_AGENDAMENTOS,
        agendamentos: parseAgendamentos(data),
      }),
      put({
        type: Agendamentos.SET_NOT_LOADING,
      }),
    ])
  } catch (error) {}
}

function* getAgendamentoByEspecialidadeId(action) {
  try {
    const { data } = yield axios.get(`Agendamento/GetByEspecialidadeId/${action.especialidadeId}`)
    yield all([
      put({
        type: Agendamentos.LISTAR_AGENDAMENTOS,
        agendamentos: parseAgendamentos(data),
      }),
      put({
        type: Agendamentos.SET_NOT_LOADING,
      }),
    ])
  } catch (error) {}
}

function getColor(item) {
  var statusAgendamento = status.find((x) => x.value === item.statusAgendamento)

  return statusAgendamento && statusAgendamento.color ? statusAgendamento.color : 'blue'
}

function parseAgendamentos(data) {
  return data.map((d) => {
    let { horaInicio, horaFim } = d
    horaInicio = horaInicio.split(':')
    horaFim = horaFim.split(':')
    return {
      ...d,
      title:
        d.tipoConsulta === TIPO_CONSULTA.AVALIACAO.value
          ? TIPO_CONSULTA.AVALIACAO.shortLabel
          : TIPO_CONSULTA.PROCEDIMENTO.shortLabel,
      allDay: false,
      backgroundColor: getColor(d),
      borderColor: getColor(d),
      start: setHours(setMinutes(new Date(d.data), horaInicio[1]), horaInicio[0]).toISOString(),
      end: setHours(setMinutes(new Date(d.data), horaFim[1]), horaFim[0]).toISOString(),
    }
  })
}

function* agendamentosListener() {
  yield takeLeading(Agendamentos.SALVAR_AGENDAMENTO, inserirAgendamento)
  yield takeLeading(Agendamentos.SALVAR_STATUS_AGENDAMENTO, salvarStatus)
  yield takeLatest(Agendamentos.GET_AGENDAMENTO_ID, getAgendamentoById)
  yield takeLatest(Agendamentos.GET_AGENDAMENTO_PACIENTE, getAgendamentoByPaciente)
  yield takeLatest(Agendamentos.GET_AGENDAMENTO_COLABORADOR, getAgendamentoByColaboradorId)
  yield takeLatest(Agendamentos.GET_AGENDAMENTO_ESPECIALIDADE, getAgendamentoByEspecialidadeId)
}

export default agendamentosListener

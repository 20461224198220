export const relatorios = {
  VENCIMENTO_PARCELA: { label: 'Relatório de lembrança de vencimento da parcela', value: 13 },
  PACIENTES_FALTOSOS: { label: 'Relatório de pacientes faltosos', value: 14 },
  PACIENTES_AGUARDANDO_AGENDAMENTO: {
    label: 'Relatório de pacientes que estão em tratamento e não tem agendamento',
    value: 15,
  },
  AVALIACAO_PENDENTE: { label: 'Relatório de avaliações', value: 16 },
  PROFISSIONAIS_PROCEDIMENTOS: { label: 'Relatório de profissionais x procedimentos', value: 17 },
  DEVEDORES: { label: 'Relatório de devedores', value: 18 },
  NEGATIVADOS: { label: 'Relatório de negativados', value: 19 },
  DESNEGATIVADOS: { label: 'Relatório de desnegativados', value: 20 },
  MANUTENCOES: { label: 'Relatório de manutenções', value: 21 },
  CANCELAMENTOS: { label: 'Relatório de cancelamentos', value: 22 },
}

export const relatoriosSelect = Object.values(relatorios)

export const statusPagamento = {
  PENDENTE: 0,
  PAGO: 1,
}

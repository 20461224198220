import React, { useEffect, useState } from 'react'
import { Card, Modal } from 'react-bootstrap'
import ListaDocumentos from './ListaDocumentos'
import AnexarGeral from './AnexarGeral'
import PageLoading from '../../Common/PageLoading'
import anexosService from '../../../services/anexos.service'

export default function TabDocumentos({ pacienteId, planosOrtodontia, planosTratamento }) {
  const [isLoading, setIsLoading] = useState(false)

  const [abrirModal, setAbrirModal] = useState(false)
  const [abrirModalAnexarGeral, setAbrirModalAnexarGeral] = useState(false)
  const [abrirModalAnexarTratamento, setAbrirModalAnexarTratamento] = useState(false)

  const [documentos, setDocumentos] = useState([])
  const [tratamentos, setTratamentos] = useState([]);
  const [tratamento, setTratamento] = useState({})

  const [categorias, setCategorias] = useState([])

  useEffect(() => {
    initialize();
  }, [planosOrtodontia, planosTratamento])

  const initialize = async () => {
    const responseCategorias = await anexosService.getCategorias();
    setCategorias(responseCategorias.data)

    const responseDocumentos = await anexosService.getDocumentos(pacienteId);
    const documentoGerais = responseDocumentos.data.filter(documento => documento.planoTratamentoId === null && documento.planoTratamentoOrtodontiaId === null)
    const documentosPorTratamento = responseDocumentos.data.filter(documento => documento.planoTratamentoId !== null || documento.planoTratamentoOrtodontiaId !== null)

    setDocumentos(documentoGerais)

    const tratamentosMapped = []

    planosOrtodontia.map(tratamento => {
      tratamentosMapped.push({
        planoTratamentoOrtodontiaId: tratamento.id,
        numeroPlano: tratamento.numeroPlano,
        pacienteId: tratamento.pacienteId,
        pacienteNome: tratamento.paciente?.nome,
        documentos: documentosPorTratamento.filter(documento => documento.planoTratamentoOrtodontiaId === tratamento.id)
      })
    })

    planosTratamento.map(tratamento => {
      tratamentosMapped.push({
        planoTratamentoId: tratamento.id,
        numeroPlano: tratamento.numeroPlano,
        pacienteId: tratamento.pacienteId,
        pacienteNome: tratamento.paciente?.nome,
        documentos: documentosPorTratamento.filter(documento => documento.planoTratamentoId === tratamento.id)
      })
    })

    setTratamentos(tratamentosMapped)
  }

  const abrirModalComDocumentos = (tratamento) => {
    setTratamento(tratamento)
    setAbrirModal(true);
  }

  const fecharModais = async () => {
    setAbrirModalAnexarGeral(false);
    setAbrirModalAnexarTratamento(false);
    setAbrirModal(false);

    setIsLoading(true);
    await initialize();
    setIsLoading(false);
  }

  const download = async (documento) => {
    setIsLoading(true);
    await anexosService.download(documento.id, documento.nomeGerado);
    setIsLoading(false);
  }

  return (
    <div>
      {isLoading && <PageLoading />}
      <div>

        <div className='mt-3 mb-1 row'>
          <div className='col'>
            <h2 className='h5 border-bottom pb-2'>Documentos por Tratamento(s)</h2>
            <p>Todos anexos centralizados nesta aba, ficha, atestado, contrato assinado, documentos, entre outros.</p>
          </div>
        </div>

        <div className='text-right mb-2'>
          <button className='btn btn-outline-primary' onClick={() => {
            setTratamento({})
            setAbrirModalAnexarTratamento(true)
          }}>Anexar no Tratamento</button>
        </div>

        <div className='table-responsive'>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th>Número do Plano</th>
                <th>Paciente</th>
                <th>Qtd de Documentos</th>
              </tr>
            </thead>
            <tbody>
              {tratamentos.map((tratamento, index) => {
                return <tr key={index} className='cursor-pointer' onClick={() => abrirModalComDocumentos(tratamento)}>
                  <td>{tratamento.numeroPlano}</td>
                  <td>{tratamento.pacienteNome}</td>
                  <td>{tratamento.documentos.length}</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>


        <div className='mt-3 mb-1 row'>
          <div className='col'>
            <h2 className='h5 border-bottom pb-2'>Documentos Gerais</h2>
            <p>Todos anexos gerais referente ao Paciente diretamente sem vinculo algum com tratamento.</p>
          </div>
        </div>

        <div className='text-right mb-2'>
          <button className='btn btn-outline-primary' onClick={() => setAbrirModalAnexarGeral(true)}>Anexar</button>
        </div>

        <div className='table-responsive'>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th>Identificador</th>
                <th>Nome do Documento</th>
                <th>Nome do Sistema</th>
                <th>Categoria</th>
              </tr>
            </thead>
            <tbody>
              {documentos.map(documento => {
                return <tr className='cursor-pointer' onClick={() => download(documento)}>
                  <td>{documento.id}</td>
                  <td>{documento.nome}</td>
                  <td>{documento.nomeGerado}</td>
                  <td>{categorias.find(categoria => categoria.id == documento.categoriaId).nome}</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>


        <Modal className='modal-documentos' show={abrirModal} onHide={() => fecharModais()}>
          <Card>
            <Modal.Header className='p-4' closeButton>
              <Modal.Title>{tratamento.numeroPlano}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-4'>
              <ListaDocumentos tratamento={tratamento} download={(documento) => download(documento)} categorias={categorias} />
            </Modal.Body>
          </Card>
        </Modal>

        <Modal className='modal-documentos' show={abrirModalAnexarTratamento} onHide={() => fecharModais()}>
          <Card>
            <Modal.Header className='p-4' closeButton>
              <Modal.Title>
                {tratamento?.numeroPlano !== undefined ?
                  <p>Anexe documento(s) no Tratamento {tratamento.numeroPlano}</p>
                  :
                  <p>Selecione um Tratamento</p>
                }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-4'>
              {tratamento?.numeroPlano === undefined ?
                <div>
                  <p>Selecione Tratamento para anexar documento(s)</p>
                  <div className='table-responsive'>
                    <table className='table table-bordered'>
                      <thead>
                        <tr>
                          <th>Número do Plano</th>
                          <th>Paciente</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tratamentos.map((tratamento, index) => {
                          return <tr className='cursor-pointer' key={index} onClick={() => setTratamento(tratamento)}>
                            <td>{tratamento.numeroPlano}</td>
                            <td>{tratamento.pacienteNome}</td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                :
                <AnexarGeral fecharModais={fecharModais} pacienteId={pacienteId} categorias={categorias} planoTratamentoId={tratamento.planoTratamentoId} planoTratamentoOrtodontiaId={tratamento.planoTratamentoOrtodontiaId} />
              }
            </Modal.Body>
          </Card>
        </Modal>



        <Modal className='modal-documentos' show={abrirModalAnexarGeral} onHide={() => fecharModais()}>
          <Card>
            <Modal.Header className='p-4' closeButton>
              <Modal.Title>Anexe documento(s) no Paciente</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-4'>
              <AnexarGeral fecharModais={fecharModais} pacienteId={pacienteId} categorias={categorias} />
            </Modal.Body>
          </Card>
        </Modal>


      </div>
    </div>
  )
}

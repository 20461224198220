import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  getByPaciente,
  salvarAgendamento,
  getByColaborador,
  getByEspecialidade,
  getById,
  salvarStatusAgendamento,
  resetAgendamento,
  resetRequestAgendamentos,
} from '../../services/reducers/agendamentos.reducer'
import { requestDentistas, requestEspecialidades } from '../../services/actions/colaboradores.actions'
import { getPacienteByCpf, resetPaciente } from '../../services/actions/pacientes.actions'
import { editarPaciente } from '../../services/actions/pacientes.actions'
import { concluirAtendimento, iniciarAvaliacao, iniciarTratamento } from '../../services/reducers/avaliacao.reducer'
import { AgendamentoOrtodontia } from '../../pages/Agenda/Ortodontia'

const mapStateToProps = (store) => ({
  isLoadingAgendamento: store.agendamentosState.isLoading,
  agendamentos: store.agendamentosState.agendamentos,
  selectedAgendamento: store.agendamentosState.selectedAgendamento,
  colaboradorId: store.accountState.user.colaboradorId,
  especialidades: store.colaboradoresState.especialidades,
  selectedPaciente: store.pacientesState.selectedPaciente,
  colaboradores: store.colaboradoresState.dentistas.map((c) => ({
    label: c.nome,
    value: c.id,
    especialidades: c.especialidadeColaborador,
  })),
  requestAgendamentos: store.agendamentosState.requestAgendamentos,
})

const mapDispatchToProps = (dispatch) => {
  dispatch(requestDentistas(true))
  return bindActionCreators(
    {
      editarPaciente,
      getByPaciente,
      resetAgendamento,
      salvarStatusAgendamento,
      salvarAgendamento,
      getByColaborador,
      getByEspecialidade,
      getById,
      requestEspecialidades,
      getPacienteByCpf,
      resetPaciente,
      concluirAtendimento,
      resetRequestAgendamentos,
      iniciarAvaliacao, 
      iniciarTratamento
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AgendamentoOrtodontia)

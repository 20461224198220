import React, { Fragment, useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { statusPagamentosParcelas, status } from '../../../constants/common.constants'
import { formatMoney } from '../../../utils/string.utils'
import CollapsePanel from '../../Common/CollapsePanel'

export default function VisualizarParcelasOrtodontia({ parcelasPlano, selectedPlano, paciente, firstStep }) {
  const [nomePaciente, setNomePaciente] = useState('')
  const [statusPlano, setStatusPlano] = useState('')
  const [stepIsOpen, setStepIsOpen] = useState('')

  useEffect(() => {
    if (selectedPlano) {
      const currentStatus = status.find((s) => selectedPlano.statusTratamento === s.value)
      setNomePaciente(selectedPlano.dependente ? selectedPlano.dependente.nome : selectedPlano.paciente.nome)
      setStatusPlano(currentStatus ? currentStatus.label : 'Pendente')
    }
  }, [selectedPlano])

  const toggleIsOpen = (step) => {
    setStepIsOpen(stepIsOpen === step ? '' : step)
  }

  const getStatusParcelaLabel = (parcelaStatus) => {
    const statusObj = Object.values(statusPagamentosParcelas).find((status) => status.value === parcelaStatus)
    return statusObj ? statusObj.label : '--'
  }

  const handleCollapseName = (renegociacao, pagamentosAparelho, suprimentos, aparelhosComplementar, dataCriacao) => {
    const data = dataCriacao ? moment(dataCriacao).format('DD/MM/YYYY') : ''

    if (renegociacao) {
      if (pagamentosAparelho) {
        return `Parcelamento aparelho - renegociado pelo cliente na data ${data}`
      }

      if (aparelhosComplementar) {
        return `Parcelamento aparelho complementar - renegociado pelo cliente na data ${data}`
      }

      if (suprimentos) {
        return `Parcelamento suprimentos - renegociado pelo cliente na data ${data}`
      }

      return `Parcelamento renegociado pelo cliente na data ${data}`
    }

    if (pagamentosAparelho) {
      return 'Parcelamento aparelho'
    }

    if (aparelhosComplementar) {
      return 'Parcelamento aparelho complementar'
    }

    if (suprimentos) {
      return 'Parcelamento suprimentos'
    }

    return 'Parcelamento escolhido no fechamento do contrato'
  }

  return selectedPlano ? (
    <div className='m-3'>
      <h3 className='m-0 text-secondary font-weight-bold'>Plano de Tratamento {selectedPlano.numeroPlano}</h3>
      <div className='mt-4 mb-2 text-secondary'>
        <strong>Paciente:</strong> <span className='text-dark'>{nomePaciente}</span>
      </div>
      <div className='mt-2 mb-4 text-secondary'>
        <strong>Status do Tratamento: </strong>
        <span className='text-dark'>{statusPlano}</span>
      </div>
      {parcelasPlano.map((pagamentoPlano, index) => {
        const {
          parcelas,
          renegociacao,
          dataCriacao,
          pagamentosAparelho,
          suprimentos,
          aparelhosComplementar,
        } = pagamentoPlano
        return (
          <CollapsePanel
            index={index + 'collapse'}
            stepCode={`step${index}`}
            text={handleCollapseName(renegociacao, pagamentosAparelho, suprimentos, aparelhosComplementar, dataCriacao)}
            isOpen={`step${index}` === stepIsOpen}
            toggleIsOpen={toggleIsOpen}
          >
            <Table className='mt-3' hover bordered>
              <thead>
                <tr className='text-center'>
                  <th>Vencimento</th>
                  <th>Parcela</th>
                  <th>Valor</th>
                  <th>Multa</th>
                  <th>Juros</th>
                  <th>Desconto</th>
                  <th>Valor Pago</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {parcelas.map((parcela, index) => {
                  const { valor, colaborador, dataVencimento, juros, multa, valorPago, desconto, status, numeroParcela, codigoIntegracao } = parcela
                  return (
                    <Fragment key={index}>
                      <tr key={index + 'tr1'} className='text-center'>
                        <td>{dataVencimento ? moment(dataVencimento).format('DD/MM/YYYY') : '-'}</td>
                        <td>{numeroParcela}</td>
                        <td>{valor ? formatMoney(valor) : '-'}</td>
                        <td>{multa > 0 ? formatMoney(multa) : '-'}</td>
                        <td>{juros > 0 ? formatMoney(juros) : '-'}</td>
                        <td>{desconto > 0 ? formatMoney(desconto) : '-'}</td>
                        <td>{valorPago > 0 ? formatMoney(valorPago) : '-'}</td>
                        <td>{getStatusParcelaLabel(status)}</td>
                        <td>
                          {codigoIntegracao &&
                            <>
                              <a href={`https://asaas.com/i/${codigoIntegracao.replace("pay_", "")}`} target='_blank'>Visualizar</a>

                              {/* {statusPagamentosParcelas.ABERTA.value == status &&
                                <>
                                  &nbsp;
                                  <a>Enviar</a>
                                </>
                              } */}
                            </>
                          }

                        </td>
                      </tr>
                    </Fragment>
                  )
                })}
              </tbody>
            </Table>
          </CollapsePanel>
        )
      })}
      <div style={backContainerStyle}>
        <div onClick={firstStep}>
          <Button variant='outline-primary' style={backStyle}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <span className='text-primary'>Voltar</span>
        </div>
      </div>
    </div>
  ) : (
    <Fragment></Fragment>
  )
}

//////////////////////////////////////////////////////////////////
///////////////////////////// STYLES /////////////////////////////
//////////////////////////////////////////////////////////////////
const backContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '1.5em 0',
}
const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

import React, { useEffect } from 'react'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'

// Components
import { Base, Wrap, PrivateRoute } from './../components/Layout/'

// Pages
import Logout from '../components/Logout'
import Login from './../containers/LoginContainer'
import Recover from '../containers/RecoverContainer'
import Dashboard from './../containers/DashboardContainer'
import FranquiasList from './../components/Franquias/FranquiasList'
import Relatorio from './../components/Relatorio'
import FranquiasForm from './../components/Franquias/FranquiasForm'
import PacientesList from '../containers/PacientesContainer/PacientesList'
import PacienteDetails from '../containers/PacientesContainer/PacienteDetails'
import { ColaboradoresList, ColaboradoresForm } from '../containers/ColaboradoresContainer'
import { VideosList, VideosForm } from '../containers/VideosContainer'
import { AnamneseList, AnamneseForm } from '../containers/AnamneseContainer'
import { Perfis, PerfilForm } from '../containers/PerfisContainer'
import MudarSenha from '../components/Common/MudarSenha'
import ParametrosList from '../components/Parametros/ParametrosList'
import AgendamentoOrtodontiaContainer from '../containers/AgendamentoOrtodontiaContainer'
import AgendamentoImplantodontiaContainer from '../containers/AgendamentoImplantodontiaContainer'
import AgendamentoHarmonizacaoFacialContainer from '../containers/AgendamentoHarmonizacaoFacialContainer'
import AgendaDentista from '../containers/AgendaDentistaContainer'
import BloqueioDentista from '../containers/BloqueioDentistaContainer'
import Avaliacao from '../containers/AvaliacaoContainer'
import Tratamento from '../containers/TratamentoContainer'
import { ProcedimentosForm, ProcedimentosList } from '../containers/ProcedimentosContainer'
import MenuContext from '../contexts/MenuContexts'
import TemplatesList from '../containers/TemplatesContainer'
import TemplateEdit from '../containers/TemplateEditContainer'
import AtendimentoFinanceiro from '../containers/AtendimentoContainer'
import ProteticosList from '../containers/ProteticoContainer/ProteticosList'
import CadastrarProtetico from '../containers/ProteticoContainer/CadastrarProtetico'
import EditarProtetico from '../containers/ProteticoContainer/EditarProtetico'
import Acompanhamento from '../containers/ProteticoContainer/Acompanhamento'
import CadastrarRadiologica from '../containers/OrtodontiaContainer/CadastrarRadiologica'
import CadastrarPlano from '../containers/OrtodontiaContainer/CadastrarPlano'
import CadastrarAparelho from '../containers/OrtodontiaContainer/CadastrarAparelho'
import CadastrarFeriado from '../containers/FeriadoContainer/CadastrarFeriado'
import AvaliacaoOrtodontiaDentista from '../containers/OrtodontiaContainer/AvaliacaoOrtodontiaDentista'
import AtendimentoRecepcao from '../containers/OrtodontiaContainer/AtendimentoRecepcao'
import AcompanhamentoRadiologia from '../containers/OrtodontiaContainer/AcompanhamentoRadiologia'
import ProcedimentoManutencao from '../containers/OrtodontiaContainer/ProcedimentoManutencao'
import { ContasPagar, ContasReceber, FechamentoCaixa, RepasseProtetico } from '../pages/Financeiro'
import { AgendamentoClinicoGeralListagem } from '../pages/Agenda/ClinicoGeral'
import TabelaPreco from '../components/Protetico/TabelaPreco'
import DashboardProfissional from '../pages/Dashboard/Profissional'
import MainDashboard from '../pages/Dashboard/Main'

const baseroutes = ['/login', '/recover', '/']

const Routes = (props) => {
  const { location, acoes, menus, requestAcoes, isProtetico } = props
  
  useEffect(() => {
    if (!isProtetico) {
      const menu = menus.find((menu) => menu.caminho.length > 0 && location.pathname.includes(menu.caminho))
      menu && requestAcoes(menu.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, menus])

  if (baseroutes.indexOf(location.pathname) > -1) {
    return (
      <Base>
        <Switch location={location}>
          <Route exact path='/' render={() => <Redirect to='/login' />} />
          <Route exact path='/logout' render={() => <Redirect to='/login' />} />
          <Route exact path='/recover' component={Recover} />
          <Route exact path='/login' component={Login} />
        </Switch>
      </Base>
    )
  } else {
    return (
      <MenuContext.Provider value={acoes}>
        <PrivateRoute {...props}>
          <Base {...props}>
            <Wrap {...props}>
              <Switch location={location}>
                {/*<Route exact path='/' render={() => <Redirect to={authenticated ? firstMenuPath : '/login'} />} />*/}
                <Route exact path='/logout' component={Logout} />

                {/* Dashboard */}
                <Route exact path='/dashboard' render={(props) => <MainDashboard {...props} />} />
                <Route exact path='/dashboard/geral' render={(props) => <Dashboard {...props} />} />
                <Route exact path='/dashboard/profissional' render={(props) => <DashboardProfissional {...props} />} />

                {/* Franquias */}
                <Route exact path='/franquias' render={(props) => <FranquiasList {...props} />} />
                <Route exact path='/franquias/cadastrar' render={(props) => <FranquiasForm {...props} />} />
                <Route exact path='/franquias/editar' render={(props) => <FranquiasForm {...props} />} />
                <Route
                  exact
                  path='/franquias/editar/:id'
                  render={(props) => (
                    <Redirect
                      to={{
                        pathname: '/franquias/editar',
                        id: props.match.params.id,
                      }}
                    />
                  )}
                />

                {/* Pacientes */}
                <Route exact path='/pacientes' render={(props) => <PacientesList {...props} />} />
                <Route exact path='/pacientes/detalhes' render={(props) => <PacienteDetails {...props} />} />

                {/* Colaboradores */}
                <Route exact path='/colaboradores' render={(props) => <ColaboradoresList {...props} />} />
                <Route exact path='/colaboradores/cadastrar' render={(props) => <ColaboradoresForm {...props} />} />
                <Route exact path='/colaboradores/editar' render={(props) => <ColaboradoresForm {...props} />} />
                <Route
                  exact
                  path='/colaboradores/editar/:id'
                  render={(props) => (
                    <Redirect
                      to={{
                        pathname: '/colaboradores/editar',
                        id: props.match.params.id,
                      }}
                    />
                  )}
                />
                {/* Mudar Senha*/}
                <Route exact path='/senha' render={(props) => <MudarSenha {...props} />} />

                {/* Vídeos */}
                <Route exact path='/videos' render={(props) => <VideosList {...props} />} />
                <Route exact path='/videos/cadastrar' render={(props) => <VideosForm {...props} />} />
                <Route exact path='/videos/editar' render={(props) => <VideosForm {...props} />} />
                <Route
                  exact
                  path='/videos/editar/:id'
                  render={(props) => (
                    <Redirect
                      to={{
                        pathname: '/videos/editar',
                        id: props.match.params.id,
                      }}
                    />
                  )}
                />

                {/* Perfis */}
                <Route exact path='/perfis' render={(props) => <Perfis {...props} />} />
                <Route exact path='/perfis/cadastrar' render={(props) => <PerfilForm {...props} />} />

                {/* Parametros */}
                <Route exact path='/parametros' render={(props) => <ParametrosList {...props} />} />
                <Route exact path='/parametros' render={(props) => <ParametrosList {...props} />} />
                
                {/* Feriados */}
                <Route exact path='/feriado/cadastrar-feriado' component={CadastrarFeriado} />

                {/* Agenda */}
                <Route exact path='/agendamento/ortodontia' component={AgendamentoOrtodontiaContainer} />
                <Route exact path='/agendamento/implantodontia' component={AgendamentoImplantodontiaContainer} />
                <Route
                  exact
                  path='/agendamento/harmonizacaofacial'
                  component={AgendamentoHarmonizacaoFacialContainer}
                />
                <Route exact path='/agendamento/clinicogeral' component={AgendamentoClinicoGeralListagem} />
                <Route
                  exact
                  path='/agendamento/clinicogeral/:id'
                  render={(props) => (
                    <Redirect
                      to={{
                        pathname: '/agendamento/clinicogeral',
                        id: props.match.params.id,
                      }}
                    />
                  )}
                />

                {/* Procedimentos */}
                <Route exact path='/procedimentos/' render={(props) => <ProcedimentosList {...props} />} />
                <Route exact path='/procedimentos/cadastrar' render={(props) => <ProcedimentosForm {...props} />} />
                <Route exact path='/procedimentos/editar' component={ProcedimentosForm} />

                {/* Anamnese */}
                <Route exact path='/anamnese' render={(props) => <AnamneseList {...props} />} />
                <Route exact path='/anamnese/cadastrar' render={(props) => <AnamneseForm {...props} />} />
                <Route exact path='/anamnese/editar' render={(props) => <AnamneseForm {...props} />} />
                <Route
                  exact
                  path='/anamnese/editar/:id'
                  render={(props) => (
                    <Redirect
                      to={{
                        pathname: '/anamnese/editar',
                        id: props.match.params.id,
                      }}
                    />
                  )}
                />

                {/* Templates */}
                <Route exact path='/templates' render={(props) => <TemplatesList {...props} />} />
                <Route exact path='/templates/editar' render={(props) => <TemplateEdit {...props} />} />

                {/* Atendimento */}
                <Route exact path='/agenda-bloqueio-dentista' component={BloqueioDentista} />
                <Route exact path='/avaliacao' component={Avaliacao} />
                <Route exact path='/tratamento' component={Tratamento} />
                <Route exact path='/atendimento-financeiro' component={AtendimentoFinanceiro} />

                {/* Financeiro */}
                <Route exact path='/financeiro/contaspagar' component={ContasPagar} />
                <Route exact path='/financeiro/contasreceber' component={ContasReceber} />
                <Route exact path='/financeiro/repasseprotetico' component={RepasseProtetico} />
                <Route exact path='/financeiro/fechamentocaixa' component={FechamentoCaixa} />

                {/*Relatório*/}
                <Route exact path='/relatorio' component={Relatorio} />

                {/* Protético */}
                <Route exact path='/protetico' component={ProteticosList} />
                <Route exact path='/protetico/cadastrar' component={CadastrarProtetico} />
                <Route exact path='/protetico/editar' component={EditarProtetico} />
                <Route exact path='/protetico/acompanhar' component={Acompanhamento} />
                <Route exact path='/protetico/tabelapreco' component={TabelaPreco} />

                {/* Ortodontia */}
                <Route exact path='/ortodontia/avaliacao/dentista' component={AvaliacaoOrtodontiaDentista} />
                <Route exact path='/ortodontia/avaliacao/recepcao' component={AtendimentoRecepcao} />
                <Route exact path='/ortodontia/cadastrar-plano' component={CadastrarPlano} />
                <Route exact path='/ortodontia/cadastrar-aparelho' component={CadastrarAparelho} />
                <Route exact path='/ortodontia/cadastrar-radiologica' component={CadastrarRadiologica} />
                <Route exact path='/ortodontia/radiologia' component={AcompanhamentoRadiologia} />
                <Route exact path='/ortodontia/plano-tratamento/manutencao' component={ProcedimentoManutencao} />

                
                {/* Atendimento */}
                <Route exact path='/atendimento' component={AgendaDentista} />

              </Switch>
            </Wrap>
          </Base>
        </PrivateRoute>
      </MenuContext.Provider>
    )
  }
}

export default withRouter(Routes)

const PROD = {
  REACT_APP_API_URL: 'https://credodontoapi.azurewebsites.net/api',
  TOKEN_KEY: 'OdontoTokenAccess',
  ATUALIZAR_AGENDA: 'atualizar_agenda',
  USER: 'USER',
  FRANQUIA: 'Fraquia',
  WEBSOCKET_SERVER: 'https://credodontoapi.azurewebsites.net/notificationHub',
  CHAT_URL: 'http://52.249.254.240/channel/general',
  CHAT_TOKEN: 'Meteor.loginToken',
  CHAT_USER_ID: 'Meteor.userId',
  CHAT_STATUS: 'Chat.Status',
}

export default PROD

import React, { Fragment, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import ParcelasList from './ParcelasList'
import CollapsePanel from '../../../Common/CollapsePanel'
import { formatMoney } from '../../../../utils/string.utils'

export default function CancelarPagamento({
  parcelas,
  selectedParcelas,
  selecionarTodasParcelas,
  toggleParcela,
  totalParcelas,
  handleEfetuarCancelamento,
  nextStep,
  motivo,
  setMotivo,
  handleFirstStep,
}) {
  const INITIAL_OPEN_STATE = { step0: false }
  const [stepIsOpen, setStepIsOpen] = useState({ ...INITIAL_OPEN_STATE })

  const handleImprimir = async () => {
    const confirmado = await handleEfetuarCancelamento(motivo)
    confirmado && nextStep()
  }

  const toggleIsOpen = (step) => {
    let newSteps = { ...INITIAL_OPEN_STATE }
    parcelas.forEach((_, i) => {
      newSteps[`step${i}`] = false
    })
    setStepIsOpen({
      ...newSteps,
      [step]: !stepIsOpen[step],
    })
  }

  return (
    <Fragment>
      <div style={backContainerStyle} onClick={handleFirstStep}>
        <Button variant='outline-primary' style={backStyle}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <span className='text-primary'>Voltar</span>
      </div>
      <h3 className='my-4 text-secondary font-weight-bold'>Cancelar pagamento</h3>
      {parcelas.length > 0 ? (
        <Fragment>
          {parcelas.map((parcelamento, i) => {
            return (
              <CollapsePanel
                stepCode={`step${i}`}
                text={parcelamento.nome}
                isOpen={stepIsOpen[`step${i}`]}
                toggleIsOpen={toggleIsOpen}
                key={i}
              >
                <ParcelasList
                  selectedParcelas={selectedParcelas}
                  toggleParcela={toggleParcela}
                  parcelas={parcelamento.parcelas}
                  selecionarTodasParcelas={selecionarTodasParcelas}
                  grupo={i}
                />
              </CollapsePanel>
            )
          })}

          <div className='d-flex justify-content-between align-items-center pb-5 pt-3'>
            <h1 className='h4 m-0 text-secondary font-weight-bold'>Total: {formatMoney(totalParcelas)}</h1>
            <Form.Control
              className='w-50'
              type='text'
              name='motivo'
              placeholder='Motivo do cancelamento'
              value={motivo}
              onChange={setMotivo}
            />
            <Button variant='primary' disabled={!totalParcelas || !motivo} onClick={handleImprimir}>
              Efetuar Cancelamento
            </Button>
          </div>
        </Fragment>
      ) : (
        <div>Nenhuma parcela deste plano foi paga até o momento</div>
      )}
    </Fragment>
  )
}

const backContainerStyle = {
  cursor: 'pointer',
}

const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

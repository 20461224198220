import AxiosClient from './axios-client'

class ReltorioService {
  async getRegistrosVencimentoParcela(filtros, numeroPagina = 1, registrosPorPagina = 20) {
    try {
      const { data } = await AxiosClient.post(`Relatorio/vencimentoParcela`, {
        filtros,
        numeroPagina,
        registrosPorPagina,
      })
      return data
    } catch (error) {
      console.log(error)
    }
    return []
  }

  async getRegistrosPacientesFaltosos(filtros, numeroPagina = 1, registrosPorPagina = 20) {
    try {
      const { data } = await AxiosClient.post(`Relatorio/pacientesFaltosos`, {
        filtros,
        numeroPagina,
        registrosPorPagina,
      })
      return data
    } catch (error) {
      console.log(error)
    }
    return []
  }

  async getRegistrosPacientesSemAgendamento(filtros, numeroPagina = 1, registrosPorPagina = 20) {
    try {
      const { data } = await AxiosClient.post(`Relatorio/pacientesEmTratamentoSemAgendamento`, {
        filtros,
        numeroPagina,
        registrosPorPagina,
      })
      return data
    } catch (error) {
      console.log(error)
    }
    return []
  }

  async getRegistrosAvaliacaoPendente(filtros, numeroPagina = 1, registrosPorPagina = 20) {
    try {
      const { data } = await AxiosClient.post(`Relatorio/avaliacoes`, {
        filtros,
        numeroPagina,
        registrosPorPagina,
      })
      return data
    } catch (error) {
      console.log(error)
    }
    return []
  }

  async getRegistrosResponsaveisProcedimentos(filtros, numeroPagina = 1, registrosPorPagina = 20) {
    try {
      const { data } = await AxiosClient.post(`Relatorio/responsaveisProcedimentos`, {
        filtros,
        numeroPagina,
        registrosPorPagina,
      })
      return data
    } catch (error) {
      console.log(error)
    }
    return []
  }

  async getRegistrosDevedores(filtros, numeroPagina = 1, registrosPorPagina = 20) {
    try {
      const { data } = await AxiosClient.post(`Relatorio/pacientesDevedores`, {
        filtros,
        numeroPagina,
        registrosPorPagina,
      })
      return data
    } catch (error) {
      console.log(error)
    }
    return []
  }

  async getRegistrosNegativados(filtros, numeroPagina = 1, registrosPorPagina = 20) {
    try {
      const { data } = await AxiosClient.post(`Relatorio/historicoNegativados`, {
        filtros,
        numeroPagina,
        registrosPorPagina,
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  async getPlanosCancelados(filtros, numeroPagina = 1, registrosPorPagina = 20) {
    try {
      const { data } = await AxiosClient.post(`Relatorio/planosCancelados`, {
        filtros,
        numeroPagina,
        registrosPorPagina,
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  async getManutencoes(filtros, numeroPagina = 1, registrosPorPagina = 20) {
    try {
      const { data } = await AxiosClient.post(`Relatorio/manutencaoOrtodontia`, {
        filtros,
        numeroPagina,
        registrosPorPagina,
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  salvarHistoricoNegativacao(historicoNegativacao) {
    return AxiosClient.post('Relatorio/addHistoricoNegativados', historicoNegativacao)
  }

  async atualizarHistoricoNegativacao(historicoNegativacao) {
    return await AxiosClient.put('Relatorio/updateHistoricoNegativados', historicoNegativacao)
  }
}

export default new ReltorioService()

import axios from './axios-client'

class AccountService {
	/**
	 * Efetua o login no sistema. Necessario cpf e password
	 * @param {} data
	 */
	login(data) {
		return axios.anonymousPost('Account/login', data)
	}

	alternarConta(){
		return axios.put(`Account/alternarConta`);
	}

	hasPermission(franquiaId, path){
		return axios.get(`Account/hasPermission`, {
			params: {
				franquiaId: franquiaId,
				path: path } });
	}
}

export default new AccountService()

// TYPES
export const LISTAR_AGENDAMENTOS_DENTISTA = 'LISTAR_AGENDAMENTOS_DENTISTA'
export const SALVAR_BLOQUEIOS_DENTISTA = 'SALVAR_BLOQUEIOS_DENTISTA'
export const SET_NOT_LOADING = 'SET_NOT_LOADING'
export const PROXIMO_BLOQUEIO = 'PROXIMO_BLOQUEIO'
export const REQUEST_AGENDAMENTOS_DENTISTA = 'REQUEST_AGENDAMENTOS_DENTISTA'
export const EDITAR_AGENDAMENTO_DENTISTA = 'EDITAR_AGENDAMENTO_DENTISTA'
export const SALVAR_AGENDAMENTO_DENTISTA = 'SALVAR_AGENDAMENTO_DENTISTA'
export const GET_AGENDAMENTO_DENTISTA_ID = 'GET_AGENDAMENTO_DENTISTA_ID'
export const INIT_RELOAD_DENDISTA = 'INIT_RELOAD_DENDISTA'
export const STOP_RELOAD_DENDISTA = 'STOP_RELOAD_DENDISTA'

// ACTIONS
export const requestAgendamentosDentista = (dataInicial, dataFinal, colaboradorId) => ({
  type: REQUEST_AGENDAMENTOS_DENTISTA,
  dataInicial,
  dataFinal,
  colaboradorId,
})

export const editarAgendamentoDentista = (agendamentoId) => ({
  type: GET_AGENDAMENTO_DENTISTA_ID,
  agendamentoId,
})

export const salvarAgendamentoDentista = (agendamento) => ({
  type: SALVAR_AGENDAMENTO_DENTISTA,
  agendamento,
})

export const salvarBloqueiosDentista = (datas, colaboradorId, callback) => ({
  type: SALVAR_BLOQUEIOS_DENTISTA,
  datas,
  colaboradorId,
  callback,
})

export const finishReload = () => ({
  type: STOP_RELOAD_DENDISTA,
})

// REDUCER
const initialState = {
  isLoading: false,
  refreshAgendamentos: false,
  agendamentosDentista: [],
  selectedAgendamentoDentista: {},
}

export const agendamentosDentistaReducer = (state = initialState, action) => {
  switch (action.type) {
    case LISTAR_AGENDAMENTOS_DENTISTA:
      return {
        ...state,
        agendamentosDentista: action.agendamentosDentista,
      }
    case EDITAR_AGENDAMENTO_DENTISTA:
      return {
        ...state,
        selectedAgendamento: action.selectedAgendamento,
      }
    case REQUEST_AGENDAMENTOS_DENTISTA:
    case SALVAR_BLOQUEIOS_DENTISTA:
      return {
        ...state,
        isLoading: true,
      }
    case SET_NOT_LOADING:
      return {
        ...state,
        isLoading: false,
      }
    case INIT_RELOAD_DENDISTA:
      return {
        ...state,
        refreshAgendamentos: true,
      }
    case STOP_RELOAD_DENDISTA:
      return {
        ...state,
        refreshAgendamentos: false,
      }
    default:
      return state
  }
}

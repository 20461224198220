import { connect } from 'react-redux'
import { getDoDia, salvarStatusAgendamento } from '../../services/reducers/agendamentos.reducer'
import { finishReload } from '../../services/reducers/agendamentosDentista.reducer'
import { requestColaboradores } from '../../services/actions/colaboradores.actions'
import { iniciarAvaliacao, iniciarTratamento } from '../../services/reducers/avaliacao.reducer'
import { bindActionCreators } from 'redux'

import Dashboard from '../../pages/Dashboard/Geral'

const mapStateToProps = (store) => ({
  autenticado: store.accountState.user.authenticated,
  agendamentos: store.agendamentosState.agendamentos,
  user: store.accountState.user,
  colaboradores: store.colaboradoresState.colaboradores.map((c) => ({
    label: c.nome,
    value: c.id,
  })),
  shouldReloadAgendamentos: store.agendamentosDentistaState.refreshAgendamentos,
})

const mapDispatchToProps = (dispatch) => {
  dispatch(requestColaboradores())
  dispatch(getDoDia())
  return bindActionCreators(
    { getDoDia, salvarStatusAgendamento, iniciarAvaliacao, iniciarTratamento, finishReload },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

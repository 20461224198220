import { Permissoes } from "../types";

const initialState = {
  permissoes: [],
  funcionalidades: []
};

export const permissoesReducer = (state = initialState, action) => {
  switch (action.type) {
    case Permissoes.LISTAR_PERMISSOES:
      return {
        ...state,
        permissoes: action.permissoes
      };
    case Permissoes.LISTAR_FUNCIONALIDADES:
      return {
        ...state,
        funcionalidades: action.funcionalidades
      };
    default:
      return state;
  }
};

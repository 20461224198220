import React, { Fragment } from 'react'
import { Button } from 'react-bootstrap'
import { imagensDentes } from '../../constants/agendamento.constants'

const DenteButton = (props) => {
  let { codigo, ausente, dente } = props.dente
  const { disabled, onClick, isSelected } = props
  if (dente) {
    codigo = dente.codigo
  }
  return (
    <Button
      variant={ausente ? 'danger' : 'light'}
      disabled={disabled}
      onClick={onClick}
      style={isSelected && !ausente ? selectedDenteStyle : denteStyle}
      className='d-flex justify-content-around align-items-center flex-column font-weight-bold'
    >
      {codigo > 65 || (codigo >= 31 && codigo <= 49) ? (
        <Fragment>
          {codigo}
          <img style={imgStyle} src={imagensDentes[codigo]} alt='' />
        </Fragment>
      ) : (
        <Fragment>
          <img style={imgStyle} src={imagensDentes[codigo]} alt='' />
          {codigo}
        </Fragment>
      )}
    </Button>
  )
}

export default DenteButton

const denteStyle = {
  padding: '10px 5px',
  cursor: 'pointer',
  border: '1px solid #b0b0b0',
}

const imgStyle = {
  width: '80%',
  height: 'auto',
}

const selectedDenteStyle = {
  ...denteStyle,
  backgroundColor: 'rgba(15, 210, 15, 0.6)',
  border: '1px solid rgba(15, 210, 15, 1)',
}

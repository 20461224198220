import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AtendimentoRecepcao from '../../components/Ortodontia/AtendimentoRecepcao'

const mapStateToProps = (store) => ({
  agendamento: store.avaliacaoState.agendamento,
  anamneseFichaId: store.avaliacaoState.anamneseFichaId,
  pacienteId: store.avaliacaoState.pacienteId,
  pacienteNome: store.avaliacaoState.pacienteNome,
  pacienteCpf: store.avaliacaoState.pacienteCpf,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AtendimentoRecepcao)

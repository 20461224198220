import { put, takeEvery, takeLatest, all } from "redux-saga/effects";
import axios from "./axios-client";
import { Procedimentos, Notificacoes } from "../types";

function* fetchProcedimentos() {
  try {
    const { data } = yield axios.get("Procedimento/getAll");
    yield put({
      type: Procedimentos.LISTAR_PROCEDIMENTOS,
      procedimentos: data
    });
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications });
  }
}

function* fetchDentes() {
  try {
    const { data } = yield axios.get("Common/getDentes");
    yield put({
      type: Procedimentos.LISTAR_DENTES,
      dentes: data
    });
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications });
  }
}

function* salvarProcedimento(action) {
  try {
    const { procedimento } = action;
    yield axios.post("Procedimento/salvar", procedimento);
    yield put({ type: Procedimentos.REQUEST_PROCEDIMENTOS });
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications });
  }
}

function* getById(action) {
  try {
    const { data } = yield axios.get(
      `Procedimento/getById/${action.procedimentoId}`
    );
    yield put({
      type: Procedimentos.EDITAR_PROCEDIMENTO,
      selectedProcedimento: data
    });
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications });
  }
}

function* inativar(action) {
  try {
    yield axios.put(
      `Procedimento/ativar/${action.procedimentoId}/${action.ativar}`
    );
    yield put({ type: Procedimentos.REQUEST_PROCEDIMENTOS });
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications });
  }
}

function* procedimentosListener() {
  yield all([
    takeEvery(Procedimentos.REQUEST_DENTES, fetchDentes),
    takeEvery(Procedimentos.REQUEST_PROCEDIMENTOS, fetchProcedimentos),
    takeLatest(Procedimentos.GET_PROCEDIMENTO_ID, getById),
    takeEvery(Procedimentos.SALVAR_PROCEDIMENTO, salvarProcedimento),
    takeEvery(Procedimentos.INATIVAR_PROCEDIMENTO, inativar)
  ]);
}

export default procedimentosListener;

import AxiosClient from './axios-client'

class AgendaDentistaService {
  getAgendamentosDentista(dataInicial, dataFinal, id) {
    return AxiosClient.get(`AgendaBloqueioDentista/get/${id}`, { dataInicial, dataFinal })
  }

  getAgendaClinicoGeral(colaboradorId) {
    return AxiosClient.get(`AgendaBloqueioDentista/GetAgendaClinicoGeral/${colaboradorId}`)
  }
}

export default new AgendaDentistaService()

import React from 'react'

const RespostaAnamnese = ['Sim', 'Não', 'Não sei']

const Anamnese = (props) => (
  <div>
    {props.anamneseRespostas.map((resposta, i) => (
      <div key={i} className='mt-4'>
        <strong>{resposta.anamnese.pergunta}</strong>
        {resposta.tipoResposta == null ? (
          <p>{resposta.texto}</p>
        ) : (
          <p>{RespostaAnamnese[resposta.tipoResposta] + (resposta.texto ? ` - ${resposta.texto}` : '')}</p>
        )}
      </div>
    ))}
  </div>
)

export default Anamnese

import { all } from "redux-saga/effects";
import perfis from "./perfil.sagas";
import permissoes from "./permissoes.sagas";
import pacientes from "./pacientes.sagas";
import colaboradores from "./colaboradores.sagas";
import agendamentosDentista from "./agendamentosDentista.sagas";
import agendamentos from "./agendamentos.sagas";
import videos from "./videos.sagas";
import procedimentos from "./procedimentos.sagas";
import anamnese from "./anamnese.sagas";
import account from "./account.sagas";

function* sagaListener() {
  yield all([
    account(),
    agendamentosDentista(),
    agendamentos(),
    anamnese(),
    colaboradores(),
    pacientes(),
    perfis(),
    permissoes(),
    procedimentos(),
    videos()
  ]);
}

export default sagaListener;

import React, { Fragment } from 'react'
import { Table } from 'react-bootstrap'
import Moment from 'moment'

import PageLoading from '../Common/PageLoading'
import { statusPlano } from '../../constants/common.constants'

const PlanosDeTratamento = (props) => {
  const { planosTratamento, isLoading, handleEditClick } = props

  const getStatusLabel = (status) => {
    const found = Object.values(statusPlano).find((s) => s.value === status)
    return found ? found.label : ''
  }

  const renderLoading = () => <PageLoading ignoreMenu />

  const renderTable = () => (
    <Fragment>
      <h1 className='h3 mb-4 text-secondary font-weight-bold'>Planos de Tratamento</h1>
      {planosTratamento.length > 0 ? (
        <Table bordered hover>
          <thead>
            <tr>
              <th>Número</th>
              <th className='text-center'>Data</th>
              <th className='text-center'>Status</th>
            </tr>
          </thead>
          <tbody>
            {planosTratamento.map((plano, index) => (
              <tr
                key={index}
                onClick={plano.parcelasEmAtraso ? undefined : () => handleEditClick(plano.id)}
                style={{
                  cursor: plano.parcelasEmAtraso ? 'auto' : 'pointer',
                  backgroundColor: plano.parcelasEmAtraso ? '#ff000040' : undefined,
                }}
              >
                <td>{`Plano - ${plano.numeroPlano}`}</td>
                <td className='text-center'>{Moment(plano.dataCriacao).format('DD/MM/YYYY')}</td>
                <td className='text-center'>{getStatusLabel(plano.statusTratamento)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div>Nenhum plano de tratamento encontrado.</div>
      )}
    </Fragment>
  )

  return <Fragment>{isLoading ? renderLoading() : renderTable()}</Fragment>
}

export default PlanosDeTratamento

import React, { useState, useEffect, Fragment } from 'react'
import formatCPF from '@brazilian-utils/format-cpf'
import { format } from 'date-fns'
import { Col, Row, Table, Button, Spinner, Card } from 'react-bootstrap'
import Select from 'react-select'

import { formatMoney } from '../../utils/string.utils'
import { statusPlanoTratamento } from '../../constants/common.constants'
import RelatorioService from '../../services/relatorio.service'
import colaboradoresService from '../../services/colaboradores.service'
import PageLoading from '../Common/PageLoading'
import Paginator from '../Common/Paginator'
import CSVDownload from './CSVDownload'

const debitoOptions = [
  // filtro.campo = 'debito'
  { label: 'Todos', value: null },
  { label: 'Com Débito', value: true },
  { label: 'Sem Débito', value: false },
]

export default function PacientesSemAgendamento({ selectedRelatorio }) {
  const itensPorPagina = 20

  const [isLoading, setLoading] = useState(false)
  const [itensLista, setItensLista] = useState([])
  const [cabecalhoRelatorio, setCabecalhoRelatorio] = useState([])
  const [resumo, setResumo] = useState(null)
  const [pagina, setPagina] = useState(0)
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [itensCSV, setItensCSV] = useState([])
  const [isLoadingCSV, setLoadingCSV] = useState(false)
  const [downloadCSV, setDownloadCSV] = useState(false)
  const [especialidadeOptions, setEspecialidadeOptions] = useState([])
  const [selectedEspecialidade, setSelectedEspecialidade] = useState(null)
  const [selectedDebito, setSelectedDebito] = useState(debitoOptions[0])
  const [filterLoading, setFilterLoading] = useState(false)

  useEffect(() => {
    didMount()
    setCabecalhoRelatorio(['Nome', 'Dependente', 'CPF', 'Telefone Principal', 'Whats App', 'E-mail', 'Nº Plano', 'Dentista', 'Status', 'Possui Débito'])
  }, [])

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina, selectedEspecialidade, selectedDebito])

  useEffect(() => {
    if (downloadCSV) {
      setTimeout(() => {
        setDownloadCSV(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itensCSV, downloadCSV])

  const didMount = async () => {
    setFilterLoading(true)
    const { data } = await colaboradoresService.getAllEspecialidades()
    const options = data.map(({ nome, id }) => ({
      label: nome,
      value: id,
    }))
    setEspecialidadeOptions(options)
    setSelectedEspecialidade(options[0])
    setFilterLoading(false)
  }

  const getValuesLista = async () => {
    const totalDaLista = itensPorPagina * totalPaginas
    if (totalDaLista >= 1) {
      const filtros = [
        { campo: 'especialidadeId', valor: selectedEspecialidade.value },
        { campo: 'debito', valor: selectedDebito.value },
      ]
      setLoadingCSV(true)
      const { lista, resumo } = await RelatorioService.getRegistrosPacientesSemAgendamento(filtros, 1, totalDaLista)
      const valores = []
      lista.dados.forEach((item) => {
        const statusPlano = statusPlanoTratamento.find((s) => s.value === item.statusTratamento)
        const itemRelatorio = new Object()
        itemRelatorio.nome = item.nome
        itemRelatorio.nomeDependente = item.nomeDependente ? item.nomeDependente : '-'
        itemRelatorio.cpf = formatCPF(item.cpf)
        itemRelatorio.telefonePrincipal = item.telefonePrincipal
        itemRelatorio.telefonesWhatsApp = item.telefonesWhatsApp || '-'
        itemRelatorio.emails = item.emails || '-'
        itemRelatorio.numeroPlano = item.numeroPlano
        itemRelatorio.dentista = item.dentista
        statusPlano ? (itemRelatorio.statusTratamento = statusPlano.label) : (itemRelatorio.statusTratamento = '-')
        itemRelatorio.temDebito = item.temDebito ? 'Sim' : 'Não'
        valores.push(Object.values(itemRelatorio))
      })
      setItensCSV(valores)
      setDownloadCSV(true)
      setLoadingCSV(false)
      return {
        data: valores,
        headers: cabecalhoRelatorio,
        filename: `${selectedRelatorio.label} - ${format(new Date(), 'dd-MM-yyyy')}.csv`,
      }
    }
  }

  const handleSearch = async () => {
    setLoading(true)
    try {
      const filtros = [
        { campo: 'especialidadeId', valor: selectedEspecialidade.value },
        { campo: 'debito', valor: selectedDebito.value },
      ]
      const { resumo, lista } = await RelatorioService.getRegistrosPacientesSemAgendamento(
        filtros,
        pagina + 1,
        itensPorPagina
      )
      setResumo(resumo)
      setItensLista(lista.dados)
      setTotalPaginas(lista.totalPaginas)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  const handleClear = () => {
    setSelectedEspecialidade(especialidadeOptions[0])
    setSelectedDebito(debitoOptions[0])
    setPagina(0)
  }

  const handleChangeEspecialidade = (option) => {
    setSelectedEspecialidade(option)
    setPagina(0)
  }

  const handleChangeDebito = (option) => {
    setSelectedDebito(option)
    setPagina(0)
  }

  const handlePagina = (data) => setPagina(data.selected)

  const renderItens = () =>
    itensLista?.length > 0 ? (
      <Fragment>
        <div className='mt-4 d-flex justify-content-end align-items-center'>
          <CSVDownload asyncExportMethod={getValuesLista}>
            <Button variant='outline-secondary' className='mb-3'>
              {isLoadingCSV ? <Spinner animation='border' size='sm' variant='light' /> : 'Gerar CSV'}
            </Button>
          </CSVDownload>
        </div>

        <Card className='mb-4 py-2'>
          <Row>
            {resumo?.map(({ descricao, valor }, index) => (
              <Col key={index} className='d-flex align-items-center justify-content-center flex-column'>
                <small className='text-center text-muted m-0'>{!!descricao ? descricao.toUpperCase() : '-'}</small>
                <h4 className='text-center m-0 font-weight-bold'>{valor || '-'}</h4>
              </Col>
            ))}
          </Row>
        </Card>

        <Table hover size='sm' className='border rounded-0 shadow-sm' style={{backgroundColor:'white'}}>
          <thead>
            <tr className='text-center'>
              <th>Nome</th>
              <th>Dependente</th>
              <th>CPF</th>
              <th>Telefone Principal</th>
              <th>WhatsApp</th>
              <th>E-mail</th>
              <th>Nº Plano</th>
              <th>Dentista</th>
              <th>Status</th>
              <th>Possui Débito</th>
            </tr>
          </thead>
          <tbody>
            {itensLista.map((item, i) => {
              const {
                nome,
                nomeDependente,
                cpf,
                telefonePrincipal,
                telefonesWhatsApp,
                emails,
                numeroPlano,
                dentista,
                statusTratamento,
                procedimentoProtetico,
                temDebito
              } = item
              const statusPlano = statusPlanoTratamento.find(({ value }) => value === statusTratamento)
              return (
                <tr
                  key={i}
                  className='text-center'
                  style={{ fontSize: 'smaller', backgroundColor: procedimentoProtetico ? 'rgb(208, 235, 251)' : null }}
                >
                  <td>{nome.toUpperCase()}</td>
                  <td>{nomeDependente ? nomeDependente.toUpperCase() : '-'}</td>
                  <td>{formatCPF(cpf)}</td>
                  <td>{telefonePrincipal}</td>
                  <td>{telefonesWhatsApp || '-'}</td>
                  <td>{emails || '-'}</td>
                  <td>{numeroPlano || '-'}</td>
                  <td>{dentista || '-'}</td>
                  <td>{statusPlano ? statusPlano.label : '-'}</td>
                  <td>{temDebito ? 'Sim' : 'Não'}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {totalPaginas > 1 && <Paginator handlePagina={handlePagina} pageCount={totalPaginas} paginaAtual={pagina} />}
      </Fragment>
    ) : (
      <div className='mt-4'>Nenhum registro encontrado</div>
    )

  const renderLoading = () => <PageLoading />

  return (
    <Fragment>
      <Row>
        <Col xs={4}>
          <Select
            placeholder='Especialidade'
            onChange={handleChangeEspecialidade}
            value={selectedEspecialidade}
            options={especialidadeOptions}
          />
          {filterLoading && <Spinner animation='border' variant='primary' size='sm' style={spinnerStyle} />}
        </Col>
        <Col xs={4}>
          <Select placeholder='Débito' onChange={handleChangeDebito} value={selectedDebito} options={debitoOptions} />
        </Col>
        <Col xs={4} className='d-flex justify-content-end align-items-center'>
          <Button disabled={filterLoading} onClick={handleClear} variant='outline-danger'>
            Limpar
          </Button>
        </Col>
      </Row>
      {isLoading ? renderLoading() : renderItens()}
    </Fragment>
  )
}

const spinnerStyle = {
  position: 'absolute',
  top: '10px',
  right: '50px',
}

import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'

import { tiposResposta } from '../../constants/anamnese.constants'

const Anamnese = (props) => {
  const { onChangeRadio, onChangeTexto, respostas, avaliacaoPreenchida, selectedFicha } = props

  return (
    <Form className='pb-4 required'>
      <Row className='d-flex align-items-center mb-2'>
        <Col className=' align-items-center'>
          <h1 className='h3 m-0 text-secondary font-weight-bold'>{selectedFicha ? selectedFicha.nome : ''}</h1>
        </Col>
      </Row>
      <div className='pl-1 inner-scroll'>
        {selectedFicha &&
          selectedFicha.anamnesePerguntas.map((p, i) => {
            const respostaSimTextoVazio =
              respostas[i] &&
              respostas[i].tipoResposta &&
              Number(respostas[i].tipoResposta) === 0 && // Resposta 'Sim'
              respostas[i].texto !== null &&
              respostas[i].texto.length === 0
            return (
              <Row key={i}>
                <Form.Group as={Col} className={p.tipoResposta !== null ? 'col-md-6' : 'col-md-6 required'}>
                  <Form.Label className='required'>
                    {i + 1} - {p.pergunta}
                  </Form.Label>
                  <div className='d-flex '>
                    {tiposResposta[p.tipoResposta] &&
                      tiposResposta[p.tipoResposta].map((t, j) => {
                        return (
                          <Form.Check
                            type='radio'
                            disabled={avaliacaoPreenchida}
                            key={`${p.id}${j}`}
                            onChange={(e) => onChangeRadio(i, e)}
                            required
                            className='my-2 mr-2'
                            name={p.id}
                            label={t.label}
                            value={t.value}
                            checked={respostas[i] && Number(respostas[i].tipoResposta) === t.value}
                          />
                        )
                      })}
                  </div>
                  {p.campoTexto && (
                    <Form.Control
                      disabled={avaliacaoPreenchida}
                      name={p.id}
                      value={respostas[i] ? respostas[i].texto : ''}
                      required={respostas[i] && respostas[i].tipoResposta}
                      placeholder={p.tipoResposta !== null ? 'Se sim, justifique a resposta' : ''}
                      className={respostaSimTextoVazio ? 'border-danger' : ''}
                      onChange={(e) => onChangeTexto(i, e)}
                      type='text'
                    />
                  )}
                </Form.Group>
              </Row>
            )
          })}
      </div>
    </Form>
  )
}

export default Anamnese

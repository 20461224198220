import { put, takeEvery, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-toastify";
import axios from "./axios-client";
import { Anamnese } from "../types";

function* fetchAnamnese() {
  try {
    const { data } = yield axios.get("Anamnese/getAll");
    yield put({ type: Anamnese.LISTAR_ANAMNESE, anamnese: data });
  } catch (error) {
    console.log(error);
  }
}

function* salvarAnamnese(action) {
  try {
    const { anamnese } = action;
    yield axios.post("Anamnese/salvar", anamnese);
    toast(`Sua pergunta foi salva com sucesso`, {
      type: toast.TYPE.SUCCESS
    });
    yield all([
      put({ type: Anamnese.REQUEST_ANAMNESE }),
      put({ type: Anamnese.RESET_ANAMNESE })
    ]);
  } catch (error) {
    console.log(error);
  }
}

function* getById(action) {
  try {
    const { data } = yield axios.get(`Anamnese/getById/${action.anamneseId}`);
    yield put({
      type: Anamnese.EDITAR_ANAMNESE,
      selectedAnamnese: data
    });
  } catch (error) {
    console.log(error);
  }
}

function* getRespostasPaciente(action) {
  try {
    const { data } = yield axios.get(
      `Anamnese/getRespostasByPacienteId/${action.pacienteId}`
    );
    yield put({
      type: Anamnese.RESPOSTAS_PACIENTE,
      respostasPaciente: data
    });
  } catch (error) {
    console.log(error);
  }
}

function* toggleAnamnese(action) {
  try {
    yield axios.put(`/Anamnese/ativar/${action.anamneseId}/${action.ativar}`);
    const ativada = action.ativar ? "ativada" : "inativada";
    toast(`Esta pergunta foi ${ativada} com sucesso`, {
      type: toast.TYPE.SUCCESS
    });
    yield put({ type: Anamnese.REQUEST_ANAMNESE });
  } catch (error) {
    console.log(error);
  }
}

function* anamnesesListener() {
  yield all([
    takeEvery(Anamnese.REQUEST_ANAMNESE, fetchAnamnese),
    takeLatest(Anamnese.GET_ANAMNESE_ID, getById),
    takeLatest(Anamnese.GET_ANAMNESE_PACIENTE, getRespostasPaciente),
    takeEvery(Anamnese.SALVAR_ANAMNESE, salvarAnamnese),
    takeEvery(Anamnese.TOGGLE_ANAMNESE, toggleAnamnese)
  ]);
}

export default anamnesesListener;

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EditarProtetico from '../../components/Protetico/EditarProtetico'

const mapStateToProps = (store) => ({
  proteticoDTO: store.accountState.user.proteticoDTO,
  procedimentosProteticos: store.accountState.user.procedimentosProteticos,
  tipoUsuario: store.accountState.user.tipoUsuario
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EditarProtetico)

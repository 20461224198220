import React, { useState, useEffect, Fragment } from 'react'
import StepWizard from 'react-step-wizard'

import { filtroNaoSimAmbos, statusPlano } from '../../../constants/common.constants'
import PageLoading from '../../Common/PageLoading'
import Manutencao from './Manutencao'
import SelecaoPlanos from './SelecaoPlanos'
import ortodontiaService from '../../../services/ortodontia.service'
import { formatMoney } from '../../../utils/string.utils'

export default function ProcedimentoOrtodontiaWizard(props) {
  const { agendamento, history } = props

  const [isLoading, setIsLoading] = useState(true)
  const [selectedPlano, setSelectedPlano] = useState(null)
  const [planosTratamento, setPlanosTratamento] = useState([])
  const [aparelhosComplementaresOptions, setAparelhosComplementaresOptions] = useState([])

  useEffect(() => {
    didMount()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const didMount = async () => {
    if (!agendamento || !agendamento.id) {
      history.push('/atendimento')
    } else {
      await Promise.all([getPlanosTratamento(), getAparelhosComplementares()])
      setIsLoading(false)
    }
  }

  const getPlanosTratamento = async () => {
    if (agendamento) {
      try {
        const { pacienteId, dependenteId } = agendamento

        const response = dependenteId
          ? await ortodontiaService.getPlanosTratamentoByDependente(dependenteId)
          : await ortodontiaService.getPlanosTratamentoByPaciente(pacienteId)

        const planos = response.data.filter((plano) => plano.statusTratamento !== statusPlano.FINALIZADO.value)

        setPlanosTratamento(planos)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const getAparelhosComplementares = async () => {
    try {
      const { data } = await ortodontiaService.getAparelhosCadastrados(filtroNaoSimAmbos.SIM.value)
      const lista = data.filter((apar) => apar.ativo && apar.complementar)
      setAparelhosComplementaresOptions(
        lista.map((apar) => ({
          ...apar,
          value: apar.id,
          label: `${apar.nome} - ${formatMoney(apar.valorVenda)}`,
        }))
      )
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Fragment>
      <StepWizard isLazyMount>
        <SelecaoPlanos
          agendamento={agendamento}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          planosTratamento={planosTratamento}
          setSelectedPlano={setSelectedPlano}
        />
        <Manutencao
          {...props}
          setIsLoading={setIsLoading}
          selectedPlano={selectedPlano}
          aparelhosComplementaresOptions={aparelhosComplementaresOptions}
        />
      </StepWizard>
      {isLoading && <PageLoading />}
    </Fragment>
  )
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  editarAgendamentoDentista,
  salvarBloqueiosDentista,
} from '../../services/reducers/agendamentosDentista.reducer'
import AxiosClient from '../../services/axios-client'
import BloqueioDentista from '../../components/BloqueioDentista'

const { logout } = AxiosClient

const mapStateToProps = (store) => {
  let mesesAConfigurar = store.accountState.user.mesesAConfigurar
  if (typeof mesesAConfigurar === 'string') {
    mesesAConfigurar = mesesAConfigurar.split(`,`)
  }
  return {
    mesesAConfigurar,
    logout,
    colaboradorId: store.accountState.user.colaboradorId,
    agendamentosDentista: store.agendamentosDentistaState.agendamentosDentista,
    isLoading: store.agendamentosDentistaState.isLoading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editarAgendamentoDentista,
      salvarBloqueiosDentista,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BloqueioDentista)

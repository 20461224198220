/**
 * Utilitario responsavel por validacoes e manipulacoes do token JWT
 */
class JwtUtils {
  /**
   * Usado para decodificar um token JWT
   * @param {string} token
   */
  decode(token) {
    try {
      const base64 = token.split('.')[1]
      return JSON.parse(Buffer.from(base64, 'base64'))
    } catch {
      throw Error('Invalid authentication token')
    }
  }

  /**
   * Usado para verificar se o token jwt esta expirado
   * @param {string} token
   */
  isTokenExpired(token) {
    if (!token) {
      return true
    }

    try {
      const obj = this.decode(token)
      console.log(obj.exp < new Date().getTime() / 1000 ? 'expired' : 'valid', new Date(obj.exp * 1000).toTimeString())
      return obj.exp < new Date().getTime() / 1000
    } catch (error) {
      console.warn(`Error decoding JWT: ${error}`)
      return true
    }
  }

  /**
   * Retorna o id do colaborador presente no token jwt
   */
  getColaboradorId(token) {
    if (token) {
      const obj = this.decode(token)
      return obj.ColaboradorId
    }

    return null
  }
}

export default new JwtUtils()

import React, { Component, Fragment, useState, useEffect } from 'react'
import { Button, Col, Row, Form, Table } from 'react-bootstrap'
import MaskedInput from 'react-text-mask'
import { isValidCnpj } from '@brazilian-utils/is-valid-cnpj'
import { toast } from 'react-toastify'
import CurrencyInput from 'react-currency-input'

import { cnpjMask } from '../../constants/mask.constants'
import ProteticoService from '../../services/protetico.service.js'
import Enderecos from '../Common/Enderecos'
import PageLoading from '../Common/PageLoading'
import { TIPO_USUARIO } from '../../constants/usuario.constants'
import SystemConstants from '../../constants/system.constants'
import proteticoService from '../../services/protetico.service.js'

class EditarProtetico extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cnpj: '',
      email: '',
      razaoSocial: '',
      nomeFantasia: '',
      telefone: '',
      enderecos: [{}],
      isLoading: false,
      invalidCNPJ: false,     
      usuarioId: null
    }
  }

  async componentDidMount() {

    let user = JSON.parse(localStorage.getItem(SystemConstants.getUser()));

    this.setState({ ...this.state, isLoading: true }, async () => {
      try {
        const { data } = await ProteticoService.getById(user.colaboradorId)
        console.log(data)
        const { id, cnpj, email, razaoSocial, nomeFantasia, telefone, endereco, usuarioId } = data
        this.setState({
          ...this.state,
          id : user.colaboradorId,
          cnpj,
          email,
          razaoSocial,
          nomeFantasia,
          telefone,
          enderecos: endereco ? [endereco] : [{}],
          isLoading: false,
          usuarioId
        })
      } catch (error) {
        this.setState({ ...this.state, isLoading: false })
      }
    })
  }

  isValid = () => {
    const { cnpj, email, razaoSocial, nomeFantasia, telefone, enderecos, invalidPassword } = this.state
    const { cep, logradouro, numero, bairro, cidade, estado } = enderecos[0]
    return (
      isValidCnpj(cnpj) &&
      email &&
      razaoSocial &&
      nomeFantasia &&
      telefone &&
      telefone.length >= 13 &&
      !invalidPassword &&
      cep &&
      cep.length >= 8 &&
      logradouro &&
      numero &&
      bairro &&
      cidade &&
      estado
    )
  }

  handleSave = () => {
    if (this.isValid()) {
      this.setState({ ...this.state, isLoading: true }, async () => {
        try {
          await ProteticoService.updateProtetico(this.getProteticoDTO())
          toast('Salvo com sucesso!', { type: toast.TYPE.SUCCESS })
          this.setState({
            ...this.state,            
            isLoading: false,
          })
        } catch (e) {
          console.error(e)
          this.setState({ ...this.state, isLoading: false })
        }
      })
    } else {
      toast('Dados inválidos no formulário!', { type: toast.TYPE.ERROR })
    }
  }

  getProteticoDTO = () => {
    const { id, cnpj, email, razaoSocial, nomeFantasia, telefone, enderecos, tabelaPrecos, usuarioId } = this.state
    return {
      ...this.state,
      id,
      cnpj,
      email,
      razaoSocial,
      nomeFantasia,
      telefone,
      tabelaPrecos,
      endereco: enderecos[0],
      usuarioId
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({ ...this.state, [name]: value })


  }

  handleChangeTelefone = (event) => {
    const { value } = event.target
    this.setState({ ...this.state, telefone: this.maskPhone(value) })
  }

  maskPhone = (v) => {
    v = v.replace(/\D/g, '')
    v = v.replace(/^(\d{2})(\d)/g, '($1)$2')
    v = v.replace(/(\d)(\d{4})$/, '$1-$2')
    return v
  }

  handleBlurCNPJ = (event) => {
    this.setState({ ...this.state, invalidCNPJ: !isValidCnpj(event.target.value) })
  }

  setEndereco = (index, endereco) => {
    const enderecos = [...this.state.enderecos]
    enderecos[index] = endereco
    this.setState({ enderecos })
  }

  renderLoading = () => <PageLoading />

  renderForm = () => {
    const { invalidCNPJ, cnpj, email, telefone, razaoSocial, nomeFantasia, enderecos } = this.state
    return (
      <Fragment>
        <h1 className='h3 m-0 text-secondary font-weight-bold'>Editar Protético</h1>
        <Form className='py-5'>
          <Row style={{ marginBottom: '-10px' }}>
            <Col>
              <Form.Group className='required'>
                <Form.Label htmlFor='cnpj'>CNPJ:</Form.Label>
                <MaskedInput
                  name='cnpj'
                  value={cnpj}
                  mask={cnpjMask}
                  className={invalidCNPJ ? 'form-control is-invalid' : 'form-control'}
                  onChange={this.handleChange}
                  onBlur={this.handleBlurCNPJ}
                  required
                />
                {invalidCNPJ && <div className='invalid-feedback'>Digite um CNPJ válido.</div>}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='required'>
                <Form.Label htmlFor='enderecoEmail'>E-mail:</Form.Label>
                <Form.Control
                  type='email'
                  name='email'
                  value={email}
                  onChange={this.handleChange}
                  onBlur={this.handleBlurEmail}
                  maxLength={100}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='required'>
                <Form.Label>Telefone:</Form.Label>
                <Form.Control
                  name='telefone'
                  type='text'
                  value={telefone}
                  onChange={this.handleChangeTelefone}
                  required
                  maxLength={14}
                />
              </Form.Group>
            </Col>
            <Col md='3'>
              <Form.Group className='required'>
                <Form.Label htmlFor='nome'>Razão Social:</Form.Label>
                <Form.Control
                  type='text'
                  name='razaoSocial'
                  value={razaoSocial}
                  onChange={this.handleChange}
                  maxLength={100}
                  required
                />
              </Form.Group>
            </Col>
            <Col md='3'>
              <Form.Group className='required'>
                <Form.Label htmlFor='nome'>Nome Fantasia:</Form.Label>
                <Form.Control
                  type='text'
                  name='nomeFantasia'
                  value={nomeFantasia}
                  onChange={this.handleChange}
                  maxLength={100}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Enderecos endereco={enderecos[0]} index={0} onChange={this.setEndereco} disableAll={false} />
            </Col>
          </Row>

          <div className='d-flex align-items-center justify-content-end'>
            <Button variant='primary' onClick={this.handleSave}>
              Salvar
            </Button>
          </div>
        </Form>
      </Fragment>
    )
  }

  render() {
    return this.state && this.state.isLoading ? this.renderLoading() : this.renderForm()
  }
}

export default EditarProtetico

import React from 'react'
import { NavLink } from 'react-router-dom'

const SidebarSubItem = (props) => {
  const { item, requestAcoes } = props

  return (
    <li onClick={() => requestAcoes(item.id)} key={item.id} className='sidebarSubItem'>
      <NavLink
        to={item.caminho.toLowerCase()}
        activeClassName='active'
        className='d-flex align-items-center justify-content-between'
      >
        <span>{item.nome}</span>
      </NavLink>
    </li>
  )
}

export default SidebarSubItem

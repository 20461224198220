import React from 'react'
import { Table, Col } from 'react-bootstrap'

export default function AvaliacoesColaboradorEspecialidade({ avaliacoesEfetivadasColaborador }) {
  return (
    <Col className='mx-3 py-3 shadow border'>
      <div className='d-flex flex-column align-items-center'>
        <h3 className='m-0 mb-4 text-secondary font-weight-bold'>Avaliações Efetivadas por Especialidade</h3>
      </div>
      <Table size='sm' bordered hover>
        <thead>
          <tr className='text-center'>
            <th>Colaborador</th>
            {avaliacoesEfetivadasColaborador.length > 0 ? (
              avaliacoesEfetivadasColaborador[0].data.map((col, index) => <th key={index}>{col.name}</th>)
            ) : (
              <th></th>
            )}
          </tr>
        </thead>
        <tbody>
          {avaliacoesEfetivadasColaborador.map((avaliacao, index) => (
            <tr key={index} className='text-center' style={{ fontSize: 'small' }}>
              <td>{avaliacao.nomeColaborador}</td>
              {avaliacao.data.map((col, index) => (
                <td key={index}>{`${col.efetivado} (${col.percentual})`}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Col>
  )
}

import React, { useState } from 'react'
import StepWizard from 'react-step-wizard'

import MenuContext from '../../../contexts/MenuContexts'
import AgendamentosRadiologia from './AgendamentosRadiologia'
import ArquivosRadiologia from './ArquivosRadiologia'
import PageLoading from '../../Common/PageLoading'

export default function AcompanhamentoRadiologia(props) {
  let acoesPermitidas = {}
  const [selectedAgendamento, setSelectedAgendamento] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [shouldReload, setShouldReload] = useState(false)

  return (
    <MenuContext.Consumer>
      {(acoes) => {
        acoes.map((a) => {
          acoesPermitidas[`${a.nome}`] = a
          return true
        })
        return (
          <div>
            <h1 className='h3 m-0 text-secondary font-weight-bold'>Acompanhamento de Radiologia</h1>
            {isLoading && <PageLoading />}
            <StepWizard isLazyMount>
              <AgendamentosRadiologia
                shouldReload={shouldReload}
                setShouldReload={setShouldReload}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                selectedAgendamento={selectedAgendamento}
                setSelectedAgendamento={setSelectedAgendamento}
              />
              <ArquivosRadiologia
                setShouldReload={setShouldReload}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                arquivos={selectedAgendamento ? selectedAgendamento.arquivos : []}
                setSelectedAgendamento={setSelectedAgendamento}
                selectedAgendamento={selectedAgendamento}
              />
            </StepWizard>
          </div>
        )
      }}
    </MenuContext.Consumer>
  )
}

import React, { Fragment, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import ParcelasList from './ParcelasList'
import CollapsePanel from '../../../Common/CollapsePanel'
import OrtodontiaService from '../../../../services/ortodontia.service'
import { toast } from 'react-toastify'

export default function RealizarPagamento({
  firstStep,
  setIsLoading,
  parcelamentoPagamento,
  refreshPlanosTratamentoOrtodontiaList,
}) {
  const INITIAL_OPEN_STATE = { step0: false }
  const [stepIsOpen, setStepIsOpen] = useState({ ...INITIAL_OPEN_STATE })

  const panels = !parcelamentoPagamento
    ? []
    : [
        ...Object.values(parcelamentoPagamento.plano),
        ...Object.values(parcelamentoPagamento.aparelho),
        ...Object.values(parcelamentoPagamento.complementar),
        ...Object.values(parcelamentoPagamento.suprimentos),
      ]

  const toggleIsOpen = (step) => {
    let newSteps = { ...INITIAL_OPEN_STATE }
    Object.keys(panels).forEach((_, i) => {
      newSteps[`step${i}`] = false
    })
    setStepIsOpen({ ...newSteps, [step]: !stepIsOpen[step] })
  }

  const handleGoBack = () => {
    firstStep()
    refreshPlanosTratamentoOrtodontiaList()
    setStepIsOpen({ ...INITIAL_OPEN_STATE })
  }

  const handleSave = async (parcelasAlteradas) => {
    setIsLoading(true)
    try {
      await OrtodontiaService.alterarVencimentoParcelas(parcelasAlteradas)
      handleGoBack()
      toast('Novo vencimento salvo com sucesso', { type: toast.TYPE.SUCCESS })
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const renderPanels = () => (
    <Fragment>
      {panels.map((parcelas, index) => {
        const { nomeAparelho, nomeAparelhoComplementar, descricaoSuprimento } = parcelas[0]
        const text = nomeAparelho || nomeAparelhoComplementar || descricaoSuprimento || 'Plano Ortodôntico'
        return (
          <CollapsePanel
            key={index}
            stepCode={`step${index}`}
            isOpen={stepIsOpen[`step${index}`]}
            toggleIsOpen={toggleIsOpen}
            text={text}
          >
            <ParcelasList parcelas={parcelas} handleSave={handleSave} />
          </CollapsePanel>
        )
      })}
    </Fragment>
  )

  return (
    <Fragment>
      <h3 className='my-4 text-secondary font-weight-bold'>Alterar Vencimento</h3>
      {panels.length > 0 ? renderPanels() : <p>Nenhuma parcela em aberto.</p>}
      <div style={backContainerStyle} onClick={handleGoBack}>
        <Button variant='outline-primary' style={backStyle}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <span className='text-primary'>Voltar</span>
      </div>
    </Fragment>
  )
}

const backContainerStyle = {
  cursor: 'pointer',
  width: 'fit-content',
  marginTop: '2em',
}

const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

import { connect } from "react-redux";
import {
  requestAnamnese,
  editarAnamnese,
  toggleAnamnese
} from "../../services/actions/anamnese.actions";
import { bindActionCreators } from "redux";

import AnamneseList from "../../components/Anamnese/AnamneseList";

const mapStateToProps = store => ({
  anamnese: [...store.anamneseState.anamnese]
});

const mapDispatchToProps = dispatch => {
  dispatch(requestAnamnese());
  return bindActionCreators({ editarAnamnese, toggleAnamnese }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnamneseList);

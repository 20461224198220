import React, { useState } from 'react'
import StepWizard from 'react-step-wizard'
import moment from 'moment'

import Financeiro from './Financeiro'
import Impressao from './Impressao'


const RenegociacaoProtese = ({ selectedPlano, firstStep, isLoading, setIsLoading, currentStep, passos, parcelasProteticas, onChangeStep, passoAtual }) => {
  const [pagamento, setPagamento] = useState(null)
  const [totalPagamento, setTotal] = useState(0.0)
  const [showModalImpressao, setShowModalImpressao] = useState(false)

  const handleSetPagamento = async (pag) => {
    setPagamento(pag)
  }

  const toggleModalImpressao = async () => {
    setShowModalImpressao(!showModalImpressao)
  }

  const handleBack = () => {
    firstStep()
    setPagamento(null)
  }
  
  return (
    <StepWizard isLazyMount>
      <Financeiro
        isRenegociacao={currentStep === passos.RENEGOCIAR_PROT}
        selectedPlano={selectedPlano}
        setPagamento={handleSetPagamento}
        onBack={firstStep}
        totalPagamento={totalPagamento}
        setTotal={setTotal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        parcelasProteticas={parcelasProteticas}
        onChangeStep={onChangeStep}
        passoAtual={passoAtual}
        setShowModalImpressao={toggleModalImpressao}
      />
      <Impressao selectedPlano={selectedPlano} pagamento={pagamento} onBack={handleBack} isOpen={true} handleClose={toggleModalImpressao}/>
    </StepWizard>
  )
}

export default RenegociacaoProtese

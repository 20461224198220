import React, { useEffect, useState, Fragment,  useRef  } from 'react'
import { Card, Modal, Button, Form, InputGroup, Row, Col, Table, OverlayTrigger, Tooltip, Toast, FormControl } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'moment/locale/pt-br'
import financeiroService from '../../../services/financeiro.service'
import { faPlus, faSave, faPen, faTimes } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'

export default function ModalSubgrupos({ isOpen, handleClose, dadosGrupoSubGrupos, handleUpdateFilterGrupo }) {

  const [isEditing, setEditing] = useState(false);  
  const [gruposSubgrupos, setGruposSubgrupos] = useState(dadosGrupoSubGrupos)
  const inputRef = useRef(null);
  const [subGrupoTexto, setSubGrupoTexto] = useState('')
  const [subGrupoEdit, setSubGrupoEdit] = useState([])

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);


  useEffect(() => {
    let grupos = [...gruposSubgrupos]

    setGruposSubgrupos(grupos)
  }, [subGrupoEdit])

  const getGrupos = async () => {
    try {
      let { data } = await financeiroService.getGruposContasPagar()
      
      var items = data.map((item) => {
        var retorno = item;
        retorno.blockEdit = item.nome.toLowerCase() === 'salários' || item.nome.toLowerCase() === 'repasse profissionais';
        return retorno;

      });
      
      setGruposSubgrupos(items)

    } catch (error) {
      console.error(error)
    }
  }

  const removerEdicao = () => {
    gruposSubgrupos.map((grupo) => {
      grupo.subGruposContasPagar = grupo.subGruposContasPagar.filter(x => x.id != '00000000-0000-0000-0000-000000000000')
    })

    gruposSubgrupos.map((grupo) => grupo.subGruposContasPagar.map((subgrupo) => 
        subgrupo.isEdit = false
    ))

    setEditing(false)
  }

  const handleSaveAdd = async (grupoSubgrupo, subgrupo, nome) => {
    nome = subGrupoTexto

    var inclusao = subgrupo.id == '00000000-0000-0000-0000-000000000000';

    try {
      if(inclusao)
      {
        await financeiroService.salvarSubGrupoContasPagar(grupoSubgrupo.id, nome)
        
        getGrupos()
        setEditing(!isEditing)
        handleUpdateFilterGrupo()
        return
      }
      await Swal.fire({
        title: 'Confirmar',
        text: `Deseja alterar o subgrupo "${nome}" no grupo "${grupoSubgrupo.nome}"`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar'
      }).then(async (result) => {
        if (!result.value) {
          return
        }
        else {
          if (!nome) {
            toast(`O campo 'Nome do Subgrupo' é obrigatório`, { type: toast.TYPE.ERROR })
            return
          }

          await financeiroService.alterarSubGrupoContasPagar(subgrupo.id, nome)
          toast('Subgrupo alterado com sucesso!', { type: toast.TYPE.SUCCESS })

          getGrupos()
          setEditing(!isEditing)
          handleUpdateFilterGrupo()
        }
      })
    }
    catch (e) {

    }
  }


  const handleToggleAdd = (grupo) => {
    removerEdicao()

    let grupos = [...gruposSubgrupos]

    grupos.find(x => x.id == grupo.id).subGruposContasPagar.push(
      {
        nome: '',
        grupoContasPagarId: grupo.id,
        dataCriacao: new Date(),
        id:'00000000-0000-0000-0000-000000000000',
        grupoContasPagar:null,
        isEdit: true,
        ativo: true
      }
    )

    setGruposSubgrupos(grupos)
    setSubGrupoTexto('')
    
    setEditing(true);

  }

  const handleChangeStatus = async (subgrupo) => {    
  try
    {
      await Swal.fire({
        title: 'Excluir Subgrupo',
        text: `Tem certeza que deseja excluir o subgrupo "${subgrupo.nome}" permanentemente?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar'
      }).then(async (result) => {
        if (!result.value) {
          return
        }
        await financeiroService.alterarStatusSubGrupoContasPagar(subgrupo.id);      
        getGrupos()
        handleUpdateFilterGrupo()
      });
    }
    catch(e){
    }
  }

  const handleKeyPress = (event, grupoSubgrupo, subgrupo) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      handleSaveAdd(grupoSubgrupo, subgrupo, subgrupo.nome)
    }
  }

  const handleInitEdit = (grupoSubgrupo, subgrupo, e) =>{
    removerEdicao()

    subgrupo.isEdit = true

    setSubGrupoTexto(subgrupo.nome)
    setSubGrupoEdit(subgrupo)
    setEditing(true)
  }

  const handleChange = (event) => {
    setSubGrupoTexto(event.target.value);
  }

  return (
    <Modal className='modal-agenda' show={isOpen} onHide={handleClose}>
      <Card>
        <Modal.Header className='p-4' closeButton>
          <Modal.Title>Grupos e Subgrupos</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>
          
          <Fragment>
            <div className='my-4'>  
              {gruposSubgrupos.length > 0 ? (
                <Table size='sm' bordered hover>
                  <thead>
                    <tr>
                      <th width='250px'>Grupo</th>
                      <th>
                        Subgrupos
                      </th>
                      <th width='50px'>
                      </th>
                    </tr>
                  </thead>  
                  <tbody>
                    {gruposSubgrupos.map((grupoSubgrupo, index) => (

                      <tr style={{ fontSize: 'small' }} key={index}>
                        <td>{grupoSubgrupo.nome || '-'}</td>
                        <td>
                          {
                            grupoSubgrupo.subGruposContasPagar.map((subgrupo) => {
                              if(subgrupo.ativo){
                                if(!subgrupo.isEdit ){
                                  return (
                                  <Toast show={true}>
                                    <Toast.Header closeButton={false} icon="primary" style={{padding: '3px'}}>
                                        <div>
                                          <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Alterar</Tooltip>}>
                                            <Button variant="link" size='sm'
                                              onClick={((e) => handleInitEdit(grupoSubgrupo, subgrupo, e))}>
                                              <FontAwesomeIcon icon={faPen} />
                                            </Button>
                                          </OverlayTrigger>
                                        </div>
                                        <small>{subgrupo.nome}</small>
                                        <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Excluir</Tooltip>}>
                                          <Button variant="link" size='sm'
                                            onClick={((e) => handleChangeStatus(subgrupo))}>
                                            <FontAwesomeIcon icon={faTimes} color='#F50000'/>
                                          </Button>
                                        </OverlayTrigger>
                                        
                                    </Toast.Header>
                                    
                                  </Toast>);
                                }
                                else{
                                  return (<Toast show={true} >
                                    <Toast.Header closeButton={false} icon="primary">
                                    {isEditing && 
                                      <InputGroup>
                                        <FormControl
                                          placeholder="subgrupo..."
                                          aria-label="subgrupo.."
                                          aria-describedby="basic-addon2"
                                          ref={inputRef}
                                          value={subGrupoTexto}
                                          onChange={((e) => handleChange(e, subgrupo))}
                                          onKeyPress={((e) => handleKeyPress(e, grupoSubgrupo, subgrupo))}
                                        />
                                        <InputGroup.Append>
                                          <Button variant="outline-primary" 
                                                  onClick={((e) => handleSaveAdd(grupoSubgrupo, subgrupo, e))}>
                                              <FontAwesomeIcon icon={faSave} /></Button>
                                        </InputGroup.Append>
                                      </InputGroup>
                                      }
                                    </Toast.Header>
                                  </Toast>);
                                }
                              }
                            })
                          }
                        </td>
                        <td className='text-center justify-content-around'>
                          <OverlayTrigger
                            trigger='hover'
                            placement='top'
                            overlay={<Tooltip>{grupoSubgrupo.ativo ? 'Desativar grupo' : 'Adicionar Subgrupo'}</Tooltip>}
                          >
                            <Button
                              onClick={() => handleToggleAdd(grupoSubgrupo)}
                              disabled={grupoSubgrupo.blockEdit}
                              size='sm'
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div>Nenhum Grupo encontrado.</div>
              )}
            </div>
          </Fragment>
        </Modal.Body>
      </Card>
    </Modal>
  )
}

import React, { Fragment } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { tiposTelefone } from '../../constants/telefones.constants'

class Telefones extends React.Component {
  state = {
    invalid: false,
  }

  onChangeNumero = (e) => {
    let { value } = e.target
    const { index, telefone, tipoTelefone } = this.props
    telefone.numero = this.mphone(value)
    telefone.tipoTelefone = tipoTelefone || tiposTelefone.PESSOAL
    this.props.onChange(index, telefone)
    const invalid =
      !(telefone.numero.length === 0) && !(telefone.numero.length === 14) && !(telefone.numero.length === 13)
    this.setState({ invalid })
  }

  onChangeNomeContato = (e) => {
    const { index, telefone } = this.props
    telefone.nomeContato = e.target.value
    this.props.onChange(index, telefone)
  }

  setPrincipal = (e) => {
    const { checked } = e.target
    const { index, telefone } = this.props
    if (checked) {
      telefone.principal = checked
    }
    this.props.onChange(index, telefone)
  }
  setWhatsapp = (e) => {
    const { checked } = e.target
    const { index, telefone } = this.props
    telefone.tipoTelefone = checked ? tiposTelefone.WHATSAPP : tiposTelefone.PESSOAL
    this.props.onChange(index, telefone)
  }

  mphone = (v) => {
    v = v.replace(/\D/g, '')
    v = v.replace(/^(\d{2})(\d)/g, '($1)$2')
    v = v.replace(/(\d)(\d{4})$/, '$1-$2')
    return v
  }

  render() {
    const { choosePrincipal, required, telefone, checkWhatsapp, disabled, showContactName, historicoTelefones, handleOpenModalHistoricoTelefone } = this.props

    return (
      <Row>
        <Form.Group as={Col} className={required ? 'required' : ''}>
          <Form.Label htmlFor='numero'>Número de Telefone</Form.Label>
          <Form.Control
            className={this.state.invalid ? 'is-invalid' : ''}
            name='numero'
            type='text'
            value={telefone.numero}
            onChange={this.onChangeNumero}
            disabled={disabled}
            required={required}
            maxLength={14}
          />
          <div className='invalid-feedback'>Telefone inválido.</div>
        </Form.Group>
        
        {telefone.principal && (!historicoTelefones || historicoTelefones.length == 0) && (
           <Col className='d-flex justify-content-center pt-2'></Col>
        )}
          
        {telefone.principal && historicoTelefones && historicoTelefones.length > 0 && (
          <Col className='d-flex pt-2'>
            <Button onClick={handleOpenModalHistoricoTelefone} className='fix-btn-margin' variant='outline-primary' style={{height: '35px', marginTop: '21px'}}>
              <FontAwesomeIcon icon={faPhoneAlt} /> Telefones
            </Button>
          </Col>
        )}
          
        
        {showContactName && !telefone.principal && (
          <Form.Group as={Col} className={required ? 'required' : ''}>
            <Fragment>
              <Form.Label htmlFor='numeroContato'>Nome do Contato</Form.Label>
              <Form.Control
                name='nomeContato'
                value={telefone.nomeContato}
                onChange={this.onChangeNomeContato}
                disabled={disabled}
                required={required}
                maxLength={100}
              />
            </Fragment>
          </Form.Group>
        )}
        {choosePrincipal && (
          <Col className='d-flex justify-content-center pt-2'>
            <label className='checkbox mt-4'>
              <input disabled={disabled} type='checkbox' checked={telefone.principal} onChange={this.setPrincipal} />
              <span className='d-flex align-items-center'>Telefone principal</span>
            </label>
          </Col>
        )}
        {checkWhatsapp && (
          <Col className='d-flex justify-content-center pt-2'>
            <label className='checkbox mt-4'>
              <input
                disabled={disabled}
                type='checkbox'
                checked={telefone.tipoTelefone === 3}
                onChange={this.setWhatsapp}
              />
              <span className='d-flex align-items-center'>WhatsApp</span>
            </label>
          </Col>
        )}
      </Row>
    )
  }
}

export default Telefones

import { Pacientes } from '../types'

export const resetPaciente = () => ({ type: Pacientes.RESET_PACIENTE })

export const resetPlanosTratamento = () => ({
  type: Pacientes.RESET_PLANOS_TRATAMENTO,
})

export const requestPacientes = () => ({ type: Pacientes.REQUEST_PACIENTES })

export const requestPlanosTratamento = (pacienteId) => ({
  type: Pacientes.REQUEST_PLANOS_TRATAMENTO,
  pacienteId,
})

export const salvarPlanoTratamento = (planoDeTratamento) => ({
  type: Pacientes.SALVAR_PLANO_TRATAMENTO,
  planoDeTratamento,
})

export const editarPaciente = (pacienteId, pacienteNome, cpfPaciente, pacienteTelefones, agendamentoId) => ({
  type: Pacientes.EDITAR_PACIENTE,
  pacienteId,
  pacienteNome,
  cpfPaciente,
  pacienteTelefones,
  agendamentoId,
})

export const getPacienteByCpf = (pacienteCpf) => ({
  type: Pacientes.GET_PACIENTE_CPF,
  pacienteCpf,
})

export const salvarPaciente = (paciente) => ({
  type: Pacientes.SALVAR_PACIENTE,
  paciente,
})

export const inativarPaciente = (pacienteId, ativar) => ({
  type: Pacientes.INATIVAR_PACIENTE,
  pacienteId,
  ativar,
})

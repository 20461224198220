import React, { useEffect, useRef, useState } from 'react';
import * as faceapi from 'face-api.js';
import './WebcamFaceDetection.css';
import { convertBase64ToBlob } from '../../utils/anexo.utils';
import faceComparisonService from '../../services/face-comparison.service';

const WebcamFaceDetectionBlink = ({ pacienteId, dependenteId, closeWebcamFaceDetection, cancelWebcamFaceDetection }) => {
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(false);
    const [messageError, setMessageError] = useState("Ocorreu uma falha, por favor tente novamente!");
    const [instructionText, setInstructionText] = useState("Inicializando...");

    const [videoWidth, setVideoWidth] = useState(285);
    const [videoHeight, setVideoHeight] = useState(355);
    const videoRef = useRef(null);
    const [blinkCount, setBlinkCount] = useState(0);

    const startVideo = () => {
        setBlinkCount(0);

        navigator.mediaDevices.getUserMedia({ video: true, audio: false })
            .then(stream => {
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                    videoRef.current.play();
                }
            })
            .catch(err => console.error('Error accessing camera:', err));
    };
    
    const stopVideo = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
            videoRef.current.srcObject = null;
        }
    };

    const handleRetry = () => {
        setError(false);
        setMessageError("");
        setProcessing(false);
        stopVideo();
        startVideo();
    };

    useEffect(() => {
        const loadModels = async () => {
            await Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
                faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
                faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
                faceapi.nets.faceExpressionNet.loadFromUri('/models'),
            ]);
            startVideo();
        };

        loadModels();

    }, []);

    useEffect(() => {
        if (videoRef.current) {
            const displaySize = { width: videoWidth, height: videoHeight };
            const intervalId = setInterval(async () => {
                try {
                    if(!videoRef.current)
                        return;

                    const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();
                    const resizedDetections = faceapi.resizeResults(detections, displaySize);
                    const faceWithMinConfidence = resizedDetections.filter(face => face.detection.score >= 0.7);


                    if (faceWithMinConfidence.length > 0) {
                        const landmarks = faceWithMinConfidence[0].landmarks;
                        const leftEye = landmarks.getLeftEye();
                        const rightEye = landmarks.getRightEye();

                        setInstructionText(`Por favor, centralize o olhar na câmera...`)

                        if (leftEye && rightEye) {
                            const leftEyeEAR = getEyeAspectRatio(leftEye);
                            const rightEyeEAR = getEyeAspectRatio(rightEye);
                            const eyeAspectRatio = (leftEyeEAR + rightEyeEAR) / 2;

                            if (!isNaN(eyeAspectRatio)) {
                                if (blinkCount == 0)
                                    setInstructionText(`Agora pisque por favor...`)

                                if (eyeAspectRatio < 0.5)
                                    setBlinkCount(count => count + 1);

                                if (blinkCount >= 1 && eyeAspectRatio <= 0.4)
                                    setInstructionText(`Agora mantenha seus olhos para luz da câmera aberto...`)

                                if (blinkCount >= 1 && eyeAspectRatio >= 0.4)
                                    captureImage()

                                console.log(`Current Eye Aspect Ratio: ${eyeAspectRatio}`);
                            } else {
                                console.error('Eye Aspect Ratio é NaN');
                            }
                        } else {
                            setInstructionText(`Por favor, aproxime mais os olhos da câmera...`)
                            console.error('Landmarks dos olhos são inválidos');
                        }
                    } else {
                        setInstructionText(`Aguardando facial para reconhecimento...`)
                        console.error('Nenhuma detecção de rosto');
                    }
                } catch (error) {
                    console.error('Erro durante o processamento:', error);
                }
            }, 500);

            return () => clearInterval(intervalId);
        }
    }, [videoRef.current, videoWidth, videoHeight, blinkCount, processing]);

    const getEyeAspectRatio = (eye) => {
        if (eye.length === 6) {
            const A = euclideanDistance(eye[1], eye[5]);
            const B = euclideanDistance(eye[2], eye[4]);
            const C = euclideanDistance(eye[0], eye[3]);
            return (A + B) / (2.0 * C);
        }
        return 0;
    };

    const euclideanDistance = (p1, p2) => {
        return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
    };

    const captureImage = () => {
        const canvas = document.createElement('canvas');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL('image/png');
        setProcessing(true)
        console.log('Image captured');
        handleSubmit(dataUrl)
    };

    const handleSubmit = async (img) => {
        try {
          let formData = new FormData();
          formData.append("capturado", convertBase64ToBlob(img))
    
          if (pacienteId && !dependenteId) {
            faceComparisonService.matchPatient(pacienteId, formData).then(response => {
              closeWebcamFaceDetection();
              setProcessing(false);
            }).catch(error => {
              setMessageError("Paciente não encontrado para comparar reconhecimento Facial, por favor recarregue página e tente novamente.");
    
              if (error.response.status === 403)
                setMessageError("Paciente não é o mesmo do documento de identificação RG/CPF ou afins.");
    
              setError(true);
              setProcessing(false);
            })
    
            return;
          }
    
          if (dependenteId) {
            faceComparisonService.matchPatientByDependentId(dependenteId, formData).then(response => {
              closeWebcamFaceDetection();
              setProcessing(false);
            }).catch(error => {
              setMessageError("Paciente não encontrado para comparar reconhecimento Facial, por favor recarregue página e tente novamente.");
    
              if (error.response.status === 403)
                setMessageError("Paciente não é o mesmo do documento de identificação RG/CPF ou afins.");
    
              setError(true);
              setProcessing(false);
            })
    
            return;
          }
    
          setMessageError("Paciente não é o mesmo do documento de identificação RG/CPF ou afins.");
          setError(true);
          setProcessing(false);
          return;
    
        } catch (error) {
          console.error(error)
          setMessageError("Paciente não é o mesmo do documento de identificação RG/CPF ou afins.");
          setError(true);
          setProcessing(false);
          return;
        }
      }

    return (
        <>

            <div className='capture-container'>
                {!processing ?
                    <>
                        {!error ?
                            <>
                                <div>
                                    <h3>Reconhecimento Facial</h3>
                                    <div className='capture-webcam' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <video ref={videoRef} width={videoWidth} height={videoHeight} />
                                    </div>
                                    <p>{instructionText}</p>
                                </div>
                            </>
                            :
                            <>
                                <h3>Reconhecimento Facial</h3>
                                <h5>Não foi possível, tente novamente!</h5>
                                <p>{messageError}</p>
                                <a onClick={() => handleRetry()}>Tente novamente</a>

                                <a onClick={() => {
                                    setError(false);
                                    setMessageError("");
                                    setProcessing(false);
                                    cancelWebcamFaceDetection();
                                }}>Fechar</a>
                            </>
                        }

                    </>
                    :
                    <>
                        <div className='loading-content'>
                            <img src='/img/face-loading.gif' />
                            <p>Estamos fazendo processamento, por favor aguarde...</p>
                        </div>
                    </>
                }
            </div>
        </>

    );
};

export default WebcamFaceDetectionBlink;

import React, { useState, Fragment, useEffect } from 'react'
import { Form, Button, Table } from 'react-bootstrap'
import Swal from 'sweetalert2'
import moment from 'moment'
import { toast } from 'react-toastify'

import { status } from '../../../constants/common.constants'
import { TIPO_CONSULTA } from '../../../constants/agendamento.constants'
import OrtodontiaService from '../../../services/ortodontia.service'
import AgendamentoService from '../../../services/agendamentos.service'
import Suprimentos from './Suprimentos'
import Complementares from './AparelhosComplementares'
import { statusConstants } from '../../../constants/agendamento.constants'

const statusManutencao = [...status]
statusManutencao.length = 3

export default function ProcedimentoManutencao({
  setIsLoading,
  selectedPlano,
  aparelhosComplementaresOptions,
  agendamento,
  history,
}) {
  const [showSuprimentos, setShowSuprimentos] = useState(false)
  const [showComplementares, setShowComplemtares] = useState(false)
  const [manutencao, setManutencao] = useState(agendamento.tipoConsulta === TIPO_CONSULTA.MANUTENCAO.value)
  const [descricao, setDescricao] = useState('')
  const [historico, setHistorico] = useState(null)

  useEffect(() => {
    getHistorico()
  }, [])

  const getAtendimento = (planoConcluido) => ({
    planoConcluido,
    manutencao,
    observacao: descricao,
    planoTratamentoId: selectedPlano.id,
    agendamentoId: agendamento.id,
  })

  const onClickConcluirPlano = async () => {
    const { value } = await Swal.fire({
      title: 'Deseja realmente concluir o plano? Ao finalizar o plano não será possível mais alterar.',
      type: 'question',
      showCancelButton: true,
      cancelButtonColor: '#dbdbdb',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Concluir',
    })

    if (value) {
      setIsLoading(true)
      try {
        await OrtodontiaService.salvarAtendimento(getAtendimento(true))
        toast('Atendimento concluído com sucesso!', { type: toast.TYPE.SUCCESS })
        history.push('/atendimento')
      } catch (error) {
        console.error(error)
      }
      setIsLoading(false)
    }
  }

  const toggleSuprimentos = () => {
    setShowSuprimentos(!showSuprimentos)
    setShowComplemtares(false)
  }

  const toggleComplemtares = () => {
    setShowComplemtares(!showComplementares)
    setShowSuprimentos(false)
  }

  const handleCloseModals = () => {
    setShowComplemtares(false)
    setShowSuprimentos(false)
  }

  const getHistorico = async () => {
    setIsLoading(true)
    try {
      const { data } = await OrtodontiaService.getHistoricoAtendimentos(selectedPlano.id)
      setHistorico(data)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const handleFinalizar = async () => {
    const { value } = await Swal.fire({
      title:
        'Ao finalizar o atendimento todos os procedimentos serão salvos e não será possível mais alterar. Deseja confirmar?',
      type: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#dbdbdb',
      cancelButtonText: 'Não confirmar',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirmar',
    })
    if (value) {
      setIsLoading(true)
      try {
        agendamento.planoTratamentoOrtodontiaId = selectedPlano.id
        agendamento.statusAgendamento = statusConstants.FINALIZADO.value

        await AgendamentoService.salvarAgendamento(agendamento)

        await OrtodontiaService.salvarAtendimento(getAtendimento(false))
        toast('Atendimento finalizado com sucesso!', { type: toast.TYPE.SUCCESS })
        history.push('/atendimento')
      } catch (error) {
        console.error(error)
      }
      setIsLoading(false)
    }
  }

  return (
    <Fragment>
      <div className='m-0 mb-4 d-flex justify-content-between align-items-center'>
        <h3 className='text-secondary font-weight-bold'>Manutenção Ortodôntica</h3>
        <div>
          <Button onClick={onClickConcluirPlano}>Concluir Plano</Button>
          <Button onClick={toggleSuprimentos} className='ml-3'>
            Suprimentos de Manutenção
          </Button>
          <Button onClick={toggleComplemtares} className='mx-3'>
            Aparelhos Complementares
          </Button>
          <Button onClick={handleFinalizar}>Finalizar Atendimento</Button>
        </div>
      </div>
      {selectedPlano && (
        <Fragment>
          <div>
            Paciente:
            <h4 className='mb-4'>
              {selectedPlano.dependente ? selectedPlano.dependente.nome : selectedPlano.paciente.nome}
            </h4>
          </div>
          <Form.Group controlId='form.manutencao'>
            <Form.Check
              custom
              type='checkbox'
              className='my-2 mr-2'
              id='manutencao'
              name='manutencao'
              label='Manutenção'
              checked={manutencao}
              onChange={(event) => setManutencao(event.target.checked)}
            />
          </Form.Group>
          <Form.Group controlId='form.descricao' className='required'>
            <Form.Label>Descrição:</Form.Label>
            <Form.Control
              as='textarea'
              rows='3'
              required
              value={descricao}
              onChange={(event) => setDescricao(event.target.value)}
            />
          </Form.Group>
          <h5 className='mt-4 text-secondary font-weight-bold'>Histórico</h5>
          <Table className='bg-white' bordered hover>
            <thead>
              <tr>
                <th>Descrição</th>
                <th className='text-center' width='200px'>
                  Colaborador
                </th>
                <th className='text-center' width='200px'>
                  Manutenção
                </th>
                <th className='text-center' width='150px'>
                  Data / Hora
                </th>
              </tr>
            </thead>
            <tbody>
              {historico ? (
                historico.map(({ manutencao, observacao, agendamento, dataCriacao }, index) => (
                  <tr key={index}>
                    <td>{observacao}</td>
                    <td className='text-center'>{agendamento?.colaborador?.nome}</td>
                    <td className='text-center'>{manutencao ? 'Sim' : 'Não'}</td>
                    <td className='text-center'>
                      <p className='m-0'>{moment(dataCriacao).format('DD/MM/YYYY')}</p>
                      <p className='m-0'>{moment(dataCriacao).format('HH:mm')}</p>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan='4'>Nenhum histórico disponível</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Fragment>
      )}
      <Suprimentos
        setIsLoading={setIsLoading}
        selectedPlano={selectedPlano}
        toggleSuprimentos={toggleSuprimentos}
        isOpen={showSuprimentos}
        handleClose={handleCloseModals}
      />
      <Complementares
        setIsLoading={setIsLoading}
        selectedPlano={selectedPlano}
        aparelhosComplementaresOptions={aparelhosComplementaresOptions}
        isOpen={showComplementares}
        handleClose={handleCloseModals}
      />
    </Fragment>
  )
}

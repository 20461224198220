import React, { useEffect, useState } from 'react'
import StepWizard from 'react-step-wizard'

import ListaAparelhos from './ListaAparelhos'
import Parcelamento from './Parcelamento'
import Impressao from './Impressao'
import OrtodontiaService from '../../../../services/ortodontia.service'

export default function AparelhosComplementares({
  firstStep,
  selectedPlano,
  setIsLoading,
  refreshPlanosTratamentoOrtodontiaList,
}) {
  const [wizardRef, setWizardRef] = useState(null)
  const [aparelhosSemPagamento, setAparelhosSemPagamento] = useState([])
  const [pagamento, setPagamento] = useState(null)

  useEffect(() => {
    if (selectedPlano && selectedPlano.aparelhosComplementar) {
      setAparelhosSemPagamento(
        selectedPlano.aparelhosComplementar.filter((apar) => !apar.pagamentos || apar.pagamentos.length === 0)
      )
    }
  }, [selectedPlano])

  const handleSelectAparelho = (aparelho) => {
    setPagamento({
      valorTotal: aparelho.valor,
      dataVencimento: new Date(),
      numeroParcelas: 1,
      planoTratamentoOrtodontiaAparelhoComplementarId: aparelho.id,
      parcelas: [
        {
          valor: aparelho.valor,
          numero: 1,
          dataVencimento: new Date(),
          status: 0,
        },
      ],
    })
    wizardRef.nextStep()
  }

  const handleRecusar = async (aparelho) => {
    await OrtodontiaService.recusarAparelho(aparelho.id)
    firstStep()
    refreshPlanosTratamentoOrtodontiaList()
  }

  const handleSalvar = async () => {
    setIsLoading(true)
    try {
      await OrtodontiaService.salvarPagamentoAparelho({ ...pagamento, valorTotal: +pagamento.valorTotal })
      wizardRef.nextStep()
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleGoBackListaPlanos = () => {
    wizardRef.firstStep()
    firstStep()
    refreshPlanosTratamentoOrtodontiaList()
  }

  return (
    <div>
      <StepWizard isLazyMount instance={setWizardRef}>
        <ListaAparelhos
          aparelhosSemPagamento={aparelhosSemPagamento}
          handleSelectAparelho={handleSelectAparelho}
          handleRecusar={handleRecusar}
          handleGoBackListaPlanos={handleGoBackListaPlanos}
        />
        <Parcelamento pagamento={pagamento} setPagamento={setPagamento} handleSalvar={handleSalvar} />
        <Impressao
          handleGoBackListaPlanos={handleGoBackListaPlanos}
          selectedPlano={selectedPlano}
          pagamento={pagamento}
        />
      </StepWizard>
    </div>
  )
}

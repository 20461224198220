import AxiosClient from './axios-client'

class FranquiasService {
  getFranquia(id) {
    return AxiosClient.get(`Franquia/getById/${id}`)
  }

  listarFranquias() {
    return AxiosClient.get('Franquia/getAll')
  }

  salvarFranquia(data, file) {
    const formData = new FormData()
    formData.append('Franquia', JSON.stringify(data))
    formData.append('Contrato', file)

    return AxiosClient.post('Franquia/salvar', formData)
  }

  async ativar(id, ativar) {
    return await AxiosClient.put(`Franquia/ativar/${id}/${ativar}`)
  }

  consultarCEP(cep) {
    return AxiosClient.post('Pacientes/consultarCEP', cep)
  }

  listarFranquiasAtivas(cep) {
    return AxiosClient.get('Common/getFranquiasAtivas')
  }

  getFranquias(){
    return AxiosClient.get('Franquia/getAllByUser');
  }
}

export default new FranquiasService()

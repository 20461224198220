import React from 'react'

export default function CustomLegenda(props) {
  const { payload } = props
  return (
    <div className='d-flex align-items-center justify-content-center'>
      {payload.map((item) => (
        <div className='mx-auto d-flex align-items-center'>
          <div className='border mr-1' style={{ ...squareStyle, backgroundColor: item.color }} />
          {item.payload.label}
        </div>
      ))}
    </div>
  )
}

const squareStyle = {
  width: '15px',
  height: '15px',
}

import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Form, Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
import formatCnpj from '@brazilian-utils/format-cnpj'

import PermissoesConstants from '../../constants/permissoes.constants'
import { statusProtetico } from '../../constants/common.constants'
import ProteticoService from '../../services/protetico.service'
import MenuContext from '../../contexts/MenuContexts'
import PageLoading from '../Common/PageLoading'

const ProteticosList = (props) => {
  let acoesPermitidas = {}
  const [isLoading, setIsLoading] = useState(true)
  const [proteticosList, setProteticosList] = useState([])
  const [filteredProteticos, setFilteredProteticos] = useState([])

  useEffect(() => {
    getProteticosList()
  }, [])

  const getProteticosList = async () => {
    try {
      const { data } = await ProteticoService.getAllProteticos()
      setProteticosList(data)
      setFilteredProteticos(data)
    } catch (e) {}
    setIsLoading(false)
  }

  const handleCadastrarProtetico = () => {
    props.history.push('/protetico/cadastrar')
  }

  const handleAcompanhamento = () => {
    props.history.push('/protetico/acompanhar')
  }

  const togglePermitirAlterarPreco = async (event, protetico) => {
    setIsLoading(true)
    try {
      await ProteticoService.togglePermitirAlterarPreco(protetico.id, event.target.checked)
      await getProteticosList()
    } catch (e) {
      setIsLoading(false)
      console.error(e)
    }
  }

  const handleDisable = async (proteticoId, currentStatus) => {
    const newActiveState = currentStatus !== statusProtetico.ATIVO.value
    setIsLoading(true)
    try {
      await ProteticoService.toggleAtivarInativar(proteticoId, newActiveState)
      await getProteticosList()
    } catch (e) {
      setIsLoading(false)
      console.error(e)
    }
  }

  const filterProteticos = (e) => {
    const { value } = e.target
    if (value) {
      setFilteredProteticos(proteticosList.filter((protetico) => protetico.nome.includes(value)))
    } else {
      setFilteredProteticos(proteticosList)
    }
  }

  const renderLoading = () => <PageLoading />

  const renderLista = (acoesPermitidas) => (
    <Fragment>
      {filteredProteticos.length > 0 ? (
          <Table bordered hover className='bg-white'>
            <thead>
              <tr>
                <th>Razão Social</th>
                <th>Nome Fantasia</th>
                <th className='text-center' width='160px'>
                  CNPJ
                </th>
                {acoesPermitidas[PermissoesConstants.PERMITIR_ALTERAR_PRECO] && (
                  <th className='text-center' width='160px'>
                    Pode Alterar Preço
                  </th>
                )}
                {acoesPermitidas[PermissoesConstants.ATIVAR_INATIVAR] && (
                  <th className='text-center' width='100px'>
                    Ações
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {filteredProteticos.map((protetico, index) => (
                <tr key={index}>
                  <td>{protetico.razaoSocial}</td>
                  <td>{protetico.nomeFantasia || '-'}</td>
                  <td className='text-center'>{formatCnpj(protetico.cnpj)}</td>
                  {acoesPermitidas[PermissoesConstants.PERMITIR_ALTERAR_PRECO] && (
                    <td className='text-center'>
                      <label className='checkbox labeless m-0'>
                        <input
                          type='checkbox'
                          defaultChecked={protetico.proteticoFranquias[0].permitirAlterarPreco}
                          onChange={(event) => togglePermitirAlterarPreco(event, protetico)}
                        />
                        <span />
                      </label>
                    </td>
                  )}
                  {acoesPermitidas[PermissoesConstants.ATIVAR_INATIVAR] && (
                    <td className='text-center d-flex justify-content-around'>
                      <OverlayTrigger
                        trigger='hover'
                        placement='top'
                        overlay={
                          <Tooltip>
                            {protetico.status === statusProtetico.ATIVO.value
                              ? 'Desativar protético'
                              : 'Ativar protético'}
                          </Tooltip>
                        }
                      >
                        <Button
                          onClick={() => handleDisable(protetico.id, protetico.status)}
                          disabled={protetico.status === statusProtetico.PENDENTE.value}
                          variant={protetico.status === statusProtetico.ATIVO.value ? 'success' : 'danger'}
                          size='sm'
                        >
                          <FontAwesomeIcon icon={faPowerOff} />
                        </Button>
                      </OverlayTrigger>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
      ) : (
        <div>Nenhum protético encontrado.</div>
      )}
    </Fragment>
  )

  return (
    <MenuContext.Consumer>
      {(acoes) => {
        acoes.map((a) => {
          acoesPermitidas[`${a.nome}`] = a
          return true
        })
        return (
          <div>
            <Row className='mb-4'>
              <Col>
                <h1 className='h3 m-0 text-secondary font-weight-bold'>Protéticos</h1>
              </Col>
              <Col>
                <Form.Control type='text' onChange={filterProteticos} placeholder='Filtrar por nome' />
              </Col>
              <Col>
                <div className='d-flex align-items-center justify-content-end'>
                  {acoesPermitidas[PermissoesConstants.ADICIONAR] && (
                    <Button variant='primary' onClick={handleCadastrarProtetico}>
                      Cadastrar Protético
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
            {isLoading ? renderLoading() : renderLista(acoesPermitidas)}
          </div>
        )
      }}
    </MenuContext.Consumer>
  )
}

export default ProteticosList

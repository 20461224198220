import AxiosClient from './axios-client'

class ProcedimentosService {
  getProcedimentos() {
    return AxiosClient.get('Procedimento/getAll')
  }

  getProcedimentoById(id) {
    return AxiosClient.get(`Procedimento/getById/${id}`)
  }

  getProcedimentosByEspecialidade(especialidadeId) {
    return AxiosClient.get(`Procedimento/getByEspecialidadeId/${especialidadeId}`)
  }

  async ativarProcedimento(procedimentoId, ativar) {
    return await AxiosClient.put(`Procedimento/ativar/${procedimentoId}/${ativar}`)
  }

  async ativarProcedimentoProtetico(procedimentoId, ativar) {
    return await AxiosClient.put(`ProcedimentoProtetico/ativar/${procedimentoId}/${ativar}`)
  }

  getProcedimentosProteticos(getSomenteAtivos = false) {
    let endpoint = 'ProcedimentoProtetico/getAll'
    if (getSomenteAtivos) {
      endpoint += `?somenteAtivos=${getSomenteAtivos}`
    }
    return AxiosClient.get(endpoint)
  }

  salvarProcedimento(procedimento) {
    return AxiosClient.post('Procedimento/salvar', procedimento)
  }

  salvarProcedimentoProtetico(procedimento) {
    return AxiosClient.post('ProcedimentoProtetico/salvar', procedimento)
  }
}

export default new ProcedimentosService()

import React, { useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faPrint } from '@fortawesome/free-solid-svg-icons'
import Moment from 'moment'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { status, statusProcedimento } from '../../../constants/common.constants'
import { TIPOS_TEMPLATE } from '../../../constants/template.constants'
import { printParcelasReceipt } from '../../Templates/PrintableReceipt'
import ortodontiaService from '../../../services/ortodontia.service'
import SystemConstants from '../../../constants/system.constants'
import { cargoConstant } from '../../../constants/common.constants'


export default function SelecaoPlanos({ planosTratamentoList, isLoading, onChangeStep, passos }) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [selectedMenu, setSelectedMenu] = useState(null)
  const [menuPrintAnchorEl, setPrintMenuAnchorEl] = useState(null)
  const [selectedPrintMenu, setSelectedPrintMenu] = useState(null)

  const user = JSON.parse(localStorage.getItem(SystemConstants.getUser()))
  const isAdmin = user.podeAlternarConta

  const handleStepChange = (plano, step) => {
    onChangeStep(plano, step)
    handleCloseMenu()
  }

  const handleOpenMenu = (event, index) => {
    setMenuAnchorEl(event.target)
    setSelectedMenu(index)
  }

  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
    setSelectedMenu(null)
  }

  const handleOpenPrintMenu = (event, index) => {
    setPrintMenuAnchorEl(event.target)
    setSelectedPrintMenu(index)
  }

  const handlePrintClick = async (planoTratamentoId, tipoTemplate) => {
    handleClosePrintMenu()
    await ortodontiaService.downloadTemplateAsPDF(planoTratamentoId, tipoTemplate)
  }

  const handleClosePrintMenu = () => {
    setPrintMenuAnchorEl(null)
    setSelectedPrintMenu(null)
  }

  const handlePrintParcelamento = async (plano) => {
    const receipt = {
      numeroPlano: plano.numeroPlano,
      nomeContratante: plano.paciente.nome,
      nomePaciente: plano.dependente ? plano.dependente.nome : plano.paciente.nome,
    }
    const pagamentoPlano = plano.pagamentos[plano.pagamentos.length - 1]
    printParcelasReceipt({
      ...receipt,
      parcelas: pagamentoPlano.parcelas,
      valorTotal: pagamentoPlano.valorTotal,
    })
    const pagamentoAparelho = plano.pagamentosAparelho[plano.pagamentosAparelho.length - 1]
    if (pagamentoAparelho) {
      printParcelasReceipt({
        ...receipt,
        parcelas: pagamentoAparelho.parcelas,
        valorTotal: pagamentoAparelho.valorTotal,
        type: 'aparelho',
      })
    }
    const pagSuprimentos = plano.suprimentos
      .filter((sup) => sup.pagamentos.length > 0)
      .map((sup) => sup.pagamentos[sup.pagamentos.length - 1])
    const pagComplementares = plano.aparelhosComplementar
      .filter((compl) => compl.pagamentos.length > 0)
      .map((compl) => compl.pagamentos[compl.pagamentos.length - 1])
    pagSuprimentos.forEach((pagamento) => {
      printParcelasReceipt({
        ...receipt,
        parcelas: pagamento.parcelas,
        valorTotal: pagamento.valorTotal,
        type: 'suprimento',
      })
    })
    pagComplementares.forEach((pagamento) => {
      printParcelasReceipt({
        ...receipt,
        parcelas: pagamento.parcelas,
        valorTotal: pagamento.valorTotal,
        type: 'aparelho',
      })
    })
  }

  return (
    <div>
      {planosTratamentoList.length > 0 ? (
        <Table bordered hover style={{ backgroundColor: 'white' }}>
          <thead>
            <tr className='text-center'>
              <th>Número do Plano</th>
              <th>Paciente</th>
              <th>Iniciado em</th>
              <th>Status</th>
              <th>Data de finalização</th>
              <th width='100px'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {planosTratamentoList.map((plano, index) => {
              const statusPlano = status.find((s) => s.value === plano.statusTratamento)
              const temSupSemPag = plano.suprimentos.some((sup) => !sup.pagamentos || sup.pagamentos.length === 0)
              const temApaSemPag = plano.aparelhosComplementar.some(
                (apar) => !apar.pagamentos || apar.pagamentos.length === 0
              )
              return (
                <tr
                  key={plano.id}
                  className={temSupSemPag || temApaSemPag ? 'bg-warning-03 text-center' : 'text-center'}
                >
                  <td>{plano.numeroPlano}{plano.comParceiroFinanceiro && <><br /><span className='badge badge-danger'>Com Parceiro</span></>}</td>
                  <td>{plano.dependente ? plano.dependente.nome : plano.paciente.nome}</td>
                  <td>{Moment(plano.dataCriacao).format('DD/MM/YYYY')}</td>
                  <td>{statusPlano ? statusPlano.label : '-'}</td>
                  {plano.dataFinalizacao ? (
                    <td>
                      <p className='m-0'>{Moment(plano.dataFinalizacao).format('DD/MM/YYYY')}</p>
                      <p className='m-0'>{Moment(plano.dataFinalizacao).format('HH:mm')}</p>
                    </td>
                  ) : (
                    <td>-</td>
                  )}
                  <td>
                    <div className='justify-content-between'>
                      <Button variant='primary' size='sm' onClick={(event) => handleOpenMenu(event, index)} style={{ width: '32px', height: '32px', marginRight: '5px' }}>
                        <FontAwesomeIcon icon={faDollarSign} />
                      </Button>
                      <Menu anchorEl={menuAnchorEl} open={selectedMenu === index} onClose={handleCloseMenu} keepMounted>
                        {temApaSemPag && (
                          <MenuItem onClick={() => handleStepChange(plano, passos.APARELHOS)}>
                            Aparelhos Complementares
                          </MenuItem>
                        )}
                        {temSupSemPag && (
                          <MenuItem onClick={() => handleStepChange(plano, passos.SUPRIMENTOS)}>
                            Suprimentos de Manutenção
                          </MenuItem>
                        )}

                        {isAdmin &&
                        <MenuItem onClick={() => handleStepChange(plano, passos.ALTERAR_VENCIMENTO)}>
                          Alterar Vencimento
                        </MenuItem>
                        }
                        
                        <MenuItem onClick={() => handleStepChange(plano, passos.PAGAMENTO)}>
                          Realizar Pagamento
                        </MenuItem>
                        <MenuItem onClick={() => handleStepChange(plano, passos.VISUALIZAR_PARC)}>
                          Visualizar Parcelas
                        </MenuItem>
                        <MenuItem onClick={() => handleStepChange(plano, passos.VISUALIZAR_HISTORICO)}>
                          Visualizar Histórico
                        </MenuItem>

                        {plano.podeRenegociar &&
                          <MenuItem onClick={() => handleStepChange(plano, passos.RENEGOCIACAO)}>Renegociar</MenuItem>
                        }

                        <MenuItem onClick={() => handleStepChange(plano, passos.CANCELAMENTO)}>Cancelar Plano</MenuItem>
                        {isAdmin &&
                          <MenuItem onClick={() => handleStepChange(plano, passos.CANCELAMENTO_PAGAMENTO)}>
                            Cancelar Pagamento
                          </MenuItem>
                        }
                      </Menu>
                      <Button onClick={(event) => handleOpenPrintMenu(event, index)} variant='primary' size='sm' style={{ width: '32px', height: '32px', marginRight: '5px' }}>
                        <FontAwesomeIcon icon={faPrint} />
                      </Button>
                      <Menu
                        anchorEl={menuPrintAnchorEl}
                        open={selectedPrintMenu === index}
                        onClose={handleClosePrintMenu}
                        keepMounted
                      >
                        <MenuItem onClick={() => handlePrintParcelamento(plano)}>Parcelamento</MenuItem>
                        <MenuItem onClick={() => handlePrintClick(plano.id, TIPOS_TEMPLATE.Contrato)}>
                          Contrato de Prestação de Serviços
                        </MenuItem>
                        <MenuItem
                          onClick={() => handlePrintClick(plano.id, TIPOS_TEMPLATE.TermoDeConsentimentoDeTratamento)}
                        >
                          Termo de Consentimento de Tratamento
                        </MenuItem>
                        {plano.statusTratamento === statusProcedimento.FINALIZADO.value && (
                          <MenuItem
                            onClick={() => handlePrintClick(plano.id, TIPOS_TEMPLATE.TermoDeConclusaoDeOrtodontia)}
                          >
                            Termo de Conclusão de Tratamento
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() =>
                            handlePrintClick(plano.id, TIPOS_TEMPLATE.TermoDeCancelamentoDeProcedimentoComPendencia)
                          }
                        >
                          Termo de Cancelamento com Confissão de Dívida
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handlePrintClick(plano.id, TIPOS_TEMPLATE.TermoDeCancelamentoDeOrtodontiaComQuitacao)
                          }
                        >
                          Termo de Cancelamento com Quitação
                        </MenuItem>
                        <MenuItem onClick={() => handlePrintClick(plano.id, TIPOS_TEMPLATE.TermoDeRenegociacao)}>
                          Termo de Renegociação
                        </MenuItem>
                        <MenuItem onClick={() => handlePrintClick(plano.id, TIPOS_TEMPLATE.RespostasAnamnese)}>
                          Anamnese
                        </MenuItem>
                      </Menu>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        !isLoading && <div>Nenhum plano encontrado para este paciente.</div>
      )}
    </div>
  )
}

import React, { Fragment, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import Financeiro from './Financeiro'
import CollapsePanel from '../../../Common/CollapsePanel'

export default function CollapseList({
  nextStep,
  handleGoBack,
  setIsLoading,
  parcelamentoPagamento,
  setPagamento,
  selectedPlano,
  setType,
}) {
  const INITIAL_OPEN_STATE = { step0: false }
  const [stepIsOpen, setStepIsOpen] = useState({ ...INITIAL_OPEN_STATE })

  const resetInitialState = () => {
    setStepIsOpen({ ...INITIAL_OPEN_STATE })
  }

  const panels = !parcelamentoPagamento
    ? []
    : [
        ...Object.values(parcelamentoPagamento.plano),
        ...Object.values(parcelamentoPagamento.aparelho),
        ...Object.values(parcelamentoPagamento.complementar),
        ...Object.values(parcelamentoPagamento.suprimentos),
      ]

  const toggleIsOpen = (step) => {
    let newSteps = { ...INITIAL_OPEN_STATE }
    Object.keys(panels).forEach((_, i) => {
      newSteps[`step${i}`] = false
    })
    setStepIsOpen({ ...newSteps, [step]: !stepIsOpen[step] })
  }

  const handleBack = () => {
    resetInitialState()
    handleGoBack()
  }

  const renderPanels = () => (
    <Fragment>
      {panels.map((parcelas, index) => {
        const { nomeAparelho, nomeAparelhoComplementar, descricaoSuprimento } = parcelas[0]
        const text = nomeAparelho || nomeAparelhoComplementar || descricaoSuprimento || 'Plano Ortodôntico'
        const customSetPagamento = (pagamento) => {
          setPagamento(pagamento)
          setType(nomeAparelho || nomeAparelhoComplementar ? 'aparelho' : descricaoSuprimento ? 'suprimento' : 'plano')
        }
        return (
          <CollapsePanel
            key={index}
            stepCode={`step${index}`}
            isOpen={stepIsOpen[`step${index}`]}
            toggleIsOpen={toggleIsOpen}
            text={text}
          >
            <Financeiro
              nextStep={nextStep}
              setPagamento={customSetPagamento}
              setIsLoading={setIsLoading}
              parcelasEmAberto={parcelas}
              selectedPlano={selectedPlano}
              resetInitialState={resetInitialState}
            />
          </CollapsePanel>
        )
      })}
    </Fragment>
  )

  return (
    <Fragment>
      <h3 className='my-4 text-secondary font-weight-bold'>Renegociar Dívida</h3>
      {panels.length > 0 ? renderPanels() : <p>Nenhuma parcela em aberto.</p>}
      <div style={backContainerStyle} onClick={handleBack}>
        <Button variant='outline-primary' style={backStyle}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <span className='text-primary'>Voltar</span>
      </div>
    </Fragment>
  )
}

const backContainerStyle = {
  cursor: 'pointer',
  width: 'fit-content',
  marginTop: '2em',
}

const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

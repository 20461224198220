export const LISTAR_PERFIS = 'LISTAR_PERFIS';
export const LISTAR_MENU = 'LISTAR_MENU';
export const LISTAR_FUNCIONALIDADES = 'LISTAR_FUNCIONALIDADES';
export const LISTAR_ACOES = 'LISTAR_ACOES';

export const REQUEST_PERFIS = 'REQUEST_PERFIS';
export const REQUEST_MENU = 'REQUEST_MENU';
export const REQUEST_FUNCIONALIDADES = 'REQUEST_FUNCIONALIDADES';
export const REQUEST_ACOES = 'REQUEST_ACOES';

export const EDITAR_PERFIL = 'EDITAR_PERFIL';
export const SALVAR_PERFIL = 'SALVAR_PERFIL';

export const GET_PERFIL_ID = 'GET_PERFIL_ID';
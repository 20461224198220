import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetTemplate } from '../../services/actions/templates.actions'

import TemplateEdit from '../../components/Templates/TemplateEdit'

const mapStateToProps = (store) => ({
	selectedTemplate: store.templatesState.selectedTemplate,
})

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ resetTemplate }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TemplateEdit)

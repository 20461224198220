import AxiosClient from './axios-client';

class ParametrosService {

    /**
     * Busca todos os parametros existentes
     */
    getAll() {
        return AxiosClient.get('Parametro/getAll');
    }

    /**
     * Usado para atualizar o valor do parametro
     */
    async update(parametro) {
        return await AxiosClient.put('Parametro/update', {...parametro});
    }
}

export default new ParametrosService();
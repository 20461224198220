import React, { useEffect, useState, Fragment } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { Table, Row, Col, Form, Button, Card } from 'react-bootstrap'
import { format } from 'date-fns'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { cargoConstant } from '../../../constants/common.constants'
import FinanceiroService from '../../../services/financeiro.service'
import FranquiaService from '../../../services/franquias.service'
import { formatMoney } from '../../../utils/string.utils'
import Select from 'react-select'
import Paginator from '../../../components/Common/Paginator'
import colaboradoresService from '../../../services/colaboradores.service'
import PageLoading from '../../../components/Common/PageLoading'
import { withRouter } from 'react-router-dom'
import { formasDePagamento } from '../../../constants/common.constants'
import SystemConstants from '../../../constants/system.constants'

const parcelasEmAbertoOptions = [
  { value: 0, label: 'Em aberto' },
  { value: 1, label: 'Paga' },
  { value: 2, label: 'Ambas' },
]

const getCurrentMenu = (caminho, menus) => {
  let currentMenu

  menus.forEach((menu) => {
    if (menu.caminho === caminho) {
      currentMenu = menu
      return
    }

    menu.subMenus.forEach((subMenu) => {
      if (subMenu.caminho === caminho) {
        currentMenu = subMenu
        return
      }
    })
  })

  return currentMenu
}

function FechamentoCaixa({ menus, location }) {
  const porPagina = 18
  const dateFilterDeMinPagamento = new Date().setMonth(new Date().getMonth() - 3)
  const [itensLista, setItensLista] = useState([])
  const [itensListaPrint, setItensListaPrint] = useState([])
  const [dateFilterDePagamento, setDateFilterDePagamento] = useState(null)
  const [dateFilterAtePagamento, setDateFilterAtePagamento] = useState(null)
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [pagina, setPagina] = useState(0)
  const [filterColaborador, setFilterColaborador] = useState({label: 'Todas', value: null})
  const [colaboradoresOptions, setColaboradoresOptions] = useState([])
  const [tipoFechamentoOptions, setTipoFechamentoOptions] = useState([])
  const [selectedTipoFechamento, setSelectedFechamento] = useState()
  const [especialidadesOptions, setEspecialidadesOptions] = useState([])
  const [selectedEspecialidade, setSelectedEspecialidade] = useState()
  const [formaPagamentoOptions, setFormasPagamentoOptions] = useState([])
  const [selectedFormaPagamento, setSelectedFormaPagamento] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [resumo, setResumo] = useState([])
  const [resumoPrint, setResumoPrint] = useState([])
  const [franquia, setFranquia] = useState([])
  const [isAdministrador, setIsAdministrador] = useState(false)
  const [colaboradorIdLogado, setColaboradorIdLogado] = useState('')
  const [usuarioLogado, setUsuarioLogado] = useState('')

  useEffect(() => {
    didMount()
  }, [])

  useEffect(() => {
    getResumo()
  }, [
    pagina
  ])

  useEffect(() => {
    if (especialidadesOptions && especialidadesOptions.length > 0) {
      setSelectedEspecialidade(especialidadesOptions[0])
    }

    if (tipoFechamentoOptions && tipoFechamentoOptions.length > 0) {
      setSelectedFechamento(tipoFechamentoOptions[0])
    }

    if (formaPagamentoOptions && formaPagamentoOptions.length > 0) {
      setSelectedFormaPagamento(formaPagamentoOptions[0])
    }

    setDateFilterDePagamento(new Date())
    setDateFilterAtePagamento(new Date())

  }, [especialidadesOptions, tipoFechamentoOptions, colaboradoresOptions, formaPagamentoOptions])

  const didMount = async () => {
    setIsLoading(true)
    await Promise.all([getRecepcionistas()])
    await Promise.all([getTipoFechamento()])
    await Promise.all([getEspecialidade()])
    await Promise.all([getFormaPagamento()])
    await Promise.all([getFranquia()])

    setIsLoading(false)
  }
  const getFormaPagamento = async () => {
    try {
      setFormasPagamentoOptions([
        { label: 'Todas', value: null },
        ...Object.values(formasDePagamento).map((formaPagamento) => ({
          label: formaPagamento.label,
          value: formaPagamento.value
        })),
      ])
    } catch (e) {
      console.error(e)
    }
  }

  const getEspecialidade = async () => {
    try {
      const { data } = await colaboradoresService.getAllEspecialidades()
      setEspecialidadesOptions([
        { label: 'Todas', value: null },
        ...data.map((especialidade) => ({
          label: especialidade.nome,
          value: especialidade.id,
        })),
      ])

      
    } catch (e) {
      console.error(e)
    }
  }

  const getFranquia = async () => {
    const franquiaId = localStorage.getItem(SystemConstants.getFranquia())
    setUsuarioLogado(JSON.parse(localStorage.getItem(SystemConstants.getUser())).name)
    try {
      const { data } = await FranquiaService.getFranquia(franquiaId)
      setFranquia(data)
    } catch (error) {}
  }

  const getTipoFechamento = async () => {
    try {
      setTipoFechamentoOptions([
        { label: 'Clínica', value: 0 },
        { label: 'Protético', value: 1 }
      ])
    } catch (e) {
      console.error(e)
    }
  }

  const getRecepcionistas = async () => {
    try {
      const { data } = await colaboradoresService.getAllRecepcionistas()
      let options  = ([
        { label: 'Todos', value: null },
        ...data.map((colaborador) => ({
          label: colaborador.nome,
          value: colaborador.id,
        })),
      ])

      setColaboradoresOptions(options)
      let user = JSON.parse(localStorage.getItem(SystemConstants.getUser()))

      let isAdmin = (user.tipoCargo == cargoConstant.ADMINISTRADOR.value)
      setIsAdministrador(isAdmin)
      let userId = user.colaboradorId
      setColaboradorIdLogado({ value: userId, label: user.name })

      if(!isAdmin)
        setFilterColaborador(options.find(x => x.value == userId))
      
    } catch (e) {
      console.error(e)
    }
  }

  const handlePagina = (data) => setPagina(data.selected)

  const limparFiltro = () => {
    setDateFilterAtePagamento(new Date())
    setDateFilterDePagamento(new Date())
    if(isAdministrador)
      setFilterColaborador(colaboradoresOptions[0])
    else
      setFilterColaborador(colaboradorIdLogado)

    setSelectedFormaPagamento(formaPagamentoOptions[0])
    setSelectedEspecialidade(especialidadesOptions[0])
    setSelectedFechamento(tipoFechamentoOptions[0])
  }

  const getResumo = async () => {
    setIsLoading(true)

    let filtros = [
      {
        campo: 'dataPagamentoInicial',
        valor: dateFilterDePagamento ? dateFilterDePagamento : new Date(),
      },
      {
        campo: 'dataPagamentoFinal',
        valor: dateFilterAtePagamento ? dateFilterAtePagamento :  new Date(),
      }
    ]
    if (selectedTipoFechamento) {
      filtros.push({
        campo: 'isProtetico',
        valor: selectedTipoFechamento.value,
      })
    }

    if (filterColaborador) {
      filtros.push({
        campo: 'colaboradorId',
        valor: filterColaborador.value,
      })
    }

    if (selectedEspecialidade) {
      filtros.push({
        campo: 'especialidadeId',
        valor: selectedEspecialidade.value,
      })
    }

    if (selectedFormaPagamento) {
      filtros.push({
        campo: 'formaPagamento',
        valor: selectedFormaPagamento.value,
      })
    }

    const { lista, resumo } = await FinanceiroService.getFechamentoCaixa(filtros, pagina + 1, porPagina)
    setResumo(resumo)

    if(lista){
      setItensLista(lista.dados)
      setTotalPaginas(lista.totalPaginas)
    }

    getListaPrint(filtros)

    setIsLoading(false)
  }

  const getListaPrint = async (filtros) => {
    const { lista, resumo } = await FinanceiroService.getFechamentoCaixa(filtros, 0, 0)
    setResumoPrint(resumo)
    
    if(lista)
      setItensListaPrint(lista.dados)
  }

  const handleDateFilterDePagamento = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterDePagamento(filter)
      setPagina(0)
    } else {
      setDateFilterDePagamento(null)
      setPagina(0)
    }
  }

  const handleDateFilterAtePagamento = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterAtePagamento(filter)
      setPagina(0)
    } else {
      setDateFilterAtePagamento(null)
      setPagina(0)
    }
  }

  const handleFilterColaborador = (colaboradorId) => {
    if (colaboradorId) {
      setFilterColaborador(colaboradorId)
      setPagina(0)
    } else {
      setFilterColaborador(null)
      setPagina(0)
    }
  }

  const handleFilterTipoFechamento = (tipoFechamento) => {
    if (tipoFechamento) {
      setSelectedFechamento(tipoFechamento)
      setPagina(0)
    } else {
      setSelectedFechamento(null)
      setPagina(0)
    }
  }

  const handleFilterEspecialidade = (especialidadeId) => {
    if (especialidadeId) {
      setSelectedEspecialidade(especialidadeId)
      setPagina(0)
    } else {
      setSelectedEspecialidade(null)
      setPagina(0)
    }
  }

  const handleFilterFormaPagamento = (formaPagamento) => {
    if (formaPagamento) {
      setSelectedFormaPagamento(formaPagamento)
      setPagina(0)
    } else {
      setSelectedFormaPagamento(null)
      setPagina(0)
    }
  }

  const handlePrint = async (e) => {
    let filtros = [
      {
        campo: 'dataPagamentoInicial',
        valor: dateFilterDePagamento ? dateFilterDePagamento : new Date(),
      },
      {
        campo: 'dataPagamentoFinal',
        valor: dateFilterAtePagamento ? dateFilterAtePagamento :  new Date(),
      }
    ]
    if (selectedTipoFechamento) {
      filtros.push({
        campo: 'isProtetico',
        valor: selectedTipoFechamento.value,
      })
    }

    if (filterColaborador) {
      filtros.push({
        campo: 'colaboradorId',
        valor: filterColaborador.value,
      })
    }

    if (selectedEspecialidade) {
      filtros.push({
        campo: 'especialidadeId',
        valor: selectedEspecialidade.value,
      })
    }

    if (selectedFormaPagamento) {
      filtros.push({
        campo: 'formaPagamento',
        valor: selectedFormaPagamento.value,
      })
    }

    let suaString = `<!DOCTYPE html><html>
    <head>
    <style type="text/css" media="print">
      @page { size: landscape; filename: teste}
      
    </style>
    </head>
    <body>${renderPrint()}</body></html>`
    
    var wnd = window.open("Fechamento de Caixa");
    wnd.document.write(suaString);
    wnd.print()
    wnd.document.title = "Fechamento de Caixa.pdf";
  }
  
  const renderLista = () => {
    return (
      <Fragment>
        <h1 className='h3 m-0 text-secondary font-weight-bold'>
          Fechamento de Caixa
        </h1>
        <Row className='my-4'>
          <Col md='3' className='pr-2'>
            <Form.Group>
              <Form.Label htmlFor='dataPagamento'>Data de pagamento de:</Form.Label>
              <div className='d-flex justify-content-between align-items-center'>
                
                <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                  <KeyboardDatePicker
                    invalidDateMessage='Formato de data inválido'
                    id='dataPagamento'
                    name='dataPagamento'
                    value={dateFilterDePagamento}
                    onChange={handleDateFilterDePagamento}
                    className='form-control'
                    variant='inline'
                    format='DD/MM/YYYY'
                    minDate={dateFilterDeMinPagamento}
                    maxDate={dateFilterAtePagamento || new Date('2100-01-01')}
                    animateYearScrolling
                    autoOk
                    placeholder='De'
                    minDateMessage={'A data deve ser a partir de ' + format(new Date(dateFilterDeMinPagamento), 'dd/MM/yyyy')}
                    maxDateMessage={'A data deve ser menor que a "Data de pagamento até"' }
                  />
                </MuiPickersUtilsProvider>

              </div>
            </Form.Group>
          </Col>
          <Col md='3' className='pr-2'>
            <Form.Group>
              <Form.Label htmlFor='dataPagamento'>Data de pagamento até:</Form.Label>
              <div>
                <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                  <KeyboardDatePicker
                    invalidDateMessage='Formato de data inválido'
                    value={dateFilterAtePagamento}
                    onChange={handleDateFilterAtePagamento}
                    minDate={dateFilterDePagamento || new Date('1900-01-01')}
                    className='form-control mx-1'
                    variant='inline'
                    format='DD/MM/YYYY'
                    animateYearScrolling
                    autoOk
                    placeholder='Até'
                    minDateMessage={'A data deve ser maior que a "Data de pagamento de"'}
                  
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Form.Group>
          </Col>
        </Row>
        
        <Row className='mb-4'>
          <Col md='2'>
            <Form.Group>
              <Form.Label htmlFor='dataPagamento'>Tipo de fechamento:</Form.Label>
              <Select
                placeholder='Filtrar por Tipo de Fechamento'
                value={selectedTipoFechamento}
                options={tipoFechamentoOptions}
                onChange={handleFilterTipoFechamento}
                noOptionsMessage={() => 'Nenhuma opção disponível'}
              />
            </Form.Group>
          </Col>
          <Col md='2'>
            <Form.Group>
              <Form.Label htmlFor='dataPagamento'>Especialidade:</Form.Label>
              <Select
                placeholder='Filtrar por Especialidade'
                value={selectedEspecialidade}
                options={especialidadesOptions}
                onChange={handleFilterEspecialidade}
                noOptionsMessage={() => 'Nenhuma opção disponível'}
              />
            </Form.Group>
          </Col>
          {isAdministrador &&
          <Col md='2'>
            <Form.Group>
              <Form.Label htmlFor='dataPagamento'>Atendente:</Form.Label>
              <Select
                placeholder='Filtrar por Colaborador'
                value={filterColaborador}
                options={colaboradoresOptions}
                onChange={handleFilterColaborador}
                noOptionsMessage={() => 'Nenhuma opção disponível'}
              />
            </Form.Group>
          </Col>
          }
          <Col md='2'>
            <Form.Group>
              <Form.Label htmlFor='dataPagamento'>Forma de pagamento:</Form.Label>
              <Select
                placeholder='Filtrar por Colaborador'
                value={selectedFormaPagamento}
                options={formaPagamentoOptions}
                onChange={handleFilterFormaPagamento}
                noOptionsMessage={() => 'Nenhuma opção disponível'}
              />
            </Form.Group>
          </Col>
          <Col md='4' className='d-flex justify-content-end align-items-end'>
            <Button onClick={limparFiltro} variant='outline-danger' style={buttonClean} className='mb-3'>
              Limpar
            </Button>
            <Button onClick={getResumo} variant='primary' style={buttonSearch} className='mb-3'>
              Pesquisar
            </Button>
            {(!itensLista || itensLista.length == 0) || (
              <Button onClick={handlePrint} variant='outline-secondary' style={buttonPrint} className='mb-3'>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="print" class="svg-inline--fa fa-print fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M448 192V77.25c0-8.49-3.37-16.62-9.37-22.63L393.37 9.37c-6-6-14.14-9.37-22.63-9.37H96C78.33 0 64 14.33 64 32v160c-35.35 0-64 28.65-64 64v112c0 8.84 7.16 16 16 16h48v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h48c8.84 0 16-7.16 16-16V256c0-35.35-28.65-64-64-64zm-64 256H128v-96h256v96zm0-224H128V64h192v48c0 8.84 7.16 16 16 16h48v96zm48 72c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"></path></svg>
                &nbsp;&nbsp;Imprimir
              </Button>
            )}
          </Col>
        </Row>
        

        {itensLista && itensLista.length > 0 ? (
          <div id='fechamento-caixa'>
            <Card className='mb-4 py-2'>
              <Row>
                {resumo.map(({ descricao, valor }, index) => (
                  <Col key={index} className='d-flex align-items-center justify-content-center flex-column'>
                    <small className='text-center text-muted m-0'>{!!descricao ? descricao.toUpperCase() : '-'}</small>
                    <h4 className='text-center m-0 font-weight-bold'>
                      {!!valor ? formatMoney(valor) : formatMoney(0)}
                    </h4>
                  </Col>
                ))}
              </Row>
            </Card>

            <Table size='sm' hover className='bg-white' style={tableBorder}>
              <thead>
                <tr>
                  <th width='8%' className='text-center'>Nº Plano</th>
                  <th width='22%'>Paciente</th>
                  <th width='22%'>Profissional</th>
                  <th width='12%' className='text-center'>Forma de Pagamento</th>
                  <th width='8%' className='text-center '>Valor</th>
                  <th width='8%' className='text-center'>Parcela</th>
                  <th width='10%' className='text-center'>Vencimento</th>
                  <th width='10%' className='text-right'>Pagamento</th>
                </tr>
              </thead>
              <tbody>
                {itensLista.map((item, i) => {
                  const {
                    nomePaciente,
                    valorCalculado,
                    numeroPlano,
                    parcela,
                    dataVencimento,
                    colaboradorQueRecebeu,
                    formaPagamentoNome,
                    dataPagamento,
                    valorPago,
                  } = item

                  return (
                    <tr  style={{ fontSize: 'smaller', height: '40px' }} key={i}>
                      <td className='text-center font-weight-bold'>{numeroPlano || '-'}</td>
                      <td>{nomePaciente.toUpperCase()}</td>
                      <td>{colaboradorQueRecebeu ? colaboradorQueRecebeu.toUpperCase() : '-'}</td>
                      <td className='text-center'>{formaPagamentoNome ? formaPagamentoNome.toUpperCase() : '-'}</td>
                      <td className='text-center'>{valorPago > 0 ? formatMoney(valorPago) : formatMoney(valorCalculado)}</td>
                      <td className='text-center'>{parcela || '-'}</td>
                      <td className='text-center'>{dataVencimento ? format(new Date(dataVencimento), 'dd/MM/yyyy') : '-'}</td>
                      <td className='text-center'>{dataPagamento ? format(new Date(dataPagamento), 'dd/MM/yyyy HH:mm') : '-'}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div>Nenhuma pagamento recebido até o momento</div>
        )}
        {totalPaginas > 1 && <Paginator handlePagina={handlePagina} pageCount={totalPaginas} paginaAtual={pagina} />}
      </Fragment>
    )
  }

  if (isLoading) {
    return <PageLoading />
  }

  const renderPrint = () => {
    //FILTROS
    let htmlFiltros = `<h3 style="margin:0">Filtros</h3>
      Pagamentos:<b> de ${format(dateFilterDePagamento, 'dd/MM/yyyy')} até ${format(dateFilterAtePagamento, 'dd/MM/yyyy')}</b><br>`

    if(selectedTipoFechamento)
      htmlFiltros += `Tipo de fechamento: <b>${selectedTipoFechamento.label}</b><br>`

    if(selectedEspecialidade.value)
      htmlFiltros += `Especialidade: <b>${selectedEspecialidade.label}</b><br>`

    if(filterColaborador.value)
      htmlFiltros += `Atendente: <b>${filterColaborador.label}</b><br>`

    if(selectedFormaPagamento.value != null)
      htmlFiltros += `Forma de Pagamento: <b>${selectedFormaPagamento.label}</b><br>`

    //RESUMO
    let htmlResumo = ''
    {resumoPrint.map(({ descricao, valor }, index) => (
      htmlResumo += `
        <div style="float:left; width:16.6%; text-align: center">
            <small style="color: #6c757d !important; font-size: 80%; font-weight: 400;">${!!descricao ? descricao.toUpperCase() : '-'}</small>
            <h4 style="font-weight: 700 !important; font-size: 1.3125rem;margin: 0">${!!valor ? formatMoney(valor) : formatMoney(0)}</h4>
        </div>`
    ))}

    //DADOS DA TABELA
    let htmlTabela = ''
    {itensListaPrint.map((item, i) => (
        htmlTabela += `
        <tr style="font-size: smaller; height: 45px; color: #212529; border: 1px solid #dee2e6;">
          <td style="vertical-align: middle; border-bottom: 1px solid #dee2e6; font-weight: 700 !important; text-align: center !important;">${item.numeroPlano || '-'}</td>
          <td style="vertical-align: middle; border-bottom: 1px solid #dee2e6; text-align: center !important;">${item.nomePaciente.toUpperCase()}</td>
          <td style="vertical-align: middle; border-bottom: 1px solid #dee2e6; text-align: center !important;">${item.colaboradorQueRecebeu ? item.colaboradorQueRecebeu.toUpperCase() : '-'}</td>
          <td style="vertical-align: middle; border-bottom: 1px solid #dee2e6; text-align: center !important;">${item.formaPagamentoNome ? item.formaPagamentoNome.toUpperCase() : '-'}</td>
          <td style="vertical-align: middle; border-bottom: 1px solid #dee2e6; text-align: center !important;">${item.valorPago > 0 ? formatMoney(item.valorPago) : formatMoney(item.valorCalculado)}</td>
          <td style="vertical-align: middle; border-bottom: 1px solid #dee2e6; text-align: center !important;">${item.parcela || '-'}</td>
          <td style="vertical-align: middle; border-bottom: 1px solid #dee2e6; text-align: center !important;">${item.dataVencimento ? format(new Date(item.dataVencimento), 'dd/MM/yyyy') : '-'}</td>
          <td style="vertical-align: middle; border-bottom: 1px solid #dee2e6; text-align: center !important;">${item.dataPagamento ? format(new Date(item.dataPagamento), 'dd/MM/yyyy HH:mm') : '-'}</td>
        </tr>`
    ))}

    //FOOTER DA TABELA
    let htmlFooter = `Clínica - ${format(new Date(), 'dd/MM/yyyy HH:mm:ss')}`

    let table = `
  
      <div id="fechamento-caixa" style="width:100%; font-family: 'Roboto', sans-serif; font-size: 0.875rem; font-weight: 400; line-height: 1.5; color: #212529; text-align: left;">
        <div id="cabecalho">
          <h1 style="color: #6c757d">Fechamento de Caixa</h1>
          
          <span style="color: #6c757d !important;padding-top:10px;">${htmlFiltros}</h1>

          <div style="margin-top:30px; width:auto; border: solid 1px black; height: 45px; padding: 0.5rem; word-wrap: break-word; background-color: #fff; background-clip: border-box; border: 1px solid rgba(0, 0, 0, 0.125); border-radius: 3px; margin-bottom:20px">
            ${htmlResumo}
          </div>
        </div>
        <table style="width: 100%; table-layout: fixed; background-color: #fff !important; margin-bottom: 1rem; border-collapse: collapse;border: border: none;">
            <thead>
              <tr style="color: #737373; border: 2px solid #dee2e6;">
                  <th width="8%" style="vertical-align: bottom; padding: 0.3rem;text-align: center; border-bottom: 2px solid #dee2e6;">Nº Plano</th>
                  <th width="22%" style="vertical-align: bottom; padding: 0.3rem;text-align: center; border-bottom: 2px solid #dee2e6;">Paciente</th>
                  <th width="22%" style="vertical-align: bottom; padding: 0.3rem;text-align: center; border-bottom: 2px solid #dee2e6;">Profissional</th>
                  <th width="12%" style="vertical-align: bottom; padding: 0.3rem;text-align: center; border-bottom: 2px solid #dee2e6;">Forma de Pagamento</th>
                  <th width="8%" style="vertical-align: bottom; padding: 0.3rem;text-align: center; border-bottom: 2px solid #dee2e6;">Valor</th>
                  <th width="8%" style="vertical-align: bottom; padding: 0.3rem;text-align: center; border-bottom: 2px solid #dee2e6;">Parcelas</th>
                  <th width="10%" style="vertical-align: bottom; padding: 0.3rem;text-align: center; border-bottom: 2px solid #dee2e6;">Vencimento</th>
                  <th width="10%" style="vertical-align: bottom; padding: 0.3rem;text-align: center; border-bottom: 2px solid #dee2e6;">Pagamento</th>
              </tr>
            </thead>
            <tbody>
              ${htmlTabela}
            </tbody>
            <tfoot>
              <tr>
                <td colspan="8" style="padding-top:20px; text-align:left">
                  <span style="width: 33%; text-align:left; display: inline-block;">${franquia.nomeFantasia}</span>
                  <span style="width: 33%; text-align:center; display: inline-block;">${format(new Date(), 'dd/MM/yyyy HH:mm:ss')}</span>
                  <span style="width: 33%; text-align:right; display: inline-block;">${usuarioLogado}</span>
                </td>
              </tr>
            </tfoot>
        </table>
      </div>`
    
    return table
  }

  return renderLista()
}

const tableBorder = {
  border: '1px solid #dee2e6'
}

const marginRight = {
  'marginRight': '15px'
}

const marginLeft = {
  'marginLeft': '15px',
  'marginRight': '15px',
}

const buttonClean = {
  left: '0',
  position: 'absolute',
  height: '38px'
}

const buttonSearch = {
  left: '80px',
  position: 'absolute',
  height: '38px'
}

const buttonPrint = {
  height: '38px'
}

const mapStateToProps = (store) => {
  return {
    menus: store.perfisState.menus,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FechamentoCaixa))

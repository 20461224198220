import React, { useState, Fragment, useEffect } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import ptBr from 'date-fns/locale/pt-BR'
import { toast } from 'react-toastify'

import PlanoTratamentoService from '../../../../services/planoTratamento.service'
import CollapsePanel from '../../../Common/CollapsePanel'
import Parcelas from './Parcelas'

registerLocale('pt-BR', ptBr)

export default function AlterarVencimento({
  previousStep,
  planoTratamento,
  isLoading,
  setIsLoading,
  currentStep,
  parcelasOriginais,
  parcelasProteticasOriginais,
}) {
  const INITIAL_OPEN_STATE = { step0: true }

  const [stepIsOpen, setStepIsOpen] = useState({ ...INITIAL_OPEN_STATE })
  const [parcelasAgrupadas, setParcelasAgrupadas] = useState({})
  const [vencimentoAgrupado, setVencimentoAgrupado] = useState({})

  useEffect(() => {
    currentStep === 2 && planoTratamento.id && parcelasOriginais && fetchParcelas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  const fetchParcelas = async () => {
    setIsLoading(true)
    try {
      agruparParcelas(parcelasOriginais, parcelasProteticasOriginais)
    } catch (error) {}
    setIsLoading(false)
  }

  const handleBack = () => {
    setParcelasAgrupadas({})
    previousStep()
  }

  const agruparParcelas = (parcelas, proteticas) => {
    if (!(parcelas.length > 0 || proteticas.length > 0)) {
      return
    }
    let parcelamento = { parcelas }
    if (proteticas) {
      parcelamento.parcelasProteticas = proteticas
    }
    setParcelasAgrupadas(parcelamento)
    configurarVencimento(parcelamento)
  }

  const configurarVencimento = (parcelamento) => {
    let vencimento = {}
    parcelamento.forEach((grupoPagamento) => {
      vencimento[grupoPagamento] = null
    })
    setVencimentoAgrupado(vencimento)
  }

  const toggleIsOpen = (step) => {
    let newSteps = { ...INITIAL_OPEN_STATE }
    Object.keys(parcelasAgrupadas).forEach((_, i) => {
      newSteps[`step${i}`] = false
    })
    setStepIsOpen({
      ...newSteps,
      [step]: !stepIsOpen[step],
    })
  }

  const handleSalvarNovoVencimento = async () => {
    setIsLoading(true)
    await salvarNovaData()
    previousStep()
    setVencimentoAgrupado({})
    setIsLoading(false)
  }

  const handleChangeVencimento = (vencimento, grupoPagamento, planoPagamentoId) => {
    if (vencimento) {
      if (moment() > vencimento) {
        vencimento = moment()
      }
      const parcelasNovas = { ...parcelasAgrupadas }
      parcelasNovas[grupoPagamento] = parcelasNovas[grupoPagamento].map((parcela, i) => {
        if (
          parcela.planoTratamentoPagamentoId === planoPagamentoId ||
          parcela.planoTratamentoPagamentoProteticoId === planoPagamentoId
        ) {
          parcela.dataVencimento = vencimento.clone().add(i, 'months')
        }
        return parcela
      })

      setVencimentoAgrupado({ ...vencimentoAgrupado, [grupoPagamento]: vencimento })
      setParcelasAgrupadas(parcelasNovas)
    }
  }

  const salvarNovaData = async () => {
    try {
      await PlanoTratamentoService.alterarValidadePlano(parcelasAgrupadas)
      toast('Novo vencimento salvo com sucesso', { type: toast.TYPE.SUCCESS })
    } catch (error) {
      console.log(error)
    }
  }

  const renderParcelas = () => (
    <Fragment>
      <Row>
        <Col>
          <h3 className='mb-4 m-0 text-secondary font-weight-bold'>Alterar Data de Vencimento</h3>
        </Col>
        <Col className='d-flex align-items-center col-md-5'></Col>
      </Row>
      {Object.keys(parcelasAgrupadas).length > 0
        ? Object.keys(parcelasAgrupadas).map((grupoPagamento, i) => {
            let parcelas = parcelasAgrupadas[grupoPagamento]
            if (parcelas.length === 0) {
              return null
            }
            if (i === 0) {
              return renderPanel('step0', grupoPagamento, parcelas[0].planoTratamentoPagamentoId, parcelas)
            } else if (i > 0 && parcelas.length > 0) {
              const parcelasProtetico = mapProteticos(parcelas, {})
              return (
                <Fragment>
                  {Object.keys(parcelasProtetico).map((pagamentoId, j) => {
                    const parcelasProteticoPorId = parcelasProtetico[pagamentoId]
                    return renderPanel(
                      `step${j + 1}`,
                      grupoPagamento,
                      pagamentoId,
                      parcelasProteticoPorId,
                      parcelasProteticoPorId[0].nomeProcedimentoProtetico || 'Procedimentos Protéticos'
                    )
                  })}
                </Fragment>
              )
            } else {
              return null
            }
          })
        : !isLoading && <div className='my-3'> Este plano de tratamento não possui parcelas em aberto </div>}
    </Fragment>
  )

  const renderPanel = (step, grupoPagamento, planoPagamentoId, parcelas, nome = 'Procedimentos') => {
    const selecionado = vencimentoAgrupado[grupoPagamento]
    return (
      <CollapsePanel key={step} stepCode={step} text={nome} isOpen={stepIsOpen[step]} toggleIsOpen={toggleIsOpen}>
        <div className='my-3 d-flex'>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              id='dataPrimeiraParcela'
              name='dataPrimeiraParcela'
              className='form-control'
              invalidDateMessage='Formato de data inválido'
              onChange={(data) => handleChangeVencimento(data, grupoPagamento, planoPagamentoId)}
              value={selecionado}
              variant='inline'
              format='DD/MM/YYYY'
              required
              autoOk
              animateYearScrolling
              disablePast
              maxDate={moment().add(30, 'days')}
            />
          </MuiPickersUtilsProvider>
          <Button disabled={!selecionado} variant='primary' className='ml-2' onClick={handleSalvarNovoVencimento}>
            Salvar
          </Button>
        </div>
        <Parcelas parcelas={parcelas} />
      </CollapsePanel>
    )
  }

  return (
    <div className='mb-5 pb-5 px-5'>
      {renderParcelas()}
      <div style={backContainerStyle} onClick={handleBack}>
        <Button variant='outline-primary' style={backStyle}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <span className='text-primary'>Voltar</span>
      </div>
    </div>
  )

  function mapProteticos(parcelas, parcelasProtetico) {
    parcelas.forEach((parcela) => {
      if (!parcelasProtetico[parcela.planoTratamentoPagamentoProteticoId]) {
        parcelasProtetico[parcela.planoTratamentoPagamentoProteticoId] = []
      }
      parcelasProtetico[parcela.planoTratamentoPagamentoProteticoId].push(parcela)
    })
    return parcelasProtetico
  }
}

const backContainerStyle = {
  cursor: 'pointer',
}
const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

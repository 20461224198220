import { isSameDay, isSameMonth, isSameYear } from 'date-fns'
import moment from 'moment'

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const isSameDate = (data1, data2) =>
  isSameDay(data1, data2) && isSameMonth(data1, data2) && isSameYear(data1, data2)

export const addMonthsToDate = (date, months) => new Date(new Date(date).setMonth(date.getMonth() + months))

export const addDaysToDate = (date, days) => new Date(new Date(date).setDate(date.getDate() + days))

export const formatFullCalendarDate = (date) => moment(date).format('YYYY-MM-DD')

export const formatBackendDate = (date) => moment(date).format('MM/DD/YYYY')

export const formatBackendDateTime = (date) => moment(date).format('MM/DD/YYYY HH:mm:ss')

export const countDaysInRange = (startDate, endDate) => {
  const difference = endDate.getTime() - startDate.getTime()
  return difference / (1000 * 3600 * 24) + 1
}

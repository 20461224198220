import React, { useState } from 'react'
import { Modal, Table } from 'react-bootstrap'

export default function AgendamentoClinicoGeral(props) {
    const { agendamentoClinicoGeralPorPaciente } = props
    const { agendamentos, presenca, semReagendar, faltosos, totalDeAgendamentos, porcentagemPresenca, porcentagemSemReagendar, porcentagemFaltosos } = agendamentoClinicoGeralPorPaciente

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [rows, setRows] = useState([])

    const abreModal = (rows, title) => {
        setRows(rows);
        setModalTitle(title)
        setModalIsOpen(true);
    }

    const fechaModal = () => {
        setRows([]);
        setModalTitle('');
        setModalIsOpen(false);
    }

    return (
        <>
            {modalIsOpen &&
                <Modal className='modal-schedule' show={modalIsOpen} onHide={() => fechaModal()}>
                    <Modal.Header className='p-4' closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-4'>
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Dependente</th>
                                    <th>CPF</th>
                                    <th>WhatsApp</th>
                                    <th>E-mail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map(row => {
                                    return <tr>
                                        <td>{row.nome}</td>
                                        <td>{row.dependente}</td>
                                        <td>{row.cpf}</td>
                                        <td>{row.whatsApp}</td>
                                        <td>{row.email}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            }

            <div className='row mt-5'>
                <div className='col-6 col-xl-3'>
                    <div className='row card-metric-dashboard align-items-center'>
                        <div className='col-3 icon'>
                            <img src='/img/note-text.png' />
                        </div>
                        <div className='col-9'>
                            <div className='row'>
                                <div className='col-9'>
                                    <p>Agendamentos</p>
                                </div>
                                <div className='col-3' onClick={() => abreModal(agendamentos, 'Agendamentos')}>
                                    <img src='/img/button.png' />
                                </div>
                                <div className='col-12 value'>
                                    <p>{totalDeAgendamentos || 0}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-6 col-xl-3'>
                    <div className='row card-metric-dashboard align-items-center'>
                        <div className='col-3 icon'>
                            <img src='/img/hand.png' />
                        </div>
                        <div className='col-9'>
                            <div className='row'>
                                <div className='col-9'>
                                    <p>Presença</p>
                                </div>
                                <div className='col-3' onClick={() => abreModal(presenca, 'Presença')}>
                                    <img src='/img/button.png' />
                                </div>
                                <div className='col-6 value'>
                                    <p>{presenca?.length || 0}</p>
                                </div>
                                <div className='col-6 text-right text-success pr-0 value'>
                                    <p>{porcentagemPresenca.toFixed(2)}%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-6 col-xl-3'>
                    <div className='row card-metric-dashboard align-items-center'>
                        <div className='col-3 icon'>
                            <img src='/img/cycle.png' />
                        </div>
                        <div className='col-9'>
                            <div className='row'>
                                <div className='col-9 pr-0'>
                                    <p>Sem reagendar</p>
                                </div>
                                <div className='col-3' onClick={() => abreModal(semReagendar, 'Sem reagendar')}>
                                    <img src='/img/button.png' />
                                </div>
                                <div className='col-6 value'>
                                    <p>{semReagendar?.length || 0}</p>
                                </div>
                                <div className='col-6 text-right text-warning pr-0 value'>
                                    <p>{porcentagemSemReagendar.toFixed(2)}%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-6 col-xl-3'>
                    <div className='row card-metric-dashboard align-items-center'>
                        <div className='col-3 icon'>
                            <img src='/img/edit.png' />
                        </div>
                        <div className='col-9'>
                            <div className='row'>
                                <div className='col-9'>
                                    <p>Faltosos</p>
                                </div>
                                <div className='col-3' onClick={() => abreModal(faltosos, 'Faltosos')}>
                                    <img src='/img/button.png' />
                                </div>
                                <div className='col-6 value'>
                                    <p>{faltosos.length || 0}</p>
                                </div>
                                <div className='col-6 text-right text-danger pr-0 value'>
                                    <p>{porcentagemFaltosos.toFixed(2)}%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
	requestProcedimentos,
	editarProcedimento,
	resetProcedimento,
} from '../../services/actions/procedimento.actions'
import ProcedimentosList from '../../components/Procedimentos/ProcedimentosList'

const mapStateToProps = (store) => ({
	procedimentos: [...store.procedimentosState.procedimentos],
})

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ requestProcedimentos, editarProcedimento, resetProcedimento }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProcedimentosList)

import { put, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import AxiosClient from './axios-client'
import { Colaboradores, Notificacoes } from '../types'

function* fetchColaboradores() {
  try {
    const { data } = yield AxiosClient.get('Colaborador/getAll')
    yield put({
      type: Colaboradores.LISTAR_COLABORADORES,
      colaboradores: data,
    })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* fetchDentistas(action) {
  try {
    const { data } = yield AxiosClient.get(`Colaborador/getDentistas/${action.atualizouAgenda}`)
    yield put({
      type: Colaboradores.LISTAR_DENTISTAS,
      dentistas: data,
    })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* salvarColaborador(action) {
  let error = {}
  try {
    const { colaborador } = action
    if (!colaborador.id) {
      error = yield AxiosClient.post('Colaborador/add', colaborador)
    } else {
      error = yield AxiosClient.put('Colaborador/update', colaborador)
    }
    yield all([
      put({ type: Colaboradores.EDITAR_COLABORADOR, colaborador: {} }),
      put({ type: Colaboradores.REQUEST_COLABORADORES }),
    ])
    toast('Informações salvas com sucesso', { type: toast.TYPE.SUCCESS })
  } catch (e) {
    console.log(error)
  }
}

function* getById(action) {
  try {
    const { data } = yield AxiosClient.get(`Colaborador/getById/${action.colaboradorId}`)
    yield put({
      type: Colaboradores.EDITAR_COLABORADOR,
      colaborador: data,
    })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, mensagens: error.notifications })
  }
}

function* alterarSenha(action) {
  try {
    yield AxiosClient.post(`Colaborador/trocarSenha/`, action.colaboradorId)
    // yield put({
    //   type: Colaboradores.ALTERAR_SENHA,
    //   colaborador: data
    // })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* gerarNovaSenha(action) {
  try {
    yield AxiosClient.post(`Colaborador/trocarSenha/`, action.colaboradorId)
    // yield put({
    //   type: Colaboradores.SOLICITAR_SENHA,
    //   colaborador: data
    // })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* getEspecialidadeCargo(action) {
  try {
    let { data } = yield AxiosClient.get(`Common/getEspecialidades/${action.cargoId}`)
    data = data.map((d) => ({
      label: d.nome,
      value: d.id,
    }))
    yield put({
      type: Colaboradores.LISTAR_ESPECIALIDADES,
      especialidades: data,
    })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* fetchCargos(action) {
  try {
    let { data } = yield AxiosClient.get(`Common/getCargos`)
    data = data.map((d) => ({
      label: d.nome,
      value: d.id,
      tipo: d.tipo,
    }))
    yield put({
      type: Colaboradores.LISTAR_CARGOS,
      cargos: data,
    })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* toggleAcesso(action) {
  try {
    yield AxiosClient.put(`Colaborador/bloquearAcesso/${action.colaboradorId}/${action.bloquear}`)
    yield put({ type: Colaboradores.REQUEST_COLABORADORES })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* inativar(action) {
  try {
    yield AxiosClient.put(`Colaborador/Ativar/${action.colaboradorId}/${action.ativar}`)
    yield put({ type: Colaboradores.REQUEST_COLABORADORES })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* fetchDentistasByEspecialidade(action) {
  try {
    yield put({
      type: Colaboradores.SET_LOADING,
      isLoading: true,
    })
    const { data } = yield AxiosClient.get(`Colaborador/getDentistaByEspecialidade/${action.especialidadeId}`)
    yield put({
      type: Colaboradores.LISTAR_DENTISTAS,
      dentistas: data,
    })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  } finally {
    yield put({
      type: Colaboradores.SET_LOADING,
      isLoading: false,
    })
  }
}

function* colaboradoresListener() {
  yield all([
    takeEvery(Colaboradores.REQUEST_COLABORADORES, fetchColaboradores),
    takeEvery(Colaboradores.REQUEST_DENTISTAS, fetchDentistas),
    takeEvery(Colaboradores.REQUEST_DENTISTAS_BY_ESPECIALIDADE, fetchDentistasByEspecialidade),
    takeLatest(Colaboradores.GET_COLABORADOR_ID, getById),
    takeLatest(Colaboradores.SALVAR_COLABORADOR, salvarColaborador),
    takeLatest(Colaboradores.TOGGLE_ACESSO_COLABORADOR, toggleAcesso),
    takeLatest(Colaboradores.INATIVAR_COLABORADOR, inativar),
    takeLatest(Colaboradores.ALTERAR_SENHA, alterarSenha),
    takeLatest(Colaboradores.SOLICITAR_SENHA, gerarNovaSenha),
    takeEvery(Colaboradores.REQUEST_CARGOS, fetchCargos),
    takeEvery(Colaboradores.REQUEST_ESPECIALIDADES, getEspecialidadeCargo),
  ])
}

export default colaboradoresListener

import React from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import moment from 'moment'
import { periodoIndisponivel } from '../../constants/bloqueios.constants'
import { isSameDate } from '../../utils/time.utils'

const options = [
  { label: 'Manhã', value: periodoIndisponivel.MANHA },
  { label: 'Tarde', value: periodoIndisponivel.TARDE },
]

const ListaBloqueio = ({ bloqueados, updateBloqueio, logout, salvarBloqueios, mesAtual, firstStep }) => {
  const visibleBloqueios = bloqueados.filter((bloq) => moment(bloq.data).month() === mesAtual)

  return (
    <div className='d-flex flex-column flex-fill'>
      <h4>Dias ausentes:</h4>
      {visibleBloqueios.length > 0 ? (
        <Form className='pr-2' style={{ overflowX: 'hidden' }}>
          {visibleBloqueios.map((bloqueio, index) => {
            const { data, periodo, justificativa } = bloqueio
            const isDateBefore = moment(data).isBefore(moment(new Date())) && !isSameDate(new Date(data), new Date())
            return (
              <Form.Group className='required' key={index}>
                <Form.Label className='mr-3 required'>
                  Observação ({moment(data).format('DD/MM')} de {options[parseInt(periodo)].label}):
                </Form.Label>
                <Form.Control
                  required
                  value={justificativa}
                  disabled={isDateBefore}
                  onChange={(e) => updateBloqueio(bloqueio, e.target.value)}
                />
              </Form.Group>
            )
          })}
        </Form>
      ) : (
        <div>Nenhum período ausente neste mês.</div>
      )}
      <Row>
        <Col className='text-right p-2'>
          <Button variant='light' className=' btn btn-outline-primary mr-2' onClick={firstStep ? firstStep : logout}>
            {firstStep ? 'Voltar' : 'Cancelar'}
          </Button>
          <Button type='submit' className='ml-2 mr-3' onClick={salvarBloqueios}>
            Salvar
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default ListaBloqueio

import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import moment from 'moment'
import {
  compareAsc,
  getDay,
  setHours,
  getYear,
  getMonth,
  addMonths,
  lastDayOfMonth,
  isAfter,
  isSameDay,
} from 'date-fns'

import Calendario from '../Common/Calendario'
import ListaBloqueio from './ListaBloqueio'
import FranquiaService from '../../services/franquias.service'
import AgendaDentistaService from '../../services/agendaDentista.service'
import SystemConstants from '../../constants/system.constants'
import { isSameDate } from '../../utils/time.utils'

import { bloqueioDia, periodoIndisponivel } from '../../constants/bloqueios.constants'

const BloqueioDentista = (props) => {
  const {
    mesesAConfigurar,
    salvarBloqueiosDentista,
    colaboradorId,
    agendamentosDentista,
    selectedDentista,
    currentStep,
    setIsLoading,
    isAdmin,
  } = props

  const calendario = useRef()
  const isEdit = mesesAConfigurar && agendamentosDentista.length > 0 && mesesAConfigurar.length === 0
  const [diasFranquia, setDiasFranquia] = useState([])
  const [mesAtual, setMesAtual] = useState(moment(mesesAConfigurar[0]).month() || moment(new Date()).month())
  const [anoAtual, setAnoAtual] = useState(moment(mesesAConfigurar[0]).year() || moment(new Date()).year())
  const [bloqueios, setBloqueios] = useState(
    agendamentosDentista && agendamentosDentista.length > 0 ? agendamentosDentista : []
  )

  useEffect(() => {
    ;(!currentStep || currentStep === 2) && didMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  const didMount = async () => {
    try {
      if (mesesAConfigurar && mesesAConfigurar.length > 0) {
        await verificarConfiguracao()
      }
      setIsLoading(true)
      const [diasFranquia, horariosAgendaDentista] = await Promise.all([
        getDiasFuncionamentoFranquia(),
        getAgendaDentista(selectedDentista || colaboradorId),
      ])
      setDiasFranquia(diasFranquia)
      setUpBloqueiosMeses(diasFranquia, horariosAgendaDentista)
      setIsLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  const getAgendaDentista = async (id) => {
    const today = new Date(),
      year = getYear(today),
      month = getMonth(today)
    const { data } = await AgendaDentistaService.getAgendamentosDentista(
      new Date(year, month, 1).toISOString(),
      addMonths(lastDayOfMonth(today), 1).toISOString(),
      id
    )
    return data
  }

  const getDiasFuncionamentoFranquia = async () => {
    const franquiaId = localStorage.getItem(SystemConstants.getFranquia())
    const { data } = await FranquiaService.getFranquia(franquiaId)
    setDiasFranquia(data.horariosFuncionamento)
    return data.horariosFuncionamento
  }

  const setUpBloqueiosMeses = (diasFranquia, horariosAgendaDentista) => {
    setBloqueios([
      ...mapBloqueios(new Date(), diasFranquia, horariosAgendaDentista),
      ...mapBloqueios(addMonths(new Date(), 1), diasFranquia, horariosAgendaDentista),
    ])
  }

  const mapBloqueios = (mesBloqueios, diasFranquia, horariosAgendaDentista) => {
    let bloqueiosDentista = []

    const isFirstConfig = horariosAgendaDentista.length === 0

    const ano = moment(mesBloqueios).year()
    const mes = moment(mesBloqueios).month()

    for (let dia = 0; dia < moment(mesBloqueios).daysInMonth(); dia++) {
      const dataDia = moment([ano, mes, dia + 1]).toDate()
      const diaSemanaFranquia = diasFranquia.find((d) => d.diaSemana === getDay(dataDia))
      if (diaSemanaFranquia) {
        const isDateBefore = dia < moment(new Date()).date() - 1 // Remover o -1 para não mostrar o dia atual
        const isSameMonth = mes === moment(new Date()).month()

        const foundDay = horariosAgendaDentista.filter((h) => isSameDate(new Date(h.data), dataDia))

        const foundManha = foundDay.find((h) => h.periodo === +periodoIndisponivel.MANHA)
        let manhaBloqueada = foundManha ? foundManha.bloqueado : false

        const foundTarde = foundDay.find((h) => h.periodo === +periodoIndisponivel.TARDE)
        let tardeBloqueada = foundTarde ? foundTarde.bloqueado : false

        if (isAdmin) {
          if (!foundManha || foundManha.id === undefined) {
            manhaBloqueada = true
          }

          if (!foundTarde || foundTarde.id === undefined) {
            tardeBloqueada = true
          }
        }

        const bloqueioManha = {
          ...bloqueioDia,
          id: foundManha ? foundManha.id : undefined,
          colaboradorId: selectedDentista || colaboradorId,
          periodo: periodoIndisponivel.MANHA,
          justificativa: foundManha ? foundManha.justificativa : '',
          data: dataDia.toISOString(),
          start: setHours(dataDia, 1).toISOString(),
          end: setHours(dataDia, 11).toISOString(),
          bloqueado: manhaBloqueada,
        }
        const bloqueioTarde = {
          ...bloqueioManha,
          id: foundTarde ? foundTarde.id : undefined,
          colaboradorId: selectedDentista || colaboradorId,
          periodo: periodoIndisponivel.TARDE,
          justificativa: foundTarde ? foundTarde.justificativa : '',
          start: setHours(dataDia, 13).toISOString(),
          end: setHours(dataDia, 23).toISOString(),
          bloqueado: tardeBloqueada,
        }
        if (!(isFirstConfig && isDateBefore && isSameMonth)) {
          diaSemanaFranquia.horaInicioManha && diaSemanaFranquia.horaFimManha && bloqueiosDentista.push(bloqueioManha)
          diaSemanaFranquia.horaInicioTarde && diaSemanaFranquia.horaFimTarde && bloqueiosDentista.push(bloqueioTarde)
        }
      }
    }
    try {
      return bloqueiosDentista.filter((bloqueio) => {
        return horariosAgendaDentista.length > 0
          ? isAfter(new Date(bloqueio.data), new Date(horariosAgendaDentista[0].data)) ||
              isSameDate(new Date(bloqueio.data), new Date(horariosAgendaDentista[0].data))
          : true
      })
    } catch (error) {
      console.error(error)
      return []
    }
  }

  const logout = () => {
    props.history.push('/logout')
  }

  const handlePreviousStep = () => {
    props.previousStep()
  }

  const verificarConfiguracao = async () => {
    let frase = ''

    const nomesMeses = mesesAConfigurar.map((m) => moment(m).format('MMMM')).join(' e ')
    const plural = mesesAConfigurar.length > 1
    if (plural) {
      frase = `As agendas dos meses de ${nomesMeses} ainda não foram configuradas.`
    } else {
      frase = `A agenda do mês de ${nomesMeses} ainda não foi configurada.`
    }
    const { value } = await Swal.fire({
      title: frase,
      type: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#dbdbdb',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Configurar',
    })
    if (value) {
      calendario.current.calendar.gotoDate(mesesAConfigurar[0])
      setMesAtual(moment(mesesAConfigurar[0]).month())
    } else {
      logout()
    }
  }

  const getItem = (item) => {
    item = bloqueios.find((b) => b.data === item.data && b.periodo === item.periodo)
    return item
  }

  const addBloqueio = (bloqueio) => {
    let novoBloqueio = getItem(bloqueio)
    const isDateBefore =
      moment(novoBloqueio.data).isBefore(moment(new Date())) && !isSameDate(new Date(novoBloqueio.data), new Date())
    if (isDateBefore) {
      return
    }
    novoBloqueio.bloqueado = !novoBloqueio.bloqueado
    bloqueios[bloqueios.indexOf(novoBloqueio)] = novoBloqueio
    setBloqueios([...bloqueios])
  }

  function updateBloqueio(item, justificativa) {
    item = getItem(item)
    item.justificativa = justificativa
    bloqueios[bloqueios.indexOf(item)] = item
    setBloqueios([...bloqueios])
  }

  const renderBloqueio = ({ event, el }) => {
    el.text = ''

    const item = getItem(event._def.extendedProps)

    const label = String(event._def.extendedProps.periodo) === periodoIndisponivel.MANHA ? 'Manhã' : 'Tarde'
    let checkbox = document.createElement('input')

    checkbox.setAttribute('type', 'checkbox')
    !item.bloqueado && checkbox.setAttribute('checked', `${!item.bloqueado}`)
    checkbox.setAttribute('nome', 'bloqueios')
    checkbox.setAttribute('class', 'mr-2')

    const isDateBefore = moment(item.data).isBefore(moment(new Date())) && !isSameDate(new Date(item.data), new Date())
    if (isDateBefore) {
      checkbox.setAttribute('disabled', 'true')
    }

    el.appendChild(checkbox)
    el.append(label)
    return el
  }

  const proximoMes = () => {
    setMesAtual(
      moment()
        .month(mesAtual + 1)
        .month()
    )
    setAnoAtual(
      moment()
        .month(mesAtual + 1)
        .year()
    )
    calendario.current && calendario.current.calendar.next()
  }

  const mesAnterior = () => {
    setMesAtual(
      moment()
        .month(mesAtual - 1)
        .month()
    )
    setAnoAtual(
      moment()
        .month(mesAtual + 1)
        .year()
    )
    calendario.current && calendario.current.calendar.prev()
  }

  const saveAlert = async (e) => {
    e.preventDefault()
    const { value } = await Swal.fire({
      title: 'Deseja salvar as datas?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Salvar',
      cancelButtonColor: '#dbdbdb',
      cancelButtonText: 'Cancelar',
    })
    value && salvarBloqueios()
  }

  const redirectToDashboard = () => {
    !selectedDentista && props.history.push('/dashboard/geral')
  }

  const redirectToAtendimento = () => {
    !selectedDentista && props.history.push('/atendimento')
  }

  const salvarBloqueios = () => {
    const bloqueiosSave = bloqueios
    salvarBloqueiosDentista(bloqueiosSave, selectedDentista ? selectedDentista : colaboradorId, redirectToAtendimento)
    setMesAtual(moment().month() || moment(new Date()).month())
    setAnoAtual(moment().year() || moment(new Date()).year())
    calendario.current && calendario.current.calendar.gotoDate(new Date())
  }

  const filteredBloqueios = () => {
    const filtrados = bloqueios.filter((b) => {
      const diaSemana = diasFranquia.find((d) => d.diaSemana === getDay(new Date(b.data)))
      if (diaSemana) {
        return (
          (diaSemana.horaInicioManha &&
            diaSemana.horaFimManha &&
            Number(b.periodo) === Number(periodoIndisponivel.MANHA)) ||
          (diaSemana.horaInicioTarde &&
            diaSemana.horaFimTarde &&
            Number(b.periodo) === Number(periodoIndisponivel.TARDE))
        )
      }
      return false
    })
    return filtrados
  }

  const renderBody = () => {
    const showPrev = (anoAtual >= moment().year() && mesAtual < moment().month()) || mesAtual > moment().month()
    const showNext = mesAtual === moment().month()
    const bloqueiosFiltradosDiasFranquia = filteredBloqueios()
    const bloqueados = bloqueiosFiltradosDiasFranquia.filter((b) => b.bloqueado)
    return (
      <div>
        <Row>
          <Col md='8' style={shadowStyle} className=' ml-2 py-3'>
            <Container>
              <Row className='my-3'>
                <Col className='text-left'>
                  {showPrev && (
                    <Button onClick={mesAnterior} variant='primary'>
                      Anterior
                    </Button>
                  )}
                </Col>
                <Col className='text-center'>
                  <h2>
                    {moment()
                      .month(mesAtual)
                      .format('MMMM')}
                  </h2>
                </Col>
                <Col className='text-right'>
                  {showNext && (
                    <Button onClick={proximoMes} variant='primary'>
                      Próximo
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Calendario
                    events={bloqueiosFiltradosDiasFranquia}
                    header={false}
                    titleFormat={{ year: 'numeric', month: 'long' }}
                    dateClick={() => {}}
                    setRef={calendario}
                    eventClick={({ event }) => addBloqueio(event._def.extendedProps)}
                    viewType='dayGridMonth'
                    nowIndicator={false}
                    eventClassName='bg-white shadow-sm text-dark py-2 pl-1 pr-3 rounded-0'
                    eventRender={renderBloqueio}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col style={shadowStyle} className='d-flex px-3 pt-3 mx-2'>
            <ListaBloqueio
              {...props}
              selectedDentista={props.selectedDentista}
              handlePreviousStep={handlePreviousStep}
              mesAtual={mesAtual}
              bloqueados={bloqueados}
              updateBloqueio={updateBloqueio}
              salvarBloqueios={saveAlert}
              calendario={calendario}
              isEdit={isEdit}
            />
          </Col>
        </Row>
      </div>
    )
  }

  return renderBody()
}

export default BloqueioDentista

const shadowStyle = {
  border: '1px solid #DDD',
  borderRadius: '4px',
  boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
}

import React, { useEffect, useState } from 'react'
import { Table, Button } from 'react-bootstrap'

import proteticoService from '../../../../services/protetico.service'
import { formatMoney } from '../../../../utils/string.utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import formatCNPJ from '@brazilian-utils/format-cnpj'

export default function AlterarProteticoProcedimento({
  selectedProcedimento,
  setIsLoading,
  previousStep,
  setProteticoDefinido,
}) {
  const [proteticos, setProteticos] = useState([])
  const [selectedProtetico, setSelectedProtetico] = useState(null)

  useEffect(() => {
    selectedProcedimento && getProteticoProcedimento()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProcedimento])

  const getProteticoProcedimento = async () => {
    setIsLoading(true)
    try {
      let { data } = await proteticoService.getProteticoByProcedimentoId(
        selectedProcedimento.procedimento.procedimentoProteticoId
      )
      data = data.filter((prot) => prot.tabelaPrecos.length > 0)
      if (data.length > 0) {
        let itensProtetico = data.map((prot) => {
          const itemProtese = prot.tabelaPrecos.find(
            (p) => selectedProcedimento.procedimento.procedimentoProteticoId === p.procedimentoProteticoId
          )
          return {
            protetico: prot,
            itemProtese,
          }
        })
        setProteticos(itensProtetico)
      } else {
        setProteticos([])
      }
    } catch (error) {}
    setIsLoading(false)
  }

  const handleChangeProtetico = (e) => {
    e.target ? setSelectedProtetico(e.target.value) : setSelectedProtetico(e)
  }

  const onSave = () => {
    if (!selectedProtetico) {
      toast('Você deve selecionar ao menus um protético', { type: toast.TYPE.ERROR })
      return
    }
    setProteticoDefinido(proteticos.find((p) => p.protetico.id === selectedProtetico))
  }

  const renderList = () => (
    <div>
      <h2 className='text-secondary font-weight-bold mb-4'>Selecione o novo Protético</h2>
      {proteticos.length > 0 ? (
        <Table bordered size='sm' hover>
          <thead>
            <tr>
              <th>CNPJ</th>
              <th>Razão Social</th>
              <th className='text-center' width='150px'>
                Valor
              </th>
              <th width='150px' className='text-center'>
                Selecionar
              </th>
            </tr>
          </thead>
          <tbody>
            {proteticos.map((p) => {
              const { id, cnpj, razaoSocial } = p.protetico
              const { valorFinal } = p.itemProtese
              return (
                <tr onClick={() => handleChangeProtetico(id)} key={cnpj}>
                  <td>{formatCNPJ(cnpj)}</td>
                  <td>{razaoSocial}</td>
                  <td className='text-center'>{formatMoney(valorFinal)}</td>
                  <td className='text-center'>
                    <input
                      type='radio'
                      name='protetico'
                      value={id}
                      onChange={handleChangeProtetico}
                      checked={selectedProtetico === id}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <div>Nenhum protetico encontrado</div>
      )}
    </div>
  )
  return (
    <div className='d-flex flex-column mb-5 pb-5'>
      {renderList()}
      <div className='d-flex flex-fill justify-content-between align-items-center'>
        <div style={backContainerStyle} onClick={previousStep}>
          <Button variant='outline-primary' style={backStyle}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <span className='text-primary'>Voltar</span>
        </div>
        <Button style={backContainerStyle} variant='primary' onClick={onSave}>
          Salvar
        </Button>
      </div>
    </div>
  )
}

const backContainerStyle = {
  cursor: 'pointer',
}
const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
  marginLeft: '10px',
}

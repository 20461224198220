import React, { Fragment, useState, useEffect } from 'react'
import { Table, Button } from 'react-bootstrap'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'

import { formatMoney } from '../../../../utils/string.utils'

export default function ParcelasList({ parcelas, handleSave }) {
  const [parcelasAlteradas, setParcelasAlteradas] = useState([])

  useEffect(() => {
    if (parcelas && parcelas.length > 0) {
      setParcelasAlteradas(parcelas)
    } else {
      setParcelasAlteradas([])
    }
  }, [parcelas])

  const handleChangeVencimento = (data) => {
    if (data) {
      if (moment() > data) {
        data = moment()
      }
      setParcelasAlteradas(
        parcelas.map((parcela, index) => ({
          ...parcela,
          dataVencimento: data.clone().add(index, 'months'),
        }))
      )
    }
  }

  return (
    <Fragment>
      {parcelasAlteradas.length > 0 && (
        <div className='d-flex my-3'>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              id='dataPrimeiraParcela'
              name='dataPrimeiraParcela'
              className='form-control'
              invalidDateMessage='Formato de data inválido'
              onChange={handleChangeVencimento}
              value={new Date(parcelasAlteradas[0].dataVencimento)}
              variant='inline'
              format='DD/MM/YYYY'
              required
              autoOk
              animateYearScrolling
              disablePast
            />
          </MuiPickersUtilsProvider>
          <Button variant='primary' className='ml-3' onClick={() => handleSave(parcelasAlteradas)}>
            Salvar
          </Button>
        </div>
      )}
      {parcelasAlteradas.length > 0 && (
        <Table bordered hover>
          <thead>
            <tr className='text-center'>
              <th>Parcela</th>
              <th>Valor</th>
              <th>Vencimento</th>
            </tr>
          </thead>
          <tbody>
            {parcelasAlteradas.map((parcela, i) => (
              <tr key={i} className='text-center'>
                <td>{parcela.parcela}</td>
                <td>{formatMoney(parcela.valor)}</td>
                <td>{moment(parcela.dataVencimento).format('DD/MM/YYYY')}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  )
}

import React, { useState, useEffect, Fragment } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import formatCPF from '@brazilian-utils/format-cpf'
import { format } from 'date-fns'
import Select from 'react-select'

import RelatorioService from '../../services/relatorio.service'
import ColaboradoresService from '../../services/colaboradores.service'
import PageLoading from '../Common/PageLoading'
import { Col, Row, Table, Button, Spinner } from 'react-bootstrap'
import Paginator from '../Common/Paginator'
import planoTratamentoService from '../../services/planoTratamento.service'
import { status } from '../../constants/common.constants'
import { formatMoney } from '../../utils/string.utils'
import CSVDownload from './CSVDownload'

export default function Manutencoes({ selectedRelatorio }) {
  const itensPorPagina = 20

  const [isLoading, setLoading] = useState(false)
  const [isFilterLoading, setFilterLoading] = useState(false)
  const [itensLista, setItensLista] = useState([])
  const [cabecalhoRelatorio, setCabecalhoRelatorio] = useState([])
  const [feedbackBusca, setFeedbackBusca] = useState('')
  const [pagina, setPagina] = useState(0)
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [itensCSV, setItensCSV] = useState([])
  const [dateFilterDe, setDateFilterDe] = useState(null)
  const [dateFilterAte, setDateFilterAte] = useState(null)
  const [selectedColaborador, setSelectedColaborador] = useState(null)
  const [colaboradoresOptions, setColaboradoresOptions] = useState([])
  const [isLoadingCSV, setLoadingCSV] = useState(false)
  const [downloadCSV, setDownloadCSV] = useState(false)

  useEffect(() => {
    didMount()
    setCabecalhoRelatorio(['Profissional', 'Paciente', 'Descrição', 'Data'])
  }, [])

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina, dateFilterDe, dateFilterAte, selectedColaborador])

  useEffect(() => {
    if (downloadCSV) {
      setTimeout(() => {
        setDownloadCSV(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itensCSV, downloadCSV])

  const didMount = async () => {
    setFilterLoading(true)
    const dentistas = await ColaboradoresService.getDentistas(false)
    setColaboradoresOptions(
      dentistas.map(({ nome, id }) => ({
        label: nome,
        value: id,
      }))
    )
    setFilterLoading(false)
  }

  const getValuesLista = async () => {
    const totalDaLista = itensPorPagina * totalPaginas
    if (totalDaLista === 0) {
      return
    }
    setLoadingCSV(true)

    let filtros = [
      getFilter('dataInicial', dateFilterDe),
      getFilter('dataFinal', dateFilterAte),
      getFilter('colaboradorId', selectedColaborador ? selectedColaborador.value : null),
    ]
    const { dados } = await RelatorioService.getManutencoes(filtros, 1, totalDaLista)

    const valores = []
    dados.forEach((item) => {
      valores.push([item.nomeDentista, item.nomePaciente, item.observacao, format(new Date(item.data), 'dd/MM/yyyy')])
    })

    setItensCSV(valores)
    setDownloadCSV(true)
    setLoadingCSV(false)

    return {
      data: valores,
      headers: cabecalhoRelatorio,
      filename: `${selectedRelatorio.label} - ${format(new Date(), 'dd-MM-yyyy')}.csv`,
    }
  }

  const getFilter = (campo, valor) => ({ campo, valor })

  const handleClear = () => {
    setDateFilterAte(null)
    setDateFilterDe(null)
    setSelectedColaborador(null)
    setPagina(0)
  }

  const handleSearch = async () => {
    setLoading(true)
    try {
      setFeedbackBusca('')
      let filtros = [
        getFilter('dataInicial', dateFilterDe),
        getFilter('dataFinal', dateFilterAte),
        getFilter('colaboradorId', selectedColaborador ? selectedColaborador.value : null),
      ]
      const { dados, totalPaginas } = await RelatorioService.getManutencoes(filtros, pagina + 1, itensPorPagina)
      setItensLista(dados)
      setTotalPaginas(totalPaginas)
      if (dados.length === 0) {
        setFeedbackBusca('Nenhum registro encontrado')
      }
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  const handleDateFilterDe = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterDe(filter)
    } else {
      setDateFilterDe(null)
    }
    setPagina(0)
  }

  const handleDateFilterAte = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterAte(filter)
    } else {
      setDateFilterAte(null)
    }
    setPagina(0)
  }

  const handleChangeColaborador = (option) => {
    setSelectedColaborador(option)
    setPagina(0)
  }

  const handlePagina = (data) => setPagina(data.selected)

  const renderItens = () =>
    itensLista.length > 0 ? (
      <Fragment>
        <div className='mt-4 d-flex justify-content-end align-items-center'>
          <CSVDownload asyncExportMethod={getValuesLista}>
            <Button variant='outline-secondary' className='mb-3'>
              {isLoadingCSV ? <Spinner animation='border' size='sm' variant='light' /> : 'Gerar CSV'}
            </Button>
          </CSVDownload>
        </div>
        <Table hover size='sm' className='border rounded-0 shadow-sm'  style={{backgroundColor:'white'}}>
          <thead>
            <tr>
              <th width='250px'>Profissional</th>
              <th width='250px'>Paciente</th>
              <th>Descrição</th>
              <th className='text-center' width='75px'>
                Data
              </th>
            </tr>
          </thead>
          <tbody>
            {itensLista.map((item, i) => {
              const { nomeDentista, nomePaciente, observacao, data } = item
              return (
                <tr key={i} style={{ fontSize: 'smaller' }}>
                  <td>{nomeDentista}</td>
                  <td>{nomePaciente}</td>
                  <td>{observacao}</td>
                  <td className='text-center'>{format(new Date(data), 'dd/MM/yyyy')}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {totalPaginas > 1 && <Paginator handlePagina={handlePagina} pageCount={totalPaginas} paginaAtual={pagina} />}
      </Fragment>
    ) : (
      <div className='mt-4'>{feedbackBusca}</div>
    )

  const renderLoading = () => <PageLoading />

  return (
    <Fragment>
      <Row>
        <Col md='3'>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              invalidDateMessage='Formato de data inválido'
              value={dateFilterDe}
              onChange={handleDateFilterDe}
              className='form-control'
              variant='inline'
              format='DD/MM/YYYY'
              maxDate={dateFilterAte || new Date('2100-01-01')}
              animateYearScrolling
              autoOk
              placeholder='De'
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col md='3'>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
            <KeyboardDatePicker
              invalidDateMessage='Formato de data inválido'
              value={dateFilterAte}
              onChange={handleDateFilterAte}
              minDate={dateFilterDe || new Date('1900-01-01')}
              className='form-control mx-1'
              variant='inline'
              format='DD/MM/YYYY'
              animateYearScrolling
              autoOk
              placeholder='Até'
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col md='3'>
          <Select
            placeholder='Profissional'
            className='w-100 mr-1'
            onChange={handleChangeColaborador}
            value={selectedColaborador}
            options={colaboradoresOptions}
          />
        </Col>
        <Col md='3'>
          <Button disabled={isFilterLoading} onClick={handleClear} variant='outline-danger' className='mr-2'>
            Limpar
          </Button>
        </Col>
      </Row>
      {isLoading ? renderLoading() : renderItens()}
    </Fragment>
  )
}

const spinnerStyle = {
  position: 'absolute',
  top: '10px',
  right: '50px',
}

import React, { Fragment, useState, useEffect } from 'react'
import { Button, Col, Form, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import Moment from 'moment'
import PageLoading from '../Common/PageLoading'
import CollapsePanel from '../Common/CollapsePanel'
import feriadosService from '../../services/feriados.service'
import Select from 'react-select'

const CadastrarFeriado = () => {
  const [isEdit, setIsEdit] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [feriadosCadastrados, setFeriadosCadastrados] = useState([])
  const [anosCadastrados, setAnosCadastrados] = useState([])
  const [anoCadastrado, setAnoCadastrado] = useState('')
  const [nome, setNome] = useState('')
  const [data, setData] = useState(new Date())
  const [tipoFeriado, setTipo] = useState([])
  
  useEffect(() => {
    getAnosCadastrados()
    getFeriadosCadastradosDoAno()
  }, [])

  const getFeriadosCadastradosDoAno = async () => {
    try {
      let ano = anoCadastrado.value ? anoCadastrado.value : new Date().getFullYear()

      const { data } = await feriadosService.getFeriadosCadastradosDoAno(ano)
      
      setFeriadosCadastrados(data)
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const getAnosCadastrados = async () => {
    try {
      const { data } = await feriadosService.getAnosCadastrados()
      setAnosCadastrados(data)
      setAnoCadastrado(({ label: new Date().getFullYear().toString(), value: new Date().getFullYear().toString() }))
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleSaveAdd = async () => {
    setIsLoading(true)
    try {
      if (!nome) {
        toast(`O campo 'Nome do Feriado' é obrigatório`, { type: toast.TYPE.ERROR })
        setIsLoading(false)
        return
      }

      let dataselecao = getOnlyDate(data)
      let hoje = getOnlyDate(new Date())

      if(dataselecao < hoje){
        toast(`O campo 'Data do Feriado' deve conter uma data apartir da data de hoje`, { type: toast.TYPE.ERROR })
        setIsLoading(false)
        return
      }
      let tipo = tipoFeriado.value
      if (!tipo) {
        toast(`Selecione o Tipo do seu Feriado no campo 'Tipo do Feriado'`, { type: toast.TYPE.ERROR })
        setIsLoading(false)
        return
      }

      await feriadosService.cadastrarFeriado({
        nome,
        data,
        tipo,
        ativo: true,

      })
      toast('Feriado cadastrado com sucesso!', { type: toast.TYPE.SUCCESS })
      resetFields()
      await getFeriadosCadastradosDoAno()
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }

  const handleSaveEdit = async () => {
    setIsLoading(true)
    try {
      if (!nome) {
        toast(`O campo 'Nome do Feriado' é obrigatório`, { type: toast.TYPE.ERROR })
        setIsLoading(false)
        return
      }

      let tipo = tipoFeriado.value

      await feriadosService.alterarFeriado({
        ...feriadosCadastrados[isEdit],
        nome,
        data,
        tipo
      })
      toast('Feriado editado com sucesso!', { type: toast.TYPE.SUCCESS })
      resetFields()
      await getFeriadosCadastradosDoAno()
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }

  const handleEdit = (index) => {
    setIsEdit(index)
    setIsOpen(true)
    setNome(feriadosCadastrados[index].nome)
    setTipo(tiposFeriado.find((t) => t.value == feriadosCadastrados[index].tipo))
    setData(feriadosCadastrados[index].data)
  }

  const resetFields = () => {
    setNome('')
    setData(new Date())
    setTipo(tiposFeriado.find((t) => t.value == feriadosCadastrados[3].tipo))
    setIsOpen(false)
    setIsEdit(null)
  }

  const handleCancelEdit = () => {
    setIsEdit(null)
    setIsOpen(false)
    setNome('')
    setData(new Date())
    setTipo(tiposFeriado.find((t) => t.value == feriadosCadastrados[3].tipo))
  }

  const handleToggleAtivo = async (feriado) => {
    setIsLoading(true)
    try {
      if(feriado.ativo){
        await feriadosService.inativarFeriado(feriado)
        toast('Feriado desativado.', { type: toast.TYPE.SUCCESS })
      }
      else{
        await feriadosService.ativarFeriado(feriado)
        toast('Feriado ativado.', { type: toast.TYPE.SUCCESS })
      }
      await getFeriadosCadastradosDoAno()
    } catch (e) {}
    setIsLoading(false)
  }

  const toggleIsOpen = (e) => {
    setIsOpen(!isOpen)
  }

  const toggleIsOpen1 = (e) => {
  }

  const handleChangeNome = (event) => {
    setNome(event.target.value)
  }

  const handleChangeData = (event) => {
    setData(event._d)
  }

  const handleChangeTipo = (event) => {
    setTipo(tiposFeriado.find((t) => t.value == event.value))
    
  }

  const handleChangeAno = async (event) => { 
    setIsLoading(true)
    try {
      const { data } = await feriadosService.getFeriadosCadastradosDoAno(event.value)
      setFeriadosCadastrados(data)
      setAnoCadastrado(event)
    }
    catch (e) {
      toast(`Feriados de ${e}`, { type: toast.TYPE.ERROR })
    }
    setIsLoading(false)
  }

  const tipoAnos = anosCadastrados.map((ano) => ({ label: ano, value: ano }))

  const tiposFeriado = [
    { value: '0', label: 'Nacional' },
    { value: '1', label: 'Estadual' },
    { value: '2', label: 'Municipal' },
    { value: '3', label: 'Facultativo' }
  ]

  const getOnlyDate = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate())
  }

  const renderCadastrar = () => (
    <div className='my-4'>
      <CollapsePanel stepCode='cadastrar' text='Formulário de Cadastro' isOpen={isOpen} toggleIsOpen={toggleIsOpen}>
        <Form>
          <Row className='my-3 align-items-center'>
            <Col>
              <Form.Group className='required'>
                <Form.Label>Nome do Feriado:</Form.Label>
                <Form.Control type='text' name='nome' value={nome} onChange={handleChangeNome} required />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='required'>
                <Form.Label>Data do Feriado:</Form.Label>
                <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br' required>
                  <KeyboardDatePicker
                    invalidDateMessage='Formato de data inválido'
                    id='data'
                    name='data'
                    className='form-control'
                    value={data}
                    onChange={handleChangeData}
                    variant='inline'
                    format='DD/MM/YYYY'
                    required
                    animateYearScrolling
                    disablePast
                    minDateMessage='Não é possível cadastrar feriados antigos'
                  />
                </MuiPickersUtilsProvider>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='required'>
                <Form.Label>Tipo do Feriado:</Form.Label>
                <Select
                  id='perfil'
                  name='perfil'
                  value={tipoFeriado}
                  options={tiposFeriado}
                  onChange={handleChangeTipo}
                  placeholder=''
                  noOptionsMessage={() => 'Perfil não encontrado'}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className='d-flex justify-content-end'>
            {(isEdit || isEdit === 0) && (
              <Button variant='outline-primary' className='mr-2' onClick={handleCancelEdit}>
                Cancelar
              </Button>
            )}
            <Button variant='primary' onClick={isEdit || isEdit === 0 ? handleSaveEdit : handleSaveAdd}>
              {isEdit || isEdit === 0 ? 'Editar' : 'Cadastrar'}
            </Button>
          </div>
        </Form>
      </CollapsePanel>
    </div>
  )

  const renderFeriadosCadastrados = () => (
    <Fragment>
      <div className='my-4'>
      <CollapsePanel stepCode='filtrar' text='Feriados Cadastrados' isOpen={true} toggleIsOpen={toggleIsOpen1}>
        <Form>
          <Row className='my-3 align-items-center'>
            <Col md='2'>
              <Form.Group>
                <Form.Label>Ano dos Feriados:</Form.Label>
                <Select
                  id='anos'
                  name='anos'
                  value={anoCadastrado}
                  options={tipoAnos}
                  onChange={handleChangeAno}
                  placeholder=''
                  noOptionsMessage={() => 'Feriados não encontrado'}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      

      {feriadosCadastrados.length > 0 ? (
        <Table bordered hover className='bg-white'>
          <thead>
            <tr>
              <th>Nome</th>
              <th>
                Data
              </th>
              <th>
                Tipo
              </th>
              <th width='110px' className='text-center'>
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            {feriadosCadastrados.map((feriado, index) => (

              <tr style={{ fontSize: 'small' }} key={index}>
                <td>{feriado.nome}</td>
                <td>{Moment(feriado.data).format('DD/MM/YYYY')}</td>
                <td>{tiposFeriado.find((x) => x.value == feriado.tipo).label}</td>
                <td className='text-center d-flex justify-content-around'>
                  <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Editar feriado</Tooltip>}>
                    <Button onClick={() => handleEdit(index)} variant='primary' disabled={feriado.franquiaId == null || getOnlyDate(new Date(feriado.data)) < getOnlyDate(new Date())} size='sm'>
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    trigger='hover'
                    placement='top'
                    overlay={<Tooltip>{feriado.ativo ? 'Desativar feriado' : 'Ativar feriado'}</Tooltip>}
                  >
                    <Button
                      onClick={() => handleToggleAtivo(feriado)}
                      variant={feriado.ativo ? 'success' : 'danger'}
                      size='sm'
                      disabled={feriado.franquiaId == null || getOnlyDate(new Date(feriado.data)) < getOnlyDate(new Date())}
                    >
                      <FontAwesomeIcon icon={faPowerOff} />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div>Nenhum feriado encontrado.</div>
      )}
      </CollapsePanel>
      </div>
    </Fragment>
  )

  return (
    <div>
      <h1 className='h3 m-0 text-secondary font-weight-bold'>Cadastrar Feriado</h1>
      {renderCadastrar()}
      
      {isLoading ? <PageLoading /> : renderFeriadosCadastrados()}
    </div>
  )
}

export default CadastrarFeriado

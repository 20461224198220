import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PerfilForm from '../../components/Perfis/PerfilForm'

const mapStateToProps = (store) => ({
	selectedPerfil: store.perfisState.selectedPerfil,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PerfilForm)

import React, { Fragment, useEffect } from 'react'
import { Table, Button } from 'react-bootstrap'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { formatMoney } from '../../../../utils/string.utils'
import { statusProcedimento, status } from '../../../../constants/common.constants'

export default function SelecaoProcedimentos({
  procedimentos,
  incluirProcedimento,
  planoTratamento,
  nextStep,
  onBack,
  selectedProcedimentos,
}) {
  const getSituacaoProcedimento = (procedimento) => {
    const situacao = status.find((s) => s.value === procedimento.statusProcedimento)
    return situacao ? situacao.label : ''
  }

  const renderProcedimentos = () => (
    <div className='w-100'>
      <h3 className='text-secondary font-weight-bold mb-4'>Cancelar Procedimento</h3>
      {procedimentos.length > 0 ? (
        <Table bordered hover>
          <thead>
            <tr>
              <th>Procedimento</th>
              <th width='100px'>Dente</th>
              <th width='200px'>Observação</th>
              <th className='text-right' width='150px'>
                Valor
              </th>
              <th width='150px' className='text-center'>
                Situação
              </th>
              <th width='100px' className='text-center'>
                Cancelar
              </th>
            </tr>
          </thead>
          <tbody>
            {procedimentos.map((p, index) => {
              const disabled =
                p.statusProcedimento === statusProcedimento.FINALIZADO.value ||
                p.statusProcedimento === statusProcedimento.CANCELADO.value ||
                p.statusProcedimento === statusProcedimento.CANCELADO_AUTOMATICAMENTE.value
              return (
                <Fragment key={p.procedimentoId + p.denteId + index}>
                  <tr key={index} style={disabled ? { backgroundColor: '#ececec' } : {}}>
                    <td>{p.procedimento ? p.procedimento.nome : '-'}</td>
                    <td>{p.dente ? p.dente.codigo : 'N/D'}</td>
                    <td>{p.descricao ? p.descricao : '-'}</td>
                    <td className='text-right'>{formatMoney(p.valor)}</td>
                    <td className='text-center'>{getSituacaoProcedimento(p)}</td>
                    <td className='text-center'>
                      {!disabled && (
                        <label className='checkbox labeless m-0'>
                          <input
                            type='checkbox'
                            onChange={() => incluirProcedimento(p.procedimentoId, p.denteId)}
                            checked={selectedProcedimentos.some(
                              (proc) => proc.procedimentoId === p.procedimentoId && proc.denteId === p.denteId
                            )}
                          />
                          <span />
                        </label>
                      )}
                    </td>
                  </tr>
                  {p.planoTratamentoProcedimentoProtetico && (
                    <tr
                      key={index + p.planoTratamentoProcedimentoProtetico.procedimentoProtetico.id}
                      style={disabled ? { backgroundColor: '#ececec' } : {}}
                    >
                      <td className='pl-3'>
                        <i className='fa fa-level-up-alt fa-rotate-90 mr-3 text-secondary' />
                        {p.planoTratamentoProcedimentoProtetico.procedimentoProtetico.nome}
                      </td>
                      <td>{'-'}</td>
                      <td>{'-'}</td>
                      <td className='text-right'>{formatMoney(p.planoTratamentoProcedimentoProtetico.valor)}</td>
                      <td className='text-center'>{getSituacaoProcedimento(p)}</td>
                      <td className='text-center'> - </td>
                    </tr>
                  )}
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <div>Nenhum procedimento encontrado</div>
      )}
    </div>
  )

  return (
    <div className='d-flex flex-column mb-5 pb-5'>
      {planoTratamento && renderProcedimentos()}
      <div className='d-flex flex-fill justify-content-between align-items-center'>
        <div style={backContainerStyle} onClick={onBack}>
          <Button variant='outline-primary' style={backStyle}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <span className='text-primary'>Voltar</span>
        </div>
        <div style={backContainerStyle} onClick={nextStep}>
          <span className='text-primary'>Próximo</span>
          <Button variant='outline-primary' style={backStyle}>
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>
      </div>
    </div>
  )
}

const backContainerStyle = {
  cursor: 'pointer',
}
const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
  marginLeft: '10px',
}

import AxiosClient from './axios-client'
import SystemConfig from '../constants/system.constants'

class ColaboradoresService {
 
  async salvarColaborador(colaborador) {
    if (!colaborador.id) {
      return AxiosClient.post('Colaborador/add', colaborador)
    } else {
      return await AxiosClient.put('Colaborador/update', colaborador)
    }
  }

  getColaboradorById(colaboradorId) {
    return AxiosClient.get(`Colaborador/getById/${colaboradorId}`)
  }

  getAllColaboradores() {
    return AxiosClient.get('Colaborador/getAll')
  }

  getAllRecepcionistas() {
    return AxiosClient.get('Colaborador/getAllRecepcionistas')
  }

  async getDentistas(atualizouAgenda = false) {
    let dentistas = []
    try {
      const { data } = await AxiosClient.get(`Colaborador/getDentistas/${atualizouAgenda}`)
      dentistas = data
    } catch (error) {
      console.log(error)
    }
    return dentistas
  }

  getAllExceptDentista(){
    return AxiosClient.get('Colaborador/getAllExceptDentista')
  }

  getAllCargos() {
    return AxiosClient.get(`Common/getCargos`)
  }

  getEspecialidadesByCargo(cargoId) {
    return AxiosClient.get(`Common/getEspecialidades/${cargoId}`)
  }

  getAllEspecialidades() {
    return AxiosClient.get('Common/getEspecialidades')
  }
  
  //Ignore Filtrs
  async getByCpf(cpf) {
    try{
      const { data } = await AxiosClient.get(`Colaborador/getByCpf/${cpf}`);
      return data;
    } catch(e){}
    
  }
  async getByCpfFranquiaId(cpf, franquiaId) {
    try{
      const { data } = await AxiosClient.get(`Colaborador/getByCpfFranquiaId/${cpf}/${franquiaId}`);
      return data;
    } catch(e){}
  }
}

export default new ColaboradoresService()

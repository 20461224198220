import React, { useState, useEffect, Fragment } from 'react'
import { Col, Row, Table, Button, Spinner, Form, Card } from 'react-bootstrap'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import Select from 'react-select'
import formatCPF from '@brazilian-utils/format-cpf'
import { format, startOfMonth } from 'date-fns'

import { formatMoney } from '../../utils/string.utils'
import { statusAvaliacao } from '../../constants/agendamento.constants'
import RelatorioService from '../../services/relatorio.service'
import SharedService from '../../services/shared.service'
import PageLoading from '../Common/PageLoading'
import Paginator from '../Common/Paginator'
import CSVDownload from './CSVDownload'

export default function AvaliacoesPendentes({ selectedRelatorio }) {
  const itensPorPagina = 20
  const statusOptions = [
    { value: statusAvaliacao.PENDENTE, label: 'Pendente' },
    { value: statusAvaliacao.EFETIVADA, label: 'Efetivada' },
    { value: statusAvaliacao.NAO_EFETIVADA, label: 'Não Efetivada' },
  ]

  const [isLoading, setLoading] = useState(false)
  const [isLoadingCSV, setLoadingCSV] = useState(false)
  const [itensLista, setItensLista] = useState([])
  const [cabecalhoRelatorio, setCabecalhoRelatorio] = useState([])
  const [dateFilterDe, setDateFilterDe] = useState(startOfMonth(new Date()))
  const [dateFilterAte, setDateFilterAte] = useState(new Date())
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0])
  const [selectedEspecialidade, setSelectedEspecialidade] = useState(null)
  const [especialidades, setEspecialidades] = useState([])
  const [pagina, setPagina] = useState(0)
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [itensCSV, setItensCSV] = useState([])
  const [downloadCSV, setDownloadCSV] = useState(false)
  const [resumo, setResumo] = useState([])

  useEffect(() => {
    setCabecalhoRelatorio([
      'Paciente',
      'Dependente',
      'CPF',
      'Telefone Principal',
      'WhatsApp',
      'E-mail',
      'Especialidade',
      'Dentista',
      'Valor',
      'Data Avaliação',
      'Data Efetivação',
    ])
  }, [])

  useEffect(() => {
    getAllEspecialidades()
  }, [])

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilterDe, dateFilterAte, pagina, selectedStatus, selectedEspecialidade])

  useEffect(() => {
    if (downloadCSV) {
      setTimeout(() => {
        setDownloadCSV(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itensCSV, downloadCSV])

  const getAllEspecialidades = async () => {
    try {
      const { data } = await SharedService.getAllEspecialidades()
      const todas = { value: null, label: 'Todas' }
      setEspecialidades([todas, ...data.map(({ id, nome }) => ({ value: id, label: nome }))])
      setSelectedEspecialidade(todas)
    } catch (error) {
      console.error(error)
    }
  }

  const getValuesLista = async () => {
    const totalDaLista = itensPorPagina * totalPaginas
    if (totalDaLista === 0) {
      return
    }
    setLoadingCSV(true)
    const filtros = []
    dateFilterDe && filtros.push({ campo: 'dataInicial', valor: dateFilterDe })
    dateFilterAte && filtros.push({ campo: 'dataFinal', valor: dateFilterAte })
    selectedStatus && filtros.push({ campo: 'status', valor: selectedStatus.value })
    selectedEspecialidade && filtros.push({ campo: 'especialidade', valor: selectedEspecialidade.value })
    const { lista } = await RelatorioService.getRegistrosAvaliacaoPendente(filtros, 1, totalDaLista)
    const valores = []
    lista.dados.forEach((item) => {
      const itemRelatorio = new Object()
      itemRelatorio.nome = item.nome
      itemRelatorio.nomeDependente = item.nomeDependente ? item.nomeDependente : '-'
      itemRelatorio.cpf = formatCPF(item.cpf)
      itemRelatorio.telefonePrincipal = item.telefonePrincipal
      itemRelatorio.telefonesWhatsApp = item.telefonesWhatsApp || '-'
      itemRelatorio.emails = item.emails || '-'
      itemRelatorio.especialidade = item.especialidade
      itemRelatorio.nomeDentista = item.nomeDentista || '-'
      itemRelatorio.valor = item.valorEfetivado ? formatMoney(item.valorEfetivado) : item.valor ? formatMoney(item.valor) : '-'
      itemRelatorio.dataAvaliacao = item.dataAvaliacao
        ? format(new Date(item.dataAvaliacao), 'dd/MM/yyyy - HH:mm')
        : '-'
      itemRelatorio.dataEfetivacao = item.dataEfetivacao
        ? format(new Date(item.dataEfetivacao), 'dd/MM/yyyy - HH:mm')
        : '-'
      valores.push(Object.values(itemRelatorio))
    })
    setItensCSV(valores)
    setDownloadCSV(true)
    setLoadingCSV(false)
    return {
      data: valores,
      headers: cabecalhoRelatorio,
      filename: `${selectedRelatorio.label} - ${format(new Date(), 'dd-MM-yyyy')}.csv`,
    }
  }

  const handleSearch = async () => {
    setLoading(true)
    try {
      const filtros = []
      dateFilterDe && filtros.push({ campo: 'dataInicial', valor: dateFilterDe })
      dateFilterAte && filtros.push({ campo: 'dataFinal', valor: dateFilterAte })
      selectedStatus && filtros.push({ campo: 'status', valor: selectedStatus.value })
      selectedEspecialidade && filtros.push({ campo: 'especialidade', valor: selectedEspecialidade.value })
      const { lista, resumo } = await RelatorioService.getRegistrosAvaliacaoPendente(
        filtros,
        pagina + 1,
        itensPorPagina
      )
      setItensLista(lista.dados)
      setTotalPaginas(lista.totalPaginas)
      setResumo(resumo)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  const handleClear = () => {
    setPagina(0)
    setDateFilterDe(null)
    setDateFilterAte(null)
    setSelectedStatus(statusOptions[0])
    setSelectedEspecialidade(especialidades[0])
  }

  const handleDateFilterDe = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterDe(filter)
    } else {
      setDateFilterDe(null)
    }
    setPagina(0)
  }

  const handleDateFilterAte = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterAte(filter)
    } else {
      setDateFilterAte(null)
    }
    setPagina(0)
  }

  const handleStatusChange = (option) => {
    setSelectedStatus(option)
    setPagina(0)
  }

  const handleEspecialidadeChange = (option) => {
    setSelectedEspecialidade(option)
    setPagina(0)
  }

  const handlePagina = (data) => setPagina(data.selected)

  const renderItens = () =>
    itensLista?.length > 0 ? (
      <Fragment>
        <Row className='my-4'>
          <Col md='10' />
          <Col md='2' className='text-right'>
            <CSVDownload asyncExportMethod={getValuesLista}>
              <Button variant='outline-secondary'>
                {isLoadingCSV ? <Spinner animation='border' size='sm' variant='light' /> : 'Gerar CSV'}
              </Button>
            </CSVDownload>
          </Col>
        </Row>

        <Card className='mb-4 py-2'>
          <Row>
            {resumo?.map(({ descricao, valor }, index) => (
              <Col key={index} className='d-flex align-items-center justify-content-center flex-column'>
                <small className='text-center text-muted m-0'>{!!descricao ? descricao.toUpperCase() : '-'}</small>
                <h4 className='text-center m-0 font-weight-bold'>{valor || '-'}</h4>
              </Col>
            ))}
          </Row>
        </Card>

        <Table hover size='sm' className='border rounded-0 shadow-sm' style={{backgroundColor:'white'}}>
          <thead>
            <tr className='text-center'>
              <th>Paciente</th>
              <th>Dependente</th>
              <th>CPF</th>
              <th>Telefone Principal</th>
              <th>WhatsApp</th>
              <th>E-mail</th>
              <th>Especialidade</th>
              <th>Dentista</th>
              <th>Valor</th>
              <th>Data Avaliação</th>
              <th>Data Efetivação</th>
            </tr>
          </thead>
          <tbody>
            {itensLista.map(
              (
                {
                  nome,
                  nomeDependente,
                  cpf,
                  telefonePrincipal,
                  telefonesWhatsApp,
                  emails,
                  especialidade,
                  dataAvaliacao,
                  protese,
                  nomeDentista,
                  dataEfetivacao,
                  valor,
                  valorEfetivado,
                  numeroPlano
                },
                index
              ) => (
                <tr
                  key={index}
                  className={!!protese ? 'text-center bg-warning-03' : 'text-center'}
                  style={{ fontSize: 'smaller' }}
                >
                  <td>{nome.toUpperCase()}</td>
                  <td>{nomeDependente ? nomeDependente.toUpperCase() : '-'}</td>
                  <td>{formatCPF(cpf)}</td>
                  <td>{telefonePrincipal}</td>
                  <td>{telefonesWhatsApp || '-'}</td>
                  <td>{emails || '-'}</td>
                  <td>{especialidade || '-'}<br />{numeroPlano ? numeroPlano : ''}</td>
                  <td>{nomeDentista || '-'}</td>
                  <td>{valorEfetivado ? formatMoney(valorEfetivado) : valor ? formatMoney(valor) : '-'}</td>
                  <td>
                    {dataAvaliacao ? (
                      <Fragment>
                        <span className='d-block'>{format(new Date(dataAvaliacao), 'dd/MM/yyyy')}</span>
                        <span className='d-block'>{format(new Date(dataAvaliacao), 'HH:mm')}</span>
                      </Fragment>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    {dataEfetivacao ? (
                      <Fragment>
                        <span className='d-block'>{format(new Date(dataEfetivacao), 'dd/MM/yyyy')}</span>
                        <span className='d-block'>{format(new Date(dataEfetivacao), 'HH:mm')}</span>
                      </Fragment>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
        {totalPaginas > 1 && <Paginator handlePagina={handlePagina} pageCount={totalPaginas} paginaAtual={pagina} />}
      </Fragment>
    ) : (
      <p className='mt-4'>Nenhum registro encontrado</p>
    )

  const renderLoading = () => <PageLoading />

  return (
    <Fragment>
      <Row>
        <Col md='3'>
          <Form.Group>
            <Form.Label>Data Inicial</Form.Label>
            <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
              <KeyboardDatePicker
                invalidDateMessage='Formato de data inválido'
                value={dateFilterDe}
                onChange={handleDateFilterDe}
                className='form-control'
                variant='inline'
                format='DD/MM/YYYY'
                maxDate={dateFilterAte || new Date('2100-01-01')}
                animateYearScrolling
                autoOk
                placeholder='De'
              />
            </MuiPickersUtilsProvider>
          </Form.Group>
        </Col>
        <Col md='3'>
          <Form.Group>
            <Form.Label>Data Final</Form.Label>
            <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
              <KeyboardDatePicker
                invalidDateMessage='Formato de data inválido'
                value={dateFilterAte}
                onChange={handleDateFilterAte}
                minDate={dateFilterDe || new Date('1900-01-01')}
                className='form-control'
                variant='inline'
                format='DD/MM/YYYY'
                animateYearScrolling
                autoOk
                placeholder='Até'
              />
            </MuiPickersUtilsProvider>
          </Form.Group>
        </Col>
        <Col md='3'>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Select
              placeholder='Selecione...'
              noOptionsMessage={() => 'Nenhuma opção disponível'}
              value={selectedStatus}
              options={statusOptions}
              onChange={handleStatusChange}
            />
          </Form.Group>
        </Col>
        <Col md='3'>
          <Form.Group>
            <Form.Label>Especialidade</Form.Label>
            <Select
              placeholder='Selecione...'
              noOptionsMessage={() => 'Nenhuma opção disponível'}
              value={selectedEspecialidade}
              options={especialidades}
              onChange={handleEspecialidadeChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Button variant='outline-danger' className='d-block ml-auto' onClick={handleClear}>
        Limpar
      </Button>
      {isLoading ? renderLoading() : renderItens()}
    </Fragment>
  )
}

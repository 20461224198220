import React, { useState, useEffect, Fragment } from 'react'
import { Badge, Col, Row, Table, Button, Spinner, Card } from 'react-bootstrap'
import Select from 'react-select'
import formatCPF from '@brazilian-utils/format-cpf'
import { format, startOfMonth, subDays, isFirstDayOfMonth } from 'date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import colaboradoresService from '../../services/colaboradores.service'
import RelatorioService from '../../services/relatorio.service'
import { statusPlanoTratamento } from '../../constants/common.constants'
import { formatMoney } from '../../utils/string.utils'
import PageLoading from '../Common/PageLoading'
import Paginator from '../Common/Paginator'
import CSVDownload from './CSVDownload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

export default function PacientesDevedores({ selectedRelatorio }) {
  const itensPorPagina = 20

  const [isLoading, setLoading] = useState(false)
  const [isLoadingCSV, setLoadingCSV] = useState(false)
  const [itensLista, setItensLista] = useState([])
  const [cabecalhoRelatorio, setCabecalhoRelatorio] = useState([])
  const [pagina, setPagina] = useState(0)
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [itensCSV, setItensCSV] = useState([])
  const [downloadCSV, setDownloadCSV] = useState(false)
  const [selectedEspecialidade, setSelectedEspecialidade] = useState(null)
  const [selectedIntegracao, setSelectedIntegracao] = useState(null)
  const [selectedParceiroFinanceiro, setSelectedParceiroFinanceiro] = useState(null)
  const [especialidadeOptions, setEspecialidadeOptions] = useState([])
  const [filterLoading, setFilterLoading] = useState(false)
  const [resumo, setResumo] = useState(null)
  const [isOpenHistoricoTelefone, setIsOpenHistoricoTelefone] = useState('')


  useEffect(() => {
    didMount()
    setCabecalhoRelatorio([
      'Nome',
      'CPF',
      'Telefone Principal',
      'Whats App',
      'E-mail',
      'Nº Plano',
      'Status',
      'Vencimento',
      'Quantidade Parcelas',
      'Total Devido',
    ])
  }, [])

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina])

  useEffect(() => {
    if (downloadCSV) {
      setTimeout(() => {
        setDownloadCSV(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itensCSV, downloadCSV])

  const didMount = async () => {
    setFilterLoading(true)
    const { data } = await colaboradoresService.getAllEspecialidades()
    setEspecialidadeOptions(
      data.map(({ nome, id }) => ({
        label: nome,
        value: id,
      }))
    )
    setFilterLoading(false)
  }

  const getValuesLista = async () => {
    const totalDaLista = itensPorPagina * totalPaginas
    if (totalDaLista === 0) {
      return
    }
    const filtros = []
    if (selectedEspecialidade) {
      filtros.push({ campo: 'especialidadeId', valor: selectedEspecialidade.value })
    }

    setLoadingCSV(true)
    const { lista, resumo } = await RelatorioService.getRegistrosDevedores(filtros, 1, totalDaLista)

    const valores = []
    lista.dados.forEach((item) => {

      if (valores.filter(valorJaAdicionado => valorJaAdicionado.cpf === formatCPF(item.cpf)).length > 0)
        return;

      const itemRelatorio = relatorioItem(item)

      if (item.telefones && item.telefones.split(',').filter(t => t.telefonePrincipal).length > 0) {
        const telefone = item.telefones.split(',').find(t => t.telefonePrincipal);
        itemRelatorio.telefonePrincipal = telefone;
      }

      valores.push(Object.values(itemRelatorio))
    })

    setItensCSV(valores)
    setDownloadCSV(true)
    setLoadingCSV(false)
    return {
      data: valores,
      headers: cabecalhoRelatorio,
      filename: `${selectedRelatorio.label} - ${format(new Date(), 'dd-MM-yyyy')}.csv`,
    }
  }

  const relatorioItem = (item) => {

    const statusPlano = statusPlanoTratamento.find((s) => s.value === item.statusTratamento)
    const itemRelatorio = new Object()
    itemRelatorio.nome = item.nomePaciente
    itemRelatorio.cpf = formatCPF(item.cpf)
    itemRelatorio.telefonePrincipal = item.telefonePrincipal
    itemRelatorio.telefonesWhatsApp = item.telefonesWhatsApp
    itemRelatorio.emails = item.emails
    itemRelatorio.numeroPlano = item.numeroPlano
    statusPlano ? (itemRelatorio.statusTratamento = statusPlano.label) : (itemRelatorio.statusTratamento = '-')
    itemRelatorio.vencimentoPrimeiraParcelaVencida = format(
      new Date(item.vencimentoPrimeiraParcelaVencida),
      'dd/MM/yyyy'
    )
    itemRelatorio.quantidadeParcelas = item.quantidadeParcelas || '-'
    itemRelatorio.valorTotalParcelas = item.valorTotalParcelas ? formatMoney(item.valorTotalParcelas) : '-'

    return itemRelatorio;

  }

  const handleSearch = async () => {
    setLoading(true)
    try {
      const filtros = []

      selectedEspecialidade && filtros.push({ campo: 'especialidadeId', valor: selectedEspecialidade.value })
      selectedIntegracao && filtros.push({ campo: 'integrados', valor: selectedIntegracao.value })
      selectedParceiroFinanceiro && filtros.push({ campo: 'comParceiro', valor: selectedParceiroFinanceiro.value })

      const { lista, resumo } = await RelatorioService.getRegistrosDevedores(filtros, pagina + 1, itensPorPagina)
      setItensLista(lista.dados)
      setTotalPaginas(lista.totalPaginas)
      setResumo(resumo)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  const handleClear = () => {
    setSelectedEspecialidade(null)
    setSelectedIntegracao(null)
    setSelectedParceiroFinanceiro(null)
    setPagina(0)
  }

  const handleChangeEspecialidade = (option) => {
    setSelectedEspecialidade(option)
    setPagina(0)
  }

  const handleChangeIntegracao = (option) => {
    setSelectedIntegracao(option)
    setPagina(0)
  }

  const handleChangeParceiroFinanceiro = (option) => {
    setSelectedParceiroFinanceiro(option)
    setPagina(0)
  }
  const handlePagina = (data) => setPagina(data.selected)

  const [isOpen, setIsOpen] = useState('')

  const handleOpen = (item) => {
    if (isOpenHistoricoTelefone === item)
      setIsOpenHistoricoTelefone('')
    else
      setIsOpenHistoricoTelefone(item)

    isOpen === item ? setIsOpen('') : setIsOpen(item)
  }

  const renderItens = () =>
    itensLista?.length > 0 ? (
      <Fragment>
        <div className='mt-4 d-flex justify-content-end align-items-center'>
          <CSVDownload asyncExportMethod={getValuesLista}>
            <Button variant='outline-secondary' className='mb-3'>
              {isLoadingCSV ? <Spinner animation='border' size='sm' variant='light' /> : 'Gerar CSV'}
            </Button>
          </CSVDownload>
        </div>

        <Card className='mb-4 py-2'>
          <Row>
            {resumo &&
              resumo.map(({ descricao, valor }, index) => (
                <Col key={index} className='d-flex align-items-center justify-content-center flex-column'>
                  <small className='text-center text-muted m-0'>{!!descricao ? descricao.toUpperCase() : '-'}</small>
                  <h4 className='text-center m-0 font-weight-bold'>{!!valor ? formatMoney(valor) : formatMoney(0)}</h4>
                </Col>
              ))}
          </Row>
        </Card>

        <Table hover size='sm' className='border rounded-0 shadow-sm' style={{ backgroundColor: 'white' }}>
          <thead>
            <tr className='text-center'>
              <th width='150px' className='text-left'>Nome</th>
              <th width='90px'>CPF</th>
              <th width='155px'>Telefone Principal</th>
              <th width='100px'>WhatsApp</th>
              <th width='150px'>E-mail</th>
              <th width='80px'>Nº Plano</th>
              <th width='90px'>Status</th>
              <th width='70px'>Vencimento</th>
              <th width='70px'>Quantidade Parcelas</th>
              <th width='80px'>Total Devido</th>
            </tr>
          </thead>
          <tbody>
            {itensLista.map((item, index) => {
              const {
                nomePaciente,
                cpf,
                telefonePrincipal,
                telefonesWhatsApp,
                emails,
                numeroPlano,
                statusTratamento,
                vencimentoPrimeiraParcelaVencida,
                quantidadeParcelas,
                valorTotalParcelas,
                telefones
              } = item
              const statusPlano = statusPlanoTratamento.find((s) => s.value === statusTratamento)

              const quantidadeTelefones = telefones ? telefones.split(',').length : 0

              const expand = index === isOpen

              return (
                <Fragment key={index}>
                  <tr key={index} className='text-center' style={{ fontSize: 'smaller' }}>
                    <td className='text-left'>{nomePaciente.toUpperCase()}</td>
                    <td>{formatCPF(cpf)}</td>
                    <td>{telefonePrincipal}
                      {telefones && (
                        <Badge pill variant="primary" style={{ padding: '5px 10px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => handleOpen(index)}> + {quantidadeTelefones}  <FontAwesomeIcon style={{ marginLeft: '8px' }} icon={isOpenHistoricoTelefone === index ? faChevronUp : faChevronDown} /></Badge>
                      )}
                    </td>
                    <td>{telefonesWhatsApp}</td>
                    <td>{emails}</td>
                    <td>{numeroPlano || '-'}</td>
                    <td>{statusPlano ? statusPlano.label : '-'}</td>
                    <td>{format(new Date(vencimentoPrimeiraParcelaVencida), 'dd/MM/yyyy') || '-'}</td>
                    <td>{quantidadeParcelas || '-'}</td>
                    <td>{valorTotalParcelas ? formatMoney(valorTotalParcelas) : '-'}</td>
                  </tr>


                  {expand && (

                    telefones.split(',').map((x) => {
                      return (
                        <tr className={'text-center' + expand ? null : 'd-none'} style={{ fontSize: 'smaller' }}>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none', marginLeft: '5px', fontSize: '11px' }} className='text-center'>{x}</td>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none' }}></td>
                          <td style={{ border: 'none' }}></td>
                        </tr>
                      )
                    })
                  )}

                </Fragment>
              )
            })}
          </tbody>
        </Table>
        {totalPaginas > 1 && <Paginator handlePagina={handlePagina} pageCount={totalPaginas} paginaAtual={pagina} />}
      </Fragment>
    ) : (
      <div className='mt-4'>Nenhum registro encontrado</div>
    )



  const renderLoading = () => <PageLoading />

  return (
    <Fragment>
      <Row>
        <Col md='3'>
          <label>Especialidade</label>
          <Select
            placeholder='Especialidade'
            className='w-100 mr-1'
            onChange={handleChangeEspecialidade}
            value={selectedEspecialidade}
            options={especialidadeOptions}
          />
        </Col>
        <Col md='3'>
          <label>Integrado Financeiro</label>
          <Select
            placeholder='Integrado Financeiro'
            className='w-100 mr-1'
            onChange={handleChangeIntegracao}
            value={selectedIntegracao}
            options={[
              {
                label: "Todos",
                value: "",
              },
              {
                label: "Sim",
                value: "sim",
              },
              {
                label: "Não",
                value: "não",
              }
            ]}
          />
        </Col>
        <Col md='3'>
          <label>Parceiro Financeiro</label>
          <Select
            placeholder='Com Parceiro Financeiro'
            className='w-100 mr-1'
            onChange={handleChangeParceiroFinanceiro}
            value={selectedParceiroFinanceiro}
            options={[
              {
                label: "Todos",
                value: "",
              },
              {
                label: "Sim",
                value: "sim",
              },
              {
                label: "Não",
                value: "não",
              }
            ]}
          />
        </Col>
        <Col>
          {filterLoading && <Spinner animation='border' variant='primary' size='sm' style={spinnerStyle} />}
          <Button disabled={filterLoading} onClick={handleClear} variant='outline-danger' className='mr-2' style={buttonCleanSearch}>
            Limpar
          </Button>
          {filterLoading && <Spinner animation='border' variant='primary' size='sm' />}
          <Button disabled={filterLoading} onClick={handleSearch} variant='primary' className='mr-2' style={buttonSearch}>
            Pesquisar
          </Button>
        </Col>
      </Row>
      {isLoading ? renderLoading() : renderItens()}
    </Fragment>
  )
}

const spinnerStyle = {
  position: 'absolute',
  top: '10px',
  right: '50px',
}

const buttonCleanSearch = {
  position: 'absolute',
  bottom: '0',
  height: '38px'
}

const buttonSearch = {
  position: 'absolute',
  bottom: '0',
  left: '100px',
  height: '38px'
}
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Spinner, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import draftToHtml from 'draftjs-to-html'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { colors } from '../../constants/color.constants'
import { MENUS, TAGS } from '../../constants/template.constants'
import TemplatesService from '../../services/templates.service'

const TemplateEdit = (props) => {
  const getContentFromTemplate = (template) => {
    if (!template || !template.conteudo) {
      return EditorState.createEmpty()
    }
    return EditorState.createWithContent(convertFromRaw(JSON.parse(template.conteudo)))
  }

  const { selectedTemplate } = props
  const [editorState, setEditorState] = useState(getContentFromTemplate(selectedTemplate))
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [contratanteAnchor, setContratanteAnchor] = useState(null)
  const [pacienteAnchor, setPacienteAnchor] = useState(null)
  const [contratadaAnchor, setContratadaAnchor] = useState(null)
  const [outrosAnchor, setOutrosAnchor] = useState(null)

  // This effect will redirect to templates list if there's not a selectedTemplate
  useEffect(() => {
    !selectedTemplate && props.history.push('/templates')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // This effect will update the editor when selectedTemplate changes
  useEffect(() => {
    setEditorState(getContentFromTemplate(selectedTemplate))
  }, [selectedTemplate])

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState)
    setHasUnsavedChanges(true)
  }

  const handleAddTag = (tag) => {
    const selectionState = editorState.getSelection()
    const start = selectionState.getStartOffset()
    const end = selectionState.getEndOffset()
    const currentBlockKey = editorState.getSelection().getStartKey()
    const currentBlockIndex = editorState
      .getCurrentContent()
      .getBlockMap()
      .keySeq()
      .findIndex((k) => k === currentBlockKey)

    const rawContent = convertToRaw(editorState.getCurrentContent())
    const { text } = rawContent.blocks[currentBlockIndex]
    rawContent.blocks[currentBlockIndex].text = [text.slice(0, start), tag, text.slice(end)].join('')

    const { inlineStyleRanges } = rawContent.blocks[currentBlockIndex]
    const newInlineStyles = []
    inlineStyleRanges.forEach((style) => {
      const { offset, length } = style
      if (start + tag.length > offset && start < offset + length) {
        newInlineStyles.push({
          ...style,
          length: start - offset,
        })
        newInlineStyles.push({
          ...style,
          offset: start + tag.length,
          length: length - (start - offset),
        })
      } else if (start <= offset) {
        newInlineStyles.push({
          ...style,
          offset: style.offset + tag.length,
        })
      } else {
        newInlineStyles.push(style)
      }
    })
    rawContent.blocks[currentBlockIndex].inlineStyleRanges = newInlineStyles
    const content = convertFromRaw(rawContent)
    setEditorState(EditorState.createWithContent(content))
    setHasUnsavedChanges(true)
  }

  const handleClose = async () => {
    let confirmValue = true
    if (hasUnsavedChanges) {
      const { value } = await Swal.fire({
        title: `Deseja fechar a tela sem salvar as alterações feitas?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Fechar',
        confirmButtonColor: '#fff',
        confirmButtonClass: 'text-primary',
        cancelButtonText: 'Não Fechar',
        cancelButtonColor: '#3085d6',
      })
      confirmValue = value
    }
    if (confirmValue) {
      props.resetTemplate()
      props.history.push('/templates')
    }
  }

  const handleSaveTemplate = async () => {
    setIsLoading(true)
    try {
      await TemplatesService.salvarTemplate({
        ...selectedTemplate,
        conteudo: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      })
      setHasUnsavedChanges(false)
      props.resetTemplate()
      props.history.push('/templates')
      toast('Template salvo com sucesso.', { type: toast.TYPE.SUCCESS })
    } catch (e) {
      toast('Não foi possível salvar o template.', { type: toast.TYPE.ERROR })
    }
    setIsLoading(false)
  }

  const openMenu = (event, menu) => {
    switch (menu) {
      case MENUS.CONTRATANTE:
        setContratanteAnchor(event.target)
        break
      case MENUS.PACIENTE:
        setPacienteAnchor(event.target)
        break
      case MENUS.CONTRATADA:
        setContratadaAnchor(event.target)
        break
      case MENUS.OUTROS:
        setOutrosAnchor(event.target)
        break
      default:
        return null
    }
  }

  const getAnchorElement = (menu) => {
    switch (menu) {
      case MENUS.CONTRATANTE:
        return contratanteAnchor
      case MENUS.PACIENTE:
        return pacienteAnchor
      case MENUS.CONTRATADA:
        return contratadaAnchor
      case MENUS.OUTROS:
        return outrosAnchor
      default:
        return null
    }
  }

  const closeMenus = () => {
    setContratanteAnchor(null)
    setPacienteAnchor(null)
    setContratadaAnchor(null)
    setOutrosAnchor(null)
  }

  const handleMenuItemClick = (tag) => {
    handleAddTag(tag)
    closeMenus()
  }

  const renderEditor = () => {
    return (
      <Editor
        wrapperClassName='wysiwig-editor-wrapper'
        editorClassName='form-control'
        editorStyle={{ height: 'calc(100vh - 300px)' }}
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        toolbar={{
          link: { className: 'd-none' },
          emoji: { className: 'd-none' },
          image: { className: 'd-none' },
          embedded: { className: 'd-none' },
          colorPicker: { colors: colors.reverse() },
        }}
        toolbarCustomButtons={[
          <Row className='d-flex flex-fill justify-content-between'>
            {Object.keys(MENUS).map((menu) => (
              <Col key={menu}>
                <Button className='w-100 border' variant='outline-primary' onClick={(event) => openMenu(event, menu)}>
                  {menu}
                </Button>
              </Col>
            ))}
          </Row>,
          <Fragment>
            {Object.keys(MENUS).map((menu) => (
              <Menu
                key={menu}
                anchorEl={getAnchorElement(menu)}
                open={!!getAnchorElement(menu)}
                onClose={closeMenus}
                keepMounted
              >
                {Object.keys(TAGS[menu]).map((menuItem) => (
                  <MenuItem key={menuItem} onClick={() => handleMenuItemClick(TAGS[menu][menuItem])}>
                    {menuItem}
                  </MenuItem>
                ))}
              </Menu>
            ))}
          </Fragment>,
        ]}
      />
    )
  }

  return (
    <div>
      <h1 className='h3 mb-4 text-secondary font-weight-bold'>
        Template{!!selectedTemplate && ' - ' + selectedTemplate.nome}
      </h1>
      <div className='mb-4'>{selectedTemplate ? renderEditor() : 'Nenhum template selecionado.'}</div>
      <div className='d-flex justify-content-center'>
        <Button variant='outline-danger' className='mr-2' onClick={handleClose}>
          Cancelar
        </Button>
        {selectedTemplate && (
          <Button variant='primary' onClick={handleSaveTemplate}>
            {!isLoading ? 'Salvar Template' : <Spinner animation='border' size='sm' variant='light' />}
          </Button>
        )}
      </div>
    </div>
  )
}

export default TemplateEdit

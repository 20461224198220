import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import Chat from './Chat'
import HeaderLogo from '../../Assets/logo.png'
import SystemConstants from '../../constants/system.constants'
import franquiasService from '../../services/franquias.service'
import Select from 'react-select'
import { TIPO_USUARIO } from '../../constants/usuario.constants'
import accountService from '../../services/account.service'
import { useDispatch } from 'react-redux'
import { Account, Perfis } from '../../services/types'
import JwtUtils from '../../utils/jwt.utils'
import Swal from 'sweetalert2'

import axios from 'axios'

const Header = (props) => {
  const [franquiaSelected, setFranquiaSelected] = useState(localStorage.getItem(SystemConstants.getFranquia()))
  const [podeAlternarConta, setPodeAlternarConta] = useState(false)
  const [franquias, setFranquias] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    if (JwtUtils.isTokenExpired(localStorage.getItem(SystemConstants.getTokenKey()))) {
      Swal.fire('Sessão expirou!', 'Sua sessão expirou, é necessário efetuar o login novamente.', 'error').then(() => {
        localStorage.removeItem(SystemConstants.getTokenKey())
      })
    }
    getFranquias()

    let user = JSON.parse(localStorage.getItem(SystemConstants.getUser()))

    if (user) setPodeAlternarConta(user.podeAlternarConta)
  }, [])

  const getFranquias = async () => {
    try {
      const { data } = await franquiasService.getFranquias()

      var options = data
        ? data.map((item) => ({
            value: item.id,
            label: item.razaoSocial ?? item.nomeFantasia,
          }))
        : []

      setFranquias(options)
    } catch (e) {}
  }

  const redirectToLogout = () => {
    props.history.push('/logout')
  }

  const redirectToPasswordRecovery = () => {
    props.history.push('/senha')
  }

  const redirectEdicaoProtetico = () => {
    props.history.push('/protetico/editar')
  }

  const alternarConta = async () => {
    const { data } = await accountService.alternarConta()
    localStorage.setItem(SystemConstants.getTokenKey(), data.token.accessToken)
    localStorage.setItem(SystemConstants.getUser(), JSON.stringify(data.token))
    dispatch({ type: Account.SET_USER, user: data })

    if (props.tipoUsuario == TIPO_USUARIO.Franqueador) props.history.push('/dashboard/geral')
    else props.history.push('/franquias')

    dispatch({ type: Perfis.REQUEST_MENU })
    window.location.reload()
  }

  const onChangeFranquia = async (e) => {
    if (e.value !== franquiaSelected) {
      setFranquiaSelected(e.value)
      localStorage.setItem(SystemConstants.getFranquia(), e.value)

      var path = window.location.pathname
      var reload = true

      if (props.tipoUsuario != TIPO_USUARIO.Protetico) {
        const { data } = await accountService.hasPermission(e.value, path)
        reload = data
      }

      if (reload) {
        let config = { headers: {} }
        config.headers.Authorization = 'Bearer ' + localStorage.getItem(SystemConstants.getTokenKey())

        axios
          .put('Account/refreshToken/' + localStorage.getItem(SystemConstants.getFranquia()), undefined, config)
          .then((resp) => {
            if (resp.data.token) {
              localStorage.setItem(SystemConstants.getTokenKey(), resp.data.token.accessToken)
              localStorage.setItem(SystemConstants.getUser(), JSON.stringify(resp.data.token))
            } else {
              localStorage.removeItem(SystemConstants.getTokenKey())
              localStorage.removeItem(SystemConstants.getTokenKey())
              localStorage.removeItem(SystemConstants.getUser())
              localStorage.removeItem(SystemConstants.getFranquia())
              localStorage.removeItem(SystemConstants.getChatStatus())
              localStorage.removeItem(SystemConstants.getChatUserId())
              localStorage.removeItem(SystemConstants.getChatTokenKey())
              localStorage.removeItem(SystemConstants.getAtualizarAgenda())
              window.location.href = '/login?status=sessionexpired'
            }
            window.location.reload()
          })
      } else {
        window.location.href = window.location.origin
      }
    }
  }

  let agrupamento = 1
  const franquiasOptions = franquias ?? []

  const franquia = franquiasOptions.find((s) => s.value === localStorage.getItem(SystemConstants.getFranquia()))
  return (
    <Navbar bg='primary' variant='dark' expand='true' fixed='top'>
      {props.tipoUsuario == TIPO_USUARIO.Franqueador && (
        <Link to='/franquias' className='navbar-brand text-left'>
          <img src={HeaderLogo} alt='CredOdonto' />
        </Link>
      )}

      {props.tipoUsuario != TIPO_USUARIO.Franqueador && (
        <Link to='/' className='navbar-brand text-left'>
          <img src={HeaderLogo} alt='CredOdonto' />
        </Link>
      )}

      <Form.Group className='col-md-2  mr-auto' style={{ height: '20px' }}>
        {franquiasOptions && franquiasOptions.length > 1 && (
          <Select
            placeholder='Franquias'
            options={franquiasOptions}
            onChange={onChangeFranquia}
            defaultValue={franquia}
          />
        )}
      </Form.Group>

      <Chat {...props} />

      {props.menus.length != 0 && (
        <Dropdown>
          <Dropdown.Toggle variant='outline-light'>
            <FontAwesomeIcon icon={faCog} className='mr-1' />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {props.menus.map((menu) => {
              if (agrupamento == menu.agrupamento) {
                return <Dropdown.Item href={menu.caminho}>{menu.nome}</Dropdown.Item>
              } else {
                agrupamento = agrupamento + 1
                return [<Dropdown.Divider />, <Dropdown.Item href={menu.caminho}>{menu.nome}</Dropdown.Item>]
              }
            })}
          </Dropdown.Menu>
        </Dropdown>
      )}
      <Dropdown>
        <Dropdown.Toggle variant='outline-light'>
          <FontAwesomeIcon icon={!podeAlternarConta ? faUser : ''} className='mr-1' /> {props.nome}
          {podeAlternarConta && (
            <>
              <div>
                <small>{props.tipoUsuario == TIPO_USUARIO.Franqueador ? 'Franqueador' : 'Clínicas'} </small>
              </div>
            </>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {podeAlternarConta && (
            <Dropdown.Item onClick={alternarConta}>
              {props.tipoUsuario == TIPO_USUARIO.Franqueador ? 'Alterar para Clínicas' : 'Alterar para Franqueador'}
            </Dropdown.Item>
          )}

          {props.tipoUsuario == TIPO_USUARIO.Protetico && (
            <Dropdown.Item onClick={redirectEdicaoProtetico}>Editar</Dropdown.Item>
          )}
          <Dropdown.Item onClick={redirectToPasswordRecovery}>Mudar Senha</Dropdown.Item>
          <Dropdown.Item onClick={redirectToLogout}>Sair</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Navbar>
  )
}

export default withRouter(Header)

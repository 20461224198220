import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import formatCpf from '@brazilian-utils/format-cpf'

import Filtros from './Filtros'
import ModalData from '../../../components/ModalData'
import ModalDetalhesAgendamento from '../../../components/ModalDetalhesAgendamento'
import Calendario from '../../../components/Common/Calendario'
import AgendamentoService from '../../../services/agendamentos.service'
import ColaboradoresService from '../../../services/colaboradores.service'
import ParametrosService from '../../../services/parametros.service'
import FranquiaService from '../../../services/franquias.service'
import SystemConstants from '../../../constants/system.constants'
import PageLoading from '../../../components/Common/PageLoading'
import { Spinner } from 'react-bootstrap'
import { LISTAR_AGENDAMENTOS } from '../../../services/reducers/agendamentos.reducer'
import { addDaysToDate } from '../../../utils/time.utils'
import agendaDentistaService from '../../../services/agendaDentista.service'
import Swal from 'sweetalert2'
import { withRouter } from 'react-router-dom'

const AgendamentoClinicoGeral = (props) => {
  const {
    agendamentos,
    getById,
    resetAgendamento,
    resetPaciente,
    selectedAgendamento,
    getPacienteByCpf,
    selectedPaciente,
    salvarStatusAgendamento,
    concluirAtendimento,
    isLoadingAgendamento,
    idDentista,
    requestAgendamentos,
    resetRequestAgendamentos,
  } = props

  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)
  const [diasFranquia, setDiasFranquia] = useState([])
  const [agendamento, setAgendamento] = useState({})
  const [showModalData, setShowModalData] = useState(false)
  const [showModalDetalhes, setShowModalDetalhes] = useState(false)
  const [params, setParams] = useState({})
  const [selectedEspecialidade, setSelectedEspecialidade] = useState(null)
  const [selectedColaborador, setSelectedColaborador] = useState(null)
  const [pacienteFilterOptions, setPacienteFilterOptions] = useState([])
  const [selectedPacienteFilter, setSelectedPacienteFilter] = useState(null)
  const [diasTrabalhados, setDiasTrabalhados] = useState([])

  const hourStep = params.TempoDeDuracaoDaConsultaEmMinutos
  let dateStep = params.DiasSubsequentesParaAgendamento
  if (!dateStep) {
    dateStep = 7
  }

  let agendamentosEspecialidade = []

  if (selectedEspecialidade) {
    agendamentosEspecialidade = agendamentos.filter(
      (agendamento) => agendamento.especialidade.id === selectedEspecialidade.id
    )
  }

  const today = new Date(new Date().setHours(0, 0, 0, 0))
  let endDate = addDaysToDate(today, dateStep + 2) // A agenda pula fins de semana, então add + 2 para ter a quantidade de dias sendo dias úteis

  const quantidadeDiasTrabalhados = diasTrabalhados.length
  const diasBloqueados = diasTrabalhados.filter((dia) => dia.bloqueio)

  if (quantidadeDiasTrabalhados > 0) {
    const quantidadeDiasBloqueados = diasBloqueados.length
    const fatorDiasComplemento = quantidadeDiasTrabalhados % dateStep
    const diasComplemento =
      quantidadeDiasTrabalhados < dateStep ? dateStep - quantidadeDiasTrabalhados : dateStep - fatorDiasComplemento
    let diasAcrescimo = 0
    if (fatorDiasComplemento !== 0) {
      diasAcrescimo = diasComplemento + 2
    }
    endDate = addDaysToDate(new Date(diasTrabalhados[diasTrabalhados.length - 1].data), diasAcrescimo)
  }

  let ultimoDiaTrabalhado = new Date()
  let primeiroDiaTrabalhado = new Date()

  if (diasTrabalhados && diasTrabalhados.length > 0) {
    primeiroDiaTrabalhado = new Date(diasTrabalhados.find((d) => d.bloqueio === false).data)
    ultimoDiaTrabalhado = new Date(diasTrabalhados[diasTrabalhados.length - 1].data)
  }

  useEffect(() => {
    didMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const newOptions = []
    if (agendamentosEspecialidade) {
      agendamentosEspecialidade.forEach((agend) => {
        const pacienteAlreadyIncluded = newOptions.some((opt) => opt.value === agend.cpfPaciente)
        // const pacienteAlreadyIncluded = false
        !pacienteAlreadyIncluded &&
          newOptions.push({
            value: agend.cpfPaciente,
            label: `${formatCpf(agend.cpfPaciente)} - ${agend.nomePaciente}`,
            nome: agend.nomePaciente,
            cpf: agend.cpfPaciente,
            pacienteId: agend.pacienteId,
          })
      })
    }
    setPacienteFilterOptions(newOptions)
  }, [agendamentos])

  useEffect(() => {
    if (!showModalData) {
      setAgendamento({})
      carregarAgendamentos()
      resetAgendamento()
      resetPaciente()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalData])

  useEffect(() => {
    if (!showModalDetalhes) {
      setAgendamento({})
      carregarAgendamentos()
      resetAgendamento()
      resetPaciente()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalDetalhes])

  useEffect(() => {
    carregarAgendamentos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColaborador, selectedEspecialidade, selectedPacienteFilter])

  useEffect(() => {
    if (requestAgendamentos) {
      carregarAgendamentos()
      resetRequestAgendamentos()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestAgendamentos])

  const carregarAgendamentos = async () => {
    if (!selectedColaborador && !selectedEspecialidade) {
      return
    }

    try {
      const filtros = []
      filtros.push({ campo: 'dataInicial', valor: today.toISOString() })
      filtros.push({ campo: 'dataFinal', valor: endDate.toISOString() })
      filtros.push({ campo: 'especialidadeId', valor: selectedEspecialidade.id })
      filtros.push({ campo: 'colaboradorId', valor: selectedColaborador.id })

      if (selectedPacienteFilter) {
        filtros.push({ campo: 'pacienteId', valor: selectedPacienteFilter.pacienteId })
      }

      const filtro = { filtros }
      const agendamentos = await AgendamentoService.getAgendamentos(filtro)

      dispatchAgendamentos(agendamentos)
    } catch (error) {
      console.log(error)
    }
  }

  const dispatchAgendamentos = (agendamentos) =>
    dispatch({
      type: LISTAR_AGENDAMENTOS,
      agendamentos,
    })

  const didMount = async () => {
    setIsLoading(true)
    await Promise.all([
      fetchParams(),
      getDiasFuncionamentoFranquia(),
      fetchEspecialidade(),
      fetchDentista(),
      fetchAgenda(),
    ])
    setIsLoading(false)
  }

  const fetchParams = async () => {
    let newParams = { ...params }
    const { data } = await ParametrosService.getAll()
    data.map((param) => {
      newParams[`${param.nome}`] = param.valor
      return true
    })
    setParams(newParams)
  }

  const getDiasFuncionamentoFranquia = async () => {
    const franquiaId = localStorage.getItem(SystemConstants.getFranquia())
    try {
      const { data } = await FranquiaService.getFranquia(franquiaId)
      setDiasFranquia(data.horariosFuncionamento)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchEspecialidade = async () => {
    try {
      const { data } = await ColaboradoresService.getAllEspecialidades()
      const especialidadesFiltradas = data.filter(
        (especialidade) => especialidade.nome.toLowerCase() === 'clínico geral'
      )
      const especialidadeOrtodontia = especialidadesFiltradas.length > 0 ? especialidadesFiltradas[0] : null
      setSelectedEspecialidade(especialidadeOrtodontia)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchDentista = async () => {
    try {
      const { data } = await ColaboradoresService.getColaboradorById(idDentista)
      setSelectedColaborador(data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAgenda = async () => {
    try {
      const { data } = await agendaDentistaService.getAgendaClinicoGeral(idDentista)
      setDiasTrabalhados(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleshowModalData = async (agendamento) => {
    if (isDiaBloqueado(agendamento.date)) {
      const { value } = await Swal.fire({
        title: 'Atenção!',
        text: 'Este dia está bloqueado para agendamentos.',
        type: 'warning',
        cancelButtonColor: '#999999',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Entendi',
      })
      return
    }

    setAgendamento(agendamento)
    toggleModalData()
  }

  const handleshowModalDetalhes = (agendamentoSelecionado) => {
    if (agendamentoSelecionado.event && agendamentoSelecionado.event.id) {
      getById(agendamentoSelecionado.event.id)
      toggleModalDetalhes()
    }
  }

  const toggleModalData = () => {
    setShowModalData(!showModalData)
    setShowModalDetalhes(!showModalDetalhes)
  }

  const toggleModalDetalhes = () => setShowModalDetalhes(!showModalDetalhes)

  const selecionarEspecialidade = (novaEspecialidade) => {
    setSelectedColaborador(null)
    setSelectedEspecialidade(novaEspecialidade)
  }

  const selecionarColaborador = (selectedColaborador) => setSelectedColaborador(selectedColaborador)

  const isDiaBloqueado = (data) => {
    let dateBK = new Date(data)
    dateBK.setHours(0, 0, 0, 0)

    const diasFiltrados = diasTrabalhados.filter((dia) => {
      let dataDia = new Date(dia.data)
      dataDia.setHours(0, 0, 0, 0)

      return dataDia.getTime() === dateBK.getTime()
    })

    if (diasFiltrados.length === 0) {
      return true
    }

    if (diasFiltrados.length > 0) {
      if (diasFiltrados[0].bloqueio) {
        return true
      }
    }

    return false
  }

  const dayRender = (dayRenderInfo) => {
    if (isDiaBloqueado(dayRenderInfo.date)) {
      dayRenderInfo.el.classList.add('disabled')
    }
  }

  const validRange = {
    start: today,
    end: endDate,
  }

  const filterProps = {
    ...props,
    selectedEspecialidade,
    selectedColaborador,
    pacienteFilterOptions,
    selectedPacienteFilter,
    setSelectedPacienteFilter,
    setSelectedEspecialidade: selecionarEspecialidade,
    setSelectedColaborador: selecionarColaborador,
    startDate: today,
    endDate,
  }

  if (dateStep) {
    dateStep = parseInt(dateStep) + 2 // A agenda pula fins de semana, então add + 2 para ter a quantidade de dias sendo dias úteis
  }

  const renderLoading = () => <PageLoading />

  return (
    <div className='pb-3'>
      {isLoading ? (
        renderLoading()
      ) : (
        <Fragment>
          <h1 className='h3 mb-4 text-secondary font-weight-bold'>{`Agenda Clínico Geral - ${selectedColaborador.nome}`}</h1>
          <Filtros {...filterProps} />
          {isLoadingAgendamento && (
            <div className='d-flex align-items-center justify-content-end mb-n4'>
              <span className='text-primary mr-3'> Carregando Agendamentos...</span>
              <Spinner animation='border' size='sm' variant='primary' />
            </div>
          )}
          <Calendario
            isLoading={isLoadingAgendamento}
            diasFranquia={diasFranquia}
            events={agendamentosEspecialidade}
            dateStep={dateStep}
            dateClick={handleshowModalData}
            eventClick={handleshowModalDetalhes}
            hourStep={hourStep}
            defaultDate={new Date()}
            eventClassName='text-white item-agendamento d-flex align-items-center rounded-0'
            header={{ left: 'prev next', center: 'title', right: '' }}
            firstDay={diasFranquia.length > 0 ? diasFranquia[0].diaSemana : moment().weekday()}
            titleFormat={{ month: 'long', day: 'numeric' }}
            validRange={validRange}
            dayRender={dayRender}
          />
        </Fragment>
      )}
      {showModalData && (
        <ModalData
          {...props}
          schedule={selectedAgendamento.id ? selectedAgendamento : agendamento}
          dateStep={dateStep}
          isOpen={showModalData}
          selectedPaciente={selectedPaciente}
          handleClose={toggleModalData}
          getPacienteByCpf={getPacienteByCpf}
          especialidade={selectedEspecialidade}
          dentista={selectedColaborador}
          startDate={primeiroDiaTrabalhado}
          endDate={ultimoDiaTrabalhado}
          excludeDates={diasBloqueados}
          diasFranquia={diasFranquia}
        />
      )}
      {selectedAgendamento.id && showModalDetalhes && (
        <ModalDetalhesAgendamento
          history={props.history}
          editarPaciente={props.editarPaciente}
          agendamento={selectedAgendamento}
          salvarStatusAgendamento={salvarStatusAgendamento}
          isOpen={showModalDetalhes}
          handleClose={toggleModalDetalhes}
          editar={toggleModalData}
          concluirAtendimento={concluirAtendimento}
          mostrarSelect={true}
        />
      )}
    </div>
  )
}

export default withRouter(AgendamentoClinicoGeral)

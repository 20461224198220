import React, {useState, useEffect, useContext} from 'react'
import { Table, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { format, isAfter } from 'date-fns'
import Swal from 'sweetalert2'
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FinanceiroService from '../../../services/financeiro.service'
import { formatMoney } from '../../../utils/string.utils'
import { statusPagamento } from '../../../constants/relatorio.constants'
import { toast } from 'react-toastify'
import PermissoesConstants from '../../../constants/permissoes.constants'
import MenuContext from '../../../contexts/MenuContexts'

export default function ListaContas({ itensLista, setIsLoading, update, editarConta }) {
  const [acoesPermitidas, setAcoesPermitidas] = useState({})
  const acoes = useContext(MenuContext)
 
  useEffect(() => {
    if (!acoes || acoes.length == 0) {
      return
    }
    let permissoes = {}
    acoes.map((a) => {
      permissoes[`${a.nome.toUpperCase()}`] = a
      return true
    })
    setAcoesPermitidas(permissoes)
  },[acoes])

  const handleStatusChange = async (item) => {
    try {
      const status = item.status === statusPagamento.PAGO ? statusPagamento.PENDENTE : statusPagamento.PAGO

      if((status === statusPagamento.PAGO && !acoesPermitidas['MARCARCOMOPAGO']) ||
        (status !== statusPagamento.PAGO && !acoesPermitidas['DESMARCARCOMOPAGO'])){
        Swal.fire({
          title: 'Operação não realizada',
          text: `É necessário permissão para ${status == statusPagamento.PAGO ? 'marcar' : 'desmarcar'} como pago. Contate o administrador do sistema`,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Entendi'})
      }
      else{
        const frase = `Deseja mesmo mudar o status deste pagamento para ${
          status === statusPagamento.PAGO ? 'Pago' : 'Pendente'
        }?`
        const { value } = await Swal.fire({
          title: frase,
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#dbdbdb',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirmar',
        })
        setIsLoading(true)
        value && (await FinanceiroService.alterarStatusConta(item.id, status))
        update()
        toast(`Status alterado com sucesso!`, { type: toast.TYPE.SUCCESS })
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const handleDelete = async (e, conta) => {
    try{
      await Swal.fire({
        title: 'Deseja excluir esta conta?',
        text: `Tem certeza que deseja excluir esta conta permanentemente?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar'
      }).then(async (result) => {
        if (!result.value) {
          return
        }
        else
        {
          await FinanceiroService.excluirConta(conta)
          update()

          toast(`Conta Excluída com sucesso!`, { type: toast.TYPE.SUCCESS })
        }
      })
    } catch(error){
      console.error(error)
    }
  }

  const handleAlterar = (e, conta) => {
    editarConta(conta)
  }

  

  return (
    <Table size='sm' className='bg-white' bordered hover>
      <thead>
        <tr>
          {acoesPermitidas && acoesPermitidas['EXCLUIR'] && (
            <th className='text-center' width='38px' ></th>
          )}
          <th>Descrição</th>
          <th width='200px' className='text-center'>
            Grupo
          </th>
          <th width='200px' className='text-center'>
            SubGrupo/Colaborador
          </th>
          <th width='120px' className='text-center'>
            Lançado em
          </th>
          <th width='120px' className='text-center'>
            Vencimento
          </th>
          <th width='150px' className='text-center'>
            Valor
          </th>
          <th width='100px' className='text-center'>
            Conta Paga
          </th>
        </tr>
      </thead>
      <tbody>
        {itensLista.map((item, i) => {
          const { 
            id,
            valor, 
            descricao,
            grupo = item.grupoContasPagarId ? item.grupoContasPagar.nome : '-', 
            subGrupo = item.subGrupoContasPagarId ? item.subGrupoContasPagar.nome : '' , 
            dataLancamento, 
            dataVencimento, 
            status,
            colaborador = item.colaboradorId? item.colaborador.nome : ''
          } = item
          return (
            <tr key={i}>
              {acoesPermitidas && acoesPermitidas['EXCLUIR'] && (
                <td>
                  <Button variant='danger' size='sm' style={{border:'none'}} onClick={((e) => handleDelete(e, item))}><FontAwesomeIcon icon={faTrashAlt} style={{height: ''}}/></Button>                
                </td>
              )}
              <td>{descricao || '-'}</td>
              <td className='text-center'>{grupo || '-'}</td>
              <td className='text-center'>{(item.subGrupoContasPagar && item.subGrupoContasPagar.nome)  || (item.colaborador && item.colaborador.nome) || '-'}</td>
              <td className='text-center'>{format(new Date(dataLancamento), 'dd/MM/yyyy') || '-'}</td>
              <td
                className={
                  'text-center' + (isAfter(new Date(), new Date(dataVencimento)) ? ' text-danger font-weight-bold' : '')
                }
              >
                {format(new Date(dataVencimento), 'dd/MM/yyyy') || '-'}
              </td>
              <td className='text-center'>{valor ? formatMoney(valor) : '-'}</td>
              <td className='text-center'>
                <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>{`Marcar como pago`}</Tooltip>}>
                  <label className='checkbox labeless m-0'>
                    <input
                      type='checkbox'
                      checked={status === statusPagamento.PAGO}
                      onChange={() => handleStatusChange(item)}
                    />
                    <span />
                  </label>
                </OverlayTrigger>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
    )
}

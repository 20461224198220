import React, { useState, useEffect, Fragment } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import Select from 'react-select'
import { useDispatch } from 'react-redux'

import AgendamentoService from '../../services/agendamentos.service'
import { LISTAR_AGENDAMENTOS } from '../../services/reducers/agendamentos.reducer'
import { formatBackendDate } from '../../utils/time.utils'

export default function Filtros(props) {
  const dispatch = useDispatch()
  const {
    pacienteFilterOptions,
    selectedPacienteFilter,
    selectedEspecialidade,
    startDate,
    endDate,
    selectedColaborador,
    setSelectedColaborador,
  } = props

  const dispatchAgendamentos = (agendamentos) =>
    dispatch({
      type: LISTAR_AGENDAMENTOS,
      agendamentos,
    })

  const clear = async () => {
    props.setSelectedPacienteFilter(null)
    setSelectedColaborador(null)
  }

  const setDentista = (selectedColaborador) => {
    setSelectedColaborador(selectedColaborador)
    props.setSelectedColaborador(selectedColaborador)
  }

  const setPaciente = async (paciente) => {
    props.setSelectedPacienteFilter(paciente)
  }

  const getDentistasOptions = () => {
    const { colaboradores } = props
    if (selectedEspecialidade) {
      return colaboradores.filter((colaborador) => {
        const idEspecialidades = colaborador.especialidades.map((especialidade) => especialidade.especialidadeId)
        return idEspecialidades.includes(selectedEspecialidade.id)
      })
    } else {
      return colaboradores
    }
  }

  return (
    <Fragment>
      <Form.Label>Filtrar por:</Form.Label>
      <Row className='mb-5 d-flex align-baseline'>
        <Col md={4}>
          <Select
            placeholder='Dentista'
            onChange={setDentista}
            value={selectedColaborador}
            options={getDentistasOptions()}
            noOptionsMessage={() => 'Nenhum dentista cadastrado para esta especialidade'}
          />
        </Col>
        <Col md={4}>
          <Select
            placeholder='Paciente'
            value={selectedPacienteFilter}
            onChange={setPaciente}
            options={pacienteFilterOptions}
            noOptionsMessage={() => 'Nenhum paciente agendado'}
          />
        </Col>
        <Col md={3} />
        <Col md={1}>
          <Button onClick={clear} variant='danger'>
            Limpar
          </Button>
        </Col>
      </Row>
    </Fragment>
  )
}

import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Container, Button, Spinner } from 'react-bootstrap'

import ortodontiaService from '../../../services/ortodontia.service'
import { printParcelasReceipt } from '../../Templates/PrintableReceipt'
import { TIPOS_TEMPLATE } from '../../../constants/template.constants'

export default function Impressao({ avaliacao, pagamentoEfetuado, history }) {
  const [isPrintLoading, setIsPrintLoading] = useState(false)

  const handleImprimirTemplate = async (template) => {
    setIsPrintLoading(true)
    await ortodontiaService.downloadTemplateAsPDF(pagamentoEfetuado.planoId, template)
    setIsPrintLoading(false)
  }

  const handleImprimirParcelamento = () => {
    const { paciente, dependente } = avaliacao
    const { planoPagamento, pagamentoAparelho, numeroPlano } = pagamentoEfetuado
    printParcelasReceipt({
      numeroPlano,
      nomeContratante: paciente.nome,
      nomePaciente: dependente ? dependente.nome : paciente.nome,
      parcelas: planoPagamento.parcelas,
      valorTotal: planoPagamento.valorTotal,
      type: 'plano',
    })

    if (pagamentoAparelho) {
      printParcelasReceipt({
        numeroPlano,
        nomeContratante: paciente.nome,
        nomePaciente: dependente ? dependente.nome : paciente.nome,
        parcelas: pagamentoAparelho.parcelas,
        valorTotal: pagamentoAparelho.valorTotal,
        type: 'aparelho',
      })
    }
  }

  return (
    <Container className='text-center pb-5'>
      <div className='mb-5'>
        <FontAwesomeIcon
          style={{
            fontSize: '11em',
          }}
          className='text-success mt-5 mb-3'
          icon={faCheckCircle}
        />
        <h2 style={fontStyle} className='text-success'>
          Avaliação realizada com sucesso!
        </h2>
      </div>
      <div>{isPrintLoading && <Spinner animation='border' size='sm' variant='primary' />}</div>
      <div className='align-items-center d-flex justify-content-center'>
        <Button
          disabled={isPrintLoading}
          className='mr-3'
          variant='primary'
          onClick={() => handleImprimirTemplate(TIPOS_TEMPLATE.Contrato)}
        >
          Imprimir Contrato
        </Button>
        <Button
          disabled={isPrintLoading}
          className='mr-3'
          variant='primary'
          onClick={() => handleImprimirTemplate(TIPOS_TEMPLATE.TermoDeConsentimentoDeTratamento)}
        >
          Imprimir Termo de Consentimento
        </Button>
        <Button
          disabled={isPrintLoading}
          className='mr-3'
          variant='primary'
          onClick={() => handleImprimirTemplate(TIPOS_TEMPLATE.RespostasAnamnese)}
        >
          Imprimir Anamnese
        </Button>
        <Button
          disabled={isPrintLoading}
          variant='primary'
          className={isPrintLoading ? 'mr-3' : ''}
          onClick={handleImprimirParcelamento}
        >
          Imprimir Parcelamento
        </Button>
      </div>
      <Button
        className='mt-3'
        variant='outline-primary'
        disabled={isPrintLoading}
        onClick={() => history.push('/agendamento/ortodontia')}
      >
        Voltar
      </Button>
      <div className='alert alert-warning mt-5 mx-auto w-50'>
        <strong>Pop-ups</strong> bloquados no seu navegador, <strong>AdBlock</strong> habilitado para esta página, ou
        algumas <strong>extensões</strong> e <strong>add-ons</strong> instaladas podem ocasionar em problemas ao tentar
        imprimir os documentos desejados
      </div>
    </Container>
  )
}

const fontStyle = {
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
}

import React, { Fragment, useState, useRef, useEffect } from 'react'
import StepWizard from 'react-step-wizard'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'

import SelecaoProcedimentos from './SelecaoProcedimentos'
import Financeiro from './Financeiro'
import Impressao from './Impressao'
import { statusProcedimento } from '../../../../constants/common.constants'
import PlanoTratamentoService from '../../../../services/planoTratamento.service'
import { formatMoney } from '../../../../utils/string.utils'

export default function CancelarProcedimentos({ planoTratamento, firstStep, franquia, isLoading, setIsLoading }) {
  const [procedimentos, setProcedimentos] = useState([])
  const [selectedProcedimentos, setSelectedProcedimentos] = useState([])
  const [pagamento, setPagamento] = useState({})
  const [wizardRef, setWizard] = useState(useRef())

  useEffect(() => {
    if (planoTratamento && planoTratamento.id) {
      setProcedimentos(planoTratamento.procedimentos)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planoTratamento])

  const toggleProcedimento = (procedimentoId, denteId) => {
    if (selectedProcedimentos.some((p) => p.procedimentoId === procedimentoId && p.denteId === denteId)) {
      setSelectedProcedimentos(
        selectedProcedimentos.filter((p) => !(p.procedimentoId === procedimentoId && p.denteId === denteId))
      )
    } else {
      const procedimento = procedimentos.find((p) => p.procedimentoId === procedimentoId && p.denteId === denteId)
      setSelectedProcedimentos([...selectedProcedimentos, procedimento])
    }
  }

  const salvarPagamento = async (novoPagamento) => {
    try {
      setPagamento(novoPagamento)
      const { data } = await PlanoTratamentoService.cancelarProcedimentos(
        planoTratamento.id,
        selectedProcedimentos,
        novoPagamento.valorTotal > 0 ? novoPagamento : null
      )
      if (data) {
        setIsLoading(false)
        await Swal.fire(
          'Procedimentos cancelados com sucesso!',
          `Este cancelamento gerou ${formatMoney(data)} de crédito para o paciente.`,
          'success'
        )
      } else {
        toast('Procedimentos cancelados com sucesso', { type: toast.TYPE.SUCCESS })
      }
      wizardRef.nextStep()
    } catch (error) {
      console.error(error)
    }
  }

  const getProcedimentosRestantes = () =>
    procedimentos.filter(
      (p) =>
        p.statusProcedimento !== statusProcedimento.ABANDONO.value &&
        p.statusProcedimento !== statusProcedimento.CANCELADO_AUTOMATICAMENTE.value &&
        p.statusProcedimento !== statusProcedimento.CANCELADO.value &&
        !selectedProcedimentos.some((proc) => proc.procedimentoId === p.procedimentoId && proc.denteId === p.denteId)
    )

  const handleBack = () => {
    firstStep()
    setProcedimentos([])
    wizardRef.firstStep()
  }

  return (
    <Fragment>
      <StepWizard isLazyMount instance={setWizard}>
        <SelecaoProcedimentos
          isLoading={isLoading}
          onBack={firstStep}
          procedimentos={procedimentos}
          selectedProcedimentos={selectedProcedimentos}
          planoTratamento={planoTratamento}
          incluirProcedimento={toggleProcedimento}
        />
        <Financeiro
          salvarPagamento={salvarPagamento}
          procedimentosSelecionados={getProcedimentosRestantes()}
          planoTratamento={planoTratamento}
          setIsLoading={setIsLoading}
        />
        <Impressao
          planoTratamento={planoTratamento}
          pagamento={pagamento}
          onBack={handleBack}
          franquia={franquia}
          procedimentos={procedimentos.filter((p) => p.statusProcedimento !== statusProcedimento.CANCELADO.value)}
        />
      </StepWizard>
    </Fragment>
  )
}

import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

class Base extends React.Component {
  constructor(props) {
    super(props)
    const { authenticated, requestMenu, isProtetico } = props
    if (authenticated && !isProtetico) {
      requestMenu()
    }
  }

  render() {
    return (
      <div style={{ backgroundColor: '#FAFAFA', minHeight: '100vh' }}>
        {this.props.loading ? (
          <div className='text-center pt-5'>
            <Spinner className='mt-5' animation='grow' variant='primary' />
          </div>
        ) : (
          <div className='h-100'>{this.props.children}</div>
        )}
      </div>
    )
  }
}

export default Base

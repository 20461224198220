import { Perfis } from '../types'

export const requestPerfis = () => ({ type: Perfis.REQUEST_PERFIS })

export const requestMenu = () => ({ type: Perfis.REQUEST_MENU })

export const requestFuncionalidades = () => ({ type: Perfis.REQUEST_FUNCIONALIDADES })

export const requestAcoes = (menuId) => ({ type: Perfis.REQUEST_ACOES, menuId })

export const editarPerfil = (perfilId) => ({ type: Perfis.EDITAR_PERFIL, perfilId })

export const salvarPerfil = (perfil) => ({ type: Perfis.SALVAR_PERFIL, perfil })

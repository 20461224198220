import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

import PageLoading from '../Common/PageLoading'
import MenuContext from '../../contexts/MenuContexts'
import PermissoesConstants from '../../constants/permissoes.constants'
import PerfilService from '../../services/perfil.service'

class Perfis extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      perfis: [],
    }
  }

  componentDidMount() {
    this.fetchPerfis()
  }

  fetchPerfis = () => {
    this.setState({ ...this.state, isLoading: true }, async () => {
      try {
        const { data } = await PerfilService.getPerfis()
        this.setState({ ...this.state, perfis: data, isLoading: false })
      } catch (e) {
        this.setState({ ...this.state, isLoading: false })
      }
    })
  }

  renderLoading = () => <PageLoading />

  renderList = (acoesPermitidas) => {
    const { perfis } = this.state
    return (
      <Fragment>
        {perfis.length > 0 ? (
          <Table bordered hover  className='bg-white'>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Descrição</th>
                {acoesPermitidas[PermissoesConstants.EDITAR] && (
                  <th className='text-center' width='80px'>
                    Ações
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {perfis.map((perfil, index) => (
                <tr key={index}>
                  <td>{perfil.nome}</td>
                  <td>{perfil.descricao}</td>
                  {acoesPermitidas[PermissoesConstants.EDITAR] && (
                    <td className='text-center'>
                      <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Editar Perfil</Tooltip>}>
                        <Link
                          to={`/perfis/cadastrar`}
                          onClick={() => this.props.editarPerfil(perfil.id)}
                          className='btn btn-primary btn-sm'
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </Link>
                      </OverlayTrigger>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          'Nenhum perfil Cadastrado.'
        )}
      </Fragment>
    )
  }

  render() {
    const { isLoading } = this.state
    let acoesPermitidas = {}
    return (
      <MenuContext.Consumer>
        {(acoes) => {
          acoes.map((a) => {
            acoesPermitidas[`${a.nome}`] = a
            return true
          })
          return (
            <div>
              <Row className='mb-4'>
                <Col>
                  <h1 className='h3 m-0 text-secondary font-weight-bold'>Perfis</h1>
                </Col>
                <Col>
                  {acoesPermitidas[PermissoesConstants.ADICIONAR] && (
                    <Link to='/perfis/cadastrar'>
                      <Button onClick={() => this.props.editarPerfil()} className='float-right'>
                        Cadastrar Perfil
                      </Button>
                    </Link>
                  )}
                </Col>
              </Row>

              {isLoading ? this.renderLoading() : this.renderList(acoesPermitidas)}
            </div>
          )
        }}
      </MenuContext.Consumer>
    )
  }
}

export default Perfis

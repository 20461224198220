import React, { Fragment } from 'react'
import { Table, Button } from 'react-bootstrap'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { formatMoney } from '../../../../utils/string.utils'
import { statusProcedimento, status } from '../../../../constants/common.constants'
import { toast } from 'react-toastify'

export default function SelecaoProcedimentos({ procedimentos, planoTratamento, nextStep, onBack, setProcedimento }) {
  const getSituacaoProcedimento = (procedimento) => {
    const situacao = status.find((s) => s.value === procedimento.statusProcedimento)
    return situacao ? situacao.label : ''
  }

  const selectProcedimento = (procedimentoId, disabled) => {
    if (disabled) {
      toast(`Um procedimento já finalizado ou cancelado não pode ser alterado`, { type: toast.TYPE.ERROR })
      return
    } else {
      setProcedimento(procedimentoId)
      nextStep()
    }
  }

  const renderProcedimentos = () => (
    <div className='w-100'>
      <h2 className='text-secondary font-weight-bold mb-4'>Plano de Tratamento - {planoTratamento.numeroPlano}</h2>
      {procedimentos.length > 0 ? (
        <Table bordered size='sm'>
          <thead>
            <tr>
              <th>Procedimento</th>
              <th>Observação</th>
              <th className='text-right' width='150px'>
                Valor
              </th>
              <th width='150px' className='text-center'>
                Situação
              </th>
            </tr>
          </thead>
          <tbody>
            {procedimentos.map((p, i) => {
              const disabled =
                p.statusProcedimento === statusProcedimento.FINALIZADO.value ||
                p.statusProcedimento === statusProcedimento.CANCELADO.value ||
                p.statusProcedimento === statusProcedimento.CANCELADO_AUTOMATICAMENTE.value
              return (
                <Fragment key={p.procedimentoId + i}>
                  <tr key={i} style={disabled ? { backgroundColor: '#ececec' } : {}}>
                    <td>{p.procedimento ? p.procedimento.nome : ''}</td>
                    <td>{p.descricao ? p.descricao : '-'}</td>
                    <td className='text-right'>{formatMoney(p.valor)}</td>
                    <td className='text-center'>{getSituacaoProcedimento(p)}</td>
                  </tr>
                  {p.planoTratamentoProcedimentoProtetico && (
                    <tr
                      className='cursor-pointer'
                      onClick={() => selectProcedimento(p, disabled)}
                      key={i + p.planoTratamentoProcedimentoProtetico.procedimentoProtetico.nome}
                      style={disabled ? { backgroundColor: '#ececec' } : {}}
                    >
                      <td className='pl-3'>
                        <i className='fa fa-level-up-alt fa-rotate-90 mr-3 text-secondary' />
                        {p.planoTratamentoProcedimentoProtetico.procedimentoProtetico.nome}
                      </td>
                      <td>{'-'}</td>
                      <td className='text-right'>{formatMoney(p.planoTratamentoProcedimentoProtetico.valor)}</td>
                      <td className='text-center'>{getSituacaoProcedimento(p)}</td>
                    </tr>
                  )}
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <div>Nenhum procedimento encontrado</div>
      )}
    </div>
  )

  return (
    <div className='d-flex flex-column mb-5 pb-5'>
      {planoTratamento && renderProcedimentos()}
      <div className='d-flex flex-fill justify-content-between align-items-center'>
        <div style={backContainerStyle} onClick={onBack}>
          <Button variant='outline-primary' style={backStyle}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <span className='text-primary'>Voltar</span>
        </div>
        <div style={backContainerStyle}></div>
      </div>
    </div>
  )
}

const backContainerStyle = {
  cursor: 'pointer',
}
const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
  marginLeft: '10px',
}

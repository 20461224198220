import React from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Perfis, Account } from '../../services/types'
import { stopServer } from '../../websocketServer'

import SystemConstants from "../../constants/system.constants";

const Logout = (props) => {
  localStorage.removeItem(SystemConstants.getTokenKey());
  localStorage.removeItem(SystemConstants.getTokenKey());
  localStorage.removeItem(SystemConstants.getUser());
  localStorage.removeItem(SystemConstants.getFranquia());
  localStorage.removeItem(SystemConstants.getChatStatus());
  localStorage.removeItem(SystemConstants.getChatUserId());
  localStorage.removeItem(SystemConstants.getChatTokenKey());
  localStorage.removeItem(SystemConstants.getAtualizarAgenda());
  localStorage.clear();

  const dispatch = useDispatch()
  dispatch({ type: Perfis.LISTAR_MENU, menu: [] })
  dispatch({ type: Account.LOGOUT })
  stopServer()

  return <Redirect to='/login' />
}

export default Logout

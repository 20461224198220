import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import moment from 'moment'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck, faTrash } from '@fortawesome/free-solid-svg-icons'

import AgendamentoService from '../../services/agendamentos.service'
import AtendimentoService from '../../services/atendimento.service'
import WebcamFaceDetection from '../WebcamFaceDetection'

export default function AvaliacoesPendentes({
  avaliacoesPendentesList,
  getAvaliacoesPendentesList,
  concluirAtendimento,
  setIsLoading,
  history,
  paciente
}) {

  const [solicitarFacial, setSolicitarFacial] = useState(false);
  const [pacienteId, setPacienteId] = useState(null);
  const [avaliacao, setAvaliacao] = useState({});

  useEffect(() => { }, [pacienteId, avaliacao]);

  const handleConcluir = async (avaliacao) => {
    setAvaliacao(avaliacao);
    setSolicitarFacial(true);
    setPacienteId(paciente.id);
  }

  const handleStep = async () => {
    setSolicitarFacial(false)
    setIsLoading(true)
    try {
      const { data } = await AgendamentoService.getById(avaliacao.agendamentoId)
      concluirAtendimento(data)
      data.avaliacaoOrtodontia
        ? history.push('/ortodontia/avaliacao/recepcao')
        : history.push('/atendimento-financeiro')
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleCancelar = async (avaliacao) => {
    const { value } = await Swal.fire({
      title: 'Tem certeza que deseja cancelar esta avaliação?',
      type: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#dbdbdb',
      cancelButtonText: 'Não Cancelar',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Cancelar',
    })
    if (value) {
      setIsLoading(true)
      try {
        await AtendimentoService.cancelarAvaliacao(avaliacao.agendamentoId)
        await getAvaliacoesPendentesList()
      } catch (e) {
        console.error(e)
      }
      setIsLoading(false)
    }
  }

  return (
    <>
      {solicitarFacial ?
        <WebcamFaceDetection
          pacienteId={pacienteId}
          cancelWebcamFaceDetection={() => setSolicitarFacial(false)}
          closeWebcamFaceDetection={() => handleStep(false)}
        />
        :
        <Table className='mt-3' bordered hover>
          <thead>
            <tr className='text-center'>
              <th>Paciente</th>
              <th>Responsável</th>
              <th>Descrição</th>
              <th width='120px'>Data</th>
              <th width='100px'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {avaliacoesPendentesList.map((avaliacao, index) => {
              const { nomeDependente, nomePaciente, nomeResponsavel, descricao, dataCriacao } = avaliacao
              return (
                <tr key={index} className='text-center'>
                  <td>{nomeDependente || nomePaciente}</td>
                  <td>{nomeResponsavel}</td>
                  <td>{descricao || '-'}</td>
                  <td>{moment(dataCriacao).format('DD/MM/YYYY')}</td>
                  <td className='text-center d-flex justify-content-around'>
                    <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Concluir Atendimento</Tooltip>}>
                      <Button onClick={() => handleConcluir(avaliacao)} variant='primary' size='sm'>
                        <FontAwesomeIcon icon={faCalendarCheck} />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Cancelar Atendimento</Tooltip>}>
                      <Button onClick={() => handleCancelar(avaliacao)} variant='danger' size='sm'>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      }
    </>
  )
}

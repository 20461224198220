export const LISTAR_PACIENTES = 'LISTAR_PACIENTES'

export const REQUEST_PACIENTES = 'REQUEST_PACIENTES'

export const SALVAR_PACIENTE = 'SALVAR_PACIENTE'
export const SET_PACIENTE = 'SET_PACIENTE'
export const RESET_PACIENTE = 'RESET_PACIENTE'
export const EDITAR_PACIENTE = 'EDITAR_PACIENTE'
export const INATIVAR_PACIENTE = 'INATIVAR_PACIENTE'

export const GET_PACIENTE_ID = 'GET_PACIENTE_ID'
export const GET_PACIENTE_CPF = 'GET_PACIENTE_CPF'

export const SET_PLANOS_TRATAMENTO = 'SET_PLANOS_TRATAMENTO'
export const REQUEST_PLANOS_TRATAMENTO = 'REQUEST_PLANOS_TRATAMENTO'
export const RESET_PLANOS_TRATAMENTO = 'RESET_PLANOS_TRATAMENTO'
export const SALVAR_PLANO_TRATAMENTO = 'SALVAR_PLANO_TRATAMENTO'

import React, { useState, Fragment, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'

import Parcelamentos from './Parcelamentos'
import CollapsePanel from '../../Common/CollapsePanel'

const AbasParcelamentos = (props) => {
  const { procedimentosSelecionados } = props
  const INITIAL_OPEN_STATE = { step0: false }

  const [procedimentos, setProcedimentos] = useState([])
  const [proteticos, setProteticos] = useState([])
  const [stepIsOpen, setStepIsOpen] = useState({ ...INITIAL_OPEN_STATE })

  useEffect(() => {
    setProcedimentos(procedimentosSelecionados.filter((p) => !p.procedimento.protetico))
    setProteticos(
      procedimentosSelecionados
        .filter((p) => p.avaliacaoProcedimentoProtetico)
        .map((p) => p.avaliacaoProcedimentoProtetico)
    )
  }, [procedimentosSelecionados])

  const toggleIsOpen = (step) => {
    let newSteps = { ...INITIAL_OPEN_STATE }
    proteticos.forEach((_, i) => {
      newSteps[`step${i + 1}`] = false
    })
    setStepIsOpen({
      ...newSteps,
      [step]: !stepIsOpen[step],
    })
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <h2 className='mb-4 text-secondary text-center font-weight-bold'>
            Plano de tratamento escolhido pelo paciente
          </h2>
        </Col>
      </Row>
      <Row className='mb-5 pb-5'>
        <Col className='mb-5' style={{ maxWidth: '750px', margin: '0 auto' }}>
          <CollapsePanel stepCode='step0' text='Procedimentos' isOpen={stepIsOpen.step0} toggleIsOpen={toggleIsOpen}>
            <Parcelamentos {...props} index={0} procedimentosSelecionados={procedimentos} />
          </CollapsePanel>
          {proteticos.map((protetico, index) => {
            return (
              <CollapsePanel
                key={index}
                stepCode={`step${index + 1}`}
                text={protetico.procedimentoProtetico.nome}
                isOpen={stepIsOpen[`step${index + 1}`]}
                toggleIsOpen={toggleIsOpen}
              >
                <Parcelamentos
                  {...props}
                  index={index + 1}
                  isProtetico
                  procedimentosSelecionados={[protetico]}
                  avaliacaoProcedimentoProteticoId={protetico.id}
                />
              </CollapsePanel>
            )
          })}
          {/* <Tabs activeKey={selectedTab} onSelect={(tab) => setSelectedTab(tab)}>
            <Tab eventKey={TabsConstants.procs} title={TabsConstants.procs}>
              <Parcelamentos {...props} titulo='Plano de tratamento escolhido pelo paciente' />
            </Tab>
            <Tab eventKey={TabsConstants.prots} title={TabsConstants.prots}>
              <ParcelasProtetico {...props} titulo='Parcelamento do protético' />
            </Tab>
          </Tabs> */}
        </Col>
      </Row>
    </Fragment>
  )
}

export default AbasParcelamentos

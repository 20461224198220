import React, { useState, useEffect } from 'react'
import { Form, InputGroup, Col, Row, Button, Spinner, Modal, Card, Table } from 'react-bootstrap'
import CurrencyInput from 'react-currency-input'
import { toast } from 'react-toastify'
import moment from 'moment'
import { formatMoney } from '../../../utils/string.utils'
import ortodontiaService from '../../../services/ortodontia.service'

export default function Suprimentos({ setIsLoading, selectedPlano, isOpen, handleClose }) {
  const [valorSuprimento, setValorSuprimento] = useState(0.0)
  const [descricao, setDescricao] = useState('')
  const [historico, setHistorico] = useState(null)

  useEffect(() => {
    if (isOpen && selectedPlano?.id) {
      getHistorico()
    }
  }, [isOpen])

  const handleCancel = () => {
    handleClose()
    setValorSuprimento(0.0)
    setDescricao('')
    setHistorico(null)
  }

  const getHistorico = async () => {
    setIsLoading(true)
    try {
      const { data } = await ortodontiaService.getSuprimentosPlanoOrtodontia(selectedPlano.id)
      setHistorico(data?.length > 0 ? data : null)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      await ortodontiaService.salvarSuprimento({
        descricao,
        valor: valorSuprimento,
        planoTratamentoOrtodontiaId: selectedPlano.id,
      })
      handleCancel()
      toast('Suprimento de manutenção adicionado com sucesso!', { type: toast.TYPE.SUCCESS })
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  return (
    <Modal className='modal-agenda' centered show={isOpen} onHide={handleClose}>
      <Card>
        <Modal.Body className='p-4'>
          <h5 className='m-0 mb-3 text-secondary font-weight-bold'>Suprimentos de Manutenção</h5>
          <Row>
            <Col>
              <div className='alert alert-info'>
                Prezado profissional, detalhar abaixo precisamente a quantidade e valores dos materiais substituídos,
                pois esse texto será impresso no termo de ciência e autorização de cobrança que o cliente irá assinar.
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='required'>
                <Form.Label>Descrição:</Form.Label>
                <Form.Control
                  as='textarea'
                  rows='2'
                  required
                  value={descricao}
                  onChange={({ target }) => setDescricao(target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='required'>
                <Form.Label className=''>Valor</Form.Label>
                <InputGroup className=''>
                  <InputGroup.Prepend>
                    <InputGroup.Text>R$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <CurrencyInput
                    className='form-control'
                    decimalSeparator=','
                    thousandSeparator='.'
                    value={valorSuprimento}
                    onChange={setValorSuprimento}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col className='d-flex justify-content-end align-items-end pb-3'>
              <Button variant='outline-primary' onClick={handleCancel}>
                Cancelar
              </Button>
              <Button onClick={handleSave} className='ml-2'>
                Salvar Suprimento
              </Button>
            </Col>
          </Row>
          <h5 className='mt-4 text-secondary font-weight-bold'>Histórico</h5>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Descrição</th>
                <th className='text-center' width='150px'>
                  Valor
                </th>
                <th className='text-center' width='150px'>
                  Data / Hora
                </th>
              </tr>
            </thead>
            <tbody>
              {historico ? (
                historico.map(({ descricao, valor, dataCriacao }, index) => (
                  <tr key={index}>
                    <td>{descricao}</td>
                    <td className='text-center'>{formatMoney(valor)}</td>
                    <td className='text-center'>
                      <p className='m-0'>{moment(dataCriacao).format('DD/MM/YYYY')}</p>
                      <p className='m-0'>{moment(dataCriacao).format('HH:mm')}</p>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan='3'>Nenhum histórico disponível</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
      </Card>
    </Modal>
  )
}

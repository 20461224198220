import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default class FileUpload extends Component {
  file = React.createRef()

  loadFile = () => this.file.current.click()

  onChangeHandler = async (e) => {
    await this.props.onChange(e.target.files[0])
    this.file.current.value = null
  }
  removeFile = () => {
    this.props.onChange(null)
    this.file.current.value = null
  }

  render() {
    const { disableAll } = this.props
    return (
      <Form.Group className='m-0'>
        <Form.Control
          ref={this.file}
          type='file'
          name='contrato'
          className='d-none'
          placeholder=''
          onChange={this.onChangeHandler}
          disabled={disableAll}
        />
        {!disableAll &&
          (!(this.file.current && this.file.current.value) ? (
            <Button disabled={disableAll} onClick={this.loadFile} variant='primary'>
              {this.props.label}
            </Button>
          ) : (
            <div className='d-flex align-items-center'>
              <span>{this.file.current.value.substr(this.file.current.value.indexOf(`th`) + 3)}</span>
              <Button onClick={this.removeFile} variant='link' className='ml-3' size='sm'>
                <FontAwesomeIcon icon={faTimes} className='text-danger' />
              </Button>
            </div>
          ))}
      </Form.Group>
    )
  }
}

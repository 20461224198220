import React, { useEffect, useState, Fragment } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { Container, Table, Row, Col, Form, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { format, compareAsc, differenceInCalendarDays } from 'date-fns'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FinanceiroService from '../../../services/financeiro.service'
import SharedService from '../../../services/shared.service'
import { formatMoney } from '../../../utils/string.utils'
import Select from 'react-select'
import Paginator from '../../../components/Common/Paginator'
import PageLoading from '../../../components/Common/PageLoading'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faClock } from '@fortawesome/free-solid-svg-icons'

const tipoFechamentoOptions = [
  {value: 0, label: 'Clínica'},
  {value: 1, label: 'Protético'}
]

const getCurrentMenu = (caminho, menus) => {
  let currentMenu

  menus.forEach((menu) => {
    if (menu.caminho === caminho) {
      currentMenu = menu
      return
    }

    menu.subMenus.forEach((subMenu) => {
      if (subMenu.caminho === caminho) {
        currentMenu = subMenu
        return
      }
    })
  })

  return currentMenu
}

function ContasReceber({ menus, location }) {
  const porPagina = 18
  
  const dateFilterDeMinVencimento = new Date().setMonth(new Date().getMonth() - 3)
  const [itensLista, setItensLista] = useState([])
  const [dateFilterDeVencimento, setDateFilterDeVencimento] = useState(new Date())
  const [dateFilterAteVencimento, setDateFilterAteVencimento] = useState(null)
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [pagina, setPagina] = useState(0)

  const [selectedTipoFechamento, setSelectedTipoFechamento] = useState(tipoFechamentoOptions[0])

  const [selectedEspecialidade, setSelectedEspecialidade] = useState(null)

  const [especialidadeOptions, setEspecialidadeOptions] = useState([])
  
  const [isLoading, setIsLoading] = useState(true)
  const [resumo, setResumo] = useState([])

  const currentMenu = getCurrentMenu(location.pathname, menus)
  const isSomenteMeus = currentMenu ? currentMenu.regra === 1 : true

  useEffect(() => {
    didMount()
  }, [])

  useEffect(() => {
    getResumo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagina,
    currentMenu,
  ])

  useEffect(() => {
    if (especialidadeOptions && especialidadeOptions.length > 0) {
      setSelectedEspecialidade(especialidadeOptions[0])
    }
  }, [especialidadeOptions])

  const didMount = async () => {
    setIsLoading(true)
    await Promise.all([getAllEspecialidades()])
    setIsLoading(false)
  }

  const getAllEspecialidades = async () => {
    try {
      const { data } = await SharedService.getAllEspecialidades()      

      setEspecialidadeOptions([
        { label: 'Todos', value: null },
        ...data.map((espec) => ({
          value: espec.id,
          label: espec.nome,
        }))
      ])
    } catch (e) {}
  }

  const handlePagina = (data) => setPagina(data.selected)

  const limparFiltro = () => {
    setDateFilterDeVencimento(new Date())
    setDateFilterAteVencimento(null)    

    setSelectedTipoFechamento(tipoFechamentoOptions[0])
    setSelectedEspecialidade(especialidadeOptions[0])
  }

  const handlePesquisar = () => {
    setPagina(0)
    getResumo()
  }

  const getResumo = async () => {
    setIsLoading(true)
    let filtros = [
      {
        campo: 'dataInicial',
        valor: dateFilterDeVencimento,
      },
      {
        campo: 'dataFinal',
        valor: dateFilterAteVencimento,
      },
      {
        campo: 'isProtetico',
        valor: selectedTipoFechamento.value
      }
    ]

    if(selectedEspecialidade){
      filtros.push({
        campo: 'especialidade',
        valor: selectedEspecialidade.value,
      })
    }    

    const { dados, totalPaginas, resumo } = await FinanceiroService.getRegistrosCreditos(
      filtros,
      pagina + 1,
      porPagina,
      currentMenu ? currentMenu.id : null
    )

    setResumo(resumo)
    setItensLista(dados)
    setTotalPaginas(totalPaginas)
    setIsLoading(false)
  }

  const handleDateFilterDeVencimento = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterDeVencimento(filter)
    } else {
      setDateFilterDeVencimento(null)
    }
  }

  const handleDateFilterAteVencimento = (date) => {
    if (date) {
      const filter = date.toDate()
      setDateFilterAteVencimento(filter)
    } else {
      setDateFilterAteVencimento(null)
    }
  }

  const handleTipoFechamento = (option) => {
    setSelectedTipoFechamento(option)
  }

  const handleEspecialidade = (option) => {
    setSelectedEspecialidade(option)
  }

  const renderLista = () => {
    if (!currentMenu) {
      return null
    }

 const getOnlyDate = (date) => {
   var retorno = new Date(date);

   retorno.setHours(0)
   retorno.setMinutes(0)
   retorno.setSeconds(0)

   return retorno;
 }

    return (
      <Fragment>
        <h1 className='h3 m-0 text-secondary font-weight-bold'>
          {'Contas a Receber'}
        </h1>
        <Row className={isSomenteMeus ? 'my-4' : 'mt-4'}>
          <Col md='2'>
            <Form.Group>
              <Form.Label htmlFor='dataVencimento'>Data de vencimento de:</Form.Label>
              
                <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                  <KeyboardDatePicker
                    invalidDateMessage='Formato de data inválido'
                    id='dataVencimento'
                    name='dataVencimento'
                    value={dateFilterDeVencimento}
                    onChange={handleDateFilterDeVencimento}
                    className='form-control'
                    variant='inline'
                    format='DD/MM/YYYY'
                    minDate={dateFilterDeMinVencimento}
                    maxDate={dateFilterAteVencimento || new Date('2100-01-01')}
                    animateYearScrolling
                    autoOk
                    placeholder='De'
                  />
                </MuiPickersUtilsProvider>
                </Form.Group>
            </Col>
        
            <Col md='2'>
            <Form.Group>
              <Form.Label htmlFor='dataVencimento'>Data de vencimento até:</Form.Label>
                <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                  <KeyboardDatePicker
                    invalidDateMessage='Formato de data inválido'
                    value={dateFilterAteVencimento}
                    onChange={handleDateFilterAteVencimento}
                    minDate={dateFilterDeVencimento || new Date('1900-01-01')}
                    className='form-control'
                    variant='inline'
                    format='DD/MM/YYYY'
                    animateYearScrolling
                    autoOk
                    placeholder='Até'
                  />
                </MuiPickersUtilsProvider>
              
            </Form.Group>
          </Col>
        
          <Col md='2'>
            <Form.Group>
              <Form.Label htmlFor='dataPagamento'>Tipo de Fechamento:</Form.Label>
              <Select
                placeholder='Selecione'
                value={selectedTipoFechamento}
                options={tipoFechamentoOptions}
                onChange={handleTipoFechamento}
                noOptionsMessage={() => 'Nenhuma opção'}
              />
            </Form.Group>
          </Col>

          <Col md='2'>
            <Form.Group>
              <Form.Label htmlFor='dataPagamento'>Especialidade:</Form.Label>
              <Select
                placeholder='Selecione'
                value={selectedEspecialidade}
                options={especialidadeOptions}
                onChange={handleEspecialidade}
                noOptionsMessage={() => 'Nenhuma opção'}
              />
            </Form.Group>
          </Col>

          <Col md='4' className='d-flex justify-content-end align-items-end'>
            <Button onClick={limparFiltro} variant='outline-danger' style={buttonClean} className='mb-3'>
              Limpar
            </Button>
            <Button onClick={getResumo} variant='primary' style={buttonSearch} className='mb-3'>
              Pesquisar
            </Button>
          </Col>
        </Row>
        
        {itensLista && itensLista.length > 0 ? (
            <Card className='mb-4 py-2'>
              <Row>
                {resumo.map(({ descricao, valor }, index) => (
                  <Col key={index} className='d-flex align-items-center justify-content-center flex-column'>
                    <small className='text-center text-muted m-0'>{!!descricao ? descricao.toUpperCase() : '-'}</small>
                    <h4 className='text-center m-0 font-weight-bold'>
                      {!!valor ? formatMoney(valor) : formatMoney(0)}
                    </h4>
                  </Col>
                ))}
              </Row>
            </Card>

        ) : ( <div></div> )}

        {itensLista && itensLista.length > 0 ? (
          <div>

            <Table size='sm' bordered hover className='bg-white'>
              <thead>
                <tr className='text-center'>
                  <th width='100px'>Nº Plano</th>
                  <th width='180px'>Paciente</th>
                  <th width='100px'>Valor</th>
                  <th width='70px'>Parcela</th>
                  <th width='100px'>Vencimento</th>
                  <th width='60px'>Situação</th>
                </tr>
              </thead>
              <tbody>
                {itensLista.map((item, i) => {
                  const {
                    nomePaciente,
                    valorCalculado,
                    numeroPlano,
                    parcela,
                    dataVencimento,
                    colaboradorQueRecebeu,
                    formaPagamentoNome,
                    dataPagamento,
                    valorPago,
                  } = item

                  const differenceDays = differenceInCalendarDays(getOnlyDate(new Date(dataVencimento)), getOnlyDate(new Date()))

                  return (
                    <tr className='text-center' style={{ fontSize: 'smaller', height: '40px' }} key={i}>
                      <td>{numeroPlano || '-'}</td>
                      <td>{nomePaciente.toUpperCase()}</td>
                      <td>{valorPago > 0 ? formatMoney(valorPago) : formatMoney(valorCalculado)}</td>
                      <td>{parcela || '-'}</td>
                      <td > {dataVencimento ? format(new Date(dataVencimento), 'dd/MM/yyyy') : '-'} </td>
                      <td>
                        {differenceDays < 0 && (
                          <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip> Em Atraso </Tooltip>}>
                            <FontAwesomeIcon icon={faTimes} />
                          </OverlayTrigger>)
                        }
                        {differenceDays >= 0 && (
                          <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Em Aberto</Tooltip>}>
                            <FontAwesomeIcon icon={faClock} />
                          </OverlayTrigger>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div>Nenhuma conta a receber até o momento</div>
        )}
        {totalPaginas > 1 && <Paginator handlePagina={handlePagina} pageCount={totalPaginas} paginaAtual={pagina} />}
      </Fragment>
    )
  }

  if (isLoading) {
    return <PageLoading />
  }

  return renderLista()
}

const mapStateToProps = (store) => {
  return {
    menus: store.perfisState.menus,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContasReceber))

const buttonClean = {
  left: '0',
  position: 'absolute',
  height: '38px'
}

const buttonSearch = {
  left: '80px',
  position: 'absolute',
  height: '38px'
}
import {put, takeEvery} from 'redux-saga/effects';
import axios from './axios-client'
import {Permissoes, Notificacoes} from '../types'

function* fetchPerfis() {
  try {
    const {data} = yield axios.get('PerfilPermissao/getAll');
    yield put({
      type: Permissoes.LISTAR_PERMISSOES,
      permissoes: data
    })
  } catch (error) {
    yield put({type: Notificacoes.ERRO, notifications: error.notifications});  
  }
}


function* permissoesListener() {
  yield takeEvery(Permissoes.REQUEST_PERMISSOES, fetchPerfis);
}

export default permissoesListener
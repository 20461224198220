import React, { Fragment, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { formatMoney } from '../../utils/string.utils'

export default function SelecaoProcedimentos(props) {
  const { procedimentos, incluirProcedimento } = props

  useEffect(() => {
    incluirTodosProcedimentos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const incluirTodosProcedimentos = () => {
    const todosInclusos = !procedimentos.find((p) => !p.efetivado)
    if (todosInclusos) {
      procedimentos.forEach((p, i) => {
        incluirProcedimento(p, p.denteId, i)
      })
    } else {
      procedimentos.forEach((p, i) => {
        if (!p.efetivado) {
          incluirProcedimento(p, p.denteId, i)
        }
      })
    }
  }

  const getTotalValorProcedimentosSelecionados = () => {
    let total = 0
    procedimentos.forEach((proc) => {
      if (proc.efetivado) {
        total += proc.valorCobrado
        if (proc.avaliacaoProcedimentoProtetico) {
          total += proc.avaliacaoProcedimentoProtetico.valor
        }
      }
    })
    return total
  }

  return (
    <div className='mb-5 pb-5'>
      <h2 className='text-secondary font-weight-bold mb-4'>Plano de tratamento indicado pelo dentista</h2>
      {procedimentos.length > 0 ? (
        <Fragment>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Procedimento</th>
                <th width='250px'>Observação</th>
                <th className='text-center' width='100px'>
                  Dente
                </th>
                <th className='text-right' width='150px'>
                  Valor
                </th>
                <th width='100px' className='text-center'>
                  <label className='checkbox labeless m-0'>
                    <input
                      type='checkbox'
                      checked={!procedimentos.find((p) => !p.efetivado)}
                      onChange={incluirTodosProcedimentos}
                    />
                    <span />
                  </label>
                </th>
              </tr>
            </thead>
            <tbody>
              {procedimentos.map((p, i) => {
                return (
                  <Fragment>
                    <tr key={i}>
                      <td>{p.procedimento.nome}</td>
                      <td style={{ fontSize: 'small' }}>{p.descricao ? p.descricao : '-'}</td>
                      <td className='text-center'>{p.dente ? p.dente.codigo : 'N/D'}</td>
                      <td className='text-right'>{formatMoney(p.valorCobrado || p.procedimento.valor)}</td>
                      <td className='text-center'>
                        <label className='checkbox labeless m-0'>
                          <input
                            type='checkbox'
                            checked={p.efetivado}
                            onChange={() => incluirProcedimento(p, p.denteId, i)}
                          />
                          <span />
                        </label>
                      </td>
                    </tr>
                    {p.avaliacaoProcedimentoProtetico && p.avaliacaoProcedimentoProtetico.procedimentoProtetico && (
                      <tr key={i + p.avaliacaoProcedimentoProtetico.procedimentoProtetico.nome}>
                        <td className='pl-3'>
                          <i className='fa fa-level-up-alt fa-rotate-90 mr-3 text-secondary' />
                          {`${p.avaliacaoProcedimentoProtetico.procedimentoProtetico.nome} `}
                        </td>
                        <td>{'-'}</td>
                        <td>{'-'}</td>
                        <td className='text-right'>{formatMoney(p.avaliacaoProcedimentoProtetico.valor)}</td>
                        <td className='text-center'> - </td>
                      </tr>
                    )}
                  </Fragment>
                )
              })}
            </tbody>
          </Table>
          <h4 className='mr-2 text-secondary text-right font-weight-bold'>
            {`Total: ${formatMoney(getTotalValorProcedimentosSelecionados())}`}
          </h4>
        </Fragment>
      ) : (
        <div>Nenhum procedimento encontrado</div>
      )}
    </div>
  )
}

import React from 'react'

const CustomTooltip = (props) => {
  const { payload, active } = props

  const calcularPercentual = (valor) => {
    const total = payload[1].value + payload[0].value
    return total === 0 ? ' (0%)' : ` (${((valor / total) * 100).toFixed(1)}%)`
  }

  if (active) {
    return (
      <div style={tooltipStyle}>
        <p style={naoEfetivadasStyle}>
          Não Efetivadas: {payload[1].value}
          {calcularPercentual(payload[1].value)}
        </p>
        <p style={efetivadasStyle}>
          Efetivadas: {payload[0].value}
          {calcularPercentual(payload[0].value)}
        </p>
      </div>
    )
  } else {
    return null
  }
}
export default CustomTooltip

const tooltipStyle = {
  backgroundColor: 'white',
  border: `1px solid #0881c7`,
  borderRadius: '5px',
  padding: '15px',
}
const naoEfetivadasStyle = {
  fontSize: '0.9em',
  fontWeight: '500',
  marginBottom: '0.5em',
  color: '#0881c7aa',
}
const efetivadasStyle = {
  fontSize: '0.9em',
  fontWeight: '700',
  color: '#0881c7',
  marginTop: 0,
  marginBottom: 0,
}

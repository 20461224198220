import React, { Fragment, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import Select from 'react-select'

import { relatorios, relatoriosSelect } from '../../constants/relatorio.constants'
import VencimentoParcela from './VencimentoParcela'
import AvaliacoesPendentes from './AvaliacoesPendentes'
import PacientesFaltosos from './PacientesFaltosos'
import PacientesSemAgendamento from './PacientesSemAgendamento'
import ProfissionaisProcedimentos from './ProfissionaisProcedimentos'
import Devedores from './Devedores'
import Desnegativados from './Desnegativados'
import Negativados from './Negativados'
import Manutencoes from './Manutencoes'
import Cancelamentos from './Cancelamentos'
import MenuContext from '../../contexts/MenuContexts'

export default function Relatorio() {
  const acoesPermitidas = {}
  const [selectedRelatorio, setSelectedRelatorio] = useState(null)

  const handleChangeRelatorio = (relatorio) => setSelectedRelatorio(relatorio)

  const renderLista = () => {
    if (!selectedRelatorio) return
    switch (selectedRelatorio.value) {
      case relatorios.VENCIMENTO_PARCELA.value:
        return <VencimentoParcela selectedRelatorio={selectedRelatorio} />
      case relatorios.AVALIACAO_PENDENTE.value:
        return <AvaliacoesPendentes selectedRelatorio={selectedRelatorio} />
      case relatorios.PACIENTES_AGUARDANDO_AGENDAMENTO.value:
        return <PacientesSemAgendamento selectedRelatorio={selectedRelatorio} />
      case relatorios.PACIENTES_FALTOSOS.value:
        return <PacientesFaltosos selectedRelatorio={selectedRelatorio} />
      case relatorios.PROFISSIONAIS_PROCEDIMENTOS.value:
        return <ProfissionaisProcedimentos selectedRelatorio={selectedRelatorio} />
      case relatorios.DEVEDORES.value:
        return <Devedores selectedRelatorio={selectedRelatorio} />
      case relatorios.NEGATIVADOS.value:
        return <Negativados selectedRelatorio={selectedRelatorio} />
      case relatorios.DESNEGATIVADOS.value:
        return <Desnegativados selectedRelatorio={selectedRelatorio} />
      case relatorios.MANUTENCOES.value:
        return <Manutencoes selectedRelatorio={selectedRelatorio} />
        case relatorios.CANCELAMENTOS.value:
        return <Cancelamentos selectedRelatorio={selectedRelatorio} />
      default:
        return
    }
  }

  return (
    <MenuContext.Consumer>
      {(acoes) => {
        acoes.map((a) => {
          acoesPermitidas[`${a.nome}`] = a
          return true
        })
        return (
          <Fragment>
            <Row>
              <Col>
                <h3 className='text-secondary font-weight-bold'>Relatórios</h3>
              </Col>
            </Row>
            <Row className='mt-3 mb-4'>
              <Col xs='5'>
                <Select
                  placeholder='Relatório'
                  options={relatoriosSelect}
                  onChange={handleChangeRelatorio}
                  noOptionsMessage={() => 'Nenhum relatório disponível'}
                />
              </Col>
            </Row>
            {renderLista()}
          </Fragment>
        )
      }}
    </MenuContext.Consumer>
  )
}

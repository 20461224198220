import React, { useEffect, useState } from 'react'
import { Card, Modal } from 'react-bootstrap'
import AnexarGeral from './AnexarGeral'
import PageLoading from '../../Common/PageLoading'
import anexosService from '../../../services/anexos.service'
import moment from 'moment'

export default function TabDocumentos({colaboradorId}) {
  const [isLoading, setIsLoading] = useState(false)
  const [abrirModalAnexarGeral, setAbrirModalAnexarGeral] = useState(false)

  const [documentos, setDocumentos] = useState([])
  const [categorias, setCategorias] = useState([])

  useEffect(() => {
    initialize();
  }, [])

  const initialize = async () => {
    const responseCategorias = await anexosService.getCategorias();

    if(!colaboradorId)
        return;

    const responseDocumentos = await anexosService.getDocumentos(colaboradorId);

    setCategorias(responseCategorias.data)
    setDocumentos(responseDocumentos.data)
  }

  const fecharModais = async () => {
    setAbrirModalAnexarGeral(false);
    setIsLoading(true);

    await initialize();
    setIsLoading(false);
  }

  const download = async (documento) => {
    setIsLoading(true);
    await anexosService.download(documento.id, documento.nomeGerado);
    setIsLoading(false);
  }

  return (
    <div>
      {isLoading && <PageLoading />}
      <div>
        <div className='mt-3 mb-1 row'>
          <div className='col'>
            <h2 className='h5 border-bottom pb-2'>Documentos Gerais</h2>
            <p>Todos anexos gerais referente ao Colaborador.</p>
          </div>
        </div>

        <div className='text-right mb-2'>
          <button className='btn btn-outline-primary' onClick={() => setAbrirModalAnexarGeral(true)}>Anexar</button>
        </div>

        <div className='table-responsive'>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th>Identificador</th>
                <th>Nome do Documento</th>
                <th>Nome do Sistema</th>
                <th>Categoria</th>
                <th>Data da Criação</th>
              </tr>
            </thead>
            <tbody>
              {documentos.map(documento => {
                return <tr className='cursor-pointer' onClick={() => download(documento)}>
                  <td>{documento.id}</td>
                  <td>{documento.nome}</td>
                  <td>{documento.nomeGerado}</td>
                  <td>{categorias.find(categoria => categoria.id == documento.categoriaId).nome}</td>
                  <td>{moment(documento.dataCriacao).format('DD/MM/YYYY')}</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>


        <Modal className='modal-documentos' show={abrirModalAnexarGeral} onHide={() => fecharModais()}>
          <Card>
            <Modal.Header className='p-4' closeButton>
              <Modal.Title>Anexe documento(s)</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-4'>
              <AnexarGeral fecharModais={fecharModais} colaboradorId={colaboradorId} categorias={categorias} />
            </Modal.Body>
          </Card>
        </Modal>


      </div>
    </div>
  )
}

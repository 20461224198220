import { all, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import axios from './axios-client'
import SystemConstants from '../../constants/system.constants'
import * as AgendamentosDentista from '../reducers/agendamentosDentista.reducer'

function* fetchAgendamentosDentista(action) {
  try {
    const { colaboradorId } = action.colaboradorId
      ? action
      : JSON.parse(localStorage.getItem(SystemConstants.getUser()))
    const { data } = yield axios.get(`AgendaBloqueioDentista/get/${colaboradorId}`, { ...action })
    yield all([
      put({
        type: AgendamentosDentista.LISTAR_AGENDAMENTOS_DENTISTA,
        agendamentosDentista: data,
      }),
      put({ type: AgendamentosDentista.SET_NOT_LOADING }),
    ])
  } catch (error) {}
}

function* salvarBloqueiosDentista(action) {
  const { datas, colaboradorId, callback } = action
  try {
    yield axios.post(`AgendaBloqueioDentista/salvar/`, datas)
    yield all([
      put({ type: AgendamentosDentista.REQUEST_AGENDAMENTOS_DENTISTA, colaboradorId }),
      put({ type: AgendamentosDentista.PROXIMO_BLOQUEIO }),
      put({ type: AgendamentosDentista.SET_NOT_LOADING }),
    ])
    toast('Datas registradas com sucesso!', { type: toast.TYPE.SUCCESS })
    callback && callback()
  } catch (error) {
    yield put({ type: AgendamentosDentista.SET_NOT_LOADING })
  }
}

function* agendamentosDentistaListener() {
  yield takeLatest(AgendamentosDentista.REQUEST_AGENDAMENTOS_DENTISTA, fetchAgendamentosDentista)
  yield takeLatest(AgendamentosDentista.SALVAR_BLOQUEIOS_DENTISTA, salvarBloqueiosDentista)
}

export default agendamentosDentistaListener

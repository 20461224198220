import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'

import ParametrosService from '../../../services/parametros.service'
import AgendamentosService from '../../../services/agendamentos.service'

import ModalDetalhesAgendamento from '../../../components/ModalDetalhesAgendamento'
import PageLoading from '../../../components/Common/PageLoading'

import { status } from '../../../constants/agendamento.constants'
import { TIPO_CONSULTA } from '../../../constants/agendamento.constants'
import { formatTitulo } from '../../../utils/string.utils'
import { useDispatch, useSelector } from 'react-redux'

const SOMENTE_ATENDIMENTO = 'DentistaVisualizarSomenteAgendamentosAguardandoAtendimento'
const AGUARDANDO_ATENDIMENTO = 5

const AgendaDentista = (props) => {
  const { shouldReloadAgendamentos, finishReload } = props
  const [isLoading, setLoading] = useState(true)
  const [showModalDetalhes, setShowModalDetalhes] = useState(false)
  const [agendamentos, setAgendamentos] = useState([])
  const [somenteAguardandoAtendimento, setSomenteAguardandoAtendimento] = useState(false)
  const [selectedAgendamento, setSelectedAgendamento] = useState({})

  useEffect(() => {
    didMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (shouldReloadAgendamentos) {
      reloadAgendamentos()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReloadAgendamentos])

  const reloadAgendamentos = async () => {
    setLoading(true)
    await didMount()
    finishReload()
  }

  const didMount = async () => {
    await Promise.all([getDoDia(), getParametros()])
    setLoading(false)
  }

  const getDoDia = async () => {
    let { data } = await AgendamentosService.getAgendamentosEmAberto()
    data = ordenarAgendamentos(data)
    if (somenteAguardandoAtendimento) {
      data = data.filter((a) => a.statusAgendamento === AGUARDANDO_ATENDIMENTO)
    }
    setAgendamentos(data)
  }

  const getParametros = async () => {
    let { data } = await ParametrosService.getAll()
    data = data.find((parametro) => parametro.nome === SOMENTE_ATENDIMENTO)
    if (data) {
      setSomenteAguardandoAtendimento(Boolean(data.value))
    }
  }

  const ordenarAgendamentos = (agendamentos) => {
    agendamentos = agendamentos.map(function(el, i) {
      return { index: i, agendamento: el }
    })
    agendamentos.sort(function(a, b) {
      return +(a.agendamento.start > b.agendamento.start) || +(a.agendamento.start === b.agendamento.start) - 1
    })
    return agendamentos
  }

  const handleModal = (agendamento) => {
    setShowModalDetalhes(!showModalDetalhes)
    setSelectedAgendamento(agendamento)
    if (!agendamento) {
      getDoDia()
    }
  }

  const renderLoading = () => <PageLoading />

  const renderAgendamentos = () => (
    <Container className='border shadow-sm'>
      <Row className='py-2 bg-white'>
        <Col>
          <i className='far fa-calendar-alt mr-2' />
          <span> Próximas Consultas</span>
        </Col>
      </Row>
      {agendamentos.length > 0 ? (
        <Table hover>
          <thead>
            <tr>
              <th width='120px'>Data</th>
              <th width='120px'>Horário</th>
              <th>Nome do Paciente</th>
              <th>Status</th>
              <th>Observação</th>
            </tr>
          </thead>
          <tbody className='bg-white'>
            {agendamentos.map((el, i) => {
              const { agendamento } = el
              const corFundo =
                agendamento.tipoConsulta === TIPO_CONSULTA.PROCEDIMENTO.value ? 'bg-primary-03' : 'bg-warning-03'
              const nomePaciente =
                agendamento.dependente !== null ? agendamento.dependente.nome : agendamento.nomePaciente
              return (
                <tr key={i} onClick={() => handleModal(agendamento)} className={corFundo} style={{ cursor: 'pointer' }}>
                  <td>{moment(agendamento.data).format('DD/MM/YYYY')}</td>
                  <td>{moment(agendamento.data).format('HH:mm')}</td>

                  <OverlayTrigger
                    trigger='hover'
                    placement='top'
                    overlay={<Tooltip id='tooltip-disabled'>{nomePaciente}</Tooltip>}
                  >
                    <td>
                      <span>{nomePaciente}</span>
                    </td>
                  </OverlayTrigger>
                  
                  <td>{status.filter((s) => s.value === agendamento.statusAgendamento)[0].label}</td>
                  <td>{agendamento.observacao ? agendamento.observacao : '-'}</td>
                </tr>
              )
            })}
          </tbody>
          {showModalDetalhes && (
            <ModalDetalhesAgendamento
              {...props}
              agendamento={selectedAgendamento}
              agendamentos={agendamentos}
              isOpen={showModalDetalhes}
              handleClose={handleModal}
              mostrarSelect={false}
              //iniciarAvaliacao={dispatchAvaliacao}
              //iniciarTratamento={dispatchTratamento}
            />
          )}
        </Table>
      ) : (
        <Row className='bg-white'>
          <Col className='py-4'>
            <span>Nenhum agendamento marcado</span>
          </Col>
        </Row>
      )}
    </Container>
  )
  return <div>{isLoading ? renderLoading() : renderAgendamentos()}</div>
}

export default AgendaDentista

import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Container, Button, Spinner } from 'react-bootstrap'

import { printParcelasReceipt } from '../../../Templates/PrintableReceipt'
import PacienteService from '../../../../services/paciente.service'
import PlanoTratamentoService from '../../../../services/planoTratamento.service'
import { TIPOS_TEMPLATE } from '../../../../constants/template.constants'

export default function Impressao({ planoTratamento, pagamento, onBack, franquia }) {
  const [isPrintLoading, setIsPrintLoading] = useState(false)
  const [pacientePlano, setPaciente] = useState(null)

  useEffect(() => {
    planoTratamento && planoTratamento.pacienteId && getPaciente()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planoTratamento])

  const getPaciente = async () => {
    try {
      const { data } = await PacienteService.getPacienteById(planoTratamento.pacienteId)
      setPaciente(data)
    } catch (error) {}
  }

  if (
    !pacientePlano ||
    !franquia ||
    !planoTratamento ||
    Object.keys(planoTratamento).length === 0 ||
    Object.keys(pagamento).length === 0
  ) {
    return null
  }

  const parcelas = pagamento.parcelas
  const contratante = { ...pacientePlano, endereco: pacientePlano.enderecos[0] }
  const paciente = planoTratamento.dependente
    ? { ...planoTratamento.dependente, endereco: contratante.endereco }
    : { ...contratante }

  const handleImprimirCancelamento = async () => {
    setIsPrintLoading(true)
    await PlanoTratamentoService.downloadTemplateAsPDF(
      planoTratamento.id,
      pagamento.valorTotal > 0
        ? TIPOS_TEMPLATE.TermoDeCancelamentoDeProcedimentoComPendencia
        : TIPOS_TEMPLATE.TermoDeCancelamentoDeProcedimentoComQuitacao
    )
    setIsPrintLoading(false)
  }

  const handleImprimirCarne = async () => {
    await printParcelasReceipt({
      numeroPlano: planoTratamento.numeroPlano,
      nomeContratante: contratante.nome,
      nomePaciente: paciente.nome,
      parcelas: parcelas,
      valorTotal: pagamento.valorTotal,
    })
  }

  return (
    <Container className='text-center pb-5'>
      <div className='mb-5'>
        <FontAwesomeIcon style={{ fontSize: '11em' }} className='text-success mt-5 mb-3' icon={faCheckCircle} />
        <h2 style={fontStyle} className='text-success'>
          Cancelamento efetuado com sucesso!
        </h2>
      </div>
      <div>
        <Button className='mr-3' variant='primary' onClick={handleImprimirCancelamento}>
          {!isPrintLoading ? 'Termo de Cancelamento' : <Spinner animation='border' size='sm' variant='light' />}
        </Button>
        {pagamento.valorTotal > 0 && (
          <Button className='mr-3' variant='primary' onClick={handleImprimirCarne}>
            Imprimir Parcelamento
          </Button>
        )}
        <Button variant='outline-primary' onClick={onBack}>
          Voltar
        </Button>
      </div>
    </Container>
  )
}

const fontStyle = {
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
}

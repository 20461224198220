import React from 'react'
import Select from 'react-select'
import { Row, Col, Spinner } from 'react-bootstrap'
import { encaminhamentoOptions } from '../../../constants/common.constants'
import ArquivosRadiologia from './ArquivosRadiologia'

export default function DefinicaoTratamento(props) {
  const {
    selectedEncaminhamento,
    setSelectedEncaminhamento,
    selectedAparelho,
    setSelectedAparelho,
    aparelhoOptions,
    isAparelhoLoading,
    agendamento,
  } = props

  const handleChangeEncaminhamento = (encaminhamento) => {
    setSelectedEncaminhamento(encaminhamento)
    setSelectedAparelho(null)
  }

  return (
    <div className='mb-5 pb-5'>
      <h2 className='text-secondary font-weight-bold mb-4'>Definição do Tratamento</h2>
      <Row>
        <Col>
          <Select
            placeholder='Selecionar encaminhamento...'
            noOptionsMessage={() => 'Nenhuma opção disponível'}
            options={Object.values(encaminhamentoOptions)}
            value={selectedEncaminhamento}
            onChange={handleChangeEncaminhamento}
          />
        </Col>
        <Col>
          <Select
            placeholder='Selecionar aparelho...'
            noOptionsMessage={() => 'Nenhuma opção disponível'}
            options={aparelhoOptions}
            value={selectedAparelho}
            onChange={setSelectedAparelho}
            isDisabled={
              (selectedEncaminhamento && selectedEncaminhamento.value !== encaminhamentoOptions.APROVADO.value) ||
              !selectedEncaminhamento
            }
          />
          {isAparelhoLoading && <Spinner animation='border' variant='primary' size='sm' style={spinnerStyle} />}
        </Col>
      </Row>
      {agendamento && agendamento.arquivos && (
        <ArquivosRadiologia selectedAgendamento={agendamento} arquivos={agendamento.arquivos} />
      )}
    </div>
  )
}

const spinnerStyle = {
  position: 'absolute',
  top: '12px',
  right: '60px',
}

import React, { Fragment, useEffect, useState } from 'react'
import StepWizard from 'react-step-wizard'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import moment from 'moment'

import ortodontiaService from '../../../services/ortodontia.service'
import { statusAvaliacaoOrtodontica } from '../../../constants/agendamento.constants'
import { formatMoney } from '../../../utils/string.utils'
import PageLoading from '../../Common/PageLoading'
import AgendamentoRadiologia from './AgendamentoRadiologia'
import SelecaoPlanoPagamento from './SelecaoPlanoPagamento'
import Impressao from './Impressao'

export default function AtendimentoRecepcao(props) {
  const { agendamento, history } = props

  const [isLoading, setIsLoading] = useState(true)
  const [wizardRef, setWizardRef] = useState(null)
  const [clinicasRadiologicas, setClinicasRadiologicas] = useState([])
  const [planosOrtodonticos, setPlanosOrtodonticos] = useState([])
  const [planoPagamento, setPlanoPagamento] = useState({})
  const [avaliacao, setAvaliacao] = useState(agendamento.avaliacaoOrtodontia || null)
  const [dataVencimento, setDataVencimento] = useState(new Date())
  const [pagamentoAparelho, setPagamentoAparelho] = useState(null)
  const [aparelhoOptions, setAparelhoOptions] = useState([])
  const [selectedAparelho, setSelectedAparelho] = useState(null)
  const [pagamentoEfetuado, setPagamentoEfetuado] = useState(null)

  useEffect(() => {
    if (!agendamento.id) {
      history.push('/agendamento/ortodontia')
    }
    wizardRef && didMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardRef])

  useEffect(() => {
    if (avaliacao && avaliacao.aparelhoId) {
      getAparelhoPaciente(avaliacao.aparelhoId)
    }
  }, [avaliacao])

  useEffect(() => {
    if (selectedAparelho && selectedAparelho.valorVenda > 0) {
      setPagamentoAparelho({
        valorTotal: selectedAparelho.valorVenda,
        dataVencimento: new Date(),
        numeroParcelas: 1,
        parcelas: [
          {
            valor: selectedAparelho.valorVenda,
            numero: 1,
            dataVencimento: new Date(),
            status: 0,
          },
        ],
      })
    } else {
      setPagamentoAparelho(null)
    }
  }, [selectedAparelho])

  const didMount = async () => {
    try {
      await Promise.all([
        getClinicas(),
        getPlanosOrtodonticos(),
        getAparelhos(),
        getAvaliacao(agendamento.avaliacaoOrtodontia.id),
      ])
    } catch (error) {}
    setIsLoading(false)
  }

  const getAparelhoPaciente = async (aparelhoId) => {
    setIsLoading(true)
    try {
      const { data } = await ortodontiaService.getAparelhoById(aparelhoId)
      setSelectedAparelho({
        label: `${data.nome} - ${formatMoney(data.valorVenda)}`,
        value: data.id,
        valorVenda: data.valorVenda,
      })
      if (data.valorVenda > 0) {
        setPagamentoAparelho({
          valorTotal: data.valorVenda,
          dataVencimento: new Date(),
          numeroParcelas: 1,
          parcelas: [
            {
              valor: data.valorVenda,
              numero: 1,
              dataVencimento: new Date(),
              status: 0,
            },
          ],
        })
      } else {
        setPagamentoAparelho(null)
      }
    } catch (error) {}
    setIsLoading(false)
  }

  const getAparelhos = async () => {
    try {
      const { data } = await ortodontiaService.getAparelhosCadastrados()
      setAparelhoOptions(
        data.map((opt) => ({
          label: `${opt.nome} - ${formatMoney(opt.valorVenda)}`,
          value: opt.id,
          valorVenda: opt.valorVenda,
        }))
      )
    } catch (e) {
      setAparelhoOptions([])
    }
  }

  const getAvaliacao = async (id) => {
    try {
      const { data } = await ortodontiaService.getAvaliacaoById(id)
      if (data.statusAvaliacao && data.statusAvaliacao === statusAvaliacaoOrtodontica.TRATAMENTO_DEFINIDO.value) {
        wizardRef.nextStep()
      }
      setAvaliacao(data)
    } catch (error) {
      console.error(error)
    }
  }

  const getClinicas = async () => {
    try {
      const { data } = await ortodontiaService.getClinicasCadastradas()
      setClinicasRadiologicas(data.filter((d) => d.ativo))
    } catch (error) {
      console.error(error)
    }
  }

  const getPlanosOrtodonticos = async () => {
    try {
      const { data } = await ortodontiaService.getPlanosCadastrados()
      setPlanosOrtodonticos(data.filter((d) => d.ativo))
    } catch (error) {
      console.error(error)
    }
  }

  const handlePlanoPagamento = async () => {
    const parcelamentoPlano = planoPagamento.id ? planoPagamento.prazo : planoPagamento.numeroParcelas
    let mensagemModal = `<ul class='text-left font-weight-normal'>
        <li class='text-break'>
          <strong>${planoPagamento.nome}</strong>:
          Total de <strong>${formatMoney(planoPagamento.valorTotal)}</strong>
          ${parcelamentoPlano > 1 ? `dividido em <strong>` + parcelamentoPlano + `x </strong>` : `à vista`}
        </li>`
    if (pagamentoAparelho) {
      const { numeroParcelas } = pagamentoAparelho
      mensagemModal += `<li class='text-break'>
          <strong>Aparelho</strong>:
          Total de <strong>${formatMoney(pagamentoAparelho.valorTotal)}</strong>
          ${numeroParcelas > 1 ? `dividido em <strong>` + numeroParcelas + `x </strong>` : `à vista`}
        </li>`
    }
    mensagemModal += `</ul>`
    const { value } = await Swal.fire({
      title: 'Confirmar a escolha do plano?',
      html: mensagemModal,
      type: 'question',
      showCancelButton: true,
      cancelButtonColor: '#dbdbdb',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirmar',
    })
    try {
      if (value) {
        setIsLoading(true)
        if (planoPagamento.id) {
          planoPagamento.numeroParcelas = planoPagamento.prazo || planoPagamento.numeroParcelas
          delete planoPagamento.id
          planoPagamento.dataVencimento = dataVencimento
        }
        const { valorFinalParcelas, diferenca } = getValorParcelas(0, 0)
        planoPagamento.parcelas = getParcelas(valorFinalParcelas, diferenca, 0)
        const planoResponse = await ortodontiaService.gerarPlanoTratamentoOrtodontico(
          { ...avaliacao, agendamentoId: agendamento.id, aparelhoId: selectedAparelho.value },
          planoPagamento,
          pagamentoAparelho
        )
        setPagamentoEfetuado({
          numeroPlano: planoResponse.data.numeroPlano,
          planoId: planoResponse.data.id,
          pagamentoAparelho,
          planoPagamento,
        })
        wizardRef.nextStep()
      }
    } catch (error) {
      console.error(`ERROR`, error)
    }
    setIsLoading(false)
  }

  function getValorParcelas(valorEntradaNumber, valorParcelas) {
    let diferenca = 0
    let totalParcelas = planoPagamento.numeroParcelas
    valorParcelas = (planoPagamento.valorTotal - valorEntradaNumber) / totalParcelas
    diferenca = planoPagamento.valorTotal - valorEntradaNumber - valorParcelas.toFixed(2) * totalParcelas
    return { valorFinalParcelas: valorParcelas, diferenca }
  }

  function getParcelas(valorParcelas, diferencaPrimeiraParcela) {
    let parcelas = []

    for (let index = 1; index < planoPagamento.numeroParcelas; index++) {
      const valor = index === 1 ? +(valorParcelas + diferencaPrimeiraParcela).toFixed(2) : +valorParcelas.toFixed(2)
      parcelas.push({
        valor: +valor.toFixed(2),
        numero: index + 1,
        dataVencimento: moment(dataVencimento)
          .add(index, 'month')
          .toISOString(),
      })
    }

    parcelas.unshift({
      valor: +valorParcelas.toFixed(2),
      numero: 1,
      dataVencimento: moment(dataVencimento).toISOString(),
    })
    return parcelas
  }

  const salvarAgendamento = async (data, clinica, formaPagamento) => {
    setIsLoading(true)
    try {
      const clinicaRadiologia = clinicasRadiologicas.find((c) => c.id === clinica.value)
      const novoAgendamento = {
        data: moment(data).format('YYYY-MM-DDTHH:mm:ss'),
        formaPagamento: formaPagamento.value,
        clinicaRadiologiaId: clinicaRadiologia.id,
      }
      await ortodontiaService.salvarAvaliacao({
        ...avaliacao,
        agendamentoId: agendamento.id,
        statusAvaliacao: statusAvaliacaoOrtodontica.EXAME_MARCADO.value,
        agendamentosRadiologia: [...avaliacao.agendamentosRadiologia, novoAgendamento],
      })
      toast('Agendamento registrado com sucesso!', { type: toast.TYPE.SUCCESS })
      history.push('/agendamento/ortodontia')
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const renderLoading = () => <PageLoading />

  return (
    <Fragment>
      <StepWizard isLazyMount instance={setWizardRef}>
        <AgendamentoRadiologia
          nomePaciente={
            agendamento && agendamento.dependente
              ? agendamento.dependente.nome
              : agendamento.paciente
              ? agendamento.paciente.nome
              : ''
          }
          clinicas={clinicasRadiologicas}
          salvarAgendamento={salvarAgendamento}
        />
        <SelecaoPlanoPagamento
          setPlanoPagamento={setPlanoPagamento}
          setIsLoading={setIsLoading}
          planosOrtodonticos={planosOrtodonticos}
          setPagamentoAparelho={setPagamentoAparelho}
          handleSalvar={handlePlanoPagamento}
          setDataVencimento={setDataVencimento}
          dataVencimento={dataVencimento}
          planoPagamento={planoPagamento}
          pagamentoAparelho={pagamentoAparelho}
          aparelhoOptions={aparelhoOptions}
          setSelectedAparelho={setSelectedAparelho}
          selectedAparelho={selectedAparelho}
        />
        <Impressao avaliacao={avaliacao} pagamentoEfetuado={pagamentoEfetuado} history={history} />
      </StepWizard>
      {isLoading && renderLoading()}
    </Fragment>
  )
}

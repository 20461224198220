import { Videos } from "../types";

export const requestVideos = () => ({ type: Videos.REQUEST_VIDEOS });

export const editarVideo = videoId => ({ type: Videos.GET_VIDEO_ID, videoId });

export const resetVideo = () => ({
  type: Videos.EDITAR_VIDEO,
  selectedVideo: {}
});

export const salvarVideo = video => ({ type: Videos.SALVAR_VIDEO, video });

export const toggleVideo = (videoId, ativar) => ({
  type: Videos.TOGGLE_VIDEO,
  videoId,
  ativar
});

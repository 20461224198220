import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Form, Card, Button, Tabs, Tab } from 'react-bootstrap'
import Select from 'react-select'
import MaskedInput from 'react-text-mask'
import { isValidCpf } from '@brazilian-utils/is-valid-cpf'
import formatCpf from '@brazilian-utils/format-cpf'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faTrashAlt, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'

// Material-UI components
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import MaterialSelect from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'

import Emails from './../Common/Emails'
import Telefones from './../Common/Telefones'
import Enderecos from './../Common/Enderecos'
import ColaboradoresService from '../../services/colaboradores.service'
import PerfilService from '../../services/perfil.service'
import { cargoConstant } from '../../constants/common.constants'
import PageLoading from '../Common/PageLoading'
import { estadoCivilOptions } from '../../constants/common.constants'
import TabDocumentos from './Documentos/TabDocumentos'

const tabsConstants = {
  dados: 'Dados Pessoais',
  documentos: 'Documentos'
};

class ColaboradoresForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      colaborador: {
        cpf: '',
        rg: '',
        nome: '',
        dataNascimento: null,
        emails: [{ endereco: '' }],
        telefones: [{ numero: '' }],
        endereco: {},
        especialidadeColaborador: [],
        cargo: {},
        cargoId: '',
        perfil: {},
        perfilId: '',
        numeroCarteiraTrabalho: '',
        cro: '',
        nacionalidade: '',
        estadoCivil: 0,
        profissao: '',
        orgaoEmissor: ''
      },
      invalidPassword: false,
      invalidPassword2: false,
      invalidCPF: false,
      isLoading: false,
      cargos: [],
      perfis: [],
      especialidades: [],
      selectedTab: tabsConstants.dados
    }
  }

  componentDidMount() {
    const { selectedColaborador } = this.props
    this.setState({ ...this.state, isLoading: true }, async () => {
      selectedColaborador && (await this.requestColaborador(selectedColaborador))
      await Promise.all([
        this.requestCargos(),
        this.requestPerfis(),
        this.requestEspecialidades(this.state.colaborador.cargoId),
      ])
      this.updateColaborador()
      this.setState({ ...this.state, isLoading: false })
    })
  }

  requestColaborador = async (colaboradorId) => {
    try {
      const { data } = await ColaboradoresService.getColaboradorById(colaboradorId)
      this.setState({ ...this.state, colaborador: data })
    } catch (e) { }
  }

  requestCargos = async () => {
    try {
      const { data } = await ColaboradoresService.getAllCargos()
      const cargos = data.map((cargo) => ({ label: cargo.nome, value: cargo.id, tipo: cargo.tipo }))
      this.setState({ ...this.state, cargos })
    } catch (e) { }
  }

  requestPerfis = async () => {
    try {
      const { data } = await PerfilService.getPerfis()
      const perfis = data.map((perfil) => ({ label: perfil.nome, value: perfil.id }))
      this.setState({ ...this.state, perfis })
    } catch (e) { }
  }

  requestEspecialidades = async (cargoId) => {
    try {
      const { data } = await ColaboradoresService.getEspecialidadesByCargo(cargoId)
      const especialidades = data.map((especialidade) => ({ label: especialidade.nome, value: especialidade.id }))
      this.setState({ ...this.state, especialidades })
    } catch (e) { }
  }

  updateColaborador = () => {
    const { colaborador, especialidades } = this.state
    const { cargoId, perfilId, estadoCivil } = colaborador
    const cpf = formatCpf(colaborador.cpf)
    const cargo = this.state.cargos.find((cargo) => cargo.value === cargoId)
    const perfil = this.state.perfis.find((perfil) => perfil.value === perfilId)
    const especialidadeColaborador = colaborador.especialidadeColaborador.map(
      (e) => especialidades.find((esp) => esp.value === e.especialidadeId).label
    )
    const estadoCivilData = estadoCivilOptions.find((estado) => estado.value === estadoCivil)

    this.setState({
      ...this.state,
      colaborador: { ...colaborador, cargo, perfil, especialidadeColaborador, cpf, estadoCivil: estadoCivilData },
    })
  }

  onChange = (e) => {
    this.setState({ ...this.state, colaborador: { ...this.state.colaborador, [e.target.name]: e.target.value } })
  }

  onChangeCargo = (cargo) => {
    this.setState(
      { ...this.state, colaborador: { ...this.state.colaborador, cargo, especialidadeColaborador: [], cro: '' } },
      async () => {
        await this.requestEspecialidades(cargo.value)
      }
    )
  }

  onChangePerfil = (perfil) => {
    this.setState({ ...this.state, colaborador: { ...this.state.colaborador, perfil } })
  }

  onChangeEstadoCivil = (estadoCivil) => {
    this.setState({ ...this.state, colaborador: { ...this.state.colaborador, estadoCivil } })
  }

  onChangeEspecialidade = (e) => {
    this.setState({
      ...this.state,
      colaborador: { ...this.state.colaborador, especialidadeColaborador: e.target.value },
    })
  }

  onBlurCPF = (e) => {
    this.onChange(e)
    this.setState({ invalidCPF: !isValidCpf(e.target.value) && e.target.value.length > 0 })
  }

  onChangeDate = (dataNascimento) => {
    this.setState({ ...this.state, colaborador: { ...this.state.colaborador, dataNascimento } })
  }

  blockPasteDrop = (e) => {
    e.preventDefault()
    return false
  }

  onSubmit = async (e) => {
    e.preventDefault()
    if (
      !this.state.invalidCPF &&
      !(this.state.colaborador.telefones[0].numero.length < 13)
    ) {
      let data = { ...this.state.colaborador }
      data.cargoId = data.cargo.value
      data.perfilId = data.perfil.value
      data.estadoCivil = data.estadoCivil.value
      data.especialidadeColaborador = data.especialidadeColaborador.map((selectedLabel) => ({
        especialidadeId: this.state.especialidades.find((espec) => espec.label === selectedLabel).value,
      }))
      this.salvarColaborador(data)
    }
  }

  salvarColaborador = (colaborador) => {
    this.setState({ ...this.state, isLoading: true }, async () => {
      try {
        await ColaboradoresService.salvarColaborador(colaborador)
        toast('Informações salvas com sucesso', { type: toast.TYPE.SUCCESS })
        this.props.resetColaborador()
        this.props.history.push('/colaboradores')
      } catch (e) {
        this.setState({ ...this.state, isLoading: false })
      }
    })
  }

  addEmail = () => {
    let emails = this.state.emails || []
    emails.push({})
    this.setState({ ...this.state, colaborador: { ...this.state.colaborador, emails } })
  }

  setEmail = (index, email) => {
    const emails = [...this.state.colaborador.emails]
    emails[index] = email
    this.setState({ ...this.state, colaborador: { ...this.state.colaborador, emails } })
  }

  removeEmail = (index) => {
    const { emails } = this.state.colaborador
    emails.splice(index, 1)
    this.setState({ ...this.state, colaborador: { ...this.state.colaborador, emails } })
  }

  renderEmail(index, email) {
    return (
      <Row key={index}>
        <Emails index={index} email={email} onChange={this.setEmail} required />
        <Col className='pt-3'>
          {index > 1 && this.props.id && email.endereco && (
            <Button onClick={() => this.removeEmail(index)} className='btn-delete-field' variant='danger'>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )}
          {index > 0 && (
            <Button onClick={() => this.removeEmail(index)} className='fix-btn-margin mr-2' variant='primary'>
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          )}
        </Col>
      </Row>
    )
  }

  addTelefone = () => {
    const telefones = this.state.colaborador.telefones || []
    telefones.push({})
    this.setState({ ...this.state, colaborador: { ...this.state.colaborador, telefones } })
  }

  removeTelefone = (index) => {
    const { telefones } = this.state.colaborador
    telefones.splice(index, 1)
    this.setState({ ...this.state, colaborador: { ...this.state.colaborador, telefones } })
  }

  setTelefone = (index, telefone) => {
    const telefones = [...this.state.colaborador.telefones]
    telefones[index] = telefone
    this.setState({ ...this.state, colaborador: { ...this.state.colaborador, telefones } })
  }

  renderTelefone(index, telefone) {
    return (
      <Row key={index}>
        <Col md='4'>
          <Telefones index={index} tipoTelefone={0} telefone={telefone} required={true} onChange={this.setTelefone} />
        </Col>
        <Col className='pt-3'></Col>
      </Row>
    )
  }

  setEndereco = (_, endereco) => {
    this.setState({ ...this.state, colaborador: { ...this.state.colaborador, endereco } })
  }

  renderEndereco = (endereco) => <Enderecos endereco={endereco} onChange={this.setEndereco} />

  handleSelectedTab = async (tab) => {
    this.setState({ selectedTab: tab })
  }

  renderForm() {
    const {
      colaborador,
      invalidPassword,
      invalidCPF,
      cargos,
      perfis,
      especialidades
    } = this.state
    const {
      cpf,
      rg,
      nome,
      dataNascimento,
      emails,
      telefones,
      endereco,
      especialidadeColaborador,
      cargo,
      perfil,
      numeroCarteiraTrabalho,
      cro,
      nacionalidade,
      estadoCivil,
      profissao,
      orgaoEmissor
    } = colaborador
    return (
      <Card className='p-4 mb-3'>
        <Form onSubmit={this.onSubmit}>
          <Row>
            <Col md='4'>
              <Form.Group className='required'>
                <Form.Label htmlFor='nome'>Nome</Form.Label>
                <Form.Control type='text' id='nome' name='nome' value={nome} onChange={this.onChange} required />
              </Form.Group>
            </Col>

            <Col md='3'>
              <Form.Group className='required'>
                <Form.Label htmlFor='dataNascimento'>Data de nascimento</Form.Label>
                <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                  <KeyboardDatePicker
                    invalidDateMessage='Formato de data inválido'
                    id='dataNascimento'
                    name='dataNascimento'
                    className='form-control'
                    value={dataNascimento}
                    onChange={this.onChangeDate}
                    variant='inline'
                    format='DD/MM/YYYY'
                    required
                    animateYearScrolling
                    disableFuture
                  />
                </MuiPickersUtilsProvider>
              </Form.Group>
            </Col>

            <Col md='3'>
              <Form.Group className='required'>
                <Form.Label htmlFor='nome'>Estado Cívil</Form.Label>
                <Select
                  name='estadoCivil'
                  value={estadoCivil}
                  onChange={this.onChangeEstadoCivil}
                  options={estadoCivilOptions}
                  placeholder=''
                  noOptionsMessage={() => 'Nenhum Estado Cívil Encontrado'}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md='2'>
              <Form.Group className='required'>
                <Form.Label htmlFor='cpf'>CPF</Form.Label>
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                  className={invalidCPF ? 'form-control is-invalid' : 'form-control'}
                  id='cpf'
                  name='cpf'
                  value={cpf}
                  onChange={this.onChange}
                  onBlur={this.onBlurCPF}
                  required
                  readOnly={!!this.state.colaborador.id}
                />
                {invalidCPF && <div className='invalid-feedback'>Digite um CPF válido.</div>}
              </Form.Group>
            </Col>
            <Col md='2'>
              <Form.Group className='required'>
                <Form.Label htmlFor='rg'>RG</Form.Label>
                <Form.Control
                  type='text'
                  id='rg'
                  name='rg'
                  value={rg}
                  onChange={this.onChange}
                  required
                  maxLength={10}
                />
              </Form.Group>
            </Col>
            <Col md='3'>
              <Form.Group className='required'>
                <Form.Label htmlFor='rg'>Orgão Emissor</Form.Label>
                <Form.Control
                  type='text'
                  id='orgaoEmissor'
                  name='orgaoEmissor'
                  value={orgaoEmissor}
                  onChange={this.onChange}
                  required
                  maxLength={100}
                />
              </Form.Group>
            </Col>
            <Col md='3'>
              <Form.Group className='required'>
                <Form.Label htmlFor='nacionalidade'>Nacionalidade</Form.Label>
                <Form.Control
                  type='text'
                  id='nacionalidade'
                  name='nacionalidade'
                  value={nacionalidade}
                  onChange={this.onChange}
                  required
                  maxLength={50}
                />
              </Form.Group>
            </Col>

          </Row>

          <Row>
            <Col md='2'>
              <Form.Group className='required'>
                <Form.Label htmlFor='numeroCarteiraTrabalho'>Perfil</Form.Label>
                <Select
                  id='perfil'
                  name='perfil'
                  value={perfil}
                  options={perfis}
                  onChange={this.onChangePerfil}
                  placeholder=''
                  noOptionsMessage={() => 'Perfil não encontrado'}
                />
              </Form.Group>
            </Col>
            <Col md='2'>
              <Form.Group className='required'>
                <Form.Label htmlFor='nome'>Cargo</Form.Label>
                <Select
                  id='tipoCargo'
                  name='tipoCargo'
                  options={cargos}
                  value={cargo}
                  placeholder=''
                  onChange={this.onChangeCargo}
                  noOptionsMessage={() => 'Cargo não encontrado'}
                />
              </Form.Group>
            </Col>
            {cargo && cargo.tipo === cargoConstant.ATENDENTE.value && (
              <Col md='4'>
                <Form.Group className='required'>
                  <Form.Label htmlFor='numeroCarteiraTrabalho'>Carteira de Trabalho</Form.Label>
                  <MaskedInput
                    mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, '-', /\d/, /\d/]}
                    className={'form-control'}
                    id='numeroCarteiraTrabalho'
                    name='numeroCarteiraTrabalho'
                    value={numeroCarteiraTrabalho}
                    onChange={this.onChange}
                    required
                  />
                </Form.Group>
              </Col>
            )}
            {especialidades.length > 0 && (
              <Col md='4'>
                <Form.Label htmlFor='nome'>Especialidades</Form.Label>
                <FormControl style={formControlStyle}>
                  <MaterialSelect
                    multiple
                    value={especialidadeColaborador}
                    onChange={this.onChangeEspecialidade}
                    input={<Input />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {especialidades.map((espec) => (
                      <MenuItem key={espec.value} value={espec.label}>
                        <Checkbox className='text-primary' checked={especialidadeColaborador.includes(espec.label)} />
                        <ListItemText primary={espec.label} />
                      </MenuItem>
                    ))}
                  </MaterialSelect>
                </FormControl>
              </Col>
            )}
            {cargo && cargo.tipo === cargoConstant.DENTISTA.value && (
              <Col md='4'>
                <Form.Group className='required'>
                  <Form.Label htmlFor='nome'>CRO</Form.Label>
                  <Form.Control type='text' id='cro' name='cro' value={cro} onChange={this.onChange} required />
                </Form.Group>
              </Col>
            )}
          </Row>

          <Row>
            <Col md='3'>
              <Form.Group className='required'>
                <Form.Label htmlFor='rg'>Profissão</Form.Label>
                <Form.Control
                  type='text'
                  id='profissao'
                  name='profissao'
                  value={profissao}
                  onChange={this.onChange}
                  required
                  maxLength={50}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col>
              <h2 className='h5 border-bottom pb-3'>E-mails:</h2>
            </Col>
          </Row>
          {(emails.length > 0 ? emails : [{}]).map((email, index) => this.renderEmail(index, email))}
          <Row className='mt-4'>
            <Col>
              <h2 className='h5 border-bottom pb-3'>Telefones:</h2>
            </Col>
          </Row>
          {(telefones.length > 0 ? telefones : [{}]).map((telefone, index) => this.renderTelefone(index, telefone))}
          <Row className='mt-4'>
            <Col>
              <h2 className='h5 border-bottom pb-3'>Endereço:</h2>
            </Col>
          </Row>
          {this.renderEndereco(endereco ?? new Object())}
          <Button type='submit' variant='primary' className='float-right'>
            {this.state.colaborador.id ? 'Atualizar' : 'Cadastrar'}
          </Button>
          <Link to='/colaboradores'>
            <Button variant='light' className='float-right mr-3'>
              Cancelar
            </Button>
          </Link>
        </Form>
      </Card>
    )
  }

  renderLoading = () => <PageLoading />

  render() {
    
    const {selectedTab, colaborador} = this.state;

    return (
      <Fragment>
        <Row className='mb-3'>
          <Col>
            <h1 className='h3 m-0 text-secondary font-weight-bold'>Colaboradores</h1>
          </Col>
        </Row>
        {this.state.isLoading ? this.renderLoading() :

          <>
            <Tabs activeKey={selectedTab} onSelect={(tab) => this.handleSelectedTab(tab)}>
              <Tab eventKey={tabsConstants.dados} title={tabsConstants.dados}>
                {this.renderForm()}
              </Tab>
              <Tab eventKey={tabsConstants.documentos} title={tabsConstants.documentos}>
                <TabDocumentos colaboradorId={colaborador.id} />
              </Tab>
            </Tabs>
          </>
        }
      </Fragment>
    )
  }
}

export default ColaboradoresForm

///////////////////////////////////////////
///////////////// STYLES //////////////////
///////////////////////////////////////////
const formControlStyle = {
  width: '100%',
  borderRadius: '5px',
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

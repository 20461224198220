import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { editarPaciente } from '../../services/actions/pacientes.actions'

import PacientesList from '../../components/Pacientes/PacientesList'

const mapStateToProps = (store) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({ editarPaciente }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PacientesList)

import React from 'react'
import '@fortawesome/fontawesome-free/css/all.css'
import SidebarItem from './SidebarItem'
import { withRouter } from 'react-router-dom'

const Sidebar = (props) => {
  const { menus, mesesAConfigurar, toggleSidebar, requestAcoes, location } = props
  const visibleMenus = mesesAConfigurar && mesesAConfigurar.length > 0 ? [] : menus

  return (
    <div className={toggleSidebar ? 'sidebar sidebar-toggle' : 'sidebar'}>
      <ul className='list-unstyled'>
        {visibleMenus.map((menu) => {
          return <SidebarItem item={menu} requestAcoes={requestAcoes} rotaAtual={location.pathname} />
        })}
      </ul>
    </div>
  )
}

export default withRouter(Sidebar)

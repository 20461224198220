import React, { Fragment } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { formatMoney } from '../../../../utils/string.utils'

export default function Resumo({
  handleEfetuarPagamento,
  total,
  totalCalculado,
  desconto,
  juros,
  multa,
  nextStep,
  previousStep,
  formaPagamento,
}) {
  const handleImprimir = async () => {
    const confirmado = await handleEfetuarPagamento()
    confirmado && nextStep()
  }

  return (
    <div className='px-5 mx-auto text-center'>
      <Fragment>
        <h3 className='text-secondary font-weight-bold'>Resumo do pagamento</h3>
        <div
          style={{ width: '370px' }}
          className='d-flex flex-column align-items-start my-4 pl-3 pt-3 border rounded-0 shadow-sm bg-white mx-auto'
        >
          <div className='d-flex mb-3'>
            <strong>Valor das parcelas:</strong> <span className='ml-2'>{total}</span>
          </div>
          <div className='d-flex mb-3'>
            <strong>Desconto:</strong> <span className='ml-2'>{desconto}</span>
          </div>
          <div className='d-flex mb-3'>
            <strong>Juros por atraso:</strong> <span className='ml-2'>{juros}</span>
          </div>
          <div className='d-flex mb-3'>
            <strong>Multa por atraso:</strong> <span className='ml-2'>{multa}</span>
          </div>
          <div className='d-flex mb-3'>
            <strong>Valor final:</strong> <span className='ml-2'>{totalCalculado}</span>
          </div>
          {formaPagamento && (
            <div className='d-flex mb-3'>
              <strong>Forma de pagamento:</strong> <span className='ml-2'>{formaPagamento.label}</span>
            </div>
          )}
        </div>
        <div className='d-flex justify-content-between'>
          <div style={backContainerStyle} onClick={previousStep}>
            <Button variant='outline-primary' style={backStyle}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <span className='text-primary'>Voltar</span>
          </div>
          <Button onClick={handleImprimir}>Confirmar Pagamento</Button>
        </div>
      </Fragment>
    </div>
  )
}

const backContainerStyle = {
  cursor: 'pointer',
}

const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

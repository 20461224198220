import React, { useState, useEffect } from 'react'
import StepWizard from 'react-step-wizard'
import Swal from 'sweetalert2'

import { formatMoney } from '../../../../utils/string.utils'
import { printPaymentReceipt } from '../../../Templates/PrintableReceipt'
import financeiroService from '../../../../services/financeiro.service'
import Pagamento from './Pagamento'
import Impressao from './Impressao'

const Cancelamento = ({
  selectedPlano,
  nomeColaborador,
  paciente,
  isLoading,
  setIsLoading,
  firstStep,
  getParcelasPagas,
  passos,
  currentStep,
}) => {
  const [paymentRecord, setPaymentRecord] = useState(null)
  const [selectedParcelas, setSelectedParcelas] = useState([])
  const [agrupamentosParcelas, setAgrupamentoParcelas] = useState([])
  const [motivo, setMotivo] = useState('')
  const [totalParcelas, setTotalParcelas] = useState(0)

  useEffect(() => {
    if (passos.CANCELAR_PAG === currentStep) {
      fetchAgrupamentosParcelasPagas(selectedPlano.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, selectedPlano])

  useEffect(() => {
    getSomaParcelasSelecioandas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParcelas])

  const fetchAgrupamentosParcelasPagas = async (planoId) => {
    setIsLoading(true)
    try {
      const { data } = await financeiroService.getParcelasPagas(planoId)
      setAgrupamentoParcelas(data)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const handleEfetuarCancelamento = async () => {
    let pagamentosSalvar = []
    Object.values(selectedParcelas).forEach((parcelas) => {
      pagamentosSalvar = pagamentosSalvar.concat(parcelas)
    })
    const qntdParcelas = pagamentosSalvar.length === 1 ? '1 parcela' : `${pagamentosSalvar.length} parcelas`
    const { value: password } = await Swal.fire({
      title: `Deseja confirmar o cancelamento de ${qntdParcelas} pagamentos no valor total de ${formatMoney(
        totalParcelas
      )}?`,
      type: 'question',
      input: 'password',
      inputPlaceholder: 'Digite sua senha',
      inputAttributes: { autocapitalize: 'off', autocorrect: 'off' },
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#3085d6',
    })
    if (password) {
      setIsLoading(true)
      try {
        let parcelasPayload = []
        Object.values(agrupamentosParcelas).forEach((parcelamento) => {
          parcelasPayload = parcelasPayload.concat(
            parcelamento.parcelas
              .filter((parcela) => pagamentosSalvar.includes(parcela.pagamentoParcelaId))
              .map((parcela) => ({
                ...parcela,
                senhaColaborador: password,
                motivoCancelamento: motivo,
              }))
          )
        })
        const { data } = await financeiroService.salvarCancelamentoPagamento(parcelasPayload)
        setPaymentRecord(data)
      } catch (e) {
        setIsLoading(false)
        return false
      }
      setIsLoading(false)
      return true
    }
    return false
  }

  const selecionarTodasParcelas = (grupo) => {
    const todasSelecionadas =
      selectedParcelas[grupo] &&
      selectedParcelas[grupo].length > 0 &&
      selectedParcelas[grupo].length === agrupamentosParcelas[grupo].parcelas.length
    const novasParcelas = [...selectedParcelas]
    if (todasSelecionadas) {
      novasParcelas[grupo] = undefined
    } else {
      novasParcelas[grupo] = agrupamentosParcelas[grupo].parcelas.map((parcela) => parcela.pagamentoParcelaId)
    }
    setSelectedParcelas([...novasParcelas])
  }

  const toggleParcela = (parcelaId, grupo, indexGeral) => {
    const novasParcelas = [...selectedParcelas]
    let parcelasGrupo = []
    if (!!selectedParcelas[grupo]) {
      parcelasGrupo = [...novasParcelas[grupo]]
    }
    const indiceParcela = parcelasGrupo.indexOf(parcelaId)
    if (indiceParcela >= 0) {
      parcelasGrupo = parcelasGrupo.slice(indiceParcela + 1)
    } else {
      const arr = agrupamentosParcelas[grupo].parcelas.slice(indexGeral)

      parcelasGrupo = [...arr.map((parcela) => parcela.pagamentoParcelaId)]
    }
    novasParcelas[grupo] = parcelasGrupo
    setSelectedParcelas([...novasParcelas])
  }

  const handlePrint = () => {
    const { dataPagamento, valorOriginal, valorPago } = paymentRecord
    printPaymentReceipt({
      dataPagamento,
      nomeContratante: paciente.nome,
      valorParcelas: valorOriginal,
      valorMultas: 0,
      valorJuros: 0,
      valorDescontos: 0,
      totalPago: valorPago,
      nomeColaborador,
    })
  }

  const getSomaParcelasSelecioandas = () => {
    let result = 0.0
    agrupamentosParcelas.forEach((p, i) => {
      p.parcelas.forEach((parcela) => {
        if (selectedParcelas[i] && selectedParcelas[i].includes(parcela.pagamentoParcelaId)) {
          result += parcela.valorPago
        }
      })
    })
    setTotalParcelas(result)
  }

  const clearPagamento = async () => {
    setIsLoading(true)
    setPaymentRecord(null)
    setSelectedParcelas([])
    try {
      await getParcelasPagas(selectedPlano.id)
    } catch (error) {}
    setIsLoading(false)
  }

  const handleMotivo = ({ target }) => {
    setMotivo(target.value)
  }

  return (
    <div className='mb-5 pb-5 px-3'>
      <StepWizard isLazyMount>
        <Pagamento
          totalParcelas={totalParcelas}
          parcelas={agrupamentosParcelas}
          selecionarTodasParcelas={selecionarTodasParcelas}
          selectedParcelas={selectedParcelas}
          toggleParcela={toggleParcela}
          handleEfetuarCancelamento={handleEfetuarCancelamento}
          handleFirstStep={firstStep}
          isLoading={isLoading}
          motivo={motivo}
          setMotivo={handleMotivo}
        />
        <Impressao clearPagamento={clearPagamento} onBack={firstStep} handlePrint={handlePrint} />
      </StepWizard>
    </div>
  )
}

export default Cancelamento

import React, { Fragment } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { formatMoney } from '../../../../utils/string.utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'

export default function ListaSuprimentos({ suprimentosSemPagamento, handleSelectSuprimento, handleGoBackListaPlanos }) {
  return (
    <Fragment>
      {suprimentosSemPagamento && suprimentosSemPagamento.length > 0 ? (
        <Table size='sm' bordered hover>
          <thead>
            <tr>
              <th>Suprimento</th>
              <th width='150px' className='text-center'>
                Valor
              </th>
              <th width='90px' className='text-center'>
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            {suprimentosSemPagamento.map((suprimento, index) => (
              <tr key={index}>
                <td>{suprimento.descricao || '-'}</td>
                <td className='text-center'>{formatMoney(suprimento.valor)}</td>
                <td className='text-center d-flex justify-content-around'>
                  <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Definir pagamento</Tooltip>}>
                    <Button onClick={() => handleSelectSuprimento(suprimento)} variant='primary' size='sm'>
                      <FontAwesomeIcon icon={faDollarSign} />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div>Nenhum suprimento de manutenção sem pagamento definido.</div>
      )}
      <Button variant='outline-primary' onClick={handleGoBackListaPlanos}>
        Voltar
      </Button>
    </Fragment>
  )
}

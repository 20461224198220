import React, { Fragment, useEffect, useState, useReducer } from 'react'
import StepWizard from 'react-step-wizard'

import AtendimentoService from '../../services/atendimento.service'

import SelecaoProcedimentos from './SelecaoProcedimentos'
import PageLoading from '../Common/PageLoading'
import Navigation from './Navigation'
import Financeiro from './Financeiro'
import Impressao from './Impressao'

import { statusAvaliacao } from '../../constants/agendamento.constants'
import { toast } from 'react-toastify'

export default function Atendimento(props) {
  const { agendamento, history } = props

  const [isLoading, setIsLoading] = useState(true)
  const [numeroPlano, setNumeroPlano] = useState('')
  const [planoId, setPlanoId] = useState('')
  const [wizardRef, setWizardRef] = useState(null)
  const [passo, setPasso] = useState(1)
  const [avaliacao, setAvaliacao] = useState({})
  const [procedimentosSelecionados, setProcedimentosSelecionados] = useState([])
  const [pagamentos, dispatchPagamento] = useReducer((pagamentos, { type, value }) => {
    const { pagamento, index } = value
    switch (type) {
      case 'add':
        return [...pagamentos, pagamento]
      case 'update':
        const atualizados = [...pagamentos]
        atualizados.splice(index, 1, pagamento)
        return atualizados
      default:
        return pagamentos
    }
  }, [])

  useEffect(() => {
    if (!agendamento.id) {
      // history.push('/agendamento')
      history.goBack()
    }
    wizardDidMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (avaliacao.procedimentos) {
      setProcedimentosSelecionados([...avaliacao.procedimentos.filter((p) => p.efetivado)])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avaliacao.procedimentos])

  const setStepWizard = (wizard) => setWizardRef(wizard)

  const wizardDidMount = async () => {
    agendamento.id && (await getAvaliacao())
  }

  const getAvaliacao = async () => {
    const { data } = await AtendimentoService.getAvaliacaoByAgendamento(agendamento.id)
    setAvaliacao(data)
    setIsLoading(false)
  }

  const onStepChange = (passo) => setPasso(passo.activeStep)

  const renderLoading = () => <PageLoading />

  const toggleProcedimento = (procedimento, denteId, index) => {
    let procedimentos = [...avaliacao.procedimentos]
    let procedimentoIncluso = procedimentos.find((p) => p === procedimento)
    procedimentoIncluso.efetivado = !procedimentoIncluso.efetivado
    procedimentos[index] = procedimentoIncluso
    setAvaliacao({ ...avaliacao, procedimentos })
  }

  const adiarDecisaoProcedimentos = () => {
    history.goBack()
  }

  const recusarProcedimentos = async () => {
    try {
      await AtendimentoService.naoRealizarProcedimentos(avaliacao.id)
      history.goBack()
      toast('Avaliação alterada com sucesso.', { type: toast.TYPE.SUCCESS })
    } catch (e) {
      console.error(e)
    }
  }

  const setDescricao = (descricao) => setAvaliacao({ ...avaliacao, descricao })

  const setRetornoAvaliacao = (retorno) => setAvaliacao({ ...avaliacao, dataRetornoPaciente: retorno })

  const gerarPlano = async () => {
    try {
      const { data } = await AtendimentoService.salvarAtendimento({
        avaliacao: { ...getAvaliacaoBody(), procedimentos: procedimentosSelecionados },
        pagamentos: pagamentos.filter((pag) => !pag.avaliacaoProcedimentoProteticoId),
        pagamentosProteticos: pagamentos.filter((pag) => pag.avaliacaoProcedimentoProteticoId),
      })
      setNumeroPlano(data.numeroPlano)
      setPlanoId(data.id)
      wizardRef.nextStep()
    } catch (error) {}
  }

  const getAvaliacaoBody = () => ({
    ...avaliacao,
    statusAvaliacao: statusAvaliacao.EFETIVADA,
    dataRetornoPaciente:
      typeof avaliacao.dataRetornoPaciente === 'string'
        ? avaliacao.dataRetornoPaciente
        : avaliacao.dataRetornoPaciente.toISOString(),
  })

  const handleChangePagamento = (pagamento, index) => {
    if (!pagamentos[index]) {
      dispatchPagamento({ type: 'add', value: { pagamento, index } })
    } else {
      dispatchPagamento({ type: 'update', value: { pagamento, index } })
    }
  }

  return (
    <Fragment>
      {isLoading ? (
        renderLoading()
      ) : (
        <Fragment>
          <StepWizard isLazyMount onStepChange={onStepChange} instance={setStepWizard}>
            <SelecaoProcedimentos
              incluirProcedimento={toggleProcedimento}
              procedimentos={avaliacao.procedimentos || []}
              descricao={avaliacao.descricao}
              setDescricao={setDescricao}
              setRetornoAvaliacao={setRetornoAvaliacao}
            />
            <Financeiro procedimentosSelecionados={procedimentosSelecionados} updatePagamento={handleChangePagamento} avaliacao={avaliacao} />
            <Impressao
              planoId={planoId}
              numeroPlano={numeroPlano}
              avaliacao={avaliacao}
              pagamentos={pagamentos}
              procedimentosSelecionados={procedimentosSelecionados}
            />
          </StepWizard>
          <Navigation
            {...props}
            handleDecidirDepois={adiarDecisaoProcedimentos}
            recusarProcedimentos={recusarProcedimentos}
            finalizar={gerarPlano}
            procedimentosSelecionados={procedimentosSelecionados}
            passo={passo}
            wizard={wizardRef}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

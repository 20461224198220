import AxiosClient from './axios-client'
import { saveAs } from 'file-saver'
import { filtroNaoSimAmbos } from '../constants/common.constants'

class OrtodontiaService {
  getPlanosCadastrados() {
    return AxiosClient.get('PlanoOrtodontia/getAll')
  }

  cadastrarPlano(plano) {
    return AxiosClient.post('PlanoOrtodontia/salvar', plano)
  }

  getAparelhosCadastrados(trazerAparelhoComplementar = filtroNaoSimAmbos.NAO.value) {
    return AxiosClient.get(`Aparelho/getAll/${trazerAparelhoComplementar}`)
  }

  cadastrarAparelho(aparelho) {
    return AxiosClient.post('Aparelho/salvar', aparelho)
  }

  getAparelhoById(id) {
    return AxiosClient.get(`Aparelho/getById/${id}`)
  }

  getClinicasCadastradas() {
    return AxiosClient.get('ClinicaRadiologia/getAll')
  }

  cadastrarClinica(clinicaRadiologica) {
    return AxiosClient.post('ClinicaRadiologia/salvar', clinicaRadiologica)
  }

  async toggleAtivarClinica(clinica) {
    return await AxiosClient.put(`ClinicaRadiologia/ativar/${clinica.id}/${!clinica.ativo}`)
  }

  async toggleAtivarPlano(plano) {
    return await AxiosClient.put(`PlanoOrtodontia/ativar/${plano.id}/${!plano.ativo}`)
  }

  async toggleAtivarAparelho(aparelho) {
    return await AxiosClient.put(`Aparelho/ativar/${aparelho.id}/${!aparelho.ativo}`)
  }

  getAvaliacaoById(id) {
    return AxiosClient.get(`AvaliacaoOrtodontia/getById/${id}`)
  }

  getAvaliacoesPaciente(pacienteId) {
    return AxiosClient.get(`AvaliacaoOrtodontia/getByPacienteId/${pacienteId}`)
  }

  getAvaliacoesDependente(dependenteId) {
    return AxiosClient.get(`AvaliacaoOrtodontia/getByDependenteId/${dependenteId}`)
  }

  salvarAvaliacao(avaliacaoOrtodontia) {
    delete avaliacaoOrtodontia.anamneseFicha
    return AxiosClient.post(`AvaliacaoOrtodontia/salvar`, { ...avaliacaoOrtodontia })
  }

  gerarPlanoTratamentoOrtodontico(avaliacao, pagamento, pagamentoAparelho) {
    return AxiosClient.post(`AvaliacaoOrtodontia/gerarPlanoDeTratamentoOrtodontia`, {
      avaliacao,
      pagamento,
      pagamentoAparelho: pagamentoAparelho,
    })
  }

  getAllAgendamentoRadiologia(filtros, numeroPagina, registrosPorPagina) {
    return AxiosClient.post('AgendamentoRadiologia/getAll', {
      filtros,
      numeroPagina,
      registrosPorPagina,
    })
  }

  async toggleStatusAgendamentoRadiologico(agendamento) {
    return await AxiosClient.put(
      `AgendamentoRadiologia/AlterarStatusRadiografia/${agendamento.id}/${!agendamento.radiografiaPronta}`
    )
  }

  async downloadRadiologia(nomeArquivo, agendamentoRadiologiaId) {
    try {
      const endpoint = `AgendamentoRadiologia/downloadRadiologia?agendamentoRadiologiaId=${agendamentoRadiologiaId}&nomeArquivo=${nomeArquivo}`
      const { data } = await AxiosClient.get(endpoint, { responseType: 'blob' })
      const file = new Blob([data])
      saveAs(file, nomeArquivo)
    } catch (error) {
      console.error(error)
    }
  }

  async uploadRadiologia(formData) {
    return await AxiosClient.put(`AgendamentoRadiologia/uploadRadiologia/`, formData)
  }

  async deleteArquivoRadiologia(agendamentoRadiologiaArquivoId) {
    return await AxiosClient.put(`AgendamentoRadiologia/removerArquivoRadiologia/${agendamentoRadiologiaArquivoId}`)
  }

  getPlanosTratamentoByPaciente(pacienteId) {
    return AxiosClient.get(`PlanoTratamentoOrtodontia/getByPacienteId/${pacienteId}`)
  }

  getPlanosTratamentoByDependente(dependenteId) {
    return AxiosClient.get(`PlanoTratamentoOrtodontia/getByDependenteId/${dependenteId}`)
  }

  getPlanosTratamentoById(planoId) {
    return AxiosClient.get(`PlanoTratamentoOrtodontia/getById/${planoId}`)
  }

  salvarSuprimento(suprimento) {
    return AxiosClient.post(`PlanoTratamentoOrtodontia/salvarSuprimento`, suprimento)
  }

  salvarPagamentoSuprimento(pagamentoSuprimento) {
    return AxiosClient.post(`PlanoTratamentoOrtodontia/salvarPagamentoSuprimento`, { ...pagamentoSuprimento })
  }

  salvarPagamentoAparelho(pagamentoAparelho) {
    return AxiosClient.post('PlanoTratamentoOrtodontia/salvarPagamentoAparelhoComplementar', pagamentoAparelho)
  }

  salvarAparelhoComplementar(payload) {
    return AxiosClient.post(`PlanoTratamentoOrtodontia/salvarAparelhoComplementar`, payload)
  }

  recusarAparelho(planoTratamentoOrtodontiaAparelhoComplementarId) {
    return AxiosClient.post(
      'PlanoTratamentoOrtodontia/recusarAparelhoComplementar',
      planoTratamentoOrtodontiaAparelhoComplementarId
    )
  }

  async alterarStatusPlano(planoId, status) {
    return await AxiosClient.put(`PlanoTratamentoOrtodontia/alterarStatus/${planoId}/${status}`)
  }

  getParcelasByPlanoTratamentoId(planoTratamentoId, trazerVencidas = true) {
    return AxiosClient.get(
      `PagamentoOrtodontia/getParcelasByPlanoTratamentoId/${planoTratamentoId}?trazerVencidas=${trazerVencidas}`
    )
  }

  async cancelarPlanoTratamento(pagamento) {
    const creditoDevolvido = pagamento.creditoDevolvido ? pagamento.creditoDevolvido : 0
    return await AxiosClient.put(`PlanoTratamentoOrtodontia/cancelar/${creditoDevolvido}`, pagamento)
  }

  salvarPagamentoOrtodontia(parcelas, senha, formaPagamento) {
    return AxiosClient.post('PagamentoOrtodontia/salvar', {
      parcelas,
      senha,
      formaPagamento,
    })
  }

  getPagamentosEfetuados(planoTratamentoId) {
    return AxiosClient.get(`PagamentoOrtodontia/getByPlanoTratamentoId/${planoTratamentoId}`)
  }

  async cancelarPagamentosEfetuados(parcelas, senha) {
    return await AxiosClient.put('PagamentoOrtodontia/cancelarPagamentos', { parcelas, senha })
  }

  async alterarVencimentoParcelas(parcelas) {
    return await AxiosClient.put('PagamentoOrtodontia/alterarVencimentoParcelas', parcelas)
  }

  async salvarRenegociacao(parcelasCanceladas, pagamentoRenegociado) {
    return await AxiosClient.put('PagamentoOrtodontia/salvarRenegociacao', { parcelasCanceladas, pagamentoRenegociado })
  }

  validarReagendar(avaliacaoOrtodontiaId) {
    return AxiosClient.get(`AgendamentoRadiologia/validarReagendar/${avaliacaoOrtodontiaId}`)
  }

  salvarReagendamentoRadiologia(agendamento) {
    return AxiosClient.post(`AgendamentoRadiologia/salvar`, agendamento)
  }

  async downloadTemplateAsPDF(planoTratamentoId, tipoTemplate) {
    try {
      const endpoint = `PlanoTratamentoOrtodontia/downloadFile/${planoTratamentoId}/${tipoTemplate}`
      const { data } = await AxiosClient.get(endpoint, { responseType: 'arraybuffer' })
      const file = new Blob([data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    } catch (e) {}
  }

  async marcarImpresso(pagamentoId) {
    return await AxiosClient.put(`PagamentoOrtodontia/marcarImpresso/${pagamentoId}`)
  }

  getParcelasPlanoOrtodontia(planoId) {
    return AxiosClient.get(`PlanoTratamentoOrtodontia/getParcelasPlanoOrtodontia/${planoId}`)
  }

  salvarAtendimento(atendimento) {
    return AxiosClient.post(`AtendimentoOrtodontia/salvar`, atendimento)
  }

  getSuprimentosPlanoOrtodontia(planoId) {
    return AxiosClient.get(`PlanoTratamentoOrtodontia/getSuprimentosPlanoOrtodontia/${planoId}`)
  }

  getAparelhosComplementaresPlanoOrtodontia(planoId) {
    return AxiosClient.get(`PlanoTratamentoOrtodontia/getAparelhosComplementaresPlanoOrtodontia/${planoId}`)
  }

  getHistoricoAtendimentos(planoId, dateFilterDe, dateFilterAte, colaboradorSelecionado) {
    const filtros = []
    planoId && filtros.push({ campo: 'planoId', valor: planoId })
    dateFilterDe && filtros.push({ campo: 'dataInicial', valor: dateFilterDe })
    dateFilterAte && filtros.push({ campo: 'dataFinal', valor: dateFilterAte })
    colaboradorSelecionado && filtros.push({ campo: 'colaboradorId', valor: colaboradorSelecionado })
    return AxiosClient.post(`PlanoTratamentoOrtodontia/getHistoricoAtendimentoOrtodontia`, {
      filtros,
    })
  }
}

export default new OrtodontiaService()

import React from 'react'
import { Button, Table, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

import OrtodontiaService from '../../../services/ortodontia.service'
import { useState } from 'react'

export default function ArquivosRadiologia({ selectedAgendamento, arquivos }) {
  const [isLoading, setIsLoading] = useState(false)
  const download = async (nome) => {
    setIsLoading(true)
    try {
      await OrtodontiaService.downloadRadiologia(nome, selectedAgendamento.id)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const renderList = () => {
    return (
      <Table size='sm' bordered hover>
        <thead>
          <tr>
            <th>Arquivo</th>
            <th className='text-center' width='150px'>
              Download
            </th>
          </tr>
        </thead>
        <tbody>
          {arquivos.map((a, i) => {
            return (
              <tr key={i}>
                <td>{a.nome}</td>
                <td className='d-flex align-items-center justify-content-around'>
                  <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Baixar arquivo</Tooltip>}>
                    <Button onClick={() => download(a.nome)} size='sm'>
                      {isLoading ? (
                        <Spinner animation='border' size='sm' variant='primary' />
                      ) : (
                        <FontAwesomeIcon icon={faDownload} />
                      )}
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
  return (
    <div className='mt-4'>
      {arquivos && arquivos.length > 0 ? (
        renderList()
      ) : (
        <div className='alert alert-primary w-50'>Nenhum arquivo nos registros</div>
      )}
    </div>
  )
}

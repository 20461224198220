import React, { Fragment, Component } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Button, Table, Form, OverlayTrigger, Tooltip, ButtonGroup } from 'react-bootstrap'
import Moment from 'moment'
import formatCpf from '@brazilian-utils/format-cpf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPowerOff } from '@fortawesome/free-solid-svg-icons'

import MenuContext from '../../contexts/MenuContexts'
import PageLoading from '../Common/PageLoading'
import PermissoesConstants from '../../constants/permissoes.constants'
import ColaboradoresService from '../../services/colaboradores.service'
import AxiosClient from '../../services/axios-client'
import { toast } from 'react-toastify'

class ColaboradoresList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInativos: false,
      filteredColaboradores: [],
      colaboradores: [],
      isLoading: false,
    }
  }

  componentDidMount() {
    this.fetchColaboradores()
  }

  fetchColaboradores = () => {
    this.setState({ ...this.state, isLoading: true }, async () => {
      try {
        const { data } = await ColaboradoresService.getAllColaboradores()
        this.setState({ ...this.state, colaboradores: data, isLoading: false })
      } catch (e) {
        this.setState({ ...this.state, isLoading: false })
      }
    })
  }

  editColaborador = (id) => {
    this.props.editarColaborador(id)
    this.props.history.push('/colaboradores/cadastrar')
  }

  toggleColaborador = (id, bloquear) => this.props.toggleColaborador(id, bloquear)

  desativar = (colaboradorId, ativar) => {
    this.setState({ ...this.state, isLoading: true }, async () => {
      try {
        await AxiosClient.put(`Colaborador/Ativar/${colaboradorId}/${ativar}`)
        this.fetchColaboradores()
      } catch (e) {}
    })
  }

  filterColaboradores = (e) => {
    let filteredColaboradores = []
    if (e.target.value !== '') {
      filteredColaboradores = [...this.state.colaboradores]
      filteredColaboradores = filteredColaboradores.filter(function(c) {
        return c.nome.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      })
    }
    filteredColaboradores.length === 0 &&
      e.target.value &&
      toast('Nenhum colaborador encontrado', { type: toast.TYPE.WARNING })
    this.setState({ ...this.state, filteredColaboradores })
  }

  filterInativos = (colaboradores, status) => {
    return colaboradores.filter((p) => p.ativo === status)
  }

  toggleInativos = (showInativos) => {
    this.setState({ showInativos })
  }

  renderLoading = () => <PageLoading />

  renderLista = (visibleColaboradores, acoesPermitidas) => (
    <Fragment>
      {visibleColaboradores.length > 0 ? (
        <Table bordered hover className='bg-white'>
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>Cargo</th>
              <th>Data de nascimento</th>
              {Object.keys(acoesPermitidas).length > 0 && (
                <th className='text-center' width='110px'>
                  Ações
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {visibleColaboradores.map((colaborador, index) => (
              <tr key={index}>
                <td>{colaborador.nome}</td>
                <td>{formatCpf(colaborador.cpf)}</td>
                <td>{colaborador.cargo.nome}</td>
                <td>{Moment(colaborador.dataNascimento).format('DD/MM/YYYY')}</td>
                {Object.keys(acoesPermitidas).length > 0 && (
                  <td className='text-center d-flex justify-content-around'>
                    {acoesPermitidas[PermissoesConstants.EDITAR] && (
                      <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Editar Colaborador</Tooltip>}>
                        <Button onClick={() => this.editColaborador(colaborador.id)} size='sm'>
                          <FontAwesomeIcon icon={faPen} />
                        </Button>
                      </OverlayTrigger>
                    )}
                    {acoesPermitidas[PermissoesConstants.ATIVAR_INATIVAR] && (
                      <OverlayTrigger
                        trigger='hover'
                        placement='top'
                        overlay={
                          <Tooltip>{colaborador.ativo ? 'Desativar colaborador' : 'Ativar colaborador'}</Tooltip>
                        }
                      >
                        <Button
                          onClick={() => this.desativar(colaborador.id, !colaborador.ativo)}
                          variant={colaborador.ativo ? 'success' : 'danger'}
                          size='sm'
                        >
                          <FontAwesomeIcon icon={faPowerOff} />
                        </Button>
                      </OverlayTrigger>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div>Nenhum colaborador Cadastrado.</div>
      )}
    </Fragment>
  )

  render() {
    const { isLoading, filteredColaboradores, colaboradores, showInativos } = this.state
    let { resetColaborador } = this.props
    let visibleColaboradores = filteredColaboradores.length > 0 ? filteredColaboradores : colaboradores
    visibleColaboradores = this.filterInativos(visibleColaboradores, !showInativos)
    let acoesPermitidas = {}
    return (
      <MenuContext.Consumer>
        {(acoes) => {
          acoes.map((a) => {
            acoesPermitidas[`${a.nome}`] = a
            return true
          })
          return (
            <div>
              <Row className='mb-4'>
                <Col>
                  <h1 className='h3 m-0 text-secondary font-weight-bold'>Colaboradores</h1>
                </Col>
                <Col className='d-flex justify-content-between'>
                  <Form.Control type='text' onChange={this.filterColaboradores} placeholder='Filtrar por nome' />
                  <ButtonGroup aria-label='Basic example' className='ml-2'>
                    <Button
                      onClick={() => this.toggleInativos(false)}
                      variant={showInativos ? 'outline-primary' : 'primary'}
                    >
                      Ativos
                    </Button>
                    <Button
                      onClick={() => this.toggleInativos(true)}
                      variant={!showInativos ? 'outline-primary' : 'primary'}
                    >
                      Inativos
                    </Button>
                  </ButtonGroup>
                </Col>
                <Col>
                  {acoesPermitidas[PermissoesConstants.ADICIONAR] && (
                    <Link onClick={resetColaborador} to='/colaboradores/cadastrar'>
                      <Button className='float-right'>Cadastrar colaborador</Button>
                    </Link>
                  )}
                </Col>
              </Row>
              {isLoading ? this.renderLoading() : this.renderLista(visibleColaboradores, acoesPermitidas)}
            </div>
          )
        }}
      </MenuContext.Consumer>
    )
  }
}

export default ColaboradoresList

import React, { Fragment, useState, useEffect } from 'react'
import { Button, Col, Row, Table, Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Select from 'react-select'
import moment from 'moment'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { formatMoney } from '../../../utils/string.utils'
import OrtodontiaService from '../../../services/ortodontia.service'

export default function AparelhosComplementares({
  setIsLoading,
  selectedPlano,
  aparelhosComplementaresOptions,
  isOpen,
  handleClose,
}) {
  const [selectedAparelho, setSelectedAparelho] = useState(null)
  const [aparelhosAdicionados, setAparelhosAdicionados] = useState([])
  const [historico, setHistorico] = useState([])

  useEffect(() => {
    if (isOpen && selectedPlano?.id) {
      getHistorico()
    }
  }, [isOpen])

  const getHistorico = async () => {
    setIsLoading(true)
    try {
      const { data } = await OrtodontiaService.getAparelhosComplementaresPlanoOrtodontia(selectedPlano.id)
      setHistorico(data)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      await OrtodontiaService.salvarAparelhoComplementar(aparelhosAdicionados)
      handleCancel()
      toast('Aparelhos adicionados ao plano de tratamento', { type: toast.TYPE.SUCCESS })
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleApply = () => {
    if (selectedAparelho) {
      setAparelhosAdicionados([
        {
          valor: selectedAparelho.valorVenda,
          planoTratamentoOrtodontiaId: selectedPlano.id,
          aparelhoComplementarId: selectedAparelho.id,
          descricao: selectedAparelho.nome,
        },
        ...aparelhosAdicionados,
      ])
      setSelectedAparelho(null)
    } else {
      toast('Nenhum aparelho selecionado.', { type: toast.TYPE.ERROR })
    }
  }

  const handleCancel = () => {
    handleClose()
    setSelectedAparelho(null)
    setAparelhosAdicionados([])
    setHistorico([])
  }

  const handleChangeSelect = (option) => {
    setSelectedAparelho(option)
  }

  const handleRemove = (index) => {
    const newAdicionados = [...aparelhosAdicionados]
    newAdicionados.splice(index, 1)
    setAparelhosAdicionados(newAdicionados)
  }

  const getListItems = () => [...aparelhosAdicionados, ...historico]

  return (
    <Modal className='modal-agenda' centered show={isOpen} onHide={handleClose}>
      <Card>
        <Modal.Body className='p-4'>
          <h5 className='mb-4 text-secondary font-weight-bold'>Aparelhos Complementares</h5>
          <Row>
            <Col md='10'>
              <Select
                onChange={handleChangeSelect}
                value={selectedAparelho}
                options={aparelhosComplementaresOptions}
                noOptionsMessage={() => 'Sem opções'}
                placeholder='Selecionar...'
              />
            </Col>
            <Col md='2'>
              <Button onClick={handleApply} className='d-block ml-auto'>
                Aplicar
              </Button>
            </Col>
          </Row>
          <h5 className='mt-4 text-secondary font-weight-bold'>Histórico</h5>
          <Table className='bg-white' bordered hover>
            <thead>
              <tr>
                <th>Descrição</th>
                <th className='text-center' width='150px'>
                  Valor
                </th>
                <th className='text-center' width='120px'>
                  Data / Hora
                </th>
                <th className='text-center' width='100px'>
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              {getListItems().length > 0 ? (
                getListItems().map(({ descricao, valor, dataCriacao, id }, index) => (
                  <tr key={index}>
                    <td>{descricao}</td>
                    <td className='text-center'>{formatMoney(valor)}</td>
                    <td className='text-center'>
                      {dataCriacao ? (
                        <Fragment>
                          <p className='m-0'>{moment(dataCriacao).format('DD/MM/YYYY')}</p>
                          <p className='m-0'>{moment(dataCriacao).format('HH:mm')}</p>
                        </Fragment>
                      ) : (
                        '--'
                      )}
                    </td>
                    <td className='text-center'>
                      {!id && (
                        <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Remover Aparelho</Tooltip>}>
                          <Button
                            onClick={() => handleRemove(index)}
                            variant='danger'
                            className='mx-auto d-block'
                            size='sm'
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </OverlayTrigger>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan='4'>Nenhum histórico disponível</td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className='d-flex justify-content-end'>
            <Button variant='outline-primary' onClick={handleCancel}>
              Cancelar
            </Button>
            <Button onClick={handleSave} className='ml-2'>
              Salvar Alterações
            </Button>
          </div>
        </Modal.Body>
      </Card>
    </Modal>
  )
}

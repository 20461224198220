import React, { Fragment, useEffect, useState } from 'react'
import { Button, Form, Table, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import PageLoading from '../Common/PageLoading'
import { statusProcedimento } from '../../constants/common.constants'
import { statusConstants } from '../../constants/agendamento.constants'
import AgendamentoService from '../../services/agendamentos.service'
import { format } from 'date-fns'
import { toast } from 'react-toastify'

const DESCRICAO = 'descricao'
const STATUS_PROCEDIMENTO = 'statusProcedimento'

const EditarPlanoDeTratamento = (props) => {
  const { isLoading, selectedPlano, handleBackClick, handleSaveClick, agendamento, handleMensagemWebsocket } = props

  const [procedimentos, setProcedimentos] = useState([]) 
  const [isLoadingSave, setLoadingSave] = useState(false)
  const [naoFinalizouProcedimento, setNaoFinalizouProcedimento] = useState(true)
  const statusOptions = Object.values(statusProcedimento)

  useEffect(() => {
    debugger
    console.log(selectedPlano)
    selectedPlano && setProcedimentos([...selectedPlano.procedimentos])
  }, [selectedPlano])

  const handleSaveChanges = async () => {
    setLoadingSave(true)
    
    try {

        agendamento.planoTratamentoId = selectedPlano.id
        agendamento.statusAgendamento = statusConstants.FINALIZADO.value
        
        await AgendamentoService.salvarAgendamento(agendamento)
        
        const data = await getAgendamentosDisponiveis()

        //Esta Finalizado se Não houver nenhum em andamento ou reaberto e se houver pelo menos 1 finalizado
        var todosProcedimentosFinalizados = !procedimentos.every(x => [1,3].some(y => y == x.statusProcedimento)) && procedimentos.some(x => x.statusProcedimento == 2);
        
        if(!todosProcedimentosFinalizados){
          const reagendar = await reagendarPaciente(data)
        
          if (reagendar) {
            let novoAgendamento = Object.assign(
              {},
              { ...agendamento },
              { data: data.toISOString(), horaInicio: format(data, 'HH:mm'), statusAgendamento: 0 }
            )
            delete novoAgendamento.id
            delete novoAgendamento.dataCriacao
            delete novoAgendamento.horaFim
            delete novoAgendamento.colaborador
            delete novoAgendamento.dependente
            delete novoAgendamento.especialidade
            delete novoAgendamento.paciente
            delete novoAgendamento.avaliacao
            delete novoAgendamento.observacao
            delete novoAgendamento.planoTratamentoId
  
            await AgendamentoService.salvarAgendamento(novoAgendamento)
            toast('Agendamento registrado', { type: toast.TYPE.SUCCESS })
          }
          else {
            handleMensagemWebsocket()
          }
        }
        
    } catch (error) {
      console.error(error)
    }
    handleSaveClick({ ...selectedPlano, procedimentos })

    setLoadingSave(false)
  }

  const handleConfirmSave = async () => {
    try {
      if(naoFinalizouProcedimento){
        
        await Swal.mixin({
          type: "warning",
          confirmButtonText: 'Finalizar Atendimento',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          html: '<span>Se desejar marcar, clique em cancelar, caso contrário, justifique para finalizar o atendimento.</span><textarea id="textarea-observacao" name="textarea-observacao" required class="swal2-input" rows="5" placeholder="Justifique para Finalizar..." style="display: flex; padding-top:10px; height: 170px;" value="' + agendamento.observacao + '"></textarea>',
          preConfirm: () => {
            if (!document.getElementById('textarea-observacao').value) {
              Swal.showValidationMessage('Justifique para finalizar o atendimento.')   
            }
          }
        }).queue([
          {
            title: 'Nenhum procedimento realizado'
          }
        ]).then((result) => {
          agendamento.observacao = document.getElementById('textarea-observacao').value;

          if (result.value){
            const { value } = Swal.fire({

              title:
                'Ao finalizar o atendimento todos os procedimentos serão salvos e não será possível mais alterar. Deseja confirmar?',
              type: 'warning',
              showCancelButton: true,
              cancelButtonColor: '#dbdbdb',
              cancelButtonText: 'Não confirmar',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Confirmar',
            }).then((result) => {
              if (result.value) {
                handleSaveChanges()
              }
            })
          }
        })
      }
      else{
        const { value } = await Swal.fire({
          title:
            'Ao finalizar o atendimento todos os procedimentos serão salvos e não será possível mais alterar. Deseja confirmar?',
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#dbdbdb',
          cancelButtonText: 'Não confirmar',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirmar',
        })
        if (value) {
          handleSaveChanges()
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDiscardChanges = async () => {
    const { value } = await Swal.fire({
      title: 'Deseja descartar as alterações feitas?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Descartar',
      cancelButtonColor: '#dbdbdb',
      cancelButtonText: 'Cancelar',
    })
    value && setProcedimentos([...selectedPlano.procedimentos])
  }

  const reagendarPaciente = async (data) => {
    try {
      const { value } = await Swal.fire({
        title: `Deseja agendar o retorno do paciente para ${format(data, 'dd/MM HH:mm')} ?`,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Agendar',
        cancelButtonColor: '#dbdbdb',
        cancelButtonText: 'Não agendar',
      })
      return value
    } catch (error) {
      console.error(error)
    }
    return false
  }

  const getAgendamentosDisponiveis = async () => {
    try {
      let { data } = await AgendamentoService.getProximoHorarioDisponivel(agendamento.colaboradorId)
      return new Date(data)
    } catch (error) {}
    return false
  }

  const handleProcedimentoChange = (index, key, value) => {
    const newProcedimentos = [...procedimentos]
    newProcedimentos[index] = { ...newProcedimentos[index], [key]: value }
    setProcedimentos(newProcedimentos)
    setNaoFinalizouProcedimento(false)
  }

  const getAvailableOptions = (status) => {
    switch (status) {
      case statusProcedimento.ABANDONO.value:
        return [statusProcedimento.ABANDONO]
      case statusProcedimento.FINALIZADO.value:
        return [statusProcedimento.TRATAMENTO, statusProcedimento.FINALIZADO]
      case statusProcedimento.CANCELADO_AUTOMATICAMENTE.value:
        return [statusProcedimento.CANCELADO_AUTOMATICAMENTE]
      case statusProcedimento.CANCELADO.value:
        return [statusProcedimento.CANCELADO]
      case statusProcedimento.PENDENTE.value:
        return [statusProcedimento.PENDENTE, statusProcedimento.TRATAMENTO, statusProcedimento.FINALIZADO]
      case statusProcedimento.TRATAMENTO.value:
        return [statusProcedimento.TRATAMENTO, statusProcedimento.FINALIZADO]
      case statusProcedimento.REABERTO.value:
        return [statusProcedimento.REABERTO, statusProcedimento.FINALIZADO]
      default:
        return []
    }
  }

   const renderProcedimentos = () => (
    <Fragment>
      <h1 className='h3 mb-4 text-secondary font-weight-bold'>Procedimentos</h1>
      {selectedPlano && procedimentos.length > 0 ? (
        <Table bordered hover>
          <thead>
            <tr>
              <th>Procedimento</th>
              <th>Observação</th>
              <th className='text-center' width='80px'>
                Dente
              </th>
              <th className='text-center' width='200px'>
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {procedimentos.map((proc, index) => {
              const { dente, descricao, procedimento } = proc
              const devendoProtese =
                proc.planoTratamentoProcedimentoProtetico && !proc.planoTratamentoProcedimentoProtetico.pago
              return (
                <tr key={index}>
                  <td>{procedimento ? procedimento.nome : ''}</td>
                  <td>
                    <Form.Control
                      type='text'
                      as='textarea'
                      maxLength={250}
                      rows={descricao ? 3 : 1}
                      value={descricao || ''}
                      style={{ fontSize: 'small' }}
                      onChange={(e) => handleProcedimentoChange(index, DESCRICAO, e.target.value)}
                    />
                  </td>
                  <td className='text-center'>{dente ? dente.codigo : 'N/D'}</td>
                  <td>
                    <Select
                      isDisabled={devendoProtese || proc.statusProcedimento === statusProcedimento.FINALIZADO.value}
                      options={getAvailableOptions(proc.statusProcedimento)}
                      value={statusOptions.find((s) => s.value === proc.statusProcedimento)}
                      onChange={(option) => handleProcedimentoChange(index, STATUS_PROCEDIMENTO, option.value)}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <div>Nenhum procedimento no plano de tratamento.</div>
      )}
      <div className='d-flex justify-content-between my-4'>
        <div style={{ width: 'max-content', cursor: 'pointer' }}>
          <div onClick={handleBackClick}>
            <Button variant='outline-primary' style={backStyle}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <span className='text-primary'>Voltar</span>
          </div>
        </div>
        <div className='d-flex' style={{ width: 'max-content' }}>
          <Button variant='outline-danger' onClick={handleDiscardChanges}>
            Descartar Alterações
          </Button>
          <Button variant='primary' className='ml-2' onClick={handleConfirmSave}>
            {isLoadingSave ? <Spinner animation='border' size='sm' variant='light' /> : 'Finalizar Atendimento'}
          </Button>
        </div>
      </div>
    </Fragment>
  )

  const renderLoading = () => <PageLoading ignoreMenu />

  return <Fragment>{isLoading ? renderLoading() : renderProcedimentos()}</Fragment>
}

export default EditarPlanoDeTratamento

const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}

import React, { Component, Fragment } from 'react'
import { Row, Col, Button, Table, Tab, Tabs } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import PageLoading from '../Common/PageLoading'
import MenuContext from '../../contexts/MenuContexts'
import PermissoesConstants from '../../constants/permissoes.constants'
import { formatMoney } from '../../utils/string.utils'
import procedimentosService from '../../services/procedimentos.service'

const TabsConstants = {
  PROCEDIMENTOS: 'Procedimentos',
  PROCEDIMENTOS_PROTETICOS: 'Procedimentos Protéticos',
}

export default class ProcedimentosList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      selectedTab: TabsConstants.PROCEDIMENTOS,
      procedimentos: [],
      procedimentosProteticos: [],
    }
  }

  componentDidMount() {
    this.fetchProcedimentos()
  }

  setSelectedTab = (selectedTab) => this.setState({ ...this.state, selectedTab })

  fetchProcedimentos = () => {
    this.setState({ ...this.state, isLoading: true }, async () => {
      try {
        const [responseProced, responseProcedProteticos] = await Promise.all([
          procedimentosService.getProcedimentos(),
          procedimentosService.getProcedimentosProteticos(),
        ])
        this.setState({
          isLoading: false,
          procedimentos: responseProced.data,
          procedimentosProteticos: responseProcedProteticos.data,
        })
      } catch (e) {
        this.setState({ isLoading: false, procedimentos: [], procedimentosProteticos: [] })
      }
    })
  }

  inativarProcedimento = (procedimento, isProtetico) => {
    this.setState({ ...this.state, isLoading: true }, async () => {
      const { id, ativo } = procedimento
      try {
        if (isProtetico) {
          await procedimentosService.ativarProcedimentoProtetico(id, !ativo)
        } else {
          await procedimentosService.ativarProcedimento(id, !ativo)
        }
        toast(`Procedimento ${isProtetico ? 'protético' : ''} ${ativo ? 'desativado' : 'ativado'} com sucesso`, {
          type: toast.TYPE.SUCCESS,
        })
        this.fetchProcedimentos()
      } catch (e) {
        console.error(e)
        this.setState({ ...this.state, isLoading: false })
      }
    })
  }

  editarProcedimento = (procedimento, isProtetico) => {
    this.props.editarProcedimento({ ...procedimento, isProtetico })
  }

  renderTabela = (acoesPermitidas, listaProcedimentos, isProtetico) => (
    <Fragment>
      {listaProcedimentos.length > 0 ? (
        <Table bordered hover  className='bg-white'>
          <thead>
            <tr>
              <th>Tratamento</th>
              {!isProtetico && <th width='200px'>Valor</th>}
              <th width='120px'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {listaProcedimentos.map((procedimento, index) => (
              <tr key={index}>
                <td>{procedimento.nome}</td>
                {!isProtetico && <td>{formatMoney(procedimento.valor)}</td>}
                <td className='text-center'>
                  {acoesPermitidas[PermissoesConstants.EDITAR] && (
                    <Link
                      to={`/procedimentos/editar`}
                      onClick={() => this.editarProcedimento(procedimento, isProtetico)}
                      className='mr-2 btn btn-primary btn-sm'
                      size='sm'
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </Link>
                  )}

                  {acoesPermitidas[PermissoesConstants.ATIVAR_INATIVAR] && (
                    <Button
                      title={procedimento.ativo ? 'Desativar procedimento' : 'Ativar procedimento'}
                      className='btn'
                      onClick={() => this.inativarProcedimento(procedimento, isProtetico)}
                      variant={procedimento.ativo ? 'success' : 'danger'}
                      size='sm'
                    >
                      <FontAwesomeIcon icon={faPowerOff} />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        !this.state.isLoading && <p>Nenhum procedimento encontrado.</p>
      )}
    </Fragment>
  )

  render() {
    const { isLoading, selectedTab, procedimentos, procedimentosProteticos } = this.state
    const { resetProcedimento } = this.props
    let acoesPermitidas = {}
    return (
      <MenuContext.Consumer>
        {(acoes) => {
          acoes.map((a) => {
            acoesPermitidas[`${a.nome}`] = a
            return true
          })
          return (
            <div>
              <Row className='mb-3'>
                <Col>
                  <h1 className='h3 m-0 text-secondary font-weight-bold'>Procedimentos</h1>
                </Col>
                <Col>
                  {acoesPermitidas[PermissoesConstants.ADICIONAR] && (
                    <Link onClick={resetProcedimento} to='/procedimentos/cadastrar'>
                      <Button className='float-right'>Cadastrar procedimento</Button>
                    </Link>
                  )}
                </Col>
              </Row>
              <Tabs activeKey={selectedTab} onSelect={this.setSelectedTab}>
                <Tab eventKey={TabsConstants.PROCEDIMENTOS} title={TabsConstants.PROCEDIMENTOS}>
                  {this.renderTabela(acoesPermitidas, procedimentos, false)}
                </Tab>
                <Tab eventKey={TabsConstants.PROCEDIMENTOS_PROTETICOS} title={TabsConstants.PROCEDIMENTOS_PROTETICOS}>
                  {this.renderTabela(acoesPermitidas, procedimentosProteticos, true)}
                </Tab>
              </Tabs>
              {isLoading && <PageLoading />}
            </div>
          )
        }}
      </MenuContext.Consumer>
    )
  }
}

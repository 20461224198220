import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import CadastrarPlano from '../../components/Ortodontia/CadastrarPlano'

const mapStateToProps = (store) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CadastrarPlano)

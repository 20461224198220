import { put, takeEvery, takeLatest, all } from 'redux-saga/effects'
import axios from './axios-client'
import { Pacientes, Notificacoes } from '../types'

function* fetchPacientes() {
  try {
    const { data } = yield axios.get('Paciente/getAll')
    yield put({
      type: Pacientes.LISTAR_PACIENTES,
      pacientes: data,
    })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* salvarPaciente(action) {
  try {
    const { paciente } = action
    if (!paciente.id) {
      yield axios.post('Paciente/add', paciente)
    } else {
      yield axios.put('Paciente/update', paciente)
    }
    yield all([put({ type: Pacientes.REQUEST_PACIENTES }), put({ type: Pacientes.RESET_PACIENTE })])
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* getById(action) {
  try {
    const { data } = yield axios.get(`Paciente/getById/${action.pacienteId}`)
    yield put({
      type: Pacientes.EDITAR_PACIENTE,
      paciente: data,
    })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* getByCpf(action) {
  try {
    const { data } = yield axios.get(`Paciente/getByCpf/${action.pacienteCpf}`)
    yield put({
      type: Pacientes.SET_PACIENTE,
      paciente: data,
    })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* inativar(action) {
  try {
    yield axios.put(`Paciente/Ativar/${action.pacienteId}/${action.ativar}`)
    yield put({ type: Pacientes.REQUEST_PACIENTES })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* getPlanosTratamentoById(action) {
  try {
    const { data } = yield axios.get(`PlanoTratamento/getByPacienteId/${action.pacienteId}`)
    yield put({
      type: Pacientes.SET_PLANOS_TRATAMENTO,
      planosDeTratamento: data,
    })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* salvarPlanoDeTratamento(action) {
  try {
    const { planoDeTratamento } = action
    const { pacienteId } = planoDeTratamento
    yield axios.post('PlanoTratamento/salvar', planoDeTratamento)
    yield put({ type: Pacientes.REQUEST_PLANOS_TRATAMENTO, pacienteId })
  } catch (error) {
    yield put({ type: Notificacoes.ERRO, notifications: error.notifications })
  }
}

function* pacientesListener() {
  yield all([
    takeEvery(Pacientes.REQUEST_PLANOS_TRATAMENTO, getPlanosTratamentoById),
    takeEvery(Pacientes.SALVAR_PLANO_TRATAMENTO, salvarPlanoDeTratamento),
    takeEvery(Pacientes.REQUEST_PACIENTES, fetchPacientes),
    takeLatest(Pacientes.GET_PACIENTE_ID, getById),
    takeLatest(Pacientes.GET_PACIENTE_CPF, getByCpf),
    takeEvery(Pacientes.SALVAR_PACIENTE, salvarPaciente),
    takeEvery(Pacientes.INATIVAR_PACIENTE, inativar),
  ])
}

export default pacientesListener
